"use strict";

var React = require("react/addons");
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var LbsBulletin = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },

  componentDidMount: function () {},

  /* privates */
  _showFeedbackForm: function () {
    window.LbsAppData.AppContext.openDialog({ type: "feedback" });
  },

  /* render */
  render: function () {
    /* jshint ignore:start */
    var classes = React.addons.classSet({
      Bulletin: true,
      Lbs: true,
    });

    return (
      <div className={classes}>
        <div className="title">
          <i className="fa fa-fw fa-bolt" />
          Lightning Bolt News
        </div>
        <div className="content">
          <div
            className="content-item"
            onClick={this.touchProxy.bind(this, this._showFeedbackForm, [])}
            onTouchEnd={this.touchProxy.bind(this, this._showFeedbackForm, [])}
          >
            <div className="title">Tell us what you think!</div>
            <div className="desc">We're getting a new look, and we'd love your input.</div>
          </div>
        </div>
      </div>
    );

    /* jshint ignore:end */
  },
});

module.exports = LbsBulletin;
