"use strict";

// scss requires
//require('./_styles.scss');
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");
// components
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

// some status string constants
var STATUS_PENDING = "pending";
var STATUS_SUSPENDED = "suspended";
var STATUS_GRANTED = "granted";
var STATUS_DENIED = "denied";
var STATUS_DELETED = "deleted";

var RequestsDialogListRequestItem = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {},
  componentWillUnmount: function () {},
  //componentWillUpdate: function() {
  //	this._unregisterBackboneEvents();
  //},
  //componentDidUpdate: function() {
  //	this._registerBackboneEvents();
  //},

  /* publics */

  /* privates */
  _handleClick: function (e) {
    window.LbsAppData.AppContext.openDialog({
      type: "request-details",
      data: this.props.request,
    });
  },
  _handleClickWithTouch: function (e) {
    var that = this;
    this._endTouch(that._handleClick, e);
  },
  _showActionContextMenu: function (e) {
    var that = this;

    var items = [];
    if (window.LbsAppData.Helpers.Permissions.CanIApproveDenyRequest(this.props.request)) {
      items.push({
        text: "Grant",
        icon: "fa-thumbs-o-up",
        onClick: function () {
          // open up the swap remove dialog (pre-filled)
          window.LbsAppData.AppContext.openDialog({
            type: "request-action",
            data: {
              action: window.LbsAppData.GRANT_ACTION,
              requests: [that.props.request],
            },
          });
        },
      });
      items.push({
        text: "Deny",
        icon: "fa-thumbs-o-down",
        onClick: function () {
          // open up the swap remove dialog (pre-filled)
          window.LbsAppData.AppContext.openDialog({
            type: "request-action",
            data: {
              action: window.LbsAppData.DENY_ACTION,
              requests: [that.props.request],
            },
          });
        },
      });
      items.push({
        text: "Suspend",
        icon: "fa-question",
        onClick: function () {
          // open up the swap remove dialog (pre-filled)
          window.LbsAppData.AppContext.openDialog({
            type: "request-action",
            data: {
              action: window.LbsAppData.SUSPEND_ACTION,
              requests: [that.props.request],
            },
          });
        },
      });
    }
    if (window.LbsAppData.Helpers.Permissions.CanIDeleteRequest(this.props.request)) {
      items.push({
        text: "Delete",
        icon: "fa-trash-o",
        onClick: function () {
          // open up the swap remove dialog (pre-filled)
          window.LbsAppData.AppContext.openDialog({
            type: "request-action",
            data: {
              action: window.LbsAppData.DELETE_ACTION,
              requests: [that.props.request],
            },
          });
        },
      });
    }

    if (items.length) {
      window.LbsAppData.AppContext.openContextMenu({
        component: this,
        items: items,
        extClasses: ["zDialog"],
        coordsRel: { top: 30, left: 1 },
        coordsRelTarget: e.target,
      });
    }

    //e.preventDefault();
  },
  _buildTypeIcon: function () {
    var iconStack = [<i key="1" className="fa fa-fw fa-stack-2x fa-2x fa-square" />];

    switch (this.props.request.attributes.command_type) {
      case 0:
        iconStack.push(<i key="2" className="fa fa-fw fa-stack-1x fa-paper-plane" />);
        break;
      case 1:
        iconStack.push(<i key="2" className="fa fa-fw fa-stack-1x fa-paper-plane" />);
        iconStack.push(<i key="3" className="fa fa-fw fa-stack-2x fa-ban" />);
        break;
      default:
        break;
    }

    return <span className="fa-stack fa-lg">{iconStack}</span>;
  },
  _buildStatusIcon: function () {
    var iconStack = [<i key="1" className="fa fa-fw fa-stack-2x fa-2x fa-square" />];

    switch (this.props.request.attributes.status) {
      case STATUS_GRANTED:
        iconStack.push(<i key="2" className="fa fa-fw fa-stack-1x fa-thumbs-o-up" />);
        break;
      case STATUS_DENIED:
        iconStack.push(<i key="2" className="fa fa-fw fa-stack-1x fa-thumbs-o-down" />);
        break;
      case STATUS_SUSPENDED:
      case STATUS_PENDING:
        iconStack.push(<i key="2" className="fa fa-fw fa-stack-1x fa-question" />);
        break;
      case STATUS_DELETED:
        iconStack.push(<i key="2" className="fa fa-fw fa-stack-1x fa-trash" />);
        break;
      default:
        break;
    }

    return <span className="fa-stack fa-lg">{iconStack}</span>;
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      Item: true,
      granted: this.props.request.attributes.status == STATUS_GRANTED,
      denied: this.props.request.attributes.status == STATUS_DENIED,
      suspended: this.props.request.attributes.status == STATUS_SUSPENDED,
      pending: this.props.request.attributes.status == STATUS_PENDING,
      deleted: this.props.request.attributes.status == STATUS_DELETED,
    });

    var requestDate = moment(this.props.request.attributes.request_date);
    var requestedDate = moment(this.props.request.attributes.timestamp);
    //var commandText = !this.props.request.attributes.command_type ? "Want" : "Don't Want";
    var commandText = !this.props.request.attributes.command_type ? "" : <i className="fa fa-ban" />;

    //var assignFlair = !this.props.request.attributes.command_type ? "" : <i className='fa fa-ban' />;
    var assignText =
      this.props.request.attributes.assign_display_name +
      " (" +
      this.props.request.attributes.target_template_desc +
      ")";

    var statusText = "";
    if (this.props.request.attributes.status == STATUS_GRANTED) {
      statusText = "Granted";
    } else if (this.props.request.attributes.status == STATUS_DENIED) {
      statusText = "Denied";
    } else if (this.props.request.attributes.status == STATUS_PENDING) {
      statusText = "Pending (RM)";
    } else if (this.props.request.attributes.status == STATUS_SUSPENDED) {
      statusText = "Pending";
    } else if (this.props.request.attributes.status == STATUS_DELETED) {
      statusText = "Deleted";
    }
    var personnel = this.props.request.attributes.display_name;
    var statusIcon = this._buildStatusIcon();
    var typeIcon = this._buildTypeIcon();

    return (
      <div className={classes}>
        <div className="icons">
          <div className="icon-status">{statusIcon}</div>
          <div className="icon-type">{typeIcon}</div>
        </div>

        <div
          className="info"
          onClick={this._handleClick}
          onTouchStart={this._startTouch}
          onTouchMove={this._moveTouch}
          onTouchEnd={this._handleClickWithTouch}
        >
          <span className="assignment">{assignText}</span>
          <span className="status">{personnel}</span>
        </div>
      </div>
    );

    /*
 			onClick={this._showActionContextMenu}
				{commandText}

				<div className='type'>
					<span className='command'>{commandText}</span>
					<span className='date'>{requestDate.format('ddd, LL')}</span>
				</div>
		*/
  },
});

module.exports = RequestsDialogListRequestItem;
