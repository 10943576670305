import React from "react";

import { PersonnelOrAssignment, RequestsByDateByName, SlotsByDateByColumn } from "@/viewer/ui/modules/common/types";
import { ReportData, ReportResult, TallyResult } from "@/viewer/types/domain/report";
import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { ViewData } from "@/viewer/types/viewdata";

import StandardPrintColumn from "./StandardPrintColumn";
import { columnRowHeightMap } from "../ColumnPrintView";
import PrintColumnLeftPrintColumn from "./printColumnLeftPrintColumn";

import "./_styles.scss";
import { DepartmentsById } from "@/viewer/types/domain/department";

export enum ColumnType {
  LEFT_COLUMN,
  STANDARD_COLUMN,
}

export interface IColumnPrintingBaseProps {
  columnRowHeightMap: columnRowHeightMap;
  isRequestOnlyView: boolean;
  leftColumnData: PersonnelOrAssignment[];
  leftHandColumnId: number;
  requestData: RequestsByDateByName;
  scale: number;
  settings: SettingsContext;
  slotData: SlotsByDateByColumn;
  ui: UIContext;
  viewData: ViewData;
  tallies?: TallyResult[];
  reportData?: ReportData;
  reportResult?: ReportResult;
}

export type LeftColumnProps = { type: ColumnType.LEFT_COLUMN; title: string; columnDate?: never };

export type StandardColumnProps = {
  type: ColumnType.STANDARD_COLUMN;
  columnDate: Date;
  title?: never;
};

// This is a discriminated union (https://www.typescriptlang.org/docs/handbook/2/narrowing.html#discriminated-unions)
// If the provided type for ColumnType is LeftColumn, title will be required and columnDate will be ignored
// even if provided. The opposite applies to StandardColumn
type ColumnTypeProps = LeftColumnProps | StandardColumnProps;

type PrintColumnFactoryProps = IColumnPrintingBaseProps & ColumnTypeProps & { departmentsById: DepartmentsById };

const PrintColumnFactory = (props: PrintColumnFactoryProps): JSX.Element => {
  const {
    columnDate = new Date(),
    columnRowHeightMap,
    isRequestOnlyView,
    leftColumnData,
    leftHandColumnId,
    reportData,
    reportResult,
    requestData,
    scale,
    settings,
    slotData,
    tallies,
    title = "",
    type,
    ui,
    viewData,
    departmentsById,
  } = props;

  const leftColumnProps = { type: type as ColumnType.LEFT_COLUMN, title };
  const standardColumnProps = { type: type as ColumnType.STANDARD_COLUMN, columnDate };

  const baseProps = {
    columnRowHeightMap,
    isRequestOnlyView,
    leftColumnData,
    leftHandColumnId,
    reportData,
    reportResult,
    requestData,
    scale,
    settings,
    slotData,
    tallies,
    ui,
    viewData,
  };

  switch (type) {
    case ColumnType.LEFT_COLUMN:
      return <PrintColumnLeftPrintColumn {...{ ...baseProps, ...leftColumnProps }} />;
    case ColumnType.STANDARD_COLUMN:
      return <StandardPrintColumn {...{ ...baseProps, ...standardColumnProps }} departmentsById={departmentsById} />;
    default:
      return <></>;
  }
};

export default PrintColumnFactory;
