"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");

var MessageDialog = React.createClass({
  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {
    // set a timeout to close this dialog if there's a timer
    if (this.props.dialogObject.timer) {
      var that = this;
      window.setTimeout(function () {
        if (that.isMounted()) {
          that.close();
        }
      }, this.props.dialogObject.timer);
    }
  },
  componentWillUnmount: function () {},
  //componentWillUpdate: function() {
  //	this._unregisterBackboneEvents();
  //},
  //componentDidUpdate: function() {
  //	this._registerBackboneEvents();
  //},

  /* publics */
  close: function (e) {
    this.props.close();
  },
  focusDate: function (date) {
    window.LbsAppData.DateManager.selectMonth(date);
  },

  /* privates */

  /* render */
  render: function () {
    /* jshint ignore:start */

    var loader;
    if (this.props.dialogObject.timer) {
      // timer countdown?
    } else {
      // loader message
      loader = (
        <div className="loader">
          <div className="flipper">
            <div className="front" />
            <div className="back" />
          </div>
        </div>
      );
    }

    var classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      Message: true,
      "no-loader": this.props.dialogObject.timer ? true : false,
    });

    return (
      <div className={classes}>
        <div className="position-reset">
          <div className="message">{this.props.dialogObject.message}</div>

          {loader}
        </div>
      </div>
    );
  },

  /* 

					<div className='loading'>
						<div className='bullet'></div>
						<div className='bullet'></div>
						<div className='bullet'></div>
						<div className='bullet'></div>
					</div>

					</div>
	*/
});

module.exports = MessageDialog;
