const Backbone = require("backbone");
var moment = require("moment");
var ApiConfig = require("_lib/data/ApiConfig.js");
var TallyDemand = require("./../models/TallyDemand.js");

var TallyDemandCollection = Backbone.Collection.extend({
  model: TallyDemand,

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    return ApiConfig.getPrefix() + "/tally/demand/" + this.attributes.schedID;
  },
});

module.exports = TallyDemandCollection;
