"use strict";

const React = require("react/addons");
const TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

const TimePickerHourPicker = React.createClass({
  mixins: [TouchMixin],

  getInitialState: function () {
    return { value: this.props.defaultVal };
  },

  getValue: function () {
    return parseInt(this.state.value);
  },

  onChange: function (newValue) {
    const callbackFunc = this.props.onChange;
    if (typeof callbackFunc === "function") {
      callbackFunc({ newHour: newValue });
    }
  },

  /* privates */
  _calculateNewVal: function (newVal, textInput, arrowClicked) {
    const isMilitary = window.LbsAppData.Helpers.Time.isMilitaryTimePreferred();
    const maxVal = isMilitary ? 23 : 12;
    const minVal = isMilitary ? 0 : 1;
    const origVal = this.state.value;
    // if field is blank, allow arrow to be used to make it max or min time
    if (newVal === undefined) {
      if (arrowClicked == "increment") {
        newVal = minVal;
      } else if (arrowClicked == "decrement") {
        newVal = maxVal;
      }
    }
    if (textInput) {
      if (isNaN(newVal)) {
        newVal = undefined;
      } else if (newVal > maxVal) {
        newVal = origVal;
      } else if (newVal < minVal) {
        newVal = minVal;
      }
    } else {
      if (newVal > maxVal) {
        newVal = minVal;
      } else if (newVal < minVal) {
        newVal = maxVal;
      }
    }
    if (this.props.submitButtonToggle) {
      this.props.submitButtonToggle(newVal, this.props.isStartPicker);
    }
    return newVal;
  },

  _incrementHour: function (e) {
    let newVal;
    if (!isNaN(this.state.value)) {
      newVal = parseInt(this.state.value) + 1;
    }
    newVal = this._calculateNewVal(newVal, false, "increment");
    this.setState({ value: newVal });
    this.onChange(newVal);
  },

  _decrementHour: function (e) {
    let newVal;
    if (this.state.value) {
      newVal = parseInt(this.state.value) - 1;
    }
    newVal = this._calculateNewVal(newVal, false, "decrement");
    this.setState({ value: newVal });
    this.onChange(newVal);
  },

  _handleHourChange: function (e) {
    let newVal = parseInt(e.target.value);
    newVal = this._calculateNewVal(newVal, true);
    this.setState({ value: newVal });
    this.onChange(newVal);
  },

  _validateHourChange: function () {
    this.props.refocusKeydown();
    this.props.submitButtonToggle && this.props.submitButtonToggle(this.state.value, this.props.isStartPicker);
  },

  /* lifecycle methods */
  componentWillReceiveProps: function (nextProps) {
    if (nextProps.isFocused && !this.props.isFocused) {
      this.setState({
        focused: true,
        spinnerValue: nextProps.spinnerCounter,
      });
    } else if (!nextProps.isFocused && this.props.isFocused) {
      this.setState({ focused: false, spinnerValue: undefined });
    } else if (this.state.focused) {
      // based on the spinner val...move up/down in response to a keyboard event
      if (this.state.spinnerValue < nextProps.spinnerCounter) {
        // up
        this._incrementHour();
      } else if (this.state.spinnerValue > nextProps.spinnerCounter) {
        // down
        this._decrementHour();
      }
      this.setState({ spinnerValue: nextProps.spinnerCounter });
    }

    if (nextProps.defaultVal !== this.props.defaultVal) {
      this.setState({ value: nextProps.defaultVal });
    }
  },

  startPicker: () => {
    if (this.props && this.props.unfocusKeydown) {
      return this.props.unfocusKeydown.bind(null, 0, this.props.isStartPicker);
    }
  },

  render: function () {
    const classes = React.addons.classSet({
      HourPicker: true,
      focused: this.state.focused,
    });

    return (
      <div className={classes}>
        <i
          className="fa fa-angle-up"
          onClick={this.touchProxy.bind(this, this._incrementHour, [])}
          onTouchEnd={this.touchProxy.bind(this, this._incrementHour, [])}
        />
        <div className="picker-text-input-container">
          <input
            className="picker-text-input"
            value={this.state.value}
            onChange={this._handleHourChange}
            onFocus={this.startPicker}
            onBlur={this._validateHourChange}
          />
        </div>
        <i
          className="fa fa-angle-down"
          onClick={this.touchProxy.bind(this, this._decrementHour, [])}
          onTouchEnd={this.touchProxy.bind(this, this._decrementHour, [])}
        />
      </div>
    );
  },
});

module.exports = TimePickerHourPicker;
