"use strict";

// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");
// components

var SubMenuItem = React.createClass({
  /* privates */
  _handleClick: function (e) {
    if (this.props.item.onClick) {
      this.props.item.onClick();
    }
  },
  _getTitle: function () {
    return (
      <span className="title" onClick={this._handleClick}>
        {this.props.item.title}
      </span>
    );
  },
  _getItems: function () {
    return [];

    /*
		var ret = [];
		switch(this.props.type) {
			case 'viewer':
				ret.push(<a className='item' key='me' href='/viewer/me'>Me</a>);
				ret.push(<a className='item' key='today' href='/viewer/today'>Today</a>);
				ret.push(<a className='item' key='default' href='/viewer/'>Default</a>);
				return ret;
			case 'view_design':
				ret.push(<a className='item' key='new' href='/manager/#/views/new'>New</a>);

				//if (window.LbsAppData.id == 'editor' && window.LbsAppData.AppContext.get('view')) {
				//	ret.push(<a className='item' key='edit' href={'/manager/#/views/' + window.LbsAppData.AppContext.get('view').id}>Edit Current</a>);
				//}

				return ret;
			case 'reports':
				ret.push(<a className='item' key='new' href='/reports/'>New</a>);

				if (window.LbsAppData.id == 'editor') {
					var urlParts = ['#s', window.LbsAppData.AppContext.get('displayedSchedule').id];
					// if there is an active view push that too
					if (window.LbsAppData.AppContext.get('view')) {
						urlParts.push(window.LbsAppData.AppContext.get('view').id);
					}

					ret.push(<a className='item' target='_blank' key='shadow' href={'/reports/' + urlParts.join('/')}>Shadow Current</a>);	
				}
				
				return ret;
			default:
				return ret;	// empty
		}
		*/
  },

  /* publics */

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {
    //this._registerBackboneEvents();
  },
  componentWillUnmount: function () {
    //this._unregisterBackboneEvents();
  },
  componentWillUpdate: function (nextProps, nextState) {},
  componentDidUpdate: function () {
    //this._registerBackboneEvents();
  },

  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      SubMenuItem: true,
      //this.props.action: true
    });

    if (this.props.item.extClassFn) {
      classes += " " + this.props.item.extClassFn();
    }
    var icon;
    if (this.props.item.icon) {
      icon = <i className={"fa fa-fw " + this.props.item.icon} />;
    }

    return (
      <div className={classes}>
        <span className="title">
          {icon}
          {this._getTitle()}
        </span>
        <div className="content">
          <div className="items">{this._getItems()}</div>
        </div>
      </div>
    );

    /* jshint ignore:end */
  },
});

module.exports = SubMenuItem;
