import React from "react";

interface Open {
  type: Type.Open;
  modal: React.ReactElement;
}

interface Close {
  type: Type.Close;
}

interface Remove {
  type: Type.Remove;
}

export type Action = Open | Close | Remove;

export enum Type {
  Open = "modal/open",
  Close = "modal/close",
  Remove = "modal/remove",
}

export default {
  open: (modal: React.ReactElement): Open => ({
    type: Type.Open,
    modal,
  }),
  close: (): Close => ({
    type: Type.Close,
  }),
  remove: (): Remove => ({
    type: Type.Remove,
  }),
};
