import list, { State as ListState } from "@/viewer/ui/modules/grid/list/reducer";

import { combineReducers } from "redux";
import session, { State as SessionState } from "../_lib/data/session/reducer";
import filterList, { State as FilterListState } from "../_lib/ui/modules/topbar/reducer";
import activityFeed, { State as ActivityFeedState } from "../common/components/activityFeed/reducer";
import modal, { State as ModalState } from "../common/components/modal/reducer";
import toast, { State as ToastState } from "../common/components/toast/reducer";
import highlighters, { State as HighlightersState } from "../viewer/ui/modules/contextmenu/highlighters/reducer";
import init, { State as InitState } from "./init/reducer";
import login, { State as LoginState } from "./login/reducer";

export interface AppState {
  init: InitState;
  login: LoginState;
  filterList: FilterListState;
  session: SessionState;
  modal: ModalState;
  highlighters: HighlightersState;
  toast: ToastState;
  list: ListState;
  activityFeed: ActivityFeedState;
}

const rootReducer = combineReducers({
  init,
  login,
  filterList,
  session,
  modal,
  highlighters,
  toast,
  list,
  activityFeed,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
