import React from "react";

import classnames from "@/_lib/utils/classnames";
import styles from "./styles.module.scss";

interface Props {
  text: string;
  className?: string;
}

const Label = ({ text, className = "" }: Props): React.ReactElement => {
  const classNames = classnames(className, {
    [styles.label]: true,
  });

  return <div className={classNames}>{text}</div>;
};

export default Label;
