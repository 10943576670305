"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");
var _ = require("underscore");
// components
var DatePickerControls = require("./DatePickerControls.jsx");
var DatePickerMonthSelect = require("./DatePickerMonthSelect.jsx");
var DatePickerCalendar = require("./DatePickerCalendar.jsx");
var DatePickerSubmit = require("./DatePickerSubmit.jsx");

var DatePicker = React.createClass({
  /* privates */
  _decrementYear: function () {
    this.state.shownMonth.subtract(1, "years");
    this.forceUpdate();
  },
  _decrementMonth: function () {
    // make sure it's in the datepicker bounds
    if (
      this.state.lowerBound &&
      !this.state.lowerBound.isAfter(moment(this.state.shownMonth).subtract(1, "months"), "month")
    ) {
      this.state.shownMonth.subtract(1, "months");
      this.forceUpdate();
    }
  },
  _incrementYear: function () {
    this.state.shownMonth.add(1, "years");
    this.forceUpdate();
  },
  _incrementMonth: function () {
    // make sure it's in the datepicker bounds
    if (
      this.state.upperBound &&
      !this.state.upperBound.isBefore(moment(this.state.shownMonth).add(1, "months"), "month")
    ) {
      this.state.shownMonth.add(1, "months");
      this.forceUpdate();
    }
  },
  _toggleDateSelection: function (date) {
    //var dateKey = date.format('YYYYMMDD');
    if (this.props.options.anchorStop) {
      // stop is anchored so always change the rangeStart
      this.setState({ rangeStart: date.clone() });
    } else if (
      (this.state.rangeStart && this.state.rangeStop) ||
      (this.state.rangeStart && date.isBefore(this.state.rangeStart))
    ) {
      // if both range start and stop endpoints are set then reset it
      this.setState({ rangeStart: date.clone(), rangeStop: undefined });
    } else if (!this.state.rangeStop) {
      // rangeStop is undefined so finish the range selection
      this.setState({ rangeStop: date.clone() });
    }
  },

  /* publics */
  increment: function () {
    switch (this.props.mode) {
      case "monthly":
        this._incrementYear();
        break;
      case "weekly":
      case "daily":
      case "range":
        this._incrementMonth();
        break;
      default:
        break;
    }
  },
  decrement: function () {
    switch (this.props.mode) {
      case "monthly":
        this._decrementYear();
        break;
      case "weekly":
      case "daily":
      case "range":
        this._decrementMonth();
        break;
      default:
        break;
    }
  },
  selectMonth: function (month) {
    this.state.shownMonth.month(month);
    this.forceUpdate();
  },
  selectWeek: function (date) {
    // get the week range
    var weekArray = [];
    var dayIterator = date.clone().startOf("week");
    while (dayIterator <= date.clone().endOf("week")) {
      weekArray.push(dayIterator.format("YYYYMMDD"));
      dayIterator.add(1, "days");
    }

    if (this.props.options.multiselect) {
      _.each(weekArray, function (weekDay) {
        if (this.state.selectedDates.hasOwnProperty(weekDay)) {
          // deselect it
          delete this.state.selectedDates[weekDay];
        } else {
          this.state.selectedDates[weekDay] = true;
        }
      });
      // update the component
      this.forceUpdate();
    } else {
      // replace the selected date(s) with this these
      var newSelectedDates = {};
      _.each(weekArray, function (weekDay) {
        newSelectedDates[weekDay] = true;
      });

      this.setState({
        selectedDates: newSelectedDates,
        shownDate: date.clone(),
      });
    }
  },
  selectDate: function (selectedDate) {
    const dateKey = selectedDate.format("YYYYMMDD");
    const dateIsSelected = this.state.selectedDates.hasOwnProperty(selectedDate.format("YYYYMMDD"));

    if (this.props.options.blockselect) {
      // Get block details
      const blockStartWeekDay = this.props.blockOptions.startWeekDay;
      const blockLength = this.props.blockOptions.length;
      const blockDayRange = this.props.blockOptions.dayRange;

      // Grab the day we just selected, and find the date within that week
      // where the block starts
      const selectedDayOfWeek = selectedDate.day();
      const weekDayDifference = blockDayRange.indexOf(selectedDayOfWeek);
      const selectedWeekBlockStart = selectedDate
        .clone()
        .subtract(blockStartWeekDay === -1 ? 0 : weekDayDifference, "days");

      // Starting from the beginning of the now known block area, gather all
      // the dates we will select
      const newSelectedDates = _.range(0, blockLength).reduce(
        (acc, i) => {
          const dateString = selectedWeekBlockStart.clone().add(i, "days").format("YYYYMMDD");

          // If they clicked on an already selected date, then we want to deselect
          // all the days within that block range
          if (dateIsSelected) {
            delete acc[dateString];
            return acc;
          }

          // If they clicked on a date that isn't selected, then we want to select
          // all the days within that block range
          return { ...acc, [dateString]: true };
        },
        {
          ...(dateIsSelected && blockStartWeekDay !== -1 ? this.state.selectedDates : {}),
        }
      );

      this.setState(
        (prevState) => ({
          selectedDates: {
            ...(dateIsSelected || blockStartWeekDay === -1 ? {} : prevState.selectedDates),
            ...newSelectedDates,
          },
        }),
        () => {
          if (this.props.setSelectedDateData) {
            this.props.setSelectedDateData(dateKey, newSelectedDates);
          } else {
            this.props.checkForStructure();
          }
        }
      );
    } else if (this.props.options.multiselect) {
      // Deselect date
      if (dateIsSelected) {
        const selectedDates = _.omit(this.state.selectedDates, dateKey);
        this.setState(
          {
            selectedDates: selectedDates,
          },
          () => {
            if (this.props.setSelectedDateData) {
              this.props.setSelectedDateData(dateKey, selectedDates);
            } else {
              this.props.checkForStructure();
            }
          }
        );
      } else {
        const newDate = {};
        newDate[dateKey] = true;
        const selectedDates = {
          ...this.state.selectedDates,
          ...newDate,
        };
        this.setState(
          () => ({
            selectedDates: selectedDates,
          }),
          () => {
            if (this.props.setSelectedDateData) {
              this.props.setSelectedDateData(dateKey, selectedDates);
            } else {
              this.props.checkForStructure();
            }
          }
        );
      }
    } else if (this.props.options.noSubmit) {
      // replace the selected date with this one -- need to do this better, right now it
      // depends on the fact that this state's array is shared with the parent object...
      // so we can't create a new one (basically)
      for (var key in this.state.selectedDates) {
        delete this.state.selectedDates[key];
      }

      this.state.selectedDates[dateKey] = true;

      var that = this;
      this.setState({ shownDate: selectedDate.clone() }, function () {
        that.submitDates();
      });
    } else {
      // replace the selected date with this one -- need to do this better, right now it
      // depends on the fact that this state's array is shared with the parent object...
      // so we can't create a new one (basically)
      for (var key in this.state.selectedDates) {
        delete this.state.selectedDates[key];
      }

      this.state.selectedDates[dateKey] = true;
      this.setState({ shownDate: selectedDate.clone() }, () => {
        if (this.props.setSelectedDateData) {
          this.props.setSelectedDateData(dateKey, this.state.selectedDates);
        }
      });
    }
  },
  updateBoundaries: function (lowerBound, upperBound) {
    // also as a part of this...blow away any already-selected dates that aren't within the new boundry
    var that = this;
    var newSelectedDates = {};
    var lowerBoundMoment = moment(lowerBound);
    var upperBoundMoment = moment(upperBound);

    var lowerBoundFormatted = lowerBoundMoment.format("YYYYMMDD");
    var upperBoundFormatted = upperBoundMoment.format("YYYYMMDD");
    _.each(Object.keys(this.state.selectedDates), function (i) {
      if (!(i < lowerBoundFormatted || i > upperBoundFormatted)) {
        newSelectedDates[i] = that.state.selectedDates[i];
      }
    });

    this.setState({
      lowerBound: lowerBoundMoment,
      upperBound: upperBoundMoment,
      selectedDates: newSelectedDates,
    });
  },
  toggleActive: function () {
    this.setState({ active: !this.state.active });
  },
  getDateRange: function () {
    return {
      start: this.state.rangeStart.clone(),
      stop: this.state.rangeStop.clone(),
    };
  },
  submitDates: function () {
    if (this.props.mode == "monthly") {
      this.props.onSubmit(this.state.shownMonth);
    } else if (this.props.mode == "range") {
      this.props.onSubmit(
        this.state.rangeStart,
        this.state.rangeStop === undefined ? this.state.rangeStart : this.state.rangeStop
      );
    } else {
      this.props.onSubmit(this.state.shownDate);
    }
  },

  /* lifecycle methods */
  getInitialState: function () {
    var shownMonth = this.props.date ? this.props.date.startOf("month") : moment().startOf("month");
    var shownDate = window.LbsAppData.DateManager ? window.LbsAppData.DateManager.getFocusedDates().start : moment();

    var selectedDates = this.props.selectedDates;
    if (!selectedDates) {
      selectedDates = {};

      // select it if it isn't a range
      if (this.props.mode != "range") {
        selectedDates[shownDate.format("YYYYMMDD")] = true;
      }
    }

    return {
      active: this.props.active,
      shownMonth: shownMonth,
      shownDate: shownDate,
      lowerBound: this.props.lowerBound ? moment(this.props.lowerBound) : moment([1901, 0, 1]),
      upperBound: this.props.upperBound ? moment(this.props.upperBound) : moment([3000, 11, 31]),
      selectedDates: selectedDates,
      rangeStart: this.props.options.rangeStart,
      rangeStop: this.props.options.rangeStop,
    };
  },
  componentDidMount: function () {},
  componentWillUnmount: function () {},
  componentWillUpdate: function () {},
  componentDidUpdate: function () {},
  componentWillReceiveProps: function (nextProps) {
    // force some fields to update when windows change
    this.setState({
      shownMonth: nextProps.date ? nextProps.date.startOf("month") : moment().startOf("month"),
      lowerBound: nextProps.lowerBound ? moment(nextProps.lowerBound) : moment([1901, 0, 1]),
      upperBound: nextProps.upperBound ? moment(nextProps.upperBound) : moment([3000, 11, 31]),
    });
  },

  render: function () {
    const { mode, options, blockOptions } = this.props;
    const { active, shownMonth, shownDate, lowerBound, upperBound, rangeStart, rangeStop, selectedDates } = this.state;

    const classes = React.addons.classSet({
      DatePicker: true,
      active: active,
    });

    const undecremental = lowerBound && !lowerBound.isAfter(moment(shownMonth).subtract(1, "months"), "month");

    const unincremental = upperBound && !upperBound.isBefore(moment(shownMonth).add(1, "months"), "month");

    const components = [];
    components.push(
      <DatePickerControls
        key="controls"
        mode={mode}
        date={shownMonth.clone()}
        undecremental={undecremental}
        unincremental={unincremental}
        decrement={this.decrement}
        increment={this.increment}
      />
    );

    if (mode === "monthly") {
      components.push(
        <DatePickerMonthSelect key="month_select" date={shownMonth.clone()} selectMonth={this.selectMonth} />
      );
    } else if (mode === "weekly") {
      components.push(
        <DatePickerCalendar
          key="calendar"
          month={shownMonth.clone()}
          date={shownDate.clone()}
          lowerBound={lowerBound}
          upperBound={upperBound}
          selectedDates={selectedDates}
          selectDate={this.selectWeek}
        />
      );
    } else if (mode === "range") {
      components.push(
        <DatePickerCalendar
          key="calendar"
          month={shownMonth.clone()}
          date={shownDate.clone()}
          lowerBound={lowerBound}
          upperBound={upperBound}
          rangeStart={rangeStart}
          rangeStop={rangeStop}
          selectedDates={selectedDates}
          selectDate={this._toggleDateSelection}
        />
      );
    } else {
      // daily
      components.push(
        <DatePickerCalendar
          key="calendar"
          month={shownMonth.clone()}
          date={shownDate.clone()}
          lowerBound={lowerBound}
          upperBound={upperBound}
          selectedDates={selectedDates}
          selectDate={this.selectDate}
          blockOptions={blockOptions}
        />
      );
    }

    if (!options.noSubmit) {
      components.push(
        <DatePickerSubmit
          key="submit"
          mode={mode}
          rangeStart={rangeStart}
          rangeStop={rangeStop}
          submitDates={this.submitDates}
        />
      );
    }

    const blockDetails = blockOptions?.length && (
      <div className="BlockDetails">
        <div className="BlockDetails__text">Block Length: {blockOptions.length}</div>
        <div className="BlockDetails__text">
          Block Start Day:{" "}
          {blockOptions.startWeekDay === -1 ? "Any Day" : moment().day(blockOptions.startWeekDay).format("dddd")}
        </div>
      </div>
    );

    return (
      <>
        <div className={classes}>{components}</div>
        {blockDetails && blockDetails}
      </>
    );
  },
});

module.exports = DatePicker;
