/*
 * Tally collection that doesn't depend on window object bindings */
const Backbone = require("backbone");
const _ = require("lodash");

const ApiConfig = require("_lib/data/ApiConfig.js");
const NakedTally = require("./../models/NakedTally.js");

const NakedTallyCollection = Backbone.Collection.extend({
  model: NakedTally,
  initialize: function (models, attributes) {
    this.attributes = {};

    for (const key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },
  url: function () {
    const queryParams = {};

    if (this?.attributes?.templateList?.length) {
      queryParams["template_list"] = this.attributes.templateList.join("~");
    }

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    const queryParamsString = _.map(cleanQueryParams, function (val, key) {
      return key + "=" + val;
    });

    return ApiConfig.getPrefix() + "/tally" + (queryParamsString.length ? "?" + queryParamsString.join("&") : "");
  },

  getTallyWithAssignmentId: function (assignId) {
    const filteredModels = this.where({ tally_type: 1 });

    for (let i = 0; i < filteredModels.length; i++) {
      const model = filteredModels[i];

      if (model.attributes.assign_ids.indexOf(assignId) > -1) {
        // found the first one... don't think we want to return
        // more than one for now
        return model;
      }
    }
  },
});

module.exports = NakedTallyCollection;
