const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");

var Slot = Backbone.Model.extend({
  idAttribute: "slot_id",

  defaults: {},

  initialize: function (attributes, options) {},

  urlRoot: function () {
    return ApiConfig.getPrefix() + "/schedule/details";
  },
});

module.exports = Slot;
