const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var UserSettings = require("./../models/UserSettings.js");

var UserSettingsCollection = Backbone.Collection.extend({
  model: UserSettings,

  url: function () {
    // views will only ever be for the template that the current schedule is in
    return ApiConfig.getPrefix() + "/user/settings/maker";
  },
});

module.exports = UserSettingsCollection;
