var ModelMixins = {};

ModelMixins.Searchable = {
  _createSearchableAttributeString: function () {
    var searchableAttributes = this._getSearchableAttributes();
    this.attrString = "";
    this.rawAttrString = "";
    for (var i = 0; i < searchableAttributes.length; i++) {
      this.attrString +=
        searchableAttributes[i].name + "=" + (searchableAttributes[i].data ? searchableAttributes[i].data : "") + ";";
      this.rawAttrString += (searchableAttributes[i].data ? searchableAttributes[i].data : "") + ";";
    }
  },

  _getSearchableAttributes: function () {
    // check the columns for this view
    var listColumns = window.LbsAppData.AppContext.get("view").theme.data.listColumns;
    var ret = [];

    var that = this;
    _.each(listColumns, function (col) {
      switch (col) {
        case "Assignment":
          ret.push({
            name: "assignment",
            data: that.attributes.assign_display_name,
          });
          break;
        case "Assignment Type":
          ret.push({
            name: "assignment_type",
            data: window.LbsAppData.AssignmentTypes.get(that.attributes.assign_atype).get("name"),
          });
          break;
        case "Business Phone":
          ret.push({
            name: "phone_business",
            data: window.LbsAppData.Personnel?.get(that.attributes.emp_id)?.get("phone_business"),
          });
          break;
        case "Cell Phone":
          ret.push({
            name: "phone_cell",
            data: window.LbsAppData.Personnel?.get(that.attributes.emp_id)?.get("phone_cell"),
          });
          break;
        case "Date":
          ret.push({
            name: "date",
            data: that.attributes.slot_date
              ? moment(that.attributes.slot_date).format("dddd, LL")
              : moment(that.attributes.request_date).format("dddd, LL"),
          });
          break;
        case "Department":
          if (that.templateObj) {
            ret.push({
              name: "department",
              data: window.LbsAppData.Departments.get(that.templateObj.get("department_id")).get("name"),
            });
          }
          break;
        case "Email":
          ret.push({
            name: "email",
            data: that.personnelObj?.attributes.email,
          });
          break;
        case "Home Phone":
          ret.push({
            name: "phone_home",
            data: that.personnelObj?.attributes.phone_home,
          });
          break;
        case "Note":
          ret.push({
            name: "note",
            data: that.attributes.note,
          });
          break;
        case "Pager":
          ret.push({
            name: "pager",
            data: that.personnelObj?.attributes.pager,
          });
          break;
        case "Personnel":
          var display_text = that.getSlotDisplayObject().personnelText;
          ret.push({
            name: "date",
            data: display_text,
          });
          break;
        case "Personnel Type":
          if (that.attributes.emp_ptype) {
            ret.push({
              name: "personnel_type",
              data: window.LbsAppData.PersonnelTypes.get(that.attributes.emp_ptype).get("name"),
            });
          }
          break;
        case "Start Time":
          ret.push({
            name: "start_time",
            data: moment(that.attributes.start_time, "YYYY-MM-DDHH:mm:ss").format(
              window.LbsAppData.Helpers.Time.preferredTimeFormat("h:mma")
            ),
          });
          break;
        case "Stop Time":
          ret.push({
            name: "stop_time",
            data: moment(that.attributes.stop_time, "YYYY-MM-DDHH:mm:ss").format(
              window.LbsAppData.Helpers.Time.preferredTimeFormat("h:mma")
            ),
          });
          break;
        case "Template":
          ret.push({
            name: "template",
            data: that.attributes.template_desc,
          });
          break;
        case "Location(s)":
          ret.push({
            name: "locations",
            data: that.attributes.location_names.join(","),
          });
          break;
        case "Weekly Hours":
          ret.push({
            name: "weekly_hours",
            data: that.personnelObj?.attributes.weekly_hours,
          });
          break;
        case "Seniority Date":
          ret.push({
            name: "seniority_date",
            data: moment(that.personnelObj?.attributes.seniority_date).format("dddd, LL"),
          });
          break;
        case "UniqueID":
          ret.push({
            name: "unique_id",
            data: that.personnelObj?.attributes.unique_id,
          });
          break;
        case "Custom1":
          ret.push({
            name: "custom_1",
            data: that.personnelObj?.attributes.custom_1,
          });
          break;
        case "Custom2":
          ret.push({
            name: "custom_2",
            data: that.personnelObj?.attributes.custom_2,
          });
          break;
        case "Custom3":
          ret.push({
            name: "custom_3",
            data: that.personnelObj?.attributes.custom_3,
          });
          break;
        case "Custom4":
          ret.push({
            name: "custom_4",
            data: that.personnelObj?.attributes.custom_4,
          });
          break;
        case "Category":
          ret.push({
            name: "category",
            data: that.assignmentObj.get("structure_to_category_map")[that.attributes.assign_structure_id],
          });
          break;
        case "Title":
          ret.push({
            name: "title",
            data: that.personnelObj?.attributes.title,
          });
          break;
        case "Total Hours":
          ret.push({
            name: "total_hours",
            data: moment(that.get("stop_time")).diff(moment(that.get("start_time")), "hours", true),
          });
        default:
          break;
      }
    });

    return ret;
  },
};

module.exports = ModelMixins;
