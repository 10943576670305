"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var moment = require("moment");
var Promise = require("bluebird");
// collections
// components
// mixins
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
var SwapMixin = require("_lib/ui/mixins/SwapMixin.jsx");

var SwapRemoveDialog = React.createClass({
  mixins: [TouchMixin, SwapMixin],

  /* lifecycle methods */
  getInitialState: function () {
    var preData = this.props.dialogObject.data;
    var changePermission = window.LbsAppData.Helpers.Permissions.CanIChangeAnyoneElsesSchedule();
    // initial step depends on a few factors
    var initialStep = 1;
    if (preData) {
      initialStep = 4;
    } else {
      if (!changePermission) {
        // permissions only allow them to change themselves
        initialStep = 2;
      }
    }

    return {
      step: initialStep,
      initStep: initialStep,
    };
  },

  /* privates */
  _getTotalSteps: function () {
    return 4;
  },

  componentDidMount: function () {
    // send a tracking event
    if (!this.props.inline) {
      window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Swap: Remove");
    }
  },

  /* publics */
  stepForward: function (e) {
    // make sure we satisfy the requirements for each step
    var satisified = false;
    switch (this.state.step) {
      case 1:
        if (this.props.getData("selectedPersonnel").length) {
          satisified = true;
          if (this.props.getData("selectedPersonnel")[0].get("scheduled") == false) {
            satisified = false;
          }
        }
        break;
      case 2:
        // require a selected date
        satisified = _.keys(this.props.getData("selectedDates")).length > 0;
        break;
      case 3:
        // require a selected slot
        satisified = _.keys(this.props.getData("selectedSlots")).length > 0;
        break;
      case 4:
        break;
      default:
        break;
    }

    if (satisified) {
      // do something depending on the step (after the state update)
      var newStep = this.state.step + 1;
      this.setState({ step: newStep }, function () {
        switch (newStep) {
          default:
            if (this.props.onStepForward) {
              this.props.onStepForward(newStep);
            }
            break;
        }
      });

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Remove: Step", "Forward");
    } else {
      var message;
      if (this.state.step == 1) {
        message = "Please make a valid selection";
      } else if (this.state.step == 2) {
        message = "Please select a date";
      } else if (this.state.step == 3) {
        message = "You must select at least one slot";
      }

      window.LbsAppData.AppContext.openDialog({
        type: "message",
        message: message,
        timer: 1500,
      });
    }
  },
  stepBackward: function (e) {
    // if moving to the fetch slot step (3) then fetch slots again
    var newStep = this.state.step - 1;
    this.setState({ step: newStep }, function () {
      switch (newStep) {
        default:
          if (this.props.onStepBackward) {
            this.props.onStepBackward(newStep);
          }
          break;
      }
    });
    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Remove: Step", "Backward");
  },

  _getSelectedSlots: function () {
    var that = this;

    // group them by date
    var groupedSlots = {};
    _.each(this.props.getData("selectedSlots"), function (slot) {
      var groupingKey = slot.get("slot_date");
      if (_.has(groupedSlots, groupingKey)) {
        groupedSlots[groupingKey].push(slot);
      } else {
        groupedSlots[groupingKey] = [slot];
      }
    });

    var groupDivs = [];
    for (var key in groupedSlots) {
      var dateString = moment(key).format("ddd, ll");
      var slotDivs = [];

      _.each(groupedSlots[key], function (slot) {
        var actionText;
        /*
				if (slot.get('pending_emp_id') == 4) {
					actionText = <div><span className='uppercase'>Remove</span> <span className='large underline'>{slot.get('display_name')}</span></div>
				} else if (slot.get('emp_id') == 4) {
					actionText = <div><span className='uppercase'>Add</span> <span className='large underline'>{slot.get('pending_display_name')}</span></div>
				} else {
					// replace
					actionText = <div><span className='uppercase'>Replace</span> <span className='large underline'>{slot.get('display_name')}</span> with <span className='large underline'>{slot.get('pending_display_name')}</span></div>
				}*/
        actionText = (
          <div>
            <span className="uppercase">Remove</span>{" "}
            <span className="large underline">{slot.get("display_name")}</span>
          </div>
        );

        slotDivs.push(
          <div className="slot unclickable" key={slot.id}>
            <span className="text">
              <span className="large underline">
                {slot.get("assign_display_name") + " (" + slot.get("template_desc") + ")"}
              </span>
              {actionText}
            </span>
          </div>
        );
      });

      groupDivs.push(
        <div className="slot-group" key={key}>
          <div className="date">{dateString}</div>
          <div className="slots-container">{slotDivs}</div>
        </div>
      );
    }

    return groupDivs;
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      SwapRemove: true,
    });

    var content;
    if (!this.props.inline) {
      content = (
        <div className={classes}>
          <div className="position-reset">
            <div
              className="close"
              onClick={this.touchProxy.bind(this, this.props.close, [])}
              onTouchEnd={this.touchProxy.bind(this, this.props.close, [])}
            >
              <i className="fa fa-close" />
            </div>

            <div className="header-container">
              <div className="header">
                <div className="mobile-section-nav">
                  <div className="mobile-section-nav-tab active">Remove</div>
                </div>
              </div>
            </div>

            <div className="section-container">
              <div className={"section _" + this.state.step}>
                {this._renderStep1()}
                {this._renderStep2()}
                {this._renderStep3()}
                {this._renderStep4()}
              </div>
            </div>

            <div className="bottom-controls">
              <div className="position-reset">
                <div className="left-side">{this._getBottomLeftControl(this._getTotalSteps())}</div>
                <div className="right-side">{this._getBottomRightControl(this._getTotalSteps())}</div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      content = this._renderInline();
    }

    return content;
  },

  /* rendering functions */
  _renderStep1: function () {
    var personnelSelectable = this.props.widgetProxy("selectPersonnel");

    return (
      <div className="content">
        <div className="editables">{personnelSelectable}</div>
      </div>
    );
  },
  _renderStep2: function () {
    var personnelDisplayName = this.props.getData("selectedPersonnel").length
      ? this.props.getData("selectedPersonnel")[0].get("display_name")
      : "";
    var datePicker = this.props.widgetProxy("datePickerGeneric");

    return <div className="content">{datePicker}</div>;
  },
  _renderStep3: function () {
    var personnelDisplayName = this.props.getData("selectedPersonnel").length
      ? this.props.getData("selectedPersonnel")[0].get("display_name")
      : "";
    var slotList = this.props.widgetProxy("listSlots");

    return (
      <div className="content">
        <div className="details"></div>

        <div className="editables xpad">{slotList}</div>
      </div>
    );
  },
  _renderStep4: function () {
    // summary
    var swapSentences = this.props.widgetProxy("sentenceSwaps");

    return (
      <div className="content">
        <div className="details">{swapSentences}</div>
      </div>
    );
  },
  _renderInline: function () {
    return <div className="inline-container"></div>;
  },
});

module.exports = SwapRemoveDialog;
