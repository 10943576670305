import ConfigHelper from "@/_lib/data/ConfigHelper";

import useSlotCollection from "@/_lib/hooks/useSlotCollection";
import CalendarStage from "@/_lib/ui/modules/dialog/types/schedule_edits/swaps/move/CalendarStage";
import FooterControls from "@/_lib/ui/modules/dialog/types/schedule_edits/swaps/move/FooterControls";
import OptionsStage from "@/_lib/ui/modules/dialog/types/schedule_edits/swaps/move/OptionsStage";
import { DialogStage, SlotData, SlotTimeType } from "@/_lib/ui/modules/dialog/types/schedule_edits/swaps/move/types";
import WarningDialog from "@/_lib/ui/modules/dialog/types/warning/WarningDialog";
import { post } from "@/_lib/utils/fetch";
import { ParsedResponse } from "@/_lib/utils/responses";
import { isItemInView } from "@/_lib/utils/slotUtils";
import { AMERICAN_DATE_FORMAT, API_COMPACT_DATE_FORMAT, ISO_8601_DATE_FORMAT } from "@/_lib/utils/time";
import modalActions from "@/common/components/modal/actions";
import Modal from "@/common/components/modal/Modal";
import toastActions from "@/common/components/toast/actions";
import Toast, { ToastStatus } from "@/common/components/toast/Toast";
import { TriggerRedraw } from "@/common/utils";
import { MRC_DEPARTMENT_ID } from "@/viewer/data/constants";
import { ResponseData } from "@/viewer/types/domain/response";
import { MovePayload } from "@/viewer/types/requests";
import useAssignmentData from "@/viewer/ui/modules/common/hooks/useAssignmentData";
import useSettings from "@/viewer/ui/modules/common/hooks/useSettings";
import useSlotsById from "@/viewer/ui/modules/common/hooks/useSlotsById";
import useUIContext from "@/viewer/ui/modules/common/hooks/useUIContext";
import useViewData from "@/viewer/ui/modules/common/hooks/useViewData";
import { timezoneConverter } from "@/viewer/utils/timezones";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import { produce } from "immer";

import "./_styles.scss";
import { DateTime } from "luxon";
import React from "react";
import { useDispatch } from "react-redux";

interface MoveDialogProps {
  slotId: number | string;
}

const MoveDialog = (props: MoveDialogProps): JSX.Element => {
  const { slotId } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const lbsAppData = (window as any).LbsAppData;
  const settings = useSettings();
  const slots = useSlotsById(settings);
  const slot = slots[Number(slotId)];
  const viewData = useViewData(settings);
  const [uiContext] = useUIContext();
  const slotCollection = useSlotCollection();
  const dispatch = useDispatch();

  const assignment = useAssignmentData(settings).assignments.find(
    (assignment) => assignment.assignStructureId === slot?.assignCompactOrDisplayName
  );

  const isAdmin = lbsAppData.User.attributes.is_admin;

  const [slotData, setSlotData] = React.useState<SlotData>({
    selectedDate: DateTime.fromFormat(slot?.dateString ?? "", ISO_8601_DATE_FORMAT) ?? DateTime.now(),
    slotStartTime: {
      hours: slot?.startTime.getHours(),
      minutes: slot?.startTime.getMinutes(),
      date: slot?.startTime,
    },
    slotStopTime: {
      hours: slot?.stopTime.getHours(),
      minutes: slot?.stopTime.getMinutes(),
      date: slot?.stopTime,
    },
    slotCallOrder: slot?.callOrder,
    slotNote: slot?.note ?? "",
    addDemand: false,
    slotTimeType: SlotTimeType.Default,
    assignStructureId: slot?.assignStructureId,
    templateId: slot?.templateId,
    dateString: slot?.dateString,
    assignCompactOrDisplayName: slot?.assignCompactOrDisplayName,
    compactOrDisplayName: slot?.compactOrDisplayName,
    reasonName: slot?.reasonName,
    slotDate: slot?.date,
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [dialogStage, setDialogStage] = React.useState<number>(DialogStage.Start);
  const [isWarningOpen, setIsWarningOpen] = React.useState<boolean>(false);
  const [warning, setWarning] = React.useState<{ date: string; message: string }>({ date: "", message: "" });
  const [warningPayload, setWarningPayload] = React.useState<MovePayload>();

  React.useEffect(() => {
    let template_id: number | "" | null = slot?.templateId;
    let assign_structure_id: number | "" | null = slot?.assignStructureId;

    // If the slot is MRC, we need to find the template id that it is mapped to
    if (slot) {
      if (!assignment) {
        dispatch(
          toastActions.open(
            <Toast status={ToastStatus.Error}>
              <span>{"No assignment found"}</span>
            </Toast>
          )
        );
      } else {
        if (slot.departmentId === MRC_DEPARTMENT_ID) {
          Object.entries(assignment.templateToAssignIdMap).find(([key, value]) => {
            if (value === slot.assignId) {
              template_id = Number(key);

              return true;
            }
          });
        } else if (assignment.isMapped) {
          assign_structure_id = slot.parentAssignStructureId;
        }

        setSlotData(
          produce(slotData, (draft) => {
            draft.templateId = template_id;
            draft.assignStructureId = assign_structure_id;
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slot, assignment]);

  if (!slot) {
    dispatch(modalActions.close());
    return <></>;
  }

  if (!assignment) {
    dispatch(
      toastActions.open(
        <Toast status={ToastStatus.Error}>
          <span>{"No assignment found"}</span>
        </Toast>
      )
    );
    dispatch(modalActions.close());
    return <></>;
  }

  const getStageContent = (): JSX.Element => {
    if (dialogStage === DialogStage.Start) {
      return <CalendarStage slotData={slotData} setSlotData={setSlotData} />;
    }

    if (dialogStage === DialogStage.Last && assignment) {
      return <OptionsStage slotData={slotData} setSlotData={setSlotData} isAdmin={isAdmin} assignment={assignment} />;
    }

    return <>Invalid Stage</>;
  };

  const handleWarnings = (payload: MovePayload) => {
    setWarningPayload(payload);
    setIsWarningOpen(true);
  };

  const handleErrors = (data: ResponseData | null) => {
    if (data?.errors?.length) {
      dispatch(toastActions.open(<Toast status={ToastStatus.Error}>{data.errors[0].message}</Toast>));
    } else if (!data?.warnings?.length) {
      if (data?.warnings?.length) {
        dispatch(toastActions.open(<Toast status={ToastStatus.Error}>{data?.warnings?.[0].message}</Toast>));
      }
    }
  };

  const handleSuccess = async (data: ResponseData | null) => {
    const movedSlot = data?.slots?.[0];

    // Check if slot is in currently displayed view
    if (movedSlot) {
      const isInView = isItemInView(movedSlot, viewData, uiContext, true);

      if (isInView) {
        slotCollection.add(movedSlot);
      }

      // delete old slot
      const url = `${ConfigHelper.host_lbapi}/schedule/update`;
      let response: ParsedResponse<ResponseData>;

      try {
        response = await post<ResponseData>(url, [{ type: "clear", slot_id: slot.id }]);
      } catch (_e) {
        dispatch(toastActions.open(<Toast status={ToastStatus.Error}>{"A network error has occurred"}</Toast>));

        return;
      }

      if (response.error) {
        dispatch(
          toastActions.open(
            <Toast status={ToastStatus.Error}>
              {response.error.message || "An unexpected error has occurred. Please refresh and try again"}
            </Toast>
          )
        );
      } else {
        slotCollection.remove(slotCollection.where({ slot_id: slot.id }));
        dispatch(toastActions.open(<Toast status={ToastStatus.Success}>Slot was was successfully moved</Toast>));
        dispatch(modalActions.close());
      }

      TriggerRedraw();
    }
  };

  const getWarningContent = (): JSX.Element => {
    return (
      <Box>
        <Typography>
          {DateTime.fromFormat(warning.date, API_COMPACT_DATE_FORMAT).toFormat(AMERICAN_DATE_FORMAT)}
        </Typography>
        <Divider style={{ marginBottom: "5px" }} />
        <Typography>{warning.message}</Typography>
      </Box>
    );
  };

  const closeWarning = () => {
    setIsWarningOpen(false);
  };

  const preparePayload = (assignStructureId: number | "" | null, templateId: number | "" | null): MovePayload => {
    const startDate = DateTime.fromObject({
      year: slotData.selectedDate.year,
      month: slotData.selectedDate.month,
      day: slotData.selectedDate.day,
      hour: slotData.slotStartTime.hours,
      minute: slotData.slotStartTime.minutes,
    });

    const stopDate = DateTime.fromObject({
      year: slotData.selectedDate.year,
      month: slotData.selectedDate.month,
      day: slotData.selectedDate.day,
      hour: slotData.slotStopTime.hours,
      minute: slotData.slotStopTime.minutes,
    });

    const convertedStartDate = timezoneConverter(startDate.toString(), true);
    const convertedStopDate = timezoneConverter(stopDate.toString(), true);

    if (!convertedStartDate || !convertedStopDate) return {} as MovePayload;

    const startDateString = DateTime.fromJSDate(convertedStartDate).toISO({ includeOffset: false });
    const stopDateString = DateTime.fromJSDate(convertedStopDate).toISO({ includeOffset: false });

    return {
      assign_id: slot.assignId,
      assign_name: slot.assignCompactOrDisplayName,
      assign_structure_id: assignStructureId,
      auto_add_demand: slotData.addDemand,
      call_order: slotData.slotCallOrder,
      date: startDateString.split("T")[0],
      emp_id: slot.empId,
      end_date: slotData.slotTimeType === SlotTimeType.Custom ? stopDateString : null,
      note: slotData.slotNote,
      start_date: slotData.slotTimeType === SlotTimeType.Custom ? startDateString : null,
      slot_id: slot.id,
      template_id: templateId,
      type: "fill",
    };
  };

  const forceSubmit = async () => {
    try {
      const url = `${ConfigHelper.host_lbapi}/schedule/update`;

      setIsLoading(true);

      const response = await post<ResponseData>(url, [{ ...warningPayload, allow_force_save: true }]);

      handleErrors(response.data);
      await handleSuccess(response.data);
    } catch (e) {
      dispatch(
        toastActions.open(
          <Toast status={ToastStatus.Error}>{"A network error has occurred. Please refresh and try again"}</Toast>
        )
      );
    } finally {
      setIsLoading(false);
      closeWarning();
    }
  };

  const getWarningActions = (): JSX.Element => (
    <Box display={"flex"} sx={{ flexDirection: "row", justifyContent: "space-between" }}>
      <Button onClick={closeWarning}>Cancel</Button>
      <Button className={"footerContainer-button__submit"} onClick={forceSubmit} disabled={isLoading}>
        {isLoading ? (
          <span className="fa fa-fw fa-spin fa-spinner" />
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>Allow</div>
        )}
      </Button>
    </Box>
  );

  const performSubmit = async (payload: MovePayload) => {
    const url = `${ConfigHelper.host_lbapi}/schedule/update`;

    setIsLoading(true);

    try {
      const response = await post<ResponseData>(url, [payload]);

      if (response.data?.warnings?.length) {
        setWarning(response.data.warnings[0]);
        handleWarnings(payload);

        return;
      }

      handleErrors(response.data);
      await handleSuccess(response.data);
    } catch (e) {
      dispatch(
        toastActions.open(
          <Toast status={ToastStatus.Error}>{"A network error has occurred. Please refresh and try again"}</Toast>
        )
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    const slotStartDate = DateTime.fromObject({
      year: slotData.selectedDate.year,
      month: slotData.selectedDate.month,
      day: slotData.selectedDate.day,
      hour: slotData.slotStartTime.hours,
      minute: slotData.slotStartTime.minutes,
    });

    const slotStopDate = DateTime.fromObject({
      year: slotData.selectedDate.year,
      month: slotData.selectedDate.month,
      day: slotData.selectedDate.day,
      hour: slotData.slotStopTime.hours,
      minute: slotData.slotStopTime.minutes,
    });

    // if the stop date is less than the start date, add a day to the stop date
    if (slotStopDate < slotStartDate) {
      slotStopDate.set({ day: slotStopDate.day + 1 });
    }

    let template_id: number | "" | null | undefined = slot.templateId;
    let assign_structure_id: number | "" | null = slot.assignStructureId;

    // If the slot is MRC, we need to find the template id that it is mapped to
    if (assignment) {
      if (slot.departmentId === MRC_DEPARTMENT_ID) {
        Object.entries(assignment.templateToAssignIdMap).find(([key, value]) => {
          if (value === slot.assignId) {
            template_id = Number(key);

            return true;
          }
        });
      } else if (assignment.isMapped) {
        assign_structure_id = slot.parentAssignStructureId;
      }
    } else {
      return dispatch(
        toastActions.open(
          <Toast status={ToastStatus.Error}>
            <span>{"No assignment found"}</span>
          </Toast>
        )
      );
    }

    const payload = preparePayload(assign_structure_id, template_id);

    try {
      await performSubmit(payload);
    } catch (_e) {
      dispatch(
        toastActions.open(
          <Toast status={ToastStatus.Error}>
            <span>{"A network error has occurred"}</span>
          </Toast>
        )
      );
    }
  };

  return (
    <Modal title={"Move"} className={"moveDialog"}>
      <Box width={"350px"} height={"500px"} display={"flex"} sx={{ flexDirection: "column", alignItems: "center" }}>
        <WarningDialog actions={getWarningActions()} content={getWarningContent()} isOpen={isWarningOpen} />
        {getStageContent()}
        <FooterControls
          dialogStage={dialogStage}
          setDialogStage={setDialogStage}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          slotData={slotData}
        />
      </Box>
    </Modal>
  );
};

export default MoveDialog;
