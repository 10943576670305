"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var moment = require("moment");
var Promise = require("bluebird");
// collections
// components
// mixins
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
var SwapMixin = require("_lib/ui/mixins/SwapMixin.jsx");
const { LDFlagEnums } = require("@/_lib/constants/LDFlagEnums");

var SwapReplaceDialog = React.createClass({
  mixins: [TouchMixin, SwapMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {
      step: 1,
      initStep: 1,
    };
  },
  componentDidMount: function () {
    // send a tracking event
    if (!this.props.inline) {
      window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Swap: Replace");
    }
  },

  /* privates */
  _getTotalSteps: function () {
    return 2;
  },

  /* publics */
  stepForward: function (e) {
    // make sure we satisfy the requirements for each step
    var satisfied = false;
    switch (this.state.step) {
      case 1:
        satisfied =
          this.props.getData("selectedAssignments").length || this.props.getData("selectedPersonnel").length
            ? true
            : false;
        break;
      case 2:
        break;
      default:
        break;
    }

    if (satisfied) {
      // do something depending on the step (after the state update)
      var newStep = this.state.step + 1;
      this.setState({ step: newStep }, function () {
        switch (newStep) {
          case 2:
            break;
          default:
            break;
        }
      });

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Replace: Step", "Forward");
    } else {
      var message;
      if (this.state.step == 1) {
        message = "You didn't edit anything";
      }

      window.LbsAppData.AppContext.openDialog({
        type: "message",
        message: message,
        timer: 1500,
      });
    }
  },
  stepBackward: function (e) {
    this.setState({ step: this.state.step - 1 });
    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Replace: Step", "Backward");
  },

  showDemandAdmin: function () {
    // can change if:
    // 1. Flag is enabled
    if (this.props?.flags[LDFlagEnums.Lv6488ShowAddDemandInReplace]) {
      return true;
    }
    return false;
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      SwapReplace: true,
    });

    var content;
    if (!this.props.inline) {
      content = (
        <div className={classes}>
          <div className="position-reset">
            <div
              className="close"
              onClick={this.touchProxy.bind(this, this.props.close, [])}
              onTouchEnd={this.touchProxy.bind(this, this.props.close, [])}
            >
              <i className="fa fa-close" />
            </div>

            <div className="header-container">
              <div className="header">
                <div className="mobile-section-nav">
                  <div className="mobile-section-nav-tab active">Replace</div>
                </div>
              </div>
            </div>

            <div className="section-container">
              <div className={"section _" + this.state.step}>
                {this._renderStep3()}
                {this._renderStep4()}
              </div>
            </div>

            <div className="bottom-controls">
              <div className="position-reset">
                <div className="left-side">{this._getBottomLeftControl(this._getTotalSteps())}</div>
                <div className="right-side">{this._getBottomRightControl(this._getTotalSteps())}</div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      content = this._renderInline();
    }

    return content;
  },

  _renderStep3: function () {
    var dateSelectable = this.props.widgetProxy("selectDate");
    var assignmentSelectable = this.props.widgetProxy("selectAssignment");
    var personnelSelectable = this.props.widgetProxy("selectPersonnel");
    var step3 = (
      <div className="content">
        <div className="editables ypad">
          {dateSelectable}
          {personnelSelectable}
          {assignmentSelectable}
        </div>
      </div>
    );

    return step3;
  },

  _renderStep4: function () {
    // summary
    var swapSentence = this.props.widgetProxy("sentenceSwap");
    // loa reason if applicable
    var loaElement = this.props.widgetProxy("selectLoaReason");
    // note if applicable
    var noteElement = this.props.widgetProxy("addNote");
    // locations if applicable
    var locElement = this.props.widgetProxy("selectLocations");
    // auto add demand only is for fills, and admins
    var demandElement = this.props.widgetProxy("addDemand");

    var step4 = (
      <div className="content">
        <div className="details">
          <div className="sentence-container">{swapSentence}</div>
        </div>
        <div className="editables">
          {noteElement}
          {loaElement}
          {locElement}
          {this.showDemandAdmin() && demandElement}
        </div>
      </div>
    );

    return step4;
  },

  _renderInline: function () {
    // "step 1"
    var assignmentSelectable = this.props.widgetProxy("selectAssignment");
    var personnelSelectable = this.props.widgetProxy("selectPersonnel");
    // "step 2"
    var noteElement = this.props.widgetProxy("addNote");
    // loa reason if applicable
    var loaElement = this.props.widgetProxy("selectLoaReason");
    // locations if applicable
    var locElement = this.props.widgetProxy("selectLocations");
    // auto add demand only is for fills, and admins
    var demandElement = this.props.widgetProxy("addDemand");

    return (
      <div className="inline-container">
        <div className="editables">
          {personnelSelectable}
          {assignmentSelectable}

          <div className="spacer bordered"></div>
          {noteElement}
          {loaElement}
          {locElement}
          {this.showDemandAdmin() && demandElement}
        </div>
      </div>
    );
  },
});

module.exports = SwapReplaceDialog;
