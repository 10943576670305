type ParseArgs = [text: string, reviver?: (opts: any) => any];

function parse(...args: ParseArgs): any {
  const [text] = args;

  if (text) {
    try {
      return JSON.parse(text);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`[JSON Parser]: ${err}`);

      return null;
    }
  }

  return null;
}

const SafeJSON = {
  parse,
};

module.exports = SafeJSON;
