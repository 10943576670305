import React from "react";
import { Form, Field, FieldProps, useFormikContext } from "formik";
import { Box, Grid } from "@material-ui/core";

import { isNumeric } from "@/common/utils";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { FormValues } from "./EditTallyCountModal";
import useFormData from "./useFormData";

import Button from "@/common/components/button/Button";
import Spinner from "@/common/components/spinner/Spinner";
import { RenderTextField } from "@/common/components/fields";
import styles from "./styles.module.scss";

interface Props {
  slotQuest: SlotOrRequest;
}

const MIN_TALLY_AMOUNT = -1000;
const MAX_TALLY_AMOUNT = 1000;

export default function Content({ slotQuest }: Props): React.ReactElement {
  const { values } = useFormikContext<FormValues>();
  const { loading, handleResetTally } = useFormData(slotQuest);

  return (
    <Spinner spinning={loading} legacy={false}>
      <div className={styles.content}>
        <Form id="edit-tally-count-form">
          <Grid container spacing={2}>
            {values.tallies.map((tally, index) => (
              <Grid key={tally.id} item xs={12}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item xs={4}>
                    {tally.name}
                    <Box fontSize={12} color="text.primary">
                      ({tally.templateName})
                    </Box>
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={7}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={7}>
                        <Field name={`tallies[${index}].value`}>
                          {({ field, form, meta }: FieldProps) => (
                            <RenderTextField
                              field={field}
                              form={form}
                              meta={meta}
                              size="small"
                              formatter={(value: string) => {
                                const isValid = /^(\s*|-|-?\d+(\.\d{0,4})?|\.?\d{1,2})$/.test(value);
                                const isNumber = isNumeric(value);
                                const isGreaterOrEqualToMin = isNumber ? parseFloat(value) >= MIN_TALLY_AMOUNT : true;
                                const isLessOrEqualToMax = isNumber ? parseFloat(value) <= MAX_TALLY_AMOUNT : true;

                                return isValid && isGreaterOrEqualToMin && isLessOrEqualToMax;
                              }}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={4}>
                        <Button variant="text" color="primary" onClick={() => handleResetTally(tally)}>
                          Reset to default
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Form>
      </div>
    </Spinner>
  );
}
