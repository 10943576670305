import { Collection } from "backbone";
import connectedPropertyHookFactory from "@/viewer/ui/modules/common/hooks/connectedPropertyHookFactory";
import { FilterMap } from "@/viewer/ui/modules/common/types/context";

const getFilteredTemplates = (): FilterMap => {
  const { LbsAppData } = window as any;
  // Ported from Assignment._checkAvailability

  if (!LbsAppData.Templates) return {};

  const Templates: Collection = LbsAppData.Templates;
  const filteredTemplates: FilterMap = {};
  Templates.forEach((template) => {
    if (!template.attributes.available || template.attributes.filtered) {
      filteredTemplates[template.id] = true;
    }
  });

  return filteredTemplates;
};

export default connectedPropertyHookFactory({
  loader: getFilteredTemplates,
  getListeners: () => {
    const { LbsAppData } = window as any;
    return [
      [LbsAppData.Templates, "change:filtered"],
      [LbsAppData.Templates, "change:available"],
    ];
  },
});
