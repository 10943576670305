const Backbone = require("backbone");
var _ = require("underscore");
var moment = require("moment");

var StaghornConfig = require("_lib/data/StaghornConfig.js");

var ReportDefinition = require("./ReportDefinition.js");
var ReportResultCollection = require("./../collections/ReportResultCollection.js");
var TableObject = require("./TableObject.js");

var TallyCollection = require("_lib/data/collections/NakedTallyCollection.js");
var PersonnelCollection = require("_lib/data/collections/NakedPersonnelCollection.js");

var Report = Backbone.Model.extend({
  idAttribute: "report_id",

  defaults: {
    last_shadow_change: undefined,
  },

  initialize: function (attributes, options) {
    // results collection
    this.results = new ReportResultCollection([]);
  },

  url: function () {
    return StaghornConfig.getPrefix() + "/report";
  },

  parse: function (response) {
    this.lib = {};

    // create the objects stored in the report's _lib
    var that = this;
    //console.log(response[0]);

    // LOOK AT THIS!
    _.each(response.results[0]._lib, function (libCollection, key) {
      switch (key) {
        case "tallies":
          that.lib.TallyCollection = new TallyCollection(libCollection);
          break;
        case "personnel":
          that.lib.PersonnelCollection = new PersonnelCollection(libCollection);
          break;
        default:
          break;
      }
    });

    // report definition object
    //this.def = new ReportDefinition(response._def);
    this.results = new ReportResultCollection(response.results, {
      _definition: this.attributes._definition,
      lib: this.lib, // library proxy
    });

    // if we have previously saved results, then carry over the selections
    if (this.prevResults) {
      this.selectObjects(this.prevResults);
    }

    return response;
  },

  /* publics */
  snapshot: function (report) {
    // copy over report state
    this.prevResults = report.results;
  },
  compress: function () {
    // hide all the unselected columns for each result set
    this.results.each(function (r, i) {
      r.hideUnselected();
      r.trigger("grid_change");
    });

    // refresh the contextual items
    //window.LbsAppData.AppContext.refreshContext();
  },
  expand: function () {
    this.results.each(function (r, i) {
      r.showHidden();
      r.trigger("grid_change");
    });

    // refresh the contextual items
    //window.LbsAppData.AppContext.refreshContext();
  },

  getAxisContext: function (axis) {
    var title;
    switch (this.get("_definition").properties.table[axis]) {
      case "tally":
        var count = this.get("_definition").tallies.length;
        title = count + (count > 1 ? " Tallies" : " Tally");
        break;
      case "personnel":
        var count = this.get("_definition").personnel.length;
        title = count + " Personnel";
        break;
      case "date":
        var reportProps = this.get("_definition").properties;
        var dates = window.LbsAppData.Helpers.Reports._translateDates(
          reportProps.data.start_date,
          reportProps.data.end_date
        );

        var count = dates.stop.diff(dates.start, "days") + 1;
        title = count + (count > 1 ? " Dates" : " Date");
        break;
      default:
        break;
    }

    return title;
  },

  clearSelectedObjects: function () {
    this.results.each(function (r) {
      r.clearSelections();
    });

    // track an event
    window.LbsAppData.Helpers.Analytics.sendEvent("Clear: Selection");
  },
  selectObjects: function (results) {
    var that = this;
    results.each(function (r, i) {
      that.results.at(i).select(r.getSelections(), true);
    });
  },

  selectSiblings: function (obj) {
    var curResultId = window.LbsAppData.AppContext.get("focused_result_index");
    this.results.each(function (r, i) {
      if (i == curResultId) {
        // skip the current report (it's already been selected)
        return;
      }

      // select the object
      r.select([obj]);
    });
  },

  hasSelection: function () {
    for (var i = 0; i < this.results.length; i++) {
      if (this.results.at(i).hasSelections()) {
        return true;
      }
    }

    return false;
  },

  selectObject: function (index) {
    this.selectedObjects[index] = true;
  },
  unselectObject: function (index) {
    delete this.selectedObjects[index];
  },
  setObject: function (index, value) {
    if (value) {
      this.selectObject(index);
    } else {
      this.unselectObject(index);
    }
  },

  convertToCsv: function () {
    var csv = new CsvProcessor(this.results);
    return csv.run();
  },

  /* privates */
});

module.exports = Report;

function CsvProcessor(report) {
  // if there isn't a _definition -- bail
  if (!report.attributes._definition) {
    return "";
  }

  this._gblConfig = {};
  this._gblConfig.showTotals = report.attributes._definition.properties.table.show_totals;

  this._reportJSON = report;
  this._reportCSV = [];

  this.run = function run() {
    var _indexedLib = {};
    var _dataTarget = undefined;

    this._getHeader = function _getHeader(axis, id, isDim) {
      var objTarget = !isDim ? _dataTarget[axis].obj : _dataTarget[axis].dim;

      switch (objTarget) {
        case "personnel":
          return '"' + _indexedLib.personnel[id].display_name + '"';
        case "tally":
          return '"' + _indexedLib.tallies[id].tally_name + '"';
        case "date":
          // just send back the id
          return '"' + id + '"';
        case "dow":
        case "dow":
          // just send back the id
          return moment().day(id).format("dd");
        default:
          break;
      }
    };
    this._getDimIds = function _getDimIds(type) {
      switch (type) {
        case "dow":
          return [1, 2, 3, 4, 5, 6, 0];
        default:
          // 'none'
          return [];
      }
    };

    // row index -- this gets a bit confusing with the header counts when you start having splits so we're
    // ripping it out of the loops
    var rowIndex = 0;
    // results loop
    for (var z = 0; z < this._reportJSON.models.length; z++) {
      _dataTarget = this._reportJSON.models[z].get("result");
      _indexedLib.personnel = _.indexBy(this._reportJSON.models[z].get("_lib").personnel, "emp_id");
      _indexedLib.tallies = _.indexBy(this._reportJSON.models[z].get("_lib").tallies, "tally_id");

      // title header
      this._reportCSV.push([_dataTarget.title]);
      ++rowIndex;

      // top header
      var xDimIds = this._getDimIds(_dataTarget.x.dim);
      if (!xDimIds.length) {
        // one title header
        this._reportCSV.push([""]);
        for (var i = 0; i < _dataTarget.x.ids.length; i++) {
          var xId = _dataTarget.x.ids[i];
          this._reportCSV[rowIndex].push(this._getHeader("x", xId));
        }
        if (this._gblConfig.showTotals) {
          this._reportCSV[rowIndex].push("Total");
        }
      } else {
        // have an x_dim -- two headers

        // first header
        this._reportCSV.push([""]);
        for (var i_0 = 0; i_0 < _dataTarget.x.ids.length; i_0++) {
          var xId = _dataTarget.x.ids[i_0];
          this._reportCSV[rowIndex].push(this._getHeader("x", xId));
          // push an empty value for each dimension
          for (var i_1 = 0; i_1 < xDimIds.length; i_1++) {
            this._reportCSV[rowIndex].push("");
          }
        }
        // totals?
        if (this._gblConfig.showTotals) {
          this._reportCSV[rowIndex].push("Total");
          // push an empty value for each dimension
          for (var i_2 = 0; i_2 < xDimIds.length; i_2++) {
            this._reportCSV[rowIndex].push("");
          }
        }
        ++rowIndex;

        // second header
        this._reportCSV.push([""]);
        for (var i_3 = 0; i_3 < _dataTarget.x.ids.length; i_3++) {
          // push the '*' first
          this._reportCSV[rowIndex].push("*");

          for (var i_4 = 0; i_4 < xDimIds.length; i_4++) {
            var xDimObjId = xDimIds[i_4];
            this._reportCSV[rowIndex].push(this._getHeader("x", xDimObjId, true));
          }
        }
        // totals?
        if (this._gblConfig.showTotals) {
          // push the '*' first
          this._reportCSV[rowIndex].push("*");
          // push the rest
          for (var i_5 = 0; i_5 < xDimIds.length; i_5++) {
            var xDimObjId = xDimIds[i_5];
            this._reportCSV[rowIndex].push(this._getHeader("x", xDimObjId, true));
          }
        }
      }
      ++rowIndex;

      // data rows
      for (var j = 0; j < _dataTarget.y.ids.length; j++) {
        // row per y value
        var yObjId = _dataTarget.y.ids[j];
        this._reportCSV.push([this._getHeader("y", yObjId)]);

        // col per x value
        for (var k = 0; k < _dataTarget.x.ids.length; k++) {
          var xObjId = _dataTarget.x.ids[k];
          // check for an x_dim
          var xDimIds = this._getDimIds(_dataTarget.x.dim);
          if (!xDimIds.length) {
            // no dimension
            var key = _assembleKey(xObjId, yObjId);
            var val = _dataTarget.data[key];

            this._reportCSV[rowIndex].push(val);
          } else {
            // has dimension

            // push the '*' first
            var totalsKey = _assembleKey(xObjId, yObjId);
            var totalsVal = _dataTarget.data[totalsKey];
            this._reportCSV[rowIndex].push(totalsVal);

            for (var k_0 = 0; k_0 < xDimIds.length; k_0++) {
              var xDimObjId = xDimIds[k_0];
              var xObjIdPlusDimId = xObjId + "!" + xDimObjId;
              var key = _assembleKey(xObjIdPlusDimId, yObjId);
              var val = _dataTarget.data[key];

              this._reportCSV[rowIndex].push(val);
            }
          }
        }

        // possible totals col
        if (this._gblConfig.showTotals) {
          // check for an x_dim
          var xDimIds = this._getDimIds(_dataTarget.x.dim);
          if (!xDimIds.length) {
            var key = _assembleKey("", yObjId);
            var val = _dataTarget.data[key];

            this._reportCSV[rowIndex].push(val);
          } else {
            // push the '*' first
            var totalsKey = _assembleKey("", yObjId);
            var totalsVal = _dataTarget.data[totalsKey];
            this._reportCSV[rowIndex].push(totalsVal);

            for (var k_0 = 0; k_0 < xDimIds.length; k_0++) {
              var xDimObjId = "!" + xDimIds[k_0];
              var key = _assembleKey(xDimObjId, yObjId);
              var val = _dataTarget.data[key];

              this._reportCSV[rowIndex].push(val);
            }
          }
        }

        ++rowIndex;

        // y dimension?
        var yDimIds = this._getDimIds(_dataTarget.y.dim);
        if (yDimIds.length) {
          // do it all again yDimIds.length times
          for (var l = 0; l < yDimIds.length; l++) {
            // row per y value
            var yDimObjId = yDimIds[l];
            this._reportCSV.push([this._getHeader("y", yDimObjId, true)]);

            var yObjIdPlusDimId = yObjId + "!" + yDimObjId;

            // col per x value
            for (var m = 0; m < _dataTarget.x.ids.length; m++) {
              var xObjId = _dataTarget.x.ids[m];
              var key = _assembleKey(xObjId, yObjIdPlusDimId);
              var val = _dataTarget.data[key];

              this._reportCSV[rowIndex].push(val);
            }

            // possible totals col
            if (this._gblConfig.showTotals) {
              var key = _assembleKey("", yObjIdPlusDimId);
              var val = _dataTarget.data[key];

              this._reportCSV[rowIndex].push(val);
            }

            ++rowIndex;
          }
        }
      }

      // possible totals row
      if (this._gblConfig.showTotals) {
        this._reportCSV.push(["Total"]);

        // col per x value
        for (var k = 0; k < _dataTarget.x.ids.length; k++) {
          var xObjId = _dataTarget.x.ids[k];
          // check for an x_dim
          var xDimIds = this._getDimIds(_dataTarget.x.dim);
          if (!xDimIds.length) {
            // no dimension
            var key = _assembleKey(xObjId, "");
            var val = _dataTarget.data[key];

            this._reportCSV[rowIndex].push(val);
          } else {
            // has dimension

            // push the '*' first
            var totalsKey = _assembleKey(xObjId, "");
            var totalsVal = _dataTarget.data[totalsKey];
            this._reportCSV[rowIndex].push(totalsVal);

            for (var k_0 = 0; k_0 < xDimIds.length; k_0++) {
              var xDimObjId = xDimIds[k_0];
              var xObjIdPlusDimId = xObjId + "!" + xDimObjId;
              var key = _assembleKey(xObjIdPlusDimId, "");
              var val = _dataTarget.data[key];

              this._reportCSV[rowIndex].push(val);
            }
          }
        }

        // possible totals col
        if (this._gblConfig.showTotals) {
          // check for an x_dim
          var xDimIds = this._getDimIds(_dataTarget.x.dim);
          if (!xDimIds.length) {
            var key = _assembleKey("", "");
            var val = _dataTarget.data[key];

            this._reportCSV[rowIndex].push(val);
          } else {
            // push the '*' first
            var totalsKey = _assembleKey("", "");
            var totalsVal = _dataTarget.data[totalsKey];
            this._reportCSV[rowIndex].push(totalsVal);

            for (var k_0 = 0; k_0 < xDimIds.length; k_0++) {
              var xDimObjId = "!" + xDimIds[k_0];
              var key = _assembleKey(xDimObjId, "");
              var val = _dataTarget.data[key];

              this._reportCSV[rowIndex].push(val);
            }
          }
        }

        ++rowIndex;

        // y dimension?
        var yDimIds = this._getDimIds(_dataTarget.y.dim);
        if (yDimIds.length) {
          // do it all again yDimIds.length times
          for (var l = 0; l < yDimIds.length; l++) {
            // row per y value
            var yDimObjId = yDimIds[l];
            this._reportCSV.push([this._getHeader("y", yDimObjId, true)]);

            // col per x value
            for (var m = 0; m < _dataTarget.x.ids.length; m++) {
              var xObjId = _dataTarget.x.ids[m];
              var key = _assembleKey(xObjId, "!" + yDimObjId);
              var val = _dataTarget.data[key];

              this._reportCSV[rowIndex].push(val);
            }

            // possible totals col
            if (this._gblConfig.showTotals) {
              var key = _assembleKey("", "!" + yDimObjId);
              var val = _dataTarget.data[key];

              this._reportCSV[rowIndex].push(val);
            }

            ++rowIndex;
          }
        }
      }

      // push an empty row
      this._reportCSV.push([]);
      ++rowIndex;
    }

    return _convertToString(this._reportCSV);
  };
}

function _assembleKey(x, y) {
  return x + "|" + y;
}
function _convertToString(inArray) {
  // outer joined with \n
  // inner joined with ,
  var ret = _.map(inArray, function (row) {
    return row.join(",");
  }).join("\n");

  return ret;
}
