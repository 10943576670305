import React from "react";
import { useDispatch } from "react-redux";
import { FormikValues, useFormik } from "formik";
import * as Yup from "yup";

import userResource, { PasswordUpdateResponse } from "@/resources/userResource/user.resource";
import toastActions from "@/common/components/toast/actions";
import Toast, { ToastStatus } from "@/common/components/toast/Toast";

import { ResourceUtils } from "@/resources/root/root.resource";

import "../_styles.scss";

const PasswordForm = (): JSX.Element => {
  const dispatch = useDispatch();

  const handlePasswordChange = async (values: FormikValues) => {
    const result = await userResource.updateUserPassword({
      password: values.currentPassword,
      new_password: values.passwordNew,
      new_password_confirm: values.passwordNewConfirm,
    });

    if (Reflect.has(result, "status")) {
      const coercedResult = result as PasswordUpdateResponse;
      const { status } = coercedResult;

      if (status && ResourceUtils.responseIntegerIsOk(status)) {
        dispatch(
          toastActions.open(
            <Toast status={ToastStatus.Success}>
              <span>{"Password successfully updated."}</span>
            </Toast>
          )
        );

        return;
      }

      dispatch(
        toastActions.open(
          <Toast status={ToastStatus.Error}>
            <span>{coercedResult?.message ?? "Could not update password."}</span>
          </Toast>
        )
      );

      return;
    }

    // network error
    dispatch(
      toastActions.open(
        <Toast status={ToastStatus.Error}>
          <span>{(result as Error)?.message ?? "Could not reach server."}</span>
        </Toast>
      )
    );
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      passwordNew: "",
      passwordNewConfirm: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().max(30, "Must be 30 characters or less").required("Required"),
      passwordNew: Yup.string()
        .min(6, "Must be at least 6 characters")
        .max(30, "Must be 30 characters or less")
        .oneOf([Yup.ref("passwordNewConfirm")], "Passwords do not match")
        .required("Required"),
      passwordNewConfirm: Yup.string()
        .min(6, "Must be at least 6 characters")
        .max(30, "Must be 30 characters or less")
        .oneOf([Yup.ref("passwordNew")], "Passwords do not match")
        .required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await handlePasswordChange(values);
      resetForm();
    },
  });

  return (
    <form className={"body-form"} onSubmit={formik.handleSubmit}>
      <label htmlFor={"currentPassword"}>Current Password:</label>
      <input
        name={"currentPassword"}
        type={"password"}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.currentPassword}
      />
      {formik.touched.currentPassword && formik.errors.currentPassword ? (
        <div className={"error-text"}>{formik.errors.currentPassword}</div>
      ) : null}
      <label htmlFor={"passwordNew"}>New Password:</label>
      <input
        name={"passwordNew"}
        type={"password"}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.passwordNew}
      />
      {formik.touched.passwordNew && formik.errors.passwordNew ? (
        <div className={"error-text"}>{formik.errors.passwordNew}</div>
      ) : null}
      <label htmlFor={"passwordNewConfirm"}>Confirm New Password:</label>
      <input
        name={"passwordNewConfirm"}
        type={"password"}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.passwordNewConfirm}
      />
      {formik.touched.passwordNewConfirm && formik.errors.passwordNewConfirm ? (
        <div className={"error-text"}>{formik.errors.passwordNewConfirm}</div>
      ) : null}
      <div className={"action-bar"}>
        <button type={"submit"}>Update</button>
      </div>
    </form>
  );
};

export default PasswordForm;
