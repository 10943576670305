export interface LDUserConfig {
  key: string;
  name: string;
  email: string;
  custom: {
    empId: number;
    customerId: number;
    userId: number;
    isAdmin: boolean;
  };
}

export interface LDUserContextConfig {
  kind: string;
  key: string;
  name: string;
  email: string;
  custom: {
    empId: number;
    customerId: number;
    userId: number;
    isAdmin: boolean;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const getLDUser = (user: any): LDUserConfig | undefined => {
  if (!user) {
    return;
  }
  return {
    key: user.customer_id + "_" + user.emp_id,
    name: user.user_name,
    email: user.email,
    custom: {
      empId: user.emp_id,
      userId: user.user_id,
      isAdmin: user.is_admin,
      customerId: user.customer_id,
    },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const getLDUserContext = (user: any): LDUserContextConfig | undefined => {
  if (!user) {
    return;
  }
  return {
    kind: "user",
    key: user.customer_id + "_" + user.emp_id,
    name: user.user_name,
    email: user.email,
    custom: {
      empId: user.emp_id,
      userId: user.user_id,
      isAdmin: user.is_admin,
      customerId: user.customer_id,
    },
  };
};
