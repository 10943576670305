import React from "react";
import { FieldProps, getIn } from "formik";

import Checkbox from "../../checkbox/Checkbox";
import ErrorMessage from "../meta/ErrorMessage";

interface Props extends FieldProps<boolean> {
  label?: string;
  disabled?: boolean;
  [key: string]: unknown;
}

const RenderCheckboxField = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  label = "",
  disabled = false,
  ...rest
}: Props): React.ReactElement => {
  const error = getIn(errors, name);
  const touch = getIn(touched, name);

  return (
    <>
      <Checkbox
        name={name}
        label={label}
        checked={value ?? false}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        error={touch && !!error}
        {...rest}
      />
      {touch && !!error && !disabled && <ErrorMessage text={error} />}
    </>
  );
};

export default RenderCheckboxField;
