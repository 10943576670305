"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");

// components
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
const classnames = require("@/_lib/utils/classnames");

var RequestEditDialog = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {
    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Request: Edit");
  },
  componentWillUnmount: function () {},

  /* publics */
  close: function (e) {
    this.props.close();
  },

  /* privates */

  _getActions: function () {
    var actions = window.LbsAppData.Helpers.Swaps.getRequestActions(
      window.LbsAppData.User,
      this.props.dialogObject.data,
      window.LbsAppData.Templates.getReqWindowStartOrLatestScheduled()
    );

    return this._pushButtons(actions);
  },
  _pushButtons: function (actions) {
    var buttons = [];
    for (var i = 0; i < actions.length; i++) {
      switch (actions[i]) {
        case window.LbsAppData.DELETE_ACTION:
          buttons.push(
            <div
              className="button"
              key={window.LbsAppData.DELETE_ACTION}
              onClick={this.touchProxy.bind(this, this._openRequestAction, [window.LbsAppData.DELETE_ACTION])}
              onTouchEnd={this.touchProxy.bind(this, this._openRequestAction, [window.LbsAppData.DELETE_ACTION])}
            >
              <i className="fa fa-fw fa-trash-o" />
              <span className="text">Delete Request</span>
            </div>
          );
          break;
        case window.LbsAppData.GRANT_ACTION:
          buttons.push(
            <div
              className="button"
              key={window.LbsAppData.GRANT_ACTION}
              onClick={this.touchProxy.bind(this, this._openRequestAction, [window.LbsAppData.GRANT_ACTION])}
              onTouchEnd={this.touchProxy.bind(this, this._openRequestAction, [window.LbsAppData.GRANT_ACTION])}
            >
              <i className="fa fa-fw fa-thumbs-o-up" />
              <span className="text">Grant Request</span>
            </div>
          );
          break;
        case window.LbsAppData.DENY_ACTION:
          buttons.push(
            <div
              className="button"
              key={window.LbsAppData.DENY_ACTION}
              onClick={this.touchProxy.bind(this, this._openRequestAction, [window.LbsAppData.DENY_ACTION])}
              onTouchEnd={this.touchProxy.bind(this, this._openRequestAction, [window.LbsAppData.DENY_ACTION])}
            >
              <i className="fa fa-fw fa-thumbs-o-down" />
              <span className="text">Deny Request</span>
            </div>
          );
          break;
        case window.LbsAppData.SUSPEND_ACTION:
          buttons.push(
            <div
              className="button"
              key={window.LbsAppData.SUSPEND_ACTION}
              onClick={this.touchProxy.bind(this, this._openRequestAction, [window.LbsAppData.SUSPEND_ACTION])}
              onTouchEnd={this.touchProxy.bind(this, this._openRequestAction, [window.LbsAppData.SUSPEND_ACTION])}
            >
              <i className="fa fa-fw fa-question" />
              <span className="text">Suspend Request</span>
            </div>
          );
          break;
        default:
          break;
      }
    }

    return buttons;
  },

  _openRequestAction: function (action) {
    // close this dialog
    window.LbsAppData.AppContext.closeDialog();

    // open up the swap remove dialog (pre-filled)
    window.LbsAppData.AppContext.openDialog({
      type: "request-action",
      data: {
        action,
        requests: [this.props.dialogObject.data],
      },
    });
  },

  /* render */
  render: function () {
    /* jshint ignore:start */
    const actions = this._getActions();

    if (actions.length === 0) {
      actions.push(
        <div className="empty" key="noop">
          No actions available
        </div>
      );
    }

    const classes = classnames.default({
      Dialog: this.props.isDialog !== false,
      isTop: this.props.isTop,
      RequestEdit: true,
    });

    let closeButton;
    if (this.props.close) {
      closeButton = (
        <div className="close" onClick={this.close} onTouchEnd={this.close}>
          <i className="fa fa-close" />
        </div>
      );
    }

    return (
      <div className={classes}>
        <div className="position-reset">
          {closeButton}

          <div className="content">
            <div className="actions">{actions}</div>
          </div>
        </div>
      </div>
    );
  },
});

module.exports = RequestEditDialog;
