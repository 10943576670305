const Backbone = require("backbone");
var moment = require("moment");
var _ = require("underscore");

var FillTool = Backbone.Model.extend({
  defaults: {
    type: "fill",
    desc: "fill",
    backgroundColor: "#FEFF84",
    color: "#000000",

    //targets: {},
    target: undefined,
    component: undefined,

    // fill specific attributes
    collection: undefined,
    collectionTitle: undefined,
    value: undefined,

    // actions queue
    inflight: false,
    queue: [],
  },

  initialize: function () {
    // subscribe to this model's own change:inflight event -- so when that flaps grab a possibly
    // queued action
    this.listenTo(this, "change:inflight", this._getQueuedAction);
  },

  onSelect: function () {
    // set the collection on the tool here
    if (window.LbsAppData.AppContext.get("GridSettings_leftColumnType") == "assignment") {
      // filter the Personnel collection
      var personnelCollection = window.LbsAppData.Personnel.filter(function (item) {
        // get schedulable, non-expired personnel from the current schedule department
        return (
          item.get("scheduled") &&
          !item.get("expired") &&
          item.get("departments").indexOf(window.LbsAppData.AppContext.get("displayedSchedule").get("department_id")) >
            -1
        );
      });
      // force an alphabetical sort
      var sortedPersonnel = _.sortBy(personnelCollection, function (m) {
        return m.attributes.display_name;
      });

      this.set({
        collectionTitle: "Personnel",
        collection: sortedPersonnel,
      });
    } else {
      // filter the Assignment collection
      var assignmentCollection = window.LbsAppData.Assignments.where({
        expired: false,
      });
      // force an alphabetical sort
      var sortedAssignments = _.sortBy(assignmentCollection, function (m) {
        return m.attributes.display_name;
      });

      this.set({
        collectionTitle: "Assignment",
        collection: sortedAssignments,
      });
    }
    // default to first
    this.set({ value: this.attributes.collection[0] });

    // show the extras panel
    window.LbsAppData.AppContext.openExtra({
      type: "fill",
      tool: this,
    });
  },

  onAttachPre: function () {},

  onAttach: function () {
    // no attached state for this tool, it just fires off, forward right to the execute
    this.onExecute();
  },

  onExecute: function () {
    var that = this;
    var slot = this.attributes.target;

    // set the inflight variable to start queuing any changes between when this one starts
    // and then finishes
    that.set({ inflight: true });

    if (slot) {
      switch (window.LbsAppData.AppContext.get("GridSettings_leftColumnType")) {
        case "assignment":
          //window.LbsAppData.Helpers.Analytics.sendEvent('Update: Slot', 'Fill Tool: Personnel');
          window.LbsAppData.ToolBox._replacePersonnel(slot);
          break;
        case "personnel":
          //window.LbsAppData.Helpers.Analytics.sendEvent('Update: Slot', 'Fill Tool: Assignment');
          window.LbsAppData.ToolBox._replaceAssignmentStep1(slot);

          break;
        default:
          break;
      }
    }
  },

  onFinished: function () {
    // trigger a redraw on the parent
    this.attributes.component.forceUpdateOnParent();
    // reset the inFlight parameter
    this.set({ inflight: false });
  },

  onClose: function () {
    this.set({
      target: undefined,
      component: undefined /*collection: undefined, collectionTitle: undefined, value: undefined*/,
    });
    window.LbsAppData.AppContext.closeExtra();
  },

  /* publics */
  getPersonnelForEmptySlot: function () {
    return this.attributes.value;
  },

  getAssignmentForEmptySlot: function () {
    return this.attributes.value;
  },

  style: function () {
    return {
      backgroundColor: this.attributes.backgroundColor,
      color: this.attributes.color,
    };
  },

  forceUpdateComponent: function () {
    if (this.attributes.component) {
      this.attributes.component.forceUpdateOnParent();
    }
  },

  /* privates */
  _reset: function () {},
  _getQueuedAction: function () {
    if (!this.attributes.inflight && this.attributes.queue.length) {
      // pop the first item and call its fn parameter
      var action = this.attributes.queue.shift();

      action.fn();
    }
  },
});

module.exports = FillTool;
