import React from "react";

import classnames from "@/_lib/utils/classnames";

import PasswordForm from "./forms/PasswordForm";
import RecoveryForm from "./forms/RecoveryForm";
import NotificationForm from "./forms/NotificationForm";
import useCurrentlyLoggedInUser from "@/viewer/ui/modules/common/hooks/useCurrentlyLoggedInUser";

import "./_styles.scss";

interface Props {
  close: () => void;
  isTop: boolean;
}

enum CurrentDialogType {
  "password",
  "recovery",
  "notification",
}

const getFormContainer = ({
  type,
  currentNotificationEmail,
  currentRecoveryEmail,
  empId,
}: {
  type: CurrentDialogType;
  currentNotificationEmail: string;
  currentRecoveryEmail: string;
  empId: number | "";
}): JSX.Element => {
  switch (type) {
    case CurrentDialogType.password:
      return <PasswordForm />;
    case CurrentDialogType.recovery:
      return <RecoveryForm currentRecoveryEmail={currentRecoveryEmail} />;
    case CurrentDialogType.notification: {
      return <NotificationForm currentNotificationEmails={currentNotificationEmail} empId={empId} />;
    }
    default:
      return <></>;
  }
};

const AccountSettingsDialog = (props: Props): JSX.Element => {
  const { close, isTop } = props;

  const { empId } = useCurrentlyLoggedInUser();
  const [currentDialog, setCurrentDialog] = React.useState<CurrentDialogType>(CurrentDialogType.password);
  const LbsAppData = (window as any).LbsAppData;
  const userRecoveryEmail = LbsAppData.User.attributes.email;
  const userNotificationEmail = LbsAppData.Personnel.get(empId).attributes.notification_email;

  const getSettingsSidebar = (): JSX.Element => {
    const classes = (dialogType: CurrentDialogType) =>
      classnames({
        ["setting-item"]: true,
        active: (() => dialogType === currentDialog)(),
      });

    return (
      <div className="setting-items">
        <div
          data-setting="password"
          onClick={() => setCurrentDialog(CurrentDialogType.password)}
          className={classes(CurrentDialogType.password)}
        >
          Password
        </div>
        <div
          data-setting="recovery"
          onClick={() => setCurrentDialog(CurrentDialogType.recovery)}
          className={classes(CurrentDialogType.recovery)}
        >
          Recovery Email
        </div>
        <div
          data-setting="notification"
          onClick={() => setCurrentDialog(CurrentDialogType.notification)}
          className={classes(CurrentDialogType.notification)}
        >
          Notification Email(s)
        </div>
      </div>
    );
  };

  const rootClasses = classnames({
    Dialog: true,
    isTop,
    AccountSettings: true,
  });

  return (
    <div className={rootClasses}>
      <div className="title-container">
        <div className="text">
          <i className="fa fa-cog" />
          Account Settings
        </div>
        <div className="close" onClick={() => close()}>
          <i className="fa fa-close" />
        </div>
      </div>
      <div className="body-container">
        {getSettingsSidebar()}
        {getFormContainer({
          type: currentDialog,
          currentNotificationEmail: userNotificationEmail,
          currentRecoveryEmail: userRecoveryEmail,
          empId,
        })}
      </div>
    </div>
  );
};

export default AccountSettingsDialog;
