import React from "react";

import { ScheduleData, SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { ViewData } from "@/viewer/types/viewdata";
import PrintContainer, { PrintType } from "@/_lib/ui/modules/print/PrintContainer";

import classnames from "@/_lib/utils/classnames";

import renderCalendarWeeks from "@/viewer/ui/modules/grid/calendar/renderers/renderCalendarWeeks";
import renderCalendarWeeksDateRange from "@/viewer/ui/modules/grid/calendar/renderers/renderCalendarWeeksDateRange";
import { formatUSDate } from "@/viewer/utils/dateFormatters";
import { DepartmentsById } from "@/viewer/types/domain/department";

interface Props {
  printRef: React.MutableRefObject<null>;
  printType: PrintType;
  settings: SettingsContext;
  ui: UIContext;
  scheduleData: ScheduleData;
  viewData: ViewData;
  startDate: Date;
  endDate: Date;
  departmentsById: DepartmentsById;
}

const CalendarPrintContainer = (props: Props): JSX.Element => {
  const {
    printRef,
    printType,
    settings,
    ui,
    scheduleData: { slots, requests },
    viewData: { filters, data, tools, originalPersonnelMap },
    startDate,
    endDate,
    departmentsById,
  } = props;

  const containerClass = classnames("CalendarContainer", {
    noWeekends: settings.hideWeekends,
  });

  const weeks = renderCalendarWeeks(
    settings,
    ui,
    filters,
    data,
    tools,
    startDate,
    endDate,
    slots,
    requests,
    originalPersonnelMap,
    departmentsById
  );
  // Renders Date Range on top center and weeks below
  const weeksDateRangePerPage = renderCalendarWeeksDateRange(
    settings,
    ui,
    filters,
    data,
    tools,
    startDate,
    endDate,
    slots,
    requests,
    originalPersonnelMap,
    departmentsById
  );

  /*
   *Solution to print date range on every page
   *Table is used print the date range on the header of each page
   *If print type is Minimal render table head for the date range and weeks component
   *If print type is One Week Per Page render the weeksDateRangePerPage component which contains date range header
   *[kgjergji@ritech.co]
   */
  return (
    <PrintContainer ref={printRef} showOutsidePrintPreview={false} printType={printType}>
      <table className="TablePrint">
        {printType === PrintType.Minimal && (
          <thead>
            <tr>
              <td className="DateRangeHeader">
                <h5>
                  {formatUSDate(startDate)}-{formatUSDate(endDate)}
                </h5>
              </td>
            </tr>
          </thead>
        )}
        <tbody>
          <tr>
            <td>
              <div className={containerClass}>{printType === PrintType.Minimal ? weeks : weeksDateRangePerPage}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </PrintContainer>
  );
};

export default CalendarPrintContainer;
