const Backbone = require("backbone");
const _ = require("underscore");
const ApiConfig = require("_lib/data/ApiConfig.js");

const TallyDataCollection = require("./../collections/TallyDataCollection.js");

const TallyDataMap = Backbone.Model.extend({
  defaults: {
    alternates_tally_list: undefined,
    dailysums_tally_list: undefined,
    period_type: "current",
    count_original: false,
    custom_start: undefined,
    custom_end: undefined,
  },

  initialize: function () {},

  url: function () {
    const tallyList = this.attributes.tally_list ? this.attributes.tally_list : window.LbsAppData.Tallies.at(0).id;
    const customRange =
      this.attributes.period_type == "custom"
        ? "&start_date=" + this.attributes.custom_start + "&end_date=" + this.attributes.custom_end
        : "";
    return (
      ApiConfig.getPrefix() +
      "/report/delta/" +
      this.attributes.schedID +
      "?tally_list=" +
      tallyList +
      "&period_type=" +
      this.attributes.period_type +
      "&count_original=" +
      this.attributes.count_original +
      customRange
    );
  },

  parse: function (response) {
    // convert the reponse arrays into tally collections
    this.data_by_day = new TallyDataCollection(response.data_by_day, {
      tallyType: "date",
    });
    this.data_by_emp = new TallyDataCollection(response.data_by_emp, {
      tallyType: "emp",
    });
    this.data_by_tally = new TallyDataCollection(response.data_by_tally, {
      tallyType: "tally",
    });

    // don't send any more attributes thru
    return {};
  },

  clear: function (response) {
    this.set({
      alternates_tally_list: undefined,
      dailysums_tally_list: undefined,
      tally_list: undefined,
    });
  },

  addTally: function (tallyObj) {
    let newTallyList = "";
    if (this.attributes.tally_list) {
      // append this tallyId to the current dailysums_tally_list
      newTallyList = _.union(this.attributes.dailysums_tally_list, [tallyObj.id]); // [this.attributes.dailysums_tally_list, tallyObj.id].join('~');
    } else {
      // no current tallies
      newTallyList = [tallyObj.id];
    }

    this.attributes.dailysums_tally_list = newTallyList;

    this.reset(newTallyList, "current", undefined, undefined, "dailysums", () => {
      this.trigger("addedTally", tallyObj.id);
    });
  },

  removeTally: function (tallyId) {
    // split the tally_list and then remove the matching id
    const tallyIndex = this.attributes.dailysums_tally_list.indexOf(tallyId);

    // reset the map
    this.reset(undefined, "current", undefined, undefined, "dailysums", () => {
      this.trigger("removedTally", tallyId);
    });
  },

  focus: function (dateKey, assignmentList, callback) {
    const tallies = [];

    if (window.LbsAppData.AppContext.get("GridSettings_leftColumnType") === "assignment") {
      // collect all the tallies associated with these assignments

      for (let i = 0; i < assignmentList.length; i++) {
        const assignmentId = assignmentList[i];
        const tallyDemand = window.LbsAppData.TallyDemand.get(dateKey).attributes.tally_demand[assignmentId];
        _.each(tallyDemand, function (item, key) {
          // tally_type == 2 means it's a total...whole lotta work/time for not a lot of gain
          const tally = window.LbsAppData.Tallies.get(key);
          if (tallies.indexOf(key) === -1 && tally && tally.attributes.tally_type !== 2) {
            // not in there already
            tallies.push(key);
          }
        });
      }

      this.attributes.alternates_tally_list = tallies;

      this.reset(
        tallies.join("~"),
        this.attributes.period_type,
        this.attributes.custom_start,
        this.attributes.custom_end,
        "selecttool",
        callback
      );
    } else {
      // collect just the system defined tallies for the assignments

      // for (let i = 0; i < assignmentList.length; i++) {
      //   // these assign_id lookups aren't great with the squashed stuff happening
      //   // but hopefully this works out all right
      //   const tally = window.LbsAppData.Assignments.get(assignmentList[i]).tally;
      //   // might be the case this assignments doesn't have a system defined tally...
      //   // thats bad data, but it's going to happen so handle gracefully
      //   if (tally) {
      //     tallies.push(tally.id);
      //   }
      // }
      callback();
    }
  },

  reset: function (tallyList, periodType, customStart, customEnd, type, callback) {
    // update the tally_list and refetch
    var currentPeriodType = this.attributes.period_type;
    if (type == "dailysums") {
      this.attributes.tally_list = this.attributes.dailysums_tally_list.join("~");
      // save processing time by making sure this is 'current'...we will switch it back at the end if it was one of the others
      this.attributes.period_type = periodType || this.attributes.period_type;
    } else if (type == "selecttool") {
      this.attributes.tally_list = tallyList;
      this.attributes.period_type = periodType || this.attributes.period_type;
    } else if (type == "talliespane") {
      this.attributes.tally_list = tallyList;
      this.attributes.period_type = periodType || this.attributes.period_type;
      this.attributes.custom_start = customStart || this.attributes.custom_start;
      this.attributes.custom_end = customEnd || this.attributes.custom_end;
    }
    // only do the fetch if there an actual tally to fetch
    if (this.attributes.tally_list.length > 0) {
      this.fetch({
        reset: true,
        success: function () {
          if (callback) {
            callback();
          }
        },
      });
    } else if (callback) {
      callback();
    }

    // restore the period type (incase it was changed for this fetch)
    this.attributes.period_type = currentPeriodType;
  },

  /* privates */
  _combineTallyLists: function () {
    return _.union(this.attributes.alternates_tally_list, this.attributes.dailysums_tally_list);
  },
});

module.exports = TallyDataMap;
