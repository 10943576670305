const Backbone = require("backbone");
const moment = require("moment-timezone");
const _ = require("underscore");
const Promise = require("bluebird");

const Session = require("_lib/data/session/Session.js");
const Slot = require("_lib/data/models/NakedSlot.js");

const SafeJSON = require("../../../common/utils/jsonParseShield");

const AppContext = Backbone.Model.extend({
  session: new Session(),

  defaults: {
    dialogStack: [],
    // displayed contexts
    contextualStack: [],
  },

  initialize: function () {
    // localization settings
    if (window.payload && window.payload.user) {
      const user = SafeJSON.parse(window.payload.user);
      // set a default timezone on the moment object (if specific...otherwise just use the default (local))
      if (user.tz) {
        moment.tz.setDefault(user.tz);
      }
    }
  },

  _syncBackbone: function () {
    var that = this;

    that.session._syncBackbone(function (callback) {
      if (callback) {
        callback();
      }

      // trigger an authorized event
      that.trigger("authorized");
    });
  },

  pushContext: function (contextObject) {
    this.attributes.contextualStack.push(contextObject);
    this.trigger("change:contextualStack");
  },

  // displayers
  openDialog: function (object) {
    this.attributes.dialogStack.push(object);
    this.trigger("pushedDialog");
  },
  closeDialog: function (type) {
    if (this.attributes.dialogStack.length) {
      if (!type) {
        this.attributes.dialogStack.pop();
        this.trigger("poppedDialog");
      } else {
        // close a specific dialog
        const index = _.findIndex(this.attributes.dialogStack, function (item) {
          return item.type === type;
        });

        if (index > -1) {
          this.attributes.dialogStack.splice(index, 1);
          this.trigger("poppedDialog");
        }
      }
    }
  },
  closeDialogTo: function (id, clearAllIfNotFound) {
    if (_.isString(id)) {
      // type identifier -- find it
      const idx = _.findIndex(this.attributes.dialogStack, function (ds) {
        return ds.type === id;
      });

      if (idx > -1) {
        this.trigger("poppedDialog");
      } else if (clearAllIfNotFound) {
        this.clearDialogs();
      }
    } else {
      // integer -- splice the id+1 (think splice is inclusive so it's 1 based)
      this.trigger("poppedDialog");
    }
  },
  clearDialogs: function () {
    this.attributes.dialogStack = [];
    this.trigger("poppedDialog");
  },
  openLoadingDialog: function (message, context, callback) {
    this.openDialog({ type: "message", message: message });

    if (callback) {
      window.setTimeout(function () {
        callback.call(context);
      }, 100);
    }
  },
  closeLoadingDialog: function () {
    // pretend like we did something that took a while basically...just so the user has time to see
    // something happened or is happening
    const that = this;

    window.setTimeout(function () {
      that.closeDialog();
    }, 500);
  },

  openSwopReview: function (slotId, swopAction) {
    // just preswaps/swops right now...but I can see this same thing being useful for plain requests in the future

    // any ideas to not have to do the timeout?  the backbone sync doenst finish in time if I don't it looks like
    // and there is no authorization header on the request to the api for the slot fetch

    // TODO so much error checking...make sure the slot is a pending swop...make sure the slot exists...make sure I'm involved on the swop...etc.

    const slot = new Slot({ slot_id: slotId });
    const promises = [];

    promises.push(
      new Promise(function (resolve, reject) {
        slot.fetch({
          success: function () {
            resolve();
          },
          error: function () {
            reject();
          },
        });
      })
    );

    Promise.all(promises).then(function () {
      // close the loading dialog
      window.LbsAppData.AppContext.closeDialog();

      // do your checks
      window.LbsAppData.AppContext.openDialog({
        type: "preswap-pending",
        data: {
          action: swopAction,
          slots: [slot],
        },
        onClose: function () {
          // clean up the router
          window.LbsAppData.Router.navigate("/");
        },
      });
    });
  },

  // trigger a redraw on the schedule container
  triggerRedraw: function () {
    this.trigger("redraw");
  },

  loginRefreshWithSession: function (callback) {
    const that = this;

    this.session.loginRefresh();

    // override backbone sync to send the authorization header
    Backbone.sync = function (method, model, options) {
      options.headers = options.headers || {};
      _.extend(options.headers, {
        Authorization: "Bearer " + that.session.get("token"),
      });

      _sync(method, model, options);
    };

    // set a timer for the token expiration
    window.setTimeout(function () {
      that.loginRefreshWithSession.call(that);
    }, that.session.get("expiresInSafe"));

    if (callback) {
      callback();
    }
  },

  loginWithToken: function (token, callback) {
    // used for application that forward a token to viewer; they won't have a refresh token in this case
    // so currently...this only makes sense to use for LB internal applications
    Backbone.sync = function (method, model, options) {
      options.headers = options.headers || {};
      _.extend(options.headers, { Authorization: "Bearer " + token });
      _sync(method, model, options);
    };

    callback();
  },
});

module.exports = AppContext;
