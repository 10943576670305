import { Collection, Model } from "backbone";
import * as React from "react";
import batchRegisterListeners from "@/viewer/ui/modules/common/helpers/batchRegisterListeners";
import { cleanPersonnelAttributes, Personnel, PersonnelAttributes } from "@/viewer/types/domain/personnel";
import { FilterMap, SettingsContext } from "@/viewer/ui/modules/common/types/context";
import { ILLEGAL_PERSONNEL_DISPLAY_NAMES } from "@/viewer/data/constants";

export interface PersonnelData {
  personnel: Personnel[];
  filteredPersonnel: FilterMap;
}

const getPersonnelData = (settings: SettingsContext): PersonnelData => {
  const { LbsAppData } = window as any;

  if (!LbsAppData.Personnel) return { personnel: [], filteredPersonnel: {} };

  const Personnel: Collection<Model<PersonnelAttributes>> = LbsAppData.Personnel;
  const personnel: Personnel[] = [];
  const filteredPersonnel: FilterMap = {};
  Personnel.forEach((item) => {
    // Remove EMPTY personnel object (id 4) and LBS personnel (id 20001)
    // In old DBs the EMPTY personnel object has id 1, 2, 3, or 4.
    // In old DBs the LBS personnel won't be id 20001.
    // Resort to string compare or display names.
    if (ILLEGAL_PERSONNEL_DISPLAY_NAMES.includes(item.attributes.display_name ?? "")) {
      return;
    }

    personnel.push(cleanPersonnelAttributes(settings, item.attributes));
    if (item.attributes.filtered) {
      filteredPersonnel[item.attributes.emp_id?.toString() ?? ""] = true;
    }
  });
  return {
    personnel,
    filteredPersonnel,
  };
};

const getFilteredPersonnel = (): FilterMap => {
  const { LbsAppData } = window as any;

  if (!LbsAppData.Personnel) return {};

  const Personnel: Collection = LbsAppData.Personnel;
  const filteredPersonnel: FilterMap = {};
  Personnel.forEach((item) => {
    if (item.attributes.filtered) {
      filteredPersonnel[item.attributes.emp_id.toString()] = true;
    }
  });
  return filteredPersonnel;
};

export default (settings: SettingsContext): PersonnelData => {
  const [personnelData, setPersonnelData] = React.useState(getPersonnelData(settings));
  React.useEffect(() => {
    const { LbsAppData } = window as any;
    const onFilterChange = () => {
      // Don't change the identity of the main data array, just update the filter.
      setPersonnelData({ ...personnelData, filteredPersonnel: getFilteredPersonnel() });
    };

    const onDataChange = () => {
      setPersonnelData(getPersonnelData(settings));
    };

    const cleanupFilterListeners = batchRegisterListeners(onFilterChange, [LbsAppData.Personnel, "change:filtered"]);

    const cleanupListeners = batchRegisterListeners(
      onDataChange,
      [LbsAppData.Personnel, "sort"],
      [LbsAppData.Personnel, "change:available"]
    );

    return () => {
      cleanupFilterListeners();
      cleanupListeners();
    };
  }, []);

  return personnelData;
};
