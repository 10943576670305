import _ from "underscore";

import { Report, ReportComposition, ReportDefiniton } from "@/viewer/types/domain/report";
import { SettingsContext, ViewDataType } from "@/viewer/ui/modules/common/types/context";
import { formatDayKey, formatISODate } from "@/viewer/utils/dateFormatters";
import { addDays, isBefore, startOfDay } from "date-fns";

export const createDateIds = (startDate: Date, endDate: Date): string[] => {
  const dateIds: string[] = [];

  if (isBefore(endDate, startDate)) {
    // Avoid an infinite loop
    [endDate, startDate] = [startDate, endDate];
  }

  let currentDay = startOfDay(startDate);
  const endDay = startOfDay(endDate);

  while (isBefore(currentDay, endDay)) {
    dateIds.push(formatDayKey(currentDay));
    currentDay = addDays(currentDay, 1);
  }

  return dateIds;
};

export const fillReport = (settings: SettingsContext, reportSkeleton: Report): Report => {
  const { isUserAdmin, view } = settings;
  const tallyIds = _.map(view.filter.on_tallies, "id");
  const empIds = _.map(view.filter.on_personnel, "id");

  // fill-ins from the view
  reportSkeleton._definition.tallies = reportSkeleton._composition.nodes["0"].def.tally_ids = tallyIds;
  reportSkeleton._composition.nodes["1"].def.y.ids = tallyIds;

  reportSkeleton._definition.templates = view.filter.on_templates;
  reportSkeleton._composition.nodes["0"].def.template_ids = view.filter.on_templates;

  reportSkeleton._definition.departments = view.filter.on_departments;
  reportSkeleton._composition.nodes["0"].def.department_ids = view.filter.on_departments;

  reportSkeleton._definition.properties.data.pub_admin = isUserAdmin;
  reportSkeleton._composition.nodes["0"].def.pub_admin = isUserAdmin;

  reportSkeleton._composition.nodes["0"].def.emp_ids = empIds;

  return reportSkeleton;
};

/**
 * Returns a basic `Report` object.
 *
 * If the optional `SettingsContext` parameter is provided then the report will be filled with
 * relevant view data.
 * @param startDate `Date`
 * @param endDate `Date`
 * @param settings `SettingsContext`
 */
export default (startDate: Date, endDate: Date, settings?: SettingsContext): Report => {
  const definition: ReportDefiniton = {
    properties: {
      data: {
        mode: "adhoc",
        start_date: {
          type: "specific",
          value: formatISODate(startDate),
        },
        end_date: {
          type: "specific",
          value: formatISODate(endDate),
        },
        report_type: settings?.viewDataType ?? ViewDataType.schedule,
        count_original: false,
        include_history: false,
        pub_admin: false,
        r_granted: true,
        r_pending: true,
        r_denied: false,
        logged_in_user: false,
        period_type: "current",
        split: "none",
      },
      table: {
        x: "date",
        y: "tally",
        x_dim: "none",
        y_dim: "none",
        show_totals: false,
      },
    },
    tallies: [], // overwrite this
    personnel: [], // shouldn't be necessary
    templates: [], // overwrite this
    departments: [], // overwite this
  };

  const dateIds = createDateIds(startDate, endDate);
  const composition: ReportComposition = {
    nodes: {
      0: {
        type: "tally_counts",
        def: {
          mode: "adhoc",
          start_date: formatDayKey(startDate),
          end_date: formatDayKey(endDate),
          report_type: settings?.viewDataType ?? ViewDataType.schedule,
          count_original: false,
          include_history: false,
          pub_admin: false,
          r_granted: true,
          r_pending: true,
          r_denied: false,
          logged_in_user: false,
          period_type: "current",
          split: "none",
          emp_ids: [], // don't think you need this..
          tally_ids: [], // overwrite this
          template_ids: [], // overwrite this
          department_ids: [], // overwrite this
        },
      },
      1: {
        type: "table",
        def: {
          title: undefined,
          x: {
            obj: "date",
            dim: "none",
            ids: dateIds,
          },
          y: {
            obj: "tally",
            dim: "none",
            ids: [], // overwrite this
          },
        },
      },
    },
    paths: {
      0: [0, 1],
    },
  };

  const reportSkeleton = { _definition: definition, _composition: composition };

  if (settings) {
    return fillReport(settings, reportSkeleton);
  }

  return reportSkeleton;
};
