/*
 * Assignment collection that doesn't depend on window object bindings */
const Backbone = require("backbone");
const _ = require("lodash");

const ApiConfig = require("_lib/data/ApiConfig.js");
const NakedAssignment = require("./../models/NakedAssignment.js");

const NakedAssignmentCollection = Backbone.Collection.extend({
  model: NakedAssignment,

  initialize: function (models, attributes) {
    this.attributes = {};

    for (const key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    const queryParams = {};

    if (this?.attributes?.tempList?.length) {
      queryParams["template_list"] = this.attributes.tempList.join("~");
    }

    if (this?.attributes?.assignList?.length) {
      queryParams["assign_list"] = this.attributes.assignList.join("~");
    }

    if (Number.isInteger(this?.attributes?.empID)) {
      queryParams["emp_id"] = this.attributes.empID;
    }

    if (this.attributes.demandDate) {
      queryParams["demand_date"] = this.attributes.demandDate;
    }

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    return (
      ApiConfig.getPrefix() +
      "/assignments/condensed/?" +
      _.map(cleanQueryParams, function (value, key) {
        return key + "=" + value;
      }).join("&")
    );
  },
});

module.exports = NakedAssignmentCollection;
