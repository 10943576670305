import moment from "moment-timezone";

export const getAllDatesBetweenRange = (
  startDate: string,
  endDate: string,
  dateFormat = "YYYY-MM-DD"
): Array<string> => {
  // The time strings returned from the database are in a non-standard ISO8061 format
  // and moment does not like this and screams about deprecation. This is an unfortunate
  // side effect due to an oddity in the ES5 spec which was later resolved in
  // ES2015. In order to prevent deprecation warnings, and to ensure these times
  // are handled correctly across environments / browsers, the non-standard
  // date string has to be instantiated as a new Date object so the ES2015 fix can
  // standardize the input. More info on Moment.js docs here: https://momentjs.com/guides/#/lib-concepts/js-date/
  const endMoment = moment(new Date(endDate as unknown as string)).utc();
  const startMoment = moment(new Date(startDate as unknown as string)).utc();
  const dateStrings: string[] = [];

  while (startMoment < endMoment) {
    const dateString = dateFormat && startMoment.format(dateFormat);

    dateStrings.push(dateString);
    startMoment.add(1, "day");
  }

  return dateStrings;
};

export const isDateStringInSchedule = (date: string): boolean => {
  const { LbsAppData } = window as any;

  return LbsAppData.DateManager.isDateInSchedule(moment(date));
};

/**
 * Sorts two date strings by ascending chronological.
 * @param dateOne - Item to check
 * @param dateTwo - Item to check against
 * @returns True if dateOne is before or the same as dateTwo and false otherwise
 *
 */
export const areDatesSortedByAscendingChronologicalOrder = (dateOne: string, dateTwo: string): boolean => {
  const momentOne = moment(dateOne);
  const momentTwo = moment(dateTwo);

  return momentOne.isBefore(momentTwo) || momentOne.isSame(momentTwo) ? true : false;
};

export const getPrecisionDifferenceInHours = (stopTime: Date, startTime: Date): string => {
  const unixTimeEpochMSDifference = stopTime.getTime() - startTime.getTime();
  const MILLISECONDS_IN_HOUR = 3_600_000;
  const hoursFromMS = unixTimeEpochMSDifference / MILLISECONDS_IN_HOUR;
  const MANTISSA_PRECISION = 4;
  const MAXIMUM_VALUE_FOR_FORMAT = 99999.9999;

  const preciseHours = hoursFromMS.toPrecision(MANTISSA_PRECISION);

  return hoursFromMS >= MAXIMUM_VALUE_FOR_FORMAT ? MAXIMUM_VALUE_FOR_FORMAT.toString() : preciseHours;
};
