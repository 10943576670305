const Backbone = require("backbone");
var _ = require("underscore");

var ReportResult = Backbone.Model.extend({
  idAttribute: "path_id",

  defaults: {},
  initialize: function () {},

  /* publics */

  /* privates */
});

module.exports = ReportResult;
