const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var ReportResult = require("./../models/ReportResult.js");

var ReportResultCollection = Backbone.Collection.extend({
  model: ReportResult,
  comparator: "path_id",

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },
});

module.exports = ReportResultCollection;
