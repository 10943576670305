import { DataContext, FilterContext, SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import * as React from "react";
import { Request } from "@/viewer/types/domain/request";
import { isSlotVisible } from "@/viewer/utils/domain/slotquests";

export type RequestMap = { [k: string]: Request[] };

export default (
  settings: SettingsContext,
  ui: UIContext,
  requests: Request[],
  filters: FilterContext,
  data: DataContext
): RequestMap => {
  return React.useMemo(() => {
    const out: { [k: string]: Request[] } = {};

    requests.forEach((request) => {
      if (!out[request.dateString]) {
        out[request.dateString] = [];
      }

      if (isSlotVisible(request, ui, filters, data)) {
        out[request.dateString].push(request);
      }
    });

    return out;
  }, [settings, requests, filters, data]);
};
