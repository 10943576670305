const Backbone = require("backbone");
var moment = require("moment");

var Error = Backbone.Model.extend({
  idAttribute: "slot_uuid",

  defaults: {
    slots: [],
  },

  initialize: function () {},

  printAttr: function (attribute) {
    switch (attribute) {
      case "date":
        var momentDate = moment([
          this.attributes.date.slice(0, 4),
          this.attributes.date.slice(4, 6),
          this.attributes.date.slice(6, 8),
        ]);
        return momentDate.format("MMM Do, YYYY");
      default:
        return this.attributes[attribute];
    }
  },

  throb: function () {
    // need to switch the way we're using the date index
    var date = moment(this.attributes.date, "YYYYMMDD").format("YYYY-MM-DD");

    // switch based on type
    switch (this.attributes.type) {
      case "compat":
        // attach the compat error class to this slot if the emp_id matches
        // this error's
        var slots = window.LbsAppData.Slots.where({ slot_date: date });
        for (var i = 0; i < slots.length; i++) {
          var slotAssignID = parseInt(
            slots[i].attributes.assign_plus_structure_id.split(window.LbsAppData.CONDENSED_DELIMITER)[0],
            10
          );
          if (
            this.attributes.assign_ids.indexOf(slotAssignID) > -1 &&
            slots[i].attributes.emp_id == this.attributes.emp_id
          ) {
            var slot = slots[i];

            this.attributes.slots.push(slot);
            slot.attachError(this);
          }
        }

        break;
      case "demand":
        // cell components are listening to a throb event that comes from the
        // Errors collection
        //var eventName = 'throb~' + this.attributes.assign_struct_id + '~' + this.attributes.date;
        //this.collection.trigger(eventName, this.attributes.type);
        var slots = window.LbsAppData.Slots.where({
          slot_date: date,
          assign_structure_id: this.attributes.assign_struct_id,
        });
        for (var i = 0; i < slots.length; i++) {
          var slot = slots[i];

          this.attributes.slots.push(slot);
          slot.attachError(this);
        }

        break;
      case "role":
        var slots = window.LbsAppData.Slots.where({
          slot_date: date,
          assign_structure_id: this.attributes.assign_struct_id,
          emp_id: this.attributes.emp_id,
        });
        for (var i = 0; i < slots.length; i++) {
          var slot = slots[i];

          this.attributes.slots.push(slot);
          slot.attachError(this);
        }

        break;

      case "expired_personnel":
        var slots = window.LbsAppData.Slots.where({
          slot_date: date,
          emp_id: this.attributes.emp_id,
        });
        for (var i = 0; i < slots.length; i++) {
          var slot = slots[i];

          this.attributes.slots.push(slot);
          slot.attachError(this);
        }

        break;

      case "merge_conflict":
        var slots = window.LbsAppData.Slots.where({
          slot_id: this.get("slot_id"),
        });
        // should only match one...but loop anyways
        for (var i = 0; i < slots.length; i++) {
          var slot = slots[i];

          this.attributes.slots.push(slot);
          slot.attachError(this);
        }

      default:
        break;
    }
  },

  detach: function () {
    // detach the error from cell level components
    //switch (this.attributes.type) {
    //case 'demand':
    //	// cell components are listening to a throb event that comes from the
    //	// Errors collection
    //	//var eventName = 'throb~' + this.attributes.assign_struct_id + '~' + this.attributes.date;
    //	//this.collection.trigger(eventName, undefined);
    //
    //
    //
    //	break;
    //default:
    //	break;
    //}

    for (var i = 0; i < this.attributes.slots.length; i++) {
      var slot = this.attributes.slots[i];

      slot.detachError();
    }

    this.attributes.slots = [];
  },
});

module.exports = Error;
