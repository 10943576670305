"use strict";

// scss requires
require("./_styles.scss");
// dependencies
const React = require("react/addons");
const { connect } = require("react-redux");

// components
const TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

const modalActions = require("@/common/components/modal/actions").default;
const MoveDialog = require("@/_lib/ui/modules/dialog/types/schedule_edits/swaps/move/MoveDialog").default;

// eslint-disable-next-line react/no-deprecated
const SlotEditDialog = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {
    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Slot: Edit");
  },
  /* publics */
  close: function (e) {
    this.props.close();
  },

  /* privates */
  _getActions: function () {
    var actions = window.LbsAppData.Helpers.Swaps.getSwapActions(window.LbsAppData.User, this.props.dialogObject.data);

    return this._pushButtons(actions);
  },
  _pushButtons: function (actions) {
    var buttons = [];
    for (var i = 0; i < actions.length; i++) {
      switch (actions[i]) {
        case window.LbsAppData.ACCEPT_ACTION:
          buttons.push(
            <div
              className="button"
              key="accept"
              onClick={this.touchProxy.bind(this, this._openPreSwapPending, [window.LbsAppData.ACCEPT_ACTION])}
              onTouchEnd={this.touchProxy.bind(this, this._openPreSwapPending, [window.LbsAppData.ACCEPT_ACTION])}
            >
              <i className="fa fa-fw fa-user-plus" />
              <span className="text">Accept Swaportunity</span>
            </div>
          );
          break;
        case window.LbsAppData.DECLINE_ACTION:
          buttons.push(
            <div
              className="button"
              key="decline"
              onClick={this.touchProxy.bind(this, this._openPreSwapPending, [window.LbsAppData.DECLINE_ACTION])}
              onTouchEnd={this.touchProxy.bind(this, this._openPreSwapPending, [window.LbsAppData.DECLINE_ACTION])}
            >
              <i className="fa fa-fw fa-user-times" />
              <span className="text">Decline Swaportunity</span>
            </div>
          );
          break;
        case window.LbsAppData.FINALIZE_ACTION:
          buttons.push(
            <div
              className="button"
              key="finish"
              onClick={this.touchProxy.bind(this, this._openPreSwapFinalize, [window.LbsAppData.FINALIZE_ACTION])}
              onTouchEnd={this.touchProxy.bind(this, this._openPreSwapFinalize, [window.LbsAppData.FINALIZE_ACTION])}
            >
              <i className="fa fa-fw fa-hand-o-right" />
              <span className="text">Finalize Swaportunity</span>
            </div>
          );
          break;
        case window.LbsAppData.CANCEL_SWOP_ACTION:
          buttons.push(
            <div
              className="button"
              key="cancel_swop"
              onClick={this.touchProxy.bind(this, this._openPreSwapPending, [window.LbsAppData.DELETE_ACTION])}
              onTouchEnd={this.touchProxy.bind(this, this._openPreSwapPending, [window.LbsAppData.DELETE_ACTION])}
            >
              <i className="fa fa-fw fa-trash-o" />
              <span className="text">Cancel Swaportunity</span>
            </div>
          );
          break;
        case window.LbsAppData.GRANT_ACTION:
          buttons.push(
            <div
              className="button"
              data-cy="SwapGrantBtn"
              key="grant"
              onClick={this.touchProxy.bind(this, this._openSwapPending, [window.LbsAppData.GRANT_ACTION])}
              onTouchEnd={this.touchProxy.bind(this, this._openSwapPending, [window.LbsAppData.GRANT_ACTION])}
            >
              <i className="fa fa-fw fa-thumbs-o-up" />
              <span className="text">Grant Swap</span>
            </div>
          );
          break;
        case window.LbsAppData.DENY_ACTION:
          buttons.push(
            <div
              className="button"
              data-cy="SwapDenyBtn"
              key="deny"
              onClick={this.touchProxy.bind(this, this._openSwapPending, [window.LbsAppData.DENY_ACTION])}
              onTouchEnd={this.touchProxy.bind(this, this._openSwapPending, [window.LbsAppData.DENY_ACTION])}
            >
              <i className="fa fa-fw fa-thumbs-o-down" />
              <span className="text">Deny Swap</span>
            </div>
          );
          break;
        case window.LbsAppData.DELETE_ACTION:
          buttons.push(
            <div
              className="button"
              key="delete"
              data-cy="SwapCancelBtn"
              onClick={this.touchProxy.bind(this, this._openSwapPending, [window.LbsAppData.DELETE_ACTION])}
              onTouchEnd={this.touchProxy.bind(this, this._openSwapPending, [window.LbsAppData.DELETE_ACTION])}
            >
              <i className="fa fa-fw fa-trash-o" />
              <span className="text">Cancel Swap</span>
            </div>
          );
          break;
        case window.LbsAppData.REMOVE_ACTION:
          buttons.push(
            <div
              className="button"
              key="remove"
              data-cy="SDRemoveBtn"
              onClick={this.touchProxy.bind(this, this._openSwapRemove, [])}
              onTouchEnd={this.touchProxy.bind(this, this._openSwapRemove, [])}
            >
              <i className="fa fa-fw fa-sign-out" />
              <span className="text">Remove</span>
            </div>
          );
          break;
        case window.LbsAppData.REPLACE_ACTION:
          buttons.push(
            <div
              className="button"
              data-cy="SDReplaceBtn"
              key={window.LbsAppData.REPLACE_ACTION}
              onClick={this.touchProxy.bind(this, this._openSwapReplace, [])}
              onTouchEnd={this.touchProxy.bind(this, this._openSwapReplace, [])}
            >
              <i className="fa fa-fw fa-exchange" />
              <span className="text">Replace</span>
            </div>
          );
          break;

        case window.LbsAppData.SPLIT_SHIFT_ACTION:
          if (!window.LbsAppData.User.attributes.parameters.LBLiteSplitShift) break;

          buttons.push(
            <div
              className="button"
              data-cy="SDSplitShiftBtn"
              key={window.LbsAppData.SPLIT_SHIFT_ACTION}
              onClick={this.touchProxy.bind(this, this._openSplitShift, [])}
              onTouchEnd={this.touchProxy.bind(this, this._openSplitShift, [])}
            >
              <span className="fa-stack">
                <i className={"fa fa-arrows-h fa-stack-1x"}></i>
                <i className={"fa fa-i-cursor fa-stack-1x"}></i>
              </span>
              <span className="text">Split Shift</span>
            </div>
          );
          break;

        case window.LbsAppData.DETAILS_ACTION:
          buttons.push(
            <div
              className="button"
              data-cy="SDmodifyDetailsBtn"
              key="details"
              onClick={this.touchProxy.bind(this, this._openSwapDetails, [])}
              onTouchEnd={this.touchProxy.bind(this, this._openSwapDetails, [])}
            >
              <i className="fa fa-fw fa-edit" />
              <span className="text">Modify Details</span>
            </div>
          );
          break;
        case window.LbsAppData.MOVE_ACTION:
          buttons.push(
            <div
              className="button"
              data-cy="SDmoveBtn"
              key="move"
              onClick={() => this.props.open(<MoveDialog slotId={this.props.dialogObject.data.attributes.slot_id} />)}
            >
              <i className="fa fa-fw fa-calendar" />
              <span className="text">Move</span>
            </div>
          );
          break;
        default:
          break;
      }
    }

    return buttons;
  },

  _openSwapRemove: function (e) {
    var dataSelectedDates = {};
    dataSelectedDates[this.props.dialogObject.data.get("slot_date").replace(/-/g, "")] = true;
    var dataSelectedSlots = {};
    dataSelectedSlots[this.props.dialogObject.data.id] = true;

    // close this dialog
    window.LbsAppData.AppContext.closeDialog();

    // open up the swap remove dialog (pre-filled)
    window.LbsAppData.AppContext.openDialog({
      type: "swap-remove",
      data: {
        step: 4,
        selectedDates: dataSelectedDates,
        slots: [this.props.dialogObject.data],
        selectedSlots: dataSelectedSlots,
      },
    });
  },
  _openSwapReplace: function (mode) {
    var dataSelectedDates = {};
    dataSelectedDates[this.props.dialogObject.data.get("slot_date").replace(/-/g, "")] = true;
    //var dataSelectedSlots = {};
    //dataSelectedSlots[this.props.dialogObject.data.id] = true;

    // close this dialog
    window.LbsAppData.AppContext.closeDialog();

    // open up the swap replace dialog (pre-filled)
    window.LbsAppData.AppContext.openDialog({
      type: "swap-replace",
      data: {
        step: 3,
        selectedDates: dataSelectedDates,
        slots: [this.props.dialogObject.data],
        selectedSlot: this.props.dialogObject.data,
      },
    });
  },
  _openMove: function () {
    var dataSelectedDates = {};
    dataSelectedDates[this.props.dialogObject.data.get("slot_date").replace(/-/g, "")] = true;

    // close this dialog
    window.LbsAppData.AppContext.closeDialog();

    // open up the swap replace dialog (pre-filled)
    window.LbsAppData.AppContext.openDialog({
      type: "move",
      data: {
        step: 3,
        selectedDates: dataSelectedDates,
        slots: [this.props.dialogObject.data],
        selectedSlot: this.props.dialogObject.data,
      },
    });
  },
  _openPreSwapPending: function (action) {
    // close this dialog
    window.LbsAppData.AppContext.closeDialog();

    // open up the preswap pending dialog (pre-filled)
    window.LbsAppData.AppContext.openDialog({
      type: "preswap-pending",
      data: {
        action: action,
        slots: [this.props.dialogObject.data],
      },
    });
  },
  _openPreSwapFinalize: function (action) {
    // close this dialog
    window.LbsAppData.AppContext.closeDialog();

    // open up the preswap finalize dialog (pre-filled)
    window.LbsAppData.AppContext.openDialog({
      type: "preswap-finalize",
      data: {
        slots: [this.props.dialogObject.data],
      },
    });
  },
  _openSwapPending: function (action) {
    // close this dialog
    window.LbsAppData.AppContext.closeDialog();

    // open up the swap pending dialog (pre-filled)
    window.LbsAppData.AppContext.openDialog({
      type: "swap-pending",
      data: {
        action: action,
        slots: [this.props.dialogObject.data],
      },
    });
  },
  _openSplitShift: function () {
    window.LbsAppData.AppContext.closeDialog();

    // open up the split shift dialog (pre-filled)
    window.LbsAppData.AppContext.openDialog({
      type: "split-shift",
      data: {
        slots: [this.props.dialogObject.data],
      },
    });
  },
  _openSwapDetails: function () {
    // close this dialog
    window.LbsAppData.AppContext.closeDialog();

    // open up the swap details dialog (pre-filled)
    window.LbsAppData.AppContext.openDialog({
      type: "swap-details",
      data: {
        slots: [this.props.dialogObject.data],
      },
    });
  },
  /* render */
  render: function () {
    /* jshint ignore:start */
    var actions = this._getActions();

    if (actions.length == 0) {
      actions.push(
        <div className="empty" key="noop">
          No actions available
        </div>
      );
    }

    var classes = React.addons.classSet({
      Dialog: this.props.isDialog !== false,
      isTop: this.props.isTop,
      SlotEdit: true,
    });

    let closeButton;
    if (this.props.close) {
      closeButton = (
        <div className="close" onClick={this.close} onTouchEnd={this.close}>
          <i className="fa fa-close" />
        </div>
      );
    }

    return (
      <div className={classes}>
        <div className="position-reset">
          {closeButton}

          <div className="content">
            <div className="actions">{actions}</div>
          </div>
        </div>
      </div>
    );
  },
});

const mapDispatchToProps = (dispatch) => ({
  open: (dialog) => dispatch(modalActions.open(dialog)),
});

module.exports = connect(null, mapDispatchToProps)(SlotEditDialog);
