"use strict";

var React = require("react/addons");
var ReactDOM = require("react-dom");

// scss requires
require("./_styles.scss");

// var AssignmentCollection = require('_lib/data/collections/NakedAssignmentCollection.js');
// var Subscription = require('login/data/models/Subscription.js');

var ForgotUsername = require("login/data/models/ForgotUsername.js");
var ForgotPassword = require("login/data/models/ForgotPassword.js");

var ForgotDialog = React.createClass({
  getInitialState: function () {
    return {
      email: this.props.dialogObject.email,
      mode: this.props.dialogObject.mode,

      formErrors: {},
    };
  },

  componentDidMount: function () {
    var that = this;
    // lame hack...for whatever reason...focus on these dialogs is hard..something is overridding them during the initial render
    window.setTimeout(function () {
      ReactDOM.findDOMNode(that.refs.emailInput).focus();
    }, 200);
  },

  _changeEmail: function (e) {
    var newFormErrors = this.state.formErrors;
    delete newFormErrors.email;
    delete newFormErrors.notFound;
    this.setState({
      email: e.currentTarget.value,
      formErrors: newFormErrors,
    });
  },

  _submit: function (e) {
    var that = this;

    var form;
    if (this.state.mode == "password") {
      form = new ForgotPassword({
        email: this.state.email,
      });
    } else {
      form = new ForgotUsername({
        email: this.state.email,
      });
    }

    if (this._validate()) {
      form.save(null, {
        success: function () {
          that._close();
        },
        error: function () {
          var newFormErrors = that.state.formErrors;
          newFormErrors.notFound = "account not found";
          that.setState({ formErrors: newFormErrors });
        },
      });
    }

    // prevent form driven redirect
    e.preventDefault();
  },

  _close: function (e) {
    // want to hook this into an update on the dashboard...do it here
    if (this.props.dialogObject.closingCallback) {
      this.props.dialogObject.closingCallback();
    }
    this.props.close();
  },

  _validate: function () {
    // make sure the email field works out...true if it does...false if not...
    // not all browsers supported the type = email input..which would be nice if they did
    var returnVal = true;

    if (!this.state.email || this.state.email.length == 0) {
      returnVal = false;
      var newFormErrors = this.state.formErrors;
      newFormErrors.email = "required field";

      this.setState({ formErrors: newFormErrors });
    } else {
      // run a real simple (wrong) regex on it...won't catch everything...but the server side also does a check
      var re = /.*?@.*?\..*/;
      var match = re.exec(this.state.email);
      if (!match || match.length == 0) {
        returnVal = false;
        var newFormErrors = this.state.formErrors;
        newFormErrors.email = "invalid email";

        this.setState({ formErrors: newFormErrors });
      }
    }

    return returnVal;
  },

  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      Forgot: true,
    });

    var errorMessage = <div />;
    var emailInputClass = "";
    if (this.state.formErrors && this.state.formErrors.email && this.state.formErrors.email.length > 0) {
      emailInputClass = "error";
      errorMessage = (
        <div className="error-text">
          <p>Invalid email address</p>
        </div>
      );
    }

    if (this.state.formErrors && this.state.formErrors.notFound && this.state.formErrors.notFound.length > 0) {
      errorMessage = (
        <div className="error-text">
          <p>Account not found</p>
        </div>
      );
    }

    var title;
    var body;
    if (this.state.mode == "password") {
      title = (
        <div className="text">
          <i className="fa fa-key" />
          Password Reset
        </div>
      );
      body = "An email will be sent to your recovery email with instructions to reset your password.";
    } else {
      title = (
        <div className="text">
          <i className="fa fa-user" />
          Username Recovery
        </div>
      );
      body = "An email containing your username will be sent to your recovery email.";
    }

    return (
      <div className={classes}>
        <div className="title-container">{title}</div>

        <div className="body-container">
          <form className="body-form" onSubmit={this._submit} action="">
            <div className="form-group">
              <label>Your recovery e-mail:</label>
              <input
                type="text"
                className={emailInputClass}
                ref="emailInput"
                name="emailInput"
                defaultValue={this.state.email}
                onChange={this._changeEmail}
              />
            </div>

            <p>{body}</p>

            {errorMessage}

            <div className="action-bar">
              <button type="submit" className="submit-button">
                Submit
              </button>
              <button type="button" className="cancel-button" onClick={this._close} onTouchEnd={this._close}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  },
});

module.exports = ForgotDialog;
