import React from "react";
import classnames from "@/_lib/utils/classnames";

import "./_styles.scss";

interface Props {
  cellClassNames: string;
  effects: React.CSSProperties;
  leftColText: string;
  leftHandColumnId: number;
  rowClassNames: string;
  rowIndex: number;
  height: string;
}

const PrintColumnSlotHeaderItem = (props: Props): JSX.Element => {
  const { cellClassNames, effects, leftColText, leftHandColumnId, rowClassNames, rowIndex, height } = props;
  const printMatrixIdentifier = `slot-row-member-id-${rowIndex}-${leftHandColumnId}`;

  const classes = classnames({
    ["column-print-header-wrapper"]: true,
    [printMatrixIdentifier]: true,
  });

  return (
    <div className={`${classes} ${rowClassNames}`} style={{ height }} key={`${leftColText}-${leftHandColumnId}`}>
      <div className={rowClassNames} style={effects}>
        <div className={cellClassNames} style={effects}>
          {leftColText}
        </div>
      </div>
    </div>
  );
};

export default PrintColumnSlotHeaderItem;
