export type AppContext = "viewer" | "editor" | "dashboard" | "reports" | "login";

export function getUrlParameter(name: string): string {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export const getAppContext = (): AppContext => {
  const path = window.location.pathname.toString();
  if (path.startsWith("/dashboard") || path === "/" || path.startsWith("/login")) {
    return "dashboard";
  } else if (path.startsWith("/viewer")) {
    return "viewer";
  } else if (path.startsWith("/editor")) {
    return "editor";
  } else if (path.startsWith("/reports")) {
    return "reports";
  } else {
    throw new Error("Unrecognized url");
  }
};
