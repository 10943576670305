"use strict";

var React = require("react/addons");
var $ = require("jquery");

var ErrorDialog = React.createClass({
  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {},
  componentWillUnmount: function () {},

  /* publics */

  /* privates */
  _close: function (e) {
    this.props.close();
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      Dialog: true,
      error: true,
    });

    return (
      <div className={classes}>
        <div className="title-container">
          <div className="text">
            <i className="fa fa-exclamation-triangle" />
            {this.props.dialogObject.title}
          </div>
        </div>
        <p>{this.props.dialogObject.message}</p>
      </div>
    );

    /*
				<div className='action-bar'>
					<div className='cancel-button' onClick={this._close}>Close</div>
				</div>
		*/
  },
});

module.exports = ErrorDialog;
