const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");

var Permissions = Backbone.Model.extend({
  // User model..but we get most (all) of this data from the permissions endpoint
  urlRoot: ApiConfig.getPrefix() + "/permissions",
  idAttribute: "emp_id",

  defaults: {
    // some constants
    LEVEL: {
      NOACCESS: 0,
      READ: 1,
      WRITE: 2,
    },

    FEATURE: {
      SCHEDULE: "schedule",
      REQUESTS: "requests",
      REPORTS: "reports",
      SCHEDULE_LOA: "LOAReason",
      REQUESTS_LOA: "requestLOAReason",
      SCHEDULE_NOTES: "notes",
      REQUEST_NOTES: "requestNotes",
      SCHEDULE_ALLOWDENY: "swapAllowDeny",
      REQUEST_ALLOWDENY: "requestAllowDeny",
      SCHEDULE_NOTIFY: "notification",
      REQUEST_NOTIFY: "requestNotification",
      REQUEST_DELETE: "requestDelete",
    },

    MRC_DEPARTMENT_ID: -98765,
    MRC_TEMPLATE_NAME: "Master Request Calendar",
  },

  initialize: function () {
    // make this data a little more useful lookups
    var that = this;
    this.attributes.parsed = {};

    _.each(this.get("data"), function (i) {
      var topLevelKey = i["feature"] + "~" + i["template"];

      that.attributes.parsed[topLevelKey] = {};

      that.attributes.parsed[topLevelKey]["personalAccess"] = i["personal_access"];
      that.attributes.parsed[topLevelKey]["departmentAccess"] = i["department_access"];
      that.attributes.parsed[topLevelKey]["intersections"] = i["intersections"];
      that.attributes.parsed[topLevelKey]["intersectionsByPType"] = _.groupBy(i["intersections"], function (j) {
        return j["ptype_id"];
      });
      that.attributes.parsed[topLevelKey]["intersectionsByAType"] = _.groupBy(i["intersections"], function (j) {
        return j["atype_id"];
      });

      // maintain a global permissions for each feature as well
      var globalLevelKey = i["feature"] + "~ALL";
      if (that.attributes.parsed[globalLevelKey] === undefined) {
        that.attributes.parsed[globalLevelKey] = {};
        that.attributes.parsed[globalLevelKey]["personalAccess"] = that.get("LEVEL")["NOACCESS"];
        that.attributes.parsed[globalLevelKey]["departmentAccess"] = that.get("LEVEL")["NOACCESS"];
        that.attributes.parsed[globalLevelKey]["intersections"] = [];
        that.attributes.parsed[globalLevelKey]["intersectionsByPType"] = {};
        that.attributes.parsed[globalLevelKey]["intersectionsByAType"] = {};
      }

      that.attributes.parsed[globalLevelKey]["personalAccess"] = that._resolveLevel(
        that.attributes.parsed[globalLevelKey]["personalAccess"],
        i["personal_access"]
      );
      that.attributes.parsed[globalLevelKey]["departmentAccess"] = that._resolveLevel(
        that.attributes.parsed[globalLevelKey]["departmentAccess"],
        i["department_access"]
      );
      Array.prototype.push.apply(that.attributes.parsed[globalLevelKey]["intersections"], i["intersections"]);

      // ugghhhhh
      var tempP = _.groupBy(i["intersections"], function (j) {
        return j["ptype_id"];
      });
      _.each(tempP, function (v, k) {
        if (that.attributes.parsed[globalLevelKey]["intersectionsByPType"][k] === undefined) {
          that.attributes.parsed[globalLevelKey]["intersectionsByPType"][k] = [];
        }
        Array.prototype.push.apply(that.attributes.parsed[globalLevelKey]["intersectionsByPType"][k], v);
      });

      // ugghhhhh x 2
      var tempA = _.groupBy(i["intersections"], function (j) {
        return j["atype_id"];
      });
      _.each(tempA, function (v, k) {
        if (that.attributes.parsed[globalLevelKey]["intersectionsByAType"][k] === undefined) {
          that.attributes.parsed[globalLevelKey]["intersectionsByAType"][k] = [];
        }
        Array.prototype.push.apply(that.attributes.parsed[globalLevelKey]["intersectionsByAType"][k], v);
      });
    });
  },

  isS1Client: function () {
    return this.attributes.is_s1_client;
  },

  hasAccess: function (feature, template, empID, ptype, atype, assignID, pDepts, aDept, minLevel) {
    var that = this;
    var highestFoundLevel = this.get("LEVEL")["NOACCESS"];

    var data = this.get("data");
    var parsed = this.get("parsed");

    // okay 2 routes...persona based permissions...and non persona based permissions
    var element;
    var lookupKey;
    if (this.get("uses_intersections") === true) {
      // personas

      if (template == this.get("MRC_TEMPLATE_NAME")) {
        // eval mrc as anything basically
        lookupKey = [feature, "ALL"].join("~");
      } else {
        lookupKey = [feature, template].join("~");
      }

      element = parsed[lookupKey];

      if (element !== undefined) {
        if (this.get("is_super_admin")) {
          // super admins get the easy check
          highestFoundLevel = this._resolveLevel(highestFoundLevel, element["departmentAccess"]);
        } else {
          // others not so much
          if (ptype === "null" || ptype === undefined || atype === "null" || atype === undefined) {
            return false;
          }
          if (element["intersectionsByAType"][atype] === undefined) {
            return false;
          }

          // loop through permissions by atype
          _.each(element["intersectionsByAType"][atype], function (val) {
            if (val["ptype_id"] == ptype) {
              if (val["exclude_self"] === false || empID !== that.get("emp_id")) {
                highestFoundLevel = that._resolveLevel(highestFoundLevel, val["level"]);
              }
            }
            // -1 is the 'myself' case in permissions land
            if (empID === that.get("emp_id") && val["ptype_id"] === -1) {
              highestFoundLevel = that._resolveLevel(highestFoundLevel, val["level"]);
            }
          });
        }
      }
    } else {
      // non-personas
      feature = this._resolveFeature(feature);
      lookupKey = [feature, template].join("~");
      element = parsed[lookupKey];

      // department membership matters in this case
      var hasPDeptIntersection = _.intersection(this.get("my_depts"), pDepts).length > 0;
      var hasADeptIntersection = _.intersection(this.get("my_depts"), [aDept]).length > 0;

      element = parsed[lookupKey];
      if (element !== undefined) {
        if (
          hasPDeptIntersection &&
          hasADeptIntersection &&
          (this._hasViewAccess(feature, assignID, template) || this.get("is_super_admin") === true)
        ) {
          if (empID === this.get("emp_id")) {
            highestFoundLevel = element["personalAccess"];
          } else {
            highestFoundLevel = element["departmentAccess"];
          }
        }
      }
    }

    // finally
    return highestFoundLevel >= minLevel;
  },

  hasAccessPType: function (feature, empID, ptype, pDepts, minLevel) {
    var that = this;
    var highestFoundLevel = this.get("LEVEL")["NOACCESS"];

    var data = this.get("data");
    var parsed = this.get("parsed");

    // okay 2 routes...persona based permissions...and non persona based permissions
    var element;
    var lookupKey;
    if (this.get("uses_intersections") === true) {
      // personas

      lookupKey = [feature, "ALL"].join("~");
      element = parsed[lookupKey];

      if (element !== undefined) {
        if (this.get("is_super_admin")) {
          // super admins get the easy check
          highestFoundLevel = this._resolveLevel(highestFoundLevel, element["departmentAccess"]);
        } else {
          // others not so much
          if (ptype === "null" || ptype === undefined) {
            return false;
          }

          // loop through permissions by ptype....twice once for a real type, once for the self type
          _.each(element["intersectionsByPType"][ptype], function (val) {
            if (!val["exclude_self"] || empID != that.get("emp_id")) {
              highestFoundLevel = that._resolveLevel(highestFoundLevel, val["level"]);
            }
          });

          // only do this one if its me
          if (empID == this.get("emp_id")) {
            _.each(element["intersectionsByPType"][-1], function (val) {
              highestFoundLevel = that._resolveLevel(highestFoundLevel, val["level"]);
            });
          }
        }
      }
    } else {
      // non-personas
      feature = this._resolveFeature(feature);
      lookupKey = [feature, "ALL"].join("~");
      element = parsed[lookupKey];

      // department membership matters in this case
      var hasDeptIntersection = _.intersection(this.get("my_depts"), pDepts).length > 0;

      element = parsed[lookupKey];
      if (element !== undefined && hasDeptIntersection) {
        if (empID === this.get("emp_id")) {
          highestFoundLevel = element["personalAccess"];
        } else {
          highestFoundLevel = element["departmentAccess"];
        }
      }
    }

    // finally
    return highestFoundLevel >= minLevel;
  },

  hasAccessAType: function (feature, atype, assignID, aDept, minLevel) {
    var that = this;
    var highestFoundLevel = this.get("LEVEL")["NOACCESS"];

    var data = this.get("data");
    var parsed = this.get("parsed");

    // okay 2 routes...persona based permissions...and non persona based permissions
    var element;
    var lookupKey;
    if (this.get("uses_intersections") === true) {
      // personas

      lookupKey = [feature, "ALL"].join("~");
      element = parsed[lookupKey];

      if (element !== undefined) {
        if (this.get("is_super_admin")) {
          // super admins get the easy check
          highestFoundLevel = this._resolveLevel(highestFoundLevel, element["departmentAccess"]);
        } else {
          // others not so much
          if (atype === "null" || atype === undefined) {
            return false;
          }

          // loop through permissions by atype
          _.each(element["intersectionsByAType"][atype], function (val) {
            highestFoundLevel = that._resolveLevel(highestFoundLevel, val["level"]);
          });
        }
      }
    } else {
      // non-personas
      feature = this._resolveFeature(feature);
      lookupKey = [feature, "ALL"].join("~");
      element = parsed[lookupKey];

      // department membership matters in this case
      var hasDeptIntersection = _.intersection(this.get("my_depts"), [aDept]).length > 0;

      element = parsed[lookupKey];
      if (
        element !== undefined &&
        hasDeptIntersection &&
        (this.get("is_admin") || this._hasViewAccess(feature, assignID, template))
      ) {
        highestFoundLevel = this._resolveLevel(highestFoundLevel, element["personalAccess"]);
        highestFoundLevel = this._resolveLevel(highestFoundLevel, element["departmentAccess"]);
      }
    }

    // finally
    return highestFoundLevel >= minLevel;
  },

  hasAccessATypeOnTemplate: function (feature, atype, assignID, aDept, template, minLevel) {
    var that = this;
    var highestFoundLevel = this.get("LEVEL")["NOACCESS"];

    var data = this.get("data");
    var parsed = this.get("parsed");

    // okay 2 routes...persona based permissions...and non persona based permissions
    var element;
    var lookupKey;
    if (this.get("uses_intersections") === true) {
      // personas

      lookupKey = [feature, template].join("~");
      element = parsed[lookupKey];

      if (element !== undefined) {
        if (this.get("is_super_admin")) {
          // super admins get the easy check
          highestFoundLevel = this._resolveLevel(highestFoundLevel, element["departmentAccess"]);
        } else {
          // others not so much
          if (atype === "null" || atype === undefined) {
            return false;
          }

          // loop through permissions by atype
          _.each(element["intersectionsByAType"][atype], function (val) {
            highestFoundLevel = that._resolveLevel(highestFoundLevel, val["level"]);
          });
        }
      }
    } else {
      // non-personas
      feature = this._resolveFeature(feature);
      lookupKey = [feature, template].join("~");
      element = parsed[lookupKey];

      // department membership matters in this case
      var hasDeptIntersection = _.intersection(this.get("my_depts"), [aDept]).length > 0;

      element = parsed[lookupKey];
      if (
        element !== undefined &&
        hasDeptIntersection &&
        (this.get("is_admin") || this._hasViewAccess(feature, assignID, template))
      ) {
        highestFoundLevel = this._resolveLevel(highestFoundLevel, element["personalAccess"]);
        highestFoundLevel = this._resolveLevel(highestFoundLevel, element["departmentAccess"]);
      }
    }

    // finally
    return highestFoundLevel >= minLevel;
  },

  hasAccessTemplate: function (feature, template, minLevel) {
    var that = this;
    var highestFoundLevel = this.get("LEVEL")["NOACCESS"];

    var data = this.get("data");
    var parsed = this.get("parsed");

    // okay 2 routes...persona based permissions...and non persona based permissions
    var element;
    var lookupKey;
    if (this.get("uses_intersections") === true) {
      // personas

      lookupKey = [feature, template].join("~");
      element = parsed[lookupKey];

      if (element !== undefined) {
        highestFoundLevel = this._resolveLevel(highestFoundLevel, element["personalAccess"]);
        highestFoundLevel = this._resolveLevel(highestFoundLevel, element["departmentAccess"]);
      }
    } else {
      // non-personas
      feature = this._resolveFeature(feature);
      lookupKey = [feature, template].join("~");
      element = parsed[lookupKey];

      element = parsed[lookupKey];
      if (element !== undefined) {
        highestFoundLevel = this._resolveLevel(highestFoundLevel, element["personalAccess"]);
        highestFoundLevel = this._resolveLevel(highestFoundLevel, element["departmentAccess"]);
      }
    }

    // finally
    return highestFoundLevel >= minLevel;
  },

  hasAccessAnyOtherPersonnel: function (feature, minLevel) {
    // this check is easy, personas non personas doesn't matter (I don't think)
    var that = this;
    var highestFoundLevel = this.get("LEVEL")["NOACCESS"];

    var data = this.get("data");
    var parsed = this.get("parsed");

    // okay 2 routes...persona based permissions...and non persona based permissions
    var element;
    var lookupKey;
    if (this.get("uses_intersections") === true) {
      // personas

      lookupKey = [feature, "ALL"].join("~");
      element = parsed[lookupKey];

      if (element !== undefined) {
        highestFoundLevel = this._resolveLevel(highestFoundLevel, element["departmentAccess"]);
      }
    } else {
      // non-personas
      feature = this._resolveFeature(feature);
      lookupKey = [feature, "ALL"].join("~");
      element = parsed[lookupKey];

      element = parsed[lookupKey];
      if (element !== undefined) {
        highestFoundLevel = this._resolveLevel(highestFoundLevel, element["departmentAccess"]);
      }
    }

    // finally
    return highestFoundLevel >= minLevel;
  },

  hasAccessAnyPersonnel: function (feature, minLevel) {
    // this check is easy, personas non personas doesn't matter (I don't think)
    var that = this;
    var highestFoundLevel = this.get("LEVEL")["NOACCESS"];

    var data = this.get("data");
    var parsed = this.get("parsed");

    // okay 2 routes...persona based permissions...and non persona based permissions
    var element;
    var lookupKey;
    if (this.get("uses_intersections") === true) {
      // personas

      lookupKey = [feature, "ALL"].join("~");
      element = parsed[lookupKey];

      if (element !== undefined) {
        highestFoundLevel = this._resolveLevel(highestFoundLevel, element["personalAccess"]);
        highestFoundLevel = this._resolveLevel(highestFoundLevel, element["departmentAccess"]);
      }
    } else {
      // non-personas
      feature = this._resolveFeature(feature);
      lookupKey = [feature, "ALL"].join("~");
      element = parsed[lookupKey];

      element = parsed[lookupKey];
      if (element !== undefined) {
        highestFoundLevel = this._resolveLevel(highestFoundLevel, element["personalAccess"]);
        highestFoundLevel = this._resolveLevel(highestFoundLevel, element["departmentAccess"]);
      }
    }

    // finally
    return highestFoundLevel >= minLevel;
  },

  _resolveLevel: function (highestFoundLevel, checkThisLevel) {
    var returnLevel = highestFoundLevel;

    if (highestFoundLevel === this.get("LEVEL")["NOACCESS"]) {
      returnLevel = checkThisLevel;
    } else if (highestFoundLevel === this.get("LEVEL")["READ"] && checkThisLevel !== this.get("LEVEL")["NOACCESS"]) {
      returnLevel = checkThisLevel;
    }

    return returnLevel;
  },

  _resolveFeature: function (feature) {
    // non-persona permissions aren't nearly as extravegent as persona permissions...and so there are 'features' that don't
    // even exist for them...in which case...don't bother send redudent data on the api...resolve to the parent here
    if (feature == this.get("FEATURE")["SCHEDULE_ALLOWDENY"]) {
      return this.get("FEATURE")["SCHEDULE"];
    } else if (feature == this.get("FEATURE")["REQUEST_ALLOWDENY"]) {
      return this.get("FEATURE")["REQUESTS"];
    } else if (feature == this.get("FEATURE")["REQUEST_DELETE"]) {
      return this.get("FEATURE")["REQUESTS"];
    } else {
      return feature;
    }
  },

  _hasViewAccess: function (feature, assignID, template) {
    let hasAccess = true;
    if (template == this.get("MRC_TEMPLATE_NAME")) return hasAccess;

    if (feature == this.get("FEATURE")["SCHEDULE"] || feature == this.get("FEATURE")["SCHEDULE_NOTES"]) {
      hasAccess = _.intersection(this.get("no_view_access_schedule_per_template")[template], assignID).length === 0;
    } else if (feature == this.get("FEATURE")["REQUESTS"] || feature == this.get("FEATURE")["REQUEST_NOTES"]) {
      hasAccess = _.intersection(this.get("no_view_access_request_per_template")[template], assignID).length === 0;
    }
    return hasAccess;
  },
});

module.exports = Permissions;
