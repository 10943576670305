import React, { useEffect, useRef, useState } from "react";
import { Moment } from "moment-timezone";
import moment from "moment";

import classnames from "@/_lib/utils/classnames";
import { momentToDate } from "@/viewer/ui/modules/common/helpers/dates";
import { UIContext } from "@/viewer/ui/modules/common/types/context";
import useOnClickOutside from "@/_lib/utils/useOnClickOutside";
import Button from "@/common/components/button/Button";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const DatePicker = require("_lib/ui/modules/widgets/datepicker/DatePicker.jsx");

interface Props {
  actionOnClick: (startDate: Date, endDate: Date) => void;
  actionText: string;
  icon: string;
  ui: UIContext;
  children?: React.ReactNode;
  loading?: boolean;
  enabled?: boolean;
  InfoIcon?: JSX.Element;
  ignoreClickEventTargetRefs?: React.Ref<unknown>[];
  ignoreClickOutside?: boolean;
}

const Dialog = (props: Props): JSX.Element => {
  const {
    actionOnClick,
    actionText,
    children,
    icon,
    InfoIcon,
    loading = false,
    ui,
    enabled = true,
    ignoreClickEventTargetRefs = [],
    ignoreClickOutside = false,
  } = props;

  const [startDate, setStartDate] = useState<moment.Moment>(moment(ui.startDate));
  const [stopDate, setStopDate] = useState<moment.Moment>(moment(ui.stopDate));
  const [editingStartDate, setEditingStartDate] = useState<boolean>(false);
  const [editingStopDate, setEditingStopDate] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const dropDownMenu = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropDownMenu, () => setShowMenu(false), ignoreClickEventTargetRefs, ignoreClickOutside);

  /**
   * We want to keep track of the dates being changed from the top bar, so
   * whenever the print dialog is opened, it displays the date range the
   * user is currently on
   */

  useEffect(() => {
    setStartDate(moment(ui.startDate));

    if (ui.dateMode === "daily") {
      setStopDate(moment(ui.startDate));
    } else {
      setStopDate(moment(ui.stopDate));
    }
  }, [ui.startDate.getTime(), ui.stopDate.getTime()]);

  const toggleMenu = async () => {
    setShowMenu(!showMenu);
  };

  const updateStartTime = (date: Moment) => {
    setStartDate(date);
    setEditingStartDate(false);
  };

  const updateStopTime = (date: Moment) => {
    setStopDate(date);
    setEditingStopDate(false);
  };

  const getSelectedDate = (date: Moment) => {
    const getSelectedDateObject: { [k: string]: boolean } = {};

    getSelectedDateObject[date.format("YYYYMMDD")] = true;

    return getSelectedDateObject;
  };

  const actionStyle = children ? {} : { marginTop: "20px" };

  const rootClassNames = classnames("dropdown", "top-bar-btn", { disabled: !enabled });
  const clickTargetClassNames = classnames("no-mobile", "btn-icon");
  const clickTargetIconClassNames = classnames("fa", "top-bar-icon", { [icon]: true, selected: showMenu });
  const modalContainerClassNames = classnames("menu", { ["open right print"]: showMenu });
  const endDatePickerClassNames = classnames("option-label", "mt-20");
  const dateRangeWarningClassNames = classnames({ bold: true, warn: true, ["mt-10"]: true });
  const dialogButtonContainerClassNames = classnames("dialog__button-container", { iconContainer: InfoIcon });

  return (
    <div className={rootClassNames} ref={dropDownMenu}>
      <div onClick={() => toggleMenu()} onTouchEnd={() => toggleMenu()} className={clickTargetClassNames}>
        <i className={clickTargetIconClassNames} />
      </div>
      <div className={modalContainerClassNames} id="dropdown__modal-container">
        <div className="flex-1">
          <div className="option-label">Start Date</div>
          <div>
            {editingStartDate ? (
              <DatePicker
                date={startDate}
                mode={"daily"}
                onSubmit={(m: Moment) => updateStartTime(m.startOf("day"))}
                options={{ noSubmit: true }}
                selectedDates={getSelectedDate(startDate)}
              />
            ) : (
              <button onClick={() => setEditingStartDate(true)} className="btn">
                {startDate.format("ddd, LL")}
              </button>
            )}
          </div>
          <div className={endDatePickerClassNames}>End Date</div>
          <div>
            {editingStopDate ? (
              <DatePicker
                date={stopDate}
                mode={"daily"}
                onSubmit={(m: Moment) => updateStopTime(m.endOf("day"))}
                options={{ noSubmit: true }}
                selectedDates={getSelectedDate(stopDate)}
              />
            ) : (
              <button onClick={() => setEditingStopDate(true)} className="btn">
                {stopDate.format("ddd, LL")}
              </button>
            )}
          </div>

          {startDate && stopDate && stopDate < startDate && (
            <div className={dateRangeWarningClassNames}>Please input a valid date range.</div>
          )}

          {children}
          <div className={dialogButtonContainerClassNames}>
            <Button
              disabled={!(startDate && stopDate && stopDate >= startDate)}
              loading={loading}
              onClick={() => actionOnClick(momentToDate(startDate.startOf("day")), momentToDate(stopDate.endOf("day")))}
              style={actionStyle}
            >
              {actionText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
