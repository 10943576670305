import React from "react";
import Select, {
  components,
  Props as SelectProps,
  ActionMeta,
  MenuProps,
  MultiValueProps,
  StylesConfig,
  Theme,
} from "react-select";
import { StateManager } from "react-select/src/stateManager";
import { Option as FilterOption } from "react-select/src/filters";
import { FieldProps, getIn, useField } from "formik";
import { ClickAwayListener } from "@material-ui/core";

import useUpdateEffect from "@/viewer/ui/modules/common/hooks/useUpdateEffect";
import classnames from "@/_lib/utils/classnames";

import Label from "../meta/Label";
import ErrorMessage from "../meta/ErrorMessage";
import Checkbox from "../../checkbox/Checkbox";
import styles from "./styles.module.scss";

interface Option {
  [key: string]: unknown;
}

interface GroupOption {
  label: string;
  options: Option[];
}

interface Props extends SelectProps<Option, boolean>, FieldProps<null | Option | Option[]> {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  clearable?: boolean;
  loading?: boolean;
  searchable?: boolean;
  disableManySelect?: boolean;
  getOptionLabel?: (option: any) => any;
  getOptionValue?: (option: any) => any;
  onSelectItem?: null | ((isMulti: boolean) => void);
  onClose?: () => void;
  options: Option[] | GroupOption[];
  lightMode?: boolean;
}

const MAX_SHOWN_CHIPS_COUNT = 1;

const getCustomTheme = () => (theme: Theme) => ({
  ...theme,
  borderRadius: 2,
  colors: {
    ...theme.colors,
    primary: "rgb(66, 179, 227, 0.7)",
    primary25: "#F0F4FF",
    primary50: "#DCE7FF",
  },
});

const getCustomStyles = ({
  value,
  disabled,
  touch,
  error,
  isManyChecked = false,
  lightMode = false,
}: {
  value: null | Option | Option[];
  disabled: boolean;
  touch: boolean;
  error: string;
  isManyChecked?: boolean;
  lightMode?: boolean;
}): StylesConfig<Option, boolean> => ({
  control: (provided, state) => ({
    ...provided,
    color: "rgba(0, 0, 0, 0.85)",
    backgroundColor: "#FFFFFF",
    fontSize: "14px",
    lineHeight: 1.6,
    padding: "0 0 0 11px",
    transition: "all 0.3s",
    cursor: state.isFocused ? "text" : "pointer",
    border: "1px solid #D9D9D9",
    borderRadius: "2px",
    opacity: lightMode ? 0.5 : 1,
    borderColor: disabled
      ? "#D9D9D9 !important"
      : touch && error
      ? "#E26868 !important"
      : state.isFocused
      ? "#40A9FF !important"
      : "#D9D9D9",
    boxShadow:
      state.isFocused && !disabled
        ? touch && error
          ? "0 0 0 2px rgba(226, 104, 104, 0.2)"
          : "0 0 0 2px rgba(24, 144, 255, 0.2)"
        : "none",
    "&:hover": {
      borderColor: touch && error ? "#E26868" : "#42B3E3",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 11px 0 0",
    "&:after": {
      content:
        Array.isArray(value) && isManyChecked && value.length > MAX_SHOWN_CHIPS_COUNT
          ? `"+ ${value.length - MAX_SHOWN_CHIPS_COUNT}"`
          : '""',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.85)",
    opacity: "0.4 !important",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1000,
  }),
  group: (provided, state) => ({
    ...provided,
    ...(!state.label ? { paddingTop: 0 } : {}),
  }),
  groupHeading: (provided, state) => ({
    ...provided,
    ...(!state.children ? { marginBottom: 0 } : {}),
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    cursor: state.data.isDisabled ? "not-allowed" : "pointer",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: 11,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),
});

const mapOptionsValues = (options: Option[], getOptionValue: (option: any) => any) => {
  return options.flatMap((o) => getOptionValue(o) ?? []);
};

const getTransformedValue = (
  options: Option[] | GroupOption[],
  formikValue: null | Option | Option[],
  disableManySelect: boolean,
  isGrouped: boolean,
  getOptionValue: (option: any) => any
): null | Option | Option[] => {
  if (disableManySelect) {
    return isGrouped
      ? (options as GroupOption[])
          .reduce<Option[]>((acc, { options }) => [...acc, ...options], [])
          .find((o) => formikValue && getOptionValue(formikValue) === getOptionValue(o)) ?? null
      : (options as Option[]).find((o) => formikValue && getOptionValue(formikValue) === getOptionValue(o)) ?? null;
  }

  return isGrouped
    ? (options as GroupOption[])
        .reduce<Option[]>((acc, { options }) => [...acc, ...options], [])
        .filter(
          (o) => Array.isArray(formikValue) && mapOptionsValues(formikValue, getOptionValue).includes(getOptionValue(o))
        )
    : (options as Option[]).filter(
        (o) => Array.isArray(formikValue) && mapOptionsValues(formikValue, getOptionValue).includes(getOptionValue(o))
      );
};

const flattenOptions = (options: Option[] | GroupOption[], isGrouped: boolean): Option[] => {
  return isGrouped
    ? (options as GroupOption[]).reduce<Option[]>((acc, { options }) => [...acc, ...options], [])
    : (options as Option[]);
};

const filterOption = (option: FilterOption, input: string): boolean => {
  return option.label?.toLowerCase().includes(input.toLowerCase()) ?? false;
};

const formatOptionLabel =
  (getOptionLabel: (option: Option) => string) =>
  (option: any): any => {
    return option.isBlock ? (
      <>
        {getOptionLabel(option)} <i className="fa fa-fw fa-cubes" />
      </>
    ) : (
      getOptionLabel(option)
    );
  };

const RenderSelectField = React.forwardRef<StateManager<Option>, Props>(
  (
    {
      field: { name, value },
      form: { errors, touched, setFieldValue, setFieldTouched },
      label = "",
      placeholder = "",
      disabled = false,
      clearable = true,
      loading = false,
      searchable = true,
      disableManySelect = false,
      lightMode = false,
      onSelectItem = null,
      onClose = () => null,
      getOptionLabel = (option) => option.label,
      getOptionValue = (option) => option.value,
      options,
      ...rest
    },
    ref
  ): JSX.Element => {
    const isGrouped = (options as GroupOption[]).every(({ options }) => Array.isArray(options));
    const transformedValue = getTransformedValue(options, value, disableManySelect, isGrouped, getOptionValue);
    const flattenedOptions = flattenOptions(options, isGrouped);

    const [filteredOptions, setFilteredOptions] = React.useState(flattenedOptions);
    const [isManyChecked, setManyChecked] = React.useState(
      Array.isArray(value) && value.length > 1 && !disableManySelect
    );

    const error = getIn(errors, name);
    const touch = getIn(touched, name);

    const rootClassNames = classnames({
      [styles.root]: true,
      [styles.disabled]: disabled,
    });

    useUpdateEffect(() => {
      setFilteredOptions(flattenedOptions);
    }, [flattenedOptions.length]);

    useUpdateEffect(() => {
      setManyChecked((prevState) => !prevState && !disableManySelect);
    }, [disableManySelect]);

    useUpdateEffect(() => {
      if (Array.isArray(value) && value.length > 1 && !isManyChecked) setManyChecked(true);
    }, [value]);

    const handleBlur = () => {
      setFieldTouched(name, true);
    };

    const handleChange = (option: unknown | unknown[], meta: ActionMeta<Option>) => {
      if (onSelectItem) onSelectItem(isManyChecked);

      if (!option || meta.action === "clear") {
        return setFieldValue(name, disableManySelect ? null : []);
      }

      if (Array.isArray(option)) {
        const isBlock = meta.option?.isBlock || option.some((o) => o.isBlock);
        const newValue = isBlock ? [meta.option] : option;
        setFieldValue(name, newValue);
      } else {
        const newValue = option;
        setFieldValue(name, disableManySelect ? newValue : [newValue]);
      }
    };

    const handleInputChange = (input: string) => {
      if (!input) return setFilteredOptions(flattenedOptions);

      const newValue = flattenedOptions.filter((o) => {
        return filterOption(
          {
            label: getOptionLabel(o),
            value: getOptionValue(o),
            data: o,
          },
          input
        );
      });

      setFilteredOptions(newValue);
    };

    const MultiValueContainer = React.useCallback(
      ({ children, data, ...rest }: MultiValueProps<Option>) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [{ value }] = useField(name);
        const position = data && mapOptionsValues(value, getOptionValue).indexOf(getOptionValue(data));

        return position >= MAX_SHOWN_CHIPS_COUNT ? null : (
          <components.MultiValueContainer data={data} {...rest}>
            {children}
          </components.MultiValueContainer>
        );
      },
      [getOptionValue]
    );

    const Menu = React.useCallback(
      ({ children, ...rest }: MenuProps<Option, boolean>) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [{ value }] = useField(name);

        const isClearDisabled = value?.length === 0;
        const isSelectAllDisabled =
          (Array.isArray(value) &&
            flattenedOptions.some(
              (o) => o.isBlock && mapOptionsValues(value, getOptionValue).includes(getOptionValue(o))
            )) ||
          flattenedOptions.length === value?.length ||
          !isManyChecked;

        const selectAllClassNames = classnames({
          [styles.action]: true,
          [styles.actionDisabled]: isSelectAllDisabled,
        });

        const clearClassNames = classnames({
          [styles.action]: true,
          [styles.actionDisabled]: isClearDisabled,
        });

        const handleSelectAllClick = () => {
          setFieldValue(name, [
            ...value,
            ...filteredOptions.filter(
              (o) => !o.isBlock && !mapOptionsValues(value, getOptionValue).includes(getOptionValue(o))
            ),
          ]);
        };

        const handleClearClick = () => {
          setFieldValue(name, [
            ...value.filter(
              (value: Option) => !mapOptionsValues(filteredOptions, getOptionValue).includes(getOptionValue(value))
            ),
          ]);
        };

        const handleSelectManyClick = () => {
          if (isManyChecked) setFieldValue(name, []);
          setManyChecked((prevState) => !prevState);
        };

        return (
          <components.Menu {...rest}>
            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={onClose}>
              <div id="select-menu">
                {children}
                {!disableManySelect && (
                  <div className={styles.dropdownMenuFooter}>
                    <div className={styles.leftActionsWrapper}>
                      <p id="select-menu-select-all" className={selectAllClassNames} onClick={handleSelectAllClick}>
                        Select All
                      </p>
                      <div className={styles.actionDivider}>|</div>
                      <p id="select-menu-clear" className={clearClassNames} onClick={handleClearClick}>
                        Clear
                      </p>
                    </div>
                    <Checkbox
                      id="select-menu-select-many"
                      label="Select Many"
                      checked={isManyChecked}
                      onChange={handleSelectManyClick}
                    />
                  </div>
                )}
              </div>
            </ClickAwayListener>
          </components.Menu>
        );
      },
      [flattenedOptions.length, filteredOptions.length, isManyChecked, disableManySelect]
    );

    return (
      <div className={rootClassNames}>
        {label && <Label text={label} />}
        <Select
          ref={ref}
          theme={getCustomTheme()}
          styles={getCustomStyles({ value: transformedValue, disabled, touch, error, isManyChecked, lightMode })}
          components={{ MultiValueContainer, Menu }}
          value={transformedValue}
          onBlur={handleBlur}
          onChange={handleChange}
          onInputChange={handleInputChange}
          filterOption={filterOption}
          formatOptionLabel={formatOptionLabel(getOptionLabel)}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          placeholder={placeholder}
          options={options}
          isDisabled={disabled}
          isClearable={clearable}
          isLoading={loading}
          isSearchable={searchable}
          closeMenuOnSelect={!isManyChecked}
          isMulti={isManyChecked && !disableManySelect}
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          {...rest}
        />
        {touch && !!error && !disabled && <ErrorMessage text={error} />}
      </div>
    );
  }
);

export default RenderSelectField;
