import React from "react";
import moment from "moment";
import _ from "underscore";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Formik, FormikHelpers, setNestedObjectValues } from "formik";
import { Divider, Box } from "@material-ui/core";

import modalActions from "@/common/components/modal/actions";
import toastActions from "@/common/components/toast/actions";
import useSettings from "@/viewer/ui/modules/common/hooks/useSettings";
import { Tally } from "@/viewer/types/domain/tally";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { FetchReport, TriggerRedraw } from "@/common/utils";
import { saveTallies, createPayload } from "./helpers";

import Modal, { MaxWidth } from "@/common/components/modal/Modal";
import Toast, { ToastStatus } from "@/common/components/toast/Toast";
import Button from "@/common/components/button/Button";
import Content from "./Content";

import styles from "./styles.module.scss";

export interface FormValues {
  tallies: Tally[];
}

interface Props {
  slotQuest: SlotOrRequest;
}

export default function EditTallyCountModal({ slotQuest }: Props): React.ReactElement {
  const settings = useSettings();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const {
    assignCompactOrDisplayName: assignmentName,
    compactOrDisplayName: personnelName,
    templateDesc: templateName,
  } = slotQuest;
  const date = moment(slotQuest.date).local().format("ddd MM/DD/YYYY");
  const startTime = moment(slotQuest.startTime)
    .local()
    .format(settings.useMilitaryTime ? "HH:mm" : "hh:mm A");
  const stopTime = moment(slotQuest.stopTime)
    .local()
    .format(settings.useMilitaryTime ? "HH:mm" : "hh:mm A");

  const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    const errors = await helpers.validateForm();
    const fieldsToTouch = setNestedObjectValues<{ [key: string]: boolean }>(errors, true);
    const outstandingErrors = await helpers.setTouched(fieldsToTouch, true);
    if (!_.isEmpty(outstandingErrors)) return;

    setLoading(true);

    const payload = createPayload(values);
    const response = await saveTallies(slotQuest, payload);

    if (response.error) {
      dispatch(
        toastActions.open(
          <Toast status={ToastStatus.Error}>
            {response.error.message || "An unexpected error has occurred. Please refresh and try again"}
          </Toast>
        )
      );
    } else {
      await FetchReport();
      TriggerRedraw();
      dispatch(modalActions.close());
      dispatch(toastActions.open(<Toast status={ToastStatus.Success}>Tally counts successfully updated!</Toast>));
    }

    setLoading(false);
  };

  const initialValues: FormValues = {
    tallies: [],
  };

  const validationSchema = Yup.object().shape({
    tallies: Yup.array().of(
      Yup.object().shape({
        value: Yup.number().required("Must enter a value"),
      })
    ),
  });

  return (
    <Formik
      onSubmit={(values, helpers: FormikHelpers<FormValues>) => handleSubmit(values, helpers)}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ values, ...helpers }) => (
        <Modal
          draggable
          fullWidth
          maxWidth={MaxWidth.Xs}
          title={
            <>
              Edit Tally Counts
              <Box mt={0.5} mb={0.25} fontSize={11} color="text.primary">
                These updated counts will not affect auto-scheduling functionality.
              </Box>
            </>
          }
          negativeAction={
            <Button variant="filled" color="default" onClick={() => dispatch(modalActions.close())}>
              Close
            </Button>
          }
          positiveAction={
            <Button variant="filled" color="primary" loading={loading} onClick={() => handleSubmit(values, helpers)}>
              Submit
            </Button>
          }
        >
          <div className={styles.root}>
            <div className={styles.details}>
              {assignmentName} <br />
              {date} <br />
              {templateName} [{startTime} - {stopTime}] <br />
              {personnelName} <br />
            </div>
            <Divider />
            <Content slotQuest={slotQuest} />
          </div>
        </Modal>
      )}
    </Formik>
  );
}
