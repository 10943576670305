"use strict";

require("./_styles.scss");

var React = require("react/addons");
var redux = require("react-redux");
var $ = require("jquery");
var docCookies = require("_lib/utils/cookies.js");
var _ = require("underscore");
var actions = require("@/modules/login/actions.ts");
var getUrlParameter = require("_lib/utils/urls.ts").getUrlParameter;

var SSOConfig = require("_lib/data/SSOConfig.js");
const { default: ConfigHelper } = require("@/_lib/data/ConfigHelper");
const { LaunchDarklyClient } = require("@/_lib/utils/launchDarklyClient");
const { LDFlagEnums } = require("@/_lib/constants/LDFlagEnums");

var SplashScreen = React.createClass({
  getInitialState: function () {
    return {
      iconId: "logo-tile",
    };
  },
  componentDidMount: async function () {
    // track a pageview
    //window.LbsAppData.AnalyticsManager.sendPageview('/splash', 'Splash Screen');
    window.LbsAppData.Helpers.Analytics.sendPageview("/splash", "Splash Screen");
    await this._fetchLogo();
  },

  _fetchLogo: async function () {
    var envLaunchDarklyUserConfig = {
      email: "",
      key: ConfigHelper.launch_darkly_env_key,
      name: "",
      custom: {
        customerId: null,
        empId: null,
        isAdmin: null,
        userId: null,
      },
    };
    var launchDarkly = new LaunchDarklyClient(envLaunchDarklyUserConfig);
    await launchDarkly.client?.waitForInitialization();
    var showBrandingDesktop = await launchDarkly.evaluateLDFlag(LDFlagEnums.Lv9217DesktopBranding, false);
    this.setState({ iconId: showBrandingDesktop ? "ps-logo-tile" : "logo-tile" });
  },

  _fullLogin: function (e) {
    e.preventDefault();
    var name = $("#login_name").val();
    var pwd = $("#login_pwd").val();
    const origin = getUrlParameter("origin");
    const originHash = getUrlParameter("origin_hash") || window.location.hash.replace("#/", "");
    this.props.login(name, pwd, origin, originHash);
  },

  _trackLogin: function () {
    window.LbsAppData.Helpers.Analytics.sendEvent("Sign In");
  },

  _rawTokenLogin: function () {
    window.LbsAppData.AppContext.loginWithToken(this.props.token, this._callbackLogIn);
  },

  _redirectToSSO: function () {
    window.LbsAppData.Helpers.Analytics.sendEvent("Enterprise Login");

    var redirectUri = window.location.origin + "/api/v1/login";
    var queryParams = _.map(window.location.search.split(/[\?&]/), function (p) {
      return p.split("=");
    });
    queryParams.shift(); // get rid of the first one (matched '?')

    var encStateData = encodeURIComponent(JSON.stringify(_.object(queryParams)));
    window.location.href =
      SSOConfig.getPrefix() +
      "/access?redirect_uri=" +
      redirectUri +
      (queryParams.length > 0 ? "&state=" + encStateData : "");
  },

  _openForgotPasswordDialog: function () {
    window.LbsAppData.AppContext.openDialog({
      type: "forgot",
      mode: "password",
    });
  },

  _openForgotUsernameDialog: function () {
    window.LbsAppData.AppContext.openDialog({
      type: "forgot",
      mode: "username",
    });
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      SplashScreen: true,
    });

    var desc, message;
    var components = [];
    var message_text = this.props.message || window.payload.message;
    if (message_text) {
      message = <div className="popup-message">{message_text}</div>;
    }

    var logo = this.state.iconId;

    //var desc, message;
    //if (this.state.loggingIn) {
    //	message = <div className='popup-message'>Signing you in...</div>
    //} else if (!this.state.cookiePresent && !this.state.tokenPresent) {
    //desc = <div id='desc'><b>Sign in</b>. Try us out!</div>
    // onClick={this._fullLogin} onTouchEnd={this._fullLogin}
    var form = (
      <form key="form" method="post" action="">
        <div id="login_fields">
          <div id="login_form">
            <div id="login-message">Invalid username/password. Please try again.</div>
            <input
              ref="UsernameInput"
              type="text"
              id="login_name"
              name="login_name"
              spellCheck="false"
              autoCorrect="off"
              autoCapitalize="off"
              className="login-field"
              placeholder="username"
            />
            <input
              ref="PasswordInput"
              type="password"
              id="login_pwd"
              name="login_pwd"
              spellCheck="false"
              autoCorrect="off"
              autoCapitalize="off"
              className="login-field"
              placeholder="password"
            />
          </div>
          <div
            className="login-button"
            onClick={(e) => {
              this._trackLogin();
              this._fullLogin(e);
            }}
          >
            <button id="submit_button">LOGIN</button>
          </div>
          <div className="sso-button">
            <button type="button" id="enterprise_button" onClick={this._redirectToSSO}>
              Enterprise Login
            </button>
          </div>
          <div className="forgot-button">
            <button type="button" id="forgot_button" onClick={this._openForgotUsernameDialog}>
              Forgot Username?
            </button>
            <button type="button" id="password_button" onClick={this._openForgotPasswordDialog}>
              Forgot Password?
            </button>
          </div>
        </div>
      </form>
    );
    components.push(form);

    //if (this.state.failed) {
    //	message = <div className='popup-message'>Invalid credentials. Please try again.</div>
    //}
    //} else {
    //message = <div className='popup-message'>Welcome back!</div>
    // logging the user in
    //desc = <div id='desc'>Welcome back!</div>
    //}

    return (
      <div id="SplashScreen">
        <div className="splash-container">
          <div id={logo}></div>

          <div className="splash-content">
            {desc}

            <div className="form-container">{components}</div>

            {message}
          </div>
        </div>
      </div>
    );

    /* jshint ignore:end */
  },
});

module.exports = redux.connect(
  (state) => ({
    message: state.login.message,
  }),
  (dispatch) => ({
    login: (username, password, origin, originHash) => dispatch(actions.login(username, password, origin, originHash)),
  })
)(SplashScreen);
