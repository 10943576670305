import { HIGHLIGHTER_COLORS } from "./constants";
import { Action, Type } from "./actions";

const highligherColors = Object.keys(HIGHLIGHTER_COLORS);

export interface State {
  colors: typeof HIGHLIGHTER_COLORS;
}

const UNUSED_COLOR_VALUE = null;

const defaultState = {
  colors: {
    orange: UNUSED_COLOR_VALUE,
    yellow: UNUSED_COLOR_VALUE,
    purple: UNUSED_COLOR_VALUE,
    green: UNUSED_COLOR_VALUE,
    blue: UNUSED_COLOR_VALUE,
    pink: UNUSED_COLOR_VALUE,
  },
};

export default (state: State = defaultState, action: Action): State => {
  const { type, payload } = action;

  switch (type) {
    case Type.Highlight: {
      const { color, id } = payload;

      //check if color is valid
      if (highligherColors.includes(color)) {
        //if value is the same, deselect it
        if (state.colors[color] === id) {
          return {
            ...state,
            colors: {
              ...state.colors,
              [color]: UNUSED_COLOR_VALUE,
            },
          };
        }

        //check if id is currently in use on any other color
        const idIsInUseAt: string | undefined = Object.keys(state.colors).find(
          (color: string) => state.colors[color] === id
        );

        //unset color in use by id and set the new color to the id
        if (idIsInUseAt) {
          const dereferencedColors = { ...state.colors };

          dereferencedColors[idIsInUseAt] = UNUSED_COLOR_VALUE;
          dereferencedColors[color] = id;

          return {
            ...state,
            colors: dereferencedColors,
          };
        }

        //if id is not in use, set the color to the id
        return {
          ...state,
          colors: {
            ...state.colors,
            [color]: id,
          },
        };
      }
    }
  }

  return state;
};
