// Manager to try and contain what will surely turn into a mess of date logic

const moment = require("moment");
const { getBrowserTimezone, isTZAwarenessEnabled } = require("@/viewer/utils/timezones");

// eslint-disable-next-line no-undef
const DateManager = Backbone.Model.extend({
  defaults: {
    cushion: 0,
  },

  initialize: function () {
    this.calculateDates();
  },

  getDateSeed: function (key) {
    const seed = this.get(key);
    return seed ? seed : moment();
  },

  setUrlDtParam: function (date) {
    let queryString = "?dt=" + date.format("YYYYMMDD");

    if (isTZAwarenessEnabled()) {
      queryString = `${queryString}&tz=${getBrowserTimezone()}`;
    }

    window.history.pushState(null, null, queryString);
  },

  calculateDates: function () {
    let seed;
    const mode = this.get("mode");
    switch (mode) {
      case "daily":
        seed = this.getDateSeed("schedule_start_date").format();
        this.setScheduleEndpoints(moment(seed).startOf("day"), moment(seed).endOf("day"));
        break;
      case "weekly":
        /*
          Schedule stop date is necessary here over schedule start date.

          Should the month be october and the first day of october be wednesday,
          then toggling between weekly and monthly, regardless of start week on day
          as we only support Sat, Sun, and Mon, will force the current month to be
          september as the start of that week is in September.

          This manifests as toggling between weekly and monthly slowly moves us back in time.

          If we choose the schedule stop date, the end of the first week must, by definition,
          have at least one day in the month we want to stay in.
        */
        seed = this.getDateSeed("schedule_stop_date").subtract(3, "day").format();
        this.setScheduleEndpoints(moment(seed).startOf("week"), moment(seed).endOf("week"));
        break;
      case "monthly":
        seed = this.getDateSeed("schedule_start_date").format();
        this.setScheduleEndpoints(moment(seed).startOf("month"), moment(seed).endOf("month"));
        break;
      case "yearly":
        seed = this.getDateSeed("schedule_start_date").format();
        this.setScheduleEndpoints(moment(seed).startOf("year"), moment(seed).endOf("year"));
        break;
    }
  },

  /* publics */
  setMode: function (mode) {
    this.set({ mode: mode });
  },

  focus: function (dateStart, dateStop) {
    if (this.attributes.mode == "monthly") {
      this.selectMonth(dateStart);
    } else if (this.attributes.mode == "weekly") {
      this.selectWeek(dateStart);
    } else if (this.attributes.mode == "yearly") {
      this.selectYear(dateStart);
    } else if (this.attributes.mode == "range") {
      this.selectRange(dateStart, dateStop);
    } else if (this.attributes.mode == "static") {
      this.selectRange(dateStart, dateStop);
    } else if (this.attributes.mode == "anchored") {
      this.selectRange(dateStart, dateStop);
    } else {
      this.selectDate(dateStart);
    }
  },

  setScheduleEndpoints: function (startDate, stopDate, noContext) {
    this.set({
      schedule_start_date: moment(startDate),
      schedule_stop_date: moment(stopDate),
      start_date: moment(startDate).startOf("week").startOf("day"),
      stop_date: moment(stopDate).endOf("week").endOf("day"),
    });

    // push the month onto the contextual stack
    if (!noContext) {
      if (this.attributes.mode == "monthly") {
        window.LbsAppData.AppContext.pushContext({
          tag: "date",
          text: startDate.format("MMMM YYYY"),
          tooltip: "change month",
          action: this.showDateSelectDialog.bind(this),
          navLeft: this.decrementMonth.bind(this),
          navRight: this.incrementMonth.bind(this),
        });
      } else if (this.attributes.mode == "weekly") {
        // this could be incorrect (if someone has year-first format) but whatever
        window.LbsAppData.AppContext.pushContext({
          tag: "date",
          text: startDate.format("L/YY") + " - " + stopDate.format("L/YY"),
          tooltip: "change week",
          action: this.showDateSelectDialog.bind(this),
          navLeft: this.decrementWeek.bind(this),
          navRight: this.incrementWeek.bind(this),
        });
      } else if (this.attributes.mode == "yearly") {
        // this could be incorrect (if someone has year-first format) but whatever
        window.LbsAppData.AppContext.pushContext({
          tag: "date",
          text: startDate.format("YYYY"),
          tooltip: "change year",
          action: this.showDateSelectDialog.bind(this),
          navLeft: this.decrementYear.bind(this),
          navRight: this.incrementYear.bind(this),
        });
      } else if (this.attributes.mode == "range") {
        // this could be incorrect (if someone has year-first format) but whatever
        window.LbsAppData.AppContext.pushContext({
          tag: "date",
          text: startDate.format("L/YY") + " - " + stopDate.format("L/YY"),
          tooltip: "change range",
          action: this.showDateSelectDialog.bind(this),
        });
      } else if (this.attributes.mode == "static") {
        // this could be incorrect (if someone has year-first format) but whatever
        window.LbsAppData.AppContext.pushContext({
          tag: "date",
          text: startDate.format("L/YY") + " - " + stopDate.format("L/YY"),
        });
      } else if (this.attributes.mode == "anchored") {
        // this could be incorrect (if someone has year-first format) but whatever
        window.LbsAppData.AppContext.pushContext({
          tag: "date",
          text: startDate.format("L/YY") + " - " + stopDate.format("L/YY"),
          navLeft: this.decrementAnchor.bind(this, this.attributes.anchorStep),
          navRight: this.incrementAnchor.bind(this, this.attributes.anchorStep),
        });
      } else {
        // daily -- if we have a list, possibly push the time as well
        var date = window.LbsAppData.DateManager.getFormattedDateString();
        window.LbsAppData.AppContext.pushContext({
          tag: "date",
          text: date,
          tooltip: "change date",
          action: this.showDateSelectDialog.bind(this),
          navLeft: this.decrementDay.bind(this),
          navRight: this.incrementDay.bind(this),
        });
      }
    }
  },
  setAnchorStep: function (anchorStep) {
    this.set({ anchorStep: anchorStep });
  },

  getFocusedDates: function () {
    var ret = { start: undefined, stop: undefined };
    // if the focus attributes are set, use those
    if (this.isFragmented()) {
      ret.start = moment(this.attributes.fragments[this.attributes.currentFragment].start);
      ret.stop = moment(this.attributes.fragments[this.attributes.currentFragment].stop);
      ret.startWithCushion = moment(this.attributes.fragments[this.attributes.currentFragment].startWithCushion);
      ret.stopWithCushion = moment(this.attributes.fragments[this.attributes.currentFragment].stopWithCushion);
    } else {
      // otherwise just use the cushioned dates
      ret.start = moment(this.attributes.schedule_start_date);
      ret.stop = moment(this.attributes.schedule_stop_date);
      ret.startWithCushion = moment(this.attributes.start_date);
      ret.stopWithCushion = moment(this.attributes.stop_date);
    }

    return ret;
  },

  getPrintDates: function () {
    var ret = { start: undefined, stop: undefined };
    if (window.LbsAppData.AppContext.get("view").theme.data.layout == "list") {
      // force single day on lists (they're too expensive)
      ret.start = moment(this.attributes.schedule_start_date).startOf("day");
      ret.stop = moment(this.attributes.schedule_stop_date).endOf("day");
    } else {
      if (this.attributes.mode == "daily") {
        ret.start = moment(this.attributes.schedule_start_date);
        ret.stop = moment(this.attributes.schedule_stop_date);
      } else {
        ret.start = moment(this.attributes.start_date);
        ret.stop = moment(this.attributes.stop_date);
      }
    }

    return ret;
  },

  isDateInSchedule: function (inDate) {
    var isBefore = inDate.isBefore(this.attributes.schedule_start_date.format("YYYY-MM-DD"), "days");
    var isAfter = inDate.isAfter(this.attributes.schedule_stop_date.format("YYYY-MM-DD"), "days");
    
    if (!isBefore && !isAfter) {
      return true;
    } else {
      return false;
    }
  },

  isFragmented: function () {
    return this.attributes.fragments ? true : false;
  },

  decrementMonth: function () {
    window.LbsAppData.AppContext.openLoadingDialog("Please wait...", this, function () {
      var duration = moment.duration(1, "months");
      var newStartDate = this.attributes.schedule_start_date.clone().subtract(duration);
      var newStopDate = newStartDate.clone().endOf("month");

      this.set({
        schedule_start_date: newStartDate,
        schedule_stop_date: newStopDate,
        start_date: moment(newStartDate).startOf("week").startOf("day"),
        stop_date: moment(newStopDate).endOf("week").endOf("day"),
      });

      this.setUrlDtParam(newStartDate);

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Update: Month", "Decrement");

      this.trigger("change:dates");
    });
  },

  incrementMonth: function () {
    window.LbsAppData.AppContext.openLoadingDialog("Please wait...", this, function () {
      var duration = moment.duration(1, "months");
      var newStartDate = this.attributes.schedule_start_date.clone().add(duration);
      var newStopDate = newStartDate.clone().endOf("month");

      this.set({
        schedule_start_date: newStartDate,
        schedule_stop_date: newStopDate,
        start_date: moment(newStartDate).startOf("week").startOf("day"),
        stop_date: moment(newStopDate).endOf("week").endOf("day"),
      });

      this.setUrlDtParam(newStartDate);

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Update: Month", "Increment");

      this.trigger("change:dates");
    });
  },

  selectMonth: function (date) {
    window.LbsAppData.AppContext.openLoadingDialog("Please wait...", this, function () {
      var newStartDate = date.clone().startOf("month");
      var newStopDate = newStartDate.clone().endOf("month");

      this.set({
        schedule_start_date: newStartDate,
        schedule_stop_date: newStopDate,
        start_date: moment(newStartDate).startOf("week").startOf("day"),
        stop_date: moment(newStopDate).endOf("week").endOf("day"),
      });

      this.setUrlDtParam(newStartDate);

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Update: Month", "Jump");

      // trigger the redraw
      this.trigger("change:dates");
    });
  },

  decrementDay: function () {
    window.LbsAppData.AppContext.openLoadingDialog("Please wait...", this, function () {
      var duration = moment.duration(1, "day");
      var newStartDate = this.attributes.schedule_start_date.clone().subtract(duration);
      var newStopDate = newStartDate.clone().endOf("day");

      this.set({
        schedule_start_date: newStartDate,
        schedule_stop_date: newStopDate,
        start_date: moment(newStartDate), //.subtract(this.attributes.cushion, 'days'),
        stop_date: moment(newStopDate), //.add(this.attributes.cushion, 'days')
      });

      this.setUrlDtParam(newStartDate);

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Update: Day", "Decrement");

      this.trigger("change:dates");
    });
  },

  incrementDay: function () {
    window.LbsAppData.AppContext.openLoadingDialog("Please wait...", this, function () {
      var duration = moment.duration(1, "day");
      var newStartDate = this.attributes.schedule_start_date.clone().add(duration);
      var newStopDate = newStartDate.clone().endOf("day");

      this.set({
        schedule_start_date: newStartDate,
        schedule_stop_date: newStopDate,
        start_date: moment(newStartDate), //.subtract(this.attributes.cushion, 'days'),
        stop_date: moment(newStopDate), //.add(this.attributes.cushion, 'days')
      });

      this.setUrlDtParam(newStartDate);

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Update: Day", "Increment");

      this.trigger("change:dates");
    });
  },

  selectDate: function (date) {
    window.LbsAppData.AppContext.openLoadingDialog("Please wait...", this, function () {
      var newStartDate = date.clone().startOf("day");
      var newStopDate = newStartDate.clone().endOf("day");

      this.set({
        schedule_start_date: newStartDate,
        schedule_stop_date: newStopDate,
        start_date: moment(newStartDate), //.subtract(this.attributes.cushion, 'days'),
        stop_date: moment(newStopDate), //.add(this.attributes.cushion, 'days')
      });

      this.setUrlDtParam(newStartDate);

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Update: Day", "Jump");

      this.trigger("change:dates");
    });
  },

  decrementWeek: function () {
    window.LbsAppData.AppContext.openLoadingDialog("Please wait...", this, function () {
      var duration = moment.duration(1, "weeks");
      var newStartDate = this.attributes.schedule_start_date.clone().subtract(duration);
      var newStopDate = newStartDate.clone().endOf("week");

      this.set({
        schedule_start_date: newStartDate,
        schedule_stop_date: newStopDate,
        start_date: moment(newStartDate),
        stop_date: moment(newStopDate),
      });

      this.setUrlDtParam(newStartDate);

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Update: Week", "Decrement");

      this.trigger("change:dates");
    });
  },

  incrementWeek: function () {
    window.LbsAppData.AppContext.openLoadingDialog("Please wait...", this, function () {
      var duration = moment.duration(1, "weeks");
      var newStartDate = this.attributes.schedule_start_date.clone().add(duration);
      var newStopDate = newStartDate.clone().endOf("week");

      this.set({
        schedule_start_date: newStartDate,
        schedule_stop_date: newStopDate,
        start_date: moment(newStartDate),
        stop_date: moment(newStopDate),
      });

      this.setUrlDtParam(newStartDate);

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Update: Week", "Increment");

      this.trigger("change:dates");
    });
  },

  selectWeek: function (date) {
    window.LbsAppData.AppContext.openLoadingDialog("Please wait...", this, function () {
      var newStartDate = date.clone().startOf("week");
      var newStopDate = newStartDate.clone().endOf("week");

      this.set({
        schedule_start_date: newStartDate,
        schedule_stop_date: newStopDate,
        start_date: moment(newStartDate),
        stop_date: moment(newStopDate),
      });

      this.setUrlDtParam(newStartDate);

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Update: Week", "Jump");

      this.trigger("change:dates");
    });
  },

  decrementYear: function () {
    window.LbsAppData.AppContext.openLoadingDialog("Please wait...", this, function () {
      var duration = moment.duration(1, "year");
      var newStartDate = this.attributes.schedule_start_date.clone().subtract(duration);
      var newStopDate = newStartDate.clone().endOf("year");

      this.set({
        schedule_start_date: newStartDate,
        schedule_stop_date: newStopDate,
        start_date: moment(newStartDate).startOf("week").startOf("day"),
        stop_date: moment(newStopDate).endOf("week").endOf("day"),
      });

      this.setUrlDtParam(newStartDate);

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Update: Year", "Decrement");

      this.trigger("change:dates");
    });
  },

  incrementYear: function () {
    window.LbsAppData.AppContext.openLoadingDialog("Please wait...", this, function () {
      var duration = moment.duration(1, "year");
      var newStartDate = this.attributes.schedule_start_date.clone().add(duration);
      var newStopDate = newStartDate.clone().endOf("year");

      this.set({
        schedule_start_date: newStartDate,
        schedule_stop_date: newStopDate,
        start_date: moment(newStartDate).startOf("week").startOf("day"),
        stop_date: moment(newStopDate).endOf("week").endOf("day"),
      });

      this.setUrlDtParam(newStartDate);

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Update: Year", "Increment");

      this.trigger("change:dates");
    });
  },

  selectYear: function (date) {
    window.LbsAppData.AppContext.openLoadingDialog("Please wait...", this, function () {
      var newStartDate = date.clone().startOf("year");
      var newStopDate = newStartDate.clone().endOf("year");

      this.set({
        schedule_start_date: newStartDate,
        schedule_stop_date: newStopDate,
        start_date: moment(newStartDate).startOf("week").startOf("day"),
        stop_date: moment(newStopDate).endOf("week").endOf("day"),
      });

      this.setUrlDtParam(newStartDate);

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Update: Year", "Jump");

      this.trigger("change:dates");
    });
  },

  selectRange: function (dateStart, dateEnd) {
    window.LbsAppData.AppContext.openLoadingDialog("Please wait...", this, function () {
      var newStartDate = dateStart.clone();
      var newStopDate = dateEnd.clone();

      this.set({
        schedule_start_date: newStartDate,
        schedule_stop_date: newStopDate,
        start_date: moment(newStartDate),
        stop_date: moment(newStopDate),
      });

      this.setUrlDtParam(newStartDate);

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Update: Range", "Jump");

      this.trigger("change:dates");
    });
  },

  decrementAnchor: function (anchorStep) {
    window.LbsAppData.AppContext.openLoadingDialog("Please wait...", this, function () {
      var duration = anchorStep;
      var newStartDate = this.attributes.schedule_start_date.clone().subtract(duration);
      var newStopDate = newStartDate.clone().add(duration).subtract(1, "days");

      this.set({
        schedule_start_date: newStartDate,
        schedule_stop_date: newStopDate,
        start_date: moment(newStartDate).startOf("week").startOf("day"),
        stop_date: moment(newStopDate).endOf("week").endOf("day"),
      });

      this.setUrlDtParam(newStartDate);

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Update: Anchor", "Decrement");

      this.trigger("change:dates");
    });
  },

  incrementAnchor: function (anchorStep) {
    window.LbsAppData.AppContext.openLoadingDialog("Please wait...", this, function () {
      var duration = anchorStep;
      var newStartDate = this.attributes.schedule_start_date.clone().add(duration);
      var newStopDate = newStartDate.clone().add(duration).subtract(1, "days");

      this.set({
        schedule_start_date: newStartDate,
        schedule_stop_date: newStopDate,
        start_date: moment(newStartDate).startOf("week").startOf("day"),
        stop_date: moment(newStopDate).endOf("week").endOf("day"),
      });

      this.setUrlDtParam(newStartDate);

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Update: Anchor", "Increment");

      this.trigger("change:dates");
    });
  },

  showDateSelectDialog: function () {
    window.LbsAppData.AppContext.openDialog({ type: "date-select" });

    // send a tracking event
    var type;
    switch (this.attributes.mode) {
      case "monthly":
        type = "Month";
        break;
      case "weekly":
        type = "Week";
        break;
      case "yearly":
        type = "Year";
        break;
      default:
        type = "Day";
        break;
    }
    window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Date Select: " + type);
  },
  closeDateSelectDialog: function () {
    window.LbsAppData.AppContext.closeDialog();
  },

  getFormattedDateString: function () {
    var returnStr;

    // getting the contextual menu string so use the schedule dates
    if (this.attributes.mode == "daily") {
      returnStr = this.attributes.schedule_start_date.format("LL");
    } else if ("weekly|range|static|anchored".indexOf(this.attributes.mode) > -1) {
      // this could be incorrect (if someone has year-first format) but whatever
      returnStr =
        this.attributes.schedule_start_date.format("L/YY") + " - " + this.attributes.schedule_stop_date.format("L/YY");
    } else if (this.attributes.mode == "yearly") {
      returnStr = this.attributes.schedule_start_date.format("YYYY");
    } else {
      returnStr = this.attributes.schedule_start_date.format("MMMM YYYY");
    }

    return returnStr;
  },

  hasMoved: function () {
    // check for the seed date via the dt parameter
    var seed = moment();
    var matches = window.location.search.replace("?", "").match(/dt=([0-9]{8})/);
    if (matches) {
      seed = moment(matches[1], "YYYYMMDD");
    }

    if (this.attributes.mode == "monthly") {
      if (this.attributes.schedule_start_date.isSame(moment(seed).startOf("month"), "month")) {
        return false;
      }
    } else if (this.attributes.mode == "weekly") {
      if (this.attributes.schedule_start_date.isSame(moment(seed).startOf("week"), "week")) {
        return false;
      }
    } else if (this.attributes.mode == "yearly") {
      if (this.attributes.schedule_start_date.isSame(moment(seed).startOf("year"), "year")) {
        return false;
      }
    } else if (this.attributes.mode == "range") {
      // will be today
      if (this.attributes.schedule_start_date.isSame(moment(seed).startOf("day"), "day")) {
        return false;
      }
    } else if (this.attributes.mode == "static") {
      // cannot move
      return false;
    } else if (this.attributes.mode == "anchored") {
      // will be today
      if (this.attributes.schedule_start_date.isSame(moment(seed).startOf("day"), "day")) {
        return false;
      }
    } else {
      if (this.attributes.schedule_start_date.isSame(moment(seed).startOf("day"), "day")) {
        return false;
      }
    }

    // it's been moved
    return true;
  },

  isToday: function () {
    // decide if the start date is today -- only for single day views
    if (this.attributes.mode == "daily") {
      var tz = window.LbsAppData.User.get("tz");
      var now = tz ? moment().tz(tz) : moment();
      if (now.isSame(this.attributes.schedule_start_date, "day")) {
        return true;
      }
    }

    return false;
  },

  getWeekNumber: function (inDate) {
    // get the week number of the inDate (standard views)
    return Math.floor(inDate.diff(this.attributes.start_date, "day") / 7);
  },
});

module.exports = DateManager;
