"use strict";

// scss requires
require("./_styles.scss");

const React = require("react/addons");

const SwitchButton = React.createClass({
  onItemClick: function () {
    const onClick = this.props.onClick;
    if (typeof onClick === "function") {
      onClick(this);
    }
  },

  render: function () {
    let onItemClick = this.onItemClick;
    let isButtonActive = this.props.isButtonActive;
    let label = this.props.label;

    let classes = React.addons.classSet({
      "button-list-item": true,
      "active-button": isButtonActive,
    });

    return (
      <li className={classes} onClick={onItemClick}>
        {label}
      </li>
    );
  },
});

module.exports = SwitchButton;
