const ApiConfig = require("_lib/data/ApiConfig.js");
const Swap = require("./Swap.js");

const SwapSplitShift = Swap.extend({
  url: ApiConfig.getPrefix() + "/schedule/split",

  save: function (success_callback, error_callback) {
    Backbone.sync("create", this, {
      success: function (response) {
        success_callback ? success_callback(response) : undefined;
      },
      error: function (response) {
        error_callback ? error_callback(response) : undefined;
      },
    });
  },
});

module.exports = SwapSplitShift;
