"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");
// models
var Feedback = require("viewer/data/models/Feedback.js");

var FeedbackDialog = React.createClass({
  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {
    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Feedback");
  },
  componentWillUnmount: function () {},

  /* publics */

  /* privates */
  _close: function (e) {
    this.props.close();
  },
  _submit: function (e) {
    var that = this;
    // just going to post the content to the api, and that'll send an email out
    var feedbackText = $(".feedback-text").val();
    var feedback = new Feedback({ message: feedbackText });
    feedback.save(null, {
      success: function () {
        that.props.close();

        // show a message
        window.LbsAppData.AppContext.openDialog({
          type: "message",
          message: "Thank you!",
          timer: 2000,
        });
      },
      error: function () {
        that.props.close();
      },
    });

    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Submit: Feedback");
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      Feedback: true,
    });

    return (
      <div className={classes}>
        <div className="title-container">
          <div className="text">
            <i className="fa fa-bullhorn" />
            Submit Feedback
          </div>
        </div>
        <p>
          We've been hard at work reinventing ourselves to better serve you and your group. We know we still have more
          to do, and want to make it as easy as possible for you to be heard. Please take a moment to give us some input
          on what we're doing right, what we're doing wrong, and what we aren't doing at all.{" "}
          <strong>Thank you, your feedback is appreciated!</strong>
        </p>

        <textarea className="feedback-text" />

        <div className="action-bar">
          <div className="submit-button" onClick={this._submit} onTouchEnd={this._submit}>
            Submit!
          </div>
          <div className="cancel-button" onClick={this._close} onTouchEnd={this._close}>
            Cancel
          </div>
        </div>
      </div>
    );
  },
});

module.exports = FeedbackDialog;
