const Backbone = require("backbone");
const _ = require("underscore");

const SafeJSON = require("../../../common/utils/jsonParseShield");

const UserSettings = Backbone.Model.extend({
  // User model..but we get most (all) of this data from the permissions endpoint
  idAttribute: "settings_id",

  parse: function (response) {
    if (response != null) {
      response.settings = SafeJSON.parse(response.settings);
    }

    return response;
  },

  toJSON: function () {
    const attributes = _.clone(this.attributes);

    attributes.settings = JSON.stringify({
      default_view: attributes.default_view,
    });

    delete attributes.default_view;

    return attributes;
  },
});

module.exports = UserSettings;
