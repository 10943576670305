import { Collection } from "backbone";
import connectedPropertyHookFactory from "@/viewer/ui/modules/common/hooks/connectedPropertyHookFactory";
import { FilterMap } from "@/viewer/ui/modules/common/types/context";

const getFilteredDepartments = (): FilterMap => {
  const { LbsAppData } = window as any;
  // Ported from Assignment._checkAvailability

  if (!LbsAppData.Templates) return {};

  const Departments: Collection = LbsAppData.Departments;
  const filteredDepartments: FilterMap = {};
  Departments.forEach((department) => {
    if (department.attributes.filtered) {
      filteredDepartments[department.id] = true;
    }
  });

  return filteredDepartments;
};

export default connectedPropertyHookFactory({
  loader: getFilteredDepartments,
  getListeners: () => {
    const { LbsAppData } = window as any;
    return [[LbsAppData.Departments, "change:filtered"]];
  },
});
