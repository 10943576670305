import { SlotAttributes } from "@/viewer/types/domain/slot";
import { Type, Action } from "./actions";
import { Department, Pagination, PendingSelectOption, DepartmentSelectOption } from "./activityFeedTypes";

export type State = {
  readonly pendingSchedules: Array<SlotAttributes>;
  readonly pagination: Pagination;
  readonly departments: Array<Department>;
  readonly selectedDepartment?: DepartmentSelectOption;
  readonly selectedPendingItem?: PendingSelectOption;
};

const initialState: State = {
  pendingSchedules: [],
  pagination: {
    pageNumber: 1,
    pageSize: 50,
    totalPages: 0,
  },
  departments: [],
  selectedDepartment: undefined,
  selectedPendingItem: undefined,
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case Type.GetActivityFeedSchedule:
      return { ...state, pendingSchedules: action.payload };
    case Type.GetActivityFeedDepartments:
      return { ...state, departments: action.payload };
    case Type.SetActivityFeedPagination:
      return { ...state, pagination: action.payload };
    case Type.SetActivityFeedSelectedDepartment:
      return { ...state, selectedDepartment: action.payload };
    case Type.SetActivityFeedSelectedPendingItem:
      return { ...state, selectedPendingItem: action.payload };
    default:
      return state;
  }
};
