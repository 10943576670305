import React, { useRef } from "react";
import { useFormik, FormikValues } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import userResource, { RecoveryEmailUpdateResponse } from "@/resources/userResource/user.resource";
import toastActions from "@/common/components/toast/actions";
import Toast, { ToastStatus } from "@/common/components/toast/Toast";

import { ResourceUtils } from "@/resources/root/root.resource";

import "../_styles.scss";

interface Props {
  readonly currentRecoveryEmail: string;
}

const RecoveryForm = (props: Props): JSX.Element => {
  const { currentRecoveryEmail } = props;
  const [updatedEmail, setUpdatedEmail] = React.useState<string>(currentRecoveryEmail ?? "");
  const currentRecoveryEmailRef = useRef<string>(currentRecoveryEmail);

  const dispatch = useDispatch();

  const handleSubmit = async (values: FormikValues) => {
    const result: RecoveryEmailUpdateResponse | Error = await userResource.updateUserRecoveryEmail({
      email: values.email,
      email_confirm: values.confirmEmail,
    });

    if (Reflect.has(result, "status")) {
      const coercedResult = result as RecoveryEmailUpdateResponse;
      const { status } = coercedResult;

      if (status && ResourceUtils.responseIntegerIsOk(status)) {
        const emailWasSet = coercedResult?.email === values.email;

        if (emailWasSet && coercedResult?.email?.length) {
          setUpdatedEmail(coercedResult.email);
          dispatch(
            toastActions.open(
              <Toast status={ToastStatus.Success}>
                <span>{"Recovery email successfully updated."}</span>
              </Toast>
            )
          );

          return;
        }
      }

      dispatch(
        toastActions.open(
          <Toast status={ToastStatus.Error}>
            <span>{coercedResult?.Message ?? "Could not update recovery email."}</span>
          </Toast>
        )
      );

      return;
    }

    // Network error occurred
    dispatch(
      toastActions.open(
        <Toast status={ToastStatus.Error}>
          <span>{(result as Error).message ?? "Could not reach server."}</span>
        </Toast>
      )
    );
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      confirmEmail: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email")
        .oneOf([Yup.ref("confirmEmail")], "Emails do not match")
        .required("Required"),
      confirmEmail: Yup.string()
        .email("Invalid email")
        .oneOf([Yup.ref("email")], "Emails do not match")
        .required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await handleSubmit(values);
      resetForm();
    },
  });

  React.useEffect(() => {
    if (updatedEmail !== currentRecoveryEmailRef.current) {
      (window as any).LbsAppData.User.set("email", updatedEmail);
      currentRecoveryEmailRef.current = updatedEmail;
    }
  }, [updatedEmail]);

  return (
    <form className="body-form" onSubmit={formik.handleSubmit} id="recovery-form">
      <label>Current Recovery Email:</label>
      {updatedEmail}
      <div className="form-group">
        <label htmlFor={"email"}>New Recovery Email:</label>
        <input name="email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
        {formik.touched.email && formik.errors.email && <div className={"error-text"}>{formik.errors.email}</div>}
        <label htmlFor={"confirmEmail"}>Confirm Recovery Email:</label>
        <input
          name="confirmEmail"
          value={formik.values.confirmEmail}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.confirmEmail && formik.errors.confirmEmail && (
          <div className={"error-text"}>{formik.errors.confirmEmail}</div>
        )}
      </div>
      <p>Set a new recovery email for your account</p>
      <div className="action-bar">
        <button type="submit">Update</button>
      </div>
    </form>
  );
};

export default RecoveryForm;
