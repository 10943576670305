"use strict";

var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var DatePickerCalendarDate = React.createClass({
  mixins: [TouchMixin],

  /* privates */
  _handleClick: function (e) {
    // only do something if it's selectable
    if (!this.props.unselectable) {
      this.props.toggleDateSelection(this.props.date);
    }
  },

  /* publics */

  /* lifecycle methods */

  render: function () {
    /* jshint ignore:start */
    var that = this;

    var classes = React.addons.classSet({
      DatePickerCalendarDate: true,
      unselectable: this.props.unselectable,
      selected: this.props.selected,
      today: this.props.date.isSame(moment().startOf("day"), "day"),
      weekend: this.props.date.day() == 0 || this.props.date.day() == 6,
    });

    return (
      <div
        className={classes}
        data-cy="datePickerCalendarDate"
        onClick={this.touchProxy.bind(this, this._handleClick, [])}
        onTouchEnd={this.touchProxy.bind(this, this._handleClick, [])}
      >
        {this.props.date.format("DD")}
      </div>
    );

    /* jshint ignore:end */
  },
});

module.exports = DatePickerCalendarDate;
