import * as Sentry from "@sentry/browser";
import { Severity } from "@sentry/browser";
import env from "./env";

if (env.SENTRY_DSN && env.SENTRY_ENVIRONMENT) {
  Sentry.init({ dsn: env.SENTRY_DSN, environment: env.SENTRY_ENVIRONMENT });
}

const error = (message: string | Error, ...args: any[]): void => {
  // eslint-disable-next-line no-console
  console.error.apply(null, [message, ...args]);

  if (typeof message === "string") {
    Sentry.captureMessage(message, Severity.Error);
  } else {
    Sentry.captureException(message);
  }
};

const warn = (message: string, ...args: any[]): void => {
  // eslint-disable-next-line no-console
  console.warn.apply(null, [message, ...args]);
  Sentry.captureMessage(message, Severity.Warning);
};

export default {
  error,
  warn,
  // eslint-disable-next-line no-console
  info: console.log,
  Sentry,
};
