import { LEGACY_VIEW_ID_OFFSET } from "@/_lib/constants/globals";
import { injectTZQueryParamIfRequired } from "@/_lib/utils/queryParams";
import * as React from "react";

const printCurrentView = () => {
  const { LbsAppData } = window as any;
  const dates = LbsAppData.DateManager.getPrintDates();
  const viewId = LbsAppData.AppContext.attributes.view.view_id;
  const startDate = dates.start.format("YYYYMMDD");
  const endDate = dates.stop.format("YYYYMMDD");
  let url: string;

  if (LbsAppData.AppContext.attributes.view.is_legacy) {
    const updatedViewId = viewId + LEGACY_VIEW_ID_OFFSET;
    url = injectTZQueryParamIfRequired(`/print/${updatedViewId}?legacy=true&start=${startDate}&end=${endDate}`);
  } else {
    url = injectTZQueryParamIfRequired(`/print/${viewId}?start=${startDate}&end=${endDate}`);
  }

  window.open(url, "_blank");
};

const LegacyPrintButton = (): JSX.Element => {
  return (
    <button className="top-bar-btn btn-icon no-mobile" onClick={printCurrentView} onTouchEnd={printCurrentView}>
      <i className="fa fa-print" />
    </button>
  );
};

export default LegacyPrintButton;
