const Backbone = require("backbone");
var moment = require("moment");
var _ = require("underscore");

var RemoveTool = Backbone.Model.extend({
  defaults: {
    type: "remove",
    desc: "remove",
    backgroundColor: "#FEFF84",
    color: "#000000",

    //targets: {},
    target: undefined,
    component: undefined,
    value: undefined,

    // select specific attributes

    // actions queue
    inflight: false,
    queue: [],
  },

  initialize: function () {
    // subscribe to this model's own change:inflight event -- so when that flaps grab a possibly
    // queued action
    this.listenTo(this, "change:inflight", this._getQueuedAction);
  },

  onSelect: function () {},

  onAttachPre: function () {},

  onAttach: function () {},

  onExecute: function () {
    // set the inflight variable to start queuing any changes between when this one starts
    // and then finishes
    this.set({ inflight: true });
    window.LbsAppData.ToolBox.performRemove(this.attributes.target, "remove_tool");

    // analytics calls
    window.LbsAppData.Helpers.Analytics.sendEvent("Remove: Slot", "Remove Tool");
  },

  onFinished: function () {
    this._reset();
    this.set({ inflight: false });
  },

  onClose: function () {},

  /* publics */
  style: function () {
    return {
      backgroundColor: this.attributes.backgroundColor,
      color: this.attributes.color,
    };
  },

  forceUpdateComponent: function () {
    if (this.attributes.component) {
      this.attributes.component.forceUpdateOnParent();
    }
  },

  /* privates */
  _reset: function () {
    this.attributes.target.set({ boundTool: undefined });
    this.attributes.component.forceUpdateOnParent();

    this.set({ target: undefined, component: undefined });
  },
  _getQueuedAction: function () {
    if (!this.attributes.inflight && this.attributes.queue.length) {
      // pop the first item and call its fn parameter
      var action = this.attributes.queue.shift();
      action.fn();
    }
  },
});

module.exports = RemoveTool;
