const Backbone = require("backbone");
const _ = require("lodash");
const ApiConfig = require("_lib/data/ApiConfig.js");

const HolidayCollection = Backbone.Collection.extend({
  model: Backbone.Model.extend(),

  initialize: function (models) {
    this.attributes = {};
    this.attributes.title = "Holidays";
  },

  url: function () {
    const queryParams = {};

    if (this.attributes) {
      if (this.attributes.start_date) {
        queryParams["start_date"] = this.attributes.start_date;
      }
      if (this.attributes.stop_date) {
        queryParams["end_date"] = this.attributes.stop_date;
      }
      if (this.attributes?.dept_list?.length) {
        queryParams["dept_list"] = this.attributes.dept_list.join("~");
      }
    }

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    return (
      ApiConfig.getPrefix() +
      "/holidays/?" +
      _.map(cleanQueryParams, function (value, key) {
        return key + "=" + value;
      }).join("&")
    );
  },

  setDateParams: function (DateManager) {
    if (DateManager) {
      const start_date_object = DateManager.attributes.start_date._d;
      const start_date =
        start_date_object.getFullYear() +
        ("0" + (start_date_object.getMonth() + 1)).slice(-2) +
        ("0" + start_date_object.getDate()).slice(-2);
      const stop_date_object = DateManager.attributes.stop_date._d;
      const stop_date =
        stop_date_object.getFullYear() +
        ("0" + (stop_date_object.getMonth() + 1)).slice(-2) +
        ("0" + stop_date_object.getDate()).slice(-2);
      this.attributes.start_date = start_date ? start_date : "";
      this.attributes.stop_date = stop_date ? stop_date : "";
    }
  },

  setDeptsParam: function (Views) {
    if (Views && Views.models) {
      for (let i = 0; i < Views.models.length; i++) {
        const view = Views.models[i];

        if (view.id !== "me" && view.id !== "today") {
          this.attributes.dept_list = view.attributes.filter.on_departments;
          break;
        }
      }
    } else {
      this.attributes.dept_list = [];
    }
  },
});

module.exports = HolidayCollection;
