"use strict";

var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");

var DatePickerSubmit = React.createClass({
  /* privates */
  _handleClick: function (e) {
    this.props.submitDates();
  },

  /* publics */

  /* lifecycle methods */

  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      DatePickerSubmit: true,
    });

    //var rangeStartText = this.props.rangeStart.format('MM/DD/YYYY');
    //var rangeStopText = this.props.rangeStop ? this.props.rangeStop.format('MM/DD/YYYY') : undefined;

    var datesDiv;
    if (this.props.mode == "range") {
      var rangeStartText = this.props.rangeStart.format("MM/DD/YYYY");
      var rangeStopText = this.props.rangeStop ? this.props.rangeStop.format("MM/DD/YYYY") : undefined;

      datesDiv = (
        <div className="dates">
          {rangeStartText} &mdash; {rangeStopText}
        </div>
      );
    }

    return (
      <div className={classes}>
        {datesDiv}

        <div className="submit-button" onClick={this._handleClick} onTouchEnd={this._handleClick}>
          Go!
        </div>
      </div>
    );

    /* jshint ignore:end */

    /* 				<div className='dates'>
					{rangeStartText} &mdash; {rangeStopText}
				</div>
		*/
  },
});

module.exports = DatePickerSubmit;
