/*
 * Schedule status model */
const Backbone = require("backbone");
const FiddleDeadConfig = require("@/_lib/data/FiddleDeadConfig.js");

const ScheduleStatus = Backbone.Model.extend({
  defaults: {},

  initialize: function (attributes, options) {},

  url: function () {
    var customerId = window.LbsAppData.User.get("customer_id");
    var scheduleId = this.attributes.schedule_id;

    return FiddleDeadConfig.getPrefix() + "/schedule_status/" + customerId + "/" + scheduleId;
  },
});

module.exports = ScheduleStatus;
