const Backbone = require("backbone");
var _ = require("underscore");
var moment = require("moment");

var StaghornConfig = require("_lib/data/StaghornConfig.js");

var TallyCollection = require("_lib/data/collections/NakedTallyCollection.js");
var PersonnelCollection = require("_lib/data/collections/NakedPersonnelCollection.js");

var ReportLibrary = require("./ReportLibrary.js");
var ReportResult = require("./ReportResult.js");

var Report = Backbone.Model.extend({
  idAttribute: "report_id",

  defaults: {
    last_shadow_change: undefined,
  },

  initialize: function (attributes, options) {},

  url: function () {
    return StaghornConfig.getPrefix() + "/report";
  },

  parse: function (response) {
    var data = response?.results?.[0];

    this.Library = {};
    this.Library.Tallies = new TallyCollection(data?._lib.tallies);
    this.Library.Personnel = new PersonnelCollection(data?._lib.personnel);

    // key the tally data dump by ids
    this.ExtData = {
      data_by_day: _.indexBy(data?._dump.data_by_day, "date"),
      data_by_day_and_emp: _.indexBy(data?._dump.data_by_day_and_emp, "date"),
      data_by_emp: _.indexBy(data?._dump.data_by_emp, "emp_id"),
      data_by_tally: _.indexBy(data?._dump.data_by_tally, "tally_id"),
    };

    return data;
  },

  /* publics */
  snapshot: function (report) {
    // copy over report state
    this.prevResults = report.results;
  },

  /* privates */
});

module.exports = Report;
