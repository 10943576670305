"use strict";

var React = require("react/addons");
var _ = require("underscore");
var moment = require("moment");

var SlotCollection = require("_lib/data/collections/NakedSlotCollection.js");

var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var UpcomingShifts = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    var slots = new SlotCollection([], {
      adhoc: true,
      emp_id: window.LbsAppData.User.get("emp_id"),
      start_date: moment().startOf("day"),
      end_date: moment().add(2, "days").endOf("day"),
      comparator: "start_time",
    });

    return {
      isLoading: true,
      slots: slots,
    };
  },
  componentDidMount: function () {
    if (window.LbsAppData.AppContext.session.get("auth")) {
      var that = this;
      window.setTimeout(function () {
        // wait a tiny bit to let the animation go through on the first open
        that._fetchSlots();
      }, 200);
    } else {
      window.LbsAppData.AppContext.once(
        "authorized",
        function () {
          this._fetchSlots();
        },
        this
      );
    }

    window.LbsAppData.AppContext.on("redraw", this._fetchSlots.bind(this, null), this);
  },
  componentWillUnmount: function () {
    window.LbsAppData.AppContext.off(null, null, this);
  },

  /* privates */
  _fetchSlots: function () {
    var that = this;
    this.state.slots.fetch({
      success: function (model, response, options) {
        that.setState({
          isLoading: false,
        });
      },
    });
  },

  _openView: function (view) {
    // push the please wait
    window.LbsAppData.AppContext.openLoadingDialog("Opening view...", this, function () {
      //var pathParts = window.location.pathname.split('/');
      //if (pathParts[pathParts.length - 1] != 'viewer'){
      //	// super awesome hack....if there isn't a trailing slash...and we are in the 'default' view...
      //	// then don't pop anything...just append
      //	var removed = pathParts.pop();
      //}

      window.location = window.location.protocol + "//" + window.location.host + "/viewer/" + view;
    });
  },

  _formatSlotTime: function (momentTime) {
    var time = "";
    time = window.LbsAppData.Helpers.Time.isMilitaryTimePreferred()
      ? momentTime.format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"))
      : momentTime.format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT")).replace(/(:?00| )/g, "");
    return time;
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var slotList;
    if (this.state.isLoading) {
      slotList = (
        <div className="loader">
          <i className="fa fa-spinner fa-spin" />
        </div>
      );
    } else {
      var groupedSlots = this.state.slots.groupBy("slot_date");
      var slotDateContainers = [];

      var startDate = this.state.slots.attributes.start_date.clone();
      var stopDate = this.state.slots.attributes.end_date.clone();
      var rollingDate = startDate.clone();

      for (var i = 0; i <= stopDate.diff(startDate, "days"); i++) {
        var key = rollingDate.format("YYYY-MM-DD");
        var slots = _.has(groupedSlots, key) ? groupedSlots[key] : [];

        var slotDivs = [];
        for (var j = 0; j < slots.length; j++) {
          var slot = slots[j];
          // ignore pendings NOT currently scheduled for this person
          if (slot.get("emp_id") != window.LbsAppData.MyPersonnel.get("emp_id")) {
            continue;
          }

          var startTime = moment(slot.get("start_time"));
          var stopTime = moment(slot.get("stop_time"));

          slotDivs.push(
            <div className="slot" key={j}>
              <div className="assignment">{slot.get("assign_display_name")}</div>

              <div className="time">
                {this._formatSlotTime(startTime)} &ndash; {this._formatSlotTime(stopTime)}
              </div>
            </div>
          );
        }

        var dateString =
          moment(key).format("dd") +
          " " +
          moment(key)
            .format("l")
            .replace(/(\/|\.|\-)?[0-9]{4}(\/|\.|\-)?/, "");
        if (slotDivs.length) {
          slotDateContainers.push(
            <div className="slots-container" key={key}>
              <div className="date">{dateString}</div>
              <div className="slots">{slotDivs}</div>
            </div>
          );
        } else {
          slotDateContainers.push(
            <div className="slots-container" key={key}>
              <div className="date">{dateString}</div>
              <div className="slots">
                <em>Not scheduled</em>
              </div>
            </div>
          );
        }

        rollingDate.add(1, "day");
      }

      slotList = slotDateContainers;
    }

    var classes = React.addons.classSet({
      UpcomingShifts: true,
    });

    return (
      <div className={classes}>
        <div className="title">
          <i className="fa fa-fw fa-medkit" />
          Next 3 Days
        </div>
        <div className="content">
          <div className="slot-list">{slotList}</div>
        </div>
        <div className="footer">
          <div className="today">
            <span className="link" onClick={this._openView.bind(this, "today")}>
              Today
            </span>
          </div>
          <div className="my">
            <span className="link" onClick={this._openView.bind(this, "me")}>
              My Schedule
            </span>
          </div>
        </div>
      </div>
    );

    /* jshint ignore:end */

    /*


					<div className='today'>
						<a className='link' href='/viewer/today'>Today</a>
					</div>
					<div className='my'>
						<a className='link' href='/viewer/me'>My Schedule</a>
					</div>
		*/
  },
});

module.exports = UpcomingShifts;
