import React from "react";
import { useFormikContext } from "formik";

import { FormValues } from "./AddShiftModal";

const ModalPreview = (): React.ReactElement => {
  const { values } = useFormikContext<FormValues>();

  return (
    <>
      <h4 style={{ marginTop: 0 }}>Preview</h4>
      {JSON.stringify(values)}
    </>
  );
};

export default ModalPreview;
