"use strict";

var moment = require("moment");

var CustomTimeMixin = {
  // if you use this...need to specify the following state variables in your component
  // useCustomTime
  // startTime
  // stopTime

  setCustomTime: function (value) {
    if (value == true) {
      var startTime, stopTime, timesDefault;
      if (this.state.selectedAssignments.length) {
        // from the fill, new request route
        if (this.state.useCustomTime) {
          startTime = this.state.startTime;
          stopTime = this.state.stopTime;
        } else {
          timesDefault = this.gatherConvertedDefaultTimes();
          startTime = timesDefault["start"];
          stopTime = timesDefault["stop"];
        }
        // save these initial start/stop times to the state
        this.setState({ startTime: startTime, stopTime: stopTime });
      } else {
        // from the details route...the state will already be accurate for the start/stop times...
        // we do however need to format them
        startTime = this.state.startTime;
        stopTime = this.state.stopTime;
      }

      // open up the custom time dialog
      window.LbsAppData.AppContext.openDialog({
        type: "schedule-edits-time-edit",
        startTime: startTime,
        stopTime: stopTime,
        updateTimes: this.updateTimes,
      });
    } else {
      // reset the useCustomTime state
      this.setState({ useCustomTime: false });

      if (this.props.onTimeChange) {
        this.props.onTimeChange(undefined, undefined);
      }
    }
  },

  updateTimes: function (tStart, tStop) {
    this.setState({
      startTime: tStart,
      stopTime: tStop,
      useCustomTime: true,
    });

    if (this.props.onTimeChange) {
      this.props.onTimeChange(tStart, tStop);
    }

    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Details: Update", "Time");
  },

  gatherConvertedDefaultTimes: function () {
    // Default multi structure assignments to midnight
    var resultTimes = { start: "00:00:00", stop: "00:00:00" };
    var assignmentStartTimeSimpleString = this.state.selectedAssignments[0]._getAssignmentDefaultStartTime(
      this.state.selectedTemplate.id
    );
    if (assignmentStartTimeSimpleString) {
      var assignmentStartTimeSimple = moment(assignmentStartTimeSimpleString, "HH:mm:ss");
      resultTimes["start"] =
        window.LbsAppData.Helpers.Time.timezoneConverter(assignmentStartTimeSimple).format("HH:mm:ss");
    }
    var assignmentStopTimeSimpleString = this.state.selectedAssignments[0]._getAssignmentDefaultStopTime(
      this.state.selectedTemplate.id
    );
    if (assignmentStopTimeSimpleString) {
      var assignmentStopTimeSimple = moment(assignmentStopTimeSimpleString, "HH:mm:ss");
      resultTimes["stop"] =
        window.LbsAppData.Helpers.Time.timezoneConverter(assignmentStopTimeSimple).format("HH:mm:ss");
    }

    return resultTimes;
  },

  gatherCustomTimes: function (date) {
    var that = this;
    var startDate = null;
    var endDate = null;
    // only go after this logic if user is choosing a custom time
    if (that.state.useCustomTime) {
      startDate = date.format("YYYY-MM-DD") + "T" + that.state.startTime;
      endDate = date.format("YYYY-MM-DD") + "T" + that.state.stopTime;
      var startDateMoment = new moment(startDate);
      var endDateMoment = new moment(endDate);

      // add a day if endDate is before the startDate (or exactly equal to)
      if (endDateMoment.isBefore(startDateMoment) || endDateMoment.isSame(startDateMoment)) {
        endDateMoment.add(1, "days");
        endDate = endDateMoment.format("YYYY-MM-DDTHH:mm:ss");
      }
    }

    return { s: startDate, e: endDate };
  },
};

module.exports = CustomTimeMixin;
