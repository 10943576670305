/*
 * Compatibility model that doesn't depend on window object bindings */
const Backbone = require("backbone");
const _ = require("lodash");
const ApiConfig = require("_lib/data/ApiConfig.js");

const NakedCompatibilityMap = Backbone.Model.extend({
  default: {},

  initialize: function (attributes) {},

  parse: function (response) {
    // dict to find personnel in constant time
    this.attributes.dict_personnel_by_id = _.indexBy(response.personnel, "id");

    return { raw: response };
  },

  url: function () {
    const queryParams = {};
    let baseUrl = "/compat";

    if (this?.attributes?.assign_id) {
      queryParams["assign_id"] = this.attributes.assign_id;
    }

    if (this?.attributes?.date) {
      baseUrl += "/date";
      queryParams["date"] = this.attributes.date;
    }

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    return (
      ApiConfig.getPrefix() +
      baseUrl +
      "?" +
      _.map(cleanQueryParams, function (value, key) {
        return key + "=" + value;
      }).join("&")
    );
  },
});

module.exports = NakedCompatibilityMap;
