import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar, Slide, Box, SlideProps, SnackbarProps } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import toastActions from "./actions";
import { RootState } from "@/modules/reducer";

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

export enum ToastStatus {
  Success = "success",
  Error = "error",
  Warning = "warning",
  Info = "info",
}

interface Props extends Omit<SnackbarProps, "children"> {
  children: React.ReactNode;
  status: ToastStatus;
  [key: string]: unknown;
}

const Toast = ({ children, status, ...rest }: Props): React.ReactElement => {
  const { isOpen } = useSelector((state: RootState) => state.toast);
  const dispatch = useDispatch();

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={(event, reason) => reason !== "clickaway" && dispatch(toastActions.close())}
      disableWindowBlurListener
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      {...rest}
    >
      <Box boxShadow={6}>
        <Alert onClose={() => dispatch(toastActions.close())} severity={status}>
          {children}
        </Alert>
      </Box>
    </Snackbar>
  );
};

export default Toast;
