"use strict";

var React = require("react/addons");
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
var LegacyToken = require("_lib/data/models/LegacyToken.js");

var SupportLink = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },

  componentDidMount: function () {},

  /* privates */
  _gotoSupport: function () {
    var legacyToken = new LegacyToken();
    legacyToken.fetch({
      success: function (model) {
        // redirect homie
        window.open(window.LbsAppData.Destinations.get("unity") + "?source=access&dest=Support.aspx", "_blank");
      },
    });
  },

  /* render */
  render: function () {
    /* jshint ignore:start */
    var classes = React.addons.classSet({
      SupportLink: true,
    });

    return (
      <div className={classes}>
        <div className="title">
          <i className="fa fa-fw fa-life-ring" />
          Link to Support
        </div>
        <div className="content">
          <div
            className="content-item"
            onClick={this.touchProxy.bind(this, this._gotoSupport, [])}
            onTouchEnd={this.touchProxy.bind(this, this._gotoSupport, [])}
          >
            <div className="title">Want to learn more about our product?</div>
            <div className="desc">Check out our help section for informative articles and videos.</div>
          </div>
        </div>
      </div>
    );

    /* jshint ignore:end */
  },
});

module.exports = SupportLink;
