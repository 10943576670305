import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

import React from "react";

export enum ActionDialogType {
  Info,
  Warning,
  Error,
}

enum HeaderColor {
  Info = "#2196f3",
  Warning = "#ff9800",
  Error = "#f44336",
}

interface ActionDialogProps {
  type: ActionDialogType;
  header: string;
  content: string | JSX.Element;
  actions: JSX.Element;
  isOpen: boolean;
  styleOverride?: React.CSSProperties;
}

const actionDialogTypeToHeaderColor = (type: ActionDialogType): string => {
  switch (type) {
    case ActionDialogType.Info:
      return HeaderColor.Info;
    case ActionDialogType.Warning:
      return HeaderColor.Warning;
    case ActionDialogType.Error:
      return HeaderColor.Error;
  }
};

const ActionDialog = (props: ActionDialogProps): JSX.Element => {
  const { type, header, content, actions, isOpen, styleOverride } = props;

  return (
    <Dialog maxWidth="sm" open={isOpen} style={styleOverride}>
      <DialogTitle style={{ color: actionDialogTypeToHeaderColor(type) }}>{header}</DialogTitle>
      <DialogContent style={{ backgroundColor: "#FFF" }}>{content}</DialogContent>
      <DialogActions style={{ display: "flex" }}>{actions}</DialogActions>
    </Dialog>
  );
};

export default ActionDialog;
