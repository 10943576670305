import React from "react";
import { Tooltip as MuiTooltip, TooltipProps, PopperProps } from "@material-ui/core";

import classnames from "@/_lib/utils/classnames";
import styles from "./styles.module.scss";

interface Props extends TooltipProps {
  disabled?: boolean;
  modifiers?: Pick<PopperProps, "modifiers">;
}

const Tooltip = ({
  className,
  title,
  disabled = false,
  modifiers = {},
  children,
  ...rest
}: Props): React.ReactElement => {
  const rootClassNames = classnames(className || "", {
    [styles.root]: true,
  });

  return (
    <MuiTooltip
      title={title}
      classes={{ popper: rootClassNames }}
      disableFocusListener={disabled}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
      PopperProps={{
        modifiers: {
          flip: { enabled: false },
          ...modifiers,
        },
      }}
      {...rest}
    >
      <div>{children}</div>
    </MuiTooltip>
  );
};

export default Tooltip;
