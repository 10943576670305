import React from "react";
import { Icon } from "@material-ui/core";

import classnames from "@/_lib/utils/classnames";

import "./InformativeIcon.scss";

export enum IconType {
  warning = "warning",
  info = "info",
  help = "help",
}

export enum IconClassesByType {
  info = "fas fa-info-circle",
  help = "fas fa-question-circle",
  warning = "fas fa-exclamation-circle",
}

export interface InfoIcon {
  iconType: IconType;
  pulse?: boolean;
  style?: React.CSSProperties;
  onMouseEnter: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseMove?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const InteractiveIcon = (props: InfoIcon): JSX.Element => {
  const { iconType, pulse, style, onMouseEnter, onMouseLeave, onMouseMove } = props;
  const baseClassName = "interactive-icon";

  const classes = classnames({
    [`${baseClassName}`]: !style,
    [`${baseClassName}--${iconType}`]: true,
    [`${baseClassName}--pulse`]: pulse,
    [`${IconClassesByType[iconType]}`]: true,
  });

  return (
    <div
      className="informative-icon-container"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
    >
      <Icon className={classes} style={style} />
    </div>
  );
};

export default InteractiveIcon;
