"use strict";

// scss requires
require("./_styles.scss");
// dependencies
const React = require("react/addons");
const _ = require("underscore");
// models
const Personnel = require("_lib/data/models/NakedPersonnel.js");
// components
const SlotDetailsDialogMain = require("./SlotDetailsDialogMain.jsx");
const SlotDetailsDialogPager = require("./SlotDetailsDialogPager.jsx");
const TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

const SlotDetailsDialog = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    const personnel = window.LbsAppData.Personnel
      ? window.LbsAppData.Personnel.get(this.props.dialogObject.data.get("emp_id"))
      : undefined;
    const personnelPending = window.LbsAppData.Personnel
      ? window.LbsAppData.Personnel.get(this.props.dialogObject.data.get("pending_emp_id"))
      : undefined;

    return {
      loading: true,
      personnel,
      personnelPending,
    };
  },
  componentDidMount: function () {
    window.LbsAppData.AppContext.on("redraw", this.checkAttributes.bind(this, null), this);

    let that = this;
    const promises = [];

    if (!this.state.personnel) {
      this.state.personnel = new Personnel({
        emp_id: this.props.dialogObject.data.get("emp_id"),
      });

      promises.push(
        new Promise(function (resolve) {
          that.state.personnel.fetch({
            success: function () {
              resolve();
            },
          });
        })
      );
    }

    if (!this.state.personnelPending && this.props.dialogObject.data.get("is_pending")) {
      this.state.personnelPending = new Personnel({
        emp_id: this.props.dialogObject.data.get("pending_emp_id"),
      });

      promises.push(
        new Promise(function (resolve) {
          that.state.personnelPending.fetch({
            success: function () {
              resolve();
            },
          });
        })
      );
    }

    Promise.all(promises).then(function () {
      // noticing a weird stutter with pending slots so trying to gate loads
      _.delay(function () {
        that.setState({ loading: false });
      });
    });
  },
  componentWillUnmount: function () {
    window.LbsAppData.AppContext.off(null, null, this);
  },
  close: function () {
    this.props.close();
  },
  checkAttributes: function () {
    // we're going to close it all the time now (leaving the above for an artifact though in case that logic is ever desired again)
    window.LbsAppData.AppContext.closeDialog("slot-details");
  },
  render: function () {
    /* jshint ignore:start */

    const classes = React.addons.classSet({
      "slot-details": true,
      isTop: this.props.isTop,
    });

    let mountObj;

    if (!this.state.loading) {
      if (!this.props.dialogObject.pager) {
        mountObj = (
          <SlotDetailsDialogMain
            flags={this.props.flags}
            close={this.props.close}
            dialogObject={this.props.dialogObject}
            personnel={this.state.personnel}
            personnelPending={this.state.personnelPending}
            loading={this.state.loading}
          />
        );
      } else {
        mountObj = (
          <SlotDetailsDialogPager
            close={this.props.close}
            dialogObject={this.props.dialogObject}
            personnel={this.state.personnel}
            personnelPending={this.state.personnelPending}
            loading={this.state.loading}
          />
        );
      }
    } else {
      mountObj = (
        <div className="loading-container">
          <span className="fa fa-fw fa-spin fa-spinner" />
        </div>
      );
    }

    return (
      <div className={classes}>
        <div className="container-middle">{mountObj}</div>
      </div>
    );
  },
});

module.exports = SlotDetailsDialog;
