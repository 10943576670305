/*
 * Template collection that doesn't depend on window object bindings */
const Backbone = require("backbone");

var _ = require("underscore");

var ApiConfig = require("_lib/data/ApiConfig.js");
var NakedTemplate = require("./../models/NakedTemplate.js");

var NakedTemplateCollection = Backbone.Collection.extend({
  model: NakedTemplate,
  comparator: "name",

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  getLatestScheduledDates: function () {
    var ret = {};
    _.each(this.models, function (item) {
      ret[item.id] = item.attributes.last_published_date;
    });

    return ret;
  },

  getReqWindowStartOrLatestScheduled: function () {
    // need to check if the request window start is before the last published date....thanks allow requests on pub schedules param....cool...cool cool cool cool
    var ret = {};
    _.each(this.models, function (item) {
      var dayBeforeReqWindowStart = moment(item.attributes.request_window_start)
        .subtract(1, "days")
        .format("YYYY-MM-DDTHH:mm:ss");
      ret[item.id] =
        dayBeforeReqWindowStart > item.attributes.last_published_date
          ? item.attributes.last_published_date
          : dayBeforeReqWindowStart;
    });

    return ret;
  },

  url: ApiConfig.getPrefix() + "/departments",

  parse: function (response) {
    // pluck the template information from each department
    var templates = [];
    _.each(response, function (d) {
      // inject a department_id attribute
      _.each(d.templates, function (t) {
        t.department_id = d.department_id;
      });

      // concat the template lists together
      templates = templates.concat(d.templates);
    });

    return templates;
  },
});

module.exports = NakedTemplateCollection;
