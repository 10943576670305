const Backbone = require("backbone");
var moment = require("moment");
var _ = require("underscore");

var AlternatesTool = Backbone.Model.extend({
  defaults: {
    type: "alternates",
    desc: "alternates",
    backgroundColor: "#FEFF84",
    color: "#000000",

    target: undefined,
    component: undefined,
    value: undefined,
  },

  onSelect: function () {
    this._reset();
    window.LbsAppData.AppContext.openExtra({
      type: "alternates",
      tool: this,
    });
  },

  onAttachPre: function () {
    this._reset();
  },

  onAttach: function () {
    var slot = this.attributes.target;
    // bind to the slot object
    this.attributes.target.set({ boundTool: this });

    window.LbsAppData.AppContext.openExtra({
      type: "alternates",
      component: this.attributes.component,
      focusedSlot: slot,
      focusedAssignment: slot.assignmentObj,
      focusedPersonnel: slot.personnelObj,
      focusedDate: moment(this.attributes.component.props.date).format("YYYYMMDD"),
    });
  },

  onExecute: function () {
    var slot = this.attributes.target;
    if (slot) {
      switch (window.LbsAppData.AppContext.get("GridSettings_leftColumnType")) {
        case "assignment":
          window.LbsAppData.Helpers.Analytics.sendEvent("Update: Slot", "Alternates Tool: Personnel");
          window.LbsAppData.ToolBox._replacePersonnel(slot);
          break;
        case "personnel":
          window.LbsAppData.Helpers.Analytics.sendEvent("Update: Slot", "Alternates Tool: Assignment");
          window.LbsAppData.ToolBox._replaceAssignmentStep1(slot);
        default:
          break;
      }
    }
  },

  onFinished: function () {
    // unbind the tool from the slot
    this.attributes.target.set({ boundTool: undefined });

    // trigger a redraw on the parent
    this.attributes.component.forceUpdateOnParent();
  },

  onClose: function () {
    this._reset();
    window.LbsAppData.AppContext.closeExtra();
  },

  /* publics */
  getPersonnelForEmptySlot: function () {
    return window.LbsAppData.Personnel.get(this.attributes.target.attributes.emp_id);
  },

  getAssignmentForEmptySlot: function () {
    return window.LbsAppData.Assignments.get(this.attributes.target.attributes.assign_plus_structure_id);
  },

  style: function () {
    return {
      backgroundColor: this.attributes.backgroundColor,
      color: this.attributes.color,
    };
  },

  forceUpdateComponent: function () {
    if (this.attributes.component) {
      this.attributes.component.forceUpdateOnParent();
    }
  },

  reset: function () {
    this._reset();
  },

  /* privates */
  _reset: function () {
    if (this.attributes.target) {
      // unbind the tool from the slot
      this.attributes.target.set({ boundTool: undefined });

      // trigger a redraw on the parent
      this.attributes.component.forceUpdateOnParent();

      // reset the state
      this.set({
        target: undefined,
        component: undefined,
        value: undefined,
      });
    }

    // remove the alternates extra
    window.LbsAppData.AppContext.closeExtra({ type: "alternates" });
  },
});

module.exports = AlternatesTool;
