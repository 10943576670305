import React from "react";
import ReactDOM from "react-dom";
import Draggable from "react-draggable";
import { ClickAwayListener, Fade, Grid, Icon, IconButton } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import classnames from "@/_lib/utils/classnames";
import { RootState } from "@/modules/reducer";

import modalActions from "./actions";
import styles from "./styles.module.scss";

export const FADE_IN_DURATION = 225;
export const FADE_OUT_DURATION = 195;

export enum MaxWidth {
  Xs,
  Sm,
  Md,
  Lg,
  Xl,
}

interface Props {
  id?: string;
  className?: string;
  title?: React.ReactNode;
  fullWidth?: boolean;
  maxWidth?: MaxWidth | false;
  disableBackdrop?: boolean;
  disableClickAway?: boolean;
  draggable?: boolean;
  negativeAction?: React.ReactElement | null;
  positiveAction?: React.ReactElement | null;
  children: React.ReactNode;
}

const Modal = ({
  id = "",
  className = "",
  title = "",
  fullWidth = false,
  maxWidth = false,
  disableBackdrop = false,
  disableClickAway = false,
  draggable = true,
  negativeAction = null,
  positiveAction = null,
  children,
}: Props): React.ReactPortal => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state: RootState) => state.modal);

  const rootClassNames = classnames(className, {
    [styles.root]: true,
    [styles.noBackdrop]: disableBackdrop,
  });

  const shellClassNames = classnames({
    [styles.shell]: true,
    [styles.fullWidth]: fullWidth,
    [styles.noMaxWidth]: maxWidth === false,
    [styles.xs]: maxWidth === MaxWidth.Xs,
    [styles.sm]: maxWidth === MaxWidth.Sm,
    [styles.md]: maxWidth === MaxWidth.Md,
    [styles.lg]: maxWidth === MaxWidth.Lg,
    [styles.xl]: maxWidth === MaxWidth.Xl,
  });

  const headerClassNames = classnames({
    [styles.header]: true,
    [styles.noDrag]: draggable === false,
  });

  const handleClose = () => {
    dispatch(modalActions.close());
  };

  const handleClickAway = (e: React.MouseEvent<Document>) => {
    /**
     * If there is a select field inside a modal, and is being treated as a portal,
     * we don't want to close the modal if the user clicks anywhere in the
     * select field's dropdown menu.
     */
    const selectMenu = document.getElementById("select-menu");

    if (!disableClickAway && !selectMenu?.contains(e.target as Node)) {
      handleClose();
    }
  };

  return ReactDOM.createPortal(
    <Fade in={isOpen} timeout={{ enter: FADE_IN_DURATION, exit: FADE_OUT_DURATION }}>
      <div id={id} className={rootClassNames}>
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
          <Draggable
            disabled={false}
            handle={`#draggable-modal`}
            cancel={`#modal-close-icon`}
            onMouseDown={(event) => event.stopPropagation()}
          >
            <div className={shellClassNames}>
              {/** Header */}
              <div id={"draggable-modal"} className={headerClassNames}>
                <div className={styles.title}>{title}</div>
                <IconButton id="modal-close-icon" className={styles.closeIcon} onClick={handleClose}>
                  <Icon className="fa fa-times" />
                </IconButton>
              </div>

              {/** Content */}
              <div className={styles.content}>{children}</div>

              {/** Footer */}
              {(negativeAction || positiveAction) && (
                <div className={styles.footer}>
                  <Grid container spacing={2} justifyContent="flex-end">
                    {negativeAction && <Grid item>{negativeAction}</Grid>}
                    {positiveAction && <Grid item>{positiveAction}</Grid>}
                  </Grid>
                </div>
              )}
            </div>
          </Draggable>
        </ClickAwayListener>
      </div>
    </Fade>,
    document.body
  );
};

export default Modal;
