import * as _ from "underscore";
import { addDays, endOfDay, isBefore } from "date-fns";

import { dayOfWeekToIndex } from "@/viewer/utils/dates";

import { SettingsContext } from "@/viewer/ui/modules/common/types/context";

/** Both requests and slots use a triply-nested dataset to store data by week by row by day.
 *  (See RequestsByDateByNameByWeek and SlotsByDateByColumnByWeek)
 *  This helper is tailor-made for this situation.
 */
export const appendToPath3 = <T>(
  data: { [k: string]: { [k: string]: { [k: string]: T[] } } },
  [k1, k2, k3]: [string, string, string],
  value: T
): void => {
  if (!_.has(data, k1)) {
    data[k1] = {};
  }

  if (!_.has(data[k1], k2)) {
    data[k1][k2] = {};
  }

  if (!_.has(data[k1][k2], k3)) {
    data[k1][k2][k3] = [];
  }

  data[k1][k2][k3].push(value);
};

/**
 * Same as appendToPath3, but only 2 deep. Used be column views.
 */
export const appendToPath2 = <T>(
  data: { [k: string]: { [k: string]: T[] } },
  [k1, k2]: [string, string],
  value: T
): void => {
  if (!_.has(data, k1)) {
    data[k1] = {};
  }

  if (!_.has(data[k1], k2)) {
    data[k1][k2] = [];
  }

  data[k1][k2].push(value);
};

/**
 * dateRange returns a list of moments representing days between <start> and <end>.
 * If <end> is before <start>, the returned list will be empty.
 * Otherwise, it will always start with <start>
 */
export const dateRange = (start: Date, end: Date): Date[] => {
  const days: Date[] = [];
  let rollingDate = start;

  while (isBefore(rollingDate, end)) {
    days.push(rollingDate);
    rollingDate = addDays(rollingDate, 1);
  }

  return days;
};

/**
 * Similar to dateRange, only this includes the last day as a part of the list
 */
export const dateRangeInclusive = (start: Date, end: Date): Date[] => {
  const days: Date[] = [];
  let rollingDate = start;

  while (isBefore(rollingDate, endOfDay(end))) {
    days.push(rollingDate);
    rollingDate = addDays(rollingDate, 1);
  }

  return days;
};

/**
 * Takes an array, and chunks it into a specified number of columns.
 * i.e `toMatrix([1, 2, 3, 4, 5, 6], 3) --> [[1, 2, 3], [4, 5, 6]]`
 */
export const toMatrix = <T>(array: T[], columns: number): T[][] => _.chunk(array, columns) as T[][];

/**
 * toMatrix but specific for block views.
 *
 * From the settings context:
 * - Block week length determines how many weeks are going to get merged together and displayed in a column.
 * - Block Start Day and Block Length (Days) settings allow you to control the subset of days that are displayed
 *   in the merged column.
 */
export const toBlockMatrix = (settings: SettingsContext, array: Date[]): Date[][] => {
  const { blockWeekLength, blockStartDay, blockLength } = settings;

  const flatArray = toMatrix<Date>(array, 7).flatMap((element) =>
    element.slice(dayOfWeekToIndex(blockStartDay), blockLength)
  );

  return toMatrix<Date>(flatArray, blockWeekLength * blockLength);
};

export const getQueryParameter = (param: string): string | null => {
  const url_string = window.location.href;
  const url = new URL(url_string);
  return url.searchParams.get(param);
};

export interface QueryParams {
  [key: string]: string | number | boolean | null | undefined;
}
