import React from "react";

interface Open {
  type: Type.Open;
  toast: React.ReactElement;
}

interface Close {
  type: Type.Close;
}

export type Action = Open | Close;

export enum Type {
  Open = "toast/open",
  Close = "toast/close",
}

export default {
  open: (toast: React.ReactElement): Open => ({
    type: Type.Open,
    toast,
  }),
  close: (): Close => ({
    type: Type.Close,
  }),
};
