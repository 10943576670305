import React from "react";
import { Dispatch } from "redux";

import { getSlotQuestUUID } from "@/viewer/utils/domain/slotquests";

import { SlotOrRequest } from "../types";
import { CallbackContext, SettingsContext, UIContext } from "../types/context";

const slotEvents = (
  callbackContext: CallbackContext,
  dispatch: Dispatch,
  settings: SettingsContext,
  slotquest: SlotOrRequest,
  uiContext: UIContext
): any => {
  const isRightClick = (e: React.MouseEvent<HTMLElement>) => {
    // abort if this is a right click
    return !!(e.button && e.button === 2);
  };

  const slotUUID = getSlotQuestUUID(slotquest);
  const { triggerSwappingMode, attachToolbox, expandFocusedSlot, openSlotContextMenu } = callbackContext;

  /**
   * We want different behavior for a long click than for a short one. This requires us to keep track
   * of the current mouse state.
   */
  let mouseIsDown = false;

  // General click handler
  const handleClick = () => {
    if (uiContext.isBulkMode) {
      attachToolbox(slotUUID);
    } else {
      expandFocusedSlot(slotUUID);
    }
  };

  // Mouse down: Update mouseIsDown and arm a long press callback
  const onMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    if (isRightClick(e) || settings.layout === "block") {
      return;
    }

    mouseIsDown = true;

    window.setTimeout(() => {
      // Long hold complete
      if (mouseIsDown && !uiContext.isBulkMode) {
        mouseIsDown = false;
        if (triggerSwappingMode()) {
          // successfully switched modes -- perform an attach
          attachToolbox(slotUUID);
        }
      }
    }, 1500);
  };

  // Mouse up: Update mouseIsDown, call handleClick
  const onMouseUp = (e: React.MouseEvent<HTMLElement>) => {
    if (isRightClick(e)) {
      return;
    }

    if (!mouseIsDown) {
      return; // long-click timeout has already fired.
    }

    mouseIsDown = false;
    handleClick();
  };

  const onContextMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    openSlotContextMenu(
      slotUUID,
      {
        top: e.pageY,
        left: e.pageX,
      },
      settings,
      dispatch
    );
  };

  return {
    onMouseUp,
    onMouseDown,
    onContextMenu,
  };
};

export default slotEvents;
