import "./_styles.scss";

import React, { useEffect, useState } from "react";

import { AppName, SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { ViewData } from "@/viewer/types/viewdata";

import useCurrentlyLoggedInUser from "@/viewer/ui/modules/common/hooks/useCurrentlyLoggedInUser";
import usePersonnelById from "@/viewer/ui/modules/common/hooks/usePersonnelById";

import LogoMenuContainer from "@/_lib/ui/modules/topbar/LogoMenuContainer";
import TopBarContainer from "@/_lib/ui/modules/topbar/TopBarContainer";
import useDepartmentsById from "@/viewer/ui/modules/common/hooks/useDepartmentsById";
import { getDesktopBrandingApiFlagForEnv } from "@/_lib/utils/viewerApiUtils";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ContextRibbon = require("./ContextRibbon.jsx");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ToolBox = require("_lib/ui/modules/toolbox/ToolBox.jsx");

const goToRequests = () => {
  const { LbsAppData } = window as any;

  LbsAppData.AppContext.openDialog({
    type: "requests",
  });
};

const goToDashboard = () => {
  const { LbsAppData } = window as any;

  // send a tracking event
  LbsAppData.Helpers.Analytics.sendEvent("Outbound", "Dashboard");

  window.location.href = window.location.protocol + "//" + window.location.host;
};

const showAbout = () => {
  const { LbsAppData } = window as any;

  LbsAppData.AppContext.openDialog({
    type: "about",
  });
};

interface Props {
  appName: AppName;
  settings: SettingsContext;
  setUIContext: (newUI: Partial<UIContext>) => void;
  signOut: () => void;
  ui: UIContext;
  viewData: ViewData;
  printRef?: React.MutableRefObject<null>;
}

const TopBar = (props: Props): JSX.Element => {
  const { appName, printRef, settings, setUIContext, signOut, ui, viewData } = props;

  const user = useCurrentlyLoggedInUser();
  const { customerName, userName } = user;
  const personnelById = usePersonnelById(settings);
  const departmentsById = useDepartmentsById();
  const isScheduled = user?.empId && personnelById[user.empId] ? personnelById[user.empId]?.scheduled : false;

  const [subNav, setSubNav] = useState<string | undefined>(undefined);
  const [iconId, setIconId] = useState<string | "">("logo-tile-icon");

  useEffect(() => {
    const handleLogoIcon = async (): Promise<string> => {
      const showBrandingIcon = await getDesktopBrandingApiFlagForEnv();
      setIconId(showBrandingIcon ? "lb-ps-logo" : "logo-tile-icon");
      return iconId;
    };

    handleLogoIcon();
  }, [iconId]);

  const toolbox = appName == "editor" || appName == "reports" ? <ToolBox appName={appName} /> : <></>;

  const subNavElements: JSX.Element[] = [];

  if (appName != "login") {
    if (appName == "viewer" && isScheduled) {
      subNavElements.push(
        <div key="sub-request" className="subnav-menu-item" onClick={goToRequests} onTouchEnd={goToRequests}>
          Requests
        </div>
      );
    }

    subNavElements.push(
      <div key="sub-dash" className="subnav-menu-item" onClick={goToDashboard} onTouchEnd={goToDashboard}>
        Dashboard
      </div>
    );

    subNavElements.push(
      <div key="sub-about" className="subnav-menu-item" onClick={showAbout} onTouchEnd={showAbout}>
        About
      </div>
    );
  }

  subNavElements.push(
    <div key="sub-out" className="subnav-menu-item" onClick={signOut} onTouchEnd={signOut}>
      Sign Out
    </div>
  );

  const getLoginPageContent = () => (
    <>
      <div id="user-details" onMouseEnter={(_e) => setSubNav("user")} onMouseLeave={(_e) => setSubNav(undefined)}>
        <div className="text">
          <span className="customer">{customerName}</span>
          <span className="user">{userName}</span>
        </div>
        <div className="icon">
          <i className="fa fa-user" />
        </div>
        <div className={subNav == "user" ? "subnav-menu active" : "subnav-menu"}>{subNavElements}</div>
      </div>
    </>
  );

  return (
    <div id="TopBar">
      <LogoMenuContainer settings={settings} user={user} appName={appName} isScheduled={isScheduled} iconId={iconId} />
      <ContextRibbon />
      <TopBarContainer
        settings={settings}
        viewData={viewData}
        ui={ui}
        printRef={printRef}
        setUIContext={setUIContext}
        departmentsById={departmentsById}
      />
      <input id="topbar-close" />
      {toolbox}
      {appName === "login" ? getLoginPageContent() : <></>}
    </div>
  );
};

export default TopBar;
