import { useSelector } from "react-redux";

import { HIGHLIGHTER_COLORS } from "@/viewer/ui/modules/contextmenu/highlighters/constants";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { AppState } from "@/modules/reducer";
import { UIContext } from "@/viewer/ui/modules/common/types/context";

export default (ui: UIContext, slot: SlotOrRequest): string | undefined => {
  const { empId, assignId } = slot;
  const id = ui.leftColumnType === "assignment" ? empId : assignId;
  const highlighters = useSelector((state: AppState) => state.highlighters.colors);
  const highlighterColor: string | undefined = Object.keys(highlighters).find((key) => highlighters[key] === id);

  if (highlighterColor) {
    return HIGHLIGHTER_COLORS[highlighterColor];
  }

  return undefined;
};
