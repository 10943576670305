"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");
// components
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
var RequestDetailsDialogMain = require("./RequestDetailsDialogMain.jsx");

// some status string constants
var STATUS_PENDING = "pending";
var STATUS_SUSPENDED = "suspended";
var STATUS_GRANTED = "granted";
var STATUS_DENIED = "denied";
var STATUS_DELETED = "deleted";

var RequestDetailsDialog = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {
    window.LbsAppData.AppContext.on("redraw", this.redraw.bind(this, null), this);

    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Request: Details");
  },
  componentWillUnmount: function () {
    window.LbsAppData.AppContext.off(null, null, this);
  },
  //componentWillUpdate: function() {
  //	this._unregisterBackboneEvents();
  //},
  //componentDidUpdate: function() {
  //	this._registerBackboneEvents();
  //},

  /* publics */
  close: function (e) {
    this.props.close();
  },
  redraw: function () {
    //// if the collection is undefined, then I _believe_ it's safe to say this was a removal -- close the dialog
    //if (this.props.dialogObject.data.collection) {
    //	// don't need to do anything
    //} else {
    //	window.LbsAppData.AppContext.closeDialog('request-details');
    //}

    // close the dialog no matter what now
    window.LbsAppData.AppContext.closeDialog("request-details");
  },

  /* privates */

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      "request-details": true,
      isTop: this.props.isTop,
    });

    return (
      <div className={classes}>
        <div className="container-middle">
          <RequestDetailsDialogMain
            flags={this.props.flags}
            close={this.props.close}
            dialogObject={this.props.dialogObject}
          />
        </div>
      </div>
    );

    /*
			<span className='value uppercase'>{type}</span><br/><span className='value large underline'>{emp}</span> for<br/><span className='value large underline'>{assignment} ({template})</span> on<br/><span className='value large underline'>{date}</span>

		*/
  },
});

module.exports = RequestDetailsDialog;
