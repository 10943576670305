// dependencies
var moment = require("moment");
var _ = require("underscore");

var Router = require("./Router.js");
var AppContext = require("./models/AppContext.js");

// collections
var PersonnelCollection = require("./collections/PersonnelCollection.js");
var ReportDefinitionCollection = require("./collections/ReportDefinitionCollection.js");
// models
var User = require("./models/User.js");
var Permissions = require("./models/Permissions.js");
var ReportDefinition = require("./models/ReportDefinition.js");
var AccessibleApps = require("_lib/data/models/AccessibleApps.js");
var Destinations = require("_lib/data/models/Destinations.js");
var Personnel = require("./models/Personnel.js");

// utilities
var ToolManager = require("reports/utils/toolbox/ToolManager.js");

// helpers
var AnalyticsHelper = require("_lib/helpers/AnalyticsHelper.js");
var ErrorsHelper = require("_lib/helpers/ErrorsHelper.js");
var FeaturesHelper = require("_lib/helpers/FeaturesHelper.js");
var PermissionsHelper = require("_lib/helpers/PermissionsHelper.js");
var TimeHelper = require("_lib/helpers/TimeHelper.js");
var SocketHelper = require("_lib/helpers/SocketHelper").default;
var ReportsHelper = require("reports/utils/helpers/ReportsHelper.js");
var NodeHelper = require("reports/utils/helpers/NodeHelper.js");
var CacheHelper = require("reports/utils/helpers/CacheHelper.js");
const SafeJSON = require("../../common/utils/jsonParseShield");
const injectInstrumentation = require("@/_lib/utils/instrumentationClient").default;

var LbsAppData = (payload) => ({
  // app identifier
  id: "reports",

  AppContext: new AppContext(),
  ToolBox: new ToolManager(),

  // helpers collection
  Helpers: {
    Analytics: new AnalyticsHelper(),
    Errors: new ErrorsHelper(),
    Features: new FeaturesHelper(),
    Permissions: undefined, // defer this until we get some base level data
    Reports: new ReportsHelper(),
    Node: new NodeHelper(),
    Cache: new CacheHelper(),
    Socket: undefined,
    Time: new TimeHelper(),
  },

  //Util_TouchEndFilter: TouchEndFilter,

  Departments: undefined,
  Personnel: undefined,
  User: undefined,
  Permissions: undefined,
  MyPersonnel: undefined,
  AccessibleApps: undefined,
  Destinations: undefined,

  init: function (callback) {
    // sync backbone and refresh the token
    window.LbsAppData.AppContext._syncBackbone();
    injectInstrumentation();

    // build the collections by parsing the payload object
    // attached to the window
    this.User = new User(SafeJSON.parse(payload.user));
    this.Permissions = new Permissions(SafeJSON.parse(payload.permissions));
    this.Personnel = new PersonnelCollection(SafeJSON.parse(payload.user_personnel));
    this.AccessibleApps = new AccessibleApps(SafeJSON.parse(payload.applications));
    this.Destinations = new Destinations(SafeJSON.parse(payload.destinations));
    this.MyPersonnel = new Personnel(SafeJSON.parse(payload.user_personnel));

    // create the permissions helper
    this.Helpers.Permissions = new PermissionsHelper();

    // push the report context
    this.AppContext.pushContext({
      tag: "report",
      text: "New Report",
      tooltip: "edit definition",
      action: function () {
        window.LbsAppData.AppContext.openDialog({
          type: window.LbsAppData.User.get("is_admin") ? "report-edit" : "manage-reports",
        });
      },
    });

    // if there is a report_definition coming down on the payload (and no shadow) then set it to the app context
    // and auto-fetch it
    if (!window.location.hash) {
      if (payload.report_definition) {
        // make sure we're authorized, then fetch the report
        this.AppContext.once(
          "authorized",
          function () {
            this.AppContext.updateReportContext(new ReportDefinition(SafeJSON.parse(payload.report_definition)));
            this.AppContext.fetchReportDraft();
          },
          this
        );
      } else {
        // open up the report-edit dialog if we aren't going to hit the shadow router
        // -- give it like 1 second to prevent whiplash
        window.setTimeout(function () {
          window.LbsAppData.AppContext.openDialog({
            type: window.LbsAppData.User.get("is_admin") ? "report-edit" : "manage-reports",
            options: {
              forceSubmit: false,
            },
          });
        }, 1000);
      }
    } else {
      if (payload.report_definition) {
        this.AppContext.updateReportContext(new ReportDefinition(SafeJSON.parse(payload.report_definition)));
        // no refresh
      }
    }

    // register the AppContext events
    this.AppContext.registerEvents();

    // register the socket helper for realtime communication
    this.Helpers.Socket = new SocketHelper({
      receive: this._receiveSocketMessage,
    });
  },

  _receiveSocketMessage: function (type, data) {
    switch (type) {
      case "slot_update":
      case "slot_remove":
      case "schedule_clear":
        // set a last change bit on the refresh report model
        if (window.LbsAppData.AppContext.get("report_draft_refresh")) {
          window.LbsAppData.AppContext.get("report_draft_refresh").set({
            last_shadow_change: moment(),
          });
        }

        break;
      default:
        break;
    }
  },

  attachRouter: function () {
    console.log("History Started");
    this.Router = new Router();
    Backbone.history.start();
  },
});

module.exports = LbsAppData;
