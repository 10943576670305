const Backbone = require("backbone");
const _ = require("underscore");
var ApiConfig = require("_lib/data/ApiConfig.js");
var ModelMixins = require("./_ModelMixins.js");

var Request = Backbone.Model.extend({
  idAttribute: "request_id",

  defaults: {
    boundTool: undefined,
  },

  initialize: function (models, options) {
    // convert time to user time zone
    this.attributes.request_date_original = this.attributes.request_date;
    this.attributes.request_date = window.LbsAppData.Helpers.Time.timezoneConverter(this.attributes.start_time).format(
      "YYYY-MM-DD"
    );
    this.attributes.start_time_original = this.attributes.start_time;
    this.attributes.start_time = window.LbsAppData.Helpers.Time.timezoneConverter(this.attributes.start_time).format(
      "YYYY-MM-DDTHH:mm:ss"
    );
    this.attributes.stop_time_original = this.attributes.stop_time;
    this.attributes.stop_time = window.LbsAppData.Helpers.Time.timezoneConverter(this.attributes.stop_time).format(
      "YYYY-MM-DDTHH:mm:ss"
    );
    this.templateObj = window.LbsAppData.Templates.get(this.attributes.template_id);
    this.personnelObj = window.LbsAppData.Personnel.get(this.attributes.emp_id);
    this.assignmentObj = window.LbsAppData.Assignments.get(this.attributes.condensed_structure_id);

    if (this.templateObj) {
      this.listenTo(this.templateObj, "change:available", this.setAvailability);
      this.listenTo(this.templateObj, "change:filtered", this.setAvailability);
    }

    if (this.personnelObj) {
      this.listenTo(this.personnelObj, "change:available", this.setAvailability);
      this.listenTo(this.personnelObj, "change:filtered", this.setAvailability);
    }
    if (this.assignmentObj) {
      this.listenTo(this.assignmentObj, "change:available", this.setAvailability);
      this.listenTo(this.assignmentObj, "change:filtered", this.setAvailability);
    }

    // subscribe to personnelObj/assignmentObj changes via tools
    if (this.personnelObj) {
      this.listenTo(this.personnelObj, "change:boundTool", this.toggleReceptive);

      // check to see if it's already bound
      if (this.personnelObj.attributes.boundTool) {
        this.toggleReceptive(this.personnelObj, this.personnelObj.attributes.boundTool);
      }
    }
    if (this.assignmentObj) {
      this.listenTo(this.assignmentObj, "change:boundTool", this.toggleReceptive);

      // check to see if it's already bound
      if (this.assignmentObj.attributes.boundTool) {
        this.toggleReceptive(this.assignmentObj, this.assignmentObj.attributes.boundTool);
      }
    }

    // initialize the request's availability attributes
    this.attributes.available = this._checkAvailability();

    // create a searchable concatenation of some of the attributes
    this._createSearchableAttributeString();
  },

  setAvailability: function (eventObj) {
    if (!eventObj.attributes.available || eventObj.attributes.filtered) {
      // set to unavailable (if not already)
      if (this.attributes.available) {
        this.set({ available: false });
      }
    } else {
      var availability = this._checkAvailability();
      if (availability != this.attributes.available) {
        this.set({ available: availability });
      }
    }
  },

  _checkAvailability: function () {
    //var template = window.LbsAppData.Templates.findWhere({'name': this.attributes.template});		// change this to be an id lookup eventually
    //var personnel = window.LbsAppData.Personnel.get(this.attributes.emp_id);
    //var assignment = window.LbsAppData.Assignments.get(this.attributes.assign_structure_id);

    if (!this.personnelObj || !this.assignmentObj /* || !this.templateObj*/) {
      return false;
    } else {
      return (
        //this.templateObj.attributes.available &&
        //!this.templateObj.attributes.filtered &&
        this.personnelObj.attributes.available &&
        !this.personnelObj.attributes.filtered &&
        this.assignmentObj.attributes.available &&
        !this.assignmentObj.attributes.filtered
      );
    }
  },

  url: function () {
    return ApiConfig.getPrefix() + (this.id ? "/request/" + this.id : "/request/");
  },

  // This is only here to comply with Slot's interface.
  getHistory: function () {
    return undefined;
  },

  getAssignmentDisplayText: function () {
    // trivial ... just need to do compact vs non compact
    if (window.LbsAppData.AppContext.attributes.compactMode == "yes") {
      return this.get("assign_compact_name");
    } else {
      return this.get("assign_display_name");
    }
  },

  getPersonnelDisplayText: function () {
    // trivial ... just need to do compact vs non compact
    if (window.LbsAppData.AppContext.attributes.compactMode == "yes") {
      return this.get("compact_name");
    } else {
      return this.get("display_name");
    }
  },

  getSlotDisplayObject: function () {
    // in addition to giving a view context specific string back...also send back some particular data
    // that the ui elements might find useful
    var a = this.getAssignmentDisplayText();
    var p = this.getPersonnelDisplayText();

    var displayText = "";
    switch (window.LbsAppData.AppContext.attributes.layout) {
      case "hybrid":
      case "grid":
      case "list":
      case "gantt":
        displayText = window.LbsAppData.AppContext.attributes.GridSettings_leftColumnType == "assignment" ? p : a;
        break;

      case "calendar":
        if (window.LbsAppData.AppContext.attributes.view.view_id !== "me") {
          if (window.LbsAppData.AppContext.attributes.GridSettings_leftColumnType == "assignment") {
            displayText = a + " - " + p;
          } else {
            displayText = p + " - " + a;
          }
        } else {
          // an implied personnel in the me view of course
          displayText = a;
        }
    }

    return {
      assignmentText: a,
      personnelText: p,
      displayText: displayText,
    };
  },

  /* toolbox operations */
  toggleReceptive: function (model, tool) {
    // only change this if there isn't a tool bound already or we're trying to unbind
    // an existing one, I'm not sure if be more robust in the future but it should be
    // good enough for the select/highlighter combo

    if (!tool || !this.attributes.boundTool) {
      this.set({
        //receptive: !this.attributes.receptive,
        boundTool: tool,
      });
    }
  },
  getEffects: function () {
    if (this.attributes.boundTool) {
      // tool takes precedence
      return this.attributes.boundTool.style();
    } else {
      var ret = {};
      if (this.attributes.bg_color) {
        ret["backgroundColor"] = this.attributes.bg_color;
      }
      if (this.attributes.text_color) {
        ret["color"] = this.attributes.text_color;
      }

      return ret;
    }
  },
  getToolClasses: function () {
    if (this.attributes.boundTool && this.attributes.boundTool.extClasses) {
      // tool takes precedence
      return this.attributes.boundTool.extClasses();
    } else {
      return {};
    }
  },
});

// extend to include mixins here
_.extend(Request.prototype, ModelMixins.Searchable);

module.exports = Request;
