import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import {
  addWeeks,
  addDays,
  isBefore,
  startOfWeek as _startOfWeek,
  startOfDay as _startOfDay,
  isWeekend,
  differenceInBusinessDays,
  differenceInCalendarDays,
} from "date-fns";
import moment, { Moment } from "moment";
import { formatISODate } from "@/viewer/utils/dateFormatters";
import { dateRange, dateRangeInclusive } from "@/viewer/utils/helpers";
import { DateTime } from "luxon";

interface GetStartAndEndDateResponse {
  dateStart: Date;
  dateEnd: Date;
}

interface GetFocusedDatesResponse {
  startDate: Date;
  stopDate: Date;
  startDateWithCushion: Date;
  stopDateWithCushion: Date;
}

/**
 * Convert a moment to a date, stripping TZ info
 */
export const momentToDate = (m: Moment): Date => {
  return new Date(m.year(), m.month(), m.date(), m.hours(), m.minutes(), m.seconds(), m.milliseconds());
};

/**
 * Return the start and stop dates for views taking into account condensed column views.
 */
export const getStartAndEndDates = (settings: SettingsContext): GetStartAndEndDateResponse => {
  const { startDate, stopDate, startDateWithCushion, stopDateWithCushion } = getFocusedDates();
  const { condenseColumnView } = settings;
  return {
    dateStart: condenseColumnView ? startDate : startDateWithCushion,
    dateEnd: condenseColumnView ? stopDate : stopDateWithCushion,
  };
};

/**
 * Return the currently-selected start and stop dates, correcting for time zone
 */
export const getFocusedDates = (): GetFocusedDatesResponse => {
  const { LbsAppData } = window as any;
  const dates = LbsAppData.DateManager.getFocusedDates();

  return {
    startDate: momentToDate(dates.start),
    stopDate: momentToDate(dates.stop),
    startDateWithCushion: momentToDate(dates.startWithCushion),
    stopDateWithCushion: momentToDate(dates.stopWithCushion),
  };
};

const weekStartNum = (ui: UIContext): 0 | 6 | 1 => {
  switch (ui.startWeekOnDay) {
    case "Mon":
      return 1;
    case "Sat":
      return 6;
    default:
      return 0;
  }
};

export const startOfWeek = (ui: UIContext, date: Date): Date => {
  return _startOfWeek(date, { weekStartsOn: weekStartNum(ui) });
};

export const startOfDay = (date: Date): Date => {
  return _startOfDay(date);
};

/**
 * Return a list of [Date, Date] tuples representing a week's start and end dates. Account for the startWeekOnDay
 * setting in the process.
 */
export const getWeekRange = (
  settings: SettingsContext,
  ui: UIContext,
  startDate: Date,
  stopDate: Date
): [Date, Date][] => {
  const weekBounds: [Date, Date][] = [];

  if (isBefore(stopDate, startDate)) {
    // Avoid an infinite loop
    [stopDate, startDate] = [startDate, stopDate];
  }

  let weekStart = startOfWeek(ui, startDate);
  let weekEnd = addWeeks(weekStart, 1);

  while (isBefore(weekStart, stopDate)) {
    weekBounds.push([weekStart, weekEnd]);

    weekStart = addWeeks(weekStart, 1);
    weekEnd = addWeeks(weekEnd, 1);
  }

  return weekBounds;
};

/**
 * Return a list of dates, each representing a day within the range.
 */
export const getDayRange = (settings: SettingsContext, ui: UIContext, startDate: Date, stopDate: Date): Date[] => {
  const dayBounds: Date[] = [];

  if (isBefore(stopDate, startDate)) {
    // Avoid an infinite loop
    [stopDate, startDate] = [startDate, stopDate];
  }

  let dayStart = startOfDay(startDate);

  while (isBefore(dayStart, stopDate)) {
    dayBounds.push(dayStart);

    dayStart = addDays(dayStart, 1);
  }

  return dayBounds;
};

export const getCurrentWeekRange = (settings: SettingsContext, ui: UIContext): [Date, Date][] => {
  const { startDate, stopDate } = getFocusedDates();
  return getWeekRange(settings, ui, startDate, stopDate);
};

/**
 * For a given date, generate a key representing the week in which that date falls.
 */
export const getWeekKey = (settings: SettingsContext, ui: UIContext, date: Date): string => {
  return formatISODate(settings, startOfWeek(ui, date));
};

/**
 * For a given date, generate a key representing the day.
 */
export const getDayKey = (settings: SettingsContext, ui: UIContext, date: Date): string => {
  return formatISODate(settings, startOfDay(date));
};

/**
 * Like regular dateRange, but skip weekends if settings.hideWeekends is true
 */
export const visibleDateRange = (settings: SettingsContext, startDate: Date, endDate: Date): Date[] => {
  if (!settings.hideWeekends) {
    return dateRange(startDate, endDate);
  } else {
    return dateRange(startDate, endDate).filter((d) => !isWeekend(d));
  }
};

/**
 * Like regular dateRange, but skip weekends if settings.hideWeekends is true
 * Also includes the last day as a part of the range
 */
export const visibleDateRangeInclusive = (settings: SettingsContext, startDate: Date, endDate: Date): Date[] => {
  if (!settings.hideWeekends) {
    return dateRangeInclusive(startDate, endDate);
  } else {
    return dateRangeInclusive(startDate, endDate).filter((d) => !isWeekend(d));
  }
};

/**
 * Account for weekends when calculating the number of days that might be displayed in a range.
 */
export const differenceInVisibleDays = (settings: SettingsContext, dateLeft: Date, dateRight: Date): number => {
  // add one because the difference leaves off a day, diff(today, yesterday) is 1, not 2
  return settings.hideWeekends
    ? differenceInBusinessDays(dateLeft, dateRight) + 1
    : differenceInCalendarDays(dateLeft, dateRight) + 1;
};

export const sameDate = (a: DateTime, b: DateTime): boolean => {
  return a.hasSame(b, "day") && a.hasSame(b, "month") && a.hasSame(b, "year");
};

export const isToday = (settings: SettingsContext, ui: UIContext): boolean => {
  if (ui.dateMode === "daily") {
    const tz = settings.timeZone;
    const uiStartDate = DateTime.fromJSDate(ui.startDate);
    const todayWithTz = DateTime.now().setZone(tz);

    return sameDate(uiStartDate, todayWithTz);
  }

  return false;
};
