interface AccessTokenUpdate {
  type: "access-token/update";
  accessToken: string;
}

export const setAccessToken = (accessToken: string): AccessTokenUpdate => {
  return { type: "access-token/update", accessToken };
};

export type Action = AccessTokenUpdate;
