import * as React from "react";
import SlotContextClues from "@/viewer/ui/modules/common/components/SlotContextClues";
import SlotDisplay from "@/viewer/ui/modules/common/components/SlotDisplay";
import { DataContext, SettingsContext, ToolContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import SlotContainer from "@/viewer/ui/modules/common/components/SlotContainer";
import { startOfDay } from "date-fns";
import { formatLocalTime, formatUSDateNoYear } from "@/viewer/utils/dateFormatters";
import { CellType } from "@/viewer/ui/modules/common/types/contextClues";
import { isSlot } from "@/viewer/utils/domain/slotquests";
import { OriginalPersonnelMap } from "@/viewer/types/viewdata";
import { DepartmentsById } from "@/viewer/types/domain/department";

interface Props {
  slot: SlotOrRequest;
  settings: SettingsContext;
  ui: UIContext;
  data: DataContext;
  tools: ToolContext;
  myEmpId: number;
  cellType: CellType;
  originalPersonnelMap: OriginalPersonnelMap;
  departmentsById: DepartmentsById;
  isDataGroup?: boolean;
}

const getOverflowDateString = (settings: SettingsContext, slot: SlotOrRequest): string | undefined => {
  const stopTimeDate = startOfDay(slot.stopTime);

  if (slot.date < stopTimeDate) {
    return `(${formatUSDateNoYear(settings, stopTimeDate)})`;
  }
};

const CalendarSlot = (props: Props): JSX.Element => {
  const { slot, data, tools, settings, ui, isDataGroup, cellType, originalPersonnelMap, departmentsById } = props;
  const overflowString = getOverflowDateString(settings, slot) ?? "";
  let oddCellTypeBG: { [key: string]: unknown } = { backgroundColor: "#FEFEFE" };

  if (!isSlot(slot)) {
    oddCellTypeBG = {
      backgroundSize: "100%",
      background: "repeating-linear-gradient(-55deg, #fff9d6, #fff9d6 6%, #fffceb 6%, #fffceb 12%)",
    };
  }

  const evenCellTypeBG = {
    backgroundColor: "#E1E1E1",
  };

  let timeString;

  if (slot.startTime) {
    const startTime = slot.startTime;
    const stopTime = slot.stopTime;

    const startTimeString = formatLocalTime(settings, startTime);
    const stopTimeString = formatLocalTime(settings, stopTime);

    timeString = `${startTimeString} - ${stopTimeString} ${overflowString}`;
  }

  const slots = React.useMemo(() => [slot], [slot]);

  return (
    <SlotContainer
      settings={settings}
      ui={ui}
      data={data}
      slots={slots}
      tools={tools}
      departmentsById={departmentsById}
    >
      <div className="assignment">
        <SlotDisplay
          slot={slot}
          settings={settings}
          ui={ui}
          leftColId={props.myEmpId.toFixed(0)}
          originalPersonnelMap={originalPersonnelMap}
          departmentsById={departmentsById}
        />
      </div>

      <SlotContextClues
        slot={slot}
        settings={settings}
        ui={ui}
        data={data}
        tools={tools}
        isDataGroup={isDataGroup}
        slotEffects={cellType ? evenCellTypeBG : oddCellTypeBG} //setting the background programmatically prevents CSS from overriding context clues camouflage
        cellType={cellType}
      />

      <div className="bottom">{ui.showTimes ? <div className="time">{timeString}</div> : null}</div>
    </SlotContainer>
  );
};

export default CalendarSlot;
