import { SlotAttributes } from "@/viewer/types/domain/slot";
import { Pagination, Department, DepartmentSelectOption, PendingSelectOption } from "./activityFeedTypes";

export enum Type {
  GetActivityFeedSchedule = "activityFeed/getSchedule",
  SetActivityFeedPagination = "activityFeed/setPagination",
  GetActivityFeedDepartments = "activityFeed/detDepartments",
  SetActivityFeedSelectedDepartment = "activityFeed/setSelectedDepartment",
  SetActivityFeedSelectedPendingItem = "activityFeed/setSelectedPendingItem",
}

interface GetActivityFeedScheduleAction {
  type: Type.GetActivityFeedSchedule;
  payload: Array<SlotAttributes>;
}

interface GetActivityFeedDepartmentsAction {
  type: Type.GetActivityFeedDepartments;
  payload: Array<Department>;
}

interface SetActivityFeedSelectedDepartmentAction {
  type: Type.SetActivityFeedSelectedDepartment;
  payload?: DepartmentSelectOption;
}

interface SetActivityFeedPaginationAction {
  type: Type.SetActivityFeedPagination;
  payload: Pagination;
}

interface SetActivityFeedSelectedPendingItemAction {
  type: Type.SetActivityFeedSelectedPendingItem;
  payload?: PendingSelectOption;
}

export type Action =
  | GetActivityFeedScheduleAction
  | GetActivityFeedDepartmentsAction
  | SetActivityFeedPaginationAction
  | SetActivityFeedSelectedDepartmentAction
  | SetActivityFeedSelectedPendingItemAction;

export default {
  getActivityFeedSchedule: (payload: Array<SlotAttributes>): GetActivityFeedScheduleAction => ({
    type: Type.GetActivityFeedSchedule,
    payload,
  }),
  getActivityFeedDepartments: (payload: Array<Department>): GetActivityFeedDepartmentsAction => ({
    type: Type.GetActivityFeedDepartments,
    payload,
  }),
  setActivityFeedSelectedDepartment: (payload?: DepartmentSelectOption): SetActivityFeedSelectedDepartmentAction => ({
    type: Type.SetActivityFeedSelectedDepartment,
    payload,
  }),
  setActivityFeedPagination: (payload: Pagination): SetActivityFeedPaginationAction => ({
    type: Type.SetActivityFeedPagination,
    payload,
  }),
  setActivityFeedSelectedPendingItem: (payload?: PendingSelectOption): SetActivityFeedSelectedPendingItemAction => ({
    type: Type.SetActivityFeedSelectedPendingItem,
    payload,
  }),
};
