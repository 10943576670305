interface HighlighterOptions {
  color: string;
  id: number;
}

export enum Type {
  Highlight = "highlighters/highlight",
}

interface Highlight {
  type: Type.Highlight;
  payload: HighlighterOptions;
}

export type Action = Highlight;

export default {
  highlight: (payload: HighlighterOptions): Highlight => ({
    type: Type.Highlight,
    payload,
  }),
};
