import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/modules/reducer";

const ToastWrapper = (): React.ReactElement => {
  const { toast } = useSelector((state: RootState) => state.toast);

  return <>{toast}</>;
};

export default ToastWrapper;
