const Backbone = require("backbone");
const _ = require("lodash");
const Promise = require("bluebird");

const ApiConfig = require("_lib/data/ApiConfig.js");
const Request = require("./../models/Request.js");
const moment = require("moment");
const logging = require("@/_lib/utils/logging");

const RequestDataCollection = Backbone.Collection.extend({
  model: Request,
  url: function () {
    const queryParams = {};

    if (this.attributes.adhoc) {
      // we're creating an adhoc collection... so don't rely on anything outside of this object
      if (this.attributes.start_date) {
        queryParams["start_date"] = this.attributes.start_date.format("YYYYMMDD");
      }

      if (this.attributes.end_date) {
        queryParams["end_date"] = this.attributes.end_date.format("YYYYMMDD");
      }

      queryParams["listed"] = true;

      if (this.attributes.emp_id) {
        queryParams["emp_id"] = this.attributes.emp_id;
      }
      if (this.attributes.only_pending) {
        queryParams["only_pending"] = this.attributes.only_pending;
      }
    } else {
      // if start/end attributes where defined...use them...otherwise use the date manager
      if (this.attributes.startDate) {
        queryParams["start_date"] = this.attributes.startDate.format("YYYYMMDD");
      } else if (window?.LbsAppData?.DateManager?.attributes?.start_date) {
        queryParams["start_date"] = window.LbsAppData.DateManager.attributes.start_date.format("YYYYMMDD");
      }

      // ditto
      if (this.attributes.endDate) {
        queryParams["end_date"] = this.attributes.endDate.format("YYYYMMDD");
      } else if (window?.LbsAppData?.DateManager?.attributes?.stop_date) {
        queryParams["end_date"] = window.LbsAppData.DateManager.attributes.stop_date.format("YYYYMMDD");
      }

      // if this isn't the 'today' or 'me' view then pass in the view_id
      if (window?.LbsAppData?.AppContext?.attributes?.view?.view_id === "today") {
        if (this.attributes.template_list?.length) {
          queryParams["template_id"] = this.attributes.template_list[0];
        }

        if (window?.LbsAppData?.DateManager?.attributes?.schedule_start_date) {
          queryParams["start_date"] = window.LbsAppData.DateManager.attributes.schedule_start_date.format("YYYYMMDD");
        }

        if (window?.LbsAppData?.DateManager?.attributes?.schedule_stop_date) {
          queryParams["end_date"] = window.LbsAppData.DateManager.attributes.schedule_stop_date.format("YYYYMMDD");
        }
      } else if (window?.LbsAppData?.AppContext?.attributes?.view?.view_id === "me") {
        const empId = window?.LbsAppData?.User?.attributes?.emp_id;

        if (!empId) {
          logging.error("Emp id is null. Refusing to build /request/range request. - Viewer_SlotCollection");

          return null;
        }

        queryParams["emp_id"] = empId;
      } else {
        if (window?.LbsAppData?.AppContext?.attributes?.view?.view_id) {
          queryParams["view_id"] = window.LbsAppData.AppContext.attributes.view.view_id;
        }

        if (window?.LbsAppData?.AppContext?.attributes?.view?.is_legacy) {
          queryParams["legacy"] = window.LbsAppData.AppContext.attributes.view.is_legacy;
        }
      }
      if (window?.LbsAppData?.Helpers?.Time?.timezoneBeforeOrAfter() === "before") {
        const endDate = moment(queryParams["end_date"], "YYYYMMDD");

        queryParams["end_date"] = endDate.clone().add(1, "days").format("YYYYMMDD");
      }

      if (window?.LbsAppData?.Helpers?.Time?.timezoneBeforeOrAfter() === "after") {
        const startDate = queryParams["start_date"];

        queryParams["start_date"] = moment(startDate, "YYYYMMDD").subtract(1, "days").format("YYYYMMDD");
      }
    }

    queryParams["listed"] = "true";

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    return (
      ApiConfig.getPrefix() +
      "/request/range/?" +
      _.map(cleanQueryParams, function (value, key) {
        return key + "=" + value;
      }).join("&")
    );
  },

  initialize: function (models, attributes) {
    this.attributes = {};

    for (const key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  parse: function (response) {
    return response.data;
  },

  _fetchRequests: function () {
    return new Promise((resolve, reject) => {
      this.fetch({
        success: () => {
          resolve();
        },
        error: () => {
          reject();
        },
      });
    });
  },

  _getSlotComparatorValue: function (object, keyword) {
    try {
      if (keyword === "assign_order") {
        return object.assignmentObj.attributes.order;
      } else if (keyword === "personnel_order") {
        return object.personnelObj.attributes.order;
      } else if (keyword === "personnel_last_name") {
        return object.personnelObj.attributes.last_name.toLowerCase();
      } else if (keyword === "personnel_display_name") {
        return object.personnelObj.attributes.display_name.toLowerCase();
      } else if (keyword === "assign_display_name") {
        return object.assignmentObj.attributes.display_name.toLowerCase();
      } else if (keyword === "personnel_time_submitted") {
        return new Date(object.attributes.timestamp);
      } else {
        // return the slot attribute
        return object.attributes[keyword];
      }
    } catch (e) {
      return undefined;
    }
  },
});

module.exports = RequestDataCollection;
