/*
 * Slot collection that doesn't depend on window object bindings */
const Backbone = require("backbone");

const _ = require("lodash");
const Promise = require("bluebird");

const ApiConfig = require("_lib/data/ApiConfig.js");
const NakedSlot = require("./../models/NakedSlot.js");
const logging = require("@/_lib/utils/logging");

const NakedSlotCollection = Backbone.Collection.extend({
  model: NakedSlot,

  initialize: function (models, attributes) {
    this.attributes = {};
    for (const key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    const queryParams = {};

    if (this.attributes.adhoc) {
      // we're creating an adhoc slots collection... so don't rely on anything outside of this object
      if (this.attributes.start_date) {
        queryParams["start_date"] = this.attributes.start_date.format("YYYYMMDD");
      }

      // "Abnormal?? Viewing" uses end_date for, ... well the end_date
      if (this.attributes.end_date) {
        queryParams["end_date"] = this.attributes.end_date.format("YYYYMMDD");
      }

      queryParams["listed"] = true;

      if (this.attributes.emp_id) {
        queryParams["emp_id"] = this.attributes.emp_id;
      }

      if (this.attributes.only_pending) {
        queryParams["only_pending"] = this.attributes.only_pending;
      }
    } else {
      // normal 'viewing' collection
      if (window?.LbsAppData?.DateManager?.attributes?.start_date) {
        queryParams["start_date"] = window.LbsAppData.DateManager.attributes.start_date.format("YYYYMMDD");
      }

      // "Normal Viewing" uses the stop_date for .... the end_date
      if (this.attributes.stop_date) {
        queryParams["end_date"] = this.attributes.stop_date.format("YYYYMMDD");
      } else if (window?.LbsAppData?.DateManager?.attributes?.stop_date) {
        queryParams["end_date"] = window.LbsAppData.DateManager.attributes.stop_date.format("YYYYMMDD");
      }

      queryParams["listed"] = true;

      // if this isn't the 'today' or 'me' view then pass in the view_id
      if (window?.LbsAppData?.AppContext?.attributes?.view?.view_id === "today") {
        // nothing
      } else if (window?.LbsAppData?.AppContext?.attributes?.view?.view_id === "me") {
        const empId = window?.LbsAppData?.User?.attributes?.emp_id;

        if (!empId) {
          logging.error("Emp id is null. Refusing to build /schedule/range request. - NakedSlotCollection");

          return null;
        }

        queryParams["emp_id"] = empId;
      } else if (window?.LbsAppData?.AppContext?.attributes?.view?.view_id) {
        queryParams["view_id"] = window.LbsAppData.AppContext.attributes.view.view_id;
      }
    }

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    return (
      ApiConfig.getPrefix() +
      "/schedule/range/?" +
      _.map(cleanQueryParams, function (value, key) {
        return key + "=" + value;
      }).join("&")
    );
  },

  parse: function (response) {
    return response.data;
  },

  _fetchSlots: function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    return new Promise(function (resolve, reject) {
      that.fetch({
        success: function () {
          resolve();
        },
        error: function () {
          reject();
        },
      });
    });
  },
});

module.exports = NakedSlotCollection;
