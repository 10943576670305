const Backbone = require("backbone");

const { formatISODateTime } = require("@/viewer/utils/dateFormatters");
const { convertSlotHistoryTimestamp, fullStringConversion, timezoneConverter } = require("@/viewer/utils/timezones");

const STYLE_CONSTANTS = require("_lib/utils/constants");
const _ = require("underscore");
var ApiConfig = require("_lib/data/ApiConfig.js");
var ModelMixins = require("./_ModelMixins.js");

var Slot = Backbone.Model.extend({
  idAttribute: "slot_uuid",
  _historyInitialized: false,

  defaults: {
    boundTool: undefined,
  },

  initialize: function (attributes, options) {
    const tzStart = timezoneConverter(this.attributes.start_time);
    const tzStop = timezoneConverter(this.attributes.stop_time);

    // A silly hack to save a format:
    // Use substr to get the date from the datetime.
    const startTime = formatISODateTime(tzStart);
    const startDate = startTime.substr(0, 10);

    // convert time to user time zone
    this.attributes.slot_date_original = this.attributes.slot_date;
    this.attributes.slot_date = startDate;
    this.attributes.start_time_original = this.attributes.start_time;
    this.attributes.start_time = startTime;
    this.attributes.stop_time_original = this.attributes.stop_time;
    this.attributes.stop_time = formatISODateTime(tzStop);

    if (this.attributes.pending_info) {
      const pendingStart = timezoneConverter(this.attributes.pending_info.start_time);
      const pendingStop = timezoneConverter(this.attributes.pending_info.stop_time);

      this.attributes.pending_info.start_time_original = this.attributes.pending_info.start_time;
      this.attributes.pending_info.start_time = formatISODateTime(pendingStart);
      this.attributes.pending_info.stop_time_original = this.attributes.pending_info.stop_time;
      this.attributes.pending_info.stop_time = formatISODateTime(pendingStop);
    }

    // attach event handlers to the connected personnel, assignment and template
    this.templateObj = window.LbsAppData.Templates.get(this.attributes.template_id);

    /*
      If this slot is pending, verify the pending id is NOT DOTGUY (which returns no personnel obj).
      If pending is DOTGUY use emp_id, otherwise use the pending_emp_id.
      Finally, validate the user exists in our personnel collection, otherwise we get an undefined
      personnel object and ALL Swap / Pending actions will fail because the permission handlers
      in base.jsx (schedule_edits) do not receive a valid personnel id. If, the pending employee
      is not in our personnel collection, use the only option we have left, the emp_id which must
      be the logged in user and if that fails, well then we're all just completely fucked
     */
    if (this.get("is_pending")) {
      const { pending_emp_id, emp_id } = this.attributes;
      const targetPersonnel = pending_emp_id === window.LbsAppData.DOTGUY ? emp_id : pending_emp_id;
      let personnelObj = window.LbsAppData.Personnel.get(targetPersonnel);

      if (!personnelObj) personnelObj = window.LbsAppData.Personnel.get(emp_id);

      this.personnelObj = personnelObj;
    } else {
      this.personnelObj = window.LbsAppData.Personnel.get(this.attributes.emp_id);
    }

    this.assignmentObj = window.LbsAppData.Assignments.get(this.attributes.condensed_structure_id);

    // subscribe to filtering changes
    if (this.templateObj) {
      this.listenTo(this.templateObj, "change:available", this.setAvailability);
      this.listenTo(this.templateObj, "change:filtered", this.setAvailability);
    }

    if (this.personnelObj) {
      this.listenTo(this.personnelObj, "change:available", this.setAvailability);
      this.listenTo(this.personnelObj, "change:filtered", this.setAvailability);
    }

    if (this.assignmentObj) {
      this.listenTo(this.assignmentObj, "change:available", this.setAvailability);
      this.listenTo(this.assignmentObj, "change:filtered", this.setAvailability);
    }

    // subscribe to personnelObj/assignmentObj changes via tools
    if (this.personnelObj) {
      this.listenTo(this.personnelObj, "change:boundTool", this.toggleReceptive);

      // check to see if it's already bound
      if (this.personnelObj.attributes.boundTool) {
        this.toggleReceptive(this.personnelObj, this.personnelObj.attributes.boundTool);
      }
    }
    if (this.assignmentObj) {
      this.listenTo(this.assignmentObj, "change:boundTool", this.toggleReceptive);

      // check to see if it's already bound
      if (this.assignmentObj.attributes.boundTool) {
        this.toggleReceptive(this.assignmentObj, this.assignmentObj.attributes.boundTool);
      }
    }

    // initialize the slot's availability attributes
    this.attributes.available = this._checkAvailability();

    // create a searchable concatenation of some of the attributes
    this._createSearchableAttributeString();

    // figure out background/text colors
    this._calculateColors();
  },

  setAvailability: function (eventObj) {
    if (!eventObj.attributes.available || eventObj.attributes.filtered) {
      // set to unavailable (if not already)
      if (this.attributes.available) {
        this.set({ available: false });
      }
    } else {
      var availability = this._checkAvailability();
      if (availability != this.attributes.available) {
        this.set({ available: availability });
      }
    }
  },

  _checkAvailability: function () {
    if (!this.personnelObj || !this.assignmentObj) {
      return false;
    } else {
      return (
        this.personnelObj.attributes.available &&
        !this.personnelObj.attributes.filtered &&
        this.assignmentObj.attributes.available &&
        !this.assignmentObj.attributes.filtered
      );
    }
  },

  url: function () {
    return ApiConfig.getPrefix() + (this.id ? "/schedule/details/" + this.id : "/schedule/details");
  },

  initHistory: function () {
    if (!this._historyInitialized && this.attributes.slot_history.length) {
      this.attributes.slot_history.forEach((h) => {
        h.timestamp_original = h.timestamp;
        h.timestamp = formatISODateTime(convertSlotHistoryTimestamp(h.timestamp));
        h.text_original = h.text;
        h.text = fullStringConversion(h.text);
      });
    }
  },

  // Since correcting the history timezones was eating up valuable loading time,
  // this method was introduced to defer that work. slot_history should only be accessed via this helper.
  getHistory: function () {
    if (!this._historyInitialized) {
      this.initHistory();
      this._historyInitialized = true;
    }

    return this.get("slot_history");
  },

  getAssignmentDisplayText: function () {
    // trivial ... just need to do compact vs non compact
    if (window.LbsAppData.AppContext.attributes.compactMode == "yes") {
      return this.get("assign_compact_name");
    } else {
      return this.get("assign_display_name");
    }
  },

  getPersonnelDisplayText: function (ignorePending) {
    // not trivial...so much l-b here.  l-b gonna l-b
    if (ignorePending) {
      if (this.get("display_name_override")) {
        // overridden text
        if (
          window.LbsAppData.AppContext.attributes.GridSettings_leftColumnType == "assignment" ||
          window.LbsAppData.AppContext.layout == "calendar"
        ) {
          return this.get("display_name_override");
        } else {
          return "[" + this.get("display_name_override") + "]";
        }
      } else {
        if (window.LbsAppData.AppContext.attributes.compactMode == "yes") {
          return this.get("compact_name");
        } else {
          return this.get("display_name");
        }
      }
    } else {
      if (this.get("display_name_override")) {
        // overridden text
        if (
          window.LbsAppData.AppContext.attributes.GridSettings_leftColumnType == "assignment" ||
          window.LbsAppData.AppContext.layout == "calendar"
        ) {
          return this.get("display_name_override");
        } else {
          return "[" + this.get("display_name_override") + "]";
        }
      } else if (this.get("is_pending") && this.get("emp_id") == window.LbsAppData.DOTGUY) {
        // pending fill
        if (window.LbsAppData.AppContext.attributes.compactMode == "yes") {
          return this.get("pending_compact_name");
        } else {
          return this.get("pending_display_name");
        }
      } else if (this.get("is_pending") && this.get("pending_emp_id") == window.LbsAppData.DOTGUY) {
        // pending remove
        if (window.LbsAppData.AppContext.attributes.compactMode == "yes") {
          return this.get("compact_name");
        } else {
          return this.get("display_name");
        }
      } else if (this.get("is_pending") && this.get("emp_id") == this.get("pending_emp_id")) {
        // pending remove
        if (window.LbsAppData.AppContext.attributes.compactMode == "yes") {
          return this.get("compact_name");
        } else {
          return this.get("display_name");
        }
      } else if (this.get("is_pending") && this.get("pending_info").preswaps.length === 0) {
        // pending replace (the and condition is to make sure it's not a swop)
        if (window.LbsAppData.AppContext.attributes.compactMode == "yes") {
          return this.get("compact_name") + " [" + this.get("pending_compact_name") + "]";
        } else {
          return this.get("display_name") + " [" + this.get("pending_display_name") + "]";
        }
      } else {
        if (window.LbsAppData.AppContext.attributes.compactMode == "yes") {
          return this.get("compact_name");
        } else {
          return this.get("display_name");
        }
      }
    }
  },

  getSlotDisplayObject: function (contextEmpID) {
    // in addition to giving a view context specific string back...also send back some particular data
    // that the ui elements might find useful
    var a = this.getAssignmentDisplayText();
    var p = this.getPersonnelDisplayText(
      window.LbsAppData.AppContext.attributes.view.theme.data.hidePending == "yes" ? true : false
    );

    var displayText = "";
    switch (window.LbsAppData.AppContext.attributes.layout) {
      case "hybrid":
      case "grid":
      case "list":
      case "gantt":
      case "block":
        displayText = window.LbsAppData.AppContext.attributes.GridSettings_leftColumnType == "assignment" ? p : a;
        break;

      case "calendar":
        if (window.LbsAppData.AppContext.attributes.view.view_id !== "me") {
          if (window.LbsAppData.AppContext.attributes.GridSettings_leftColumnType == "assignment") {
            displayText = a + " - " + p;
          } else {
            displayText = p + " - " + a;
          }
        } else {
          // an implied personnel in the me view of course -- heh.. 'of course'.. not so fast skippy
          // -- gotta add CUSTOM DISPLAY TEXT!!
          displayText = a;
          if (this.get("display_name_override")) {
            displayText = a + " - " + p;
          }
        }
    }

    var isPendingDelete = false;
    var isPendingFill = false;
    var isPendingReplace = false;
    var isPendingDetails = false;
    var isSwop = false;

    if (window.LbsAppData.AppContext.attributes.view.theme.data.hidePending != "yes") {
      isPendingDelete = this.get("is_pending") && this.get("pending_emp_id") == window.LbsAppData.DOTGUY;
      isPendingFill = this.get("is_pending") && this.get("emp_id") == window.LbsAppData.DOTGUY;

      isPendingReplace = false;
      // pending replace logic depends on if it's a by personnel or by assignment if
      if (
        this.get("is_pending") &&
        this.get("pending_info").preswaps.length === 0 &&
        this.get("pending_emp_id") != window.LbsAppData.DOTGUY &&
        this.get("emp_id") != window.LbsAppData.DOTGUY
      ) {
        if (this.get("pending_emp_id") === this.get("emp_id")) {
          // same person -- details modification
          isPendingDetails = true;
        } else if (
          window.LbsAppData.AppContext.attributes.GridSettings_leftColumnType == "assignment" ||
          (window.LbsAppData.AppContext.attributes.layout == "calendar" &&
            window.LbsAppData.AppContext.attributes.view.view_id !== "me")
        ) {
          // easy...and makes sense
          isPendingReplace = true;
        } else {
          // gets changed to a fill/delete style depending on where this guy is going
          // this condition must have the contextEmpID argument filled in
          if (contextEmpID == this.get("emp_id")) {
            // they are being moved off of it in the replace
            isPendingDelete = true;
          } else if (contextEmpID == this.get("pending_emp_id")) {
            // they are being moved into it in the replace
            isPendingFill = true;
          }
        }
      }
      isSwop = this.get("is_pending") && this.get("pending_info").preswaps.length > 0;
    }

    // by personnel view in the grid type views...might need to put custom text on the display text
    if (
      this.get("display_name_override") &&
      window.LbsAppData.AppContext.attributes.GridSettings_leftColumnType == "personnel" &&
      window.LbsAppData.AppContext.attributes.layout != "list" &&
      window.LbsAppData.AppContext.attributes.layout != "calendar"
    ) {
      displayText = a + " " + p;
    }

    // only 1 of the _pending attributes should ever be true at a time
    return {
      assignmentText: a,
      personnelText: p,
      displayText: displayText,

      is_pending_delete: isPendingDelete,
      is_pending_fill: isPendingFill,
      is_pending_replace: isPendingReplace,
      is_pending_details: isPendingDetails,
      is_swop: isSwop,
    };
  },

  isVisible: function () {
    // available set based on filters believe
    var isVisible = this.attributes.available;

    // special pending (add) dotguy check...will be visible IF the assignment is available
    isVisible =
      isVisible ||
      (this.assignmentObj.attributes.available &&
        !this.assignmentObj.attributes.filtered &&
        this.attributes.emp_id == window.LbsAppData.DOTGUY &&
        this.attributes.is_pending);

    return isVisible;
  },

  getPendingDetail: function () {
    var ret = {};

    var pInfo = this.get("pending_info");
    // loa reason
    if (this.get("loa_reason_id") !== pInfo.loa_reason_id) {
      ret.name = "LOA Reason";
      ret.action = "add";
      ret.value = pInfo.loa_reason_name;
    }

    return ret;
  },

  /* toolbox operations */
  toggleReceptive: function (model, tool) {
    // only change this if there isn't a tool bound already or we're trying to unbind
    // an existing one, I'm not sure if be more robust in the future but it should be
    // good enough for the select/highlighter combo

    if (!tool || !this.attributes.boundTool) {
      this.set({
        boundTool: tool,
      });
    }
  },
  getEffects: function (onlyTool) {
    if (this.attributes.boundTool) {
      // tool takes precedence
      return this.attributes.boundTool.style();
    } else if (!onlyTool) {
      var ret = {};
      if (this.attributes.bg_color) {
        ret["backgroundColor"] = this.attributes.bg_color;
      }
      if (this.attributes.text_color) {
        ret["color"] = this.attributes.text_color;
      }

      return ret;
    } else {
      // list views don't care about personnel/assignment colorings
      return {};
    }
  },
  getToolClasses: function () {
    if (this.attributes.boundTool && this.attributes.boundTool.extClasses) {
      // tool takes precedence
      return this.attributes.boundTool.extClasses();
    } else {
      return {};
    }
  },

  _calculateColors: function () {
    var attributes = {};

    // bail out if this is an adhoc pull (for now..)
    if (this.collection?.attributes?.adhoc) {
      // do nothing
    } else {
      var bgColor = window.LbsAppData.AppContext.attributes.colorMethod != "none" ? this._getBgColor() : undefined;
      var textColor =
        window.LbsAppData.AppContext.attributes.colorTextMethod != "none" ? this._getTextColor(bgColor) : undefined;
      //var textColor = window.LbsAppData.AppContext.attributes.colorTextMethod != 'none' ? this._getTextColor(bgColor) : undefined;

      if (
        window.LbsAppData.AppContext.attributes.colorMethod == "mixed" ||
        window.LbsAppData.AppContext.attributes.colorMethod == "cell"
      ) {
        if (bgColor) {
          attributes["bg_color"] = bgColor;
        }
      }

      if (window.LbsAppData.AppContext.attributes.colorTextMethod == "colored") {
        if (textColor) {
          attributes["text_color"] = textColor;
        }
      }
    }

    this.set(attributes);
  },

  _getBgColor: function () {
    var bgColor;
    if (window.LbsAppData.AppContext.getLeftColumnType() == "personnel") {
      bgColor = this.assignmentObj ? this.assignmentObj.attributes.color : undefined; // ? this.assignmentObj.attributes.color : this.personnelObj.attributes.color;
    } else {
      bgColor = this.personnelObj ? this.personnelObj.attributes.color : undefined; // ? this.personnelObj.attributes.color : this.assignmentObj.attributes.color;
    }

    return bgColor;
  },

  _getTextColor: function (bgColor) {
    var textColor;

    var personnelTextColor =
      this.personnelObj && this.personnelObj.attributes.colorText ? this.personnelObj.attributes.colorText : undefined;
    var assignmentTextColor =
      this.assignmentObj && this.assignmentObj.attributes.colorText
        ? this.assignmentObj.attributes.colorText
        : undefined;

    // ternary mess (yep, sure is) -- the idea is for the text color to be, in order:
    // 1. inner text color if defined
    // 2. default text color if not defined, but a background color is
    // 3. outer text color if defined
    // 4. default text color (undefined)
    if (window.LbsAppData.AppContext.getLeftColumnType() == "personnel") {
      textColor = assignmentTextColor
        ? assignmentTextColor
        : bgColor
        ? STYLE_CONSTANTS.VIEWER_TEXT_COLOR
        : personnelTextColor
        ? personnelTextColor
        : undefined;
    } else {
      textColor = personnelTextColor
        ? personnelTextColor
        : bgColor
        ? STYLE_CONSTANTS.VIEWER_TEXT_COLOR
        : assignmentTextColor
        ? assignmentTextColor
        : undefined;
    }

    return textColor;
  },
});

// extend to include mixins here
_.extend(Slot.prototype, ModelMixins.Searchable);

module.exports = Slot;
