const Backbone = require("backbone");
// Manager to try and contain what will surely turn into a mess of date logic

var moment = require("moment");
var _ = require("underscore");
var $ = require("jquery");

var DateManager = Backbone.Model.extend({
  defaults: {
    cushion: 7,
    //max_focus_duration: moment.duration(3, 'months')
  },

  initialize: function () {},

  /* publics */
  setScheduleEndpoints: function (startDate, stopDate, isWeekView) {
    //var maxFocusDuration = isWeekView ? moment.duration(1, 'weeks') : moment.duration(2, 'weeks');
    //var durationUnit = isWeekView ? 'weeks' : 'months';

    // check to see if we should fragmentize this schedule
    //var shouldFragment = this._shouldFragment(moment(startDate), moment(stopDate));

    // if this schedule is longer than 3 months, then create the fragmented boundaries
    var fragments, currentFragment;
    if (this._shouldFragment(moment(startDate), moment(stopDate)) || isWeekView) {
      var fragmentRanges = this._createFragmentRanges(moment(startDate), moment(stopDate), isWeekView);

      // if only 1 fragment gets created with the fragment logic...we're good without it
      // the schedule range falls within a range that is really close to what the fragment is so we can ignore it
      if (fragmentRanges.length > 1) {
        //this.set({
        //	//focus_start_date: moment(this.attributes.start_date),
        //	//focus_stop_date: moment(this.attributes.start_date).add(this.attributes.max_focus_duration),
        //	fragments: fragments,
        //	currentFragment: 0,
        //});
        fragments = fragmentRanges;
        currentFragment = 0;

        // show the fragments pane on startup
        window.LbsAppData.AppContext.openPane({ type: "fragments" });
      }
    }
    //if (moment(startDate).add(maxFocusDuration).isBefore(stopDate, 'days')) {
    //}

    this.set({
      fragments: fragments,
      currentFragment: currentFragment,
      schedule_start_date: moment(startDate),
      schedule_stop_date: moment(stopDate),
      start_date: moment(startDate).subtract(this.attributes.cushion, "days"),
      stop_date: moment(stopDate).add(this.attributes.cushion, "days"),
      //max_focus_duration: maxFocusDuration,
      //duration_unit: durationUnit
    });
  },

  getFocusedDates: function () {
    var ret = { start: undefined, stop: undefined };
    // if the focus attributes are set, use those
    if (this.isFragmented()) {
      ret.start = moment(this.attributes.fragments[this.attributes.currentFragment].start);
      ret.stop = moment(this.attributes.fragments[this.attributes.currentFragment].stop);
      ret.startWithCushion = moment(this.attributes.fragments[this.attributes.currentFragment].startWithCushion);
      ret.stopWithCushion = moment(this.attributes.fragments[this.attributes.currentFragment].stopWithCushion);
    } else {
      // otherwise just use the cushioned dates
      ret.start = moment(this.attributes.schedule_start_date);
      ret.stop = moment(this.attributes.schedule_stop_date);
      ret.startWithCushion = moment(this.attributes.start_date);
      ret.stopWithCushion = moment(this.attributes.stop_date);
    }

    return ret;
  },

  isDateInSchedule: function (inDate) {
    var isBefore = inDate.isBefore(this.attributes.schedule_start_date.format("YYYY-MM-DD"), "days");
    var isAfter = inDate.isAfter(this.attributes.schedule_stop_date.format("YYYY-MM-DD"), "days");
    if (!isBefore && !isAfter) {
      return true;
    } else {
      return false;
    }
  },

  isFragmented: function () {
    return this.attributes.fragments ? true : false;
  },

  selectFragment: function (index) {
    //var that = this;
    //
    //// show the please wait
    //$('#please-wait').show();
    //window.setTimeout(function() {
    //	that.set({currentFragment: index});
    //}, 50);

    var that = this;
    // push the please wait
    window.LbsAppData.AppContext.openLoadingDialog("Moving dates...", this, function () {
      that.set({ currentFragment: index });
      window.LbsAppData.AppContext.trigger("redraw");
    });

    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Focus: Fragment", index);
  },

  /* privates */
  _shouldFragment: function (startDate, stopDate) {
    // lets see how complicated this function gets... for now we're going to make it
    // a slot limit on 2000 -- a 'slot' is defined as an intersection between the left
    // header row and the date column
    var max_slots = 5000;
    var weeks = stopDate.diff(startDate, "weeks");

    var shouldFragment = false;
    if (window.LbsAppData.AppContext.get("GridSettings_leftColumnType") == "personnel") {
      shouldFragment = window.LbsAppData.Personnel.where({ available: true }).length * weeks * 7 > max_slots;
    } else {
      shouldFragment = window.LbsAppData.Assignments.where({ available: true }).length * weeks * 7 > max_slots;
    }

    return shouldFragment;
  },
  _createFragmentRanges: function (startDate, stopDate, isWeekView) {
    var fragments = [];

    // rudimentary date splits
    //var rollingDate = moment(this.attributes.schedule_start_date);
    //while (rollingDate.isBefore(this.attributes.schedule_stop_date, 'days')) {
    //	var fragmentStart = moment(rollingDate);
    //	var fragmentStartWithCushion = moment(fragmentStart).subtract(1, 'weeks');
    //	var fragmentStop = moment(rollingDate).add(this.attributes.max_focus_duration);
    //	var fragmentStopWithCushion;
    //	if (this.attributes.duration_unit == 'weeks'){
    //		fragmentStopWithCushion = fragmentStop.clone();
    //	} else {
    //		fragmentStopWithCushion = moment(fragmentStop).add(1, 'weeks');
    //	}
    //	fragments.push({
    //		start: fragmentStart,
    //		stop: fragmentStop,
    //		startWithCushion: fragmentStartWithCushion,
    //		stopWithCushion: fragmentStopWithCushion,
    //		durationUnit: this.attributes.duration_unit
    //	});
    //
    //	rollingDate.add(this.attributes.max_focus_duration);
    //}

    // slot count splits
    var max_slots = 2500;
    var weeks = Math.ceil(moment(stopDate).endOf("week").diff(moment(startDate).startOf("week"), "days") / 7);
    var numRows, slotsPerWeek, weeksPerSplit, idealDays;
    if (window.LbsAppData.AppContext.get("GridSettings_leftColumnType") == "personnel") {
      numRows = window.LbsAppData.Personnel.where({ available: true }).length;
      idealDays = max_slots / numRows;

      weeksPerSplit = Math.round(idealDays / 7);
    } else {
      numRows = window.LbsAppData.Assignments.where({ available: true }).length;
      idealDays = max_slots / numRows;

      weeksPerSplit = Math.round(idealDays / 7);
    }

    if (isWeekView) {
      // force it to 1 week
      weeksPerSplit = 1;
    }

    var rollingDate = moment(startDate).startOf("week");
    while (rollingDate.isBefore(moment(stopDate).endOf("week"), "days")) {
      var fragmentStart = moment(rollingDate).startOf("week");
      //var fragmentStartWithCushion = moment(fragmentStart).subtract(1, 'weeks');
      var fragmentStartWithCushion = moment(fragmentStart);
      var fragmentStop = moment.min(
        moment(fragmentStart)
          .endOf("week")
          .add(weeksPerSplit - 1, "weeks"),
        moment(stopDate).endOf("week")
      );
      var fragmentStopWithCushion = moment(fragmentStop);
      //var fragmentStopWithCushion;
      //if (this.attributes.duration_unit == 'weeks') {
      //	fragmentStopWithCushion = fragmentStop.clone();
      //} else {
      //	fragmentStopWithCushion = moment(fragmentStop).add(1, 'weeks');
      //}

      fragments.push({
        start: fragmentStart,
        stop: fragmentStop,
        startWithCushion: fragmentStartWithCushion,
        stopWithCushion: fragmentStopWithCushion,
        //durationUnit: this.attributes.duration_unit
      });

      rollingDate.add(weeksPerSplit, "weeks");
    }

    return fragments;
  },
});

module.exports = DateManager;
