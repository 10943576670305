import React from "react";

import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { SlotsByDate, SlotsByDateByColumn } from "@/viewer/ui/modules/common/types";
import { columnRowHeightMap } from "../ColumnPrintView";
import { ViewData } from "@/viewer/types/viewdata";
import { Slot } from "@/viewer/types/domain/slot";

import classnames from "@/_lib/utils/classnames";

import ColumnCell from "../../components/ColumnCell";

import "./_styles.scss";
import { DepartmentsById } from "@/viewer/types/domain/department";

interface Props {
  cellDate: Date;
  cellEffects: React.CSSProperties;
  cellKey: any;
  columnRowHeightMap: columnRowHeightMap;
  leftColId: string;
  leftHandColumnId: number;
  settings: SettingsContext;
  slotData: SlotsByDateByColumn;
  ui: UIContext;
  viewData: ViewData;
  visibleItemCount: number;
  height: string;
  rowIndex: number;
  departmentsById: DepartmentsById;
}

const PrintColumnSlotItem = (props: Props): JSX.Element => {
  const {
    cellDate,
    cellEffects,
    cellKey,
    height,
    leftColId,
    leftHandColumnId,
    rowIndex,
    settings,
    slotData,
    ui,
    viewData,
    visibleItemCount,
    departmentsById,
  } = props;
  const { data, tools, originalPersonnelMap } = viewData;
  const slotsByDate: SlotsByDate = slotData?.[leftColId];
  const slots: Slot[] = slotsByDate ? slotsByDate[cellKey] ?? [] : [];

  const rowClassNames = classnames("row", {
    "row--even": visibleItemCount % 2 === 0,
    "row--odd": visibleItemCount % 2 !== 0,
  });

  const printMatrixIdentifier = `slot-row-member-id-${rowIndex}-${leftHandColumnId}`;

  const slotClassNames = classnames({
    [rowClassNames]: true,
    ["column-print-cell-wrapper"]: true,
    [printMatrixIdentifier]: true,
  });

  return (
    <div
      className={slotClassNames}
      style={{ backgroundColor: cellEffects?.backgroundColor, height }}
      key={`${cellKey}-${rowIndex}-${cellDate}`}
    >
      <ColumnCell
        cellEffects={cellEffects}
        cellType={visibleItemCount % 2 ^ 1}
        data={data}
        date={cellDate}
        leftColId={leftColId}
        scheduled={slots}
        settings={settings}
        tools={tools}
        ui={ui}
        originalPersonnelMap={originalPersonnelMap}
        departmentsById={departmentsById}
        isPrintedCell
      />
    </div>
  );
};

export default PrintColumnSlotItem;
