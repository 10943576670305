const Backbone = require("backbone");
const Change = require("./../models/Change.js");
const ApiConfig = require("_lib/data/ApiConfig.js");

const ChangeCollection = Backbone.Collection.extend({
  model: Change,

  comparator: "timestamp",

  initialize: function (models, attributes) {
    this.attributes = {};

    for (let key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  printHeaders: function () {
    return ["old_slot", "new slot", "timestamp", "c_id", "change_type", "user_empid", "sched_id"];
  },

  url: function () {
    return ApiConfig.getPrefix() + ("/schedule/rollback/" + this.attributes.schedID);
  },
});

module.exports = ChangeCollection;
