import React from "react";

import classnames from "@/_lib/utils/classnames";

import styles from "./styles.module.scss";

interface Props {
  className?: string;
  disabled?: boolean;
  color?: "default" | "primary" | "secondary";
  variant?: "filled" | "outlined";
  fullWidth?: boolean;
  children: React.ReactElement[];
  [key: string]: unknown;
}

const ButtonGroup = ({
  className = "",
  disabled = false,
  fullWidth = false,
  color,
  variant,
  children,
  ...rest
}: Props): React.ReactElement => {
  const rootClassNames = classnames(className, {
    [styles.root]: true,
    [styles.fullWidth]: fullWidth,
    [styles.filled]: variant === "filled",
    [styles.outlined]: variant === "outlined",
  });

  return (
    <div className={rootClassNames} {...rest}>
      {children.map((child) =>
        React.cloneElement(child, {
          color: color || child.props.color,
          variant: variant || child.props.variant,
          disabled: disabled || child.props.disabled,
          fullWidth: fullWidth || child.props.fullWidth,
        })
      )}
    </div>
  );
};

export default ButtonGroup;
