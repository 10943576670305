import { Model } from "backbone";

import { AssignmentTypesById, cleanAssignmentTypeAttributes } from "@/viewer/types/domain/assignmentType";
import connectedPropertyHookFactory from "@/viewer/ui/modules/common/hooks/connectedPropertyHookFactory";

const getAssignmentTypes = (): AssignmentTypesById => {
  const { LbsAppData } = window as any;

  const out: AssignmentTypesById = {};
  const assignmentTypeCollection = LbsAppData.AssignmentTypes;

  if (!assignmentTypeCollection) return out;

  assignmentTypeCollection.forEach((assignmentTypeModel: Model) => {
    const assignmentType = cleanAssignmentTypeAttributes(assignmentTypeModel.attributes);
    out[assignmentType.id] = assignmentType;
  });

  return out;
};

export default connectedPropertyHookFactory({
  loader: getAssignmentTypes,
  getListeners: () => {
    const { LbsAppData } = window as any;
    return [[LbsAppData.AppContext, "fetched"]];
  },
});
