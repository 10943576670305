const Backbone = require("backbone");
var _ = require("underscore");

var ApiConfig = require("_lib/data/ApiConfig.js");

var Assignment = Backbone.Model.extend({
  idAttribute: "assign_structure_id",

  defaults: {
    boundTool: undefined,
    available: true,
    filtered: false,
    //"forced": false
  },

  initialize: function () {
    // attach event listeners to this assignment's template object
    //for (var i = 0; i < this.attributes.templates.length; i++) {
    //	var template = window.LbsAppData.Templates.get(this.attributes.templates[i]);
    //
    //	if (template) {
    //		this.listenTo(template, 'change:available', this.setAvailability);
    //		this.listenTo(template, 'change:filtered', this.setAvailability);
    //	}
    //}

    // display + compact attributes (if it's different)
    if (this.attributes.compact_name != this.attributes.display_name) {
      this.attributes.display_plus_compact = this.attributes.display_name + " [" + this.attributes.compact_name + "]";
    } else {
      this.attributes.display_plus_compact = this.attributes.display_name;
    }

    // default the order to display_name
    this.attributes.order = this.attributes.display_name.toLowerCase();

    // hide expired setting would cause things to possibly become unavailable
    //this.listenTo(window.LbsAppData.AppContext, 'change:GlobalSettings_hideExpired', this.setAvailability);

    // link to the demand dictionary
    this.demand = window.LbsAppData.AssignmentDemand.get(this.id);

    // link to the personnel roles
    this.roles = window.LbsAppData.Roles.get(this.id.split(window.LbsAppData.CONDENSED_DELIMITER)[0]);

    // link to the system defined tally
    this.tally = window.LbsAppData.Tallies.getTallyWithAssignmentId(
      parseInt(this.id.split(window.LbsAppData.CONDENSED_DELIMITER)[0], 10)
    );

    // component map (for realtime updates that don't require total redraws)
    this._components = {};
  },

  _checkAvailability: function (eventObj) {
    // if assignment is expired AND user is set to hide expired, then they are unavailable
    //if (this.attributes.expired && window.LbsAppData.AppContext.attributes.GlobalSettings_hideExpired){
    //	return false;
    //}

    // check assigment's templates to see if any are unfiltered (and available?)
    //for (var i = 0; i < this.attributes.templates.length; i++) {
    //	var template = window.LbsAppData.Templates.get(this.attributes.templates[i]);
    //	if (template !== undefined && template.attributes.available && !template.attributes.filtered) {
    //		// found one
    //		return true;
    //	}
    //}

    return true;
  },

  getDisplayText: function () {
    if (window.LbsAppData.AppContext.attributes.compactMode != "yes") {
      // non compact mode
      return this.attributes.display_name;
    } else {
      // compact mode
      return this.attributes.display_plus_compact;
    }
  },

  getEffects: function () {
    if (this.attributes.boundTool) {
      // tool takes precedence
      return this.attributes.boundTool.style();
    } else if (window.LbsAppData.AppContext.attributes.view) {
      var ret = {};

      var colorMethod = window.LbsAppData.AppContext.attributes.view.attributes.theme.data.colorMethod;
      var colorTextMethod = window.LbsAppData.AppContext.attributes.view.attributes.theme.data.colorTextMethod;

      if (colorMethod == "mixed" || colorMethod == "row") {
        // view is set to color rows
        if (this.attributes.color) {
          ret["backgroundColor"] = this.attributes.color;
        }
      }

      if (colorTextMethod == "colored") {
        // view is set to color text
        if (this.attributes.colorText) {
          ret["color"] = this.attributes.colorText;
        }
      }

      return ret;
    } else {
      // nothing
      return {};
    }
  },

  getDemandTotal: function (date) {
    // check if there is demand for the given date
    if (!this.demand) {
      return 0;
    }

    var demandTotalMin = 0;
    var demandTotalMax = 0;
    _.each(this.demand.attributes.structure_demand, function (struct_demand, key) {
      var demand = struct_demand[date];

      if (demand) {
        demandTotalMin += demand.min;
        demandTotalMax += demand.max;
      }
    });

    return { min: demandTotalMin, max: demandTotalMax };
  },

  checkDemand: function (date, alreadyScheduled) {
    // check if there is demand for the given date
    if (!this.demand) {
      return 0;
    }

    var demandTotalMin = 0;
    var demandTotalMax = 0;
    _.each(this.demand.attributes.structure_demand, function (struct_demand, key) {
      var demand = struct_demand[date];

      if (demand) {
        demandTotalMin += demand.min;
        demandTotalMax += demand.max;
      }
    });

    return demandTotalMax - alreadyScheduled;
  },
  checkDemandForSpecificId: function (structureId, date, alreadyScheduled) {
    // check if there is demand for the given date
    if (!this.demand) {
      return 0;
    }

    var demandTotalMin = 0;
    var demandTotalMax = 0;
    _.each(this.demand.attributes.structure_demand, function (struct_demand, key) {
      if (key == structureId) {
        var demand = struct_demand[date];

        if (demand) {
          demandTotalMin += demand.min;
          demandTotalMax += demand.max;
        }
      }
    });

    return demandTotalMax - alreadyScheduled;
  },

  addDemand: function (date, structureID) {
    // just add any structures for now... not sure if this is the right way to
    // handle this..UNLESS a structureID argument is passed in...in which cause add it to that structure only

    if (structureID) {
      var demandElement = this.demand.attributes.structure_demand[structureID.toString()];
      if (!demandElement || !demandElement[date]) {
        // no demand for the structure or date (yet) at all
        this.demand.attributes.structure_demand[structureID.toString()][date] = { min: 0, max: 0 };
      }

      this.demand.attributes.structure_demand[structureID.toString()][date].max += 1;
    } else {
      _.each(this.demand.attributes.structure_demand, function (struct_demand, key) {
        if (!struct_demand[date]) {
          struct_demand[date] = { min: 0, max: 0 };
        }

        struct_demand[date].max += 1;
      });
    }
  },

  getSchedulableStructures: function (date) {
    // check if there is demand for the given date
    if (!this.demand) {
      return [];
    }

    var structures = [];
    _.each(this.demand.attributes.structure_demand, function (struct_demand, key) {
      var demand = struct_demand[date];

      if (demand) {
        structures.push(parseInt(key));
      }
    });

    return structures;
  },

  getStructureDesc: function (structureID) {
    return this.attributes.structure_to_desc_map[structureID];
  },

  //	setAvailability: function(eventObj) {
  //		var availability = this._checkAvailability(eventObj);
  //		if (availability != this.attributes.available) {
  //			this.set({'available': availability});
  //		}
  //	},
  //
  //	_checkAvailability: function(eventObj) {
  //		// if assignment is expired AND user is set to hide expired, then they are unavailable
  //		if (this.attributes.expired && window.LbsAppData.AppContext.attributes.GlobalSettings_hideExpired){
  //			return false;
  //		}
  //
  //		// check assigment's templates to see if any are unfiltered (and available?)
  //		for (var i = 0; i < this.attributes.templates.length; i++) {
  //			var template = window.LbsAppData.Templates.get(this.attributes.templates[i]);
  //			if (template !== undefined && template.attributes.available && !template.attributes.filtered) {
  //				// found one
  //				return true;
  //			}
  //		}
  //
  //		return false;
  //	},
  //
  //	// following functions exist because we condense the assignment index (to make it view 'nicer')
  //	// that means we need additional data lookups to tell what assignment we are actually dealing with
  //	_isAssignmentOnTemplate: function(template_id){
  //		return this.attributes.templates.indexOf(template_id) > -1;
  //	},
  //
  //	_isAssignmentRequestableOnTemplate: function(template_id){
  //		return this.attributes.template_to_assign_requestable_map[template_id];
  //	},
  //
  //	_getAssignmentIDForTemplate: function(template_id){
  //		return this.attributes.template_to_assign_id_map[template_id];
  //	},
  //
  //	_getAssignmentDefaultStartTime: function(template_id){
  //		var returnVal = undefined;
  //		if (this.attributes.template_to_default_times_map[template_id] !== undefined){
  //			if (this.attributes.template_to_default_times_map[template_id].split('~').length > 1){
  //				returnVal = this.attributes.template_to_default_times_map[template_id].split('~')[0];
  //			}
  //		}
  //		return returnVal;
  //	},
  //
  //	_getAssignmentDefaultStopTime: function(template_id){
  //		var returnVal = undefined;
  //		if (this.attributes.template_to_default_times_map[template_id] !== undefined){
  //			if (this.attributes.template_to_default_times_map[template_id].split('~').length > 1){
  //				returnVal = this.attributes.template_to_default_times_map[template_id].split('~')[1];
  //			}
  //		}
  //		return returnVal;
  //	},

  url: function () {
    return ApiConfig.getPrefix() + (this.id ? "/assignments" + this.id : "/assignments");
  },
});

module.exports = Assignment;
