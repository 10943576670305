"use strict";

// scss requires
require("./_styles.scss");

// dependencies
const React = require("react/addons");

// components
const TimePicker = require("_lib/ui/modules/widgets/timepicker/TimePicker.jsx");
let TimeHelper;

const TimeRangePicker = React.createClass({
  getInitialState: function () {
    TimeHelper = window.LbsAppData.Helpers.Time;

    const max = TimeHelper.millisecondsToMinutes(this.props.endDate - this.props.startDate);

    const leftDefaultValue = TimeHelper.addMinutesToDate(max * 0.25, this.props.startDate);
    const rightDefaultValue = TimeHelper.addMinutesToDate(max * 0.75, this.props.startDate);
    const singleDefaultValue = TimeHelper.addMinutesToDate(max * 0.5, this.props.startDate);

    return {
      leftSplitDate: this.props.leftSplitDate || leftDefaultValue,
      rightSplitDate: this.props.rightSplitDate || rightDefaultValue,
      singleSplitDate: this.props.singleSplitDate || singleDefaultValue,

      isLeftSplitDateValid: true,
      isRightSplitDateValid: true,
      isSingleSplitDateValid: true,
    };
  },

  componentDidUpdate: function (prevProps) {
    if (this.props.leftSplitDate !== prevProps.leftSplitDate) {
      this.setState({
        leftSplitDate: this.props.leftSplitDate,
        isLeftSplitDateValid: true,
      });
    }

    if (this.props.rightSplitDate !== prevProps.rightSplitDate) {
      this.setState({
        rightSplitDate: this.props.rightSplitDate,
        isRightSplitDateValid: true,
      });
    }

    if (this.props.singleSplitDate !== prevProps.singleSplitDate) {
      this.setState({
        singleSplitDate: this.props.singleSplitDate,
        isSingleSplitDateValid: true,
      });
    }
  },

  onTimeRangeUpdated: function (updatedDates) {
    let callbackFunc = this.props.onChange;

    // If: we were passed an updated value we will return that one
    // Else If: we were not passed a date and our state value is valid, we will return the state value
    // Else: we return our prop value
    if (typeof callbackFunc === "function") {
      callbackFunc({
        leftSplitDate:
          updatedDates.leftDate ||
          (this.state.isLeftSplitDateValid ? this.state.leftSplitDate : this.props.leftSplitDate),
        rightSplitDate:
          updatedDates.rightDate ||
          (this.state.isRightSplitDateValid ? this.state.rightSplitDate : this.props.rightSplitDate),
        singleSplitDate:
          updatedDates.singleDate ||
          (this.state.isSingleSplitDateValid ? this.state.singleSplitDate : this.props.singleSplitDate),
      });
    }
  },

  updateLeftSplitValue: function (newTime) {
    let dayOfStartDateWithNewTime = new Date(this.props.startDate);
    dayOfStartDateWithNewTime.setHours(newTime.hours, newTime.minutes);

    let dayOfEndDateWithNewTime = new Date(this.props.endDate);
    dayOfEndDateWithNewTime.setHours(newTime.hours, newTime.minutes);

    let validDate;
    if (
      (this._isDateBetweenStartAndEndDates(dayOfStartDateWithNewTime) ||
        this._isValidInclusiveOfStartOrEndDate(dayOfStartDateWithNewTime, this.state.rightSplitDate)) &&
      dayOfStartDateWithNewTime < this.state.rightSplitDate
    ) {
      validDate = dayOfStartDateWithNewTime;
    } else if (
      (this._isDateBetweenStartAndEndDates(dayOfEndDateWithNewTime) ||
        this._isValidInclusiveOfStartOrEndDate(dayOfEndDateWithNewTime, this.state.rightSplitDate)) &&
      dayOfEndDateWithNewTime < this.state.rightSplitDate
    ) {
      validDate = dayOfEndDateWithNewTime;
    } else {
      this.setState({
        leftSplitDate: dayOfStartDateWithNewTime,
        isLeftSplitDateValid: false,
      });
      return;
    }

    this.setState({
      leftSplitDate: validDate,
      isLeftSplitDateValid: true,
    });
    this.onTimeRangeUpdated({ leftDate: validDate });
  },

  updateRightSplitValue: function (newTime) {
    let dayOfStartDateWithNewTime = new Date(this.props.startDate);
    dayOfStartDateWithNewTime.setHours(newTime.hours, newTime.minutes);

    let dayOfEndDateWithNewTime = new Date(this.props.endDate);
    dayOfEndDateWithNewTime.setHours(newTime.hours, newTime.minutes);

    let validDate;
    if (
      (this._isDateBetweenStartAndEndDates(dayOfStartDateWithNewTime) ||
        this._isValidInclusiveOfStartOrEndDate(this.state.leftSplitDate, dayOfStartDateWithNewTime)) &&
      dayOfStartDateWithNewTime > this.state.leftSplitDate
    ) {
      validDate = dayOfStartDateWithNewTime;
    } else if (
      (this._isDateBetweenStartAndEndDates(dayOfEndDateWithNewTime) ||
        this._isValidInclusiveOfStartOrEndDate(this.state.leftSplitDate, dayOfEndDateWithNewTime)) &&
      dayOfEndDateWithNewTime > this.state.leftSplitDate
    ) {
      validDate = dayOfEndDateWithNewTime;
    } else {
      this.setState({
        rightSplitDate: dayOfEndDateWithNewTime,
        isRightSplitDateValid: false,
      });
      return;
    }

    this.setState({
      rightSplitDate: validDate,
      isRightSplitDateValid: true,
    });
    this.onTimeRangeUpdated({ rightDate: validDate });
  },

  updateSingleSplitValue: function (newTime) {
    let dayOfStartDateWithNewTime = new Date(this.props.startDate);
    dayOfStartDateWithNewTime.setHours(newTime.hours, newTime.minutes);

    let dayOfEndDateWithNewTime = new Date(this.props.endDate);
    dayOfEndDateWithNewTime.setHours(newTime.hours, newTime.minutes);

    let validDate;
    if (this._isDateBetweenStartAndEndDates(dayOfStartDateWithNewTime)) {
      validDate = dayOfStartDateWithNewTime;
    } else if (this._isDateBetweenStartAndEndDates(dayOfEndDateWithNewTime)) {
      validDate = dayOfEndDateWithNewTime;
    } else {
      this.setState({
        singleSplitDate: dayOfStartDateWithNewTime,
        isSingleSplitDateValid: false,
      });
      return;
    }

    this.setState({
      singleSplitDate: validDate,
      isSingleSplitDateValid: true,
    });
    this.onTimeRangeUpdated({ singleDate: validDate });
  },

  _isDateBetweenStartAndEndDates: function (date) {
    return date > this.props.startDate && date < this.props.endDate;
  },

  _isValidInclusiveOfStartOrEndDate: function (leftDate, rightDate) {
    if (!this.props.canIncludeStartOrEndDates) {
      return false;
    }

    return (
      (leftDate.getTime() === this.props.startDate.getTime() && rightDate < this.props.endDate) ||
      (rightDate.getTime() === this.props.endDate.getTime() && leftDate > this.props.startDate)
    );
  },

  _getTimePickers: function () {
    const errorMessage = (
      <div
        className="error-message"
        style={{
          display:
            (this.props.isSingleSplit && this.state.isSingleSplitDateValid) ||
            (!this.props.isSingleSplit && this.state.isLeftSplitDateValid && this.state.isRightSplitDateValid)
              ? "none"
              : "block",
        }}
      >
        Please enter a valid time. The time entered is not within the correct range.
      </div>
    );

    if (this.props.isSingleSplit) {
      return (
        <span>
          <TimePicker
            ref="SingleSplitTimePicker"
            defaultTimeStr={window.LbsAppData.Helpers.Time.formatTimeAsMilitary(this.state.singleSplitDate)}
            bigMode={false}
            focusedCounter={false}
            spinnerCounter={undefined}
            isStartPicker={true}
            unfocusKeydown={function () {}}
            refocusKeydown={function () {}}
            submitButtonToggle={function () {}}
            onChange={this.updateSingleSplitValue}
          />
          {errorMessage}
        </span>
      );
    } else {
      return (
        <div>
          <span>
            <TimePicker
              ref="StartTimePicker"
              defaultTimeStr={window.LbsAppData.Helpers.Time.formatTimeAsMilitary(this.state.leftSplitDate)}
              bigMode={false}
              focusedCounter={false}
              spinnerCounter={undefined}
              isStartPicker={true}
              unfocusKeydown={function () {}}
              refocusKeydown={function () {}}
              submitButtonToggle={function () {}}
              onChange={this.updateLeftSplitValue}
            />
          </span>
          <span>
            <TimePicker
              ref="StopTimePicker"
              defaultTimeStr={window.LbsAppData.Helpers.Time.formatTimeAsMilitary(this.state.rightSplitDate)}
              bigMode={false}
              focusedCounter={false}
              spinnerCounter={undefined}
              isStartPicker={false}
              unfocusKeydown={function () {}}
              refocusKeydown={function () {}}
              submitButtonToggle={function () {}}
              onChange={this.updateRightSplitValue}
            />
          </span>
          {errorMessage}
        </div>
      );
    }
  },

  render: function () {
    const classes = React.addons.classSet({
      TimeRangePicker: true,
    });

    return <div className={classes}>{this._getTimePickers()}</div>;
  },
});

module.exports = TimeRangePicker;
