const Backbone = require("backbone");
const moment = require("moment");
const ApiConfig = require("_lib/data/ApiConfig.js");

const Schedule = Backbone.Model.extend({
  idAttribute: "schedule_id",

  defaults: {
    locale_start_date: undefined,
    locale_stop_date: undefined,
    locale_last_modified_date: undefined,
  },

  //TODO Lift format strings out and replace with constants in dates.ts constants
  initialize: function () {
    this.attributes.locale_start_date = moment(this.attributes.start_date, "YYYY-MM-DD").format("YYYY-L");
    this.attributes.locale_stop_date = moment(this.attributes.stop_date, "YYYY-MM-DD").format("YYYY-L");

    const lastModMoment = moment(this.attributes.last_modified_date);
    const lastModDate = lastModMoment.format("YYYY-L").replace("/", "-");
    // just always show military for this
    const lastModTime = lastModMoment.format("hh:mm:ss");

    // purge the '/' and '-' difference that exists between locales, for this field we want '-' always
    this.attributes.locale_start_date = this.attributes.locale_start_date.replace("/", "-");
    this.attributes.locale_stop_date = this.attributes.locale_stop_date.replace("/", "-");
    this.attributes.locale_last_modified_date = lastModDate + " " + lastModTime;
  },

  url: function () {
    return ApiConfig.getPrefix() + (this.id ? "/schedule/data/" + this.id : "/schedule/data");
  },
});

module.exports = Schedule;
