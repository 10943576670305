import React, { useRef, useState } from "react";
import myWindow from "@/_lib/utils/window";
import { get, del, put, post } from "@/_lib/utils/fetch";
import useOnClickOutside from "@/_lib/utils/useOnClickOutside";
import { PersonalFilter } from "../types";
import * as errorReporter from "@/_lib/utils/errorReporter";
import DeletePrompt from "../../../../../components/ConfirmPrompt";
import HelpIcon from "../../../../../components/HelpIcon";
import { Personnel } from "@/viewer/types/domain/personnel";
import { Assignment } from "@/viewer/types/domain/assignment";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ApiConfig = require("_lib/data/ApiConfig.js");

interface Props {
  personnelFilters: Personnel["empId"][];
  selectedPersonnelFilters: Personnel["empId"][];
  assignmentFilters: Assignment["assignStructureId"][];
  selectedAssignmentFilters: Assignment["assignStructureId"][];
  filterByAssignments: (filters: Assignment["assignStructureId"][]) => void;
  filterByPersonnel: (filters: Personnel["empId"][]) => void;
}

const PersonalFilters: React.FunctionComponent<Props> = ({
  personnelFilters,
  selectedPersonnelFilters,
  assignmentFilters,
  selectedAssignmentFilters,
  filterByAssignments,
  filterByPersonnel,
}: Props) => {
  const dropDownMenu = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [filters, setFilters] = useState<PersonalFilter[]>();
  const [selectedFilter, setSelectedFilter] = useState<PersonalFilter | null>(null);
  const [saveAsName, setSaveAsName] = useState<string>("");
  const [canSaveCurrentSelectedFilter, setCanSaveCurrentSelectedFilter] = useState<boolean>(false);
  const [renamingFilterId, setRenamingFilterId] = useState<number | null>(null);
  const [renamingFilterName, setRenamingFilterName] = useState<string>("");
  const [confirmDelete, setConfirmDelete] = useState<PersonalFilter | null>(null);
  const maximumFilters = 10;

  useOnClickOutside(dropDownMenu, () => setShowMenu(false));

  const getExcludedPersonnelList = () => {
    if (selectedPersonnelFilters && selectedPersonnelFilters.length > 0) {
      return personnelFilters.filter((f) => selectedPersonnelFilters.indexOf(f) < 0);
    }

    return [];
  };

  const getExcludedAssignmentList = () => {
    if (selectedAssignmentFilters && selectedAssignmentFilters.length > 0) {
      return assignmentFilters.filter((f) => selectedAssignmentFilters.indexOf(f) < 0);
    }

    return [];
  };

  const getPersonalFilters = async () => {
    const url =
      ApiConfig.getPrefix() + `/views/${myWindow.LbsAppData.AppContext.attributes.view.view_id}/personalfilter`;
    const resp = await get<PersonalFilter[]>(url);

    if (resp.data) {
      setFilters(resp.data.sort((a, b) => a.Name.localeCompare(b.Name)));
      if (selectedFilter) {
        const filter = resp.data.find((x) => x.PersonalViewFilterId === selectedFilter.PersonalViewFilterId);
        if (filter) setSelectedFilter(filter);
      }
    }

    setConfirmDelete(null);
    setLoading(false);
  };

  const toggleMenu = async () => {
    setShowMenu(!showMenu);

    setRenamingFilterId(null);
    setRenamingFilterName("");

    if (!filters) {
      setLoading(true);
      await getPersonalFilters();
    }

    if (selectedFilter && !showMenu) {
      setCanSaveCurrentSelectedFilter(() => {
        return (
          selectedFilter.PersonnelToExclude.filter((x) => !getExcludedPersonnelList().includes(x)).concat(
            getExcludedPersonnelList().filter((x) => !selectedFilter.PersonnelToExclude.includes(x))
          ).length > 0 ||
          selectedFilter.AssignmentsToExclude.filter((x) => !getExcludedAssignmentList().includes(x)).concat(
            getExcludedAssignmentList().filter((x) => !selectedFilter.AssignmentsToExclude.includes(x))
          ).length > 0
        );
      });
    }
  };

  const selectPersonalFilter = (filter: PersonalFilter) => {
    setSelectedFilter(filter);

    if (filter.PersonnelToExclude && filter.PersonnelToExclude.length > 0) {
      filterByPersonnel(personnelFilters.filter((f) => !filter.PersonnelToExclude.includes(f)));
    } else {
      filterByPersonnel([]);
    }

    if (filter.AssignmentsToExclude && filter.AssignmentsToExclude.length > 0) {
      filterByAssignments(assignmentFilters.filter((f) => !filter.AssignmentsToExclude.includes(f)));
    } else {
      filterByAssignments([]);
    }

    toggleMenu();
  };

  const saveCurrentFilter = async () => {
    if (!selectedFilter) return;

    const body: PersonalFilter = {
      ...selectedFilter,
      AssignmentsToExclude: selectedAssignmentFilters,
      PersonnelToExclude: selectedPersonnelFilters,
    };

    const url =
      ApiConfig.getPrefix() + `/views/${myWindow.LbsAppData.AppContext.attributes.view.view_id}/personalfilter`;

    try {
      const resp = await put<PersonalFilter>(url, body);
      if (resp.data) {
        await getPersonalFilters();
        setCanSaveCurrentSelectedFilter(false);
      }
    } catch (e) {
      errorReporter.reportError(e as ErrorEvent, window.location.href, "Personal Filters - Save");
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  };

  const renameFilter = async (filter: PersonalFilter) => {
    if (!filter || !renamingFilterName) return;

    const body: PersonalFilter = {
      ...filter,
      Name: renamingFilterName.trim(),
    };

    const url =
      ApiConfig.getPrefix() + `/views/${myWindow.LbsAppData.AppContext.attributes.view.view_id}/personalfilter`;

    try {
      const resp = await put<PersonalFilter>(url, body);

      if (resp.data) {
        await getPersonalFilters();
        setRenamingFilterId(null);
        setRenamingFilterName("");
      }
    } catch (e) {
      errorReporter.reportError(e as ErrorEvent, window.location.href, "Personal Filters - Rename");
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  };

  const saveAsCurrentFilter = async () => {
    if (!saveAsName) return;

    const body: PersonalFilter = {
      Name: saveAsName.trim(),
      PersonalViewFilterId: 0,
      ViewId: myWindow.LbsAppData.AppContext.attributes.view.view_id,
      UserId: myWindow.LbsAppData.User.attributes.user_id,
      PersonnelToExclude: selectedPersonnelFilters,
      AssignmentsToExclude: selectedAssignmentFilters,
    };

    const url =
      ApiConfig.getPrefix() + `/views/${myWindow.LbsAppData.AppContext.attributes.view.view_id}/personalfilter`;

    try {
      const resp = await post<PersonalFilter>(url, body);
      if (resp.data) {
        setFilters(filters?.concat(resp.data).sort((a, b) => a.Name.localeCompare(b.Name)));
        setSelectedFilter(resp.data);
        setCanSaveCurrentSelectedFilter(false);
        setSaveAsName("");
      }
    } catch (e) {
      errorReporter.reportError(e as ErrorEvent, window.location.href, "Personal Filters - Save As Current");
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  };

  const deleteFilter = async () => {
    if (!confirmDelete) return;

    const url =
      ApiConfig.getPrefix() +
      `/views/${myWindow.LbsAppData.AppContext.attributes.view.view_id}/personalfilter/${confirmDelete?.PersonalViewFilterId}`;

    try {
      const resp = await del(url);

      if (resp) {
        await getPersonalFilters();
      }
    } catch (e) {
      errorReporter.reportError(e as ErrorEvent, window.location.href, "Personal Filters - Delete");
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  };

  const setRenamingFilter = (filter: PersonalFilter) => {
    setRenamingFilterId(filter.PersonalViewFilterId);
    setRenamingFilterName(filter.Name);
  };

  const clearFilters = () => {
    setSelectedFilter(null);
    filterByPersonnel([]);
    filterByAssignments([]);
    toggleMenu();
  };

  const isNameUnique = (name: string, filter?: PersonalFilter) => {
    if (filter) {
      return !filters?.find(
        (x) => x.Name === name.trim() && filter && x.PersonalViewFilterId != filter.PersonalViewFilterId
      );
    }
    return !filters?.find((x) => x.Name === name.trim());
  };

  const uniqueErrorDisplay = () => {
    return <div className="field-error mt-5 ml-5">The filter name is already in use.</div>;
  };

  return (
    <div className="top-bar-btn dropdown" ref={dropDownMenu}>
      <div
        onClick={() => toggleMenu()}
        onTouchEnd={() => toggleMenu()}
        className={"ribbon-text no-mobile limit-width-large max-width" + (showMenu ? " selected" : "")}
      >
        {selectedFilter ? selectedFilter.Name : `Saved Filters`}
      </div>
      <div className="icons-mobile" onClick={() => toggleMenu()} onTouchEnd={() => toggleMenu()}>
        <i className={"fa fa-save"}></i>
      </div>
      <div className={"menu " + (showMenu ? "open right" : "")}>
        <div className="flex-1">
          {loading && (
            <div className="flex-display-justify-center mt-10 mb-10">
              <i className="fa fa-fw fa-spinner fa-spin" />
              <span className="ml-10">Loading saved filters</span>
            </div>
          )}

          {confirmDelete && (
            <DeletePrompt
              text={`Are you sure you want to delete "${confirmDelete.Name}" filter?`}
              cancel={() => setConfirmDelete(null)}
              confirm={deleteFilter}
            />
          )}

          {!loading && filters && (
            <>
              <div className="flex-between">
                <div className="bold">Current Saved Filters</div>
                <div>
                  <HelpIcon
                    position="left"
                    text={`You can save Personnel and Assignment filter selections for later use. You can save up to ${maximumFilters.toString()} custom filters.`}
                  />
                </div>
              </div>

              <div className="divider"></div>

              {filters.length === 0 && (
                <>
                  <div className="flex-display-justify-center mt-20 mb-20">
                    You currently have no Saved Filters for this view.
                  </div>
                </>
              )}

              {filters.map((filter: PersonalFilter, idx: number) => {
                return (
                  <div
                    key={idx}
                    className={
                      `pointer listitem flex-between action-icon-container` +
                      (selectedFilter?.PersonalViewFilterId === filter.PersonalViewFilterId ? " selected-row" : "")
                    }
                  >
                    {!renamingFilterId || renamingFilterId != filter.PersonalViewFilterId ? (
                      <div className="full-width">
                        <div className="flex-between">
                          <div
                            className="flex-column ellipsis"
                            onClick={(_e) => selectPersonalFilter(filter)}
                            onTouchEnd={(_e) => selectPersonalFilter(filter)}
                          >
                            {filter.Name}
                          </div>
                          <div>
                            <span
                              className="action-link ml-10"
                              onClick={() => setRenamingFilter(filter)}
                              onTouchEnd={() => setRenamingFilter(filter)}
                            >
                              Rename
                            </span>
                            <span
                              className="action-link ml-10"
                              onClick={() => {
                                setConfirmDelete(filter);
                              }}
                              onTouchEnd={() => {
                                setConfirmDelete(filter);
                              }}
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                        {canSaveCurrentSelectedFilter &&
                          selectedFilter &&
                          selectedFilter?.PersonalViewFilterId === filter.PersonalViewFilterId && (
                            <div className="bold mt-10">
                              <span className="mr-5">The current filter has been edited.</span>
                              <span
                                className="action-link bold"
                                onClick={saveCurrentFilter}
                                onTouchEnd={saveCurrentFilter}
                              >
                                Save Updated Filter
                              </span>
                            </div>
                          )}
                      </div>
                    ) : (
                      <div className="full-width">
                        <div className="flex-display-center">
                          <div className="flex-column">
                            <input
                              type="text"
                              className="btn-input"
                              maxLength={255}
                              value={renamingFilterName}
                              onChange={(e) => setRenamingFilterName(e.target.value)}
                              onKeyDown={(e) =>
                                e.key === "Enter" &&
                                isNameUnique(renamingFilterName, filter) &&
                                renamingFilterName.trim() &&
                                renameFilter(filter)
                              }
                            />
                          </div>
                          <div>
                            <span
                              className={
                                "ml-20 action-link" + (!isNameUnique(renamingFilterName, filter) ? " disabled" : "")
                              }
                              onClick={() =>
                                isNameUnique(renamingFilterName, filter) &&
                                renamingFilterName.trim() &&
                                renameFilter(filter)
                              }
                              onTouchEnd={() =>
                                isNameUnique(renamingFilterName, filter) &&
                                renamingFilterName.trim() &&
                                renameFilter(filter)
                              }
                            >
                              Save Rename
                            </span>
                            <span
                              className="action-link ml-10"
                              onClick={() => setRenamingFilterId(null)}
                              onTouchEnd={() => setRenamingFilterId(null)}
                            >
                              Cancel
                            </span>
                          </div>
                        </div>
                        {!isNameUnique(renamingFilterName, filter) && uniqueErrorDisplay()}
                      </div>
                    )}
                  </div>
                );
              })}

              {filters.length < maximumFilters &&
                (selectedPersonnelFilters.length > 0 || selectedAssignmentFilters.length > 0) && (
                  <>
                    <div className="divider" />

                    <div>Save current filter set as a new filter.</div>
                    <div className="flex-display-center mt-10">
                      <div className="flex-column">
                        <input
                          type="text"
                          className="btn-input flex-column"
                          placeholder="Filter Name"
                          value={saveAsName}
                          onChange={(e) => setSaveAsName(e.target.value)}
                          maxLength={255}
                          onKeyDown={(e) => e.key === "Enter" && saveAsCurrentFilter()}
                        />
                      </div>

                      <button
                        className="btn ml-10"
                        disabled={!saveAsName.trim() || !isNameUnique(saveAsName)}
                        onClick={() => saveAsCurrentFilter()}
                        onTouchEnd={() => saveAsCurrentFilter()}
                      >
                        Save As
                      </button>
                    </div>
                    {!isNameUnique(saveAsName) && uniqueErrorDisplay()}
                  </>
                )}

              {filters.length < maximumFilters &&
                selectedPersonnelFilters.length === 0 &&
                selectedAssignmentFilters.length === 0 && (
                  <>
                    <div className="divider" />
                    <div className="flex-display-justify-center">
                      <div>You currently have no filters selected.</div>
                    </div>
                  </>
                )}

              {selectedFilter && (
                <>
                  <div className="divider" />
                  <button className="btn" onClick={clearFilters} onTouchEnd={clearFilters} disabled={!selectedFilter}>
                    Clear All Filters
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalFilters;
