import React from "react";

const EngageCookie = (): null => {
  React.useEffect(() => {
    const updateCookie = (cName: string, cValue: string) => {
      const expireDate =
        document.cookie.indexOf(cName) === -1
          ? new Date(new Date().setTime(new Date().getTime() + 30 * 24 * 3600 * 1000))
          : unescape(document.cookie).split("expireDate=")[1];
      const domain = "lightning-bolt.com";
      const path = "/";

      document.cookie =
        cName +
        "=" +
        cValue +
        ";expireDate=" +
        expireDate +
        ";expires=" +
        expireDate +
        ";domain=" +
        domain +
        ";path=" +
        path +
        ";";
    };
    updateCookie("l_ua", "true");
  }, []);

  return null;
};

export default EngageCookie;
