// Since getDisplayElementAndCls returns a tuple, this lint settings complains unduly
/* eslint-disable react/jsx-key */
import * as React from "react";
import { getSlotDisplayObject } from "@/viewer/utils/domain/slotquests";
import { SlotDisplayObject, SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { DisplayDepartmentNames, SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { formatLocalTime, formatUSDateNoYear } from "@/viewer/utils/dateFormatters";
import { startOfDay } from "date-fns";
import { OriginalPersonnelMap } from "@/viewer/types/viewdata";
import classnames from "@/_lib/utils/classnames";
import { DepartmentsById } from "@/viewer/types/domain/department";

interface Props {
  slot: SlotOrRequest;
  settings: SettingsContext;
  ui: UIContext;
  originalPersonnelMap: OriginalPersonnelMap;
  departmentsById: DepartmentsById;
  leftColId?: string;
  disableOverflowDateString?: boolean;
}

export const getDisplayElementAndCls = (
  settings: SettingsContext,
  slotDisplayObject: SlotDisplayObject
): [string, React.ReactNode] => {
  const display_text = slotDisplayObject.displayText;

  if (settings.layout === "block") {
    return ["", slotDisplayObject.displayText];
  }

  if (slotDisplayObject.is_pending_fill) {
    return [
      "pending-add",
      <span>
        <i className="fa fa-fw fa-plus" /> {display_text}
      </span>,
    ];
  } else if (slotDisplayObject.is_pending_delete) {
    return [
      "pending-del",
      <span>
        <i className="fa fa-fw fa-minus" /> {display_text}
      </span>,
    ];
  } else if (slotDisplayObject.is_pending_replace) {
    // need to do some creative splitting to get the arrow between the names
    const insertionIndex = display_text.indexOf("[");
    return [
      "pending-chg",
      <span>
        {display_text.slice(0, insertionIndex - 1)} <i className="fa fa-arrow-right" />{" "}
        {display_text.slice(insertionIndex)}
      </span>,
    ];
  } else if (slotDisplayObject.is_pending_details) {
    return [
      "pending-det",
      <span>
        <i className="fa fa-fw fa-question" /> {display_text}
      </span>,
    ];
  } else if (slotDisplayObject.is_swop) {
    return [
      "pending-chg",
      <span>
        {display_text} <i className="fa fa-fw fa-rss" />
      </span>,
    ];
  } else {
    return ["", slotDisplayObject.displayText];
  }
};

const getOverflowDateStrings = (settings: SettingsContext, slot: SlotOrRequest) => {
  const stopDateTime = startOfDay(slot.stopTime);

  if (slot.date < stopDateTime) {
    return ` (${formatUSDateNoYear(settings, stopDateTime)})`;
  }

  return "";
};

const SlotDisplay = (props: Props): JSX.Element => {
  const { slot, settings, ui, leftColId, disableOverflowDateString, originalPersonnelMap, departmentsById } = props;

  const getOriginalPersonnel = (): string | null => {
    const originalEmpId = slot?.originalEmpId ? (slot.originalEmpId as number) : null;

    if (originalEmpId) {
      const { compactOrDisplayName } = originalPersonnelMap?.get(originalEmpId) || {
        compactOrDisplayName: undefined,
      };

      return compactOrDisplayName ? "[" + compactOrDisplayName + "]" : "-";
    }

    return null;
  };

  const slotDisplayObj = getSlotDisplayObject(settings, ui, slot, leftColId);
  const [pendingClass, displayElement] = getDisplayElementAndCls(settings, slotDisplayObj);

  let timeStringSpan: React.ReactNode = null;

  if (ui.showTimes) {
    timeStringSpan = (
      <span className="times">
        {formatLocalTime(settings, slot.startTime)} &ndash; {formatLocalTime(settings, slot.stopTime)}
        {!disableOverflowDateString && getOverflowDateStrings(settings, slot)}
      </span>
    );
  }

  let personnelSpan: React.ReactNode = displayElement;

  if (ui.showOriginalPersonnel) {
    const originalPersonnel: string | null = getOriginalPersonnel();
    const originalPersonnelClasses = classnames({
      "original-personnel": !!originalPersonnel,
    });
    const slotPersonnelContainerClasses = classnames({
      "slot-personnel-container": !!originalPersonnel,
    });

    const originalPersonnelContainerClasses = classnames({
      "original-personnel-container": !!originalPersonnel,
    });

    personnelSpan = (
      <span className={slotPersonnelContainerClasses}>
        <span className={originalPersonnelContainerClasses}>
          {displayElement}
          <span className={originalPersonnelClasses}>{originalPersonnel}</span>
        </span>
      </span>
    );
  }

  const getDepartmentNameSpan = (): React.ReactNode | null => {
    switch (ui.displayDepartmentNames) {
      case DisplayDepartmentNames.DEPARTMENT_NAME:
        return (
          <span className="department-name">
            {(departmentsById && departmentsById[Number(slot?.departmentId)]?.name) || "-"}
          </span>
        );

      case DisplayDepartmentNames.DEPARTMENT_COMPACT_NAME:
        return (
          <span className="department-name">
            {(departmentsById && departmentsById[Number(slot?.departmentId)]?.compactName) || "-"}
          </span>
        );

      default:
        return null;
    }
  };

  return (
    <span data-cy="DataCellTextValue" className={pendingClass === "" ? "text" : "text " + pendingClass}>
      {personnelSpan}
      {getDepartmentNameSpan()}
      {settings.layout !== "calendar" ? timeStringSpan : null}
    </span>
  );
};

export default SlotDisplay;
