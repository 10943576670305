// Like ClickableHeaderMixin but not a mixin.
import MoveDialog from "@/_lib/ui/modules/dialog/types/schedule_edits/swaps/move/MoveDialog";
import modalActions from "@/common/components/modal/actions";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TemplateCollection from "@/viewer/data/collections/TemplateCollection";
import { cleanRequestAttributes } from "@/viewer/types/domain/request";
import { cleanSlotAttributes } from "@/viewer/types/domain/slot";

import {
  BulkToolActions,
  FocusHolidayDateData,
  FocusSlotCallbackData,
  FocusTallySlotCallbackData,
  OpenContextMenuData,
  OpenContextMenuParams,
  UnfocusHolidayDateData,
} from "@/viewer/ui/modules/common/types/callbacks";
import {
  CallbackContext as CallbackContextType,
  SettingsContext,
  ViewDataType,
} from "@/viewer/ui/modules/common/types/context";
import EditTallyCountModal from "@/viewer/ui/modules/modals/edit-tally-count/EditTallyCountModal";
import { formatDayKey } from "@/viewer/utils/dateFormatters";
import { getSlotQuestUUID, isSlot } from "@/viewer/utils/domain/slotquests";
import { Collection, Model } from "backbone";
import { isAfter, isBefore, isEqual } from "date-fns";
import React, { createContext } from "react";
import { Dispatch } from "redux";
import * as _ from "underscore";

export const openDateContextMenu = (settings: SettingsContext, date: Date, eventTarget: EventTarget): void => {
  const { LbsAppData } = window as any;
  const items: any[] = [];

  // fill out some template arrays to use below
  const useRequestTemplates: any[] = [];
  const useFillTemplates: any[] = [];

  _.each(LbsAppData.Templates.models, function (t: any) {
    if (!t._isHidden()) {
      const requestWindowStart = new Date(t.get("request_window_start"));
      const requestWindowEnd = new Date(t.get("request_window_end"));
      const firstPublished = new Date(t.get("first_published_date"));
      const lastPublished = new Date(t.get("last_published_date"));

      const requestWindowStartCheck = isBefore(requestWindowStart, date) || isEqual(requestWindowStart, date);
      const requestWindowEndCheck = isAfter(requestWindowEnd, date) || isEqual(requestWindowEnd, date);
      const firstPublishedCheck = isBefore(firstPublished, date) || isEqual(firstPublished, date);
      const lastPublishedCheck = isAfter(lastPublished, date) || isEqual(lastPublished, date);

      if (
        LbsAppData.AppContext.getViewDataType() !== ViewDataType.schedule &&
        requestWindowStartCheck &&
        requestWindowEndCheck &&
        LbsAppData.Helpers.Permissions.CanIChangeTemplateRequestData(t)
      ) {
        useRequestTemplates.push(t);
      }
      if (
        LbsAppData.AppContext.getViewDataType() !== ViewDataType.request &&
        firstPublishedCheck &&
        lastPublishedCheck &&
        LbsAppData.Helpers.Permissions.CanIChangeTemplateScheduleData(t)
      ) {
        useFillTemplates.push(t);
      }
    }
  });

  // request
  // requestable templates, if zero...don't push it
  // ALSO check to make sure they have the ability to make any requests permissions wise
  // ALSO if a schedule only view...dont show this (handled above in the template selection)

  if (useRequestTemplates.length > 0 && LbsAppData.Helpers.Permissions.CanIChangeAnyonesRequests()) {
    if (
      _.intersection(
        LbsAppData.MyPersonnel.get("departments"),
        _.map(useRequestTemplates, function (i) {
          return i.get("department_id");
        })
      ).length > 0
    ) {
      items.push({
        text: "Create Request",
        icon: "fa-paper-plane",
        onClick: function () {
          const myPersonnel = LbsAppData.MyPersonnel;
          const myTemplates = new TemplateCollection();
          _.each(useRequestTemplates, function (i) {
            if (_.intersection(myPersonnel.attributes.departments, [i.attributes.department_id]).length > 0) {
              myTemplates.add(i);
            }
          });
          LbsAppData.AppContext.openDialog({
            type: "new-request",
            templateCollection: myTemplates,
            data: {
              step: 1,
              selectedDates: { [formatDayKey(settings, date)]: true },
            },
          });
        },
      });
    }
  }

  // fill
  // fillable templates, if zero...don't push it
  // ALSO check to make sure they have the ability to make any swaps permissions wise
  // ALSO if a request only view...dont show this (handled above in the template selection)

  if (useFillTemplates.length > 0 && LbsAppData.Helpers.Permissions.CanIChangeAnyonesSchedule()) {
    if (
      _.intersection(
        LbsAppData.MyPersonnel.get("departments"),
        _.map(useFillTemplates, function (i) {
          return i.get("department_id");
        })
      ).length > 0
    ) {
      items.push({
        text: "Create Fill",
        icon: "fa-sign-in",
        onClick: function () {
          const myTemplates = new TemplateCollection();
          useFillTemplates.forEach((tpl) => myTemplates.add(tpl));

          LbsAppData.AppContext.openDialog({
            type: "swap-fill",
            templateCollection: myTemplates,
            data: {
              step: 1,
              selectedDates: { [formatDayKey(settings, date)]: true },
            },
          });
        },
      });
    }
  }

  LbsAppData.AppContext.openContextMenu({
    items: items,
    coordsRel: { top: 25, left: 2 },
    coordsRelTarget: eventTarget,
  });
};

interface ContextMenuItem {
  text: string;
  icon: string;
  onClick: () => void;
}

const getContextMenuItemsBulk = (slot: any, noActions: boolean): ContextMenuItem[] => {
  const { LbsAppData } = window as any;
  const items: ContextMenuItem[] = [];

  // view slot details
  items.push({
    text: "View",
    icon: "fa-info-circle",
    onClick: function () {
      LbsAppData.AppContext.expandFocusedSlot(slot);
    },
  });

  if (noActions) {
    return items;
  }

  // bulk actions
  const bulkFn = (action: string) => {
    // open up the swap remove dialog (pre-filled)
    LbsAppData.AppContext.openDialog({
      type: "bulk-action",
      action,
      slots: LbsAppData.ToolBox.get("currentTool").get("targets"),
    });
  };

  const actions = LbsAppData.ToolBox.get("currentTool").getAvailableActions();

  for (const key in actions) {
    if (!actions[key]) {
      // set to false -- skip
      continue;
    }

    // set to true -- add to the item list
    switch (key) {
      case LbsAppData.REMOVE_ACTION:
        items.push({
          text: "Remove All",
          icon: "fa-sign-out",
          onClick: () => bulkFn(LbsAppData.REMOVE_ACTION),
        });
        break;
      case LbsAppData.REPLACE_ACTION:
        items.push({
          text: "Replace All",
          icon: "fa-exchange",
          onClick: () => bulkFn(LbsAppData.REPLACE_ACTION),
        });
        break;
      case LbsAppData.MODIFY_ACTION:
        items.push({
          text: "Modify All",
          icon: "fa-edit",
          onClick: () => bulkFn(LbsAppData.MODIFY_ACTION),
        });
        break;
      case LbsAppData.EXCHANGE_ACTION:
        items.push({
          text: "Exchange",
          icon: "fa-arrows-h",
          onClick: () => bulkFn(LbsAppData.EXCHANGE_ACTION),
        });
        break;
      case LbsAppData.GRANT_ACTION:
        items.push({
          text: "Grant All",
          icon: "fa-thumbs-o-up",
          onClick: () => bulkFn(LbsAppData.GRANT_ACTION),
        });
        break;
      case LbsAppData.DENY_ACTION:
        items.push({
          text: "Deny All",
          icon: "fa-thumbs-o-down",
          onClick: () => bulkFn(LbsAppData.DENY_ACTION),
        });
        break;
      case LbsAppData.SUSPEND_ACTION:
        items.push({
          text: "Suspend All",
          icon: "fa-question",
          onClick: () => bulkFn(LbsAppData.SUSPEND_ACTION),
        });
        break;
      case LbsAppData.DELETE_ACTION:
        items.push({
          text: "Delete All",
          icon: "fa-trash-o",
          onClick: () => bulkFn(LbsAppData.DELETE_ACTION),
        });
        break;
      case LbsAppData.ACCEPT_ACTION:
        items.push({
          text: "Accept All",
          icon: "fa-user-plus",
          onClick: () => bulkFn(LbsAppData.ACCEPT_ACTION),
        });
        break;
      case LbsAppData.DECLINE_ACTION:
        items.push({
          text: "Decline All",
          icon: "fa-user-times",
          onClick: () => bulkFn(LbsAppData.DECLINE_ACTION),
        });
        break;
      case LbsAppData.FINALIZE_ACTION:
        items.push({
          text: "Finalize All",
          icon: "fa-hand-o-right",
          onClick: () => bulkFn(LbsAppData.FINALIZE_ACTION),
        });
        break;
      case LbsAppData.CANCEL_ACTION:
        items.push({
          text: "Cancel All",
          icon: "fa-trash-o",
          onClick: () => bulkFn(LbsAppData.CANCEL_ACTION),
        });
        break;
      default:
        break;
    }
  }

  return items;
};

const handleHighlighter = ({
  name,
  highlighterId,
  slotquest,
}: {
  name: string;
  highlighterId: string;
  slotquest: Model;
}) => {
  const { LbsAppData } = window as any;
  const highlighters = LbsAppData.ToolBox.get("availableTools")["highlighters"];
  const component = null; // TODO: Deal with the ramifications of not having the component
  const targetHighlighter = highlighters.find((item: any) => item.attributes.id === highlighterId);

  const resetHighlighter = (highlighter: any) => {
    highlighter.enabled = false;
    highlighter.slotquestTargetName = "";

    // perform a one-off highlight
    LbsAppData.ToolBox.setTool(targetHighlighter);
    LbsAppData.ToolBox.perform("reset", {
      component,
    });
    LbsAppData.ToolBox.setTool(undefined);
  };

  if (!targetHighlighter.enabled || targetHighlighter.slotquestTargetName !== name) {
    //clear any highlighter used previously by the slot
    LbsAppData.ToolBox.get("availableTools")["highlighters"].some((highlighter: any) => {
      if (highlighter.slotquestTargetName === name) {
        resetHighlighter(highlighter);

        return true;
      }
    });

    targetHighlighter.slotquestTargetName = name;
    targetHighlighter.enabled = true;

    // perform a one-off highlight
    LbsAppData.ToolBox.setTool(targetHighlighter);
    LbsAppData.ToolBox.perform("click", { slot: slotquest });
    LbsAppData.ToolBox.setTool(undefined);
  } else {
    resetHighlighter(targetHighlighter);
  }
};

export const getSlotContextMenuItems = (slotUUID: string, settings: SettingsContext, dispatch: Dispatch): any => {
  const { LbsAppData } = window as any;
  const { isUserAdmin, isUserSuperAdmin } = settings;
  const Slots: Collection = LbsAppData.Slots;
  const Requests: Collection = LbsAppData.Requests;
  const items = [];
  const noActions = LbsAppData.AppContext.get("layout") === "block";
  const slot = Slots.get(slotUUID);
  const request = Requests.get(slotUUID);

  if (!slot && !request) {
    return;
  }

  const slotquest = slot ? slot : request;

  // Bulk mode gets a separate list
  if (LbsAppData.AppContext.get("mode") === "bulk") {
    return getContextMenuItemsBulk(slotquest, noActions);
  }

  LbsAppData.ToolBox.get("availableTools")["highlighters"].forEach((highlighter: any) => {
    const { emp_id, assign_id, display_name, assign_display_name } = slotquest.attributes;
    const {
      id: highlighterId,
      attributes: { colorName: color },
    } = highlighter;
    const leftColType = LbsAppData.AppContext.get("GridSettings_leftColumnType");
    const id = leftColType === "assignment" ? emp_id : assign_id;

    return items.push({
      text: "",
      icon: `fa-square ${color}`,
      color,
      id,
      onClick: function () {
        const name = leftColType === "assignment" ? display_name : assign_display_name;

        handleHighlighter({ name, highlighterId, slotquest });
      },
    });
  });

  // swap/request actions
  if (slotquest.get("slot_date") && !noActions) {
    const actions = LbsAppData.Helpers.Swaps.getSwapActions(LbsAppData.User, slotquest);

    for (const element of actions) {
      switch (element) {
        case LbsAppData.ACCEPT_ACTION:
          items.push({
            text: "Accept Swaportunity",
            icon: "fa-user-plus",
            onClick: function () {
              // open up the swap remove dialog (pre-filled)
              LbsAppData.AppContext.openDialog({
                type: "preswap-pending",
                data: {
                  action: LbsAppData.ACCEPT_ACTION,
                  slots: [slotquest],
                },
              });
            },
          });
          break;

        case LbsAppData.DECLINE_ACTION:
          items.push({
            text: "Decline Swaportunity",
            icon: "fa-user-times",
            onClick: function () {
              // open up the swap remove dialog (pre-filled)
              LbsAppData.AppContext.openDialog({
                type: "preswap-pending",
                data: {
                  action: LbsAppData.DECLINE_ACTION,
                  slots: [slotquest],
                },
              });
            },
          });
          break;

        case LbsAppData.FINALIZE_ACTION:
          items.push({
            text: "Finalize Swaportunity",
            icon: "fa-hand-o-right",
            onClick: function () {
              // open up the swap remove dialog (pre-filled)
              LbsAppData.AppContext.openDialog({
                type: "preswap-finalize",
                data: {
                  slots: [slotquest],
                },
              });
            },
          });
          break;

        case LbsAppData.CANCEL_SWOP_ACTION:
          items.push({
            text: "Cancel Swaportunity",
            icon: "fa-trash-o",
            onClick: function () {
              // open up the swap remove dialog (pre-filled)
              LbsAppData.AppContext.openDialog({
                type: "preswap-pending",
                data: {
                  action: LbsAppData.DELETE_ACTION,
                  slots: [slotquest],
                },
              });
            },
          });
          break;

        case LbsAppData.GRANT_ACTION:
          items.push({
            text: "Grant Swap",
            icon: "fa-thumbs-o-up",
            onClick: function () {
              // open up the swap remove dialog (pre-filled)
              LbsAppData.AppContext.openDialog({
                type: "swap-pending",
                data: {
                  action: LbsAppData.GRANT_ACTION,
                  slots: [slotquest],
                },
              });
            },
          });
          break;

        case LbsAppData.DENY_ACTION:
          items.push({
            text: "Deny Swap",
            icon: "fa-thumbs-o-down",
            onClick: function () {
              // open up the swap remove dialog (pre-filled)
              LbsAppData.AppContext.openDialog({
                type: "swap-pending",
                data: {
                  action: LbsAppData.DENY_ACTION,
                  slots: [slotquest],
                },
              });
            },
          });
          break;

        case LbsAppData.DELETE_ACTION:
          items.push({
            text: "Delete Swap",
            icon: "fa-trash-o",
            onClick: function () {
              // open up the swap remove dialog (pre-filled)
              LbsAppData.AppContext.openDialog({
                type: "swap-pending",
                data: {
                  action: LbsAppData.DELETE_ACTION,
                  slots: [slotquest],
                },
              });
            },
          });
          break;

        case LbsAppData.REMOVE_ACTION:
          items.push({
            text: "Remove",
            icon: "fa-sign-out",
            onClick: function () {
              // open up the swap remove dialog (pre-filled)
              LbsAppData.AppContext.openDialog({
                type: "swap-remove",
                data: {
                  step: 4,
                  selectedDates: {
                    [slotquest.get("slot_date").replace(/-/g, "")]: true,
                  },
                  slots: [slotquest],
                  selectedSlots: { [slotquest.id]: true },
                },
              });
            },
          });
          break;

        case LbsAppData.REPLACE_ACTION:
          items.push({
            text: "Replace",
            icon: "fa-exchange",
            onClick: function () {
              // open up the swap remove dialog (pre-filled)
              LbsAppData.AppContext.openDialog({
                type: "swap-replace",
                data: {
                  step: 3,
                  selectedDates: { [slotquest.get("slot_date").replace(/-/g, "")]: true },
                  slots: [slotquest],
                  selectedSlot: slotquest,
                },
              });
            },
          });
          break;

        case LbsAppData.MOVE_ACTION:
          items.push({
            text: "Move",
            icon: "fa fa-fw fa-calendar",
            onClick: () => dispatch(modalActions.open(<MoveDialog slotId={slot.attributes.slot_id} />)),
          });
          break;

        case LbsAppData.SPLIT_SHIFT_ACTION:
          if (LbsAppData.User.attributes.parameters.LBLiteSplitShift) {
            // TODO: Remove 'if' and feature flag once the feature is complete
            items.push({
              text: "Split Shift",
              icon: "fa-arrows-h",
              icon2: "fa-i-cursor",
              onClick: function () {
                // open up the swap remove dialog (pre-filled)
                LbsAppData.AppContext.openDialog({
                  type: "split-shift",
                  data: {
                    slots: [slotquest],
                  },
                });
              },
            });
          }

          break;

        case LbsAppData.DETAILS_ACTION:
          items.push({
            text: "Modify Details",
            icon: "fa-edit",
            onClick: function () {
              // open up the swap remove dialog (pre-filled)
              LbsAppData.AppContext.openDialog({
                type: "swap-details",
                data: {
                  slots: [slotquest],
                },
              });
            },
          });
          break;

        case LbsAppData.EDIT_TALLY_COUNT:
          if (!isUserAdmin && !isUserSuperAdmin) {
            break;
          }

          items.push({
            text: "Edit Tally Count",
            icon: "fa-sort-amount-up",
            onClick: () =>
              dispatch(
                modalActions.open(
                  <EditTallyCountModal
                    slotQuest={
                      slot
                        ? cleanSlotAttributes(settings, slot.attributes)
                        : cleanRequestAttributes(settings, request.attributes)
                    }
                  />
                )
              ),
          });

          break;

        default:
          break;
      }
    }
  } else if (slotquest.get("request_date") && !noActions) {
    const actions = LbsAppData.Helpers.Swaps.getRequestActions(
      LbsAppData.User,
      slotquest,
      LbsAppData.Templates.getReqWindowStartOrLatestScheduled()
    );
    for (let i = 0; i < actions.length; i++) {
      switch (actions[i]) {
        case LbsAppData.DELETE_ACTION:
          items.push({
            text: "Delete Request",
            icon: "fa-trash-o",
            onClick: function () {
              // open up the swap remove dialog (pre-filled)
              LbsAppData.AppContext.openDialog({
                type: "request-action",
                data: {
                  action: LbsAppData.DELETE_ACTION,
                  requests: [slotquest],
                },
              });
            },
          });
          break;

        case LbsAppData.GRANT_ACTION:
          items.push({
            text: "Grant Request",
            icon: "fa-thumbs-o-up",
            onClick: function () {
              // open up the swap remove dialog (pre-filled)
              LbsAppData.AppContext.openDialog({
                type: "request-action",
                data: {
                  action: LbsAppData.GRANT_ACTION,
                  requests: [slotquest],
                },
              });
            },
          });
          break;

        case LbsAppData.DENY_ACTION:
          items.push({
            text: "Deny Request",
            icon: "fa-thumbs-o-down",
            onClick: function () {
              // open up the swap remove dialog (pre-filled)
              LbsAppData.AppContext.openDialog({
                type: "request-action",
                data: {
                  action: LbsAppData.DENY_ACTION,
                  requests: [slotquest],
                },
              });
            },
          });
          break;

        case LbsAppData.SUSPEND_ACTION:
          items.push({
            text: "Suspend Request",
            icon: "fa-question",
            onClick: function () {
              // open up the swap remove dialog (pre-filled)
              LbsAppData.AppContext.openDialog({
                type: "request-action",
                data: {
                  action: LbsAppData.SUSPEND_ACTION,
                  requests: [slotquest],
                },
              });
            },
          });
          break;

        default:
          break;
      }
    }
  }

  return items;
};

export const expandFocusedSlot = (slotUUID: string, openPager = false): void => {
  const { LbsAppData } = window as any;
  let slotOrRequest = LbsAppData.Slots.get(slotUUID);
  if (!slotOrRequest) {
    slotOrRequest = LbsAppData.Requests.get(parseInt(slotUUID, 10));
  }

  if (!slotOrRequest) {
    return;
  }

  LbsAppData.AppContext.expandFocusedSlot(slotOrRequest, openPager);
};

export const focusSlot = ({ data, boundingRect, expanded }: FocusSlotCallbackData): void => {
  const { LbsAppData } = window as any;
  const slotId = getSlotQuestUUID(data);
  let slot: Model;

  if (isSlot(data)) {
    slot = LbsAppData.Slots.get(slotId);
  } else {
    slot = LbsAppData.Requests.get(parseInt(slotId, 10));
  }

  if (slot) {
    LbsAppData.AppContext.focusSlot({
      data: slot,
      rect: boundingRect,
      expanded,
    });
  }
};

export const focusTallySlot = (data: FocusTallySlotCallbackData): void => {
  const { LbsAppData } = window as any;
  const { percentLabel, tallyName, tallyDate, overUnder, boundingRect } = data;

  LbsAppData.AppContext.focusSlot({
    // Make a fake slot out of the tally data
    data: {
      attributes: {
        percent: percentLabel,
        tally: tallyName,
        tally_date: tallyDate,
        over_under: overUnder,
      },
    },
    rect: boundingRect,
    expanded: false,
  });

  // Since I don't believe window.LbsAppData.AppContext.focusSlot will be changing all that often,
  // we should be ok to never update deps
};

export const focusHolidayDate = (data: FocusHolidayDateData): void => {
  (window as any).LbsAppData.AppContext.focusHolidayDate(data);
};

export const unFocusHolidayDate = (data: UnfocusHolidayDateData): void => {
  (window as any).LbsAppData.AppContext.unfocusHolidayDate(data);
};

export const openSlotContextMenu = (
  slotUUID: string,
  data: OpenContextMenuParams,
  settings: SettingsContext,
  dispatch: Dispatch
): void => {
  const { LbsAppData } = window as any;
  const items = getSlotContextMenuItems(slotUUID, settings, dispatch);

  if (items?.length) {
    LbsAppData.AppContext.openContextMenu({
      ...data,
      items,
    });
  }
};

export const openFilterContextMenu = (data: OpenContextMenuData): any => {
  const { LbsAppData } = window as any;
  LbsAppData.AppContext.openContextMenu({
    ...data,
    items: data?.items.map((item) => {
      const leftColId: string = item.contextData.objectId;
      return {
        ...item,
        onClick: () => {
          if (item.type === "assignment") {
            LbsAppData.Assignments.filterAssignments([leftColId]);
            // send a tracking event
            LbsAppData.Helpers.Analytics.sendEvent("Filter: Assignment Only", "Context Menu");
          } else {
            LbsAppData.Personnel.filterPersonnel([parseInt(leftColId)]);
            // send a tracking event
            LbsAppData.Helpers.Analytics.sendEvent("Filter: Personnel Only", "Context Menu");
          }

          // set the scroll to the top
          $(window).scrollTop(0);
        },
      };
    }),
  });
};

export const unFocusSlot = (): void => {
  const { LbsAppData } = window as any;
  LbsAppData.AppContext.unfocusSlot();
};

export const attachToolbox = (slotUUID: string): void => {
  const { LbsAppData } = window as any;
  let slot = LbsAppData.Slots.get(slotUUID);
  if (!slot) {
    slot = LbsAppData.Requests.get(slotUUID);
  }

  LbsAppData.ToolBox.perform("attach", { slot });
};

export const bulkAttachSlots = (slotUUIDs: string[]): void => {
  const { LbsAppData } = window as any;
  const slots = slotUUIDs.map((uuid) => ({ slot: LbsAppData.Slots.get(uuid) })).filter(({ slot }) => !!slot);

  LbsAppData.ToolBox.perform("reset");
  LbsAppData.ToolBox.perform("attach", slots);
};

export const bulkAttachRequests = (slotUUIDs: string[]): void => {
  const { LbsAppData } = window as any;
  const requests = slotUUIDs.map((uuid) => ({ slot: LbsAppData.Requests.get(uuid) })).filter(({ slot }) => !!slot);

  LbsAppData.ToolBox.perform("reset");
  LbsAppData.ToolBox.perform("attach", requests);
};

export const bulkAttachAll = (components: { slot: Model }[]): void => {
  const { LbsAppData } = window as any;

  LbsAppData.ToolBox.perform("reset");
  LbsAppData.ToolBox.perform("attach", components);
};

export const resetCurrentTool = (): void => {
  const { LbsAppData } = window as any;
  LbsAppData.ToolBox.get("currentTool").reset();
};

export const getBulkSelectTool = () => {
  const { LbsAppData } = window as any;
  return LbsAppData.ToolBox.attributes.availableTools["bulk-select"];
};

export const getAvailableActions = (): BulkToolActions => {
  const { LbsAppData } = window as any;
  let actions = {};
  if (LbsAppData.ToolBox.get("currentTool")) {
    actions = LbsAppData.ToolBox.get("currentTool").getAvailableActions();
  }
  return actions;
};

const selectAction = (action: string): void => {
  const { LbsAppData } = window as any;
  switch (action) {
    default:
      LbsAppData.AppContext.openDialog({
        type: "bulk-action",
        action: action,
        slots: LbsAppData.ToolBox.get("currentTool").get("targets"),
      });
      break;
  }
};

const getActionKeys = (): string[] => {
  const { LbsAppData } = window as any;
  const bulkSelectTool = getBulkSelectTool();
  const targetType = bulkSelectTool.getTargetType();

  let actionKeys = [
    LbsAppData.FILL_ACTION,
    LbsAppData.REQUEST_ACTION,
    LbsAppData.MODIFY_ACTION,
    LbsAppData.REMOVE_ACTION,
    LbsAppData.MOVE_ACTION,
    LbsAppData.REPLACE_ACTION,
    LbsAppData.EXCHANGE_ACTION,
  ];

  if (targetType !== "swop") {
    actionKeys = actionKeys.concat([
      LbsAppData.GRANT_ACTION,
      LbsAppData.DENY_ACTION,
      LbsAppData.SUSPEND_ACTION,
      LbsAppData.DELETE_ACTION,
    ]);
  } else {
    // swap/request/undefined
    actionKeys = actionKeys.concat([
      LbsAppData.ACCEPT_ACTION,
      LbsAppData.DECLINE_ACTION,
      LbsAppData.FINALIZE_ACTION,
      LbsAppData.CANCEL_ACTION,
    ]);
  }

  return actionKeys;
};

export const getTargetKeys = (): string[] => {
  const bulkSelectTool = getBulkSelectTool();
  return _.keys(bulkSelectTool.get("targets"));
};

export const getSelectedTargets = (): Model[] => {
  const { LbsAppData } = window as any;
  const selectedTargets = LbsAppData.ToolBox.attributes.availableTools["bulk-select"].get("targets");
  return Object.keys(selectedTargets).map((key) => selectedTargets[key]);
};

export const triggerSwappingMode = (): boolean => {
  const { LbsAppData } = window as any;
  return LbsAppData.AppContext.triggerSwappingMode();
};

export const triggerViewingMode = (): void => {
  const { LbsAppData } = window as any;
  LbsAppData.AppContext.triggerViewingMode();
};

export const openDatesChangeLoadingDialog = (): void => {
  const { LbsAppData } = window as any;
  const { DateManager } = LbsAppData;
  LbsAppData.AppContext.openLoadingDialog("Please wait...", DateManager, () => {
    DateManager.trigger("change:dates");
  });
};

export const openListViewFilterContextMenu = (
  isFiltered: boolean,
  value: string,
  onClick: () => void,
  data: OpenContextMenuParams
): void => {
  const { LbsAppData } = window as any;
  const items = [];

  if (isFiltered) {
    // push the clear option
    items.push({
      text: "Unfilter " + value,
      icon: "fa-ban",
      onClick: onClick,
    });
  } else {
    // push the filter option
    items.push({
      text: 'Filter "' + value + '"',
      icon: "fa-filter",
      onClick: onClick,
    });
  }

  LbsAppData.AppContext.openContextMenu({
    ...data,
    items,
  });
};

export const defaultCallbackContext = {
  attachToolbox,
  bulkAttachSlots,
  bulkAttachRequests,
  bulkAttachAll,
  resetCurrentTool,
  getBulkSelectTool,
  getAvailableActions,
  selectAction,
  getActionKeys,
  getTargetKeys,
  getSelectedTargets,
  triggerSwappingMode,
  triggerViewingMode,
  expandFocusedSlot,
  focusSlot,
  focusTallySlot,
  focusHolidayDate,
  unFocusHolidayDate,
  unFocusSlot,
  openDatesChangeLoadingDialog,
  openDateContextMenu,
  openSlotContextMenu,
  openFilterContextMenu,
  openListViewFilterContextMenu,
};

// Right now the callbacks don't actually change, but I'm sticking with this context model so that
// we can keep a handle on things as our implementation evolves.
export const CallbackContext = createContext<CallbackContextType>(defaultCallbackContext);
