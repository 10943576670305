/**
 * So Legacy Viewer has a Request backbone model, and a Slot backbone model, and uses
 * them approximately interchangeably.
 *
 * The helpers in this file operate on SlotOrRequest (aka SlotQuest) to accomplish the same goal
 */
import { EMPTY_PERSONNEL_PLACEHOLDER_ID } from "@/viewer/data/constants";
import { CSSProperties } from "react";
import { Slot } from "@/viewer/types/domain/slot";
import { Request } from "@/viewer/types/domain/request";
import { Personnel } from "@/viewer/types/domain/personnel";
import { Assignment } from "@/viewer/types/domain/assignment";
import {
  DataContext,
  FilterContext,
  FilterMap,
  SettingsContext,
  ToolContext,
  ToolEffects,
  UIContext,
} from "@/viewer/ui/modules/common/types/context";
import { SlotDisplayObject, SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { isVisible } from "@/viewer/utils/domain/perssignment";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const STYLE_CONSTANTS = require("@/_lib/utils/constants");

// This is just for fun.
type SlotQuest = SlotOrRequest;

///////////////////////////////////////////////////////////////////
// Accessors

export const isSlot = (s: SlotQuest): s is Slot => {
  // Slots have UUIDs.
  return (s as Slot).uuid !== undefined;
};

export const getSlotQuestUUID = (s: SlotQuest): string => {
  if (isSlot(s)) {
    return s.uuid;
  } else {
    return s.id.toString();
  }
};

export const getLeftColumnId = (ui: UIContext, s: SlotQuest): string => {
  // Should be parallel to getPersonnelOrAssignmentId for the purposes of looking up the slot in an object.
  return ui.leftColumnType === "assignment" ? s.condensedStructureId : s.empId.toString();
};

const getPersonnelDisplayText = (slot: Slot, settings: SettingsContext, ui: UIContext): string => {
  // not trivial...so much l-b here.  l-b gonna l-b

  const { ignorePending } = settings;
  const { leftColumnType } = ui;

  // Ignore all other concerns in the presence of display_name_override
  if (slot.displayNameOverride) {
    if (leftColumnType === "assignment") {
      return slot.displayNameOverride;
    } else {
      return `[${slot.displayNameOverride}]`;
    }
  }

  if (ignorePending) {
    return slot.compactOrDisplayName;
  }

  // Do a bunch of logic about pending
  if (slot.isPending && slot.empId === EMPTY_PERSONNEL_PLACEHOLDER_ID) {
    // pending fill
    return slot.pendingCompactOrDisplayName;
  } else if (slot.isPending && slot.pendingEmpId === EMPTY_PERSONNEL_PLACEHOLDER_ID) {
    // pending remove
    return slot.compactOrDisplayName;
  } else if (slot.isPending && slot.empId === slot.pendingEmpId) {
    return slot.compactOrDisplayName;
  } else if (slot.isPending && slot.pendingInfo?.preswaps?.length === 0) {
    // pending replace (the and condition is to make sure it's not a swop)
    return `${slot.compactOrDisplayName} [${slot.pendingCompactOrDisplayName}]`;
  }

  return slot.compactOrDisplayName;
};

const getSlotDisplayObjectForSlot = (
  settings: SettingsContext,
  ui: UIContext,
  slot: Slot,
  contextEmpID: string | undefined = undefined
): SlotDisplayObject => {
  const { ignorePending, viewId, layout } = settings;
  const { leftColumnType } = ui;

  // in addition to giving a view context specific string back...also send back some particular data
  // that the ui elements might find useful
  const a = slot.assignCompactOrDisplayName;
  const p = getPersonnelDisplayText(slot, settings, ui);

  let displayText = "";
  switch (layout) {
    case "hybrid":
    case "grid":
    case "list":
    case "gantt":
    case "block":
      displayText = leftColumnType === "assignment" ? p : a;
      break;

    case "calendar":
      if (viewId !== "me") {
        if (leftColumnType === "assignment") {
          displayText = a + " - " + p;
        } else {
          displayText = p + " - " + a;
        }
      } else {
        // an implied personnel in the me view of course -- heh.. 'of course'.. not so fast skippy
        // -- gotta add CUSTOM DISPLAY TEXT!!
        displayText = a;
        if (slot.displayNameOverride) {
          displayText = a + " - " + p;
        }
      }
  }

  let isPendingDelete = false;
  let isPendingFill = false;
  let isPendingReplace = false;
  let isPendingDetails = false;
  let isSwop = false;

  if (!ignorePending && slot.isPending) {
    isPendingDelete = slot.pendingEmpId === EMPTY_PERSONNEL_PLACEHOLDER_ID;
    isPendingFill = slot.empId === EMPTY_PERSONNEL_PLACEHOLDER_ID;
    isSwop = (slot.pendingInfo?.preswaps?.length ?? 0) > 0;

    // By process of elimination, we are pending replace... right?
    if (!isPendingDelete && !isPendingFill && !isSwop) {
      // pending replace logic depends on if it's a by personnel or by assignment
      if (slot.pendingEmpId === slot.empId) {
        // same person -- details modification
        isPendingDetails = true;
      } else if (leftColumnType === "assignment" || (layout === "calendar" && viewId !== "me")) {
        // easy...and makes sense
        isPendingReplace = true;
      } else {
        // gets changed to a fill/delete style depending on where this guy is going
        // this condition must have the contextEmpID argument filled in
        if (contextEmpID === slot.empId.toString()) {
          // they are being moved off of it in the replace
          isPendingDelete = true;
        } else if (contextEmpID === slot.pendingEmpId.toString()) {
          // they are being moved into it in the replace
          isPendingFill = true;
        }
      }
    }
  }

  // by personnel view in the grid type views...might need to put custom text on the display text
  if (slot.displayNameOverride && leftColumnType === "personnel" && layout !== "list" && layout !== "calendar") {
    displayText = a + " " + p;
  }

  // only 1 of the _pending attributes should ever be true at a time
  return {
    assignmentText: a,
    personnelText: p,
    displayText: displayText,

    is_pending_delete: isPendingDelete,
    is_pending_fill: isPendingFill,
    is_pending_replace: isPendingReplace,
    is_pending_details: isPendingDetails,
    is_swop: isSwop,
  };
};

const getSlotDisplayObjectForRequest = (
  settings: SettingsContext,
  ui: UIContext,
  request: Request
): SlotDisplayObject => {
  const { layout, viewId } = settings;
  const { leftColumnType } = ui;
  // in addition to giving a view context specific string back...also send back some particular data
  // that the ui elements might find useful
  const a = request.assignCompactOrDisplayName;
  const p = request.compactOrDisplayName;

  let displayText = "";
  switch (layout) {
    case "hybrid":
    case "grid":
    case "list":
    case "gantt":
      displayText = leftColumnType === "assignment" ? p : a;
      break;

    case "calendar":
      if (viewId !== "me") {
        if (leftColumnType === "assignment") {
          displayText = a + " - " + p;
        } else {
          displayText = p + " - " + a;
        }
      } else {
        // an implied personnel in the me view of course
        displayText = a;
      }
  }

  return {
    assignmentText: a,
    personnelText: p,
    displayText: displayText,

    is_pending_delete: false,
    is_pending_fill: false,
    is_pending_replace: false,
    is_pending_details: false,
    is_swop: false,
  };
};

export const getSlotDisplayObject = (
  settings: SettingsContext,
  ui: UIContext,
  s: SlotQuest,
  contextEmpId?: string
): SlotDisplayObject => {
  if (isSlot(s)) {
    return getSlotDisplayObjectForSlot(settings, ui, s, contextEmpId);
  } else {
    return getSlotDisplayObjectForRequest(settings, ui, s);
  }
};

export const getSlotPersonnel = (
  slot: SlotQuest,
  data: DataContext,
  filteredPersonnel?: FilterMap
): Personnel | null => {
  if (isSlot(slot) && slot && slot.isPending) {
    // Check if employee is filtered (empId is not in the filteredPersonnel object)
    // This is necessary to display pending delete/add for the filtered employee
    if (filteredPersonnel && Object.keys(filteredPersonnel).length && !filteredPersonnel[slot.empId]) {
      // Get current employee data
      return data.personnelById[slot.empId] ?? null;
    }
    if (slot.pendingEmpId === EMPTY_PERSONNEL_PLACEHOLDER_ID) {
      // Pending delete, so we need to check the current employee ID, not the pending one.
      return data.personnelById[slot.empId ?? ""] ?? null;
    } else if (slot.pendingEmpId) {
      // Pending add, so we need to check the pending employee ID, not the current one.
      return data.personnelById[slot.pendingEmpId] ?? null;
    }
  }
  return data.personnelById[slot.empId ?? ""] ?? null;
};

export const getSlotAssignment = (slot: SlotQuest, data: DataContext): Assignment | null => {
  return slot.condensedStructureId ? data.assignmentsById[slot.condensedStructureId] : null;
};

export const isPendingSlot = (slot: SlotQuest): boolean => {
  return Boolean(slot.isPending) || slot.status === "pending";
};

export const isSlotVisible = (slot: SlotQuest, ui: UIContext, filters: FilterContext, data: DataContext): boolean => {
  // There are many reasons a slot might not be shown:
  // - The attached personnel/assignment is filtered
  // - The attached personnel/assignment may not be available

  const slotPersonnel = getSlotPersonnel(slot, data, filters.filteredPersonnel);
  const slotAssignment = getSlotAssignment(slot, data);

  if (slotPersonnel && !isVisible(slotPersonnel, ui, filters)) {
    return false;
  }

  if (slotAssignment && !isVisible(slotAssignment, ui, filters)) {
    return false;
  }

  if (ui.showOnlyPending) {
    return isPendingSlot(slot);
  }

  return true;
};

// Get Slot Colors
const getBgColor = (
  slot: SlotOrRequest,
  { leftColumnType }: UIContext,
  data: DataContext,
  columnDef?: string,
  layout?: string
) => {
  const assignmentColor = () => {
    const assignments = getSlotAssignment(slot, data);
    return assignments?.color ?? null;
  };

  const personnelColor = () => {
    const personnel = getSlotPersonnel(slot, data);

    return personnel?.color ?? null;
  };

  if (columnDef && layout === "list") {
    switch (columnDef) {
      case "Assignment":
        return assignmentColor();
      case "Personnel":
        return personnelColor();
      default:
        return null;
    }
  }

  if (leftColumnType === "assignment") {
    // Use the color from the complementary attribute
    return personnelColor();
  }

  return assignmentColor();
};

// the idea is for the text color to be, in order:
// 1. inner text color if defined
// 2. default text color if not defined, but a background color is
// 3. outer text color if defined
// 4. default text color (null)
export const getTextColor = (
  bgColor: string | null,
  slot: SlotOrRequest,
  { leftColumnType }: UIContext,
  data: DataContext,
  columnDef?: string,
  layout?: string
): string | null => {
  const personnelTextColor = getSlotPersonnel(slot, data)?.colorText;
  const assignmentTextColor = getSlotAssignment(slot, data)?.colorText;

  const leftColumnTextColor = leftColumnType === "personnel" ? personnelTextColor : assignmentTextColor;
  const rightColumnTextColor = leftColumnType === "personnel" ? assignmentTextColor : personnelTextColor;

  if (columnDef && layout === "list") {
    switch (columnDef) {
      case "Assignment":
        return assignmentTextColor || STYLE_CONSTANTS.VIEWER_TEXT_COLOR;
      case "Personnel":
        return personnelTextColor || STYLE_CONSTANTS.VIEWER_TEXT_COLOR;
      default:
        return STYLE_CONSTANTS.VIEWER_TEXT_COLOR;
    }
  }

  if (rightColumnTextColor) {
    return rightColumnTextColor;
  } else if (bgColor) {
    return STYLE_CONSTANTS.VIEWER_TEXT_COLOR;
  } else {
    return leftColumnTextColor ?? null;
  }
};

export const getSlotEffects = (
  slot: SlotOrRequest,
  settings: SettingsContext,
  ui: UIContext,
  data: DataContext,
  tools: ToolContext,
  columnDef?: string
): ToolEffects => {
  const slotUUID = getSlotQuestUUID(slot);
  const toolEffects = tools.toolEffectsBySlotUUID[slotUUID];

  if (toolEffects) {
    // Below is overridden.
    return toolEffects;
  }

  const { colorMethod, colorTextMethod, layout } = settings;
  const bgColor = colorMethod !== "none" ? getBgColor(slot, ui, data, columnDef, layout) : null;

  const returnValue: CSSProperties = {};

  if (colorMethod === "mixed" || colorMethod === "cell") {
    if (bgColor) {
      returnValue["backgroundColor"] = bgColor;
    }
  }

  if (colorTextMethod === "colored") {
    const textColor = getTextColor(bgColor, slot, ui, data, columnDef, layout);

    if (textColor) {
      returnValue["color"] = textColor;
    }
  }

  return { style: returnValue };
};
