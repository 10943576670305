import { ReportData, ReportResult, TallyResult } from "@/viewer/types/domain/report";
import connectedPropertyHookFactory from "@/viewer/ui/modules/common/hooks/connectedPropertyHookFactory";
import { ListenerDesc } from "@/viewer/ui/modules/common/helpers/batchRegisterListeners";
import { SettingsContext } from "@/viewer/ui/modules/common/types/context";

export interface TallyGroupReportData {
  tallies: TallyResult[];
  reportData: ReportData;
  reportResult: ReportResult;
}

const getReportData = (settings: SettingsContext, reportModel: any): TallyGroupReportData | null => {
  if (!reportModel) {
    return null;
  }

  // Use the `on_tallies` filter to get the tallies in the expected order.
  const tallyIds = settings.view.filter.on_tallies;
  const tallies = tallyIds
    .map((tallyId: string) => reportModel?.Library?.Tallies.get(tallyId)?.attributes)
    .filter((attributes: TallyResult) => !!attributes);

  return {
    tallies,
    reportData: reportModel.ExtData,
    reportResult: reportModel.get("result"),
  };
};

export default connectedPropertyHookFactory({
  loader: getReportData,
  getListeners: () => {
    const { LbsAppData } = window as any;

    const listeners: ListenerDesc[] = [];

    if (LbsAppData.Report) {
      listeners.push([LbsAppData.Report, "change:available"]);
    }

    return listeners;
  },
});
