"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");
var _ = require("underscore");
var Promise = require("bluebird");
// collections
var TemplateCollection = require("login/data/collections/TemplateCollection.js");
var RequestDataCollection = require("login/data/collections/RequestDataCollection.js");
var SlotCollection = require("login/data/collections/SlotCollection.js");
// components
var RequestsDialogList = require("_lib/ui/modules/dialog/types/requests/list/RequestsDialogList.jsx");
// mixins
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var LegacyToken = require("_lib/data/models/LegacyToken.js");
const ConfigHelper = require("_lib/data/ConfigHelper").default;

var ExtMenuDialog = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    var startDate = moment().startOf("month");
    var endDate = moment().endOf("month");

    var requests = new RequestDataCollection([], {
      adhoc: true,
      //start_date: horizonStartMoment,
      start_date: startDate,
      //end_date: horizonEndMoment,
      end_date: endDate,
      emp_id: window.LbsAppData.User.attributes.emp_id,
    });

    var swaps = new SlotCollection([], {
      adhoc: true,
      emp_id: window.LbsAppData.User.get("emp_id"),
      //start_date: moment().subtract(1, 'year'),
      start_date: startDate,
      //end_date: moment().add(1, 'year'),
      end_date: endDate,
      only_pending: true,
    });

    return {
      isLoading: true,
      activeControl: undefined,

      //filter: 'me',

      //activityFeed: new FeedItemCollection([], {emp_id: window.LbsAppData.User.get('emp_id')}),
      activityFeedIntervalId: undefined,

      requests: requests,
      swaps: swaps,
      startDate: startDate,
      stopDate: endDate,
    };
  },

  componentDidMount: function () {
    var that = this;
    if (window.LbsAppData.MyPersonnel.get("scheduled")) {
      var that = this;

      window.setTimeout(function () {
        // wait a tiny bit to let the animation go through on the first open
        //that._fetchActivityFeed();
        that._fetchItems();
      }, 200);
    }

    // set a timer to refetch the feed
    //var that = this;
    //this.state.activityFeedIntervalId = window.setInterval(function() {
    //	if (that.isMounted()) {
    //		that._fetchActivityFeed();
    //	} else {
    //		// remove it
    //		window.clearInterval(that.state.activityFeedIntervalId);
    //	}
    //}, 5000);

    //window.setTimeout(func)

    // track an event from AppContext (currently, it redraws for every request submit)
    window.LbsAppData.AppContext.on("submittedRequest", this.refetchRequests.bind(this, null), this);
    window.LbsAppData.AppContext.on("submittedSwap", this.refetchSwaps.bind(this, null), this);

    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "ExtMenu");
  },
  componentWillUnmount: function () {
    window.LbsAppData.AppContext.off(null, null, this);
  },
  //componentWillUpdate: function() {
  //	this._unregisterBackboneEvents();
  //},
  //componentDidUpdate: function() {
  //	this._registerBackboneEvents();
  //},

  /* publics */
  close: function (e) {
    this.props.close();
  },
  update: function (options) {
    if (options.filter != "me") {
      // undefine the emp_id attributes
      this.state.activityFeed.attributes.emp_id = undefined;
      this.state.activityFeed.attributes.departments = window.LbsAppData.Personnel.get(
        window.LbsAppData.User.get("emp_id")
      ).get("departments");
      this.state.activityFeed.attributes.last_stamp = undefined;
    } else {
      this.state.activityFeed.attributes.emp_id = window.LbsAppData.User.attributes.emp_id;
      this.state.activityFeed.attributes.departments = undefined;
      this.state.activityFeed.attributes.last_stamp = undefined;
    }

    this.setState(
      {
        isLoading: true,
        filter: options.filter,
        activeControl: undefined,
        //sort: options.sort,
        //startDate: options.startDate,
        //endDate: options.endDate,
      },
      function () {
        this._fetchActivityFeed(true);
      }
    );
  },
  decrementMonth: function () {
    var that = this;

    this.state.startDate.subtract(1, "month").startOf("month");
    this.state.stopDate.subtract(1, "month").endOf("month");

    this.setState(
      {
        isLoading: true,
      },
      function () {
        that._fetchItems();
      }
    );
  },
  incrementMonth: function () {
    var that = this;

    this.state.startDate.add(1, "month").startOf("month");
    this.state.stopDate.add(1, "month").endOf("month");

    this.setState(
      {
        isLoading: true,
      },
      function () {
        that._fetchItems();
      }
    );
  },
  refetchRequests: function () {
    var promises = [];
    promises.push(this.state.requests._fetchRequests());

    var that = this;
    Promise.all(promises).then(function () {
      if (that.isMounted()) {
        that.forceUpdate();
      }
    });
  },

  refetchSwaps: function () {
    var promises = [];
    promises.push(this.state.swaps._fetchSlots());

    var that = this;
    Promise.all(promises).then(function () {
      if (that.isMounted()) {
        that.forceUpdate();
      }
    });
  },

  /* privates */
  _toggleControls: function (e) {
    if (this.state.activeControl != "controls") {
      this.setState({ activeControl: "controls" });
    } else {
      this.setState({ activeControl: undefined });
    }
  },
  _fetchItems: function () {
    var promises = [];
    promises.push(this.state.requests._fetchRequests());
    promises.push(this.state.swaps._fetchSlots());

    var that = this;
    Promise.all(promises).then(function () {
      //_.each(slotCollections, function(item) { slots.add(item.models, {silent: true}); });	// don't bother with events

      that.setState({ isLoading: false });
    });
  },

  _fetchActivityFeed: function (doRemove) {
    //var activityFeed = new FeedItemCollection([], {emp_id: window.LbsAppData.User.get('emp_id')});
    //if (this.state.isLoading) {
    var that = this;

    //if (this.state.filter != 'all') {
    this.state.activityFeed.fetch({
      success: function (model, response, options) {
        that.setState({
          isLoading: false,
          //activityFeed: _.groupBy(activityFeed.models, function(item) { return moment(item.attributes.timestamp).format('YYYYMMDD'); })
        });
      },
      remove: doRemove ? true : false,
    });
    //} else {

    //}

    //} else {
    //	var that = this;
    //
    //	this.setState({isLoading: true}, function() {
    //		that.state.activityFeed.fetch({
    //			success: function(model, response, options) {
    //				that.setState({
    //					isLoading: false,
    //					//activityFeed: _.groupBy(activityFeed.models, function(item) { return moment(item.attributes.timestamp).format('YYYYMMDD'); })
    //				});
    //			}
    //		});
    //	});
    //}
  },
  _openFeedbackForm: function () {
    window.LbsAppData.AppContext.openDialog({
      type: "feedback",
    });
  },
  _gotoDashboard: function () {
    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Outbound", "Dashboard");

    window.location = window.location.protocol + "//" + window.location.host;
  },
  _signout: function () {
    window.location = "/logout";
  },
  _openRequestList: function () {
    window.LbsAppData.AppContext.openDialog({
      type: "requests",
    });
  },
  _openNewRequest: function () {
    // send in the list of templates that are eligible for this dude to request

    // use a modified template collection here...only show templates the user is a member of
    // via department (mydept) membership..
    var myPersonnel = window.LbsAppData.Personnel.get(window.LbsAppData.User.attributes.emp_id);
    var myTemplates = new TemplateCollection();
    _.each(window.LbsAppData.Templates.models, function (i) {
      if (
        !i._isHidden() &&
        window.LbsAppData.Helpers.Permissions.CanIChangeTemplateRequestData(i) &&
        _.intersection(myPersonnel.attributes.departments, [i.attributes.department_id]).length > 0
      ) {
        myTemplates.add(i);
      }
    });

    window.LbsAppData.AppContext.openDialog({
      type: "new-request",
      refetchRequests: this.props.refetchRequests,
      templateCollection: myTemplates,
    });
  },
  _openSwapRemove: function () {
    window.LbsAppData.AppContext.openDialog({
      type: "swap-remove",
    });
  },
  _openSwapFill: function () {
    // send in the list of templates that are eligible for this dude to request

    // use a modified template collection here...only show templates the user is a member of
    // via department (mydept) membership..
    var myPersonnel = window.LbsAppData.Personnel.get(window.LbsAppData.User.attributes.emp_id);
    var myTemplates = new TemplateCollection();
    _.each(window.LbsAppData.Templates.models, function (i) {
      if (!i._isHidden() && window.LbsAppData.Helpers.Permissions.CanIChangeTemplateScheduleData(i)) {
        myTemplates.add(i);
      }
    });

    window.LbsAppData.AppContext.openDialog({
      type: "swap-fill",
      templateCollection: myTemplates,
    });
  },
  _openSwapReplace: function () {
    window.LbsAppData.AppContext.openDialog({
      type: "swap-replace",
    });
  },
  _gotoSupport: function () {
    var legacyToken = new LegacyToken();
    legacyToken.fetch({
      success: function (model) {
        window.open(window.LbsAppData.Destinations.get("unity") + "?source=access&dest=Support.aspx", "_blank");
      },
    });
  },
  _openAccountSettings: function () {
    window.LbsAppData.AppContext.openDialog({
      type: "settings",
    });
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    //var personnelData = window.LbsAppData.Personnel.get(window.LbsAppData.User.get('emp_id'));
    //var userName = personnelData.get('first_name') + ' ' + personnelData.get('last_name');
    var userName = window.LbsAppData.User.attributes.user_name;
    var customerName = window.LbsAppData.User.attributes.customer_name;

    var activityFeedList;
    if (this.state.isLoading) {
      activityFeedList = (
        <div className="loader">
          <i className="fa fa-spinner fa-spin" />
        </div>
      );
    } else {
      //var activityFeed = this.state.activityFeed.groupBy(function(item) { return moment(item.attributes.timestamp).format('YYYY-MM-DD'); });
      //
      //var activityFeedDateContainers = [];
      //for (var key in activityFeed) {
      //	var feedItems = activityFeed[key];
      //
      //	var feedItemDivs = [];
      //	for (var i = 0; i < feedItems.length; i++) {
      //		var feedItem = feedItems[i];
      //
      //		var icon = feedItem.getIcon();
      //		var pendingIcon = undefined;
      //		if (feedItem._isPending()) {
      //			pendingIcon =
      //				<span className='fa-stack fa-lg pending'>
      //					<i className='fa fa-fw fa-stack-2x fa-2x fa-square' />
      //					<i className='fa fa-fw fa-stack-1x fa-question' />
      //				</span>
      //		}
      //
      //		feedItemDivs.push(
      //			<div className='feed-item' key={i}>
      //				<div className='feed-item-header'>
      //					{pendingIcon}
      //					<span className='fa-stack fa-lg'>
      //						{icon}
      //					</span>
      //					<div className='feed-item-header-text'>
      //						<div className='time'>{feedItem.getTime()}</div>
      //						<div className='action-text'>{feedItem.getActionText()}</div>
      //					</div>
      //				</div>
      //
      //				<div className='message'>
      //					<span className='text' dangerouslySetInnerHTML={{__html: feedItem.getMessage()}} />
      //				</div>
      //			</div>
      //		);
      //	}
      //
      //	activityFeedDateContainers.push(
      //		<div className='feed-items-container' key={i}>
      //			<div className='date'>{moment(feedItem.get('timestamp')).format('ddd, ll')}</div>
      //			<div className='feed-items'>
      //				{feedItemDivs}
      //			</div>
      //		</div>
      //	);
      //}
      //if (activityFeedDateContainers.length == 0) {
      //	activityFeedDateContainers.push(
      //		<div className='empty' key='empty'>Nothing new.</div>
      //	)
      //}
      //activityFeedList = activityFeedDateContainers
    }

    // permission logic will now drive which tools are visible to whom
    var hasAnyChangePermission = false;
    let swapFill, swapReplace;
    var swapRemove = (swapFill = swapReplace = null);
    if (window.LbsAppData.Helpers.Permissions.CanIChangeAnyonesSchedule()) {
      hasAnyChangePermission = true;
      swapRemove = (
        <div
          className="button"
          onClick={this.touchProxy.bind(this, this._openSwapRemove, [])}
          onTouchEnd={this.touchProxy.bind(this, this._openSwapRemove, [])}
        >
          <i className="fa fa-sign-out" />
          Remove
        </div>
      );

      swapFill = (
        <div
          className="button"
          onClick={this.touchProxy.bind(this, this._openSwapFill, [])}
          onTouchEnd={this.touchProxy.bind(this, this._openSwapFill, [])}
        >
          <i className="fa fa-sign-in" />
          Fill
        </div>
      );

      /*
			if (window.LbsAppData.MyPersonnel.get('scheduled') == true){
				swapReplace =
					<div className='button'
						onClick={this.touchProxy.bind(this, this._openSwapReplace, [])}
						onTouchEnd={this.touchProxy.bind(this, this._openSwapReplace, [])}>
						<i className='fa fa-exchange' />
						Replace
					</div>
			}
			*/
    }

    var newRequest = {};
    if (window.LbsAppData.Helpers.Permissions.CanIChangeAnyonesRequests()) {
      hasAnyChangePermission = true;
      newRequest = (
        <div
          className="button"
          onClick={this.touchProxy.bind(this, this._openNewRequest, [])}
          onTouchEnd={this.touchProxy.bind(this, this._openNewRequest, [])}
        >
          <i className="fa fa-paper-plane" />
          Request
        </div>
      );
    }

    let createSection = null;
    if (hasAnyChangePermission) {
      createSection = (
        <div className="section requests">
          <div className="section-title">Create</div>
          <div className="buttons">
            {swapRemove}
            {swapFill}
            {swapReplace}
            {newRequest}
          </div>
        </div>
      );
    }

    var requestsSection;
    if (window.LbsAppData.MyPersonnel.get("scheduled")) {
      requestsSection = (
        <div className="requests-container">
          <RequestsDialogList
            isLoading={this.state.isLoading}
            requests={this.state.requests}
            swaps={this.state.swaps}
            startDate={this.state.startDate}
            stopDate={this.state.stopDate}
            incrementMonth={this.incrementMonth}
            decrementMonth={this.decrementMonth}
            refetchRequests={this.refetchRequests}
          />
        </div>
      );
    }

    var classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      ExtMenu: true,
      "not-scheduled": !window.LbsAppData.MyPersonnel.get("scheduled"),
    });

    return (
      <div className={classes}>
        <div className="position-reset">
          <div className="close" onClick={this.close} onTouchEnd={this.close}>
            <i className="fa fa-close" />
          </div>

          <div className="content">
            <div className="header">
              <span className="name">{userName}</span>
              <span className="customer"> &mdash; {customerName}</span>
            </div>

            {requestsSection}

            <div className="sections">
              {createSection}

              <div className="section navigate">
                <div className="section-title">Navigate</div>
                <div className="buttons">
                  <div className="button" onClick={this._signout}>
                    <i className="fa fa-reply" />
                    Sign Out
                  </div>
                  <div className="button" onClick={this._openAccountSettings}>
                    <i className="fa fa-cog" />
                    Settings
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer">
            <div className="item">
              <a className="refer-link" target="_blank" href={ConfigHelper.engage_page_url} rel="noreferrer">
                Engage Page
              </a>
              |
              <span className="feedback-link" onClick={this._openFeedbackForm} onTouchEnd={this._openFeedbackForm}>
                Feedback
              </span>
              |
              <a className="refer-link" target="_blank" href="https://www.lightning-bolt.com/ambassador-program/">
                Refer a Friend
              </a>
              |
              <span className="feedback-link" onClick={this._gotoSupport} onTouchEnd={this._gotoSupport}>
                Support
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  },
});

module.exports = ExtMenuDialog;
