const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var Tally = require("./../models/Tally.js");

var TallyCollection = Backbone.Collection.extend({
  model: Tally,
  comparator: function (a) {
    return a.attributes.tally_name.toLowerCase();
  },

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },
  url: function () {
    return ApiConfig.getPrefix() + "/tally?template_list=" + this.attributes.templateId;
  },

  getTallyWithAssignmentId: function (assignId) {
    var filteredModels = this.where({ tally_type: 1 });
    for (var i = 0; i < filteredModels.length; i++) {
      var model = filteredModels[i];
      if (model.attributes.assign_ids.indexOf(assignId) > -1) {
        // found the first one... don't think we want to return
        // more than one for now
        return model;
      }
    }
  },
});

module.exports = TallyCollection;
