import { toDate } from "date-fns-tz";

export interface AssignmentTypeAttributes {
  atype_id: number;
  created_at: string;
  description: string;
  name: string;
  updated_at: string;
}

export interface AssignmentType {
  id: number | "";
  created: Date;
  description: string;
  name: string;
  updated: Date;
}

export const cleanAssignmentTypeAttributes = (attrs: Partial<AssignmentTypeAttributes>): AssignmentType => {
  return {
    id: attrs.atype_id ?? "",
    created: toDate(attrs.created_at),
    description: attrs.description ?? "",
    name: attrs.name ?? "",
    updated: toDate(attrs.updated_at),
  };
};

export type AssignmentTypesById = Record<string, AssignmentType>;
