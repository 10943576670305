"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var _ = require("underscore");
var moment = require("moment");
var Promise = require("bluebird");
var ReactDOM = require("react-dom");

// components
var ListDialogItem = require("./ListDialogItem.jsx");
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var ListDialog = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    var vault = this.props.dialogObject.collection.models;

    return {
      unfetched: this.props.dialogObject.unfetched,
      filterText: "",
      filteredVault: vault,
      hasBlockingAssignments: false,
    };
  },
  componentDidMount: function () {
    var that = this;

    if (this.state.unfetched) {
      this._fetch();
    } else {
      // focus the search
      ReactDOM.findDOMNode(this.refs.Search).focus();
    }

    // register events on the collection
    this._registerBackboneEvents();
  },
  componentWillUnmount: function () {
    this._unregisterBackboneEvents();
  },
  componentWillUpdate: function () {
    this._unregisterBackboneEvents();
  },
  componentDidUpdate: function () {
    this._registerBackboneEvents();
  },

  /* publics */
  close: function (e) {
    if (this.props.dialogObject.multiselect) {
      this.props.dialogObject.action();
    }

    this.props.close();
  },
  resetAndFetch: function () {
    var that = this;
    this.setState({ unfetched: true }, function () {
      that._fetch();
    });
  },

  /* privates */
  _registerBackboneEvents: function () {
    this.props.dialogObject.collection.on("reset", this.resetAndFetch.bind(this, null), this);
    //this.props.dialogObject.collection.on('fetch', this.forceUpdate.bind(this, null), this);
  },
  _unregisterBackboneEvents: function () {
    this.props.dialogObject.collection.off(null, null, this);
  },

  _fetch: function () {
    var that = this;
    this.props.dialogObject.collection.fetch({
      success: function (collection) {
        // make sure we're still mounted
        if (that.isMounted()) {
          // if there is a postFetchFilter function attached to this collection then use it
          if (collection.postFetch) {
            // wrap it up in a promise in case we have to go get more shit (fucking hell)
            Promise.all([collection.postFetch()]).then(function () {
              that.state.unfetched = false; // don't update the component yet (let the search do that)
              that._handleSearch();
            });
          } else {
            that.state.unfetched = false; // don't update the component yet (let the search do that)
            that._handleSearch();
          }

          // focus the search
          ReactDOM.findDOMNode(that.refs.Search).focus();
        }
      },
    });
  },

  _pushValue: function (item) {
    if (this.props.dialogObject.multiselect) {
      var idx = _.findIndex(this.props.dialogObject.result, function (i) {
        return i.id === item.id;
      });
      // already there, remove it
      if (idx > -1) {
        this.props.dialogObject.result.splice(idx, 1);
        // not there, add it
      } else {
        if (this.state.hasBlockingAssignments) {
          const isBlockingAssignment = item.attributes.is_block;
          const hasABlockingAssignmentSelected = this.props.dialogObject.result.some((a) => a.attributes.is_block);

          if (isBlockingAssignment || hasABlockingAssignmentSelected) {
            const selectedDates = this.props.dialogObject.selectedDates;
            const hasSelectedDates = Object.keys(selectedDates).length;

            // We don't want the user to select a block assignment if they have dates pre-selected.
            // Therefore, we want to clear the date(s) selected
            if (hasSelectedDates && isBlockingAssignment) {
              Object.keys(selectedDates).forEach((key) => delete selectedDates[key]);
            }

            // We don't want the user to be able to select block and non-block assignments together
            this.props.dialogObject.result.length = 0;
          }
        }

        this.props.dialogObject.result.push(item);
      }

      // force an update (coloring..)
      this.forceUpdate();
    } else {
      this.props.dialogObject.action(item);
    }

    // these updates are getting pretty spaghetti-y but we'll keep going...
    // this new thing is needed so we can get the right form scenario on replaces
    // if they happen to close out by click the dialog mask
    if (this.props.dialogObject.onUpdate) {
      this.props.dialogObject.onUpdate(item);
    }
  },

  _selectAll: function () {
    var that = this;

    that.props.dialogObject.result.length = 0;

    if (that.state.hasBlockingAssignments) {
      that.state.filteredVault.forEach((item) => {
        if (!item.attributes.is_block) {
          that.props.dialogObject.result.push(item);
        }
      });
    } else {
      that.state.filteredVault.forEach((item) => {
        that.props.dialogObject.result.push(item);
      });
    }

    // update this component
    this.forceUpdate();

    if (this.props.dialogObject.onUpdate) {
      this.props.dialogObject.onUpdate();
    }
  },
  _selectAllInGroup: function (group) {
    var that = this;
    _.each(_.filter(this.state.filteredVault, group.filterFn), function (item) {
      var idx = _.findIndex(that.props.dialogObject.result, function (i) {
        return i.id === item.id;
      });
      if (idx == -1) {
        that.props.dialogObject.result.push(item);
      } else {
        // don't push a duplicate
      }
    });

    // update this component
    this.forceUpdate();

    if (this.props.dialogObject.onUpdate) {
      this.props.dialogObject.onUpdate();
    }
  },
  _selectNone: function (group) {
    var that = this;
    if (!this.props.dialogObject.onReset) {
      if (this.props.dialogObject.multiselect) {
        _.each(_.filter(this.state.filteredVault, group.filterFn), function (item) {
          var idx = _.findIndex(that.props.dialogObject.result, function (i) {
            return i.id === item.id;
          });
          if (idx > -1) {
            that.props.dialogObject.result.splice(idx, 1);
          }
        });
      } else {
        this._pushValue(undefined);
      }

      // update this component
      this.forceUpdate();
    } else {
      this.props.dialogObject.onReset();
    }

    if (this.props.dialogObject.onUpdate) {
      this.props.dialogObject.onUpdate();
    }
  },
  _selectNoneInGroup: function (group) {
    var that = this;
    if (this.props.dialogObject.multiselect) {
      _.each(_.filter(this.state.filteredVault, group.filterFn), function (item) {
        var idx = _.findIndex(that.props.dialogObject.result, function (i) {
          return i.id === item.id;
        });
        if (idx > -1) {
          that.props.dialogObject.result.splice(idx, 1);
        }
      });
    } else {
      this._pushValue(undefined);
    }

    // update this component
    this.forceUpdate();

    if (this.props.dialogObject.onUpdate) {
      this.props.dialogObject.onUpdate();
    }
  },
  _reset: function () {
    if (!this.props.dialogObject.onReset) {
      if (this.props.dialogObject.multiselect) {
        this.props.dialogObject.result.splice(0, this.props.dialogObject.result.length);
      } else {
        this._pushValue(undefined);
      }

      // update this component
      this.forceUpdate();
    } else {
      this.props.dialogObject.onReset();
    }

    if (this.props.dialogObject.onUpdate) {
      this.props.dialogObject.onUpdate();
    }
  },

  _handleSearch: function (e) {
    var that = this;
    var value;

    if (e) {
      value = e.currentTarget.value.toLowerCase();
    } else {
      value = this.state.filterText;
    }

    // filter the collection
    var vault = this.props.dialogObject.collection.models,
      newFilteredVault = this.props.dialogObject.collection.models;

    if (value) {
      newFilteredVault = _.filter(vault, function (item) {
        return item.attributes[that.props.dialogObject.property].toLowerCase().indexOf(value) > -1;
      });
    }

    const hasBlockingAssignments =
      this.props.dialogObject.shouldShowBlocks && newFilteredVault.some((a) => a.attributes.is_block);

    this.setState({
      filterText: value,
      filteredVault: newFilteredVault,
      hasBlockingAssignments: hasBlockingAssignments,
    });
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    const {
      dialogObject: { multiselect, result },
    } = this.props;

    const showCheckIcon = multiselect && result?.length > 0;

    const closeIconClassName = showCheckIcon ? "fa fa-check" : "fa fa-close";

    const closeIcon = <i className={closeIconClassName} />;

    var hasItems = false;
    var listItemsContainer = [];
    if (this.state.unfetched) {
      listItemsContainer.push(
        <div className="loader" key="spinner">
          <i className="fa fa-fw fa-spinner fa-spin" />
        </div>
      );
    } else {
      //var clickFunction = this.props.dialogObject.multiselect ? this._pushValue : this._selectValue;
      if (!this.props.dialogObject.groupings) {
        var listItems = [];
        for (var i = 0; i < this.state.filteredVault.length; i++) {
          hasItems = true;

          var item = this.state.filteredVault[i];
          var isSelected = this.props.dialogObject.multiselect
            ? _.findIndex(this.props.dialogObject.result, function (ri) {
                return ri.id === item.id;
              }) > -1
            : this.props.dialogObject.result &&
              (this.props.dialogObject.result.id == item.id ||
                (this.props.dialogObject.result.length && this.props.dialogObject.result[0].id == item.id));

          const icon = this.props.dialogObject.shouldShowBlocks && item.attributes.is_block && (
            <i className="fa fa-fw fa-cubes" />
          );

          listItems.push(
            <ListDialogItem
              key={i}
              data={item}
              selected={isSelected}
              icon={icon}
              display={this.props.dialogObject.displayOverride || this.props.dialogObject.property}
              extClasses={this.props.dialogObject.extClasses ? this.props.dialogObject.extClasses(item) : []}
              onClick={this._pushValue}
            />
          );
        }

        listItemsContainer.push(
          <div className="list-items-grouping" data-cy="listItems" key={0}>
            {listItems}
          </div>
        );
      } else {
        // groupings list
        for (var z = 0; z < this.props.dialogObject.groupings.length; z++) {
          var listItems = [];
          var groupedItems = _.filter(this.state.filteredVault, this.props.dialogObject.groupings[z].filterFn);
          for (var i = 0; i < groupedItems.length; i++) {
            hasItems = true;

            var item = groupedItems[i];
            var isSelected = this.props.dialogObject.multiselect
              ? _.findIndex(this.props.dialogObject.result, function (ri) {
                  return ri.id === item.id;
                }) > -1
              : this.props.dialogObject.result &&
                (this.props.dialogObject.result.id == item.id ||
                  (this.props.dialogObject.result.length && this.props.dialogObject.result[0].id == item.id));

            listItems.push(
              <ListDialogItem
                key={i}
                data={item}
                selected={isSelected}
                display={this.props.dialogObject.displayOverride || this.props.dialogObject.property}
                extClasses={this.props.dialogObject.extClasses ? this.props.dialogObject.extClasses(item) : []}
                onClick={this._pushValue}
              />
            );
          }

          if (groupedItems.length) {
            listItemsContainer.push(
              <div
                className={"list-items-grouping " + this.props.dialogObject.groupings[z].extClasses.join(" ")}
                key={z}
              >
                <div className="list-items-grouping-title" data-cy="listItems">
                  {this.props.dialogObject.groupings[z].title} ({listItems.length})
                  <div className="actions-row">
                    Select{" "}
                    <span
                      className="actions-row-item"
                      onClick={this._selectAllInGroup.bind(this, this.props.dialogObject.groupings[z])}
                    >
                      All
                    </span>{" "}
                    |{" "}
                    <span
                      className="actions-row-item"
                      onClick={this._selectNoneInGroup.bind(this, this.props.dialogObject.groupings[z])}
                    >
                      None
                    </span>
                  </div>
                </div>
                {listItems}
              </div>
            );
          }
        }
      }

      // if we're still empty push an empty text
      if (!hasItems) {
        listItemsContainer = (
          <div className="empty-list" key={0}>
            No results.
          </div>
        );
      }
    }

    var pinnedItems = [];
    if (this.props.dialogObject.message) {
      pinnedItems.push(
        <span className="pinned-item no-hover" key="msg">
          {this.props.dialogObject.message}
        </span>
      );
    } else {
      if (this.props.dialogObject.multiselect) {
        pinnedItems.push(
          <span className="pinned-item" key="all" onClick={this._selectAll}>
            <i className="fa fa-fw fa-asterisk" /> All
          </span>
        );
      }

      var noneText = this.state.filterText != "" && this.props.dialogObject.multiselect ? "None" : "Reset";
      pinnedItems.push(
        <span className="pinned-item" key="none" onClick={this._selectNone}>
          <i className="fa fa-fw fa-ban" /> {noneText}
        </span>
      );
    }

    // this is -kinda- getting out of control.. need to rethink this list component
    var pinnedItemContainer;
    if (this.props.dialogObject.message || (this.props.dialogObject.result && !this.props.dialogObject.noReset)) {
      pinnedItemContainer = <div className="pinned">{pinnedItems}</div>;
    }

    var subCollectionContainer;
    if (this.props.dialogObject.subCollection) {
      subCollectionContainer = (
        <div className="filter-container">
          <span className="filter-title">Template</span>
          <span className="filter-name" onClick={this.props.dialogObject.onSubCollectionFocus}>
            {this.props.dialogObject.subResult()}
          </span>
        </div>
      );
    }

    let blockInfo;
    if (this.state.hasBlockingAssignments) {
      blockInfo = (
        <div className="info-container">
          <i className="fa fa-fw fa-cubes" />
          <span className="info-title">
            Selecting a block assignment will clear any dates that you may have already selected.
          </span>
        </div>
      );
    }

    const classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      List: true,
    });

    const listClasses = React.addons.classSet({
      "list-items": true,
      "with-filter": this.props.dialogObject.subCollection,
      "with-block-info": this.state.hasBlockingAssignments,
    });

    return (
      <div className={classes}>
        <div className="position-reset">
          <div
            className="close"
            data-cy="listCloseCheckBtn"
            onClick={this.touchProxy.bind(this, this.close, [])}
            onTouchEnd={this.touchProxy.bind(this, this.close, [])}
          >
            {closeIcon}
          </div>

          <div className="message">{this.props.dialogObject.title}</div>

          <div className="search">
            <i className="fa fa-search" />
            <input ref="Search" type="text" value={this.state.filteredText} onChange={this._handleSearch} />
          </div>

          {subCollectionContainer}

          {blockInfo}

          <div className={listClasses}>{listItemsContainer}</div>

          {pinnedItemContainer}
        </div>
      </div>
    );
  },
});

module.exports = ListDialog;
