import React from "react";

import { getSlotQuestUUID } from "@/viewer/utils/domain/slotquests";
import classnames from "@/_lib/utils/classnames";
import { isWeekend } from "date-fns";

import ColumnSlot from "@/viewer/ui/modules/grid/column/components/ColumnSlot";

import { DataContext, SettingsContext, ToolContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { CellType } from "@/viewer/ui/modules/common/types/contextClues";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";

import { getCellHeight } from "../print/constants";
import { OriginalPersonnelMap } from "@/viewer/types/viewdata";
import { DepartmentsById } from "@/viewer/types/domain/department";

interface Props {
  settings: SettingsContext;
  ui: UIContext;
  data: DataContext;
  tools: ToolContext;
  date: Date;
  leftColId: string;
  scheduled: SlotOrRequest[];
  cellEffects: React.CSSProperties;
  originalPersonnelMap: OriginalPersonnelMap;
  departmentsById: DepartmentsById;
  cellType?: CellType;
  isPrintedCell?: boolean;
}

const ColumnCell = (props: Props): JSX.Element => {
  const {
    cellEffects,
    cellType,
    data,
    date,
    isPrintedCell,
    leftColId,
    scheduled,
    settings,
    tools,
    ui,
    originalPersonnelMap,
    departmentsById,
  } = props;
  const slots: JSX.Element[] = [];

  scheduled.forEach((slot) => {
    slots.push(
      <ColumnSlot
        key={getSlotQuestUUID(slot)}
        settings={settings}
        ui={ui}
        data={data}
        tools={tools}
        slot={slot}
        leftColId={leftColId}
        cellType={cellType}
        cellEffects={cellEffects}
        originalPersonnelMap={originalPersonnelMap}
        departmentsById={departmentsById}
      />
    );
  });

  let style = {};

  // If this is a blank cell and we are printing it
  // then set the height to the one returned
  // from the getCellHeight helper function
  if (!scheduled.length && isPrintedCell) {
    style = { height: getCellHeight(ui) };
  }

  const classes = classnames("cell", {
    "cell--condensed": settings.condenseColumnView,
    "cell--weekend": isWeekend(date),
    "cell--times-shown": ui.showTimes,
  });

  return (
    <div className={classes} style={style}>
      {slots}
    </div>
  );
};

export default ColumnCell;
