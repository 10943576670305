const Backbone = require("backbone");
var _ = require("underscore");
var Promise = require("bluebird");

var ApiConfig = require("_lib/data/ApiConfig.js");
var Request = require("./../models/requests/Request.js");

var RequestCollection = Backbone.Collection.extend({
  model: Request,
  url: ApiConfig.getPrefix() + "/request",

  // we can get away with this since we route EVERYTHING through a POST for swaps
  // all that matters is that the individual swap models have accurate data on them
  // the collection will just be thrown out (or at least it should be) once the save is done...merge it into
  // the main slot collection and get rid of it

  save: function (success_callback, error_callback) {
    Backbone.sync("create", this, {
      success: function (response) {
        success_callback ? success_callback(response) : undefined;
      },
      error: function (response) {
        // k this is a hack...but the whole way I'm using these is pretty much a hack
        // I'm sending deletes through here...so that we can support bulk deletes via a collectino..
        // for schedule data that works out of the box (since data is sent back), but for requests...no data is sent back
        // HOWEVER a status code of 200 is...so if we are in here with a status code == 200, then call the success call back
        if (response.status == 200) {
          success_callback ? success_callback(response) : undefined;
        } else {
          // something other than a 200 (i guess? what the hell is that comment up there).. call the error_callback
          error_callback ? error_callback(response) : undefined;
        }
      },
    });
  },
});

module.exports = RequestCollection;
