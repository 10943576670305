import CustomTimePicker from "@/_lib/ui/modules/dialog/components/customTimePicker/CustomTimePicker";
import { SlotData, SlotTimeType } from "@/_lib/ui/modules/dialog/types/schedule_edits/swaps/move/types";
import { getSlotDefaultTime } from "@/_lib/utils/slotUtils";
import { TWELVE_HOUR_FORMAT } from "@/_lib/utils/time";
import { Assignment } from "@/viewer/types/domain/assignment";
import { SlotTime } from "@/viewer/types/domain/slot";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { produce } from "immer";
import { DateTime } from "luxon";
import React, { ChangeEvent } from "react";

interface OptionsStageProps {
  assignment: Assignment;
  isAdmin: boolean;
  setSlotData: (slotData: SlotData) => void;
  slotData: SlotData;
}

const callOrderOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, "None"];

const OptionsStage = (props: OptionsStageProps): JSX.Element => {
  const { slotData, setSlotData, isAdmin, assignment } = props;
  const selectedSlotDate = slotData.selectedDate.toLocaleString(DateTime.DATE_MED);
  let selectedStartTime = DateTime.fromObject({
    hour: slotData.slotStartTime.hours,
    minute: slotData.slotStartTime.minutes,
  }).toFormat(TWELVE_HOUR_FORMAT);
  let selectedEndTime = DateTime.fromObject({
    hour: slotData.slotStopTime.hours,
    minute: slotData.slotStopTime.minutes,
  }).toFormat(TWELVE_HOUR_FORMAT);

  if (slotData.slotTimeType === SlotTimeType.Default && assignment) {
    const defaultTimes = getSlotDefaultTime(assignment, slotData.templateId as number, slotData.dateString);
    selectedStartTime = defaultTimes.startTime;
    selectedEndTime = defaultTimes.endTime;
  }

  const getCallOrderOptions = (): JSX.Element => {
    return (
      <Select
        value={slotData.slotCallOrder ?? "None"}
        onChange={(e) =>
          setSlotData(
            produce(slotData, (draft) => {
              if (e.target.value === "None") {
                draft.slotCallOrder = null;
                return;
              }

              draft.slotCallOrder = e.target.value as number;
            })
          )
        }
      >
        {Array.from(callOrderOptions).map((option) => (
          <MenuItem value={option} key={`callOrder_${option}`}>
            {option}
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <Box
      display={"flex"}
      sx={{ flexDirection: "column", justifyContent: "space-between", alignContent: "left" }}
      width={"325px"}
      height={"434px"}
    >
      <Typography>{`${selectedSlotDate}, ${selectedStartTime} - ${selectedEndTime}`}</Typography>
      <Typography>{`${slotData.assignCompactOrDisplayName}`}</Typography>
      <Typography>{`${slotData.compactOrDisplayName}`}</Typography>
      <Divider style={{ height: "2px", margin: "5px 0" }} />
      <Box
        display={"flex"}
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "-55px",
        }}
      >
        <Box display={"flex"} sx={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
          <Typography style={{ marginRight: "20px" }}>{"Time"}</Typography>
          <RadioGroup
            row
            defaultValue={SlotTimeType.Default}
            defaultChecked={true}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSlotData(
                produce(slotData, (draft) => {
                  draft.slotTimeType = event.target.value as SlotTimeType;
                })
              )
            }
            value={slotData.slotTimeType}
          >
            <FormControlLabel
              control={<Radio />}
              label={<Typography style={{ fontSize: "1em" }}>Default</Typography>}
              value={SlotTimeType.Default}
            />
            <FormControlLabel
              control={<Radio />}
              label={<Typography style={{ fontSize: "1em" }}>Custom</Typography>}
              value={SlotTimeType.Custom}
            />
          </RadioGroup>
        </Box>
        {slotData.slotTimeType === SlotTimeType.Custom && (
          <CustomTimePicker
            handleStartTimeChange={(slotStartTime: SlotTime) => setSlotData({ ...slotData, slotStartTime })}
            handleStopTimeChange={(slotStopTime: SlotTime) => setSlotData({ ...slotData, slotStopTime })}
            slotStartTime={slotData.slotStartTime}
            slotStopTime={slotData.slotStopTime}
          />
        )}
      </Box>
      <Box
        display={"flex"}
        sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
        width={"260px"}
      >
        <Typography style={{ marginRight: "10px" }}>{"Call Order"}</Typography>
        {getCallOrderOptions()}
      </Box>
      <Box
        display={"flex"}
        sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
        width={"260px"}
      >
        <Typography style={{ marginRight: "10px" }}>{"Note"}</Typography>
        <TextField
          id="standard-basic"
          variant="standard"
          value={slotData.slotNote}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSlotData({ ...slotData, slotNote: e.target.value })}
        />
      </Box>
      <Box
        display={"flex"}
        sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
        width={"260px"}
      >
        <Typography style={{ marginRight: "10px" }}>{"Reason"}</Typography>
        <TextField id="standard-basic" variant="standard" value={slotData?.reasonName ?? "No reason"} />
      </Box>
      {isAdmin && (
        <Box
          display={"flex"}
          sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}
          width={"260px"}
        >
          <Checkbox
            checked={slotData.addDemand}
            onClick={() => setSlotData({ ...slotData, addDemand: !slotData.addDemand })}
          />
          <Typography>{"Add demand if necessary"}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default OptionsStage;
