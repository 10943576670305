/*
 * Assignment model that doesn't depend on window object bindings */
const Backbone = require("backbone");
const ApiConfig = require("@/_lib/data/ApiConfig.js");
const logging = require("@/_lib/utils/logging").default;

const NakedAssignment = Backbone.Model.extend({
  idAttribute: "assign_structure_id",

  defaults: {},

  initialize: function () {},

  url: function () {
    if (!this.id) {
      logging.error(
        `[NakedAssignment.js - /assignments/condensed] idAttribute is missing for assignment: ${this?.attributes?.display_name}`
      );

      return "";
    }

    return ApiConfig.getPrefix() + "/assignments/condensed/" + this.id;
  },

  // following functions exist because we condense the assignment index (to make it view 'nicer')
  // that means we need additional data lookups to tell what assignment we are actually dealing with
  _isAssignmentOnTemplate: function (template_id) {
    return this.attributes.templates.indexOf(template_id) > -1;
  },

  _getAssignmentIDForTemplate: function (template_id) {
    return this.attributes.template_to_assign_id_map[template_id];
  },

  _getAssignmentTypeForTemplate: function (template_id) {
    return this.attributes.template_to_assign_type_map[template_id];
  },

  _getAssignmentDefaultStartTime: function (template_id) {
    let returnVal = undefined;

    if (this.attributes.template_to_default_times_map[template_id] !== undefined) {
      if (this.attributes.template_to_default_times_map[template_id].split("~").length > 1) {
        returnVal = this.attributes.template_to_default_times_map[template_id].split("~")[0];
      }
    }
    return returnVal;
  },

  _getAssignmentDefaultStopTime: function (template_id) {
    let returnVal = undefined;

    if (this.attributes.template_to_default_times_map[template_id] !== undefined) {
      if (this.attributes.template_to_default_times_map[template_id].split("~").length > 1) {
        returnVal = this.attributes.template_to_default_times_map[template_id].split("~")[1];
      }
    }
    return returnVal;
  },

  _isExpired: function () {
    return this.attributes.expired;
  },
});

module.exports = NakedAssignment;
