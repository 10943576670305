const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");

var NakedSchedule = Backbone.Model.extend({
  idAttribute: "schedule_id",

  defaults: {
    locale_start_date: undefined,
    locale_stop_date: undefined,
    locale_last_modified_date: undefined,
  },

  initialize: function () {
    this.attributes.locale_start_date = moment(this.attributes.start_date, "YYYY-MM-DD")
      .format("YYYY-L")
      .replace(/\-[0-9]{4}/, "");
    this.attributes.locale_stop_date = moment(this.attributes.stop_date, "YYYY-MM-DD")
      .format("YYYY-L")
      .replace(/\-[0-9]{4}/, "");

    var lastModMoment = moment(this.attributes.last_modified_date);
    var lastModDate = lastModMoment
      .format("YYYY-L")
      .replace(/\//g, "-")
      .replace(/\-[0-9]{4}/, "");
    // just always show military for this
    var lastModTime = lastModMoment.format("hh:mm:ss");

    // purge the '/' and '-' difference that exists between locales, for this field we want '-' always
    this.attributes.locale_start_date = this.attributes.locale_start_date.replace(/\//g, "-").replace(/\-[0-9]{4}/, "");
    this.attributes.locale_stop_date = this.attributes.locale_stop_date.replace(/\//g, "-").replace(/\-[0-9]{4}/, "");
    this.attributes.locale_last_modified_date = lastModDate + " " + lastModTime;
  },

  url: function () {
    return ApiConfig.getPrefix() + (this.id ? "/schedule/data/" + this.id : "/schedule/data");
  },
});

module.exports = NakedSchedule;
