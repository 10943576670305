import ActionDialog, { ActionDialogType } from "@/_lib/ui/modules/dialog/types/action_dialog/ActionDialog";
import React from "react";

interface WarningDialogProps {
  isOpen: boolean;
  content: JSX.Element;
  actions: JSX.Element;
}

const WarningDialog = (props: WarningDialogProps): JSX.Element => {
  const { isOpen, content, actions } = props;

  return (
    <ActionDialog
      type={ActionDialogType.Warning}
      header={"Warning"}
      content={content}
      actions={actions}
      isOpen={isOpen}
      styleOverride={{ height: "auto", width: "400px", margin: "0 auto" }}
    />
  );
};

export default WarningDialog;
