import { Model } from "backbone";
import {
  AccessibleApps,
  AccessibleAppsAttributes,
  cleanAccessibleAppsAttributes,
} from "@/viewer/types/domain/accessibleApps";

export default (): AccessibleApps => {
  const { LbsAppData } = window as any;
  const AccessibleAppsModal: Model<AccessibleAppsAttributes> = LbsAppData.AccessibleApps;

  return cleanAccessibleAppsAttributes(AccessibleAppsModal.attributes);
};
