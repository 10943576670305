const Backbone = require("backbone");
var TallyDataByEmp = Backbone.Model.extend({
  idAttribute: "emp_id",

  defaults: {},

  initialize: function (attributes, options) {},
});

module.exports = TallyDataByEmp;
