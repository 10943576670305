import moment from "moment";
import { isSameDay } from "date-fns";

import { getSlotDisplayObject } from "@/viewer/utils/domain/slotquests";
import {
  formatFullHumanizedDate,
  formatLocalTime,
  formatLocalTimeWithMonthAndDay,
} from "@/viewer/utils/dateFormatters";

import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { AssignmentTypesById } from "@/viewer/types/domain/assignmentType";
import { PersonnelTypesById } from "@/viewer/types/domain/personnelType";
import { AssignmentsById } from "@/viewer/types/domain/assignment";
import { PersonnelById } from "@/viewer/types/domain/personnel";
import { TemplatesById } from "@/viewer/types/domain/template";
import { DepartmentsById } from "@/viewer/types/domain/department";

/***
 * The body of search terms for a given slot or request.
 *
 * Both search corpora are delimited by semi colons.
 *
 * `keyValueSearchCorpus` is a concatenated list of strings of the form "key=value;"
 * `searchCorpus` is a concatenated list of strings.
 */
export type SearchCorpus = { keyValueSearchCorpus: string; searchCorpus: string };
export type SearchableAttribute = { name: string; data: any };
export interface SearchCorpusParams {
  settings: SettingsContext;
  ui: UIContext;
  columnNames: string[];
  assignmentTypesById: AssignmentTypesById;
  personnelTypesById: PersonnelTypesById;
  assignmentsById: AssignmentsById;
  personnelById: PersonnelById;
  templatesById: TemplatesById;
  departmentsById: DepartmentsById;
}

export const getSearchableAttributes = (
  slotquest: SlotOrRequest,
  params: SearchCorpusParams
): SearchableAttribute[] => {
  const {
    settings,
    ui,
    columnNames,
    assignmentTypesById,
    personnelTypesById,
    assignmentsById,
    personnelById,
    templatesById,
    departmentsById,
  } = params;
  const ret: SearchableAttribute[] = [];
  let personnel, assignment, displayText, description;

  columnNames?.forEach((columnName) => {
    switch (columnName) {
      case "Assignment":
        ret.push({
          name: "assignment",
          data: slotquest.assignCompactOrDisplayName,
        });
        break;
      case "Assignment Type":
        if (slotquest.assign_atype) {
          ret.push({
            name: "assignment_type",
            data: assignmentTypesById[slotquest.assign_atype].name,
          });
        }
        break;
      case "Business Phone":
        ret.push({
          name: "phone_business",
          data: personnelById[slotquest.empId]?.businessPhoneNumber,
        });
        break;
      case "Cell Phone":
        ret.push({
          name: "phone_cell",
          data: personnelById[slotquest.empId]?.cellPhoneNumber,
        });
        break;
      case "Date":
        ret.push({
          name: "date",
          data: moment(slotquest.date).format("dddd, LL"),
        });
        break;
      case "Department":
        if (slotquest.templateId) {
          const template = templatesById[slotquest.templateId];
          const departmentName = departmentsById[template?.departmentId]?.name;
          ret.push({
            name: "department",
            data: departmentName,
          });
        }
        break;
      case "Email":
        ret.push({
          name: "email",
          data: personnelById[slotquest.empId]?.email,
        });
        break;
      case "Home Phone":
        ret.push({
          name: "phone_home",
          data: personnelById[slotquest.empId]?.homePhoneNumber,
        });
        break;
      case "Note":
        if ("note" in slotquest) {
          ret.push({
            name: "note",
            data: slotquest.note,
          });
        }
        break;
      case "Pager":
        ret.push({
          name: "pager",
          data: personnelById[slotquest.empId]?.pager,
        });
        break;
      case "Personnel":
        displayText = getSlotDisplayObject(settings, ui, slotquest, `${slotquest.empId}`).personnelText;
        ret.push({
          name: "personnel",
          data: displayText,
        });
        break;
      case "Personnel Type":
        if (slotquest.emp_ptype) {
          ret.push({
            name: "personnel_type",
            data: personnelTypesById[slotquest.emp_ptype].name,
          });
        }
        break;
      case "Start Time":
        ret.push({
          name: "start_time",
          data: formatLocalTime(settings, slotquest.startTime),
        });
        break;
      case "Stop Time":
        ret.push({
          name: "stop_time",
          data: isSameDay(slotquest.stopTime, slotquest.date)
            ? formatLocalTime(settings, slotquest.stopTime)
            : formatLocalTimeWithMonthAndDay(settings, slotquest.stopTime),
        });
        break;
      case "Template":
        ret.push({
          name: "template",
          data: slotquest.templateDesc,
        });
        break;
      case "Location(s)":
        ret.push({
          name: "locations",
          data: slotquest.locationNames.join(","),
        });
        break;
      case "Weekly Hours":
        ret.push({
          name: "weekly_hours",
          data: personnelById[slotquest.empId]?.weeklyHours,
        });
        break;
      case "Seniority Date":
        personnel = personnelById[slotquest.empId];
        ret.push({
          name: "seniority_date",
          data: personnel?.seniorityDate ? formatFullHumanizedDate(personnel?.seniorityDate) : "",
        });
        break;
      case "UniqueID":
        ret.push({
          name: "unique_id",
          data: personnelById[slotquest.empId]?.uniqueId,
        });
        break;
      case "Custom1":
        ret.push({
          name: "custom_1",
          data: personnelById[slotquest.empId]?.custom1,
        });
        break;
      case "Custom2":
        ret.push({
          name: "custom_2",
          data: personnelById[slotquest.empId]?.custom2,
        });
        break;
      case "Custom3":
        ret.push({
          name: "custom_3",
          data: personnelById[slotquest.empId]?.custom3,
        });
        break;
      case "Custom4":
        ret.push({
          name: "custom_4",
          data: personnelById[slotquest.empId]?.custom4,
        });
        break;
      case "Category":
        assignment = assignmentsById[slotquest.assignStructureId];
        ret.push({
          name: "category",
          data: assignment?.structureToCategoryMap[slotquest.assignStructureId] ?? "",
        });
        break;
      case "Title":
        ret.push({
          name: "title",
          data: personnelById[slotquest.empId]?.title,
        });
        break;
      case "Total Hours":
        ret.push({
          name: "total_hours",
          data: moment(slotquest.stopTime).diff(moment(slotquest.startTime), "hours", true),
        });
        break;
      case "Assignment Information":
        assignment = assignmentsById[slotquest.condensedStructureId];

        description = assignment?.assignmentToNoteMap[slotquest?.assignId];

        ret.push({
          name: "assignment_information",
          data: description ?? "",
        });
        break;
      case "Call Order":
        ret.push({
          name: "callOrder",
          data: slotquest.callOrder,
        });
        break;
      default:
        break;
    }
  });

  return ret;
};

const constructSearchCorpus = (slotquest?: SlotOrRequest, params?: SearchCorpusParams): SearchCorpus => {
  let keyValueSearchCorpus = "",
    searchCorpus = "";

  if (!slotquest || !params) return { keyValueSearchCorpus, searchCorpus };

  const searchableAttributes = getSearchableAttributes(slotquest, params);

  searchableAttributes.forEach((searchableAttribute) => {
    keyValueSearchCorpus += searchableAttribute.name + `=${searchableAttribute.data ? searchableAttribute.data : ""};`;
    searchCorpus += `${searchableAttribute.data ? searchableAttribute.data : ""};`;
  });

  return {
    keyValueSearchCorpus,
    searchCorpus,
  };
};

export default constructSearchCorpus;
