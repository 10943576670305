"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var _ = require("underscore");
var moment = require("moment");
var Promise = require("bluebird");
// components
var ErrorDialogItem = require("./ErrorDialogItem.jsx");
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var ErrorDialog = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {},
  componentWillUnmount: function () {},

  /* privates */
  _reset: function () {},
  _submit: function () {
    // close this dialog and trigger the resubmission
    window.LbsAppData.AppContext.closeDialog();
    this.props.submit();
  },
  _getEditDetailsTimezoneErrorMessage: function () {
    var detailsError = this._getDetailsErrorMessage();
    if (this.props.isDetailsError) {
      return <div className="errors-header">{detailsError}</div>;
    }
    return;
  },
  _getFillTimezoneErrorMessage: function () {
    var fillError = window.LbsAppData.User.attributes.parameters.LBLiteTimeZoneAwareness
      ? this._getFillErrorMessage()
      : this._getFillErrorMessageNoTZA();
    if (this.props.isFillError) {
      return <div className="errors-header">{fillError}</div>;
    }
    return;
  },
  _getDetailsErrorMessage: function () {
    var errorMessageOpening =
      "Due to time zone differences you cannot modify \
            the time of this shift as it would now begin on a different day.";
    var errorMessageEnding;
    if (window.LbsAppData.User.get("is_admin")) {
      errorMessageEnding =
        " To make this change, please delete \
            this assignment and fill on your chosen day with a custom time instead.";
    } else {
      errorMessageEnding = " Please contact your administrator for assistance.";
    }
    return errorMessageOpening + errorMessageEnding;
  },
  _getFillErrorMessage: function () {
    var errorMessageOpening =
      "Due to time zone differences you cannot add \
            this provider to this shift on this day.";
    var errorMessageEnding;
    if (window.LbsAppData.User.get("is_admin")) {
      errorMessageEnding =
        " To make this change, please ensure \
            you are in the proper time zone, use a custom time, or make the change \
            in the editor interface";
    } else {
      errorMessageEnding = " Please use a custom time or contact your \
            administrator for assistance.";
    }
    return errorMessageOpening + errorMessageEnding;
  },

  _getFillErrorMessageNoTZA: function () {
    var errorMessageOpening = "No Structure found on this day.";
    var errorMessageEnding;
    if (window.LbsAppData.User.get("is_admin")) {
      errorMessageEnding =
        " To make this change, please choose \
            a day the assignment exists, use a custom time, add slots as needed \
            or make the change in the editor interface";
    } else {
      errorMessageEnding =
        " Please choose a day the assignment exists or \
             contact you administrator for assistance.";
    }
    return errorMessageOpening + errorMessageEnding;
  },

  /* publics */

  /* render */
  render: function () {
    /* jshint ignore:start */
    var classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      ScheduleEditsExt: true,
      ErrorDialog: true,
    });

    var errors = [];
    _.each(this.props.dialogObject.errors, function (i, index) {
      errors.push(<ErrorDialogItem key={index} error={i} />);
    });

    var closeIcon, bottomControls;
    var submitButton = this.props.isDetailsError || this.props.isFillError ? "Submit" : "Allow";
    if (this.props.submit) {
      bottomControls = (
        <div className="bottom-controls">
          <div className="left-side">
            <span
              className="step"
              onClick={this.touchProxy.bind(this, this.props.close, [])}
              onTouchEnd={this.touchProxy.bind(this, this.props.close, [])}
            >
              <i className="fa fa-close" />
              Cancel
            </span>
          </div>
          <div className="right-side">
            <span
              className="submit"
              onClick={this.touchProxy.bind(this, this._submit, [])}
              onTouchEnd={this.touchProxy.bind(this, this._submit, [])}
            >
              <i className="fa fa-check" data-cy="ErrorSubmitBtn" />
              {submitButton}
            </span>
          </div>
        </div>
      );
    } else {
      closeIcon = (
        <div
          className="close"
          onClick={this.touchProxy.bind(this, this.props.close, [])}
          onTouchEnd={this.touchProxy.bind(this, this.props.close, [])}
        >
          <i className="fa fa-close" />
        </div>
      );
    }
    var detailsError = this._getEditDetailsTimezoneErrorMessage();
    var fillError = this._getFillTimezoneErrorMessage();

    return (
      <div className={classes}>
        <div className="position-reset">
          <div className="header">Your Request Had Errors</div>
          {closeIcon}

          <div className="content">
            <div className="errors-container">
              {detailsError}
              {fillError}
              {errors}
            </div>
          </div>
          {bottomControls}
        </div>
      </div>
    );

    /*

					<div className='close' onClick={this.touchProxy.bind(this, this.props.close, [])} 
						onTouchEnd={this.touchProxy.bind(this, this.props.close, [])}>

						<i className='fa fa-close' />
					</div>
		*/
  },
});

module.exports = ErrorDialog;
