const Backbone = require("backbone");
const _ = require("lodash");

const ApiConfig = require("_lib/data/ApiConfig.js");
const Slot = require("./../models/Slot.js");
const injectInstrumentation = require("@/_lib/utils/instrumentationClient").default;

const SlotCollection = Backbone.Collection.extend({
  model: Slot,

  initialize: function () {
    // listen to focused date changes
    this.listenTo(window.LbsAppData.DateManager, "change:dates", this._fetchSlots);
  },

  url: function () {
    const queryParams = {};

    queryParams["listed"] = true;
    queryParams["cushion"] = 7;

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    return (
      ApiConfig.getPrefix() +
      "/schedule/range/" +
      window.LbsAppData.AppContext.attributes.displayedSchedule.id +
      "?" +
      _.map(cleanQueryParams, function (value, key) {
        return key + "=" + value;
      }).join("&")
    );
  },

  parse: function (response) {
    if (!response.data) return [];

    const ret = [];

    for (let i = 0; i < response.data.length; i++) {
      ret.push(...response.data[i].slots);
    }

    return ret;
  },

  _getSlotComparatorValue: function (object, keyword) {
    if (keyword === "assign_order") {
      return object.assignmentObj ? object.assignmentObj.attributes.order : "";
    } else if (keyword === "personnel_order") {
      return object.personnelObj ? object.personnelObj.attributes.order : "";
    } else if (keyword === "personnel_last_name") {
      return object.personnelObj ? object.personnelObj.attributes.last_name.toLowerCase() : "";
    } else if (keyword === "personnel_display_name") {
      return object.personnelObj ? object.personnelObj.attributes.display_name.toLowerCase() : "";
    } else if (keyword === "assign_display_name") {
      return object.assignmentObj ? object.assignmentObj.attributes.display_name.toLowerCase() : "";
    } else {
      // return the slot attribute
      return object.attributes[keyword];
    }
  },
  _fetchSlots: function () {

    injectInstrumentation();

    this.fetch({
      success: function (collection) {
        collection.trigger("fetched");
      },
    });
  },
  _calculateColors: function () {
    this.each(function (m) {
      m._calculateColors();
    });
  },
});

module.exports = SlotCollection;
