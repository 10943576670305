import React from "react";

import { ScheduleData, SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { ViewData } from "@/viewer/types/viewdata";
import { getWeekRange } from "@/viewer/ui/modules/common/helpers/dates";

import classnames from "@/_lib/utils/classnames";
import PrintContainer, { PrintType } from "@/_lib/ui/modules/print/PrintContainer";
import renderStandardRows from "@/viewer/ui/modules/grid/standard/renderers/renderStandardRows";
import { DepartmentsById } from "@/viewer/types/domain/department";

interface Props {
  endDate: Date;
  printRef: React.MutableRefObject<null>;
  printType: PrintType;
  scheduleData: ScheduleData;
  settings: SettingsContext;
  startDate: Date;
  ui: UIContext;
  viewData: ViewData;
  departmentsById: DepartmentsById;
}

const StandardPrintView = (props: Props): JSX.Element => {
  const { endDate, printRef, printType, scheduleData, settings, startDate, ui, viewData, departmentsById } = props;

  const weekRange = getWeekRange(settings, ui, startDate, endDate);
  const { rows } = renderStandardRows(
    settings,
    ui,
    weekRange,
    viewData,
    scheduleData,
    [startDate, endDate],
    departmentsById,
    true
  );

  const className = classnames("StandardContainer", {
    weekly: false,
    noWeekends: settings.hideWeekends,
  });

  return (
    <PrintContainer ref={printRef} showOutsidePrintPreview={false} printType={printType}>
      <div className={className}>
        <div className={"WeekContainer"}>
          {rows.map((row) => (
            <div key={row.key} className={row.wrapperClassName}>
              {row.element}
            </div>
          ))}
        </div>
      </div>
    </PrintContainer>
  );
};

export default StandardPrintView;
