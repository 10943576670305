"use strict";

var React = require("react/addons");
var $ = require("jquery");

var ActionBarIcon = React.createClass({
  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {
    // if this is the save icon, get the status of the current schedule
    if (this.props.icon == "fa-floppy-o") {
      // get the initial schedule state ARS - cant...backbone sync callback hasn't happened yet it'll return 401
      // window.LbsAppData.AppContext.fetchScheduleStatus();
      // refresh event
      window.LbsAppData.AppContext.on("refreshedScheduleStatus", this.forceUpdate.bind(this, null), this);
    }
  },
  componentWillUnmount: function () {
    window.LbsAppData.AppContext.off(null, null, this);
  },
  componentWillUpdate: function () {
    //window.LbsAppData.ToolBox.off(null, null, this);
  },
  componentDidUpdate: function () {
    //window.LbsAppData.ToolBox.on('change:currentTool', this.forceUpdate.bind(this, null), this);
  },

  /* publics */

  /* privates */

  /* render */
  render: function () {
    /* jshint ignore:start */

    // if this is our save button, make it red if there are pending changes
    //var needsAttention = false;

    var status = undefined;
    var tooltipMessage = this.props.tooltip;
    if (this.props.icon == "fa-floppy-o" && this.props.appName == "editor") {
      // get the amount of changes to show as well
      var count = window.LbsAppData.AppContext.get("displayedScheduleStatus").get("count");
      if (count > 0) {
        tooltipMessage = "save " + count + (count > 1 ? " changes" : " change");
        status = <span className="status">{count}</span>;
      }
    }

    var classes = React.addons.classSet({
      ToolBoxIcon: true,
      disabled: this.props.isDisabled === undefined ? false : this.props.isDisabled(),
      attention: count > 0,
    });

    var component;
    if (this.props.url) {
      // download link
      return (
        <a className={classes} href={this.props.url} download={this.props.filename} onClick={this.props.onClick}>
          <i className={"fa fa-fw " + this.props.icon} />

          {status}

          <div className="tool-tip">
            <div className="triangle"></div>

            <div className="message">{tooltipMessage}</div>
          </div>
        </a>
      );
    } else {
      return (
        <div className={classes} onClick={this.props.onClick}>
          <i className={"fa fa-fw " + this.props.icon} />

          {status}

          <div className="tool-tip">
            <div className="triangle"></div>

            <div className="message">{tooltipMessage}</div>
          </div>
        </div>
      );
    }

    /*
		<ToolBoxMenu currentTool={currentTool} />
		{currentToolComponent}
			<div className={classes} onClick={this.props.onClick}>
				<i className={'fa fa-fw ' + this.props.icon} />

				{status}

				<div className="tool-tip">
					<div className="triangle"></div>

					<div className="message">
						{tooltipMessage}
					</div>
				</div>
			</div>

		*/
  },
});

module.exports = ActionBarIcon;
