export enum LDFlagEnums {
  AvailabilityWindowShowDropdown = "availabilityWindowShowDropdown",
  Lv4729NotificationEditButtonShowNotificationSettingsEditButton = "lv4729NotificationEditButtonShowNotificationSettingsEditButton",
  Lv852DeprecateFiddlehead = "lv852DeprecateFiddlehead",
  PushNotificationUseZaqarForNotifications = "pushNotificationUseZaqarForNotifications",
  ScheduleWsRemoveScheduleWs = "scheduleWsRemoveScheduleWs",
  SecureMessageControllerRemoveSecureMessageController = "secureMessageControllerRemoveSecureMessageController",
  SessionServiceEnabled = "sessionServiceEnabled",
  VrmUnity = "vrmUnity",
  Lv5467ActivityFeedShowActivityFeedButton = "lv5467ActivityFeedShowActivityFeedButton",
  Lv6192ShowAssignmentCallOrder = "lv6192Showassignmentcallorder",
  Lv6488ShowAddDemandInReplace = "lv6488ShowAddDemandInReplace",
  Lv3084PubnubShadowReports = "lv3084PubnubShadowReports",
  Lv6823TalliesInRequestCombinedViews = "lv6823TalliesInRequestCombinedViews",
  Lv7029MoveEditButtonsIntoSlotDetailsWindow = "lv7029MoveEditButtonsIntoSlotDetailsWindow",
  LV7216FilterByGroup = "lv7216FilterByGroup",
  Lv7030MoveToolSingle = "lv7030MoveToolSingle",
  Lv9385ToggleViewerApiLbapi = "lv-9385-toggle-viewer-api-lb-api",
  Lv9217DesktopBranding = "LV-9217-Desktop-Branding",
  Lv7392ShowPendingRequestsCheckboxOnTemplates = "lv7392ShowPendingRequestsCheckboxOnTemplates",
}
