"use strict";

// scss requires
require("./_styles.scss");

// dependencies
const React = require("react/addons");
let TimeHelper;

const TimeRangeSlider = React.createClass({
  getInitialState: function () {
    TimeHelper = window.LbsAppData.Helpers.Time;

    const totalMinutes = TimeHelper.millisecondsToMinutes(this.props.endDate - this.props.startDate);

    const leftDefaultSplitDate =
      this.props.leftSplitDate || TimeHelper.addMinutesToDate(totalMinutes * 0.25, this.props.startDate);
    const rightDefaultSplitDate =
      this.props.rightSplitDate || TimeHelper.addMinutesToDate(totalMinutes * 0.75, this.props.startDate);
    const singleDefaultSplitDate =
      this.props.singleSplitDate || TimeHelper.addMinutesToDate(totalMinutes * 0.5, this.props.startDate);

    return {
      leftSplitDate: leftDefaultSplitDate,
      rightSplitDate: rightDefaultSplitDate,
      singleSplitDate: singleDefaultSplitDate,
      leftSplitValue: this._getMinutesFromStartDate(leftDefaultSplitDate),
      rightSplitValue: this._getMinutesFromStartDate(rightDefaultSplitDate),
      singleSplitValue: this._getMinutesFromStartDate(singleDefaultSplitDate),
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      step: this.props.step,
      maxValue: totalMinutes,
      leftSliderZIndex: 120,
      rightSliderZIndex: 121,
    };
  },

  componentDidUpdate: function (prevProps) {
    if (this.props.leftSplitDate !== prevProps.leftSplitDate) {
      this.setState({
        leftSplitValue: this._getMinutesFromStartDate(this.props.leftSplitDate),
        leftSplitDate: this.props.leftSplitDate,
      });
    }

    if (this.props.rightSplitDate !== prevProps.rightSplitDate) {
      this.setState({
        rightSplitValue: this._getMinutesFromStartDate(this.props.rightSplitDate),
        rightSplitDate: this.props.rightSplitDate,
      });
    }

    if (this.props.singleSplitDate !== prevProps.singleSplitDate) {
      this.setState({
        singleSplitValue: this._getMinutesFromStartDate(this.props.singleSplitDate),
        singleSplitDate: this.props.singleSplitDate,
      });
    }
  },

  onSliderUpdated: function (leftDate, rightDate, singleDate) {
    let callbackFunc = this.props.onChange;
    if (typeof callbackFunc === "function") {
      callbackFunc({
        leftSplitDate: leftDate,
        rightSplitDate: rightDate,
        singleSplitDate: singleDate,
      });
    }
  },

  _updateLeftSplitValue: function (event) {
    let newValue = parseInt(event.target.value);

    if (newValue >= this.state.rightSplitValue) {
      newValue = this.state.rightSplitValue - this.state.step;
    }

    // if 'canIncludeStartOrEndDates' is true we will allow one of the sliders to go all the way to the end, but not both
    if (this.props.canIncludeStartOrEndDates && this.state.rightSplitValue !== this.state.maxValue) {
      if (newValue < 0) {
        newValue = 0;
      }
    } else if (newValue < 1) {
      newValue = 1;
    }

    let newDate = TimeHelper.addMinutesToDate(newValue, this.state.startDate);

    this.setState({
      leftSplitValue: newValue,
      leftSplitDate: newDate,
    });

    this.onSliderUpdated(newDate, this.state.rightSplitDate, this.state.singleSplitDate);
  },

  _updateRightSplitValue: function (event) {
    let newValue = parseInt(event.target.value);

    if (newValue <= this.state.leftSplitValue) {
      newValue = this.state.leftSplitValue + this.state.step;
    }

    // if 'canIncludeStartOrEndDates' is true we will allow one of the sliders to go all the way to the end, but not both
    if (this.props.canIncludeStartOrEndDates && this.state.leftSplitValue !== 0) {
      if (newValue > this.state.maxValue) {
        newValue = this.state.maxValue;
      }
    } else if (newValue >= this.state.maxValue) {
      newValue = this.state.maxValue - 1;
    }

    let newDate = TimeHelper.addMinutesToDate(newValue, this.state.startDate);

    this.setState({
      rightSplitValue: newValue,
      rightSplitDate: newDate,
    });

    this.onSliderUpdated(this.state.leftSplitDate, newDate, this.state.singleSplitDate);
  },

  _updateSingleSplitValue: function (event) {
    let newValue = parseInt(event.target.value);

    if (newValue < 1) {
      newValue = 1;
    }

    if (newValue >= this.state.maxValue) {
      newValue = this.state.maxValue - 1;
    }

    let newDate = TimeHelper.addMinutesToDate(newValue, this.state.startDate);

    this.setState({
      singleSplitValue: newValue,
      singleSplitDate: newDate,
    });

    this.onSliderUpdated(this.state.leftSplitDate, this.state.rightSplitDate, newDate);
  },

  // Works around an IE issue where only one range selector is on top by changing the display order based on the mouse position.
  // So which ever slider handle is closer to the mouse will be put on top to recieve any clicks (N/A for single split)
  _findClosestInputRange: function (event) {
    const maxValue = this.state.maxValue;
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;

    const mouseAtValue = (x / bounds.width) * maxValue;

    const leftDistanceFromMouse = Math.abs(this.state.leftSplitValue - mouseAtValue);
    const rightDistanceFromMouse = Math.abs(this.state.rightSplitValue - mouseAtValue);

    // Put the closest slider on top
    if (leftDistanceFromMouse < rightDistanceFromMouse) {
      this.setState({
        leftSliderZIndex: 121,
        rightSliderZIndex: 120,
      });
    } else {
      this.setState({
        leftSliderZIndex: 120,
        rightSliderZIndex: 121,
      });
    }
  },

  _getPercentageOfMaxValue: function (value) {
    return (value * 100) / this.state.maxValue + "%";
  },

  _getMinutesFromStartDate: function (date) {
    return TimeHelper.millisecondsToMinutes(date - this.props.startDate);
  },

  // Get the value label(s) based on if we are only doing a single split
  _getValueLabels: function () {
    if (this.props.isSingleSplit) {
      return (
        <span
          className="slider-single-label"
          style={{
            left: this._getPercentageOfMaxValue(this.state.singleSplitValue),
          }}
        >
          {TimeHelper.formatTimeAsPreferred(this.state.singleSplitDate)}
        </span>
      );
    } else {
      return [
        <span
          className="slider-left-label"
          style={{
            left: this._getPercentageOfMaxValue(this.state.leftSplitValue),
          }}
          key={0}
        >
          {TimeHelper.formatTimeAsPreferred(this.state.leftSplitDate)}
        </span>,
        <span
          className="slider-right-label"
          style={{
            left: this._getPercentageOfMaxValue(this.state.rightSplitValue),
          }}
          key={1}
        >
          {TimeHelper.formatTimeAsPreferred(this.state.rightSplitDate)}
        </span>,
      ];
    }
  },

  _getSliderTicks: function () {
    let sliderTicks = [];
    const step = this.state.step;

    // Starting Tick
    sliderTicks.push(<span className="slider-grid-pol" style={{ left: "0%" }} key={0} />);

    // Middle Ticks
    for (let i = step; i < this.state.maxValue; i += step) {
      let size = " small";

      // Every 4th tick will be a large tick
      if (i % (step * 4) == 0) {
        size = "";
      }

      sliderTicks.push(
        <span className={"slider-grid-pol" + size} style={{ left: this._getPercentageOfMaxValue(i) }} key={i} />
      );
    }

    // Ending Tick
    sliderTicks.push(<span className="slider-grid-pol" style={{ left: "100%" }} key={this.state.maxValue} />);

    return sliderTicks;
  },

  // Get the input slider(s) based on if we are only doing a single split
  _getInputSliders: function () {
    if (this.props.isSingleSplit) {
      return (
        <input // Single Split Slider
          value={this.state.singleSplitValue}
          min={0}
          max={this.state.maxValue}
          step={this.state.step}
          type="range"
          onChange={this._updateSingleSplitValue}
          onMouseUp={this._updateSingleSplitValue} // <-Only here because IE isn't sending onChange events
        />
      );
    } else {
      return (
        <span onMouseMove={this._findClosestInputRange} onFocus={this._findClosestInputRange}>
          <input // Left Split Slider
            value={this.state.leftSplitValue}
            min={0}
            max={this.state.maxValue}
            step={this.state.step}
            type="range"
            onChange={this._updateLeftSplitValue}
            onMouseUp={this._updateLeftSplitValue} // <-Only here because IE isn't sending onChange events
            hidden={this.state.isSingleSplit}
            style={{
              zIndex: this.state.leftSliderZIndex,
            }}
          />
          <input // Right Split Slider
            value={this.state.rightSplitValue}
            min={0}
            max={this.state.maxValue}
            step={this.state.step}
            type="range"
            onChange={this._updateRightSplitValue}
            onMouseUp={this._updateRightSplitValue} // <-Only here because IE isn't sending onChange events
            style={{
              zIndex: this.state.rightSliderZIndex,
            }}
          />
        </span>
      );
    }
  },

  // Gets the colored bar that marks the range the user has selected
  _getSliderBar: function () {
    let leftPercent;
    let widthPercent;

    if (this.props.isSingleSplit) {
      leftPercent = "0%";
      widthPercent = this._getPercentageOfMaxValue(this.state.singleSplitValue);
    } else {
      leftPercent = this._getPercentageOfMaxValue(this.state.leftSplitValue);
      widthPercent = this._getPercentageOfMaxValue(this.state.rightSplitValue - this.state.leftSplitValue);
    }

    return (
      <span
        className={"slider-bar " + (this.state.isSingleSplit ? "single-split" : "multi-split")}
        style={{
          left: leftPercent,
          width: widthPercent,
        }}
      />
    );
  },

  render: function () {
    const classes = React.addons.classSet({
      TimeRangeSlider: true,
    });

    let valueLabels = this._getValueLabels();

    let sliderTicks = this._getSliderTicks();

    let inputSliders = this._getInputSliders();

    let sliderBar = this._getSliderBar();

    return (
      <section className={classes}>
        <span className="slider">
          <span>
            <span className="slider-line" tabIndex="0" />

            <span // Start Time label
              className="slider-min-label"
            >
              {TimeHelper.formatTimeAsPreferred(this.state.startDate)}
            </span>

            <span // End Time label
              className="slider-max-label"
            >
              {TimeHelper.formatTimeAsPreferred(this.state.endDate)}
            </span>

            {valueLabels}
          </span>

          <span className="slider-grid">{sliderTicks}</span>

          {sliderBar}

          {inputSliders}
        </span>
      </section>
    );
  },
});

module.exports = TimeRangeSlider;
