import React from "react";

import { BASE_FONT_SIZE_PERCENTAGE, LEFT_COLUMN_WIDTH_CONDENSED_PX, LEFT_COLUMN_WIDTH_PX } from "../constants";
import { IColumnPrintingBaseProps, LeftColumnProps } from "./printColumnFactory";

import PrintColumnRenderLeftColumn from "./printColumnRenderLeftColumn";
import PrintColumnTallyHeaders from "./printColumnTallyHeaders";

import classnames from "@/_lib/utils/classnames";

type Props = IColumnPrintingBaseProps & LeftColumnProps;

import "./_styles.scss";

const PrintColumnLeftPrintColumn = (props: Props): JSX.Element => {
  const {
    columnRowHeightMap,
    leftColumnData,
    leftHandColumnId,
    reportData,
    reportResult,
    requestData,
    scale,
    settings,
    slotData,
    tallies,
    title,
    ui,
    viewData,
  } = props;

  if (!leftColumnData) return <></>;

  const { filters } = viewData;
  const { condenseColumnView } = settings;
  const leftColumnClassNames = classnames("header__left-column", {
    "header__left-column--condensed": condenseColumnView,
  });
  const cellClassNames = classnames("fixed-cell", {
    "fixed-cell--condensed": condenseColumnView,
  });

  const tallyProps = { cellClassNames, leftHandColumnId, reportData, reportResult, tallies };
  const leftColumnProps = {
    columnRowHeightMap,
    filters,
    leftColumnData,
    leftHandColumnId,
    requestData,
    settings,
    slotData,
    ui,
  };
  const columnWidth = (condenseColumnView ? LEFT_COLUMN_WIDTH_CONDENSED_PX : LEFT_COLUMN_WIDTH_PX) * scale;

  return (
    <>
      <div
        className="left-column-items"
        key={`leftHandColumnItems_${leftHandColumnId}`}
        style={{
          width: `${columnWidth}px`,
          fontSize: `${BASE_FONT_SIZE_PERCENTAGE * scale}% !important`,
        }}
      >
        <div className={leftColumnClassNames}>{title}</div>
        <PrintColumnTallyHeaders {...tallyProps} />
        <PrintColumnRenderLeftColumn {...leftColumnProps} />
      </div>
    </>
  );
};

export default PrintColumnLeftPrintColumn;
