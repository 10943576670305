"use strict";

// scss requires
//require('./_styles.scss');
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");
// components
//var RequestsDialogListOptions = require('./RequestsDialogListOptions.jsx');
// mixins
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var RequestsDialogListTabs = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {
      activeControl: undefined,
    };
  },
  componentDidMount: function () {},
  componentWillUnmount: function () {},
  //componentWillUpdate: function() {
  //	this._unregisterBackboneEvents();
  //},
  //componentDidUpdate: function() {
  //	this._registerBackboneEvents();
  //},

  /* publics */
  update: function (options) {
    this.props.update(options);
    this.setState({ activeControl: undefined });
  },

  /* privates */
  _toggleControls: function (e) {
    if (this.state.activeControl != "controls") {
      this.setState({ activeControl: "controls" });
    } else {
      this.setState({ activeControl: undefined });
    }

    //if (!this.refs.Options.state.active) {
    //	this.refs.Options.setState({active: true});
    //} else {
    //	this.refs.Options.setState({active: false});
    //}
  },

  _selectTab: function (tab) {
    this.props.selectTab(tab);
  },
  _handleSearch: function (e, val) {
    this.props.handleSearch(e, val);
  },

  _decrementMonth: function () {
    this.props.decrementMonth();
  },
  _incrementMonth: function () {
    this.props.incrementMonth();
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var date = this.props.startDate.format("MMM YYYY");

    var classes = React.addons.classSet({
      tabs: true,
    });

    return (
      <div className={classes}>
        <div
          className={this.props.activeTabs.indexOf("requests") > -1 ? "tab active" : "tab"}
          onClick={this.touchProxy.bind(this, this._selectTab, ["requests"])}
          onTouchEnd={this.touchProxy.bind(this, this._selectTab, ["requests"])}
        >
          Requests
        </div>
        <div
          className={this.props.activeTabs.indexOf("swaps") > -1 ? "tab active" : "tab"}
          onClick={this.touchProxy.bind(this, this._selectTab, ["swaps"])}
          onTouchEnd={this.touchProxy.bind(this, this._selectTab, ["swaps"])}
        >
          Swaps
        </div>
        <div className="date-control">
          <i className="fa fa-angle-left" onClick={this._decrementMonth} />
          <span className="month">{date}</span>
          <i className="fa fa-angle-right" onClick={this._incrementMonth} />
        </div>
      </div>
    );

    /*
						<div className='mode'>
					<i className='fa fa-ellipsis-v' onClick={this._toggleControls.bind(this, 'controls')} />

					<RequestsDialogListOptions
						ref='Options'
						active={this.state.activeControl == 'controls'}
						startDate={moment(this.props.startDate)}
						endDate={moment(this.props.endDate)}
						filter={this.props.filter}
						sort={this.props.sort}
						update={this.update} />
				</div>


				<div className='sub-header'>
					<div className='filter'>
						<i className='fa fa-cog' onClick={this._toggleControls.bind(this, 'controls')} />

						<RequestsDialogListOptions
							ref='Options'
							active={this.state.activeControl == 'controls'}
							startDate={moment(this.props.startDate)}
							endDate={moment(this.props.endDate)}
							filter={this.props.filter}
							sort={this.props.sort}
							update={this.update} />
					</div>
					<div className='search'>
						<i className='fa fa-search' />
						<input ref='Search' type='text' value={this.props.filteredText} onChange={this._handleSearch} />
					</div>
				</div>
		*/
  },
});

module.exports = RequestsDialogListTabs;
