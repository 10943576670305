var ReactGA = require("react-ga4").default;

function initialize(trackerId) {
  ReactGA.initialize(trackerId);
}

function ga(eventType, eventParams) {
  // React.GA ga function shims UA calls
  // and translates them to GA4, so we
  // don't need to do anything ourselves
  ReactGA.ga(eventType, eventParams);
}

module.exports = {
  initialize,
  ga,
};
