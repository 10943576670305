import React from "react";

import { PersonnelOrAssignment, RequestsByDateByName, SlotsByDateByColumn } from "@/viewer/ui/modules/common/types";
import { FilterContext, SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { columnRowHeightMap } from "../ColumnPrintView";

import { getPersonnelOrAssignmentId, isVisible } from "@/viewer/utils/domain/perssignment";

import PrintColumnRenderRequestHeader from "./printColumnRenderRequestHeader";
import PrintColumnRequestGroupHeader from "./printColumnRequestGroupHeader";
import PrintColumnRenderSlotHeader from "./printColumnRenderSlotHeader";

import { getCellHeight, ZERO_HEIGHT_CELL_STRING } from "../constants";

import "./_styles.scss";

interface Props {
  columnRowHeightMap: columnRowHeightMap;
  filters: FilterContext;
  leftColumnData: PersonnelOrAssignment[];
  leftHandColumnId: number;
  requestData: RequestsByDateByName;
  settings: SettingsContext;
  slotData: SlotsByDateByColumn;
  ui: UIContext;
}

const PrintColumnRenderLeftColumn = (props: Props): JSX.Element => {
  const { columnRowHeightMap, filters, leftColumnData, leftHandColumnId, requestData, settings, slotData, ui } = props;
  const { forceScheduleGroup, forceRequestsGroup, hideBlankRows, condenseColumnView, viewDataType } = settings;
  const slotItems: JSX.Element[] = [];
  const requestItems: JSX.Element[] = [];
  const cellHeight = getCellHeight(ui);

  let visibleRequestCount = 0;
  let visibleSlotCount = 0;

  const renderSlotHeader = (
    leftColId: string,
    leftColumn: PersonnelOrAssignment,
    visibleItemCount: number
  ): JSX.Element | null => {
    const { slot } = columnRowHeightMap.get(leftColId) ?? { slot: null };
    const displayBlankSlotRow = !hideBlankRows && forceScheduleGroup;
    const rowIndex = slot?.index as number;
    let height = slot?.rowHeights[leftHandColumnId];

    // If there is no height, either undefined or
    // default of 0px as assigned from the heightmap
    // and we are showing blankrows, assign the cell
    // height as defined from the getCellHeight
    // helper function
    if (!height || height === ZERO_HEIGHT_CELL_STRING) {
      if (!displayBlankSlotRow) return null;

      height = cellHeight;
    }

    const slotProps = {
      columnRowHeightMap,
      condenseColumnView,
      forceScheduleGroup,
      hideBlankRows,
      leftColId,
      leftColumn,
      leftHandColumnId,
      settings,
      slotData,
      visibleItemCount,
      height,
      rowIndex,
    };

    return <PrintColumnRenderSlotHeader {...slotProps} key={`slot-header-${leftColId}-${leftHandColumnId}`} />;
  };

  const renderRequestHeader = (
    leftColId: string,
    leftColumn: PersonnelOrAssignment,
    visibleItemCount: number
  ): JSX.Element | null => {
    const { request } = columnRowHeightMap.get(leftColId) ?? { request: null };
    const displayBlankRequestRow = !hideBlankRows && forceRequestsGroup;
    const rowIndex = request?.index as number;
    let height = request?.rowHeights[leftHandColumnId];

    // If there is no height, either undefined or
    // default of 0px as assigned from the heightmap
    // and we are showing blankrows, assign the cell
    // height as defined from the getCellHeight
    // helper function
    if (!height || height === ZERO_HEIGHT_CELL_STRING) {
      if (!displayBlankRequestRow) return null;

      height = cellHeight;
    }

    const requestProps = {
      columnRowHeightMap,
      condenseColumnView,
      forceRequestsGroup,
      hideBlankRows,
      leftColId,
      leftColumn,
      leftHandColumnId,
      requestData,
      settings,
      visibleItemCount,
      height,
      rowIndex,
    };

    return <PrintColumnRenderRequestHeader {...requestProps} key={`request-header-${leftColId}-${leftHandColumnId}`} />;
  };

  leftColumnData.forEach((leftColumn) => {
    if (!isVisible(leftColumn, ui, filters)) return;

    const leftColId = getPersonnelOrAssignmentId(leftColumn);

    if (viewDataType !== "request") {
      const slotItem = renderSlotHeader(leftColId, leftColumn, visibleSlotCount);

      if (slotItem) {
        slotItems.push(slotItem);
        visibleSlotCount++;
      }
    }

    if (viewDataType === "request" || viewDataType === "combined") {
      const requestItem = renderRequestHeader(leftColId, leftColumn, visibleRequestCount);

      if (requestItem) {
        requestItems.push(requestItem);
        visibleRequestCount++;
      }
    }
  });

  return (
    <>
      {slotItems}

      {forceRequestsGroup || requestItems.length ? (
        <>
          {<PrintColumnRequestGroupHeader leftHandHeader />}
          {requestItems}
        </>
      ) : null}
    </>
  );
};

export default PrintColumnRenderLeftColumn;
