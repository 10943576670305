"use strict";

var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");

var DatePickerControls = React.createClass({
  /* privates */
  _handleArrowLeft: function () {
    this.props.decrement();
  },
  _handleArrowRight: function () {
    this.props.increment();
  },

  /* publics */

  /* lifecycle methods */

  render: function () {
    /* jshint ignore:start */

    var textComponent;
    if (this.props.mode == "monthly") {
      textComponent = <div className="text">{this.props.date.format("YYYY")}</div>;
    } else {
      //var blah =
      //	<div className='month-text left'>{this.props.date.clone().subtract(1, 'months').format('MMMM YYYY')}</div>
      //var blah2 =
      //	<div className='month-text right'>{this.props.date.clone().add(1, 'months').format('MMMM YYYY')}</div>

      textComponent = <div className="text">{this.props.date.format("MMM YYYY")}</div>;
    }

    var classes = React.addons.classSet({
      DatePickerControls: true,
    });

    // excluding TouchEnd here as it causes the 'double click' issue
    return (
      <div className={classes}>
        <i className="fa fa-angle-left" data-cy="datePickerPrevMonth" onClick={this._handleArrowLeft} />
        {textComponent}
        <i className="fa fa-angle-right" data-cy="datePickerNextMonth" onClick={this._handleArrowRight} />
      </div>
    );

    /* jshint ignore:end */
  },
});

module.exports = DatePickerControls;
