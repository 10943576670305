/*
 * Slot model that doesn't depend on window object bindings */
const Backbone = require("backbone");
const ApiConfig = require("_lib/data/ApiConfig.js");

const NakedSlot = Backbone.Model.extend({
  idAttribute: "slot_id",

  defaults: {},

  initialize: function (attributes, options) {
    // convert time to user time zone
    this.attributes.slot_date_original = this.attributes.slot_date;
    this.attributes.slot_date = window.LbsAppData.Helpers.Time.timezoneConverter(this.attributes.start_time).format(
      "YYYY-MM-DD"
    );
    this.attributes.start_time_original = this.attributes.start_time;
    this.attributes.start_time = window.LbsAppData.Helpers.Time.timezoneConverter(this.attributes.start_time).format(
      "YYYY-MM-DDTHH:mm:ss"
    );
    this.attributes.stop_time_original = this.attributes.stop_time;
    this.attributes.stop_time = window.LbsAppData.Helpers.Time.timezoneConverter(this.attributes.stop_time).format(
      "YYYY-MM-DDTHH:mm:ss"
    );
    if (this.attributes.pending_info) {
      this.attributes.pending_info.start_time_original = this.attributes.pending_info.start_time;
      this.attributes.pending_info.start_time = window.LbsAppData.Helpers.Time.timezoneConverter(
        this.attributes.pending_info.start_time
      ).format("YYYY-MM-DDTHH:mm:ss");
      this.attributes.pending_info.stop_time_original = this.attributes.pending_info.stop_time;
      this.attributes.pending_info.stop_time = window.LbsAppData.Helpers.Time.timezoneConverter(
        this.attributes.pending_info.stop_time
      ).format("YYYY-MM-DDTHH:mm:ss");
    }
  },

  urlRoot: function () {
    return ApiConfig.getPrefix() + "/schedule/details";
  },
});

module.exports = NakedSlot;
