"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var moment = require("moment");

// collections
// mixins
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
var SwapMixin = require("_lib/ui/mixins/SwapMixin.jsx");

var PreSwapFinalizeDialog = React.createClass({
  mixins: [TouchMixin, SwapMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {
      step: 1,
      initStep: 1,
    };
  },
  componentDidMount: function () {
    // send a tracking event
    if (!this.props.inline) {
      window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Preswap: Finalize");
    }
  },

  /* privates */
  _getTotalSteps: function () {
    return 2;
  },
  /* publics */
  stepForward: function (e) {
    // make sure we satisfy the requirements for each step
    var satisified = false;
    switch (this.state.step) {
      case 1:
        if (this.props.getData("selectedPersonnel").length) {
          satisified = true;
        }
        break;
      default:
        break;
    }

    if (satisified) {
      // do something depending on the step (after the state update)
      var newStep = this.state.step + 1;
      this.setState({ step: newStep }, function () {
        switch (newStep) {
          default:
            break;
        }
      });

      // send a tracking event
      window.LbsAppData.Helpers.Analytics.sendEvent("Finalize: Step", "Forward");
    } else {
      var message;
      if (this.state.step == 1) {
        message = "Please select a swapee";
      }

      window.LbsAppData.AppContext.openDialog({
        type: "message",
        message: message,
        timer: 1500,
      });
    }
  },
  stepBackward: function (e) {
    // if moving to the fetch slot step (3) then fetch slots again
    var newStep = this.state.step - 1;
    this.setState({ step: newStep }, function () {
      switch (newStep) {
        default:
          break;
      }
    });
    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Finalize: Step", "Backward");
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      SwapFinalize: true,
      finalize: true,
    });

    var content;
    if (!this.props.inline) {
      content = (
        <div className={classes} data-cy="PSFWindowPrompt">
          <div className="position-reset">
            <div
              className="close"
              onClick={this.touchProxy.bind(this, this.props.close, [])}
              onTouchEnd={this.touchProxy.bind(this, this.props.close, [])}
            >
              <i className="fa fa-close" />
            </div>

            <div className="header-container">
              <div className="header">
                <div className="mobile-section-nav">
                  <div className="mobile-section-nav-tab active">Finalize</div>
                </div>
              </div>
            </div>

            <div className="section-container">
              <div className={"section _" + this.state.step}>
                {this._renderStep1()}
                {this._renderStep2()}
              </div>
            </div>

            <div className="bottom-controls">
              <div className="position-reset">
                <div className="left-side">{this._getBottomLeftControl(this._getTotalSteps())}</div>
                <div className="right-side">{this._getBottomRightControl(this._getTotalSteps())}</div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      content = this._renderInline();
    }

    return content;
  },
  /* rendering functions */
  _renderStep1: function () {
    //var candidateList = this.props.widgetProxy('listSwopCandidates');
    var personnelSelectable = this.props.widgetProxy("selectPersonnel");

    return (
      <div className="content">
        <div className="editables">{personnelSelectable}</div>
      </div>
    );
  },
  _renderStep2: function () {
    var swapSentence = this.props.widgetProxy("sentenceSwap");
    var noteElement = this.props.widgetProxy("addNote");

    return (
      <div className="content">
        <div className="details">
          <div className="sentence-container">{swapSentence}</div>
        </div>
        <div className="editables">{noteElement}</div>
      </div>
    );
  },
  _renderInline: function () {
    var personnelSelectable = this.props.widgetProxy("selectPersonnel");
    var noteElement = this.props.widgetProxy("addNote");

    return (
      <div className="inline-container">
        <div className="editables">
          {personnelSelectable}
          <div className="spacer bordered"></div>
          {noteElement}
        </div>
      </div>
    );
  },
});

module.exports = PreSwapFinalizeDialog;
