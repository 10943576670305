import React from "react";

interface Props {
  label: string;
  min: number;
  max: number;
  value: number;
  changeHandler: (value: number) => void;
  defaultValue?: number;
  step?: number;
  containerClassnames?: string;
  unit?: string;
  valueDisplayMultiplier?: number;
}

const Slider = (props: Props): JSX.Element => {
  const {
    label,
    min,
    max,
    defaultValue,
    step,
    containerClassnames,
    changeHandler,
    unit,
    valueDisplayMultiplier = 1,
    value,
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.bubbles = false;

    changeHandler(e.currentTarget.valueAsNumber);
  };

  const displayValue = value * valueDisplayMultiplier;

  return (
    <div className={containerClassnames} style={{ display: "flex", flexDirection: "row" }}>
      <input
        className="mr-5"
        type="range"
        min={min}
        max={max}
        step={step}
        onChange={handleChange}
        defaultValue={defaultValue ?? max}
      />
      <span>{`${label}${displayValue.toFixed(0)}${unit}`}</span>
    </div>
  );
};

export default Slider;
