import { Assignment } from "@/viewer/types/domain/assignment";
import { Personnel } from "@/viewer/types/domain/personnel";
import { DataContext } from "@/viewer/ui/modules/common/types/context";

export const makeDataContext = (assignments: Assignment[], personnel: Personnel[]): DataContext => {
  const data: DataContext = {
    personnelById: {},
    assignmentsById: {},
  };
  personnel.forEach((p) => {
    data.personnelById[p.empId] = p;
  });
  assignments.forEach((a) => {
    data.assignmentsById[a.assignStructureId] = a;
  });

  return data;
};
