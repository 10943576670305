import { Model } from "backbone";
import _ from "underscore";

import { post } from "@/_lib/utils/fetch";
import { Report, StaghornReportResponse } from "@/viewer/types/domain/report";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const StaghornConfig = require("_lib/data/StaghornConfig.js");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ReportModel = require("@/viewer/data/models/Report.js");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const TallyCollection = require("_lib/data/collections/NakedTallyCollection.js");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const PersonnelCollection = require("_lib/data/collections/NakedPersonnelCollection.js");

const postReport = async (report: Report): Promise<Model> => {
  const host: string = StaghornConfig.getPrefix();
  const url = host + "/report";

  const response = await post<StaghornReportResponse>(url, report);

  if (response.data) {
    const reportModel = new ReportModel(report);
    const data = response.data.results[0];

    reportModel.Library = {};
    reportModel.Library.Tallies = new TallyCollection(data?._lib.tallies);
    reportModel.Library.Personnel = new PersonnelCollection(data?._lib.personnel);

    // key the tally data dump by ids
    reportModel.ExtData = {
      data_by_day: _.indexBy(data?._dump.data_by_day, "date"),
      data_by_day_and_emp: _.indexBy(data?._dump.data_by_day_and_emp, "date"),
      data_by_emp: _.indexBy(data?._dump.data_by_emp, "emp_id"),
      data_by_tally: _.indexBy(data?._dump.data_by_tally, "tally_id"),
    };

    reportModel.set("result", data.result);

    return reportModel;
  } else {
    return new ReportModel();
  }
};

export default postReport;
