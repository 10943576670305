"use strict";

var React = require("react/addons");
var $ = require("jquery");

var SwapMixin = {
  // refs proxy
  getRef: function (id) {
    return this.refs[id];
  },

  /* privates */
  // render bottom left control
  _getBottomLeftControl: function (totalSteps) {
    // anything past the first step render a back unless we're at the dialog started point
    if (this.state.initStep != this.state.step) {
      return (
        <span
          className="step"
          onClick={this.touchProxy.bind(this, this.stepBackward, [])}
          onTouchEnd={this.touchProxy.bind(this, this.stepBackward, [])}
        >
          <i className="fa fa-arrow-left" />
          Back
        </span>
      );
    }
    return undefined;
  },
  // render bottom right control
  _getBottomRightControl: function (totalSteps) {
    var controls = [];

    // last step render 'submit', otherwise 'next'
    if (this.state.step < totalSteps) {
      return (
        <span
          className="step"
          onClick={this.touchProxy.bind(this, this.stepForward, [])}
          onTouchEnd={this.touchProxy.bind(this, this.stepForward, [])}
        >
          <i className="fa fa-arrow-right" data-cy="nextArrowBtn" />
          Next
        </span>
      );
    } else if (this.state.step == totalSteps) {
      return (
        <span
          className="submit"
          data-cy="submitBtn"
          onClick={this.touchProxy.bind(this, this.props.submit, [])}
          onTouchEnd={this.touchProxy.bind(this, this.props.submit, [])}
        >
          <i className="fa fa-check" />
          Submit
        </span>
      );
    } else {
      // should never be here
    }
  },

  /*
	stepForward: function(e) {
		// make sure we satisfy the requirements for each step
		var satisified = false;
		switch (this.state.step) {
			case 1:
				// make sure all the selections are valid 
				if (this.state.selectedPersonnel.length > 1 && this.state.selectedAssignment && this.state.selectedTemplate){
					// additional check to dig any deeper into the selections
					satisified = true;
					if (this.state.selectedPersonnel[0].get('scheduled') == false){
						satisified = false;
					}
				}
				break;
			case 2:
				// require a selected date
				satisified = _.keys(this.state.selectedDates).length > 0;
				break;
			default:
				break;
		}

		if (satisified) {
			// do something depending on the step (after the state update)
			var newStep = this.state.step + 1;
			this.setState({step: newStep}, function() {
				switch (newStep) {
					case 2:
						//this._getAssignments(this.state.selectedTemplate);
					default:
						break;
				}
			});

			// send a tracking event
			if (this.state.action == SWAP){
				window.LbsAppData.Helpers.Analytics.sendEvent('Fill: Step', 'Forward');
			} else if (this.state.action == REQUEST){
				window.LbsAppData.Helpers.Analytics.sendEvent('Request: Step', 'Forward');
			}
		} else {
			var message;
			if (this.state.step == 1) {
				message = 'Please make a valid selection';
			} else if (this.state.step == 2) {
				message = 'Please select a date';
			}

			window.LbsAppData.AppContext.openDialog({
				type: 'message',
				message: message,
				timer: 1500,
			});
		}
	},

	stepBackward: function(e) {
		this.setState({step: this.state.step - 1});
		// send a tracking event
		if (this.state.action == SWAP){
			window.LbsAppData.Helpers.Analytics.sendEvent('Fill: Step', 'Backward');
		} else if (this.state.action == REQUEST){
			window.LbsAppData.Helpers.Analytics.sendEvent('Request: Step', 'Backward');
		}
	},
	*/
};

module.exports = SwapMixin;
