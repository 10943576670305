import React, { useEffect, useRef, useState } from "react";
import "./Tooltip.scss";

interface ToolTipProps {
  title: string;
  width: string;
  children: React.ReactNode;
  position?: string;
}

const Tooltip = ({ title, position = "top", width, children }: ToolTipProps): JSX.Element => {
  const node = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const handleClick = ({ target }: any) => {
    if (node.current?.contains(target)) {
      // inside click
      return;
    }
    setIsVisible(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div
      className="tooltipContainer"
      data-testid="tooltip"
      ref={node}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <div data-testid="tooltip-placeholder">{children}</div>
      {isVisible && (
        <div
          className={"tooltipContent " + position}
          data-testid="tooltip-content"
          style={{ width: width != undefined ? width : "auto", whiteSpace: width != undefined ? "unset" : "nowrap" }}
        >
          <span className="arrow"></span>
          {title}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
