"use strict";

require("./_styles.scss");

var React = require("react/addons");
var moment = require("moment");
var _ = require("underscore");

var FeedItem = React.createClass({
  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {},
  componentWillUnmount: function () {},

  /* privates */

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      FeedItem: true,
    });

    return <div className={classes}></div>;

    /* jshint ignore:end */
  },
});

module.exports = FeedItem;
