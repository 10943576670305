"use strict";

var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");

var DatePickerCalendarHeader = React.createClass({
  /* privates */

  /* publics */

  /* lifecycle methods */

  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      DatePickerCalendarHeader: true,
    });

    return (
      <div className={classes}>
        <div className="day weekend">Sun</div>
        <div className="day">Mon</div>
        <div className="day">Tue</div>
        <div className="day">Wed</div>
        <div className="day">Thu</div>
        <div className="day">Fri</div>
        <div className="day weekend">Sat</div>
      </div>
    );

    /* jshint ignore:end */
  },
});

module.exports = DatePickerCalendarHeader;
