/* this is the object that gets saved to the server */
const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var _ = require("underscore");

var ReportDefinition = Backbone.Model.extend({
  idAttribute: "view_id",

  defaults: {
    name: undefined,
    data: undefined,
  },

  initialize: function (attributes, options) {},

  url: function () {
    return ApiConfig.getPrefix() + (this.id ? "/report/view/" + this.id : "/report/view");
  },

  toJSON: function (options) {
    var attributes = _.clone(this.attributes);

    // strip any shadowing properties
    attributes.data._definition.properties.data.mode = "adhoc";
    attributes.data._definition.properties.data.shadow = undefined;
    attributes.data._definition.properties.data.view = undefined;
    //attributes.data._definition.properties.data.period_type = 'current'; -- don't want to strip this actually

    attributes.data = JSON.stringify(attributes.data);

    delete attributes.is_modified; // don't need this

    return attributes;
  },

  /* publics */
});

module.exports = ReportDefinition;
