import { Model } from "backbone";

import connectedPropertyHookFactory from "@/viewer/ui/modules/common/hooks/connectedPropertyHookFactory";

import { cleanDepartmentAttributes, DepartmentsById } from "@/viewer/types/domain/department";

const getDepartments = (): DepartmentsById => {
  const { LbsAppData } = window as any;

  const out: DepartmentsById = {};
  const departmentsCollection = LbsAppData.Departments;

  if (!departmentsCollection) return out;

  departmentsCollection.forEach((departmentModel: Model) => {
    const department = cleanDepartmentAttributes(departmentModel.attributes);
    out[department.id] = department;
  });

  return out;
};

export default connectedPropertyHookFactory({
  loader: getDepartments,
  getListeners: () => {
    const { LbsAppData } = window as any;
    return [[LbsAppData.AppContext, "fetched"]];
  },
});
