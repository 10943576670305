import { DEFAULT_TIMEOUT } from "@/viewer/data/constants";
import ConfigHelper from "../data/ConfigHelper";
import { getAuthHeaders } from "./headers";
import { ParsedResponse, getParsedResponse } from "./responses";

const timeout = ConfigHelper.timeout ? Number(ConfigHelper.timeout) : DEFAULT_TIMEOUT;

export const createFetchTimeoutSignal = (timeoutDuration: number): AbortSignal => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), timeoutDuration);
  return controller.signal;
};

export async function request<T>(path: string, params: RequestInit = {}): Promise<Response> {
  return await fetch(path, {
    ...{
      signal: createFetchTimeoutSignal(timeout),
      headers: {
        Accept: "application/json",
        ...getAuthHeaders(),
      },
    },
    ...params,
  });
}

export async function get<T>(
  path: string,
  params: RequestInit = {},
  responseHandler: (resp: Response) => Promise<ParsedResponse<T>> = getParsedResponse
): Promise<ParsedResponse<T>> {
  const resp = await request(path, {
    ...{
      method: "GET",
      signal: createFetchTimeoutSignal(timeout),
    },
    ...params,
  });

  return await responseHandler(resp);
}

export async function post<T>(
  path: string,
  payload: any,
  params: RequestInit = {},
  responseHandler: (resp: Response) => Promise<ParsedResponse<T>> = getParsedResponse
): Promise<ParsedResponse<T>> {
  const resp = await request(path, {
    ...{
      method: "POST",
      signal: createFetchTimeoutSignal(timeout),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=utf-8",
        ...getAuthHeaders(),
      },
      body: JSON.stringify(payload),
    },
    ...params,
  });

  return await responseHandler(resp);
}

export async function put<T>(
  path: string,
  payload: any,
  params: RequestInit = {},
  responseHandler: (resp: Response) => Promise<ParsedResponse<T>> = getParsedResponse
): Promise<ParsedResponse<T>> {
  const resp = await request(path, {
    ...{
      method: "PUT",
      signal: createFetchTimeoutSignal(timeout),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=utf-8",
        ...getAuthHeaders(),
      },
      body: JSON.stringify(payload),
    },
    ...params,
  });

  return await responseHandler(resp);
}

export async function del<T>(
  path: string,
  params: RequestInit = {},
  responseHandler: (resp: Response) => Promise<ParsedResponse<T>> = getParsedResponse
): Promise<ParsedResponse<T>> {
  const resp = await request(path, {
    ...{
      method: "DELETE",
      signal: createFetchTimeoutSignal(timeout),
      headers: {
        Accept: "application/json",
        ...getAuthHeaders(),
      },
    },
    ...params,
  });
  return await responseHandler(resp);
}

export function postForm<T>(path: string, body: FormData, opts: RequestInit = {}): Promise<Response> {
  return request(path, {
    ...{
      method: "POST",
      signal: createFetchTimeoutSignal(timeout),
      body,
    },
    ...opts,
  });
}

export function patch<T>(path: string, pars: any, opts: RequestInit = {}) {
  return post(path, pars, { ...opts, method: "PATCH", signal: createFetchTimeoutSignal(timeout) });
}
