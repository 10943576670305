import ConfigHelper from "@/_lib/data/ConfigHelper";
import { del, get, post } from "@/_lib/utils/fetch";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { Tally, TallyAttributes } from "@/viewer/types/domain/tally";
import { ParsedResponse } from "@/_lib/utils/responses";
import { FormValues } from "./EditTallyCountModal";

interface PostResponse {
  Id: string;
  OverrideValue: number;
  SlotId: number;
  TallyId: number;
}

type GetResponse = TallyAttributes[];

interface DeleteResponse {
  Id: string;
  OverrideValue: number;
  SlotId: number;
  TallyId: number;
}

export interface PayloadItem {
  TallyId: number;
  OverrideValue: number;
}

type Payload = PayloadItem[];

export const saveTallies = async (
  slotQuest: SlotOrRequest,
  payload: Payload
): Promise<ParsedResponse<PostResponse>> => {
  const url = `${ConfigHelper.host_lbapi}/slots/${slotQuest.id}/tallies/overrides/bulk`;
  return await post<PostResponse>(url, payload);
};

export const fetchTallies = async (slotQuest: SlotOrRequest): Promise<ParsedResponse<GetResponse>> => {
  const url = `${ConfigHelper.host_lbapi}/slots/${slotQuest.id}/tallies?tallyCountType=ByIndividualAssignment`;
  return await get<GetResponse>(url);
};

export const deleteTally = async (slotQuest: SlotOrRequest, tally: Tally): Promise<ParsedResponse<DeleteResponse>> => {
  const url = `${ConfigHelper.host_lbapi}/slots/${slotQuest.id}/tallies/overrides/${tally.tallyOverrideId}`;
  return await del<DeleteResponse>(url);
};

export const createPayload = (values: FormValues): Payload => {
  return values.tallies.flatMap((t) => {
    const formattedValue = parseFloat(t.value.toString());
    const isValueSameAsInitialValue = formattedValue === t.initialValue;

    if (isValueSameAsInitialValue) return [];

    return {
      TallyId: t.id,
      OverrideValue: formattedValue,
    };
  });
};
