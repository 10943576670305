import React from "react";
import Popper from "@material-ui/core/Popper";
import { PopperProps } from "@material-ui/core";

export interface ICustomPopper extends Partial<PopperProps> {
  content: string | React.ReactElement;
  anchor: React.MutableRefObject<HTMLElement | null>;
  style?: React.CSSProperties;
  isOpen?: boolean;
  modifiers?: { [key: string]: any };
}

const CustomPopper = (props: ICustomPopper): JSX.Element => {
  const { anchor, isOpen, content, style, modifiers, placement } = props;

  return (
    <Popper
      open={!!isOpen}
      style={{ ...style }}
      anchorEl={anchor.current}
      modifiers={modifiers}
      placement={placement}
      {...props}
    >
      <>{content}</>
    </Popper>
  );
};

export default CustomPopper;
