import { Type, Action } from "./actions";
import React from "react";

export type State = {
  toast: React.ReactElement | null;
  isOpen: boolean;
};

const initialState: State = {
  toast: null,
  isOpen: false,
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case Type.Open:
      return { toast: action.toast, isOpen: true };
    case Type.Close:
      return { ...state, isOpen: false };
    default:
      return state;
  }
};
