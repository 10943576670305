"use strict";

// scss requires
require("./_styles.scss");
// dependencie
const React = require("react/addons");
const moment = require("moment");
const _ = require("underscore");
// collections
// components
// mixins
const TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
const SwapMixin = require("_lib/ui/mixins/SwapMixin.jsx");

const SWAP = "swap-fill";
const REQUEST = "new-request";

const SwapFillDialog = React.createClass({
  mixins: [TouchMixin, SwapMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {
      step: 1,
      initStep: 1,
    };
  },
  componentDidMount: function () {
    // send a tracking event
    if (!this.props.inline) {
      if (this.props.getData("action") == SWAP) {
        window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Swap: Fill");
      } else if (this.props.getData("action") == REQUEST) {
        window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Request: New");
      }
    }
  },

  /* privates */
  _getTotalSteps: function () {
    return 3;
  },

  /* publics */
  stepForward: function () {
    // make sure we satisfy the requirements for each step
    let satisified = false;

    switch (this.state.step) {
      case 1:
        // make sure all the selections are valid
        if (
          this.props.getData("selectedPersonnel").length &&
          this.props.getData("selectedAssignments").length &&
          this.props.getData("selectedTemplate")
        ) {
          // additional check to dig any deeper into the selections
          satisified = true;
          if (this.props.getData("selectedPersonnel")[0].get("scheduled") == false) {
            satisified = false;
          }
        }
        break;
      case 2:
        // require a selected date
        satisified = _.keys(this.props.getData("selectedDates")).length > 0;
        break;
      default:
        break;
    }

    if (satisified) {
      // do something depending on the step (after the state update)
      const newStep = this.state.step + 1;

      this.setState({ step: newStep }, function () {
        switch (newStep) {
          // case 2:
          //this._getAssignments(this.state.selectedTemplate);
          default:
            break;
        }
      });

      // send a tracking event
      if (this.props.getData("action") == SWAP) {
        window.LbsAppData.Helpers.Analytics.sendEvent("Fill: Step", "Forward");
      } else if (this.props.getData("action") == REQUEST) {
        window.LbsAppData.Helpers.Analytics.sendEvent("Request: Step", "Forward");
      }
    } else {
      let message;

      if (this.state.step == 1) {
        message = "Please make a valid selection";
      } else if (this.state.step == 2) {
        message = "Please select a date";
      }

      window.LbsAppData.AppContext.openDialog({
        type: "message",
        message: message,
        timer: 1500,
      });
    }
  },
  stepBackward: function (e) {
    this.setState({ step: this.state.step - 1 });
    // send a tracking event
    if (this.props.getData("action") == SWAP) {
      window.LbsAppData.Helpers.Analytics.sendEvent("Fill: Step", "Backward");
    } else if (this.props.getData("action") == REQUEST) {
      window.LbsAppData.Helpers.Analytics.sendEvent("Request: Step", "Backward");
    }
  },

  /* privates */
  _getSelectedDates: function () {
    let assignments = this.props.getData("selectedAssignments");
    let startTime, stopTime;

    if (assignments.length) {
      startTime = this.props.getData("useCustomTime")
        ? this.props.getData("startTime")
        : assignments[0]._getAssignmentDefaultStartTime(this.props.getData("selectedTemplate").id);
      stopTime = this.props.getData("useCustomTime")
        ? this.props.getData("stopTime")
        : assignments[0]._getAssignmentDefaultStopTime(this.props.getData("selectedTemplate").id);
    }

    // loop through them and push date divs
    const dateDivs = [];

    for (let key in this.props.getData("selectedDates")) {
      const startDateRep = moment(key + "T" + startTime, "YYYYMMDDTHH:mm:ss");
      const stopDateRep = moment(key + "T" + stopTime, "YYYYMMDDTHH:mm:ss");

      // stop date gonna stop date
      if (stopDateRep.isBefore(startDateRep) || stopDateRep.isSame(startDateRep)) {
        stopDateRep.add(1, "days");
      }

      dateDivs.push(
        <div className="date" key={key}>
          <span className="text">{startDateRep.format("lll")}</span>
          &ndash;
          <span className="text">{stopDateRep.format("lll")}</span>
        </div>
      );
    }

    return dateDivs;
  },

  /* render */
  render: function () {
    /* jshint ignore:start */
    const classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      SwapFill: true,
    });

    if (!this.props.inline) {
      return (
        <div className={classes} data-cy="SFWindowPrompt">
          <div className="position-reset">
            <div
              className="close"
              onClick={this.touchProxy.bind(this, this.props.close, [])}
              onTouchEnd={this.touchProxy.bind(this, this.props.close, [])}
            >
              <i className="fa fa-close" />
            </div>

            <div className="header-container">
              <div className="header">
                <div className="mobile-section-nav">
                  <div className="mobile-section-nav-tab active">
                    {this.props.getData("action") == SWAP ? "Fill" : "Request"}
                  </div>
                </div>
              </div>
            </div>

            <div className="section-container">
              <div className={"section _" + this.state.step}>
                {this._renderStep1()}
                {this._renderStep2()}
                {this._renderStep3()}
              </div>
            </div>

            <div className="bottom-controls">
              <div className="position-reset">
                <div className="left-side">{this._getBottomLeftControl(this._getTotalSteps())}</div>
                <div className="right-side">{this._getBottomRightControl(this._getTotalSteps())}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this._renderInline();
  },

  /* rendering functions */
  _renderStep1: function () {
    const templateSelectable = () => this.props.widgetProxy("selectTemplate");
    const assignmentSelectable = () => this.props.widgetProxy("selectAssignment");
    const personnelSelectable = () => this.props.widgetProxy("selectPersonnel");

    return (
      <div className="content">
        <div className="editables ypad">
          {this.state.step === 1 && (
            <>
              {templateSelectable()}
              {personnelSelectable()}
              {assignmentSelectable()}
            </>
          )}
        </div>
      </div>
    );
  },

  _renderStep2: function () {
    const swapDatePicker = () => this.props.widgetProxy("datePickerSwap");
    const requestDatePicker = () => this.props.widgetProxy("datePickerRequest");
    const picker = this.props.getData("action") === SWAP ? swapDatePicker : requestDatePicker;

    return <div className="content">{this.state.step === 2 && picker()}</div>;
  },

  _renderStep3: function () {
    // summary
    const swapSentence = () => this.props.widgetProxy("sentenceSwap");
    // note
    const noteElement = () => this.props.widgetProxy("addNote");
    // auto add demand only is for fills, and admins
    const demandElement = () => this.props.widgetProxy("addDemand");
    // loa reason if applicable
    const loaElement = () => this.props.widgetProxy("selectLoaReason");
    // locations if applicable
    const locElement = () => this.props.widgetProxy("selectLocations");
    // edit time
    const timeElement = () => this.props.widgetProxy("editTime");
    // call order
    const callOrderElement = () => this.props.widgetProxy("editCallOrderFill");

    return (
      <div className="content">
        {this.state.step === 3 && (
          <>
            <div className="details">
              <div className="sentence-container">{swapSentence()}</div>
            </div>

            <div className="editables">
              <div className="editables-title">Customize</div>
              {timeElement()}
              {callOrderElement()}
              {noteElement()}
              {loaElement()}
              {locElement()}
              {demandElement()}
            </div>
          </>
        )}
      </div>
    );
  },

  _renderInline: function () {
    // "step 1"
    const templateSelectable = this.props.widgetProxy("selectTemplate");
    const assignmentSelectable = this.props.widgetProxy("selectAssignment");
    const personnelSelectable = this.props.widgetProxy("selectPersonnel");

    // "step 2"
    const datepicker = this.props.widgetProxy("selectDate");

    // "step 3"
    const noteElement = this.props.widgetProxy("addNote");
    // auto add demand only is for fills, and admins
    const demandElement = this.props.widgetProxy("addDemand");
    // loa reason if applicable
    const loaElement = this.props.widgetProxy("selectLoaReason");
    // locations if applicable
    const locElement = this.props.widgetProxy("selectLocations");
    // edit time
    const timeElement = this.props.widgetProxy("editTime");

    // call order
    // const callOrderElement = this.props.widgetProxy("editCallOrderFill");

    return (
      <div className="inline-container">
        <div className="editables">
          {templateSelectable}
          {personnelSelectable}
          {assignmentSelectable}
          {datepicker}

          <div className="spacer bordered"></div>
          {timeElement}
          {/* {callOrderElement} */}
          {noteElement}
          {loaElement}
          {locElement}
          {demandElement}
        </div>
      </div>
    );
  },
});

module.exports = SwapFillDialog;
