const Backbone = require("backbone");
const _ = require("lodash");
const Promise = require("bluebird");

const ApiConfig = require("_lib/data/ApiConfig.js");
const Slot = require("./../models/Slot.js");
const moment = require("moment");
const logging = require("@/_lib/utils/logging");
const injectInstrumentation = require("@/_lib/utils/instrumentationClient").default;

const SlotCollection = Backbone.Collection.extend({
  model: Slot,

  initialize: function (models, attributes) {
    this.attributes = {};

    for (let key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    const queryParams = {};

    if (this.attributes.adhoc) {
      // we're creating an adhoc slots collection... so don't rely on anything outside of this object
      if (this.attributes.start_date) {
        queryParams["start_date"] = this.attributes.start_date.format("YYYYMMDD");
      }

      // adhoc uses end_date for end_date
      if (this.attributes.end_date) {
        queryParams["end_date"] = this.attributes.end_date.format("YYYYMMDD");
      }

      queryParams["listed"] = true;

      if (this.attributes.emp_id) {
        queryParams["emp_id"] = this.attributes.emp_id;
      }
      if (this.attributes.only_pending) {
        queryParams["only_pending"] = this.attributes.only_pending;
      }
    } else {
      // normal 'viewing' collection
      if (window?.LbsAppData?.DateManager?.attributes?.start_date) {
        queryParams["start_date"] = window.LbsAppData.DateManager.attributes.start_date.format("YYYYMMDD");
      }

      // normal viewing uses stop_date for end_date
      if (this.attributes.stop_date) {
        queryParams["end_date"] = this.attributes.stop_date.format("YYYYMMDD");
      } else if (window?.LbsAppData?.DateManager?.attributes?.stop_date) {
        queryParams["end_date"] = window.LbsAppData.DateManager.attributes.stop_date.format("YYYYMMDD");
      }

      queryParams["listed"] = true;

      // if this isn't the 'today' or 'me' view then pass in the view_id
      if (window?.LbsAppData?.AppContext?.attributes?.view?.view_id === "today") {
        // Thanks MRC! need to send in the department list because we can no longer rely on permissions
        // to filter out data for us

        if (this.attributes?.template_list?.length) {
          queryParams["template_id"] = this.attributes.template_list[0];
        }

        if (window?.LbsAppData?.DateManager?.attributes?.schedule_start_date) {
          queryParams["start_date"] = window.LbsAppData.DateManager.attributes.schedule_start_date.format("YYYYMMDD");
        }

        if (window?.LbsAppData?.DateManager?.attributes?.schedule_stop_date) {
          queryParams["end_date"] = window.LbsAppData.DateManager.attributes.schedule_stop_date.format("YYYYMMDD");
        }
      } else if (window?.LbsAppData?.AppContext?.attributes?.view?.view_id === "me") {
        const empId = window?.LbsAppData?.User?.attributes?.emp_id;

        if (!empId) {
          logging.error("Emp id is null. Refusing to build /schedule/range request. - Viewer_SlotCollection");

          return null;
        }

        queryParams["emp_id"] = empId;
      } else {
        if (Number.isInteger(window?.LbsAppData?.AppContext?.attributes?.view?.view_id)) {
          queryParams["view_id"] = window.LbsAppData.AppContext.attributes.view.view_id;
        }

        if (window?.LbsAppData?.AppContext?.attributes?.view?.is_legacy) {
          queryParams["legacy"] = window.LbsAppData.AppContext.attributes.view.is_legacy;
        }
      }
      if (window?.LbsAppData?.Helpers?.Time?.timezoneBeforeOrAfter() === "before") {
        const endDate = queryParams["end_date"];

        queryParams["end_date"] = moment(endDate, "YYYYMMDD").add(1, "days").format("YYYYMMDD");
      }

      if (window?.LbsAppData?.Helpers?.Time?.timezoneBeforeOrAfter() === "after") {
        const startDate = queryParams["start_date"];

        queryParams["start_date"] = moment(startDate, "YYYYMMDD").subtract(1, "days").format("YYYYMMDD");
      }
    }

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    return (
      ApiConfig.getPrefix() +
      "/schedule/range/?" +
      _.map(cleanQueryParams, function (value, key) {
        return key + "=" + value;
      }).join("&")
    );
  },

  parse: function (response) {
    return response.data;
  },

  _getSlotComparatorValue: function (object, keyword) {
    try {
      switch (keyword) {
        case "assign_order":
          return object.assignmentObj.attributes.order;
        case "personnel_order":
          return object.personnelObj.attributes.order;
        case "personnel_last_name":
          return object.personnelObj.attributes.last_name.toLowerCase();
        case "personnel_display_name":
          return object.personnelObj.attributes.display_name.toLowerCase();
        case "assign_display_name":
          return object.assignmentObj.attributes.display_name.toLowerCase();
        case "date":
          return object.attributes["slot_date"];
        default:
          // return the slot attribute for any other keyword
          return object.attributes[keyword];
      }
    } catch (e) {
      return undefined;
    }
  },

  _fetchSlots: function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    injectInstrumentation();

    return new Promise(function (resolve, reject) {
      that.fetch({
        success: function (collection, response, options) {
          resolve();
        },
        error: function () {
          reject();
        },
        reset: true,
      });
    });
  },
  _checkDataRange: function (startDate, endDate) {
    const group = this.groups.at(0);

    // check to see if the given range has all the data it needs currently
    const rollingDate = moment(startDate);

    for (let i = 0; i <= endDate.diff(startDate, "days"); i++) {
      const key = rollingDate.format("YYYYMMDD");

      if (!group.dates.get(key)) {
        // key not present
        return false;
      }

      rollingDate.add("days", 1);
    }

    // all the keys are present
    return true;
  },
});

module.exports = SlotCollection;
