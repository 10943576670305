"use strict";

require("./_styles.scss");

const React = require("react/addons");
const $ = require("jquery");

const TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
const ExtMenuDialog = require("./types/ext_menu/ExtMenuDialog.jsx");
const MessageDialog = require("./types/message/MessageDialog.jsx");
const ErrorDialog = require("./ErrorDialog.jsx");
const FeedbackDialog = require("./types/feedback/FeedbackDialog.jsx");
const SubscribeDialog = require("./types/subscribe/SubscribeDialog.jsx");
const ForgotDialog = require("./types/forgot/ForgotDialog.jsx");
const ListDialog = require("_lib/ui/modules/dialog/types/list/ListDialog.jsx");
const AccountSettingsDialog = require("_lib/ui/modules/dialog/types/settings/AccountSettingsDialog.tsx").default;
const SlotDetailsDialog = require("_lib/ui/modules/dialog/types/slot_details/SlotDetailsDialog.jsx");
const RequestDetailsDialog = require("_lib/ui/modules/dialog/types/requests/details/RequestDetailsDialog.jsx");
const SlotHistoryDialog = require("_lib/ui/modules/dialog/types/slot_history/SlotHistoryDialog.jsx");
const SlotEditDialog = require("_lib/ui/modules/dialog/types/slot_edit/SlotEditDialog.jsx");
const RequestEditDialog = require("_lib/ui/modules/dialog/types/slot_edit/RequestEditDialog.jsx");
const ScheduleEditsBaseDialog = require("_lib/ui/modules/dialog/types/schedule_edits/base.jsx");
const ScheduleEditsExtBaseDialog = require("_lib/ui/modules/dialog/types/schedule_edits/_ext/base.jsx");
const NoteFullDialog = require("_lib/ui/modules/dialog/types/note_full/NoteFullDialog.jsx");
const classnames = require("@/_lib/utils/classnames").default;

// eslint-disable-next-line react/no-deprecated
const DialogContainer = React.createClass({
  mixins: [TouchMixin],

  getInitialState: function () {
    return {
      active: !!window.LbsAppData.AppContext.get("dialogStack").length,
      translucent: false,
    };
  },
  componentDidMount: function () {
    this._registerBackboneEvents();
  },
  componentWillUnmount: function () {
    this._unregisterBackboneEvents();
  },
  toggleDialog: function () {
    this.setState({
      active: !!window.LbsAppData.AppContext.get("dialogStack").length,
    });
  },
  toggleTranslucent: function (on) {
    this.setState({ translucent: on });
  },
  _registerBackboneEvents: function () {
    window.LbsAppData.AppContext.on("pushedDialog poppedDialog", this.toggleDialog.bind(this, null), this);
  },
  _unregisterBackboneEvents: function () {
    window.LbsAppData.AppContext.off(null, null, this);
  },
  close: function (e) {
    //TODO: Unfuck this shit
    if (!e) {
      window.LbsAppData.AppContext.closeDialog();
      // super super super hack to make chrome not suck
      // I cannot explain to you how this fixes the issue...it just does.  if you comment out this line
      // upon closing a dialog for the first time you will notice studder in the framerate of the app...
      // observed in chrome only.  the cpu profile is distinctly different while this is happening.  focusing in on
      // this simple input element does _something_ to not allow it slide into that useless state
      $("#topbar-close").focus();
    } else {
      const displayedDialogType = window.LbsAppData.AppContext.attributes.displayedDialog
        ? window.LbsAppData.AppContext.attributes.displayedDialog.type
        : undefined;

      // reset and close if this was a non-translucent mask click or they hit the 'esc' button
      if (this.checkIfNonTranslucent(e, displayedDialogType)) {
        // reset the tool and close
        this.close();
      }
    }
  },

  checkIfNonTranslucent: function (e, displayedDialogType) {
    return (
      e &&
      e.target.classList.contains("mask") &&
      !this.state.translucent &&
      displayedDialogType !== "message" &&
      displayedDialogType !== "error"
    );
  },
  render: function () {
    /* jshint ignore:start */
    const dialogComponents = [];
    const dialogStack = window.LbsAppData.AppContext.get("dialogStack");

    if (dialogStack.length) {
      for (let i = 0; i < dialogStack.length; i++) {
        const dialogObject = dialogStack[i];

        switch (dialogObject.type) {
          case "ext-menu": {
            dialogComponents.push(
              <ExtMenuDialog
                isTop={i + 1 === dialogStack.length}
                dialogObject={dialogObject}
                key={i}
                close={this.close}
              />
            );
            break;
          }
          case "message": {
            dialogComponents.push(
              <MessageDialog
                isTop={i + 1 === dialogStack.length}
                dialogObject={dialogObject}
                key={i}
                close={this.close}
              />
            );
            break;
          }
          case "error": {
            dialogComponents.push(
              <ErrorDialog
                isTop={i + 1 === dialogStack.length}
                dialogObject={dialogObject}
                key={i}
                close={this.close}
              />
            );
            break;
          }
          case "list": {
            dialogComponents.push(
              <ListDialog isTop={i + 1 === dialogStack.length} dialogObject={dialogObject} key={i} close={this.close} />
            );
            break;
          }
          case "settings": {
            dialogComponents.push(
              <AccountSettingsDialog
                isTop={i + 1 === dialogStack.length}
                dialogObject={dialogObject}
                key={i}
                close={this.close}
              />
            );
            break;
          }
          case "feedback": {
            dialogComponents.push(
              <FeedbackDialog
                isTop={i + 1 === dialogStack.length}
                dialogObject={dialogObject}
                key={i}
                close={this.close}
              />
            );
            break;
          }
          case "subscribe": {
            dialogComponents.push(
              <SubscribeDialog
                isTop={i + 1 === dialogStack.length}
                dialogObject={dialogObject}
                key={i}
                close={this.close}
              />
            );
            break;
          }
          case "forgot": {
            dialogComponents.push(
              <ForgotDialog
                isTop={i + 1 === dialogStack.length}
                dialogObject={dialogObject}
                key={i}
                close={this.close}
              />
            );
            break;
          }
          case "slot-details": {
            dialogComponents.push(
              <SlotDetailsDialog
                isTop={i + 1 === dialogStack.length}
                dialogObject={dialogObject}
                key={i}
                close={this.close}
              />
            );
            break;
          }
          case "slot-edit": {
            dialogComponents.push(
              <SlotEditDialog
                isTop={i + 1 === dialogStack.length}
                dialogObject={dialogObject}
                key={i}
                close={this.close}
              />
            );
            break;
          }
          case "slot-history": {
            dialogComponents.push(
              <SlotHistoryDialog
                isTop={i + 1 === dialogStack.length}
                dialogObject={dialogObject}
                key={i}
                close={this.close}
              />
            );
            break;
          }
          case "request-details": {
            dialogComponents.push(
              <RequestDetailsDialog
                isTop={i + 1 === dialogStack.length}
                dialogObject={dialogObject}
                key={i}
                close={this.close}
              />
            );
            break;
          }
          case "request-edit": {
            dialogComponents.push(
              <RequestEditDialog
                isTop={i + 1 === dialogStack.length}
                dialogObject={dialogObject}
                key={i}
                close={this.close}
              />
            );
            break;
          }

          case "request-action":
          case "swap-remove":
          case "swap-fill":
          case "new-request":
          case "swap-replace":
          case "swap-pending":
          case "swap-details":
          case "preswap-pending":
          case "preswap-finalize": {
            dialogComponents.push(
              <ScheduleEditsBaseDialog
                isTop={i + 1 === dialogStack.length}
                dialogObject={dialogObject}
                key={i}
                flags={this.props.flags}
                close={this.close}
              />
            );
            break;
          }
          case "schedule-edits-error":
          case "schedule-edits-details-error":
          case "schedule-edits-fill-error":
          case "schedule-edits-date-select":
          case "schedule-edits-time-edit": {
            dialogComponents.push(
              <ScheduleEditsExtBaseDialog
                isTop={i + 1 === dialogStack.length}
                dialogObject={dialogObject}
                key={i}
                close={this.close}
              />
            );
            break;
          }
          case "note-full": {
            dialogComponents.push(
              <NoteFullDialog
                isTop={i + 1 === dialogStack.length}
                dialogObject={dialogObject}
                key={i}
                close={this.close}
              />
            );
            break;
          }
          default:
            break;
        }
      }
    }

    const classes = classnames({
      DialogContainer: true,
      active: this.state.active,
      translucent: this.state.translucent,
    });

    return (
      <div className={classes}>
        <div className="mask" onClick={this.close} onTouchEnd={this.close}>
          {dialogComponents}
        </div>
      </div>
    );
  },
});

module.exports = DialogContainer;
