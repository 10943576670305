// Helper to handle sorting configurations

var moment = require("moment");
var _ = require("underscore");
var $ = require("jquery");

// ordering type constants
var PO_CUSTOM = "custom order";
var PO_DISPLAY = "display name";
var PO_LAST = "last name";
var PO_TIME_CUSTOM = "time then custom order";
var PO_TIME_DISPLAY = "time then display name";
var PO_TIME_LAST = "time then last name";

var AO_CUSTOM = "custom order";
var AO_DISPLAY = "name";
var AO_TIME_CUSTOM = "time then custom order";
var AO_TIME_DISPLAY = "time then name";

var SortHelper = Backbone.Model.extend({
  defaults: {},

  initialize: function () {},

  _getAssignComparator: function () {
    // set up the comparator based on the view settings, time sorting is only supported in terms of the slot level
    // and so we don't deal with that here...only the name or field (if custom ordering) matters for the collection order
    var assignComparator;
    var sortAssignmentsBy = window.LbsAppData.AppContext.attributes.view.attributes.filter.sort_assignments_by;
    if (sortAssignmentsBy == AO_CUSTOM || sortAssignmentsBy == AO_TIME_CUSTOM) {
      assignComparator = "order";
    } else {
      assignComparator = function (a, b) {
        var orderA = a.attributes.display_name.toLowerCase();
        var orderB = b.attributes.display_name.toLowerCase();
        // must be same type, so do normal int, string comparisons
        if (orderA == orderB) {
          return 0;
        } else {
          if (orderA < orderB) {
            return -1;
          } else {
            return 1;
          }
        }
      };
    }

    return assignComparator;
  },

  _getPersonnelComparator: function () {
    // set up the comparator based on the view settings, time sorting is only supported in terms of the slot level
    // and so we don't deal with that here...only the name or field (if custom ordering) matters for the collection order
    var personnelComparator;
    var sortPersonnelBy = window.LbsAppData.AppContext.attributes.view.attributes.filter.sort_personnel_by;
    if (sortPersonnelBy == PO_CUSTOM || sortPersonnelBy == PO_TIME_CUSTOM) {
      personnelComparator = "order";
    } else if (sortPersonnelBy == PO_LAST || sortPersonnelBy == PO_TIME_LAST) {
      personnelComparator = function (a, b) {
        var orderA = a.attributes.last_name.toLowerCase();
        var orderB = b.attributes.last_name.toLowerCase();

        if (orderA == orderB) {
          return 0;
        } else {
          if (orderA < orderB) {
            return -1;
          } else {
            return 1;
          }
        }
      };
    } else {
      personnelComparator = function (a, b) {
        var orderA = a.attributes.display_name.toLowerCase();
        var orderB = b.attributes.display_name.toLowerCase();

        if (orderA == orderB) {
          return 0;
        } else {
          if (orderA < orderB) {
            return -1;
          } else {
            return 1;
          }
        }
      };
    }

    return personnelComparator;
  },

  _getSlotComparator: function () {
    var slotOrdering;
    if (!window.LbsAppData.AppContext.attributes.view) {
      // base case
      if (window.LbsAppData.AppContext.getLeftColumnType() == "personnel") {
        // focus is on assignments on the slots
        slotOrdering = "start_time!assign_display_name";
      } else {
        // focus is on personnel on the slots
        slotOrdering = "start_time!display_name";
      }
    } else {
      // l-b logicz

      // this can pull double duty now on slots AND requests..now that we have assignment structure id's on requests
      var sortPersonnelBy = window.LbsAppData.AppContext.attributes.view.attributes.filter.sort_personnel_by;
      var sortAssignmentsBy = window.LbsAppData.AppContext.attributes.view.attributes.filter.sort_assignments_by;

      slotOrdering = this._translateSort(window.LbsAppData.AppContext.attributes.view); //window.LbsAppData.AppContext.attributes.slotOrdering;
    }

    var slotComparator = function (a, b) {
      var splitOrdering = window.LbsAppData.AppContext.attributes.slotOrdering.split("!");
      // compare level 1, then level 2, then level 3
      var i = 0;
      var compareA, compareB;
      do {
        // get the values to compare
        // fyi the SlotComparatorValue function is in the _collection_
        compareA = this._getSlotComparatorValue(a, splitOrdering[i]);
        compareB = this._getSlotComparatorValue(b, splitOrdering[i]);

        // normal comparison
        if (compareA < compareB) {
          return -1;
        } else if (compareB < compareA) {
          return 1;
        }

        /* 
				sorting between regular and mapped assignments
				backbone comparator doesnt compare all the combinations of models in the collection.
				if comparison of A and B returns 0 and comparison of B and C returns 0
				then it might not compare A and C to optimize the sorting algorithm.
				therefore since number and string are not equal, we need to make sure we return 1 or -1.
				*/
        if (typeof compareA == "number" && typeof compareB == "string") {
          return 1;
        }
        if (typeof compareA == "string" && typeof compareB == "number") {
          return -1;
        }
        i++;
      } while (compareA == compareB && i < splitOrdering.length); // implicit equality check (provided for clarity)

      // everything was the same
      return 0;
    };

    // set the sentence
    window.LbsAppData.AppContext.set({ slotOrdering: slotOrdering });

    return slotComparator;
  },

  _translateSort: function (view) {
    var slotOrdering;
    var sortPersonnelBy = window.LbsAppData.AppContext.attributes.view.attributes.filter.sort_personnel_by;
    var sortAssignmentsBy = window.LbsAppData.AppContext.attributes.view.attributes.filter.sort_assignments_by;

    // translate the view sorting attributes into the sort string
    if (window.LbsAppData.AppContext.getLeftColumnType() == "personnel") {
      // focus is on assignments on the slots
      if (sortAssignmentsBy == AO_CUSTOM) {
        slotOrdering = "assign_order";
      } else if (sortAssignmentsBy == AO_TIME_CUSTOM) {
        slotOrdering = "start_time!assign_order";
      } else if (sortAssignmentsBy == AO_DISPLAY) {
        slotOrdering = "assign_display_name";
      } else if (sortAssignmentsBy == AO_TIME_DISPLAY) {
        slotOrdering = "start_time!assign_display_name";
      }
    } else {
      // focus is on personnel on the slots
      if (sortPersonnelBy == PO_CUSTOM) {
        slotOrdering = "personnel_order";
      } else if (sortPersonnelBy == PO_TIME_CUSTOM) {
        slotOrdering = "start_time!personnel_order";
      } else if (sortPersonnelBy == PO_DISPLAY) {
        slotOrdering = "display_name";
      } else if (sortPersonnelBy == PO_TIME_DISPLAY) {
        slotOrdering = "start_time!display_name";
      } else if (sortPersonnelBy == PO_LAST) {
        slotOrdering = "personnel_last_name";
      } else if (sortPersonnelBy == PO_TIME_LAST) {
        slotOrdering = "start_time!personnel_last_name";
      }
    }

    // calendar/gantt/list...not a row to sort...so shove in the left column type in front of the sentence
    // that we just constructed
    if (
      window.LbsAppData.AppContext.attributes.view.attributes.theme.data.layout === "calendar" ||
      window.LbsAppData.AppContext.attributes.view.attributes.theme.data.layout === "gantt"
    ) {
      if (window.LbsAppData.AppContext.getLeftColumnType() == "personnel") {
        if (sortPersonnelBy == PO_CUSTOM || sortPersonnelBy == PO_TIME_CUSTOM) {
          slotOrdering = "personnel_order!" + slotOrdering;
        } else if (sortPersonnelBy == PO_DISPLAY || sortPersonnelBy == PO_TIME_DISPLAY) {
          slotOrdering = "display_name!" + slotOrdering;
        } else if (sortPersonnelBy == PO_LAST || sortPersonnelBy == PO_TIME_LAST) {
          slotOrdering = "personnel_last_name!" + slotOrdering;
        }
      } else {
        if (sortAssignmentsBy == AO_CUSTOM || sortAssignmentsBy == AO_TIME_CUSTOM) {
          slotOrdering = "assign_order!" + slotOrdering;
        } else if (sortAssignmentsBy == AO_DISPLAY || sortAssignmentsBy == AO_TIME_DISPLAY) {
          slotOrdering = "assign_display_name!" + slotOrdering;
        }
      }
    }

    if (window.LbsAppData.AppContext.attributes.view.attributes.theme.data.layout === "list") {
      // list view can sort the outer axis by time as well...none of this || shit here
      if (window.LbsAppData.AppContext.getLeftColumnType() == "personnel") {
        if (sortPersonnelBy == PO_CUSTOM) {
          slotOrdering = "personnel_order!" + slotOrdering;
        } else if (sortPersonnelBy == PO_TIME_CUSTOM) {
          slotOrdering = "start_time!personnel_order!" + slotOrdering;
        } else if (sortPersonnelBy == PO_DISPLAY) {
          slotOrdering = "display_name!" + slotOrdering;
        } else if (sortPersonnelBy == PO_TIME_DISPLAY) {
          slotOrdering = "start_time!display_name!" + slotOrdering;
        } else if (sortPersonnelBy == PO_LAST) {
          slotOrdering = "personnel_last_name!" + slotOrdering;
        } else if (sortPersonnelBy == PO_TIME_LAST) {
          slotOrdering = "start_time!personnel_last_name!" + slotOrdering;
        }
      } else {
        var LbsAppData = window.LbsAppData;
        if (sortAssignmentsBy == LbsAppData.AO_CUSTOM) {
          slotOrdering = "assign_order!" + slotOrdering;
        } else if (sortAssignmentsBy == LbsAppData.AO_TIME_CUSTOM) {
          slotOrdering = "start_time!assign_order!" + slotOrdering;
        } else if (sortAssignmentsBy == LbsAppData.AO_DISPLAY) {
          slotOrdering = "assign_display_name!" + slotOrdering;
        } else if (sortAssignmentsBy == LbsAppData.AO_TIME_DISPLAY) {
          slotOrdering = "start_time!assign_display_name!" + slotOrdering;
        }
      }

      // list has the addition issue that you can span multiple days within the slot containing element (the table in this case)
      // so need to put in the date into the ordering first in that case
      if (window.LbsAppData.AppContext.attributes.view.attributes.theme.data.layout === "list") {
        slotOrdering = "date!" + slotOrdering;
      }
    }

    return slotOrdering;
  },
});

module.exports = SortHelper;
