const Backbone = require("backbone");
//var ApiConfig = require('_lib/data/ApiConfig.js');
var Error = require("./../models/Error.js");

var ErrorCollection = Backbone.Collection.extend({
  model: Error,
  comparator: "type",

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  resetAndThrob: function (models) {
    // reset the collection
    this.reset(models);

    // pass out throb classes
    for (var i = 0; i < this.length; i++) {
      var error = this.at(i).throb();
    }
  },

  resetAndClearClasses: function () {
    // clear out the slot-level throb classes
    //this.trigger('clearErrorClasses');
    // clear out the cell-level throb classes
    for (var i = 0; i < this.length; i++) {
      this.at(i).detach();
    }

    // reset the collection
    this.reset();
  },

  printHeaders: function () {
    return ["date", "emp_display_name", "emp_id", "slot_uuid", "type", "words"];
  },
});

module.exports = ErrorCollection;
