// Manager to handle communication with Google Analytics

var ReactGA = require("@/_lib/helpers/ReactGAHelper");
var ga = ReactGA.ga;

var AnalyticsHelper = Backbone.Model.extend({
  defaults: {
    active: true,
    eventValueTimerStart: undefined,
  },

  initialize: function () {
    // don't send analytics if we're on the dev server
    if (window.top.location.hostname == "localhost") {
      //this.attributes.active = false;
    }
  },

  /* publics */
  sendPageview: function (page, title) {
    // if available, set the tracker to this page so they trickle down to any subsequent events
    if (page) {
      ga("set", {
        page: page,
        title: title,
      });
    }

    if (this._isActive()) {
      // track a pageview
      ga("send", "pageview");
    }
  },
  sendEvent: function (action, label, value) {
    if (this._isActive()) {
      var category = window.LbsAppData.User ? window.LbsAppData.User.attributes.customer_id.toString() : "No Client";
      ga("send", {
        hitType: "event",
        eventCategory: category, // always set category to the client id
        eventAction: action,
        eventLabel: label,
        eventValue: value,
      });
    }
  },
  sendTiming: function (action, value, label) {
    if (this._isActive()) {
      var category = window.LbsAppData.User ? window.LbsAppData.User.attributes.customer_id.toString() : "No Client";
      // submit a timing event to ga
      ga("send", {
        hitType: "timing",
        timingCategory: category,
        timingVar: action,
        timingValue: value,
        timingLabel: label,
      });
    }
  },

  resetEventValueTimer: function () {
    this.set({
      eventValueTimerStart: new Date(),
    });
  },

  /* privates */
  _isActive: function () {
    return this.attributes.active;
  },
});

module.exports = AnalyticsHelper;
