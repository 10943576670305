import React from "react";
import { Type, Action } from "./actions";

export interface State {
  modal: React.ReactElement | null;
  isOpen: boolean;
}

const initialState: State = {
  modal: null,
  isOpen: false,
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case Type.Open:
      return { modal: action.modal, isOpen: true };
    case Type.Close:
      return { ...state, isOpen: false };
    case Type.Remove:
      return initialState;
    default:
      return state;
  }
};
