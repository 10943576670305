import React from "react";

import classnames from "@/_lib/utils/classnames";
import styles from "./styles.module.scss";

interface Props {
  spinning: boolean;
  className?: string;
  children?: React.ReactNode;
  legacy?: boolean;
}

const Spinner = ({ className = "", legacy = true, spinning, children }: Props): React.ReactElement => {
  const rootClassNames = classnames(className, {
    [styles.root]: true,
  });

  const overlayClassNames = classnames({
    [styles.overlay]: true,
  });

  const spinIconClassNames = classnames({
    [styles.spinIcon]: !legacy,
    [styles.legacySpinIcon]: legacy,
  });

  const contentClassNames = classnames({
    [styles.content]: true,
    [styles.blur]: spinning,
  });

  return (
    <div className={rootClassNames}>
      {spinning && (
        <div className={overlayClassNames}>
          <div className={spinIconClassNames} />
        </div>
      )}
      <div className={contentClassNames}>{children}</div>
    </div>
  );
};

export default Spinner;
