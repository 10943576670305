var moment = require("moment");
var ApiConfig = require("_lib/data/ApiConfig.js");
var docCookies = require("_lib/utils/cookies.js");
var _ = require("underscore");
var _sync = Backbone.sync;
const store = require("../../../store").default;
const { setAccessToken } = require("./actions");
const { DEFAULT_TIMEOUT } = require("@/viewer/data/constants");
var ConfigHelper = require("_lib/data/ConfigHelper.ts").default;

var TOKEN_TIMEOUT = 604800;

const timeout = ConfigHelper.timeout ? Number(ConfigHelper.timeout) : DEFAULT_TIMEOUT;

var Session = Backbone.Model.extend({
  urlRoot: ApiConfig.getPrefix() + "/token",

  defaults: {
    auth: false,
    token: null,
    expiresInSafe: null,
    expiresIn: null,
    grantedAt: null,
  },

  login: function (name, pwd, successCallBack, objRef, successCallBackArg, errorCallBack) {
    var that = this;
    var args =
      "grant_type=password&username=" + name + "&password=" + pwd + "&client_id=1bc4e40b-0373-42a8-bfed-979f10b0743a";

    $.ajax({
      method: "POST",
      url: that.urlRoot,
      data: args,
      dataType: "json",
      async: true,

      success: function (data) {
        const accessToken = data.access_token;
        store.dispatch(setAccessToken(accessToken));
        // substract a bit from the expiresAt so we aren't cutting it so close (5s)
        that.set({
          auth: true,
          token: accessToken,
          expiresInSafe: data.expires_in * 1000 - 5000,
          expiresIn: data.expires_in * 1000,
          grantedAt: moment(),
        });
        docCookies.setItem("LB_TKN", data.refresh_token, TOKEN_TIMEOUT, "/");
        successCallBack(objRef, successCallBackArg);
      },

      error: function () {
        errorCallBack();
      },
      timeout,
    });
  },

  loginRefresh: function (callback) {
    // hack to prevent chrome from firing this in a pre-render....yes...the pre-render gets in here
    if (document.webkitVisibilityState == "prerender") {
      return;
    }
    var that = this;
    var refreshToken = docCookies.getItem("LB_TKN");

    // the cookie gets deleted once we use it once (keeping this.. for the passthrough as well..to hopefully
    // avoid confusing scenerios for lbs staff who have multiple cookies?)
    //docCookies.removeItem("LB_TKN", '/');

    if (refreshToken !== null) {
      var args =
        "grant_type=refresh_token&refresh_token=" + refreshToken + "&client_id=1bc4e40b-0373-42a8-bfed-979f10b0743a";
      $.ajax({
        method: "POST",
        url: that.urlRoot,
        data: args,
        dataType: "json",
        //async: false,

        success: function (data) {
          const accessToken = data.access_token;
          store.dispatch(setAccessToken(accessToken));
          // substract a bit from the expiresAt so we aren't cutting it so close (5s)
          that.set({
            auth: true,
            token: accessToken,
            expiresInSafe: data.expires_in * 1000 - 5000,
            expiresIn: data.expires_in * 1000,
            grantedAt: moment(),
          });
          // set a new refresh token cookie
          docCookies.setItem("LB_TKN", data.refresh_token, TOKEN_TIMEOUT, "/");

          // save the client computer's timezone
          //docCookies.setItem("LB_tzdata", moment().utcOffset(), null, '/');
          //docCookies.setItem("LB_tzdata", 480, null, '/');

          // call the callback
          callback(data.access_token, that.get("expiresInSafe"));
        },
        error: function (jqXHR, textStatus, errorThrown) {
          // the cookie gets deleted
          //docCookies.removeItem("LB_TKN", '/');

          // no transport error == really old/shitty browser in our testing...it can't handle the OPTIONS requests (we think)
          console.log(errorThrown);
          if (errorThrown == "No Transport" || errorThrown.toLowerCase() == "no transport") {
            // so dirty
            $("#content").hide();
          }
        },
        timeout,
      });

      //window.LbsAppData.Helpers.Analytics.sendEvent('Refresh: Token', window.LbsAppData.User.attributes.emp_id.toString());
    }
  },

  _syncBackbone: function (callback) {
    // refresh the tokens and sync the backbone negotiator with it
    var that = this;

    this.loginRefresh(function (accessToken, expiresInSafe) {
      // override backbone sync to send the authorization header
      Backbone.sync = function (method, model, options) {
        options.headers = options.headers || {};
        _.extend(options.headers, {
          Authorization: "Bearer " + accessToken,
        });
        _sync(method, model, options);
      };

      // overwrite non-backbone calls to use the authorization token as well (currently used for the error logging)
      $.ajaxSetup({
        headers: { Authorization: "Bearer " + accessToken },
      });

      // _try_ and gracefully handle token expiration with this...this is course is not fool proof (computer might fall asleep etc)
      // but hopefully it works in most of our cases
      window.setTimeout(function () {
        that._syncBackbone();
      }, expiresInSafe);

      if (callback) {
        callback();
      }
    });
  },
});

module.exports = Session;
