import { Request } from "@/viewer/types/domain/request";
import { Slot } from "@/viewer/types/domain/slot";

import moment, { Moment } from "moment-timezone";

export const TimezoneConverter = (time: string, dbSubmit: boolean): moment.Moment => {
  const Helpers = (window as any).LbsAppData.Helpers;

  return Helpers.Time.timezoneConverter(time, dbSubmit);
};

const itemIsWithinDateRange = ({
  endOfRange,
  itemStart,
  itemStop,
  startOfRange,
}: {
  endOfRange: Moment;
  itemStart: Moment;
  itemStop: Moment;
  startOfRange: Moment;
}): boolean => {
  const itemBeginsWithinRange = itemStart.isSameOrAfter(startOfRange) && itemStart.isSameOrBefore(endOfRange);
  const itemEndsWithinRange = itemStop.isSameOrAfter(startOfRange) && itemStop.isSameOrBefore(endOfRange);

  return itemBeginsWithinRange || itemEndsWithinRange;
};

export const convertItemDateTimesAndCheckIfInRange = ({
  itemStartDate,
  itemStopDate,
  rangeEnd,
  rangeStart,
}: {
  itemStartDate: Date;
  itemStopDate: Date;
  rangeEnd: Date;
  rangeStart: Date;
}): boolean => {
  const startOfRange = moment(rangeStart).local();
  const endOfRange = moment(rangeEnd).local();
  const itemStart = moment(itemStartDate);
  const itemStop = moment(itemStopDate);

  return itemIsWithinDateRange({
    endOfRange,
    itemStart,
    itemStop,
    startOfRange,
  });
};

export const filterSlotsOutsideDateRange = ({
  endDate,
  slots,
  startDate,
}: {
  endDate: Date;
  slots: Slot[];
  startDate: Date;
}): Slot[] => {
  if (!slots.length) {
    return [];
  }

  const slotsInRange: Slot[] = slots.filter((slot) =>
    convertItemDateTimesAndCheckIfInRange({
      itemStartDate: slot.startTime,
      itemStopDate: slot.stopTime,
      rangeEnd: endDate,
      rangeStart: startDate,
    })
  );

  return slotsInRange;
};

export const filterRequestsOutsideDateRange = ({
  endDate,
  requests,
  startDate,
}: {
  endDate: Date;
  requests: Request[];
  startDate: Date;
}): Request[] => {
  if (!requests.length) {
    return [];
  }

  const requestsInRange: Request[] = requests.filter((request) =>
    convertItemDateTimesAndCheckIfInRange({
      itemStartDate: request.startTime,
      itemStopDate: request.stopTime,
      rangeEnd: endDate,
      rangeStart: startDate,
    })
  );

  return requestsInRange;
};

export const recursivelyReplaceOnRegexMatch = ({
  target,
  pattern,
  replacement,
}: {
  target: string;
  pattern: RegExp;
  replacement: string;
}): string => {
  if (target && target !== "") {
    const result = target.replace(pattern, replacement);

    if (result === target) {
      return result;
    }

    return recursivelyReplaceOnRegexMatch({ target: result, pattern, replacement });
  }

  return target;
};
