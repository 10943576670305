// dependencies
import * as React from "react";
import { DataContext, SettingsContext, ToolContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { getSlotQuestUUID } from "@/viewer/utils/domain/slotquests";
import CalendarSlot from "@/viewer/ui/modules/grid/calendar/components/CalendarSlot";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { OriginalPersonnelMap } from "@/viewer/types/viewdata";
import { DepartmentsById } from "@/viewer/types/domain/department";

// components

interface Props {
  settings: SettingsContext;
  ui: UIContext;
  data: DataContext;
  tools: ToolContext;
  myEmpId: number;
  slotData: SlotOrRequest[];
  originalPersonnelMap: OriginalPersonnelMap;
  departmentsById: DepartmentsById;
}

const CalendarDateBoxGroup = (props: Props): JSX.Element => {
  const { settings, ui, data, myEmpId, slotData, tools, originalPersonnelMap, departmentsById } = props;
  const slots: JSX.Element[] = [];

  slotData.forEach((slot, index) => {
    if (settings.viewId !== "me" || myEmpId === slot.empId) {
      slots.push(
        <CalendarSlot
          key={getSlotQuestUUID(slot)}
          slot={slot}
          settings={settings}
          ui={ui}
          data={data}
          tools={tools}
          myEmpId={myEmpId}
          cellType={index % 2}
          originalPersonnelMap={originalPersonnelMap}
          departmentsById={departmentsById}
        />
      );
    }
  });

  return (
    <div className={"DataGroup Requests"}>
      <div className="slots">{slots}</div>
    </div>
  );
};

export default CalendarDateBoxGroup;
