/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment-timezone";
import { isDateInPaddedRange } from "./dateChecks";

import { getAllDatesBetweenRange } from "./dateUtils";

/**
 * Converts a flat array into a two-dimensional array where each
 * sub array is of length width.
 * @param arr An array of type <T>
 * @param width The length of each new array
 * @param result Two-dimensional array of arrays of length width
 * @returns A two dimensional array containing arrays of lenth width
 */
const splitFlatArrayIntoMultiDimOfXWidth = <T>(
  arr: Array<T>,
  width: number,
  result: Array<Array<T>> = []
): Array<Array<T>> => {
  const results = [...result];

  if (result.length === Math.ceil(arr.length / width)) {
    return result;
  }

  const startIndex = result.length * width;
  const endIndex = startIndex + width;
  const rowOfWidthX = arr.slice(startIndex, endIndex > arr.length ? undefined : endIndex);

  results.push(rowOfWidthX);

  return splitFlatArrayIntoMultiDimOfXWidth(arr, width, results);
};

/**
 *  Accepts a one-dimensional array of strings compliant with moment.MomentFormatSpecification and returns a two dimensional array of date rows of length rowWidthAsDays. Each date row consists
 *  of date strings sorted in the given chronological order. Each date row only contains the dates
 *  within the row bounds as defined as:
 *  @description rowOfDates -> A member of the result of splitting a one-dimensional
 *  array of all dates within the given start and end dates (inclusive of both) into rows of length rowWidthAsDays
 *  @description lowerBound -> The start date of the row of which the first item of a rowOfDates width rowWidthAsDays belongs
 *  @description upperBound -> The end date of the row of which the first item of an array of width rowWidthAsDays belongs
 *
 *  @param arr A one-dimensional array of date strings in an acceptable moment.js date format,
 *  expected to be sorted in chronilogical ascending order
 *  @param rowWidthAsDays Size of a date row.
 *  @param startDate Start date of the range, this is included in the range
 *  @param endDate End date of the range, this is included in the range
 */

export const convertDateArrayIntoDateRowsOfXWidth = (
  arr: Array<moment.MomentFormatSpecification>,
  rowWidthAsDays: number,
  startDate: string,
  endDate: string
): Array<Array<string>> => {
  if (!arr || !arr.length || rowWidthAsDays === 0 || !startDate || !endDate) return [];
  //determine date format for input array items
  const dateFormat = moment(arr[0] as string).creationData().format as string;

  // if a valid date format cannot be found, bail
  if (!dateFormat) return [];

  // Gets all dates in the supplied range, inclusive of start and end dates
  const flatDatesInRangeInclusive = getAllDatesBetweenRange(startDate, endDate, dateFormat);

  // Holds a collection of the upper and lower bounds for
  // each row within the provided date range split
  // into X width
  const dateRowBounds: moment.Moment[][] = [];

  // Get lower and upper date bounds for date row of x width (determined by rowWidthAsDays)
  // Converts a one-dimensional date string array into a two-dimensional date string array
  // of X width (determined by rowWidthAsDays). Once the flat array is converted,
  // hydrate the dateBoundsMap
  splitFlatArrayIntoMultiDimOfXWidth(flatDatesInRangeInclusive, rowWidthAsDays).forEach((rowOfDates) => {
    // Because rowOfDates is in ascending chronological we can ascertain the bounds for this row
    // by getting the bounds of the first item
    const bounds: [moment.Moment, moment.Moment] = [
      moment(rowOfDates[0]).utc(false),
      moment(rowOfDates[rowOfDates.length - 1]).utc(false),
    ];

    dateRowBounds.push(bounds);
  });

  // contains all dates from the provided input
  // bounded by the bounds for each row of the
  // provided date range. If the given input
  // does not contain a date for a certain row
  // the value for the row is set to an empty
  // array; this informs the column renderer
  // that the row is blank without messing up
  // the order of the column row groups
  const dateRows: string[][] = [];

  // iterate over the row bounds
  // and construct a row of the
  // provided dates within the bounds
  // of the row.
  dateRowBounds.forEach((boundSet) => {
    const rowItems: string[] = [];
    const [start, stop] = boundSet;

    arr.forEach((date) => {
      const dateIsInRow = isDateInPaddedRange({
        date: moment(date as string),
        start,
        stop,
      });

      if (dateIsInRow) rowItems.push(date as string);
    });

    dateRows.push(rowItems);
  });

  return dateRows ?? [];
};

interface Item {
  [k: string]: any;
}

export const sortByProperty = <T extends Item>(array: Array<T>, property: string): Array<T> => {
  const sorted = array.sort((a, b) => {
    if (a[property] !== null && b[property] !== null) {
      if (a[property] < b[property]) {
        return -1;
      }
      if (a[property] > b[property]) {
        return 1;
      }
      return 0;
    }
    return 0;
  });
  return sorted;
};
