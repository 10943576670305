import React from "react";
import moment from "moment";

import { IColumnPrintingBaseProps, StandardColumnProps } from "./printColumnFactory";

import { getEffects, getPersonnelOrAssignmentId, isVisible } from "@/viewer/utils/domain/perssignment";
import { visibleDateRangeInclusive } from "@/viewer/ui/modules/common/helpers/dates";
import { formatDayKey, formatISODate } from "@/viewer/utils/dateFormatters";

import PrintColumnRequestGroupHeader from "./printColumnRequestGroupHeader";
import ColumnHeaderCell from "../../components/ColumnHeaderCell";
import PrintColumnRequestItem from "./printColumnRequestItem";
import PrintColumnTallyItems from "./printColumnTallyItems";
import PrintColumnSlotItem from "./printColumnSlotItem";

import { COLUMN_WIDTH_PX, COLUMN_WIDTH_PX_CONDENSED, getCellHeight, ZERO_HEIGHT_CELL_STRING } from "../constants";

import "./_styles.scss";
import { DepartmentsById } from "@/viewer/types/domain/department";

type Props = IColumnPrintingBaseProps & StandardColumnProps & { departmentsById: DepartmentsById };

const StandardPrintColumn = (props: Props): JSX.Element => {
  const {
    columnDate,
    columnRowHeightMap,
    departmentsById,
    leftColumnData,
    leftHandColumnId,
    reportData,
    reportResult,
    requestData,
    scale,
    settings,
    slotData,
    tallies,
    ui,
    viewData,
  } = props;

  const { condenseColumnView } = settings;
  const columnItems: JSX.Element[] = [];
  const requestItems: JSX.Element[] = [];

  const cellDate = visibleDateRangeInclusive(settings, columnDate, columnDate)[0];
  const cellKey = formatISODate(settings, cellDate);
  const dayKey = formatDayKey(settings, cellDate);

  if (!cellDate) return <></>;

  const baseProps = { cellDate, cellKey, settings, viewData };
  const tallyProps = { ...baseProps, reportData, reportResult, tallies, dayKey };
  const slotQuestSharedProps = { ...baseProps, columnRowHeightMap, ui, leftHandColumnId };
  const slotProps = { ...slotQuestSharedProps, slotData };
  const requestProps = { ...slotQuestSharedProps, requestData };
  const columnWidth = (condenseColumnView ? COLUMN_WIDTH_PX_CONDENSED : COLUMN_WIDTH_PX) * scale;
  const { forceRequestsGroup, forceScheduleGroup, hideBlankRows, viewDataType } = settings;
  const { filters } = viewData;
  const cellHeight = getCellHeight(ui);

  columnItems.push(<PrintColumnTallyItems {...tallyProps} key={`column-tally-items-${leftHandColumnId}`} />);

  const renderSlotItem = (
    cellEffects: React.CSSProperties,
    leftColId: string,
    visibleItemCount: number
  ): JSX.Element | null => {
    const { slot } = columnRowHeightMap.get(leftColId) ?? { slot: null };
    const displayBlankSlotRow = !hideBlankRows && forceScheduleGroup;
    const rowIndex = slot?.index as number;
    let height = slot?.rowHeights[leftHandColumnId];

    // If there is no height, either undefined or
    // default of 0px as assigned from the heightmap
    // and we are showing blank rows, assign the cell
    // height as defined from the getCellHeight
    // helper function
    if (!height || height === ZERO_HEIGHT_CELL_STRING) {
      if (!displayBlankSlotRow) return null;

      height = cellHeight;
    }

    const itemProps = {
      ...slotProps,
      cellEffects,
      height,
      leftColId,
      visibleItemCount,
      rowIndex,
    };

    return (
      <PrintColumnSlotItem
        {...itemProps}
        key={`column-slot-${leftColId}-${leftHandColumnId}`}
        departmentsById={departmentsById}
      />
    );
  };

  const renderRequestItem = (
    cellEffects: React.CSSProperties,
    leftColId: string,
    visibleItemCount: number
  ): JSX.Element | null => {
    const { request } = columnRowHeightMap.get(leftColId) ?? { request: null };
    const displayBlankRequestRow = !hideBlankRows && forceRequestsGroup;
    const rowIndex = request?.index as number;
    let height = request?.rowHeights[leftHandColumnId];

    // If there is no height, either undefined or
    // default of 0px as assigned from the heightmap
    // and we are showing blank rows, assign the cell
    // height as defined from the getCellHeight
    // helper function
    if (!height || height === ZERO_HEIGHT_CELL_STRING) {
      if (!displayBlankRequestRow) return null;

      height = cellHeight;
    }

    const itemProps = {
      ...requestProps,
      cellEffects,
      height,
      leftColId,
      visibleItemCount,
      rowIndex,
    };

    return (
      <PrintColumnRequestItem
        {...itemProps}
        key={`column-request-${leftColId}-${leftHandColumnId}`}
        departmentsById={departmentsById}
      />
    );
  };

  let visibleSlotCount = 0;
  let visibleRequestCount = 0;

  leftColumnData.forEach((leftColumn) => {
    if (!isVisible(leftColumn, ui, filters)) return;

    const cellEffects = getEffects(leftColumn, settings);
    const leftColId = getPersonnelOrAssignmentId(leftColumn);

    if (viewDataType !== "request") {
      const slotItem = renderSlotItem(cellEffects, leftColId, visibleSlotCount);

      if (slotItem) {
        columnItems.push(slotItem);
        visibleSlotCount++;
      }
    }

    if (viewDataType === "request" || viewDataType === "combined") {
      const requestItem = renderRequestItem(cellEffects, leftColId, visibleRequestCount);

      if (requestItem) {
        requestItems.push(requestItem);
        visibleRequestCount++;
      }
    }
  });

  const { LbsAppData }: any = window as any;

  return (
    <>
      <div
        className="column-items"
        key={`columnItems_${columnDate}`}
        style={{
          width: `${columnWidth}px`,
          fontSize: `${100 * scale}% !important`,
        }}
      >
        <div className="column-items-header">
          <ColumnHeaderCell
            settings={settings}
            date={columnDate}
            isDateInSchedule={LbsAppData.DateManager.isDateInSchedule(moment(columnDate))}
            isHoliday={LbsAppData.AppContext._isHoliday(moment(columnDate))}
          />
        </div>
        {tallies?.length ? (
          <div className="column-print-cell-wrapper" style={{ height: "20px", borderRight: "none" }}>
            <div className="column-items__tally-header" />
          </div>
        ) : null}
        {columnItems}
        {forceRequestsGroup || requestItems.length ? (
          <>
            <PrintColumnRequestGroupHeader leftHandHeader={false} />
            {requestItems}
          </>
        ) : null}
      </div>
    </>
  );
};

export default StandardPrintColumn;
