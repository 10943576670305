/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import ConfigHelper from "@/_lib/data/ConfigHelper";
import { get } from "@/_lib/utils/fetch";
import { AssignmentGroup, Group } from "../types/groups.types";
import { sortByProperty } from "@/_lib/utils/arrayUtils";

export interface PersonnelGroupsData {
  getPersonnelGroups: (id: number) => Promise<Group[]>;
  getAssignmentsGroups: (id: number) => Promise<Group[]>;
}

const useGroupsData = (): PersonnelGroupsData => {
  const host = ConfigHelper.host_lbapi;

  const getPersonnelGroups = async (id: number): Promise<Group[]> => {
    const url = `${host}/groups?dept_list=${id}&visible_only=true`;

    const { data } = await get<Group[]>(url);
    if (data) {
      const groups = sortByProperty(data, "name");

      return groups;
    }
    return [];
  };

  const getAssignmentsGroups = async (id: number): Promise<Group[]> => {
    const url = `${host}/assigngroupsforviewer?dept_list=${id}&visible_only=true`;

    const { data } = await get<AssignmentGroup[]>(url);
    if (data) {
      // Map members to assign_structure_ids property for the dropdown (shared component for both personnel and assignments filters)
      const assignmentGroups = data.map((item) => {
        return {
          group_id: item.group_id,
          name: item.name,
          description: item.description,
          dept_id: item.dept_id,
          members: item.assign_structure_ids,
        };
      });

      const groups = sortByProperty(assignmentGroups, "name");

      return groups;
    }
    return [];
  };

  return {
    getPersonnelGroups,
    getAssignmentsGroups,
  };
};

export default useGroupsData;
