import React from "react";
import { useDispatch, useSelector } from "react-redux";

import modalActions from "@/common/components/modal/actions";
import { FADE_OUT_DURATION } from "@/common/components/modal/Modal";
import { RootState } from "@/modules/reducer";

const ModalWrapper = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { modal, isOpen } = useSelector((state: RootState) => state.modal);

  /**
   * Disabling scrolling on the body causes the scrollbar to completely disappear.
   * This behaviour is desired, although it causes the content to "jump" a little in
   * order to fill the remaining space. Manually adding a width offset prevents the jump
   * from occurring.
   */
  React.useLayoutEffect(() => {
    if (isOpen) {
      const html = document.querySelector("html");
      const scrollbarWidth = !html ? 0 : window.innerWidth - html.offsetWidth;

      document.body.style.overflow = "hidden";
      if (scrollbarWidth) document.body.style.width = `calc(100% - ${scrollbarWidth}px)`;
    } else {
      setTimeout(() => {
        document.body.style.overflow = "unset";
        document.body.style.width = "unset";

        dispatch(modalActions.remove());
      }, FADE_OUT_DURATION);
    }
  }, [isOpen]);

  return <>{modal}</>;
};

export default ModalWrapper;
