import moment from "moment";
import * as React from "react";
import { CallbackContext } from "@/viewer/ui/modules/common/helpers/callbacks";
import { SettingsContext } from "@/viewer/ui/modules/common/types/context";

export default (settings: SettingsContext, date: Date) => {
  const { openDateContextMenu, focusHolidayDate, unFocusHolidayDate } = React.useContext(CallbackContext);
  const onClick = (e: React.MouseEvent<HTMLDivElement>) => openDateContextMenu(settings, date, e.target);
  const onMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    focusHolidayDate({
      data: moment(date),
      isHoveringDate: true,
      rect: e.currentTarget.getBoundingClientRect(),
    });
  };

  const onMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    unFocusHolidayDate({
      data: moment(date),
      isHoveringDate: false,
      rect: e.currentTarget.getBoundingClientRect(),
    });
  };

  return { onClick, onMouseEnter, onMouseLeave };
};
