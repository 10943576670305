// Header dates
import classnames from "@/_lib/utils/classnames";
import * as React from "react";
import useDateHeaderCallbacks from "@/viewer/ui/modules/common/hooks/useDateHeaderCallbacks";
import { isToday, isWeekend } from "date-fns";
import { formatStandardDay, formatUSDate } from "@/viewer/utils/dateFormatters";
import { SettingsContext } from "@/viewer/ui/modules/common/types/context";

interface Props {
  settings: SettingsContext;

  date: Date;
  isHoliday: boolean;
  isDateInSchedule: boolean;
}

const StandardWeekHeaderCell = (props: Props): JSX.Element => {
  const { settings, date, isDateInSchedule, isHoliday } = props;

  const headClassList = classnames("date", {
    weekend: isWeekend(date),
    notInSchedule: !isDateInSchedule,
    today: isToday(date),
    holiday: isHoliday,
  });

  return (
    <div
      className={headClassList}
      data-cy="dayColumn"
      data-date={formatUSDate(settings, date)}
      {...useDateHeaderCallbacks(settings, date)}
    >
      {formatStandardDay(settings, date)}
    </div>
  );
};

export default StandardWeekHeaderCell;
