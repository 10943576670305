const Backbone = require("backbone");
var moment = require("moment");
var _ = require("underscore");

var HighlightTool = require("./HighlightTool.js");

var SelectTool = Backbone.Model.extend({
  // sibling highlighter to outline related slots
  //siblingHighlighter: new HighlightTool({backgroundColor: '#FDFDD9'}),

  defaults: {
    type: "select",
    desc: "select",
    backgroundColor: "#FEFF84",
    color: "#000000",

    target: undefined,
    component: undefined,
    value: undefined,
    structureId: undefined,
  },

  onSelect: function () {
    this._reset();
  },

  onAttachPre: function () {},

  onAttach: function () {
    var slot = this.attributes.target;

    // bind to the slot object
    this.attributes.target.set({ boundTool: this });

    // highlight the slot siblings
    //if (slot.id) {
    //this.siblingHighlighter.click(slot);
    //}

    // kick a fill dialog, if it's already open then update it, cause it means they moved slots in the dialog
    var dialogStack = window.LbsAppData.AppContext.attributes.dialogStack;
    if (dialogStack.length && dialogStack[dialogStack.length - 1].type == "select") {
      var thisDialog = dialogStack[dialogStack.length - 1];
      thisDialog.focusedSlot = slot;
      thisDialog.focusedPersonnel = slot.personnelObj;
      thisDialog.focusedAssignment = slot.assignmentObj;

      // trigger a redraw on the parent
      this.attributes.component.forceUpdateOnParent();
    } else {
      // if there is a pane open, close it here before the dialog open
      window.LbsAppData.AppContext.closePane();
      window.LbsAppData.AppContext.closeDialog();
      window.LbsAppData.AppContext.openDialog({
        type: "select",
        focusedSlot: slot,
        focusedAssignment: slot.assignmentObj,
        focusedPersonnel: slot.personnelObj,
        focusedDate: moment(this.attributes.component.props.date).format("YYYY-MM-DD"),
      });
    }
  },

  onExecute: function () {
    var slot = this.attributes.target;
    var structureId = this.attributes.structureId;

    if (slot) {
      switch (window.LbsAppData.AppContext.get("GridSettings_leftColumnType")) {
        case "assignment":
          window.LbsAppData.Helpers.Analytics.sendEvent("Update: Slot", "Select Tool: Personnel");
          window.LbsAppData.ToolBox._replacePersonnel(slot);
          break;
        case "personnel":
          window.LbsAppData.Helpers.Analytics.sendEvent("Update: Slot", "Select Tool: Assignment");
          window.LbsAppData.ToolBox._replaceAssignmentStep1(slot, structureId);
        default:
          break;
      }
    }
  },

  onFinished: function () {
    this.forceUpdateComponent();
  },

  onClose: function () {
    this._reset();
  },

  /* publics */
  getPersonnelForEmptySlot: function () {
    return window.LbsAppData.Personnel.get(this.attributes.target.attributes.emp_id);
  },

  getAssignmentForEmptySlot: function () {
    return window.LbsAppData.Assignments.get(this.attributes.target.attributes.assign_plus_structure_id);
  },

  style: function () {
    return {
      backgroundColor: this.attributes.backgroundColor,
      color: this.attributes.color,
    };
  },

  forceUpdateComponent: function () {
    if (this.attributes.component) {
      this.attributes.component.forceUpdateOnParent();
    }
  },

  reset: function () {
    this._reset();
  },

  /* privates */
  _getPersonnelForEmptySlot: function () {
    return window.LbsAppData.Personnel.get(this.attributes.target.attributes.emp_id);
  },

  _getAssignmentForEmptySlot: function () {
    return window.LbsAppData.Assignments.get(this.attributes.target.attributes.assign_plus_structure_id);
  },

  _reset: function () {
    if (this.attributes.target) {
      // unbind the tool from the slot
      this.attributes.target.set({ boundTool: undefined });

      // trigger a redraw on the parent
      this.attributes.component.forceUpdateOnParent();

      // reset the state
      this.set({
        target: undefined,
        component: undefined,
        value: undefined,
        structureId: undefined,
      });
    }

    // reset the sibling highlighter
    //this.siblingHighlighter.reset();

    // remove the alternates dialog
    window.LbsAppData.AppContext.closeDialog();
  },
});

module.exports = SelectTool;
