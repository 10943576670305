import { Model } from "backbone";

import connectedPropertyHookFactory from "@/viewer/ui/modules/common/hooks/connectedPropertyHookFactory";

import { cleanTemplateAttributes, TemplatesById } from "@/viewer/types/domain/template";

const getTemplates = (): TemplatesById => {
  const { LbsAppData } = window as any;

  const out: TemplatesById = {};
  const templatesCollection = LbsAppData.Templates;

  if (!templatesCollection) return out;

  templatesCollection.forEach((templateModel: Model) => {
    const template = cleanTemplateAttributes(templateModel.attributes);
    out[template.id] = template;
  });

  return out;
};

export default connectedPropertyHookFactory({
  loader: getTemplates,
  getListeners: () => {
    const { LbsAppData } = window as any;
    return [[LbsAppData.AppContext, "fetched"]];
  },
});
