import * as React from "react";
import SlotContextClues from "@/viewer/ui/modules/common/components/SlotContextClues";
import SlotDisplay from "@/viewer/ui/modules/common/components/SlotDisplay";
import { DataContext, SettingsContext, ToolContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import SlotContainer from "@/viewer/ui/modules/common/components/SlotContainer";
import { CellType } from "@/viewer/ui/modules/common/types/contextClues";
import { OriginalPersonnelMap } from "@/viewer/types/viewdata";
import { DepartmentsById } from "@/viewer/types/domain/department";

interface Props {
  slot: SlotOrRequest;
  settings: SettingsContext;
  ui: UIContext;
  data: DataContext;
  tools: ToolContext;
  leftColId: string;
  originalPersonnelMap: OriginalPersonnelMap;
  cellEffects: React.CSSProperties;
  departmentsById: DepartmentsById;
  cellType?: CellType;
}

const ColumnSlot = (props: Props): JSX.Element => {
  const { slot, data, tools, settings, ui, cellType, cellEffects, originalPersonnelMap, leftColId, departmentsById } =
    props;

  const slots = React.useMemo(() => [slot], [slot]);

  return (
    <SlotContainer
      settings={settings}
      ui={ui}
      data={data}
      slots={slots}
      tools={tools}
      departmentsById={departmentsById}
      className="slot"
    >
      <div className="assignment">
        <SlotDisplay
          slot={slot}
          settings={settings}
          ui={ui}
          leftColId={leftColId}
          originalPersonnelMap={originalPersonnelMap}
          departmentsById={departmentsById}
        />
      </div>

      <SlotContextClues
        slot={slot}
        settings={settings}
        ui={ui}
        data={data}
        tools={tools}
        isDataGroup={false}
        slotEffects={cellEffects}
        cellType={cellType}
      />
    </SlotContainer>
  );
};

export default ColumnSlot;
