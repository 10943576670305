import { ViewerContext } from "@/viewer/types/ViewerApiResponse";

export const convertViewerAPIMeViewToOldViewer = (view: ViewerContext, personnelId: number): any => {
  if (view.view_id === 0 && view.name === "Me") {
    return {
      view_id: "me",
      name: "Me",
      filter: {
        on_personnel: [personnelId],
        on_assignments: [],
      },
      theme: {
        data: {
          layout: "calendar",
          GridSettings_leftColumnType: "personnel",
          startOnDay: "Sun",
          colorMethod: "none",
          colorTextMethod: "none",
          slotOrdering: "start_time!assign_display_name",
          showTimes: "yes",
        },
      },
    };
  }
  return view;
};

export const convertViewerAPITodayViewToOldViewer = (view: ViewerContext): unknown => {
  if (view.view_id === 0 && view.name === "Daily") {
    return {
      view_id: "today",
      name: "Daily",
      filter: {
        on_assignments: [],
        on_personnel: [],
      },
      theme: {
        data: {
          layout: "gantt",
          range: "day",
          GridSettings_leftColumnType: "assignment",
          colorMethod: "none",
          colorTextMethod: "none",
          slotOrdering: "stop_time!start_time!assign_display_name!personnel_display_name",
        },
      },
    };
  }
  return view;
};

export const transformViewsToOldViewer = (views: any[], personnelId: number): unknown[] => {
  const modifiedViews = views.map((view) => ({ ...view }));
  const finalViews = modifiedViews.map((view) => {
    const updatedView = convertViewerAPIMeViewToOldViewer(view, personnelId);
    const finalView = convertViewerAPITodayViewToOldViewer(updatedView);
    return finalView;
  });
  return finalViews;
};

export const tranformSingleViewToOldViewer = (view: any, personnelId: number): unknown => {
  const copyView = view;
  const modifiedView = convertViewerAPIMeViewToOldViewer(copyView, personnelId);
  const finalView = convertViewerAPITodayViewToOldViewer(modifiedView);
  return finalView;
};
