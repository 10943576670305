"use strict";

const React = require("react/addons");
const moment = require("moment");
const SafeJSON = require("../../../../../../../common/utils/jsonParseShield");
const classnames = require("@/_lib/utils/classnames").default;
const { LDFlagEnums } = require("@/_lib/constants/LDFlagEnums");
const RequestEditDialog = require("../../slot_edit/RequestEditDialog");

// some status string constants
const STATUS_PENDING = "pending";
const STATUS_SUSPENDED = "suspended";
const STATUS_GRANTED = "granted";
const STATUS_DENIED = "denied";
const STATUS_DELETED = "deleted";

const RequestDetailsDialogMain = React.createClass({
  /* lifecycle methods */
  getInitialState: function () {
    return {
      activeTab: "general",
    };
  },

  /* publics */
  close: function () {
    this.props.close();
  },

  /* privates */
  _setCurrentTab: function (id) {
    this.setState({ activeTab: id });
  },
  _deleteRequest: function () {
    const that = this;

    window.LbsAppData.AppContext.openDialog({
      type: "prompt",
      message: "Delete this request?",
      callback: function () {
        // at this point we are stacked 3 dialogs deep...we need to close the top 2
        that.props.close();
        that.props.close();

        window.LbsAppData.AppContext.openLoadingDialog("Deleting request...", this, function () {
          that.props.dialogObject.data.destroy({
            wait: true,
            success: function (model) {
              // close the loading dialog
              window.LbsAppData.AppContext.closeDialog();

              // display message
              window.LbsAppData.AppContext.openDialog({
                type: "message",
                message: "Request Deleted",
                timer: 2000,
              });

              // the list dialog carries its own collection, so this requests also needs to be removed
              // from the window (if it exists) in order for the redraw to remove it entirely
              if (window.LbsAppData.Requests.get(model.id)) {
                window.LbsAppData.Requests.remove(model.id, { silent: true });
              }

              // meanwhile... we're gonna use the 'request submitted' curtain to trigger a redraw
              window.LbsAppData.AppContext.triggerRedraw();
            },
            error: function (model, response) {
              // close the loading dialog
              window.LbsAppData.AppContext.closeDialog();

              // display message
              window.LbsAppData.AppContext.openDialog({
                type: "request-errors",
                errors: SafeJSON.parse(response.responseText),
              });
            },
          });
        });
      },
    });
  },
  _openEditDialog: function () {
    window.LbsAppData.AppContext.openDialog({
      type: "request-edit",
      data: this.props.dialogObject.data,
    });
  },
  _expandNoteDialog: function (type) {
    window.LbsAppData.AppContext.openDialog({
      type: "note-full",
      note: this.props.dialogObject.data.get(type),
      readonly: true,
    });
  },

  _getRequestSentence: function (request) {
    const templateText = request.attributes.template_desc;
    const type = request.attributes.command_type === 1 ? "Don't schedule" : "Schedule";
    const date = moment(request.attributes.request_date).format("ddd, LL");
    const department_name = window.LbsAppData.Departments.get(request.attributes.department_id).get("name");

    // an undefined/null start_time means its a default time...the way requests work..thats not so easy to figure out what the default time is
    // as time is tied to structures, and requests don't have a structure until they are scheduled.
    let timeElement = {};

    if (request.attributes.start_time) {
      //TODO Replace date format strings with constants
      const startDate = moment(request.attributes.start_time, "YYYY-MM-DDTHH:mm:ss");
      const stopDate = moment(request.attributes.stop_time, "YYYY-MM-DDTHH:mm:ss");
      const timeString =
        startDate.format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT")) +
        " - " +
        stopDate.format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"));

      timeElement = (
        <div>
          <span>{timeString}</span>
          <br />
        </div>
      );
    }

    return (
      <div className="sentence">
        <span className="uppercase semi-large">{date}</span>
        <br />
        {timeElement}
        <span className="large underline">{request.get("assign_display_name") + " (" + templateText + ")"}</span>
        <br />
        <div className="department-text-container">
          <span className="large underline department-name-overflow">{department_name}</span>
          <span className="large underline">&nbsp;</span>
          <span className="large underline">{"(Department)"}</span>
        </div>
        <span className="uppercase">{type}</span> <span className="large underline">{request.get("display_name")}</span>
      </div>
    );
  },

  _getRequestViolation: function (request) {
    let ret;

    if (request.attributes.violation_msg) {
      ret = (
        <div className="violation-details">
          <div className="violation-title">Request Manager</div>
          <div className="violation-content">
            <span className="message">
              <span className="message-text">{request.attributes.violation_msg}</span>
              <span className="expand-text" onClick={() => this._expandNoteDialog("violation_msg")}>
                more
              </span>
            </span>
          </div>
        </div>
      );
    }

    return ret;
  },
  _getRequestDetails: function (request) {
    let ret;

    const additionalDetailDivs = [];

    if (request.attributes.location_ids.length) {
      additionalDetailDivs.push(
        <div className="additional-details-item" key={"location"}>
          <span className="additional-details-item-title">Location</span> &mdash;{" "}
          <span className="dark">{request.attributes.location_names.join(", ")}</span>
        </div>
      );
    }

    if (additionalDetailDivs.length) {
      ret = (
        <div className="additional-details">
          <div className="additional-details-title">Details</div>
          <div className="additional-details-content">{additionalDetailDivs}</div>
        </div>
      );
    }

    return ret;
  },
  _getCreatedSection: function (request) {
    const submittingPersonnel = request.attributes.created_by_display_name;
    const submittedTime = moment(request.attributes.timestamp);
    let submissionText = (
      <div className="sentence">
        <span className="dark">{submittedTime.format(window.LbsAppData.Helpers.Time.preferredTimeFormat("lll"))}</span>{" "}
        by <span className="dark">{submittingPersonnel}</span>
      </div>
    );

    let submissionReason;

    if (request.attributes.loa_reason_name) {
      submissionReason = (
        <span className="reason">
          Reason &mdash; <span className="dark">{request.attributes.loa_reason_name}</span>
        </span>
      );
    }

    let submissionMessage;

    if (request.attributes.message) {
      submissionMessage = (
        <span className="message">
          <sup>
            <i className="fa fa-quote-left" />
          </sup>
          <span className="message-text">{request.attributes.message}</span>
          <span className="expand-text" onClick={() => this._expandNoteDialog("message")}>
            more
          </span>
          <sup>
            <i className="fa fa-quote-right" />
          </sup>
        </span>
      );
    }

    return (
      <div className="stages-item">
        <div className="stages-item-title">Submitted</div>
        <div className="stages-item-content">
          <div className="stages-item-subtext">
            {submissionText}
            {submissionReason}
          </div>
          {submissionMessage}
        </div>
      </div>
    );
  },
  _getModifiedSection: function (request) {
    let modificationText;

    if (request.attributes.modified_timestamp) {
      const modifyingPersonnel = request.attributes.modified_by_display_name;
      const modifyingTime = moment(request.attributes.modified_timestamp);
      modificationText = (
        <div className="sentence">
          <span className="dark">
            {modifyingTime.format(window.LbsAppData.Helpers.Time.preferredTimeFormat("lll"))}
          </span>{" "}
          by <span className="dark">{modifyingPersonnel}</span>
        </div>
      );
    } else {
      modificationText = "No modifications.";
    }

    let modificationReason;

    if (request.attributes.denial_reason_name) {
      modificationReason = (
        <span className="reason">
          Reason &mdash; <span className="dark">{request.attributes.denial_reason_name}</span>
        </span>
      );
    }

    let modificationMessage;

    if (request.attributes.decision_msg) {
      modificationMessage = (
        <span className="message">
          <sup>
            <i className="fa fa-quote-left" />
          </sup>
          <span className="message-text">{request.attributes.decision_msg}</span>
          <span className="expand-text" onClick={() => this._expandNoteDialog("decision_msg")}>
            more
          </span>
          <sup>
            <i className="fa fa-quote-right" />
          </sup>
        </span>
      );
    }

    return (
      <div className="stages-item">
        <div className="stages-item-title">Last Modified</div>
        <div className="stages-item-content">
          <div className="stages-item-subtext">
            {modificationText}
            {modificationReason}
          </div>
          {modificationMessage}
        </div>
      </div>
    );
  },

  /* render */
  render: function () {
    /* jshint ignore:start */
    const request = this.props.dialogObject.data;
    const status = request.attributes.status;

    const requestText = this._getRequestSentence(request);
    const requestViolation = this._getRequestViolation(request);
    const requestDetails = this._getRequestDetails(request);
    const sectionCreated = this._getCreatedSection(request);
    const sectionModified = this._getModifiedSection(request);

    const classes = classnames({
      Dialog: true,
      RequestDetails: true,
      granted: status === STATUS_GRANTED,
      denied: status === STATUS_DENIED,
      suspended: status === STATUS_SUSPENDED,
      pending: status === STATUS_PENDING,
      deleted: status === STATUS_DELETED,
    });

    let requestEditContent;
    if (this.props?.flags[LDFlagEnums.Lv7029MoveEditButtonsIntoSlotDetailsWindow]) {
      requestEditContent = (
        <div>
          <RequestEditDialog isDialog={false} dialogObject={this.props.dialogObject} />
        </div>
      );
    } else {
      requestEditContent = (
        <div className="edit-button" onClick={this._openEditDialog} onTouchEnd={this._openEditDialog}>
          Edit
        </div>
      );
    }

    return (
      <div className={classes}>
        <div className="position-reset">
          <div className="close" onClick={this.close} onTouchEnd={this.close}>
            <i className="fa fa-close" />
          </div>

          <div className="content">
            <div className="header">
              <div className="mobile-section-nav">
                <div
                  className={
                    this.state.activeTab === "general"
                      ? "mobile-section-nav-tab group-1 active"
                      : "mobile-section-nav-tab group-1"
                  }
                  onClick={() => this._setCurrentTab("general")}
                >
                  {status} Request
                </div>
              </div>
            </div>

            <div className="content-body">
              <div className="details">
                <div className="sentence-container">{requestText}</div>

                <div className="details-ext">
                  {requestDetails}
                  {requestViolation}
                </div>

                <div className="stages-container">
                  {sectionCreated}
                  {sectionModified}
                </div>
              </div>

              <div className="footer">{requestEditContent}</div>
            </div>
          </div>
        </div>
      </div>
    );
  },
});

module.exports = RequestDetailsDialogMain;
