var ApiConfig = require("_lib/data/ApiConfig.js");
var TalliesCollection = require("_lib/data/collections/NakedTallyCollection.js");

var TalliesNode = Backbone.Model.extend({
  //idAttribute: "emp_id",
  //urlRoot: (ApiConfig.getPrefix() + '/personnel'),

  defaults: {},
  initialize: function () {},

  // return a list of available tally properties
  properties: function () {
    // name is what we call it
    // key is what the api calls it
    // desc is how we describe it
    return [
      { name: "id", key: "tally_id", desc: "unique identifier" },
      { name: "name", key: "tally_name", desc: "tally name" },
    ];
  },

  // return a list of available tally objects
  objects: function () {
    this.get("objects") ? this.get("objects") : this._fetchObjects();
  },

  // fetch the actual tally objects -- kind of trying to do a lazy load throughout this application
  _fetchObjects: function () {
    var that = this;
    var objects = new TalliesCollection().fetch({
      success: function () {
        that.set({ objects: objects });
      },
    });
  },
});

module.exports = TalliesNode;
