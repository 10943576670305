/*
 * Template model that doesn't depend on window object bindings */
const Backbone = require("backbone");

var NakedTemplate = Backbone.Model.extend({
  idAttribute: "template_id",

  defaults: {},

  initialize: function (attributes, options) {},

  _isHidden: function () {
    return this.attributes.is_hidden;
  },
});

module.exports = NakedTemplate;
