import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import classnames from "@/_lib/utils/classnames";
import { getSlotEffects } from "@/viewer/utils/domain/slotquests";
import useHighlighterColor from "@/viewer/ui/modules/common/hooks/useSlotHighlightColor";

import slotEvents from "@/viewer/ui/modules/common/hooks/useSlotEvents";

import { DataContext, SettingsContext, ToolContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { CallbackContext } from "@/viewer/ui/modules/common/helpers/callbacks";

import SlotPopup from "@/viewer/ui/modules/common/components/SlotPopup";

import { DepartmentsById } from "@/viewer/types/domain/department";

import "./SlotContainer.scss";

interface Props {
  data: DataContext;
  settings: SettingsContext;
  slots: SlotOrRequest[];
  tools: ToolContext;
  ui: UIContext;
  departmentsById?: DepartmentsById;
  className?: string;
  children?: React.ReactNode;
}

const SlotContainer = (props: Props): JSX.Element => {
  const { slots, ui, settings, tools, data, children, departmentsById, className: propsClassName = "" } = props;
  const dispatch = useDispatch();
  const callbackContext = useContext(CallbackContext);

  if (slots.length < 1) return <></>;

  const slot = slots[0];
  const { style, className: toolClassName } = getSlotEffects(slot, settings, ui, data, tools);
  const className = classnames("Slot", "slot-container", propsClassName, toolClassName ?? "");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const highlighterColor = useHighlighterColor(ui, slot);

  const slotEventHandlers = slotEvents(callbackContext, dispatch, settings, slot, ui);

  const setStyleToSlotHighlight = () => {
    if (!ui.isBulkMode || (ui.isBulkMode && !style?.background)) {
      if (highlighterColor) {
        return { ...style, background: highlighterColor };
      }
    }

    return style;
  };

  const onMouseEnter = () => {
    setShowTooltip(true);
  };

  const onMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleOnContextMenu = (e: React.MouseEvent<HTMLElement>) => {
    const { onContextMenu } = slotEventHandlers;
    setShowTooltip(false);
    onContextMenu(e);
  };

  return (
    <div
      className={className}
      style={setStyleToSlotHighlight()}
      {...{ ...slotEventHandlers, onContextMenu: handleOnContextMenu }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={"slot-container__children-container"}>{children}</div>
      {showTooltip && (
        <div className={"slot-container__tooltip"}>
          <SlotPopup settings={settings} slots={slots} departmentsById={departmentsById} />
        </div>
      )}
    </div>
  );
};

export default SlotContainer;
