import * as React from "react";

import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { visibleDateRange } from "@/viewer/ui/modules/common/helpers/dates";
import getLeftColumnTitle from "@/viewer/ui/modules/common/helpers/getLeftColumnTitle";

import StandardWeekHeaderCell from "@/viewer/ui/modules/grid/standard/components/StandardWeekHeaderCell";

interface Props {
  dateStart: Date;
  dateEnd: Date;
  settings: SettingsContext;
  ui: UIContext;

  // LbsAppData.DateManager.isDateInSchedule
  isDateInSchedule: (d: Date) => boolean;

  // LbsAppData.AppContext._isHoliday
  isHoliday: (d: Date) => boolean;
}

const StandardWeekHeaderRow = (props: Props): JSX.Element => {
  const { dateStart, dateEnd, isDateInSchedule, isHoliday, settings, ui } = props;

  const leftColTitle = getLeftColumnTitle(settings, ui);

  const dates = visibleDateRange(settings, dateStart, dateEnd).map((rollingDate) => {
    return (
      <StandardWeekHeaderCell
        key={rollingDate.getTime()}
        settings={settings}
        date={rollingDate}
        isHoliday={isHoliday(rollingDate)}
        isDateInSchedule={isDateInSchedule(rollingDate)}
      />
    );
  });

  return (
    <>
      <div className="left-col-head">{leftColTitle}</div>
      {dates}
    </>
  );
};

export default StandardWeekHeaderRow;
