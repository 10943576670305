import { Action } from "./actions";

export interface State {
  searchTerms: string[];
}

const defaultState = {
  searchTerms: [],
};

export default (state: State = defaultState, action: Action): State => {
  const { type, payload } = action;

  switch (type) {
    case "csv/list-views/search-terms": {
      return {
        ...state,
        searchTerms: payload,
      };
    }

    default: {
      return state;
    }
  }
};
