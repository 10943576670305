"use strict";

const React = require("react/addons");

require("./_styles.scss");

const SuccessPopup = React.createClass({
  render: function () {
    return (
      <div className="success-popup">
        <div className="success-popup-header">
          <h1>{this.props.headerMessage || "Success!"}</h1>
        </div>
        <div className="check-icon">
          <span className="icon-line line-tip" />
          <span className="icon-line line-long" />
          <div className="icon-circle" />
          <div className="icon-fix" />
        </div>
        <div className="popup-message">{this.props.message || "Changes were successfully submitted."}</div>
      </div>
    );
  },
});

module.exports = SuccessPopup;
