import { AppAction } from "@/modules/types";

export interface State {
  message?: string;
}

export default (state: State = { message: undefined }, action: AppAction) => {
  switch (action.type) {
    case "login/login-action":
      return { message: action.message };
  }
  return state;
};
