import { SettingsContext } from "@/viewer/ui/modules/common/types/context";

export interface LocationAttributes {
  created_at: Date;
  updated_at: Date;
  name: string;
  description: string;
  location_id: number;
}

export interface Location {
  createdAt: Date;
  updatedAt: Date;
  name: string;
  description: string;
  locationId: number;
}

export const cleanLocationAttributes = (settings: SettingsContext, attrs: LocationAttributes): Location => {
  return {
    createdAt: attrs.created_at,
    updatedAt: attrs.updated_at,
    name: attrs.name,
    description: attrs.description,
    locationId: attrs.location_id,
  };
};
