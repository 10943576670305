// dependencies
const moment = require("moment");
const _ = require("underscore");

// enums
const Enums = require("_lib/data/Enums.js");

const Router = require("./Router.js");
const AppContext = require("./models/AppContext.js");

// collections
const DepartmentCollection = require("./collections/DepartmentCollection.js");
const TemplateCollection = require("./collections/TemplateCollection.js");
const PersonnelCollection = require("./collections/PersonnelCollection.js");
const AssignmentCollection = require("./collections/AssignmentCollection.js");
const ViewCollection = require("./collections/ViewCollection.js");
const SlotCollection = require("./collections/SlotCollection.js");
const RequestDataCollection = require("./collections/RequestDataCollection.js");
const AssignmentTypeCollection = require("./collections/AssignmentTypeCollection.js");
const PersonnelTypeCollection = require("./collections/PersonnelTypeCollection.js");
const LoaReasonCollection = require("_lib/data/collections/NakedLoaReasonCollection.js");
const DenialReasonCollection = require("_lib/data/collections/NakedDenialReasonCollection.js");
const LocationCollection = require("_lib/data/collections/NakedLocationCollection.js");
const HolidayCollection = require("./collections/HolidayCollection.js");

const slotQuestComparatorFactory = require("./comparators/SlotQuestComparatorFactory.js").default;

// models
const User = require("./models/User.js");
const UserSettings = require("./models/UserSettings.js");
const Personnel = require("./models/Personnel.js");
const Permissions = require("_lib/data/models/Permissions.js");
const Report = require("./models/Report.js");
const Destinations = require("_lib/data/models/Destinations.js");
const AccessibleApps = require("_lib/data/models/AccessibleApps.js");

// utilities
const DateManager = require("viewer/utils/DateManager.js");
const SlotManager = require("viewer/utils/SlotManager.js");
const ToolManager = require("viewer/utils/toolbox/ToolManager.js");

// helpers
const AnalyticsHelper = require("_lib/helpers/AnalyticsHelper.js");
const ErrorsHelper = require("_lib/helpers/ErrorsHelper.js");
const FeaturesHelper = require("_lib/helpers/FeaturesHelper.js");
const PermissionsHelper = require("_lib/helpers/PermissionsHelper.js");
const SwapsHelper = require("_lib/helpers/SwapsHelper.js");
const MessagingHelper = require("_lib/helpers/MessagingHelper.js");
const ExportHelper = require("_lib/helpers/ExportHelper.js");
const TimeHelper = require("_lib/helpers/TimeHelper.js");
const SafeJSON = require("../../common/utils/jsonParseShield");
const { getLDUser } = require("@/_lib/utils/launchDarklyHelper");
const { LaunchDarklyClient } = require("@/_lib/utils/launchDarklyClient");
const injectInstrumentation = require("@/_lib/utils/instrumentationClient").default;

const aToBStringComparator = (a, b) => {
  if (!a || !b) {
    return 0;
  }

  const orderA = a.toLowerCase();
  const orderB = b.toLowerCase();

  if (orderA === orderB) {
    return 0;
  }

  if (orderA < orderB) {
    return -1;
  }

  return 1;
};

const LbsAppData = (payload) => ({
  // app identifier
  id: "viewer",

  DOTGUY: 4,
  CONDENSED_DELIMITER: "~!",
  MILITARY: "military",
  HISTORY_TIME_ZONE: "US/Pacific",

  // ordering type constants
  PO_CUSTOM: "custom order",
  PO_DISPLAY: "display name",
  PO_LAST: "last name",
  PO_TIME_CUSTOM: "time then custom order",
  PO_TIME_DISPLAY: "time then display name",
  PO_TIME_LAST: "time then last name",
  PO_TIME_SUBMITTED: "request time submitted",

  AO_CUSTOM: "custom order",
  AO_DISPLAY: "name",
  AO_TIME_CUSTOM: "time then custom order",
  AO_TIME_DISPLAY: "time then name",

  // Action Types
  ACCEPT_ACTION: "accept",
  CANCEL_ACTION: "cancel",
  CANCEL_SWOP_ACTION: "cancel_swop",
  DECLINE_ACTION: "decline",
  DELETE_ACTION: "delete",
  DENY_ACTION: "deny",
  DETAILS_ACTION: "details",
  EDIT_TALLY_COUNT: "edit_tally_count",
  EXCHANGE_ACTION: "exchange",
  FILL_ACTION: "fill",
  FINALIZE_ACTION: "finalize",
  GRANT_ACTION: "grant",
  MODIFY_ACTION: "modify",
  MOVE_ACTION: "move",
  REMOVE_ACTION: "remove",
  REPLACE_ACTION: "replace",
  REQUEST_ACTION: "request",
  SPLIT_SHIFT_ACTION: "split_shift",
  SUSPEND_ACTION: "suspend",

  //DOTGUY: 4,
  AppContext: new AppContext(payload.view_context.theme.data),
  DateManager: new DateManager(),
  SlotManager: new SlotManager(),
  ToolBox: new ToolManager(),

  // helpers collection
  Helpers: {
    Analytics: new AnalyticsHelper(),
    Errors: new ErrorsHelper(),
    Features: new FeaturesHelper(),
    Permissions: undefined, // defer this until we get some base level data
    Swaps: undefined,
    Messaging: new MessagingHelper(),
    Export: new ExportHelper(),
    Time: new TimeHelper(),
  },

  Departments: undefined,
  AccessibleApps: undefined,
  Templates: new TemplateCollection(), // models added via the department initializer
  Personnel: undefined,
  PersonnelTypes: undefined,
  Assignments: undefined,
  AssignmentTypes: undefined,
  LoaReasons: undefined,
  DenialReasons: undefined,
  Locations: undefined,
  Slots: undefined,
  Requests: undefined,
  Report: undefined,
  Views: undefined,
  User: new User(SafeJSON.parse(payload.user)),
  UserSettings: new UserSettings(),
  Permissions: undefined,
  MyPersonnel: undefined,
  Destinations: undefined,

  init: function () {
    // sync backbone and refresh the token
    window.LbsAppData.AppContext._syncBackbone();
    injectInstrumentation();

    // if we're coming in from a different view, but changed dates then seed the DateManager appropriately
    let seed = moment();
    const queryString = window.location.search.replace("?", "");

    if (queryString) {
      const matches = queryString.match(/(?:dt|date)=([0-9]{8})/);

      if (matches) {
        seed = moment(matches[1], "YYYYMMDD");
      }
    }

    // probably should change this to look at range not view type
    if (this.AppContext.attributes.layout === "list") {
      if (this.AppContext.attributes.range === "week") {
        this.DateManager.setMode("range");
        this.DateManager.setScheduleEndpoints(moment(seed).startOf("day"), moment(seed).add(7, "days").endOf("day"));
      } else if (this.AppContext.attributes.range === "day") {
        this.DateManager.setMode("daily");
        this.DateManager.setScheduleEndpoints(moment(seed).startOf("day"), moment(seed).endOf("day"));
      }
    } else {
      // non list views (the norm)
      if (this.AppContext.attributes.range === "day") {
        // set the date manager to daily mode
        this.DateManager.setMode("daily");
        this.DateManager.setScheduleEndpoints(moment(seed).startOf("day"), moment(seed).endOf("day"));
      } else if (this.AppContext.attributes.range === "week") {
        // set the date manager to weekly mode
        this.DateManager.setMode("weekly");
        this.DateManager.setScheduleEndpoints(moment(seed).startOf("week"), moment(seed).endOf("week"));
      } else if (this.AppContext.attributes.range === "year") {
        // set the date manager to weekly mode
        this.DateManager.setMode("yearly");
        this.DateManager.setScheduleEndpoints(moment(seed).startOf("year"), moment(seed).endOf("year"));
      } else if (this.AppContext.attributes.range === "static") {
        // only found on block views right now
        // set the date manager to a static range mode
        const start = this.AppContext.attributes.blockStaticStart;
        const end = this.AppContext.attributes.blockStaticStop;

        this.DateManager.setMode("static");
        this.DateManager.setScheduleEndpoints(moment(start).startOf("day"), moment(end).endOf("day"));
      } else if (this.AppContext.attributes.range === "anchored") {
        // only found on block views right now
        // set the date manager to a dynamic range mode
        const start = moment(this.AppContext.attributes.blockAnchorDate);
        let end = moment(start).add(this.AppContext.attributes.blockViewTotalWeeks, "weeks").subtract(1, "days");
        // if our basedate isn't within the start and end dates then we need to go forward or
        // back until it is
        while (seed.isBefore(start, "day") || seed.isAfter(end, "day")) {
          if (seed.isBefore(start, "day")) {
            // baseDate is before -- step back
            start.subtract(this.AppContext.attributes.blockViewTotalWeeks, "weeks");
            end = moment(start).add(this.AppContext.attributes.blockViewTotalWeeks, "weeks").subtract(1, "days");
          } else if (seed.isAfter(end, "day")) {
            // baseDate is after -- step forward
            start.add(this.AppContext.attributes.blockViewTotalWeeks, "weeks");
            end = moment(start).add(this.AppContext.attributes.blockViewTotalWeeks, "weeks").subtract(1, "days");
          }
        }
        this.DateManager.setMode("anchored");
        this.DateManager.setAnchorStep(moment.duration(this.AppContext.attributes.blockViewTotalWeeks, "weeks"));
        this.DateManager.setScheduleEndpoints(moment(start).startOf("day"), moment(end).endOf("day"));
      } else {
        // set the date manager to monthly mode
        this.DateManager.setMode("monthly");
        this.DateManager.setScheduleEndpoints(moment(seed).startOf("month"), moment(seed).endOf("month"));
      }
    }

    this.DateManager.on("change:mode", this.DateManager.calculateDates);
    this.AppContext.on("change:startOnDay", () => {
      const locale = this.User.get("locale");

      this.AppContext.setMomentLocale(locale);
      this.DateManager.calculateDates();
    });

    window.LbsAppData.AppContext.pushViewContext();

    // build the collections by parsing the payload object
    this.Permissions = payload?.permissions && new Permissions(SafeJSON.parse(payload.permissions));

    this.Departments = payload?.departments && new DepartmentCollection(SafeJSON.parse(payload.departments));

    this.Destinations = payload?.destinations && new Destinations(SafeJSON.parse(payload.destinations));

    this.AccessibleApps = payload?.applications && new AccessibleApps(SafeJSON.parse(payload.applications));

    this.AssignmentTypes =
      payload?.assignment_types && new AssignmentTypeCollection(SafeJSON.parse(payload?.assignment_types));

    this.PersonnelTypes =
      payload?.personnel_types && new PersonnelTypeCollection(SafeJSON.parse(payload?.personnel_types));

    this.LoaReasons = payload?.loa_reasons && new LoaReasonCollection(SafeJSON.parse(payload?.loa_reasons));

    this.DenialReasons = payload?.denial_reasons && new DenialReasonCollection(SafeJSON.parse(payload?.denial_reasons));

    this.Locations = payload?.locations && new LocationCollection(SafeJSON.parse(payload.locations));

    // important this happens after departments...the personnel models got some init code
    this.MyPersonnel = payload?.user_personnel && new Personnel(SafeJSON.parse(payload.user_personnel));

    this.Assignments =
      payload?.assignments &&
      new AssignmentCollection(SafeJSON.parse(payload.assignments), {
        comparator: this._getAssignComparator(),
      });

    this.Personnel =
      payload?.personnel &&
      new PersonnelCollection(SafeJSON.parse(payload.personnel), {
        comparator: this._getPersonnelComparator(),
      });

    this.Views =
      payload?.views &&
      new ViewCollection(SafeJSON.parse(payload.views), {
        status: Enums.LoadingStatus.UNFETCHED,
      });

    this.Slots =
      payload?.schedule_data &&
      new SlotCollection(SafeJSON.parse(payload.schedule_data).data, {
        comparator: this._getSlotComparator(),
      });

    this.Requests =
      payload?.request_data &&
      new RequestDataCollection(SafeJSON.parse(payload.request_data).data, {
        comparator: this._getSlotComparator(),
      });

    this.Holidays =
      payload?.holidays &&
      new HolidayCollection(SafeJSON.parse(payload.holidays), {
        comparator: (a, b) => aToBStringComparator(a?.attributes?.dept_name, b?.attributes?.dept_name),
      });

    this.Holidays.setDeptsParam(this.Views);

    // tally data
    if (payload?.report_data) {
      this.Report = new Report(payload.report_data, {
        parse: true,
      });
    }

    // may be empty for now
    if (payload?.user_settings && payload.user_settings !== "") {
      var settings = SafeJSON.parse(payload.user_settings);
      if (settings?.settings_id !== 0) {
        this.UserSettings = new UserSettings(settings);
      }
    }

    // today view get's filtered by template
    if (this.AppContext.attributes.view.view_id === "today") {
      // grab the template id from the first slot

      // filter everything to start
      _.each(this.Templates.models, function (i) {
        i.set({ filtered: true });
      });

      // push a template context
      window.LbsAppData.AppContext.pushTemplateContext();
    }

    // Init launch darkly client
    const ldUserConfig = getLDUser(this.User.attributes);
    this.launchDarklyClient = new LaunchDarklyClient(ldUserConfig);

    // create the permissions helper
    this.Helpers.Permissions = new PermissionsHelper();
    this.Helpers.Swaps = new SwapsHelper();

    // register the AppContext events
    this.AppContext.registerEvents();
  },

  _getAssignComparator: function () {
    // set up the comparator based on the view settings, time sorting is only supported in terms of the slot level
    // and so we don't deal with that here...only the name or field (if custom ordering) matters for the collection order
    let assignComparator;
    const sortAssignmentsBy = window.LbsAppData.AppContext.attributes.view.filter.sort_assignments_by;

    if (sortAssignmentsBy === this.AO_CUSTOM || sortAssignmentsBy === this.AO_TIME_CUSTOM) {
      assignComparator = "order";
    } else {
      assignComparator = (a, b) => aToBStringComparator(a?.attributes?.display_name, b?.attributes?.display_name);
    }

    return assignComparator;
  },

  _getPersonnelComparator: function () {
    // set up the comparator based on the view settings, time sorting is only supported in terms of the slot level
    // and so we don't deal with that here...only the name or field (if custom ordering) matters for the collection order
    let personnelComparator;
    const sortPersonnelBy = window.LbsAppData.AppContext.attributes.view.filter.sort_personnel_by;
    if (sortPersonnelBy === this.PO_CUSTOM || sortPersonnelBy === this.PO_TIME_CUSTOM) {
      personnelComparator = "order";
    } else if (sortPersonnelBy === this.PO_LAST || sortPersonnelBy === this.PO_TIME_LAST) {
      personnelComparator = (a, b) => aToBStringComparator(a?.attributes?.last_name, b?.attributes?.last_name);
    } else {
      personnelComparator = (a, b) => aToBStringComparator(a?.attributes?.display_name, b?.attributes?.display_name);
    }

    return personnelComparator;
  },

  _getSlotComparator: function () {
    return slotQuestComparatorFactory();
  },

  attachRouter: function () {
    this.Router = new Router();
    // will obey first route
    Backbone.history.start();
  },
});

module.exports = LbsAppData;
