import { toDate } from "date-fns-tz";

export interface PersonnelTypeAttributes {
  ptype_id: number;
  created_at: string;
  description: string;
  name: string;
  updated_at: string;
}

export interface PersonnelType {
  id: number | "";
  created: Date;
  description: string;
  name: string;
  updated: Date;
}

export const cleanPersonnelTypeAttributes = (attrs: Partial<PersonnelTypeAttributes>): PersonnelType => {
  return {
    id: attrs.ptype_id ?? "",
    created: toDate(attrs.created_at),
    description: attrs.description ?? "",
    name: attrs.name ?? "",
    updated: toDate(attrs.updated_at),
  };
};

export type PersonnelTypesById = Record<string, PersonnelType>;
