import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";

type LeftColumnTitle = "Assignment" | "Personnel" | "Assign." | "Pers.";

export default ({ condenseColumnView, isCompact }: SettingsContext, { leftColumnType }: UIContext): LeftColumnTitle => {
  const useCompactLeftColTitle = isCompact || condenseColumnView;
  const assignmentLeftColTitle = useCompactLeftColTitle ? "Assign." : "Assignment";
  const personnelLeftColTitle = useCompactLeftColTitle ? "Pers." : "Personnel";
  return leftColumnType === "assignment" ? assignmentLeftColTitle : personnelLeftColTitle;
};
