// Manager to facilitate actions based on the current tool

const moment = require("moment");

const BulkSelectTool = require("./tools/BulkSelectTool.jsx");

// error messages
const NO_PUBLISHED_PARENT = "Oops!  We can't find a published schedule to update for this mapped assignment.";
const FIDDLEDEAD_ERROR =
  "We can't seem to contact our server.  Is your network connection okay?  If so try refreshing the page.";

// tool collections
const HighlighterPack = require("./HighlighterPack.js");
const Backbone = require("backbone");

const ToolManager = Backbone.Model.extend({
  defaults: {
    currentTool: undefined,
    availableTools: {},
  },

  initialize: function () {
    // create the tool models

    // bulk select
    this.attributes.availableTools["bulk-select"] = new BulkSelectTool();

    // highlighter pack
    const highlighterPack = new HighlighterPack();
    highlighterPack.add({
      id: "0",
      backgroundColor: "#FEFF84",
      color: "#000000",
      colorName: "yellow",
      enabled: false,
      slotquestTargetName: "",
    }); // yellow
    highlighterPack.add({
      id: "1",
      backgroundColor: "#A7F8A0",
      color: "#000000",
      colorName: "green",
      enabled: false,
      slotquestTargetName: "",
    }); // green
    highlighterPack.add({
      id: "2",
      backgroundColor: "#A0F8F5",
      color: "#000000",
      colorName: "blue",
      enabled: false,
      slotquestTargetName: "",
    }); // blue
    highlighterPack.add({
      id: "3",
      backgroundColor: "#D8A0F8",
      color: "#000000",
      colorName: "purple",
      enabled: false,
      slotquestTargetName: "",
    }); // purple
    highlighterPack.add({
      id: "4",
      backgroundColor: "#FFB7F2",
      color: "#000000",
      colorName: "pink",
      enabled: false,
      slotquestTargetName: "",
    }); // pink
    highlighterPack.add({
      id: "5",
      backgroundColor: "#FFBF74",
      color: "#000000",
      colorName: "orange",
      enabled: false,
      slotquestTargetName: "",
    }); // orange

    this.attributes.availableTools["highlighters"] = highlighterPack;

    // set the default
    //this.set({currentTool: this.attributes.availableTools['highlighters'].at(0)});
    this.set({ currentTool: undefined });
  },

  /* publics */
  perform: function (action, args) {
    // all public tool functions should pass through this
    if (action === "click") {
      this.performClick(args);
    } else if (action === "value-get") {
      return this.attributes.currentTool.get(args["attr_name"]);
    } else if (action === "value-set") {
      this.attributes.currentTool.set(args);
    } else if (action === "execute") {
      this.attributes.currentTool.onExecute();
    } else if (action === "attach") {
      this.attributes.currentTool.onAttach(args);
    } else if (action === "reattach") {
      // only used by Select tool...so this isn't very expandable
      const slot = args["slot"];

      this.attributes.currentTool.attributes.target.set({
        boundTool: undefined,
      });
      // save the new target and component
      this.attributes.currentTool.set({ target: slot });
      this.attributes.currentTool.onAttach();
    } else if (action === "remove") {
      // we have a few places that allow you to remove/delete a slot...so makes sense to have a call here
      this.performRemove(args.slot, args.caller);
    } else if (action === "reset") {
      // only used by select/alternates tool currently when the dialog closes
      this?.attributes?.currentTool?.reset();
    }
  },

  setTool: function (tool) {
    this.set({ currentTool: tool });
  },

  flutterTool: function () {
    // called when the axis change (maybe layout too?) call the onClose and onSelect functions on the tool
    this.attributes.currentTool.onClose();
    this.attributes.currentTool.onSelect();
  },

  selectTool: function (tool) {
    // first allow the current tool to do any cleanup
    if (this.attributes.currentTool) {
      this.attributes.currentTool.onClose();
    }

    switch (tool) {
      case "select":
        this.set({
          currentTool: this.attributes.availableTools["select"],
        });
        break;
      case "fill":
        this.set({
          currentTool: this.attributes.availableTools["fill"],
        });
        break;
      case "alternates":
        this.set({
          currentTool: this.attributes.availableTools["alternates"],
        });
        break;
      case "remove":
        this.set({
          currentTool: this.attributes.availableTools["remove"],
        });
        break;
      case "highlight":
        this.set({
          currentTool: this.attributes.availableTools["highlighters"].get(0),
        });
        break;
      case "bulk-select":
        this.set({
          currentTool: this.attributes.availableTools["bulk-select"],
        });
        break;
      default:
        this.set({ currentTool: undefined });
        return; // don't trigger the onSelect
    }

    // lastly allow the tool to run any setup
    this.attributes.currentTool.onSelect();
  },

  performClick: function (args) {
    // if the action is being taken on a slot that exists (has something in it) -> component.props.slot
    // else the slot having the action involked on is -> component.slot

    let slot, isScheduledSlot;
    if (args.slot) {
      isScheduledSlot = true;
      slot = args.slot;
    } else if (args.component) {
      isScheduledSlot = args.component.props.slot !== undefined;
      slot = args.component.props.slot || args.component.slot;
    }

    // possible that the tool already has a context, depending on what tool this is...that might not be good
    this.attributes.currentTool.onAttachPre();

    // save the new target and component
    this.attributes.currentTool.set({
      target: slot,
      component: args.component,
    });

    // some of the tools can't use the 'generic' flow
    switch (this.attributes.currentTool.attributes.type) {
      case "remove":
        // a click with the remove tool can get forwarded right to its execute (no attach phase)
        // totally ignore the click on a blank slot
        if (isScheduledSlot) {
          this.attributes.currentTool.onExecute();
        }
        break;

      case "highlight":
        // a click with the highlighter tool can get forwarded right to its Attach
        // totally ignore the click on a blank slot
        if (isScheduledSlot) {
          this.attributes.currentTool.onAttach();
        }
        break;

      default:
        if (isScheduledSlot) {
          // on a slot that already exists...the job is way easier, call the tools onAttach method
          this.attributes.currentTool.onAttach();
        } else {
          // otherwise we gotta do some prep work
          this._performPrepareStep1(slot);
        }
        break;
    }
  },

  // this one has a callFinished option...mainly for the actual remove tool
  performRemove: function (slot, caller) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    slot.removeListeners();
    slot.destroy({
      success: function (model) {
        that.attributes.currentTool.trigger("removeSlot");
        if (caller === "remove_tool") {
          that.attributes.currentTool.onFinished();
        } else if (caller === "select_tool") {
          that.attributes.currentTool.forceUpdateComponent();
        }
      },
      error: function () {
        window.LbsAppData.ToolManager._showFiddleDeadError();
      },
    });
  },

  // resolve the assignment structure to a single id
  _performPrepareStep1: function (slot) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const { currentTool } = this.attributes;

    if (!currentTool.attributes.component) {
      return;
    }

    const date = currentTool.attributes.component.props.date;
    const dateFormatted = date.format("YYYYMMDD");

    if (window.LbsAppData.AppContext.get("GridSettings_leftColumnType") === "assignment") {
      const schedulableStructures = this._getAssignmentForEmptySlot().getSchedulableStructures(dateFormatted);

      if (schedulableStructures.length > 1) {
        // more than one choice, user has to decide
        window.LbsAppData.AppContext.showDialog({
          type: "structure",
          slot: slot,
          assignment: this._getAssignmentForEmptySlot(),
          structures: schedulableStructures,
          callback: function (structureId) {
            // close the dialog
            window.LbsAppData.AppContext.closeDialog();
            // call the next thing in the chain
            if (that._performPrepareStep2.call(that, structureId)) {
              currentTool.onAttach();
            }
          },
        });
      } else if (schedulableStructures.length === 1) {
        // only one choice...hopefully this happens 99% of the time
        if (this._performPrepareStep2(schedulableStructures[0])) {
          currentTool.onAttach();
        }
      }
    } else {
      if (this._performPrepareStep2()) {
        currentTool.onAttach();
      }
    }
  },

  // fill the the placeholder slot with some actual shit
  // if this returns false...it means there was an issue with resolving a mapped parent..and error dialog will be
  // displayed and all further execution should stop
  _performPrepareStep2: function (structureId) {
    const { currentTool } = this.attributes;

    if (!currentTool) {
      return;
    }

    const { target, slot: currentToolSlot } = currentTool.attributes;
    const slot = currentToolSlot ?? target;

    const assignment = this._getAssignmentForEmptySlot();
    const personnel = this._getPersonnelForEmptySlot();

    // (fill in the thing we know for sure...the thing in the row)
    if (window.LbsAppData.AppContext.get("GridSettings_leftColumnType") === "assignment") {
      const useDate = this.attributes.currentTool.attributes.component.props.date;

      // make sure the assignment is valid in terms of mapping
      if (this._checkIsValidMap(assignment, structureId, useDate)) {
        slot.replaceAssignment(assignment, structureId, useDate);
        return true;
      } else {
        // open an error dialog
        window.LbsAppData.ToolManager._showParentSchedError();
        return false;
      }
    } else {
      slot.replacePersonnel(personnel);
      return true;
    }
  },

  // START STATICS ------------------------------------------------------------------------------
  //...helper functions, they need all relevant data passed in OR they need every accessible view the window

  _getAssignmentForEmptySlot: function () {
    const currentTool = window.LbsAppData.ToolManager.get("currentTool");

    if (window.LbsAppData.AppContext.get("GridSettings_leftColumnType") === "assignment") {
      // get the assignment based on the row
      return currentTool.attributes.component.props.rowKey;
    } else {
      // get the assignment based on the slot
      return currentTool.getAssignmentForEmptySlot();
    }
  },

  _getPersonnelForEmptySlot: function () {
    const currentTool = window.LbsAppData.ToolManager.get("currentTool");

    if (window.LbsAppData.AppContext.get("GridSettings_leftColumnType") === "personnel") {
      // get the assignment based on the row
      return currentTool.attributes.component.props.rowKey;
    } else {
      // get the assignment based on the tool properties
      return currentTool.getPersonnelForEmptySlot();
    }
  },

  _replacePersonnel: function (slot) {
    const currentTool = window.LbsAppData.ToolManager.get("currentTool");

    // fill the value on the slot
    slot.replacePersonnel(currentTool.attributes.value);

    // add the slot to the collection (if it isn't already)
    window.LbsAppData.ScheduleData.get(moment(slot.attributes.start_time).format("YYYYMMDD")).slots.add(slot);

    // save the changes
    //var that = this;
    slot.save(null, {
      success: function () {
        currentTool.onFinished();
        currentTool.trigger("replacePersonnel");
      },
      error: function () {
        window.LbsAppData.ToolManager._showFiddleDeadError();
      },
    });
  },

  _replaceAssignmentStep1: function (slot, forcedStructureId) {
    // forcedStructureId is used by the select tool in the situations where there are structure collisions on the same day
    // it can't push the select structure dialog like everything else can...cause the tool itself is an open dialog
    const currentTool = window.LbsAppData.ToolManager.get("currentTool");

    if (!currentTool.attributes.component) {
      return;
    }

    const date = currentTool.attributes.component.props.date;

    // need to see the structures of the NEW assignment..which if we are here we must assume it's stored as the value of the tool
    const schedulableStructures = currentTool.attributes.value.getSchedulableStructures(date.format("YYYYMMDD"));

    if (schedulableStructures.length > 1 && currentTool.attributes.type !== "select") {
      // more than one choice, user has to decide to continue...notice SELECT TOOL is ignored here...
      // that has its own way of dealing with this...basically doing it right in the dialog via a couple methods
      window.LbsAppData.AppContext.showDialog({
        type: "structure",
        slot: slot,
        assignment: currentTool.attributes.value,
        structures: schedulableStructures,
        callback: function (structureId) {
          // close the dialog
          window.LbsAppData.AppContext.closeDialog();
          // call the next thing in the chain
          window.LbsAppData.ToolManager._replaceAssignmentStep2(slot, structureId, date);
        },
      });
    } else if (schedulableStructures.length === 0) {
      // zeroooo, have to pop up another dialog...due to restrictions we put on the alternates and select tools
      // by only showing assignments _with_ demand...this should only hit on the fill tool as it is right now
      window.LbsAppData.AppContext.showDialog({
        type: "add-demand",
        assignment: currentTool.attributes.value,
        date: date,
        callback: function () {
          // close the dialog
          window.LbsAppData.AppContext.closeDialog();

          // now that they've decided to add demand on this day, call this whole thing again
          window.LbsAppData.ToolManager._replaceAssignmentStep1(slot);
        },
      });
    } else if (currentTool.attributes.type === "select" && forcedStructureId !== undefined) {
      // select tool, in the case where there were multiple structures to pick from...the tool did the work
      // just push it through to the next step
      window.LbsAppData.ToolManager._replaceAssignmentStep2(slot, forcedStructureId, date);
    } else {
      // only 1, hopefully the majority of the cases
      window.LbsAppData.ToolManager._replaceAssignmentStep2(slot, schedulableStructures[0], date);
    }
  },

  _replaceAssignmentStep2: function (slot, structureId, date) {
    const currentTool = window.LbsAppData.ToolManager.get("currentTool");

    // we need to check to make sure if this is mapped slot...that there is a published parent schedule for the date
    // they are trying to update...if not this is an invalid operation and we need to bail
    if (window.LbsAppData.ToolManager._checkIsValidMap(currentTool.attributes.value, structureId, date)) {
      slot.replaceAssignment(currentTool.attributes.value, structureId, date);

      // add the slot to the collection (if it isn't already)
      window.LbsAppData.ScheduleData.get(moment(slot.attributes.start_time).format("YYYYMMDD")).slots.add(slot);

      // save the changes
      slot.save(null, {
        success: function () {
          currentTool.onFinished();
          currentTool.trigger("replaceAssignment");
        },
        error: function () {
          window.LbsAppData.ToolManager._showFiddleDeadError();
        },
      });
    } else {
      // invalid mapped assignment operation...show them an error dialog, finish the operation and error
      currentTool.onFinished();
      window.LbsAppData.ToolManager._showParentSchedError();
    }
  },

  _checkIsValidMap: function (assignment, structureId, date) {
    // this'll be called at the places we know the actual assignment/structure trying to be used
    // return true if there is a published parent schedule for mapped slot (or the slot isn't mapped at all)
    const templateId = assignment.attributes.structure_to_template_map[structureId];
    const publishedMap = window.LbsAppData.PublishedMap.get(templateId);

    if (publishedMap === undefined) {
      return false;
    } else if (publishedMap.getPublishedScheduleId(date.format("YYYYMMDD")) === undefined) {
      return false;
    } else {
      return true;
    }
  },

  _showParentSchedError: function () {
    // mapping related error
    window.LbsAppData.AppContext.showDialog({
      type: "error",
      title: "Parent Schedule Not Found",
      message: NO_PUBLISHED_PARENT,
    });
  },

  _showFiddleDeadError: function () {
    // this will get called on any of the error callbacks from the fiddledead changes
    window.LbsAppData.AppContext.showDialog({
      type: "error",
      title: "Network Error",
      message: FIDDLEDEAD_ERROR,
    });
  },

  // END STATICS -------------------------------------------------------------------
});

module.exports = ToolManager;
