// Helper to define available actions dependent on a user and slot

const _ = require("underscore");
const Backbone = require("backbone");
const { LDFlagEnums } = require("@/_lib/constants/LDFlagEnums");

var SwapsHelper = Backbone.Model.extend({
  defaults: {},

  initialize: function () {},

  getSwapActions: function (user, slot) {
    var ret = [];
    const moveToolEnabled = window.LbsAppData.launchDarklyClient.evaluateLDFlag(
      LDFlagEnums.Lv7030MoveToolSingle,
      false
    );

    if (slot.get("is_pending")) {
      if (slot.get("pending_info").preswaps.length > 0) {
        // swaportunity
        var eligible = _.findWhere(slot.get("pending_info").preswaps, {
          emp_id: user.get("emp_id"),
        });
        if (eligible && eligible["response"] != 3) {
          if (eligible["response"] != 2) {
            ret.push(window.LbsAppData.ACCEPT_ACTION);
          }
          if (eligible["response"] != 1) {
            ret.push(window.LbsAppData.DECLINE_ACTION);
          }
        }
        var swopCreator = user.get("emp_id") == slot.get("emp_id");
        var responses = _.map(slot.get("pending_info").preswaps, function (preswap) {
          return preswap.response;
        });
        var anyAccepted = _.contains(responses, 2);
        if (swopCreator || user.get("is_admin")) {
          if (anyAccepted) {
            ret.push(window.LbsAppData.FINALIZE_ACTION);
          }
          ret.push(window.LbsAppData.CANCEL_SWOP_ACTION);
        }
      } else {
        // normal swap finishers
        if (window.LbsAppData.Helpers.Permissions.CanIApproveDenySwap(slot)) {
          ret.push(window.LbsAppData.GRANT_ACTION);
        }
        if (window.LbsAppData.Helpers.Permissions.CanIApproveDenySwap(slot)) {
          ret.push(window.LbsAppData.DENY_ACTION);
        }
        if (window.LbsAppData.Helpers.Permissions.CanIDeleteSwap(slot)) {
          ret.push(window.LbsAppData.DELETE_ACTION);
        }
      }
    } else {
      // normal swap starters
      var myselfSlot = user.get("emp_id") == slot.get("emp_id");

      if (window.LbsAppData.Helpers.Permissions.CanICreateSwap(slot)) {
        ret.push(window.LbsAppData.REMOVE_ACTION);
      }

      if (myselfSlot) {
        if (window.LbsAppData.Helpers.Permissions.CanICreateSwap(slot, user.get("emp_id"))) {
          if (moveToolEnabled) {
            ret.push(
              window.LbsAppData.REPLACE_ACTION,
              window.LbsAppData.SPLIT_SHIFT_ACTION,
              window.LbsAppData.MOVE_ACTION
            );
          } else {
            ret.push(window.LbsAppData.REPLACE_ACTION, window.LbsAppData.SPLIT_SHIFT_ACTION);
          }
        }
      } else {
        // if a slot is not mine, and I can't change anyone but myself...then do an extra roles check
        if (!window.LbsAppData.Helpers.Permissions.CanIChangeAnyoneElsesSchedule()) {
          if (_.intersection(window.LbsAppData.MyPersonnel.get("roles"), [slot.get("assign_id")]).length > 0) {
            if (window.LbsAppData.Helpers.Permissions.CanICreateSwap(slot, user.get("emp_id"))) {
              ret.push(window.LbsAppData.REPLACE_ACTION);
            }
          }
        } else {
          if (window.LbsAppData.Helpers.Permissions.CanICreateSwap(slot, user.get("emp_id"))) {
            ret.push(window.LbsAppData.REPLACE_ACTION);
          }

          // Almost the same as above but we don't want to check the emp_id for split shift
          if (window.LbsAppData.Helpers.Permissions.CanICreateSwap(slot)) {
            if (moveToolEnabled) {
              ret.push(window.LbsAppData.SPLIT_SHIFT_ACTION, window.LbsAppData.MOVE_ACTION);
            } else {
              ret.push(window.LbsAppData.SPLIT_SHIFT_ACTION);
            }
          }
        }
      }

      if (window.LbsAppData.Helpers.Permissions.CanIChangeSlotDetails(slot)) {
        ret.push(window.LbsAppData.DETAILS_ACTION);
      }
    }

    if (
      window.LbsAppData.Helpers.Permissions.CanICreateSwap(slot) &&
      window.LbsAppData.User?.attributes?.parameters?.LBLiteEditTallyCount
    ) {
      ret.push(window.LbsAppData.EDIT_TALLY_COUNT);
    }

    return ret;
  },

  getRequestActions: function (user, slot, latestDates, ignoreStatus) {
    var ret = [];

    // dont allow anything if we are in published schedule territory (if that latestDates wasn't passed...just let it through)
    if (!latestDates || slot.get("request_date") > latestDates[slot.get("template_id")]) {
      var status = !ignoreStatus ? slot.get("status") : "";
      if (status === "pending") {
        if (window.LbsAppData.Helpers.Permissions.CanIDeleteRequest(slot)) {
          ret.push(window.LbsAppData.DELETE_ACTION);
        }
        if (window.LbsAppData.Helpers.Permissions.CanIApproveDenyRequest(slot)) {
          ret.push(window.LbsAppData.GRANT_ACTION);
        }
        if (window.LbsAppData.Helpers.Permissions.CanIApproveDenyRequest(slot)) {
          ret.push(window.LbsAppData.DENY_ACTION);
        }
      } else if (status === "granted") {
        if (window.LbsAppData.Helpers.Permissions.CanIDeleteRequest(slot)) {
          ret.push(window.LbsAppData.DELETE_ACTION);
        }
        if (window.LbsAppData.Helpers.Permissions.CanIApproveDenyRequest(slot)) {
          ret.push(window.LbsAppData.DENY_ACTION);
        }
        if (window.LbsAppData.Helpers.Permissions.CanIApproveDenyRequest(slot)) {
          ret.push(window.LbsAppData.SUSPEND_ACTION);
        }
      } else if (status === "denied") {
        if (window.LbsAppData.Helpers.Permissions.CanIDeleteRequest(slot)) {
          ret.push(window.LbsAppData.DELETE_ACTION);
        }
        if (window.LbsAppData.Helpers.Permissions.CanIApproveDenyRequest(slot)) {
          ret.push(window.LbsAppData.GRANT_ACTION);
        }
        if (window.LbsAppData.Helpers.Permissions.CanIApproveDenyRequest(slot)) {
          ret.push(window.LbsAppData.SUSPEND_ACTION);
        }
      } else {
        // ignore the status
        if (window.LbsAppData.Helpers.Permissions.CanIDeleteRequest(slot)) {
          ret.push(window.LbsAppData.DELETE_ACTION);
        }
        if (window.LbsAppData.Helpers.Permissions.CanIApproveDenyRequest(slot)) {
          ret.push(window.LbsAppData.GRANT_ACTION);
        }
        if (window.LbsAppData.Helpers.Permissions.CanIApproveDenyRequest(slot)) {
          ret.push(window.LbsAppData.DENY_ACTION);
        }
        if (window.LbsAppData.Helpers.Permissions.CanIApproveDenyRequest(slot)) {
          ret.push(window.LbsAppData.SUSPEND_ACTION);
        }
      }
    }

    return ret;
  },
});

module.exports = SwapsHelper;
