const Backbone = require("backbone");
const ApiConfig = require("@/_lib/data/ApiConfig.js");
const NakedViewLegacy = require("./../models/NakedViewLegacy");
const _ = require("lodash");
const { LEGACY_VIEW_ID_OFFSET } = require("@/_lib/constants/globals");

const NakedViewLegacyCollection = Backbone.Collection.extend({
  model: NakedViewLegacy,
  comparator: "name",

  initialize: function (models, attributes) {
    this.attributes = {};

    for (const key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    const queryParams = {
      legacy: true,
      slim: true,
    };

    if (this?.attributes?.template_list?.length) {
      queryParams["template_list"] = this.attributes.template_list.join("~");
    }

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    return (
      ApiConfig.getPrefix() +
      "/views?" +
      _.map(cleanQueryParams, function (value, key) {
        return key + "=" + value;
      }).join("&")
    );
  },

  parse: function (response) {
    let views = [];

    _.each(response, function (v) {
      v.view_id = v.view_id + LEGACY_VIEW_ID_OFFSET;
      views = views.concat(v);
    });

    return views;
  },
});

module.exports = NakedViewLegacyCollection;
