var HighlightTool = require("./tools/HighlightTool.js");
var _ = require("underscore");

var HighlighterPack = Backbone.Collection.extend({
  model: HighlightTool,
  comparator: "id",

  initialize: function () {},

  reset: function () {
    // push a reset call to each highlighter
    _.each(this.models, function (i) {
      i.reset();
    });
  },
});

module.exports = HighlighterPack;
