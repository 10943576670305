const Backbone = require("backbone");
var _ = require("underscore");
var Template = require("./../models/Template.js");
var moment = require("moment");

var TemplateCollection = Backbone.Collection.extend({
  model: Template,
  comparator: "name",

  toggleFilteredModels: function (ids, value) {
    for (var i = 0; i < ids.length; i++) {
      var model = this.get(ids[i]);

      if (value === undefined) {
        // toggle it
        model.set({ filtered: !model.attributes.filtered });
        this.trigger("change:filteredModel");
      } else {
        // set it explicitly (if it needs to be)
        if (model.attributes.filtered != value) {
          model.set({ filtered: value });
          // trigger an event
          this.trigger("change:filteredModel");
        }
      }
    }
  },

  filterAll: function () {
    this.forEach(function (item) {
      item.set({ filtered: true });
    });

    // trigger an event
    this.trigger("change:filteredModel");
  },
  unfilterAll: function () {
    this.forEach(function (item) {
      item.set({ filtered: false });
    });

    // trigger an event
    this.trigger("change:filteredModel");
  },

  getLatestScheduledDates: function () {
    var ret = {};
    _.each(this.models, function (item) {
      ret[item.id] = item.attributes.last_published_date;
    });

    return ret;
  },

  getReqWindowStartOrLatestScheduled: function () {
    // need to check if the request window start is before the last published date....thanks allow requests on pub schedules param....cool...cool cool cool cool
    var ret = {};
    _.each(this.models, function (item) {
      var dayBeforeReqWindowStart = moment(item.attributes.request_window_start)
        .subtract(1, "days")
        .format("YYYY-MM-DDTHH:mm:ss");
      ret[item.id] =
        dayBeforeReqWindowStart > item.attributes.last_published_date
          ? item.attributes.last_published_date
          : dayBeforeReqWindowStart;
    });

    return ret;
  },
});

module.exports = TemplateCollection;
