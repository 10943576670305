import { Model } from "backbone";

import { SettingsContext } from "@/viewer/ui/modules/common/types/context";
import connectedPropertyHookFactory from "@/viewer/ui/modules/common/hooks/connectedPropertyHookFactory";

import { cleanAssignmentAttributes, AssignmentsById } from "@/viewer/types/domain/assignment";

const getAssignments = (settings: SettingsContext): AssignmentsById => {
  const { LbsAppData } = window as any;

  const out: AssignmentsById = {};
  const assignmentCollection = LbsAppData.Assignments;

  if (!assignmentCollection) return out;

  assignmentCollection.forEach((assignmentModel: Model) => {
    const assignment = cleanAssignmentAttributes(settings, assignmentModel.attributes);
    out[assignment.assignStructureId] = assignment;
  });

  return out;
};

export default connectedPropertyHookFactory({
  loader: getAssignments,
  getListeners: () => {
    const { LbsAppData } = window as any;
    return [[LbsAppData.AppContext, "fetched"]];
  },
});
