const Router = Backbone.Router.extend({
  routes: {
    "v/:view_id": "openView",
  },

  openView: function (id) {
    // open a view
    window.LbsAppData.AppContext.openLoadingDialog("Opening view...", this, function () {
      // send a tracking event -- putting this here to avoid tracking first-view openings
      window.LbsAppData.Helpers.Analytics.sendEvent("Open: View", id);

      // open the view
      window.LbsAppData.AppContext.openView(id);
    });
  },
});

module.exports = Router;
