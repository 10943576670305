// Pretend to be the real react/addons
const classnames = require("_lib/utils/classnames");

const React = require("react");
const ReactDOM = require("react-dom");

React.createClass = require("create-react-class");

React.addons = {
  classSet: (obj) => classnames.default("", obj),
};

module.exports = React;
