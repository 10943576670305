const Backbone = require("backbone");
var _ = require("underscore");

var ApiConfig = require("_lib/data/ApiConfig.js");
var Assignment = require("./../models/Assignment.js");

var AssignmentCollection = Backbone.Collection.extend({
  model: Assignment,

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }

    this.attributes.title = "Assignments";
  },

  comparator: function (a, b) {
    var orderA = a.attributes.order;
    var orderB = b.attributes.order;

    // if any of these is an integer (as opposed to a string) then it comes first
    if (typeof orderA === "number" && typeof orderB === "string") {
      return -1;
    } else if (typeof orderB === "number" && typeof orderA === "string") {
      return 1;
    }

    // must be same type, so do normal int, string comparisons
    if (orderA == orderB) {
      return 0;
    } else {
      if (orderA < orderB) {
        return -1;
      } else {
        return 1;
      }
    }
  },

  url: function () {
    return (
      ApiConfig.getPrefix() +
      "/assignments/condensed?condense_by=display_and_id&template_list=" +
      this.attributes.templateList +
      "&minor_template_list=" +
      this.attributes.minorTemplateList +
      "&start_date=" +
      this.attributes.startDate.format("YYYYMMDD") +
      "&end_date=" +
      this.attributes.stopDate.format("YYYYMMDD")
    );
  },
  //parse: function(response) {
  //	return response.data;
  //}

  toggleFilteredModels: function (ids, value) {
    for (var i = 0; i < ids.length; i++) {
      var model = this.get(ids[i]);

      if (value === undefined) {
        // toggle it
        model.set({ filtered: !model.attributes.filtered });
        this.trigger("change:filteredModel");
      } else {
        // set it explicitly (if it needs to be)
        if (model.attributes.filtered != value) {
          model.set({ filtered: value });
          // trigger an event
          this.trigger("change:filteredModel");
        }
      }
    }
  },

  filterAll: function () {
    this.forEach(function (item) {
      item.set({ filtered: true });
    });

    // trigger an event
    this.trigger("change:filteredModel");
  },
  unfilterAll: function () {
    this.forEach(function (item) {
      item.set({ filtered: false });
    });

    // trigger an event
    this.trigger("change:filteredModel");
  },

  getAssignmentsWithDemand: function (date) {
    return _.filter(this.models, function (item) {
      if (item.checkDemand(date, 0) > 0) {
        return true;
      } else {
        return false;
      }
    });
  },
});

module.exports = AssignmentCollection;
