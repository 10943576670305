/* eslint-disable no-console */
const Backbone = require("backbone");
const moment = require("moment");
const _ = require("underscore");
const Promise = require("bluebird");

// collections
const PersonnelCollection = require("_lib/data/collections/NakedPersonnelCollection.js");
const TallyCollection = require("_lib/data/collections/NakedTallyCollection.js");

// models
const Schedule = require("_lib/data/models/NakedSchedule.js");
const View = require("_lib/data/models/NakedView.js");
const Report = require("@/reports/data/models/Report.js");
const SafeJSON = require("../../../common/utils/jsonParseShield");

// Time difference in milliseconds between authentication api call and schedule data api call
const DEFAULT_TIME_DIFFERENCE_DELTA = 700;

const ReportsHelper = Backbone.Model.extend({
  defaults: {},

  initialize: function () {},

  /* publics */
  defineDefaultShadowReport: function (schedId, viewId, callback) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    // personnel/tally collections (populated after the schedule object is retrieved)
    let personnel, tallies;

    // display message and start the api calls after the defined delta time difference in order for the api calls to be authenticated
    window.LbsAppData.AppContext.openLoadingDialog(
      "Loading",
      this,
      function () {
        // get the schedule object
        const schedule = new Schedule({ schedule_id: schedId });
        schedule.fetch({
          success: function (model) {
            if (window.payload.report_definition) {
              // saved report -- don't auto pick anything except for the shadow and dates
              var composition = SafeJSON.parse(window.payload.report_definition);
              composition.data._definition.properties.data.mode = "shadow";
              composition.data._definition.properties.data.shadow = SafeJSON.parse(JSON.stringify(model.attributes));
              // force the saved view dates to current schedule period
              composition.data._definition.properties.data.period_type = "current";
              composition.data._definition.properties.data.start_date = {
                type: "specific",
                value: model.get("start_date"),
              };
              composition.data._definition.properties.data.end_date = {
                type: "specific",
                value: model.get("stop_date"),
              };
              // return with the report
              callback(that.defineTallyReport(composition.data._definition));
            } else {
              // auto pick based on the schedule/view combination
              if (viewId === undefined) {
                // no view

                // fetch the personnel/tallies for this department/template
                var promises = [];

                personnel = new PersonnelCollection([], {
                  deptList: [schedule.attributes.department_id],
                  permissionsFilter: "reports",
                });
                promises.push(
                  new Promise(function (resolve, reject) {
                    window.LbsAppData.Helpers.Cache.fetch(
                      personnel,
                      function (collection) {
                        resolve();
                      },
                      function () {
                        reject();
                      }
                    );
                  })
                );

                tallies = new TallyCollection([], {
                  templateList: [schedule.attributes.template_id],
                });
                promises.push(
                  new Promise(function (resolve, reject) {
                    window.LbsAppData.Helpers.Cache.fetch(
                      tallies,
                      function (collection) {
                        resolve();
                      },
                      function () {
                        reject();
                      }
                    );
                  })
                );

                Promise.all(promises).then(function () {
                  var composition = {
                    properties: that._getDefaultProperties(schedule),
                    personnel: that._getDefaultPersonnel(personnel),
                    tallies: that._getDefaultTallies(tallies),
                    templates: [schedule.attributes.template_id],
                    departments: [schedule.attributes.department_id],
                  };

                  // return with the report
                  callback(that.defineTallyReport(composition));
                });
              } else {
                // get the view
                var view = new View({ view_id: viewId });

                var promises = [];
                promises.push(
                  new Promise(function (resolve, reject) {
                    view.fetch({
                      success: function () {
                        resolve();
                      },
                    });
                  })
                );

                personnel = new PersonnelCollection([], {
                  deptList: [schedule.attributes.department_id],
                  permissionsFilter: "reports",
                });
                promises.push(
                  new Promise(function (resolve, reject) {
                    window.LbsAppData.Helpers.Cache.fetch(
                      personnel,
                      function (collection) {
                        resolve();
                      },
                      function () {
                        reject();
                      }
                    );
                  })
                );

                tallies = new TallyCollection([], {
                  templateList: [schedule.attributes.template_id],
                });
                promises.push(
                  new Promise(function (resolve, reject) {
                    window.LbsAppData.Helpers.Cache.fetch(
                      tallies,
                      function (collection) {
                        resolve();
                      },
                      function () {
                        reject();
                      }
                    );
                  })
                );

                Promise.all(promises).then(function () {
                  var onAssignIds = _.pluck(view.attributes.filter.on_assignments, "id");
                  var viewTallies = tallies.filter(function (t) {
                    return (
                      _.intersection(t.attributes.assign_structure_ids, onAssignIds).length > 0 &&
                      t.attributes.tally_type === 0
                    );
                  });

                  var composition = {
                    properties: that._getDefaultProperties(schedule, view),
                    personnel: _.intersection(
                      _.pluck(personnel.where({ scheduled: true }), "id"),
                      _.pluck(view.attributes.filter.on_personnel, "id")
                    ),
                    tallies: _.pluck(viewTallies, "id"),
                    templates: [schedule.attributes.template_id],
                    departments: [schedule.attributes.department_id],
                  };

                  // return with the report
                  callback(that.defineTallyReport(composition));
                });
              }
            }
          },
        });
      },
      DEFAULT_TIME_DIFFERENCE_DELTA
    );
  },

  defineTallyReport: function (inDef) {
    // generate a tally report
    var definition = {};
    definition.properties = inDef.properties;
    definition.tallies = inDef.tallies;
    definition.personnel = inDef.personnel;
    definition.templates = inDef.templates;
    definition.departments = inDef.departments;

    var composition = {};
    var nodesAndPaths = this._createTallyReportNodesAndPaths(SafeJSON.parse(JSON.stringify(inDef)));
    composition.nodes = nodesAndPaths.nodes;
    composition.paths = nodesAndPaths.paths;

    return new Report({
      _definition: definition,
      _composition: composition,
    });
  },

  /* privates */
  _createTallyReportNodesAndPaths: function (inDef) {
    let compositions;
    if (inDef.properties.data.split == "none") {
      // just need two nodes and one path
      compositions = [inDef];

      // swap out the date values with translated dates -- nasty; way too many assumptions
      var dates = this._translateDates(inDef.properties.data.start_date, inDef.properties.data.end_date);
      compositions[0].properties.data.start_date = dates.start.format("YYYYMMDD");
      compositions[0].properties.data.end_date = dates.stop.format("YYYYMMDD");

      // -- attach a title to the definition
      // if this is a shadow lookback -- do not use 1900-01-01
      if (compositions[0].properties.data.start_date != "19000101") {
        inDef.properties.table.title = dates.start.format("L") + " - " + dates.stop.format("L");
      } else {
        inDef.properties.table.title = "Equalization Start Dates - " + dates.stop.format("L");
      }
    } else {
      // if a split is defined, we need to create more than one path
      compositions = this._splitComposition(inDef);
    }

    // create the nodes and paths
    var nodes = {};
    var paths = {};

    var that = this;
    _.each(compositions, function (c) {
      that._generateTablePath(c, nodes, paths);
    });

    return {
      nodes: nodes,
      paths: paths,
    };
  },
  _generateTablePath: function (composition, nodes, paths) {
    // check how many node definitions we currently have
    var nextNodeId = _.keys(nodes).length;
    var tc_id = nextNodeId;
    var t_id = nextNodeId + 1;

    nodes[tc_id] = {
      type: "tally_counts",
      def: composition.properties.data,
    };
    nodes[t_id] = {
      type: "table",
      def: {
        title: composition.properties.table.title,
        x: {
          obj: composition.properties.table.x,
          dim: composition.properties.table.x_dim,
        },
        y: {
          obj: composition.properties.table.y,
          dim: composition.properties.table.y_dim,
        },
      },
    };

    // check how many paths we currently have
    var nextPathId = _.keys(paths).length;
    paths[nextPathId] = [tc_id, t_id];

    // deeper properties
    if (nodes[t_id].def.x.obj == "tally") {
      // tally ids
      nodes[tc_id].def.tally_ids = nodes[t_id].def.x.ids = composition.tallies;
    } else if (nodes[t_id].def.x.obj == "personnel") {
      // personnel ids
      nodes[tc_id].def.emp_ids = nodes[t_id].def.x.ids = composition.personnel;
    } else if (nodes[t_id].def.x.obj == "date") {
      var startDate = moment(composition.properties.data.start_date, "YYYYMMDD");
      var endDate = moment(composition.properties.data.end_date, "YYYYMMDD");

      var rollingDate = startDate.clone();
      var dates = [];
      for (var i = 0; i <= endDate.diff(startDate, "day"); i++) {
        dates.push(rollingDate.format("YYYYMMDD"));
        rollingDate.add(1, "day");
      }

      nodes[tc_id].def.emp_ids = nodes[t_id].def.x.ids = dates;
    }

    if (nodes[t_id].def.y.obj == "tally") {
      // tally ids
      nodes[tc_id].def.tally_ids = nodes[t_id].def.y.ids = composition.tallies;
    } else if (nodes[t_id].def.y.obj == "personnel") {
      // personnel ids
      nodes[tc_id].def.emp_ids = nodes[t_id].def.y.ids = composition.personnel;
    } else if (nodes[t_id].def.y.obj == "date") {
      var startDate = moment(composition.properties.data.start_date, "YYYYMMDD");
      var endDate = moment(composition.properties.data.end_date, "YYYYMMDD");

      var rollingDate = startDate.clone();
      var dates = [];
      for (var i = 0; i <= endDate.diff(startDate, "day"); i++) {
        dates.push(rollingDate.format("YYYYMMDD"));
        rollingDate.add(1, "day");
      }

      nodes[tc_id].def.emp_ids = nodes[t_id].def.y.ids = dates;
    }

    // include the template and department ids to make staghorn's reference calls less blunt
    nodes[tc_id].def.template_ids = composition.templates;
    nodes[tc_id].def.department_ids = composition.departments;

    // don't need to return anything because we're modifying objects by reference in here
  },
  _splitComposition: function (composition) {
    var compositionSplits = [];

    switch (composition.properties.data.split) {
      case "weekly":
        // split the date range into weeks
        var ranges = this._createDateRanges(
          composition.properties.data.start_date,
          composition.properties.data.end_date,
          moment.duration(1, "week")
        );
        _.each(ranges, function (r) {
          var newDef = SafeJSON.parse(JSON.stringify(composition)); // this is a trick to get a deep copy of an object
          newDef.properties.data.start_date = r.start_date;
          newDef.properties.data.end_date = r.end_date;
          newDef.properties.table.title =
            moment(r.start_date, "YYYYMMDD").format("L") + " - " + moment(r.end_date, "YYYYMMDD").format("L");

          compositionSplits.push(newDef);
        });
        break;
      case "biweekly":
        // split the date range into weeks
        var ranges = this._createDateRanges(
          composition.properties.data.start_date,
          composition.properties.data.end_date,
          moment.duration(2, "week")
        );
        _.each(ranges, function (r) {
          var newDef = SafeJSON.parse(JSON.stringify(composition)); // this is a trick to get a deep copy of an object
          newDef.properties.data.start_date = r.start_date;
          newDef.properties.data.end_date = r.end_date;
          newDef.properties.table.title =
            moment(r.start_date, "YYYYMMDD").format("L") + " - " + moment(r.end_date, "YYYYMMDD").format("L");

          compositionSplits.push(newDef);
        });
        break;
      case "monthly":
        // split the date range into weeks
        var ranges = this._createDateRanges(
          composition.properties.data.start_date,
          composition.properties.data.end_date,
          moment.duration(1, "month")
        );
        _.each(ranges, function (r) {
          var newDef = SafeJSON.parse(JSON.stringify(composition)); // this is a trick to get a deep copy of an object
          newDef.properties.data.start_date = r.start_date;
          newDef.properties.data.end_date = r.end_date;
          newDef.properties.table.title =
            moment(r.start_date, "YYYYMMDD").format("L") + " - " + moment(r.end_date, "YYYYMMDD").format("L");

          compositionSplits.push(newDef);
        });
        break;
      default:
        // shouldn't happen
        break;
    }

    return compositionSplits;
  },
  _createDateRanges: function (start, stop, duration) {
    var ranges = [];

    // decode dates
    var baseDates = this._translateDates(start, stop);

    var startDate = moment(baseDates.start);
    var stopDate = moment(baseDates.stop);

    var rollingDate = startDate.clone();
    while (!rollingDate.isAfter(stopDate, "day")) {
      ranges.push({
        start_date: rollingDate.format("YYYYMMDD"),
        end_date: moment.min(moment(rollingDate).add(duration).subtract(1, "day"), stopDate).format("YYYYMMDD"),
      });

      rollingDate.add(duration);
    }

    return ranges;
  },
  _translateDates: function (start, stop) {
    // get the actual date coinciding with the current context

    var startDate, stopDate;

    // start date
    if (start.type == "specific") {
      // specific date logic -- just return it
      startDate = moment(start.value, "YYYYMMDD");
    } else if (start.type == "anchor") {
      // anchor date logic

      // start with the current date
      startDate = moment();
      // apply the anchor function
      if (start.anchorFn == "start") {
        startDate.startOf(start.anchorType);
      } else {
        // end
        startDate.endOf(start.anchorType);
      }
      // apply the offset
      if (start.offsetVal) {
        startDate.add(start.offsetVal, start.offsetType);
      }
    } else {
      // variable date logic
      // the start date can't have this (yet)
    }

    // stop date
    if (stop.type == "specific") {
      // specific date logic -- just return it
      stopDate = moment(stop.value, "YYYYMMDD");
    } else if (stop.type == "anchor") {
      // anchor date logic

      // start with the current date
      var stopDate = moment();
      // apply the anchor function
      if (stop.anchorFn == "start") {
        stopDate.startOf(stop.anchorType);
      } else {
        // end
        stopDate.endOf(stop.anchorType);
      }
      // apply the offset
      if (stop.offsetVal) {
        stopDate.add(stop.offsetVal, stop.offsetType);
      }
    } else {
      // variable date logic

      // start with the state variable we're pointed at
      stopDate = moment(startDate);
      // apply the offset
      if (stop.offsetVal) {
        stopDate.add(stop.offsetVal, stop.offsetType);
      }
    }

    return { start: startDate, stop: stopDate };
  },

  /* default getters for schedule shadows */
  _getDefaultProperties: function (schedule, view) {
    return {
      data: {
        mode: "shadow",
        start_date: {
          type: "specific",
          value: schedule.get("start_date"),
        },
        end_date: {
          type: "specific",
          value: schedule.get("stop_date"),
        },
        report_type: "schedule",
        period_type: "current",
        count_original: false,
        include_history: false,
        pub_admin: false,
        r_granted: false,
        r_pending: false,
        r_denied: false,
        logged_in_user: false,
        split: "none",
        shadow: SafeJSON.parse(JSON.stringify(schedule.attributes)),
        view: view ? SafeJSON.parse(JSON.stringify(view.attributes)) : undefined,
      },
      table: {
        x: "tally",
        y: "personnel",
        x_dim: "none",
        y_dim: "none",
        cell_display: "value",
        show_totals: false,
      },
    };
  },
  _getDefaultPersonnel: function (personnel) {
    var filteredPersonnel = personnel.filter(function (p) {
      return p.attributes.scheduled;
    }); //; && p.departments.indexOf(departmentId) > -1); });
    var emps = _.map(filteredPersonnel, function (fp) {
      return fp.attributes.emp_id;
    });

    return emps;
  },
  _getDefaultTallies: function (tallies) {
    return _.map(tallies.where({ tally_type: 0 }), function (t) {
      return t.attributes.tally_id;
    });
  },
});

module.exports = ReportsHelper;
