/*
 * Department collection that doesn't depend on window object bindings */
const Backbone = require("backbone");

const ApiConfig = require("_lib/data/ApiConfig.js");
const NakedDepartment = require("./../models/NakedDepartment.js");
const _ = require("lodash");

const NakedDepartmentCollection = Backbone.Collection.extend({
  model: NakedDepartment,
  comparator: "name",

  initialize: function (models, attributes) {
    this.attributes = {};

    for (const key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    const queryParams = {};

    if (this.attributes.permissionsFilter) {
      queryParams["pf"] = this.attributes.permissionsFilter;
    }

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    return (
      ApiConfig.getPrefix() +
      "/departments?" +
      _.map(cleanQueryParams, function (value, key) {
        return key + "=" + value;
      }).join("&")
    );
  },
});

module.exports = NakedDepartmentCollection;
