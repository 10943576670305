/*
 * Personnel model that doesn't depend on window object bindings */
const Backbone = require("backbone");
var moment = require("moment");
var ApiConfig = require("_lib/data/ApiConfig.js");

var NakedPersonnel = Backbone.Model.extend({
  idAttribute: "emp_id",

  url: function () {
    return ApiConfig.getPrefix() + (this.id ? "/personnel/" + this.id : "/personnel");
  },

  isActiveDuringDates: function (dates) {
    if (dates) {
      for (var i = 0; i < dates.length; i++) {
        var date = moment(dates[i], "YYYYMMDD");
        if (date.isBefore(this.get("first_active_date"), "day") || date.isAfter(this.get("last_active_date"), "day")) {
          return false;
        }
      }
      // made it through each date -- return true
      return true;
    } else {
      // default to is_expired bit for now
      return this.get("is_expired");
    }
  },
});

module.exports = NakedPersonnel;
