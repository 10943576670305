import { Collection, Model } from "backbone";

import { stringifyQuery } from "../utils/queryString";
import { formatDayKey } from "@/viewer/utils/dateFormatters";
import { get } from "@/_lib/utils/fetch";
import { Slot, SlotAttributes } from "@/viewer/types/domain/slot";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ApiConfig = require("@/_lib/data/ApiConfig.js");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const SlotCollection = require("@/viewer/data/collections/SlotCollection.js");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const slotQuestComparatorFactory = require("@/viewer/data/comparators/SlotQuestComparatorFactory.js").default;

const fetchSlots = async (
  startDate: Date,
  endDate: Date,
  viewId: string,
  listed = true
): Promise<Collection<Model<SlotAttributes>>> => {
  const host: string = ApiConfig.getPrefix();
  const params = stringifyQuery({
    start_date: formatDayKey(startDate),
    end_date: formatDayKey(endDate),
    view_id: viewId,
    listed: listed.toString(),
  });

  const url = `${host}/schedule/range/?${params}`;

  const response = await get<{ data: { data: Slot[] } }>(url);

  if (response.data) {
    return new SlotCollection(response.data.data, {
      comparator: slotQuestComparatorFactory(),
    });
  }

  return new SlotCollection();
};

export default fetchSlots;
