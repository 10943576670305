const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var Swap = require("./../models/swaps/Swap.js");

var SwapCollection = Backbone.Collection.extend({
  model: Swap,
  url: ApiConfig.getPrefix() + "/schedule/update",

  // we can get away with this since we route EVERYTHING through a POST for swaps
  // all that matters is that the individual swap models have accurate data on them
  // the collection will just be thrown out (or at least it should be) once the save is done...merge it into
  // the main slot collection and get rid of it

  save: function (success_callback, error_callback) {
    Backbone.sync("create", this, {
      success: function (response) {
        success_callback ? success_callback(response) : undefined;
      },
      error: function (response) {
        error_callback ? error_callback(response) : undefined;
      },
    });
  },
});

module.exports = SwapCollection;
