import React from "react";

import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { columnRowHeightMap } from "../ColumnPrintView";
import { RequestsByDateByName } from "@/viewer/ui/modules/common/types";
import { ViewData } from "@/viewer/types/viewdata";

import classnames from "@/_lib/utils/classnames";

import ColumnCell from "../../components/ColumnCell";

import { REQUEST_ROW_MEMBER_CLASSNAME_PREFIX } from "@/viewer/ui/modules/grid/column/print/constants";

import "./_styles.scss";
import { DepartmentsById } from "@/viewer/types/domain/department";

interface Props {
  cellDate: Date;
  cellEffects: React.CSSProperties;
  cellKey: any;
  columnRowHeightMap: columnRowHeightMap;
  height: string;
  leftColId: string;
  leftHandColumnId: number;
  requestData: RequestsByDateByName;
  settings: SettingsContext;
  ui: UIContext;
  departmentsById: DepartmentsById;
  viewData: ViewData;
  visibleItemCount: number;
  rowIndex: number;
}

const PrintColumnRequestItem = (props: Props): JSX.Element => {
  const {
    cellDate,
    cellEffects,
    cellKey,
    height,
    leftColId,
    leftHandColumnId,
    requestData,
    rowIndex,
    settings,
    ui,
    viewData,
    visibleItemCount,
    departmentsById,
  } = props;
  const requestsForDate = (requestData && requestData?.[leftColId]?.[cellKey]) ?? [];
  const { data, tools, originalPersonnelMap } = viewData;
  const rowClassNames = classnames("row", {
    "row--even": visibleItemCount % 2 === 0,
    "row--odd": visibleItemCount % 2 !== 0,
  });

  const printMatrixIdentifier = `${REQUEST_ROW_MEMBER_CLASSNAME_PREFIX}${rowIndex}-${leftHandColumnId}`;

  const requestClassNames = classnames({
    [rowClassNames]: true,
    ["column-print-cell-wrapper"]: true,
    ["print-request"]: true,
    [printMatrixIdentifier]: true,
  });

  return (
    <div className={requestClassNames} style={{ ...cellEffects, height }} key={`${cellKey}-${rowIndex}-${cellDate}`}>
      <ColumnCell
        settings={settings}
        ui={ui}
        data={data}
        tools={tools}
        date={cellDate}
        scheduled={requestsForDate}
        leftColId={leftColId}
        cellType={visibleItemCount % 2 ^ 1}
        cellEffects={cellEffects}
        isPrintedCell={true}
        departmentsById={departmentsById}
        originalPersonnelMap={originalPersonnelMap}
      />
    </div>
  );
};

export default PrintColumnRequestItem;
