import { Personnel } from "@/viewer/types/domain/personnel";
import { Assignment } from "@/viewer/types/domain/assignment";
import { Template } from "@/viewer/types/domain/template";

export const CanIChangeTemplateScheduleData = (template: Template): boolean => {
  const Permissions = (window as any).LbsAppData.Permissions;

  return Permissions.hasAccessTemplate(
    Permissions.get("FEATURE")["SCHEDULE"],
    template.fullName,
    Permissions.get("LEVEL")["WRITE"]
  );
};

export const CanIChangeTemplateRequestData = (template: Template): boolean => {
  const Permissions = (window as any).LbsAppData.Permissions;

  return Permissions.hasAccessTemplate(
    Permissions.get("FEATURE")["REQUESTS"],
    template.fullName,
    Permissions.get("LEVEL")["WRITE"]
  );
};

export const CanIChangeThisPersonsSchedule = (personnel: Personnel): boolean => {
  const Permissions = (window as any).LbsAppData.Permissions;

  return Permissions.hasAccessPType(
    Permissions.get("FEATURE")["SCHEDULE"],
    personnel.empId,
    personnel.pTypeId,
    personnel.departments,
    Permissions.get("LEVEL")["WRITE"]
  );
};

export const CanIChangeThisPersonsRequests = (personnel: Personnel): boolean => {
  const Permissions = (window as any).LbsAppData.Permissions;

  return Permissions.hasAccessPType(
    Permissions.get("FEATURE")["REQUESTS"],
    personnel.empId,
    personnel.pTypeId,
    personnel.departments,
    Permissions.get("LEVEL")["WRITE"]
  );
};

export const CanIChangeAssignmentOnScheduleForTemplate = (assignment: Assignment, template: Template): boolean => {
  const Permissions = (window as any).LbsAppData.Permissions;

  return Permissions.hasAccessATypeOnTemplate(
    Permissions.get("FEATURE")["SCHEDULE"],
    assignment.templateToAssignTypeMap[template.id],
    assignment.templateToAssignIdMap[template.id],
    template.departmentId,
    template.fullName,
    Permissions.get("LEVEL")["WRITE"]
  );
};

export const CanIChangeAssignmentOnRequestsForTemplate = (assignment: Assignment, template: Template): boolean => {
  const Permissions = (window as any).LbsAppData.Permissions;

  return Permissions.hasAccessATypeOnTemplate(
    Permissions.get("FEATURE")["REQUESTS"],
    assignment.templateToAssignTypeMap[template.id],
    assignment.templateToAssignIdMap[template.id],
    template.departmentId,
    template.fullName,
    Permissions.get("LEVEL")["WRITE"]
  );
};

export const CanIChangeFillNotes = (personnel: Personnel, assignment: Assignment, template: Template): boolean => {
  const Permissions = (window as any).LbsAppData.Permissions;

  return Permissions.hasAccess(
    Permissions.get("FEATURE")["SCHEDULE_NOTES"],
    template.fullName,
    personnel.empId,
    personnel.pTypeId,
    assignment.templateToAssignTypeMap[template.id],
    assignment.templateToAssignIdMap[template.id],
    Permissions.get("my_depts"),
    template.departmentId,
    Permissions.get("LEVEL")["WRITE"]
  );
};

export const CanIChangeRequestNotes = (personnel: Personnel, assignment: Assignment, template: Template): boolean => {
  const Permissions = (window as any).LbsAppData.Permissions;

  return Permissions.hasAccess(
    Permissions.get("FEATURE")["REQUEST_NOTES"],
    template.fullName,
    personnel.empId,
    personnel.pTypeId,
    assignment.templateToAssignTypeMap[template.id],
    assignment.templateToAssignIdMap[template.id],
    Permissions.get("my_depts"),
    template.departmentId,
    Permissions.get("LEVEL")["WRITE"]
  );
};
