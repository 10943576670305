import { Personnel } from "@/viewer/types/domain/personnel";
import { Assignment } from "@/viewer/types/domain/assignment";

interface AssignmentFilterIdsUpdate {
  type: "assignment-filter-list/update";
  assignmentFilterIds: Assignment["assignStructureId"][];
}

interface PersonnelFilterIdsUpdate {
  type: "personnel-filter-list/update";
  personnelFilterIds: Personnel["empId"][];
}

export const UpdateAssignmentFilterIds = (ids: Assignment["assignStructureId"][]): AssignmentFilterIdsUpdate => ({
  type: "assignment-filter-list/update",
  assignmentFilterIds: ids,
});

export const UpdatePersonnelFilterIds = (ids: Personnel["empId"][]): PersonnelFilterIdsUpdate => ({
  type: "personnel-filter-list/update",
  personnelFilterIds: ids,
});

export type Action = AssignmentFilterIdsUpdate | PersonnelFilterIdsUpdate;
