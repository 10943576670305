// Helper to catalogue available application features

var moment = require("moment");
var _ = require("underscore");
var $ = require("jquery");

var FeaturesHelper = Backbone.Model.extend({
  defaults: {},

  initialize: function () {},

  /* publics */
  isAvailable: function (feature) {
    // check to see if _feature_ is available
    var ret = false;

    switch (feature) {
      case "swops": // swaportunities
        ret = window.LbsAppData.User.get("feature_swops");
        break;
      default:
        break;
    }

    return ret;
  },

  /* privates */
});

module.exports = FeaturesHelper;
