// LoaderChain.js
//
// attempt to clean up the api calls so models are available at necessary times

var $ = require("jquery");
var moment = require("moment");
var _ = require("underscore");

var LoaderChain = {
  _loadingStep: 0,
  _parentCallback: undefined,

  load: function (callback) {
    this._parentCallback = callback;
    // start the chain
    this._startChain();
  },

  /* private helpers */
  _startChain: function () {
    // load the link
    // reset the loadingStep
    this._loadingStep = 1;
    this._loadLink();
  },
  _endChain: function () {
    // call the parent back
    if (this._parentCallback) {
      this._parentCallback();
    }
  },
  _loadLink: function () {
    var fn = this._mapStep();
    // call the function
    fn.call(this);
  },
  _incrementChain: function () {
    this._loadingStep++;
    // next link
    this._loadLink();
  },

  // map steps to loader functions here
  _mapStep: function () {
    switch (this._loadingStep) {
      //case 0:
      //	return this._loadUser;
      case 1:
        return this._loadUserSettings;
      case 2:
        return this._loadPersonnel;
      case 3:
        return this._loadAssignmentDemand;
      case 4:
        return this._loadRoles;
      case 5:
        return this._loadTallies;
      case 6:
        return this._loadAssignments;
      case 7:
        return this._loadScheduleData;
      case 8:
        return this._loadTallyDataMap;
      case 9:
        return this._loadPublishedMap;
      case 10:
        return this._loadTallyDemand;
      case 11:
        return this._loadViews;
      default:
        // finished here
        return this._endChain;
        break;
    }
  },

  /* custom loading functions */
  _loadUser: function () {
    this._updateMessageText("Loading permissions...");

    var that = this;
    window.LbsAppData.User.fetch({
      success: function () {
        that._incrementChain.call(that);
      },
      error: function () {
        that._showError.call(that, "user data");
      },
    });
  },
  _loadUserSettings: function () {
    this._updateMessageText("Loading user settings...");

    var that = this;
    window.LbsAppData.UserSettings.fetch({
      success: function () {
        that._incrementChain.call(that);
      },
      error: function () {
        that._showError.call(that, "user settings");
      },
    });
  },
  _loadPersonnel: function () {
    this._updateMessageText("Loading personnel...");

    var that = this;
    window.LbsAppData.Personnel.fetch({
      reset: true,
      success: function () {
        that._incrementChain.call(that);
      },
      error: function () {
        that._showError.call(that, "personnel data");
      },
    });
  },
  _loadAssignments: function () {
    this._updateMessageText("Loading assignments...");

    var that = this;
    window.LbsAppData.Assignments.fetch({
      reset: true,
      success: function () {
        that._incrementChain.call(that);
      },
      error: function () {
        that._showError.call(that, "assignment data");
      },
    });
  },
  _loadScheduleData: function () {
    this._updateMessageText("Loading schedule data...");

    var that = this;
    window.LbsAppData.Slots.fetch({
      reset: true,
      success: function () {
        that._incrementChain.call(that);
      },
      error: function () {
        that._showError.call(that, "schedule data");
      },
    });
  },
  _loadTallies: function () {
    this._updateMessageText("Loading tallies...");

    var that = this;
    window.LbsAppData.Tallies.fetch({
      reset: true,
      success: function () {
        that._incrementChain.call(that);
      },
      error: function () {
        that._showError.call(that, "tally data");
      },
    });
  },
  _loadTallyDataMap: function () {
    //var that = this;
    //window.LbsAppData.TallyDataMap.fetch({reset: true, 'success': function() { that._incrementChain.call(that); } });
    // no reason to load this on the initial load..it'll get loaded when they click something that needs it
    this._incrementChain();
  },
  _loadAssignmentDemand: function () {
    this._updateMessageText("Loading assignment demand...");

    var that = this;
    window.LbsAppData.AssignmentDemand.fetch({
      reset: true,
      success: function () {
        that._incrementChain.call(that);
      },
      error: function () {
        that._showError.call(that, "assignment demand");
      },
    });
  },
  _loadRoles: function () {
    this._updateMessageText("Loading roles...");

    var that = this;
    window.LbsAppData.Roles.fetch({
      reset: true,
      success: function () {
        that._incrementChain.call(that);
      },
      error: function () {
        that._showError.call(that, "personnel roles");
      },
    });
  },
  _loadPublishedMap: function () {
    //this._updateMessageText('Loading permissions...');

    var that = this;
    window.LbsAppData.PublishedMap.fetch({
      reset: true,
      success: function () {
        that._incrementChain.call(that);
      },
      error: function () {
        that._showError.call(that, "schedule mappings");
      },
    });
  },
  _loadTallyDemand: function () {
    this._updateMessageText("Loading tally demand...");

    var that = this;
    window.LbsAppData.TallyDemand.fetch({
      reset: true,
      success: function () {
        that._incrementChain.call(that);
      },
      error: function () {
        that._showError.call(that, "tally demand");
      },
    });
  },
  _loadViews: function () {
    this._updateMessageText("Loading views...");

    var that = this;
    window.LbsAppData.Views.fetch({
      reset: true,
      success: function () {
        that._incrementChain.call(that);
      },
      error: function () {
        that._showError.call(that, "view data");
      },
    });
  },

  /* helpers */
  _updateMessageText: function (text) {
    $(".popup-message").text(text);
  },
  _showError: function (text) {
    var message =
      "There was a problem loading " +
      text +
      ". Lightning Bolt Support has been alerted\
		and will work to fix it as quickly as possible. We apologize for any inconvenience.";

    $("#please-wait").hide();
    window.LbsAppData.AppContext.openDialog({
      type: "error",
      title: "Something went wrong",
      message: message,
      noClose: true,
    });
  },
};

module.exports = LoaderChain;
