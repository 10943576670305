"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var moment = require("moment");

// collections
// mixins
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
var SwapMixin = require("_lib/ui/mixins/SwapMixin.jsx");

var PreSwapPendingDialog = React.createClass({
  mixins: [TouchMixin, SwapMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {
      step: 1,
      initStep: 1,
    };
  },
  componentDidMount: function () {
    // send a tracking event
    if (!this.props.inline) {
      window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Preswap: Pending");
    }
  },

  /* privates */
  _getTotalSteps: function () {
    return 1;
  },
  _getTitleText: function () {
    var action = this.props.getData("pendingAction");
    return action.charAt(0).toUpperCase() + action.substr(1);
  },
  /* publics */

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      SwapPending: true,
      grant: this.props.getData("pendingAction") == window.LbsAppData.ACCEPT_ACTION,
      deny: this.props.getData("pendingAction") == window.LbsAppData.DECLINE_ACTION,
      delete: this.props.getData("pendingAction") == window.LbsAppData.DELETE_ACTION,
    });

    var content;
    if (!this.props.inline) {
      content = (
        <div className={classes} data-cy="PSPWindowPrompt">
          <div className="position-reset">
            <div
              className="close"
              onClick={this.touchProxy.bind(this, this.props.close, [])}
              onTouchEnd={this.touchProxy.bind(this, this.props.close, [])}
            >
              <i className="fa fa-close" />
            </div>

            <div className="header-container">
              <div className="header">
                <div className="mobile-section-nav">
                  <div className="mobile-section-nav-tab active">{this._getTitleText()}</div>
                </div>
              </div>
            </div>

            <div className="section-container">
              <div className={"section _" + this.state.step}>{this._renderStep1()}</div>
            </div>

            <div className="bottom-controls">
              <div className="position-reset">
                <div className="left-side">{this._getBottomLeftControl(this._getTotalSteps())}</div>
                <div className="right-side">{this._getBottomRightControl(this._getTotalSteps())}</div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      content = this._renderInline();
    }

    return content;
  },
  /* rendering functions */
  _renderStep1: function () {
    var swapSentence = this.props.widgetProxy("sentenceSwap");

    return (
      <div className="content">
        <div className="details">
          <div className="sentence-container">{swapSentence}</div>
        </div>
      </div>
    );
  },
  _renderInline: function () {
    return <div className="inline-container"></div>;
  },
});

module.exports = PreSwapPendingDialog;
