// Helper to maintain a socket connection with Felicity and allow real-time bi-directional communication
// throughout the LBLite ecosystem

import FelicityConfig from "@/_lib/data/FelicityConfig";
import SocketIO from "socket.io-client";

export interface IRegisterCallback {
  (type: string, data: unknown): void;
}

export interface ISocketTransport {
  connect: (customerId: number, channel: string) => void;
  disconnect: () => void;
  send: (type: string, message: unknown) => void;
  registerCallback: (callback: IRegisterCallback) => void;
  removeCallback: () => void;
}

export class SocketTransport implements ISocketTransport {
  constructor() {
    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.send = this.send.bind(this);
    this.registerCallback = this.registerCallback.bind(this);
    this.removeCallback = this.removeCallback.bind(this);
  }

  storedCustomerId?: number;
  storedChannel?: string;
  socket?: SocketIOClient.Socket;

  public connect(customerId: number, channel: string): void {
    if (this.socket && this.storedChannel !== channel) {
      this.socket.disconnect();
    }

    const query = {
      customer_id: customerId,
      stream_id: channel,
    };

    if (this.socket) {
      this.socket.io.opts.query = query;
      this.socket.connect();
    } else {
      const felicityPrefix = FelicityConfig.getPrefix();
      if (felicityPrefix) {
        this.socket = SocketIO(felicityPrefix, {
          query,
        });
      }
    }

    this.storedCustomerId = customerId;
    this.storedChannel = channel;
  }
  public disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.storedCustomerId = undefined;
      this.storedChannel = undefined;
    }
  }
  public send(type: string, message: unknown): void {
    if (this.socket) {
      this.socket.emit(type, message);
    }
  }
  public registerCallback(callback: IRegisterCallback): void {
    if (this.socket) {
      // this is sort of the catalogue of possible events (as well as the registration code)
      this.socket.on("slot_update", function (data: unknown) {
        // call the receive function passed in from the app's initialization of this model
        callback("slot_update", data);
      });
      this.socket.on("slot_remove", function (data: unknown) {
        // call the receive function passed in from the app's initialization of this model
        callback("slot_remove", data);
      });
      this.socket.on("schedule_clear", function (data: unknown) {
        // call the receive function passed in from the app's initialization of this model
        callback("schedule_clear", data);
      });
    }
  }

  public removeCallback(): void {
    if (this.socket) {
      this.socket.off("slot_update");
      this.socket.off("slot_remove");
      this.socket.off("schedule_clear");
    }
  }
}
