import React from "react";

interface Props {
  filename: string;
  getData: () => string;
  doneCallback: () => void;
}

const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const buildURI = (csvData: string) => {
  const type = isSafari() ? "application/csv" : "text/csv";
  const blob = new Blob([csvData], { type });
  const dataURI = `data:${type};charset=utf-8,${csvData}`;

  const URL = window.URL || window.webkitURL;

  return typeof URL.createObjectURL === "undefined" ? dataURI : URL.createObjectURL(blob);
};

const CsvDownload = (props: Props): JSX.Element => {
  const { filename, getData, doneCallback } = props;

  const href = buildURI(getData());

  /*
      We want this component to trigger a download as soon as it is rendered.
      To accomplish this we use this ref callback to click the link. React will
      call the callback as soon as the element mounts.

      The doneCallback is then called so that the parent component can rehide this
      component.
     */
  const handleRef = (e: any) => {
    if (e) {
      e.click();
    }
    doneCallback();
  };

  return <a ref={handleRef} download={filename} href={href} target="_self" />;
};

export default CsvDownload;
