var Router = Backbone.Router.extend({
  routes: {
    "": "openTemplate",
    "t/:template_id": "openTemplate",
  },

  openTemplate: function (id) {
    if (window.LbsAppData.AppContext.get("view").view_id != "today") {
      return;
    } else if (!id) {
      // get the default template
      id = this._getDefaultTemplate();
    }

    this._openTemplate(id);
  },

  /* privates */
  _openTemplate: function (id) {
    // open a template
    var tplName = window.LbsAppData.Templates.get(id).get("name");
    window.LbsAppData.AppContext.openLoadingDialog("Opening '" + tplName + "'...", this, function () {
      // send a tracking event -- putting this here to avoid tracking first-view openings
      window.LbsAppData.Helpers.Analytics.sendEvent("Open: Template", id);

      // open the view
      window.LbsAppData.AppContext.openTemplate(id);
    });
  },

  _getDefaultTemplate: function () {
    var tplId = window.LbsAppData.Templates.at(0).id;
    if (
      window.LbsAppData.UserSettings.get("settings") &&
      window.LbsAppData.UserSettings.get("settings").default_template
    ) {
      tplId = window.LbsAppData.UserSettings.get("settings").default_template;
    }
    return tplId;
  },
});

module.exports = Router;
