import { Collection, Model } from "backbone";

import { Request } from "@/viewer/types/domain/request";
import {
  DataContext,
  FilterContext,
  SettingsContext,
  UIContext,
  ViewDataType,
} from "@/viewer/ui/modules/common/types/context";
import { SearchCorpusParams } from "@/_lib/utils/constructSearchCorpus";

import connectedPropertyHookFactory from "@/viewer/ui/modules/common/hooks/connectedPropertyHookFactory";
import { cleanRequestAttributes } from "@/viewer/types/domain/request";
import { isSlotVisible } from "@/viewer/utils/domain/slotquests";

const getRequestData = (
  settings: SettingsContext,
  ui: UIContext,
  filters: FilterContext,
  data: DataContext,
  requestCollection: Collection,
  searchCorpusParams?: SearchCorpusParams
): Request[] => {
  const { LbsAppData } = window as any;

  // TODO remove dependency on LbsAppDAta
  const reqWindowOrLastPub: {
    [templateId: string]: string;
  } = LbsAppData.Templates?.getReqWindowStartOrLatestScheduled();

  if (!requestCollection || !reqWindowOrLastPub) return [];

  // only do the futureRequests logic IF this is a combined view...otherwise (a request only view) allow them all to show
  let futureRequests: Model[];

  if (settings.viewDataType === ViewDataType.request) {
    futureRequests = requestCollection.models;
  } else {
    futureRequests = requestCollection.filter(function (item) {
      return item.attributes.request_date_original > reqWindowOrLastPub[item.attributes.template_id];
    });
  }

  const out: Request[] = [];
  futureRequests.forEach((item) => {
    const attrs = cleanRequestAttributes(settings, item.attributes, searchCorpusParams);
    if (isSlotVisible(attrs, ui, filters, data)) {
      out.push(attrs);
    }
  });

  return out;
};

export default connectedPropertyHookFactory({
  loader: getRequestData,
  getListeners: () => {
    const { LbsAppData } = window as any;

    return [
      [LbsAppData.AppContext, "redraw"],
      [LbsAppData.AppContext, "fetched"],
      [LbsAppData.Requests, "add"],
      [LbsAppData.Requests, "change"],
      [LbsAppData.Requests, "remove"],
    ];
  },
});
