import * as React from "react";

/**
 * This gives us access to the logic of `componentDidUpdate`
 */

export default (effect: React.EffectCallback, deps: ReadonlyArray<any>) => {
  const isInitialMount = React.useRef(true);
  return React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      return effect();
    }
  }, deps);
};
