import connectedPropertyHookFactory from "./connectedPropertyHookFactory";
import { PermissionsContext as PermissionsContextType } from "../types/context";

/**
 * Global permission settings for the user.
 */
const getPermissions = (): PermissionsContextType => {
  const { LbsAppData } = window as any;

  const { Permissions } = LbsAppData.Helpers;

  return {
    canIChangeAnyonesSchedule: Permissions?.CanIChangeAnyonesSchedule() ?? false,
    canIChangeAnyonesRequests: Permissions?.CanIChangeAnyonesRequests() ?? false,
    canIApproveDenyAnyoneElsesSchedule: Permissions?.CanIApproveDenyAnyoneElsesSchedule() ?? false,
    canIApproveDenyAnyoneElsesRequest: Permissions?.CanIApproveDenyAnyoneElsesRequest() ?? false,
  };
};

export default connectedPropertyHookFactory({
  loader: getPermissions,
  getListeners: () => {
    const { LbsAppData } = window as any;
    return [[LbsAppData.Permissions, "fetched"]];
  },
});

export const defaultPermissionsPlaceholder: PermissionsContextType = {
  canIChangeAnyonesSchedule: false,
  canIChangeAnyonesRequests: false,
  canIApproveDenyAnyoneElsesSchedule: false,
  canIApproveDenyAnyoneElsesRequest: false,
};
