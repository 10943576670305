/*
 * Department model that doesn't depend on window object bindings */
const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");

var NakedDepartment = Backbone.Model.extend({
  idAttribute: "department_id",

  defaults: {},

  initialize: function () {
    // spawn child Templates
    if (window.LbsAppData.Templates) {
      for (var i = 0; i < this.attributes.templates.length; i++) {
        var templateAttributes = this.attributes.templates[i];
        templateAttributes.department_id = this.attributes.department_id;
        templateAttributes.is_hidden = this.attributes.is_hidden;
        window.LbsAppData.Templates.add(templateAttributes);
      }
    }
  },

  url: function () {
    return ApiConfig.getPrefix() + (this.id ? "/department/" + this.id : "/department");
  },

  _isHidden: function () {
    return this.attributes.is_hidden;
  },
});

module.exports = NakedDepartment;
