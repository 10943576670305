import React from "react";
import useActivityFeedIconData from "@/common/hooks/useActivityFeedIconData";

interface Props {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const ActivityFeedIcon = ({ onClick }: Props): React.ReactElement => {
  const { hasPendingItems } = useActivityFeedIconData();

  return (
    <button onClick={onClick} className="top-bar-btn btn-icon">
      <div className="icon">
        {hasPendingItems && <div className="activity-feed-chip"></div>}
        <i className="fa fa-list" data-cy="TBActivityFeedBtn" />
      </div>
    </button>
  );
};

export default ActivityFeedIcon;
