import { RequireIntersection } from "@/_lib/utils/typeUtils";

export interface ParsedResponse<T> {
  data: T | null;
  error: APIError | null;
  status: number;
}

export interface APIError {
  message: string;
  body: Error | null;
}

export interface Error {
  name: string;
  message: string;
}

export interface MessageResponse<T> extends RequireIntersection<ParsedResponse<T>, "data"> {
  message: string;
}

interface ResponseDataMessage {
  Message: string;
}

export const transformParsedResponseDataToMessageResponse = <T>(
  parsedResponse: ParsedResponse<T>
): MessageResponse<T> => {
  return {
    ...parsedResponse,
    message: (parsedResponse?.data && (parsedResponse?.data as unknown as ResponseDataMessage).Message) ?? "",
  } as MessageResponse<T>;
};

export async function getError(resp: Response): Promise<APIError> {
  return { message: resp.statusText, body: null };
}

export async function getParsedResponse<T>(resp: Response): Promise<ParsedResponse<T>> {
  if (resp.status >= 200 && resp.status < 300) {
    if (resp.status == 204) {
      // handle deletes with no resp data
      return { status: resp.status, error: null, data: null };
    }
    const data: T = await resp.json();
    return { status: resp.status, error: null, data };
  }
  return { status: resp.status, error: await getError(resp), data: null };
}
