export const FetchReport = async (): Promise<void> => {
  const LbsAppData = (window as any).LbsAppData;

  if (LbsAppData.Report) await LbsAppData.AppContext.fetchReport();
};

export const TriggerRedraw = (): void => {
  const AppContext = (window as any).LbsAppData.AppContext;

  AppContext.triggerRedraw();
};
