import React from "react";

import { PersonnelOrAssignment, RequestsByDateByName } from "@/viewer/ui/modules/common/types";
import { SettingsContext } from "@/viewer/ui/modules/common/types/context";
import { columnRowHeightMap } from "../ColumnPrintView";

import { getEffects } from "@/viewer/utils/domain/perssignment";
import classnames from "@/_lib/utils/classnames";

import PrintColumnRequestHeaderItem from "./printColumnRequestHeaderItem";

import "./_styles.scss";

interface Props {
  columnRowHeightMap: columnRowHeightMap;
  condenseColumnView: boolean;
  forceRequestsGroup: boolean;
  height: string;
  hideBlankRows: boolean;
  leftColId: string;
  leftColumn: PersonnelOrAssignment;
  leftHandColumnId: number;
  requestData: RequestsByDateByName;
  rowIndex: number;
  settings: SettingsContext;
  visibleItemCount: number;
}

const getRequestItem = (props: Props): JSX.Element | null => {
  const { condenseColumnView, leftColumn, leftHandColumnId, settings, visibleItemCount, height, rowIndex } = props;
  const leftColText = leftColumn.compactOrDisplayName;
  const effects = getEffects(leftColumn, settings);
  const rowClassNames = classnames("row", {
    "row--even": visibleItemCount % 2 === 0,
    "row--odd": visibleItemCount % 2 !== 0,
  });
  const cellClassNames = classnames("fixed-cell", {
    "fixed-cell--condensed": condenseColumnView,
  });

  const requestProps = { cellClassNames, effects, height, leftColText, leftHandColumnId, rowClassNames, rowIndex };

  return <PrintColumnRequestHeaderItem {...requestProps} />;
};

const PrintColumnRenderRequestHeader = (props: Props): JSX.Element | null => {
  return getRequestItem(props);
};

export default PrintColumnRenderRequestHeader;
