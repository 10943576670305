"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");
var _ = require("underscore");
// components
var RequestsDialogListTabs = require("./RequestsDialogListTabs.jsx");
var RequestsDialogListItemsContainer = require("./RequestsDialogListItemsContainer.jsx");

var RequestsDialogList = React.createClass({
  /* lifecycle methods */
  getInitialState: function () {
    return {
      activeTabs: ["requests", "swaps"],
      //activeControl: undefined,

      filter: "me",
      sort: "date",
      startDate: moment(this.props.startDate),
      endDate: moment(this.props.endDate),
    };
  },
  componentDidMount: function () {},
  componentWillUnmount: function () {},
  //componentWillUpdate: function() {
  //	this._unregisterBackboneEvents();
  //},
  //componentDidUpdate: function() {
  //	this._registerBackboneEvents();
  //},

  /* publics */
  update: function (options) {
    // reconfigure the collections
    this.props.requests.attributes.start_date = moment(options.startDate);
    this.props.requests.attributes.end_date = moment(options.endDate);
    this.props.swaps.attributes.start_date = moment(options.startDate);
    this.props.swaps.attributes.end_date = moment(options.endDate);

    if (options.filter != "me") {
      // undefine the emp_id attributes
      this.props.requests.attributes.emp_id = undefined;
      this.props.swaps.attributes.emp_id = undefined;
    } else {
      this.props.requests.attributes.emp_id = window.LbsAppData.User.attributes.emp_id;
      this.props.swaps.attributes.emp_id = window.LbsAppData.User.attributes.emp_id;
    }

    this.forceUpdate();
  },

  /* privates */
  //_toggleControls: function(e) {
  //	if (this.state.activeControl != 'controls') {
  //		this.setState({activeControl: 'controls'});
  //	} else {
  //		this.setState({activeControl: undefined});
  //	}
  //
  //	//if (!this.refs.Options.state.active) {
  //	//	this.refs.Options.setState({active: true});
  //	//} else {
  //	//	this.refs.Options.setState({active: false});
  //	//}
  //},

  _selectTab: function (tab) {
    var tabIndex = this.state.activeTabs.indexOf(tab);
    if (tabIndex > -1) {
      // remove it
      this.state.activeTabs.splice(tabIndex, 1);
    } else {
      // add it
      this.state.activeTabs.push(tab);
    }

    this.forceUpdate();
  },
  _handleSearch: function (e, val) {
    // proxy into the lists search function
    this.refs.Listing._handleSearch(e, val);
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var component;
    if (this.props.isLoading) {
      // push the loader icon
      component = (
        <div className="loader">
          <i className="fa fa-fw fa-spin fa-spinner" />
        </div>
      );
    } else {
      var itemDivs = [];

      var items = [];
      if (this.state.activeTabs.indexOf("requests") > -1) {
        // push the requests
        items = items.concat(this.props.requests.models);

        //component =
        //	<RequestsDialogListRequest
        //		ref='Listing'
        //		isLoading={this.props.isLoading}
        //		requests={this.props.requests} />
      }

      if (this.state.activeTabs.indexOf("swaps") > -1) {
        // push the swaps
        items = items.concat(this.props.swaps.models);

        //component =
        //	<RequestsDialogListSwap
        //		ref='Listing'
        //		isLoading={this.props.isLoading}
        //		swaps={this.props.swaps} />
      }

      items = _.sortBy(items, function (item) {
        return item.attributes.request_date || item.attributes.slot_date;
      });

      component = <RequestsDialogListItemsContainer ref="Listing" isLoading={this.props.isLoading} items={items} />;
    }

    var classes = React.addons.classSet({
      RequestsList: true,
    });

    return (
      <div className={classes}>
        <RequestsDialogListTabs
          activeTabs={this.state.activeTabs}
          filter={this.state.filter}
          sort={this.state.sort}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          incrementMonth={this.props.incrementMonth}
          decrementMonth={this.props.decrementMonth}
          selectTab={this._selectTab}
          handleSearch={this._handleSearch}
          update={this.update}
        />

        {component}
      </div>
    );
  },
});

module.exports = RequestsDialogList;
