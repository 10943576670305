import { MILITARY } from "@/viewer/data/constants";
import connectedPropertyHookFactory from "./connectedPropertyHookFactory";
import { SettingsContext as SettingsContextType } from "../types/context";

/**
 * Gather our crack team of global settings from the very corners of LbsAppData
 */
const getSettings = (): SettingsContextType => {
  const { LbsAppData } = window as any;

  const { AppContext, User } = LbsAppData;
  const view = AppContext.get("view");
  const themeData = view?.theme?.data;

  return {
    app: LbsAppData.id,
    blockLength: parseInt(AppContext.get("blockLength")) ?? 7,
    blockStartDay: AppContext.get("blockStartDay") ?? "Mon",
    blockWeekLength: parseInt(AppContext.get("blockWeekLength")) ?? 1,
    colorMethod: AppContext.get("colorMethod"),
    colorTextMethod: AppContext.get("colorTextMethod"),
    columnNames: themeData ? themeData.listColumns : [],
    condenseColumnView: themeData ? themeData.condenseColumns === "yes" : false,
    displayedScheduleId: AppContext.attributes.displayedSchedule?.id,
    forceScheduleGroup: AppContext.forceScheduleGroup ? AppContext.forceScheduleGroup() : false,
    forceRequestsGroup: AppContext.forceRequestsGroup ? AppContext.forceRequestsGroup() : false,
    isCompact: AppContext.get("compactMode") == "yes",
    ignorePending: themeData ? themeData.hidePending === "yes" : false,
    hideBlankRows: themeData ? themeData.hideBlankRows === "yes" : false,
    hideExpired: AppContext.get("GlobalSettings_hideExpired"),
    hideWeekends: AppContext.get("hideWeekends") === "yes",
    isUserAdmin: User.get("is_admin"),
    isUserSuperAdmin: User.get("is_super_admin"),
    customerId: User.get("customer_id"),
    layout: AppContext.get("layout"),
    personalFiltersEnabled: themeData ? themeData.personalFilter === "yes" : false,
    plugins: AppContext.get("plugins") ?? {},
    range: AppContext.get("range"),
    shouldCountTalliesByPersonnelFilter: AppContext.get("shouldCountTalliesByPersonnelFilter") === "yes",
    slotQuestOrdering: AppContext.attributes.slotOrdering,
    timeZone: User.get("tz"),
    timeZoneEnabled: User.get("parameters").LBLiteTimeZoneAwareness ?? false,
    useMilitaryTime: User.get("time_display") === MILITARY,
    view: AppContext.get("view"),
    viewId: view ? view.view_id : undefined,
    viewName: view ? view.name : undefined,
    viewDataType: AppContext.getViewDataType ? AppContext.getViewDataType() : undefined,
    viewTypeRequiresHighlighters: ["hybrid", "grid", "calendar", "block", "gantt"].some(
      (item) => item === AppContext.get("layout")
    ),
  };
};

export default connectedPropertyHookFactory({
  loader: getSettings,
  getListeners: () => {
    const { LbsAppData } = window as any;

    return [
      [LbsAppData.AppContext, "redraw"],
      [LbsAppData.User, "change:time_display"],
    ];
  },
});
