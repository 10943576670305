import * as React from "react";
import { VirtualizedRow } from "@/viewer/ui/modules/common/types/virtualization";
import StandardWeekHeaderRow from "@/viewer/ui/modules/grid/standard/components/StandardWeekHeaderRow";
import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { formatISODate } from "@/viewer/utils/dateFormatters";
import { HEADER_ROW_HEIGHT } from "@/viewer/ui/modules/grid/standard/constants";

interface RenderHeaderRowOpts {
  settings: SettingsContext;
  ui: UIContext;

  dateStart: Date;
  dateEnd: Date;

  // LbsAppData.DateManager.isDateInSchedule
  isDateInSchedule: (d: Date) => boolean;

  // LbsAppData.AppContext._isHoliday
  isHoliday: (d: Date) => boolean;
}

const RenderHeaderRow = ({
  dateStart,
  dateEnd,
  isDateInSchedule,
  isHoliday,
  settings,
  ui,
}: RenderHeaderRowOpts): VirtualizedRow => {
  const weekKey = formatISODate(settings, dateStart);

  return {
    height: HEADER_ROW_HEIGHT,
    key: `header-${weekKey}`,
    wrapperClassName: "header-wrapper",
    element: (
      <div className={"header"}>
        <StandardWeekHeaderRow
          dateStart={dateStart}
          dateEnd={dateEnd}
          isDateInSchedule={isDateInSchedule}
          isHoliday={isHoliday}
          settings={settings}
          ui={ui}
        />
      </div>
    ),
  };
};

export default RenderHeaderRow;
