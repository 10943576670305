interface MyWindow extends Window {
  LbsAppData: any;
  env: any;
  payload: {
    [key: string]: number | string | boolean | Date | undefined;
    logged_in: boolean;
  };
}

const myWindow = window as unknown as MyWindow;

// Get department from window object for single department views
export const getDepartment = (): number | null => {
  const view = myWindow.LbsAppData.AppContext.get("view");
  const deptList = view
    ? view.attributes
      ? (view.attributes.filter.on_departments as number[])
      : (view.filter.on_departments as number[])
    : null;
  if (deptList && deptList.length === 1) {
    return deptList[0];
  }
  return null;
};

export default myWindow;
