/**
 * Helper to wrap up any messaging functions
 */

const { Device, getDevice } = require("../utils/device");

var moment = require("moment");
var _ = require("underscore");

var MessagingHelper = Backbone.Model.extend({
  defaults: {
    providers: {},
  },

  initialize: function (attributes, options) {},
  configure: function () {},

  /* publics */
  getSecureMessagingProfileLink: function (username) {
    var provider = window.LbsAppData.AppContext.get("plugins").sm_p;
    return provider + "://x-callback-url/profile?username=" + username;
  },
  getSecureMessagingCallLink: function (number) {
    var provider = window.LbsAppData.AppContext.get("plugins").sm_p;
    return provider + "://x-callback-url/voice?number=" + number;
  },
  getSecureMessagingChatLink: function (sm_c, sm_d, sm_a) {
    // if we don't have a client, domain or account then we don't have a link
    // this prevents showing a bad link for a user detail
    if (!sm_c || !sm_d || !sm_a) {
      return "";
    }

    const device = getDevice();
    switch (sm_c) {
      case "voalte":
        return device === Device.Desktop
          ? `https://${sm_d}/messenger/#/x-callback-url/chat?username=${sm_a}`
          : `voalte://x-callback-url/chat?username=${sm_a}`;
      case "cortext":
        return `cortext://conversation?email_address=${sm_a}`;
      case "vocera":
        return device === Device.Desktop
          ? `http://${sm_d}/Invoke.aspx?commMode=Chat&searchScope=email&recipientKey=${sm_a}`
          : `voceracs://invoke?commMode=Chat&searchScope=email&recipientKey=${sm_a}`;
      case "perfectserve":
        return device === Device.Desktop ? `https://${sm_d}/message/${sm_a}` : `pservemobileprod://message/`;
      case "telmediq":
        return {
          [Device.Desktop]: `https://${sm_d}/resolve-recipients/?email=${sm_a}`,
          [Device.Android]: `tmiq://threads/compose?userIdentifier=${sm_a}`,
          [Device.IOS]: `sp://threads/compose?email=${sm_a}`,
        }[device];
      case "epic_sc":
        return (
          device === Device.IOS && `epichaiku://launch/securechat/command/newconversation?participantkey=EMP;${sm_a}`
        );
      case "vina":
        return `https://${sm_d}/ei/w/SendWebMessage/per_select_phone_search_results_operator3?Devices.usr.login=${sm_a}`;
      default:
        return "";
    }
  },

  createMessage: function (attributes, options) {
    return new Message(attributes, options);
  },
  sendTrackingEvent: function (action, label) {
    window.LbsAppData.Helpers.Analytics.sendEvent(action, label);
  },
});

module.exports = MessagingHelper;

/* message class */
var Message = Backbone.Model.extend({
  defaults: {
    to: null,
    from: null,
    message: null,
  },
  initialize: function (attributes, options) {},

  /* publics */
});
