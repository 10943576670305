/**
 * The height of a standard week data row, where each cell has at most 1 slot is 22 pixels.
 *
 * This height is otherwise determined by the data cell with the greatest number of slots and is
 * calculated at render time per row.
 *
 * See renderers/renderTallyGroups.tsx, renderers/renderSlots.tsx, and renderers/renderRequests.tsx for details.
 */
export const BASE_STANDARD_WEEK_DATA_ROW_HEIGHT = 22;

export const HEADER_ROW_HEIGHT = 35;
export const TOP_SPACER_ROW_HEIGHT = 36;
export const BOTTOM_SPACER_ROW_HEIGHT = 500;
export const GROUP_BANNER_ROW_HEIGHT = 29;
