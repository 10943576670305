const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var Role = require("./../models/Role.js");

var RoleCollection = Backbone.Collection.extend({
  model: Role,
  //comparator: 'assign_id',

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    if (this.attributes.deptList) {
      return ApiConfig.getPrefix() + "/roles/?dept_list=" + this.attributes.deptList;
    } else {
      return ApiConfig.getPrefix() + "/roles/";
    }
  },
});

module.exports = RoleCollection;
