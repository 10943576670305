import store from "@/store";

export function getAuthHeaders(): { [k: string]: string } | undefined {
  const {
    session: { accessToken },
  } = store.getState();
  if (accessToken) {
    return {
      Authorization: "Bearer " + accessToken,
    };
  }
  return;
}
