const Backbone = require("backbone");
var _ = require("underscore");

var TallyCollection = require("_lib/data/collections/NakedTallyCollection.js");
var PersonnelCollection = require("_lib/data/collections/NakedPersonnelCollection.js");

var ReportLibrary = Backbone.Model.extend({
  defaults: {},

  initialize: function () {
    this.collections = {};
    this.collections.tallies = new TallyCollection(this.attributes.tallies);
    this.collections.personnel = new PersonnelCollection(this.attributes.personnel);

    //var that = this;
    //_.each(this.attributes._lib, function(libCollection, key) {
    //	switch(key) {
    //		case 'tallies':
    //			that.collections.tallies = new TallyCollection(libCollection);
    //			break;
    //		case 'personnel':
    //			that.collections.personnel = new PersonnelCollection(libCollection);
    //			break;
    //		default:
    //			break;
    //	}
    //});
  },

  /* publics */
});

module.exports = ReportLibrary;
