const Backbone = require("backbone");
// Manager to facilitate actions based on the current tool

const _ = require("underscore");

const SelectTool = require("./tools/SelectTool.js");
const FillTool = require("./tools/FillTool.js");
const RemoveTool = require("./tools/RemoveTool.js");
const AlternatesTool = require("./tools/AlternatesTool.js");

const NO_MAPPED_CHANGES =
  "The current version of Editor does not allow changes to mapped schedules from within a child schedule.\
								If you need to update a mapped schedule slot please do so on the parent schedule where the slot resides. We apologize for any inconvenience.";
const FIDDLEDEAD_ERROR =
  "We can't seem to contact our server.  Is your network connection okay?  If so try refreshing the page.";

const SLOT_PENDING =
  "This slot is currently pending. Please use Viewer to make changes or wait until the schedule is published.";

// tool collections
const HighlighterPack = require("./HighlighterPack.js");

const ToolBox = Backbone.Model.extend({
  defaults: {
    currentTool: undefined,
    availableTools: {},
  },

  initialize: function () {
    // create the tool models

    // select
    this.attributes.availableTools["select"] = new SelectTool();
    // fill
    this.attributes.availableTools["fill"] = new FillTool();
    // alternates
    this.attributes.availableTools["alternates"] = new AlternatesTool();
    // remove
    this.attributes.availableTools["remove"] = new RemoveTool();

    // highlighter pack
    const highlighterPack = new HighlighterPack();

    highlighterPack.add({
      id: "0",
      backgroundColor: "#FEFF84",
      color: "#000000",
    }); // yellow
    highlighterPack.add({
      id: "1",
      backgroundColor: "#A7F8A0",
      color: "#000000",
    }); // green
    highlighterPack.add({
      id: "2",
      backgroundColor: "#A0F8F5",
      color: "#000000",
    }); // blue
    highlighterPack.add({
      id: "3",
      backgroundColor: "#D8A0F8",
      color: "#000000",
    }); // purple
    highlighterPack.add({
      id: "4",
      backgroundColor: "#FFB7F2",
      color: "#000000",
    }); // pink
    highlighterPack.add({
      id: "5",
      backgroundColor: "#FFA3A3",
      color: "#000000",
    }); // red
    highlighterPack.add({
      id: "6",
      backgroundColor: "#FFBF74",
      color: "#000000",
    }); // orange

    this.attributes.availableTools["highlighters"] = highlighterPack;

    // set the default
    this.set({ currentTool: this.attributes.availableTools["select"] });
  },

  /* publics */
  perform: function (action, args) {
    // all public tool functions should pass through this
    if (action === "click") {
      this.performClick(args);
    } else if (action === "value-get") {
      return this.attributes.currentTool.get(args["attr_name"]);
    } else if (action === "value-set") {
      this.attributes.currentTool.set(args);
    } else if (action === "execute") {
      this.attributes.currentTool.onExecute();
    } else if (action === "reattach") {
      // only used by Select tool...so this isn't very expandable
      const slot = args["slot"];

      this.attributes.currentTool.attributes.target.set({
        boundTool: undefined,
      });

      // save the new target and component
      this.attributes.currentTool.set({ target: slot });
      this.attributes.currentTool.onAttach();
    } else if (action === "remove") {
      // we have a few places that allow you to remove/delete a slot...so makes sense to have a call here
      this.performRemove(args.slot, args.caller);
    } else if (action === "addslot") {
      // multiple places that allow you to add to demand
      this.performAddSlotDemand(args.date, args.assignment, args.callerComponent, args.callback);
    } else if (action === "details") {
      // save a change to slot details (a note, custom text, time change, etc.)
      this.performDetailsChange(args.slot, args.caller, args.updateList, args.hasChanged);
    } else if (action === "reset") {
      // only used by select/alternates tool currently when the dialog closes
      this.attributes.currentTool.reset();
    }
  },

  setTool: function (tool) {
    this.set({ currentTool: tool });
  },

  flutterTool: function () {
    // called when the axis change (maybe layout too?) call the onClose and onSelect functions on the tool
    this.attributes.currentTool.onClose();
    this.attributes.currentTool.onSelect();
  },

  selectTool: function (tool) {
    // first allow the current tool to do any cleanup
    if (this.attributes.currentTool) {
      this.attributes.currentTool.onClose();
    }

    switch (tool) {
      case "select":
        this.set({
          currentTool: this.attributes.availableTools["select"],
        });
        break;
      case "fill":
        this.set({
          currentTool: this.attributes.availableTools["fill"],
        });
        break;
      case "alternates":
        this.set({
          currentTool: this.attributes.availableTools["alternates"],
        });
        break;
      case "remove":
        this.set({
          currentTool: this.attributes.availableTools["remove"],
        });
        break;
      case "highlight":
        this.set({
          currentTool: this.attributes.availableTools["highlighters"].get(0),
        });
        break;
      default:
        break;
    }

    // lastly allow the tool to run any setup
    this.attributes.currentTool.onSelect();
  },

  performClick: function (args) {
    // if the action is being taken on a slot that exists (has something in it) -> component.props.slot
    // else the slot having the action invoked on is -> component.slot
    const isScheduledSlot = args.component.props.slot !== undefined;
    const slot = args.component.props.slot || args.component.slot;

    // possible that the tool already has a context, depending on what tool this is...that might not be good
    this.attributes.currentTool.onAttachPre();

    // save the new target and component
    // -- this is a bit hacky and i'd like an actual queuing mechanism eventually
    if (this.attributes.currentTool.attributes.inflight !== true) {
      this.attributes.currentTool.set({
        target: slot,
        component: args.component,
      });
    } else {
      this.attributes.currentTool.attributes.queue.push({
        target: slot,
        component: args.component,
        fn: this.performClick.bind(this, args),
      });

      // return -- come back when the action gets picked up
      return;
    }

    //If slot is pending block all operations except highlight and display the pending request message box
    if (slot.attributes.is_pending_request && this.attributes.currentTool.attributes.type !== "highlight") {
      window.LbsAppData.ToolBox._showSlotPendingError();
      return;
    }

    // some of the tools can't use the 'generic' flow
    switch (this.attributes.currentTool.attributes.type) {
      case "remove":
        // a click with the remove tool can get forwarded right to its execute (no attach phase)
        // totally ignore the click on a blank slot
        if (isScheduledSlot) {
          // TEMPORARY LOGIC...see below...dont' allow changes to mapped schedules..yet...completely remove everything
          // except the onExecute call once we allow it
          if (
            this._checkIsValidMap(slot.assignmentObj, slot.attributes.assign_structure_id, args.component.props.date)
          ) {
            this.attributes.currentTool.onExecute();
          } else {
            window.LbsAppData.ToolBox._showParentSchedError();
          }
        }
        break;

      case "highlight":
        // a click with the highlighter tool can get forwarded right to its Attach
        // totally ignore the click on a blank slot
        if (isScheduledSlot) {
          this.attributes.currentTool.onAttach();
        }
        break;

      case "fill": {
        // splitting this out for tracking purposes
        let rowTypeTitle =
          window.LbsAppData.AppContext.getLeftColumnType() === "assignment" ? "Personnel" : "Assignment";

        if (isScheduledSlot) {
          // track an event
          window.LbsAppData.Helpers.Analytics.sendEvent("Update: Slot", "Fill Tool: " + rowTypeTitle);

          // on a slot that already exists...the job is way easier, call the tools onAttach method
          // TEMPORARY LOGIC to prevent mapped slots from being changed...this can be lifted once we allow published schedules to be changed
          // remove the if/else completely once we can make those published schedule changes, leaving only the onAttach call
          if (
            this._checkIsValidMap(slot.assignmentObj, slot.attributes.assign_structure_id, args.component.props.date)
          ) {
            this.attributes.currentTool.onAttach();
          } else {
            window.LbsAppData.ToolBox._showParentSchedError();
          }
        } else {
          // otherwise we gotta do some prep work
          this._performPrepareStep1(slot);

          // track an event
          window.LbsAppData.Helpers.Analytics.sendEvent("Update: Placeholder", "Fill Tool: " + rowTypeTitle);
        }
        break;
      }

      default: {
        // capitalize the tool name
        const toolName =
          this.attributes.currentTool.attributes.type.charAt(0).toUpperCase() +
          this.attributes.currentTool.attributes.type.slice(1);
        const rowTypeTitle =
          window.LbsAppData.AppContext.getLeftColumnType() === "assignment" ? "Personnel" : "Assignment";

        if (isScheduledSlot) {
          // track an event
          window.LbsAppData.Helpers.Analytics.sendEvent("Focus: Slot", toolName + " Tool: " + rowTypeTitle);

          // on a slot that already exists...the job is way easier, call the tools onAttach method
          // TEMPORARY LOGIC to prevent mapped slots from being changed...this can be lifted once we allow published schedules to be changed
          // remove the if/else completely once we can make those published schedule changes, leaving only the onAttach call
          if (
            this._checkIsValidMap(slot.assignmentObj, slot.attributes.assign_structure_id, args.component.props.date)
          ) {
            this.attributes.currentTool.onAttach();
          } else {
            window.LbsAppData.ToolBox._showParentSchedError();
          }
        } else {
          // otherwise we gotta do some prep work
          this._performPrepareStep1(slot);

          // track an event
          window.LbsAppData.Helpers.Analytics.sendEvent("Focus: Placeholder", toolName + " Tool: " + rowTypeTitle);
        }
        break;
      }
    }
  },

  // this one has a callFinished option...mainly for the actual remove tool
  performRemove: function (slot, caller) {
    const currentTool = window.LbsAppData.ToolBox.get("currentTool");

    // TEMPORARY LOGIC...same story...prevent mapped schedule changes for now
    // completely remove this check when it's allowed
    if (this._checkIsValidMap(slot.assignmentObj, slot.attributes.assign_structure_id, slot.attributes.slot_date)) {
      const that = this;

      slot.destroy({
        wait: true,
        success: function () {
          slot.removeListeners();

          that.attributes.currentTool.trigger("removeSlot");

          if (caller === "remove_tool") {
            that.attributes.currentTool.onFinished();
          } else if (caller === "select_tool") {
            that.attributes.currentTool.forceUpdateComponent();
          } else if (caller === "menu") {
            slot._component.forceUpdateOnParent();
          }

          // trigger the schedule status update
          window.LbsAppData.AppContext.fetchScheduleStatus();

          // send a message to felicity
          const slotAttrs = _.clone(slot.attributes);

          slotAttrs.boundTool = undefined;

          window.LbsAppData.Helpers.Socket.send("slot_remove", slotAttrs);
        },
        error: function () {
          window.LbsAppData.ToolBox._showFiddleDeadError();
          currentTool.onFinished();
        },
      });
    } else {
      window.LbsAppData.ToolBox._showParentSchedError();
    }
  },

  performAddSlotDemand: function (useDate, assignmentObj, callerComponent, callback) {
    // if there are multiple, non-expired, structures for this assignment...user has to pick which one

    if (assignmentObj.get("structures").length > 1) {
      window.LbsAppData.AppContext.openDialog({
        type: "structure",
        assignment: assignmentObj,
        structures: assignmentObj.get("structures"),
        callback: function (structureId) {
          // close the dialog
          window.LbsAppData.AppContext.closeDialog();
          // add the demand
          assignmentObj.addDemand(useDate, structureId);
          callerComponent.forceUpdate();

          if (callback) {
            callback();
          }
        },
      });
    } else {
      assignmentObj.addDemand(useDate);

      if (callback) {
        callback();
      }
    }
  },

  performDetailsChange: function (slot, caller, updateList, hasChanged) {
    // TEMPORARY LOGIC...same story...prevent mapped schedule changes for now
    // completely remove this check when it's allowed
    if (this._checkIsValidMap(slot.assignmentObj, slot.attributes.assign_structure_id, slot.attributes.slot_date)) {
      const that = this;

      if (!hasChanged) return;
      // check for timezone awareness and a difference in timezone between user and db
      // swap the original and current start /stop times (current being in the user's timezone)
      slot.prepAttributesForDBSave();

      // PUT changes to API
      slot.save(null, {
        success: function () {
          that.attributes.currentTool.trigger("detailsChange");

          if (caller === "select_tool") {
            updateList();

            that.attributes.currentTool.forceUpdateComponent();
          }

          // trigger the schedule status update
          window.LbsAppData.AppContext.fetchScheduleStatus();

          // send a message to felicity
          const slotAttrs = _.clone(slot.attributes);

          slotAttrs.boundTool = undefined;

          // Send updated slot information update across Felicity socket
          window.LbsAppData.Helpers.Socket.send("slot_update", slotAttrs);

          // Swap the original and current start / stop times back so the UI
          // and slot data remain consistent with timezones

          slot.updateTimezoneAttributesAfterDBSave();
        },
        error: function () {
          slot.updateTimezoneAttributesAfterDBSave();

          window.LbsAppData.ToolBox._showFiddleDeadError();
        },
      });
    } else {
      window.LbsAppData.ToolBox._showParentSchedError();
    }
  },

  // resolve the assignment structure to a single id
  _performPrepareStep1: function (slot) {
    const that = this;
    const date = this.attributes.currentTool.attributes.component.props.date;
    const dateFormatted = date.format("YYYYMMDD");

    if (window.LbsAppData.AppContext.get("GridSettings_leftColumnType") === "assignment") {
      const schedulableStructures = this._getAssignmentForEmptySlot().getSchedulableStructures(dateFormatted);

      if (schedulableStructures.length > 1) {
        // more than one choice, user has to decide
        window.LbsAppData.AppContext.openDialog({
          type: "structure",
          slot,
          assignment: this._getAssignmentForEmptySlot(),
          structures: schedulableStructures,
          callback: function (structureId) {
            // close the dialog
            window.LbsAppData.AppContext.closeDialog();
            // call the next thing in the chain
            if (that._performPrepareStep2.call(that, structureId)) {
              that.attributes.currentTool.onAttach();
            }
          },
        });
      } else if (schedulableStructures.length === 1) {
        // only one choice...hopefully this happens 99% of the time
        if (this._performPrepareStep2(schedulableStructures[0])) {
          this.attributes.currentTool.onAttach();
        }
      }
    } else {
      if (this._performPrepareStep2()) {
        this.attributes.currentTool.onAttach();
      }
    }
  },

  // fill the placeholder slot with some actual shit
  // if this returns false...it means there was an issue with resolving a mapped parent..and error dialog will be
  // displayed and all further execution should stop
  _performPrepareStep2: function (structureId) {
    const slot = this.attributes.currentTool.attributes.target;
    const assignment = this._getAssignmentForEmptySlot();
    const personnel = this._getPersonnelForEmptySlot();

    // (fill in the thing we know for sure...the thing in the row)
    if (window.LbsAppData.AppContext.get("GridSettings_leftColumnType") === "assignment") {
      const useDate = this.attributes.currentTool.attributes.component.props.date;

      // make sure the assignment is valid in terms of mapping
      if (this._checkIsValidMap(assignment, structureId, useDate)) {
        slot.replaceAssignment(assignment, structureId, useDate);

        return true;
      }

      window.LbsAppData.ToolBox._showParentSchedError();

      return false;
    }

    slot.replacePersonnel(personnel);

    return true;
  },

  // START STATICS ------------------------------------------------------------------------------
  //...helper functions, they need all relevant data passed in OR they need every accessible view the window

  _getAssignmentForEmptySlot: function () {
    const currentTool = window.LbsAppData.ToolBox.get("currentTool");

    if (window.LbsAppData.AppContext.get("GridSettings_leftColumnType") === "assignment") {
      // get the assignment based on the row
      return currentTool.attributes.component.props.rowKey;
    }

    // get the assignment based on the slot
    return currentTool.getAssignmentForEmptySlot();
  },

  _getPersonnelForEmptySlot: function () {
    const currentTool = window.LbsAppData.ToolBox.get("currentTool");

    if (window.LbsAppData.AppContext.get("GridSettings_leftColumnType") === "personnel") {
      // get the assignment based on the row
      return currentTool.attributes.component.props.rowKey;
    }

    // get the assignment based on the tool properties
    return currentTool.getPersonnelForEmptySlot();
  },

  _replacePersonnel: function (slot) {
    const currentTool = window.LbsAppData.ToolBox.get("currentTool");

    // fill the value on the slot
    slot.replacePersonnel(currentTool.attributes.value);

    slot.prepAttributesForDBSave();
    slot.save(null, {
      success: function () {
        // add the slot to the collection (if it isn't already)
        window.LbsAppData.Slots.add(slot);

        currentTool.onFinished();
        currentTool.trigger("replacePersonnel");

        // trigger the schedule status update
        window.LbsAppData.AppContext.fetchScheduleStatus();

        // send a message to felicity
        // encode the attributes but strip the boundTool (otherwise socket.io will throw up)
        const slotAttrs = _.clone(slot.attributes);

        slotAttrs.boundTool = undefined;
        window.LbsAppData.Helpers.Socket.send("slot_update", slotAttrs);

        slot.updateTimezoneAttributesAfterDBSave();
      },
      error: function () {
        slot.updateTimezoneAttributesAfterDBSave();
        window.LbsAppData.ToolBox._showFiddleDeadError();
        currentTool.onFinished();
      },
    });
  },

  _replaceAssignmentStep1: function (slot, forcedStructureId) {
    // forcedStructureId is used by the select tool in the situations where there are structure collisions on the same day
    // it can't push the select structure dialog like everything else can...cause the tool itself is an open dialog
    const currentTool = window.LbsAppData.ToolBox.get("currentTool");

    const date = currentTool.attributes.component.props.date;

    // need to see the structures of the NEW assignment..which if we are here we must assume it's stored as the value of the tool
    const schedulableStructures = currentTool.attributes.value.getSchedulableStructures(date.format("YYYYMMDD"));

    if (schedulableStructures.length > 1 && currentTool.attributes.type !== "select") {
      // more than one choice, user has to decide to continue...notice SELECT TOOL is ignored here...
      // that has its own way of dealing with this...basically doing it right in the dialog via a couple methods
      window.LbsAppData.AppContext.openDialog({
        type: "structure",
        slot,
        assignment: currentTool.attributes.value,
        structures: schedulableStructures,
        callback: function (structureId) {
          // close the dialog
          window.LbsAppData.AppContext.closeDialog();
          // call the next thing in the chain
          window.LbsAppData.ToolBox._replaceAssignmentStep2(slot, structureId, date);
        },
      });
    } else if (schedulableStructures.length === 0) {
      // zeroooo, have to pop up another dialog...due to restrictions we put on the alternates and select tools
      // by only showing assignments _with_ demand...this should only hit on the fill tool as it is right now
      if (currentTool.attributes.type === "fill" && this.showErrorAndBailIfMappedAssignment()) return;

      window.LbsAppData.AppContext.openDialog({
        type: "add-demand",
        assignment: currentTool.attributes.value,
        date,
        yes: function () {
          // close the dialog
          window.LbsAppData.AppContext.closeDialog();

          // now that they've decided to add demand on this day, call this whole thing again
          window.LbsAppData.ToolBox._replaceAssignmentStep1(slot);
        },
        no: function () {
          currentTool.onFinished();
        },
      });
    } else if (currentTool.attributes.type === "select" && forcedStructureId !== undefined) {
      // select tool, in the case where there were multiple structures to pick from...the tool did the work
      // just push it through to the next step
      window.LbsAppData.ToolBox._replaceAssignmentStep2(slot, forcedStructureId, date);
    } else {
      // only 1, hopefully the majority of the cases
      window.LbsAppData.ToolBox._replaceAssignmentStep2(slot, schedulableStructures[0], date);
    }
  },

  _replaceAssignmentStep2: function (slot, structureId, date) {
    const currentTool = window.LbsAppData.ToolBox.get("currentTool");

    // we need to check to make sure if this is mapped slot...that there is a published parent schedule for the date
    // they are trying to update...if not this is an invalid operation and we need to bail
    if (window.LbsAppData.ToolBox._checkIsValidMap(currentTool.attributes.value, structureId, date)) {
      slot.replaceAssignment(currentTool.attributes.value, structureId, date);

      // save the changes
      slot.prepAttributesForDBSave();

      slot.save(null, {
        success: function () {
          // add the slot to the collection (if it isn't already)
          window.LbsAppData.Slots.add(slot);

          currentTool.onFinished();
          currentTool.trigger("replaceAssignment");

          // trigger the schedule status update
          window.LbsAppData.AppContext.fetchScheduleStatus();

          // send a message to felicity
          // encode the attributes but strip the boundTool (otherwise socket.io will throw up)
          const slotAttrs = _.clone(slot.attributes);
          slotAttrs.boundTool = undefined;

          window.LbsAppData.Helpers.Socket.send("slot_update", slotAttrs);
          slot.updateTimezoneAttributesAfterDBSave();
        },
        error: function () {
          slot.updateTimezoneAttributesAfterDBSave();
          window.LbsAppData.ToolBox._showFiddleDeadError();
          currentTool.onFinished();
        },
      });
    } else {
      // invalid mapped assignment operation...show them an error dialog, finish the operation and error
      currentTool.onFinished();
      window.LbsAppData.ToolBox._showParentSchedError();
    }
  },

  _checkIsValidMap: function (assignment, structureId) {
    // this'll be called at the places we know the actual assignment/structure trying to be used
    // return true if there is a published parent schedule for mapped slot (or the slot isn't mapped at all)
    const templateId = assignment.attributes.structure_to_template_map[structureId];

    if (templateId === window.LbsAppData.AppContext.attributes.displayedSchedule.attributes.template_id) {
      // it's not mapped...so it's a valid map
      return true;
    }

    // FOR NOW...do not allow any mapped changes...until we allow published schedule changes in this app
    // cause all the mapped data IS published...so trying to avoid confusion.  when it does become okay to do this
    // just uncomment this publishedMap logic...and change the error message back to a data error message and not a general info message
    return false;
  },

  showErrorAndBailIfMappedAssignment: function () {
    // eslint-disable-next-line no-undef
    const currentTool = window.LbsAppData.ToolBox.get("currentTool");
    const isMappedAssignment = !!Object.keys(currentTool.attributes.value.attributes.structure_mapping_lookup).length;
    const assignmentName = currentTool.attributes.value.attributes.compact_name;

    if (isMappedAssignment) {
      // eslint-disable-next-line no-undef
      window.LbsAppData.AppContext.openDialog({
        type: "error",
        title: "Fill Error: Mapped Assignment",
        message: `${assignmentName} is a mapped assignment and cannot be used as a fill.`,
      });

      currentTool.onFinished();

      return true;
    }

    return false;
  },

  _showParentSchedError: function () {
    // mapping related error
    window.LbsAppData.AppContext.openDialog({
      type: "error",
      title: "Mapped Changes Not Yet Supported",
      message: NO_MAPPED_CHANGES,
    });
  },

  _showFiddleDeadError: function () {
    // this will get called on any of the error callbacks from the fiddledead changes
    window.LbsAppData.AppContext.openDialog({
      type: "error",
      title: "Network Error",
      message: FIDDLEDEAD_ERROR,
    });
  },

  _showSlotPendingError: function () {
    // slot in pending state error
    window.LbsAppData.AppContext.openDialog({
      type: "error",
      title: "Schedule slot has actions pending",
      message: SLOT_PENDING,
    });
  },

  // END STATICS -------------------------------------------------------------------
});

module.exports = ToolBox;
