const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");

var Personnel = Backbone.Model.extend({
  idAttribute: "emp_id",

  defaults: {
    boundTool: undefined,

    available: true,
    filtered: false,
    forced: false,
  },

  initialize: function () {
    // attach event listeners to this employee's department objects
    if (this.attributes.departments !== undefined) {
      for (var i = 0; i < this.attributes.departments.length; i++) {
        var dept = window.LbsAppData.Departments.get(this.attributes.departments[i]);

        if (dept) {
          this.listenTo(dept, "change:filtered", this.setAvailability);
        }
      }

      // default the order to display_name
      //this.attributes.order = this.attributes.display_name.toLowerCase();

      // hide expired setting would cause things to possibly become unavailable
      this.listenTo(window.LbsAppData.AppContext, "change:GlobalSettings_hideExpired", this.setAvailability);
    }
  },

  url: function () {
    return ApiConfig.getPrefix() + (this.id ? "/personnel/" + this.id : "/personnel");
  },

  setAvailability: function (eventObj) {
    var availability = this._checkAvailability();
    if (availability != this.attributes.available) {
      this.set({ available: availability });
    }
  },

  getEffects: function (forceColor) {
    if (this.attributes.boundTool) {
      // tool takes precedence
      return this.attributes.boundTool.style();
    } else {
      var ret = {};
      if (
        (forceColor && window.LbsAppData.AppContext.attributes.colorMethod == "cell") ||
        "mixed|row".indexOf(window.LbsAppData.AppContext.attributes.colorMethod) > -1
      ) {
        // view is set to color rows
        if (this.attributes.color) {
          ret["backgroundColor"] = this.attributes.color;
        }
      }

      if (window.LbsAppData.AppContext.attributes.colorTextMethod == "colored") {
        // view is set to color text
        if (this.attributes.colorText) {
          ret["color"] = this.attributes.colorText;
        }
      }

      return ret;
    }
  },

  _checkAvailability: function () {
    // if employee is not scheduled, then they are unavailable
    if (!this.attributes.scheduled) {
      return false;
    }

    // if employee is expired AND user is set to hide expired, then they are unavailable
    if (this.attributes.expired && window.LbsAppData.AppContext.attributes.GlobalSettings_hideExpired) {
      return false;
    }

    // check employee's departments to see if any are unfiltered
    for (var i = 0; i < this.attributes.departments.length; i++) {
      var dept = window.LbsAppData.Departments.get(this.attributes.departments[i]);
      if (dept && !dept.attributes.filtered) {
        // found one
        return true;
      }
    }

    return false;
  },
});

module.exports = Personnel;
