import { toDate } from "date-fns-tz";
import { SettingsContext } from "@/viewer/ui/modules/common/types/context";
import { BlockDay } from "@/common/components/fields/date-field/types";

interface LocationResponse {
  location_id: number;
  is_default: boolean;
  location_name: string;
}

interface AssignmentToLocationsMapResponse {
  [k: string]: {
    is_location_editable: boolean;
    is_location_multiple: boolean;
    locations: LocationResponse[];
  };
}

interface AssignmentToDenialReasonResponse {
  [k: string]: any;
}
interface AssignmentToLoaReasonResponse {
  [k: string]: any;
}
interface AssignmentToNoteMapResponse {
  [k: string]: any;
}
interface Location {
  locationId: number;
  isDefault: boolean;
  locationName: string;
}

interface AssignmentToLocationsMap {
  [k: string]: {
    isLocationEditable: boolean;
    isLocationMultiple: boolean;
    locations: Location[];
  };
}
interface AssignmentToDenialReasonMap {
  [k: string]: any;
}
interface AssignmentToLoaReasonMap {
  [k: string]: any;
}
interface AssignmentToNoteMap {
  [k: string]: any;
}

export interface AssignmentAttributes {
  filtered: boolean;
  assign_structure_id: string;
  assign_id: number;
  display_name: string | null;
  compact_name: string | null;
  description: string | null;
  departments: number[];
  templates: number[];
  structures: number[];
  structures_include_expired: number[];
  loa_reasons: number[];
  last_recurrence_date: string;
  tallies: number[];
  template_to_assign_id_map: Record<string, number>;
  template_to_assign_type_map: Record<string, number>;
  template_to_default_times_map: Record<string, string>; // { "19": "08:00:00~12:00:00" },
  structure_to_template_map: Record<string, number>;
  structure_to_desc_map: Record<string, string>;
  structure_to_category_map: Record<string, string>;
  structure_mapping_lookup: Record<string, unknown>;
  assignment_to_locations_map: AssignmentToLocationsMapResponse;
  assignment_to_denial_reason_map: AssignmentToDenialReasonResponse;
  assignment_to_loa_reason_map: AssignmentToLoaReasonResponse;
  assignment_to_note_map: AssignmentToNoteMapResponse;
  expired: boolean;
  locations: LocationResponse[];
  is_location_multiple: boolean;
  is_location_editable: boolean;
  order: number;
  color: string | null;
  colorText: string | null;
  is_block: boolean;
  block_start_day: number;
  block_duration: number;
  is_vrm?: boolean;
  call_order: number | null;
}

// Cleaned up data model
export interface Assignment {
  assignStructureId: string;
  compactOrDisplayName: string;
  description: string;
  expired: boolean;
  lastRecurrenceDate: Date | null;
  templates: number[];
  color: string | null;
  templateToDefaultTimesMap: Record<string, string>;
  templateToAssignIdMap: Record<string, number>;
  templateToAssignTypeMap: Record<string, number>;
  structureToCategoryMap: Record<string, string>;
  structureMappingLookup: Record<string, unknown>;
  assignmentToLocationsMap: AssignmentToLocationsMap;
  assignmentToDenialReasonMap: AssignmentToDenialReasonMap;
  assignmentToLoaReasonMap: AssignmentToLoaReasonMap;
  assignmentToNoteMap: AssignmentToNoteMap;
  structures: number[];
  colorText: string | null;
  order: null | number;
  loaReasons: number[];
  locations: Location[];
  isBlock: boolean;
  blockStartDay: BlockDay;
  blockDuration: null | number;
  isVrm: boolean;
  departments: number[];
  callOrder: number | null;
  isMapped: boolean;
}

const cleanLocationsAttributes = (locations: LocationResponse[]) => {
  return locations.map((l) => ({
    locationId: l.location_id,
    locationName: l.location_name,
    isDefault: l.is_default,
  }));
};

const cleanAssignmentToLocationsMapAttributes = (
  assignmentToLocationsMapResponse: AssignmentToLocationsMapResponse
): AssignmentToLocationsMap => {
  return Object.entries(assignmentToLocationsMapResponse).reduce<AssignmentToLocationsMap>((acc, [key, value]) => {
    return {
      ...acc,
      [key]: {
        isLocationEditable: value.is_location_editable,
        isLocationMultiple: value.is_location_multiple,
        locations: cleanLocationsAttributes(value.locations),
      },
    };
  }, {});
};

export const cleanAssignmentAttributes = (
  settings: SettingsContext,
  attrs: Partial<AssignmentAttributes>
): Assignment => {
  return {
    assignStructureId: attrs.assign_structure_id ?? "",
    compactOrDisplayName: (settings.isCompact ? attrs.compact_name : attrs.display_name) ?? attrs.display_name ?? "",
    description: attrs.description ?? "",
    expired: attrs.expired ?? false,
    lastRecurrenceDate: attrs.last_recurrence_date ? toDate(attrs.last_recurrence_date) : null,
    templates: attrs.templates ?? [],
    templateToDefaultTimesMap: attrs.template_to_default_times_map ?? {},
    templateToAssignIdMap: attrs.template_to_assign_id_map ?? {},
    templateToAssignTypeMap: attrs.template_to_assign_type_map ?? {},
    structureToCategoryMap: attrs.structure_to_category_map ?? {},
    structureMappingLookup: attrs.structure_mapping_lookup ?? {},
    assignmentToLocationsMap: cleanAssignmentToLocationsMapAttributes(attrs.assignment_to_locations_map ?? {}),
    assignmentToDenialReasonMap: attrs.assignment_to_denial_reason_map ?? {},
    assignmentToLoaReasonMap: attrs.assignment_to_loa_reason_map ?? {},
    assignmentToNoteMap: attrs.assignment_to_note_map ?? {},
    structures: attrs.structures ?? [],
    color: attrs.color ?? "",
    colorText: attrs.colorText ?? "",
    order: attrs.order ?? null,
    loaReasons: attrs.loa_reasons ?? [],
    locations: cleanLocationsAttributes(attrs.locations ?? []),
    isBlock: attrs.is_block ?? false,
    blockStartDay: attrs.block_start_day as BlockDay,
    blockDuration: attrs.block_duration ?? null,
    isVrm: attrs.is_vrm ?? false,
    departments: attrs.departments ?? [],
    callOrder: attrs.call_order ?? null,
    isMapped: Object.keys(attrs.structure_mapping_lookup ?? {}).length > 0,
  };
};

export interface VrmAssignmentAttributes {
  EmployeeId: number;
  BlockStartDay: number;
  BlockDuration: number;
  RequestWindowStart: string;
  RequestWindowEnd: string;
  PickWindowStart: string;
  PickWindowEnd: string;
  Granted: number;
  Allotted: number;
}

// Cleaned up VRM Assignment model
export interface VrmAssignment {
  employeeId: number | null;
  blockStartDay: BlockDay | null;
  blockDuration: number | null;
  requestWindowStart: Date;
  requestWindowEnd: Date;
  pickWindowStart: Date;
  pickWindowEnd: Date;
  granted: number | null;
  allotted: number | null;
}

export const cleanVrmAssignmentAttributes = (attrs: Partial<VrmAssignmentAttributes>): VrmAssignment => {
  return {
    employeeId: attrs.EmployeeId ?? null,
    blockStartDay: (attrs.BlockStartDay as BlockDay) ?? null,
    blockDuration: attrs.BlockDuration ?? null,
    requestWindowStart: toDate(attrs.RequestWindowStart),
    requestWindowEnd: toDate(attrs.RequestWindowEnd),
    pickWindowStart: toDate(attrs.PickWindowStart),
    pickWindowEnd: toDate(attrs.PickWindowEnd),
    granted: attrs.Granted ?? null,
    allotted: attrs.Allotted ?? null,
  };
};

export type AssignmentsById = Record<string, Assignment>;
