import { ReportData } from "@/viewer/types/domain/report";
import { FilterMap } from "@/viewer/ui/modules/common/types/context";

/***
 *
 * @param tallyId
 * @param cellKey: this is a date yyyyMMdd, key for the cell day
 * @param reportData: this is the dump of report data (data_by_day, data_by_day_and_emp etc.)
 * @param filteredPersonnel: this is all the non-visible personnel
 *
 * The tally data come back on a _dump object which includes data_by_day_and_emp. It is formatter like this:
 * data_by_day_and_emp: [
 *   {
 *     counted_slot_uuids: {},
 *     date: "yyyyMMdd",
 *     emp_tally_values: {
 *       empID: {
 *         tallyID: COUNT,
 *         tallyID2: COUNT,
 *       },
 *       empID2: {
 *         tallyID: COUNT
 *         tallyID15: COUNT
 *       }
 *     }
 *   },
 *   {
 *     counted_slot_uuids: {},
 *     date: "yyyyMMdd",
 *     emp_tally_values: {
 *       empID: {
 *         tallyID: COUNT,
 *         tallyID2: COUNT,
 *       },
 *       empID2: {
 *         tallyID: COUNT
 *         tallyID15: COUNT
 *       }
 *     }
 *   }
 * ]
 *
 * This goes through all the empIDs in emp_tally_values, makes sure they are visible, checks if they should be counted
 * for this tallyId (the row) and then adds it all up.
 *
 * Have Fun!
 */
export const getTallyDataForDayAndEmp = (
  tallyId: string,
  cellKey: string,
  reportData: ReportData,
  filteredPersonnel: FilterMap
): number | undefined => {
  const reportDataByEmpForCell = reportData.data_by_day_and_emp[cellKey];
  if (!reportDataByEmpForCell) {
    return undefined;
  }
  const tallyCount = Object.entries(reportDataByEmpForCell["emp_tally_values"])
    .filter((v) => !filteredPersonnel.hasOwnProperty(v[0]))
    .map((v) => v[1][tallyId])
    .reduce((acc, c) => {
      if (c !== undefined) {
        return acc + c;
      }
      return acc;
    }, 0);
  // if it's undefined, it shows a dash, need to keep this and not show a 0
  return tallyCount ? tallyCount : undefined;
};
