export type SecureMessagingClient =
  | "voalte"
  | "telmediq"
  | "perfectserve"
  | "cortext"
  | "vocera"
  | "epic"
  | "vina"
  | "";

export interface UserAttributes {
  customer_id: number;
  customer_name: string;
  database_type: "sqlserver" | "postgres" | string;
  email: string | null;
  emp_id: number;
  feature_swops: boolean;
  is_admin: boolean;
  is_super_admin: boolean;
  locale: "en" | string;
  parameters: Record<string, boolean>;
  show_nsight_views: unknown | null;
  sm_client: SecureMessagingClient;
  sm_domain: string;
  time_display: string;
  tz: string;
  user_id: number;
  user_name: string;
}

export interface User {
  id: number | "";
  empId: number | "";
  customerId: number | "";
  userName: string;
  email: string;
  customerName: string;
  secureMessagingClient: SecureMessagingClient;
  secureMessagingDomain: string;

  databaseType: "sqlserver" | "postgres" | string;
  timeDisplay: string;
  timeZone: string;
  locale: "en" | string;
  parameters: Record<string, boolean>;

  featureSwops: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  showNsightViews: boolean; // I suspect this to be a boolean given the name, but with LB who knows.
}

export const cleanUserAttributes = (attrs: Partial<UserAttributes>): User => {
  return {
    id: attrs.user_id ?? "",
    userName: attrs.user_name ?? "",
    empId: attrs.emp_id ?? "",
    email: attrs.email ?? "",
    customerId: attrs.customer_id ?? "",
    customerName: attrs.customer_name ?? "",
    secureMessagingClient: attrs.sm_client ?? "",
    secureMessagingDomain: attrs.sm_domain ?? "",
    databaseType: attrs.database_type ?? "",
    timeDisplay: attrs.time_display ?? "",
    timeZone: attrs.tz ?? "",
    locale: attrs.locale ?? "",
    parameters: attrs.parameters ?? {},
    featureSwops: attrs.feature_swops ?? false,
    isAdmin: attrs.is_admin ?? false,
    isSuperAdmin: attrs.is_super_admin ?? false,
    showNsightViews: !!attrs.show_nsight_views ?? false,
  };
};
