export interface TallyAttributes {
  Id: number;
  Name: string;
  TallyOverrideId: string;
  TemplateName: string;
  Value: number;
}

export interface Tally {
  id: number;
  name: string;
  tallyOverrideId: string;
  templateName: string;
  value: number;
  initialValue?: Tally["value"];
}

export const cleanTallyAttributes = (attrs: TallyAttributes): Tally => {
  return {
    id: attrs.Id,
    name: attrs.Name,
    tallyOverrideId: attrs.TallyOverrideId,
    templateName: attrs.TemplateName,
    value: parseFloat(attrs.Value.toFixed(4)),
  };
};
