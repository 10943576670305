/**
 * Wrap common useFilteredRequestData to return the expected format
 */
import { appendToPath3 } from "@/viewer/utils/helpers";
import { RequestsByDateByNameByWeek, SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { DataContext, FilterContext, SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import useFilteredRequestData from "@/viewer/ui/modules/common/hooks/useFilteredRequestData";
import { useMemo } from "react";
import { getWeekKey } from "@/viewer/ui/modules/common/helpers/dates";
import { getLeftColumnId } from "@/viewer/utils/domain/slotquests";
import { Collection } from "backbone";

export default (
  settings: SettingsContext,
  ui: UIContext,
  filters: FilterContext,
  data: DataContext,
  requestCollection: Collection,
  dateRange: Date[]
): RequestsByDateByNameByWeek => {
  const requests = useFilteredRequestData(settings, ui, filters, data, requestCollection);

  return useMemo(() => {
    const out: RequestsByDateByNameByWeek = {};

    const appendRequestDataToOutput = (request: SlotOrRequest) => {
      const weekKey: string = getWeekKey(settings, ui, request.date);
      const columnId = getLeftColumnId(ui, request);
      const requestDate = request.dateString;

      appendToPath3(out, [weekKey, columnId, requestDate], request);
    };

    requests.forEach((request) => {
      const [startDate, stopDate] = dateRange;

      if (request.date >= startDate && request.date <= stopDate) {
        appendRequestDataToOutput(request);
      }
    });
    return out;
  }, [requests, settings]);
};
