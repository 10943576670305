const Backbone = require("backbone");
const { UpdateAssignmentFilterIds } = require("../../../_lib/ui/modules/topbar/actions");
const store = require("../../../store").default;
const ApiConfig = require("_lib/data/ApiConfig.js");
const Assignment = require("./../models/Assignment.js");
const _ = require("lodash");

const AssignmentCollection = Backbone.Collection.extend({
  model: Assignment,

  initialize: function (models, attributes) {
    this.attributes = {};

    for (const key in attributes) {
      this.attributes[key] = attributes[key];
    }

    this.attributes.title = "Assignments";
  },

  url: function () {
    const queryParams = {};

    if (this?.attributes?.tempList?.length) {
      queryParams["template_list"] = this.attributes.tempList.join("~");
    }

    if (this?.attributes?.assignList?.length) {
      queryParams["assign_list"] = this.attributes.assignList.join("~");
    }

    if (this.attributes.empID) {
      queryParams["emp_id"] = this.attributes.empID;
    }

    if (this.attributes.demandDate) {
      queryParams["demand_date"] = this.attributes.demandDate;
    }

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    return (
      ApiConfig.getPrefix() +
      "/assignments/condensed/?" +
      _.map(cleanQueryParams, function (value, key) {
        return key + "=" + value;
      }).join("&")
    );
  },

  _createDummyRequestsModel: function () {
    this.add(
      new Assignment({
        assign_structure_id: "requests_dummy",
        compact_name: "Req[Pend]",
        display_name: "[PENDING REQUESTS]",
        templates: [],
        expired: false,
      })
    );
  },

  getDummyRequestsModel: function () {
    return this.get("requests_dummy");
  },

  toggleFilteredModels: function (ids, value) {
    for (let i = 0; i < ids.length; i++) {
      const model = this.get(ids[i]);

      if (value === undefined) {
        // toggle it
        model.set({ filtered: !model.attributes.filtered });
        this.trigger("change:filteredModel");
      } else {
        // set it explicitly (if it needs to be)
        if (model.attributes.filtered !== value) {
          model.set({ filtered: value });
          // trigger an event
          this.trigger("change:filteredModel");
        }
      }
    }
  },

  filterAll: function () {
    this.forEach(function (item) {
      item.set({ filtered: true });
    });

    // trigger an event
    this.trigger("change:filteredModel");
  },
  unfilterAll: function () {
    this.forEach(function (item) {
      item.set({ filtered: false });
    });

    // trigger an event
    this.trigger("change:filteredModel");
  },

  resetOrdering: function () {
    this.forEach(function (item) {
      item.set({ order: item.attributes.display_name });
    });
  },

  anyRequestableAssignments: function (templateID) {
    return this.some(function (item) {
      if (item.attributes.templates.indexOf(templateID) > -1 && item._isAssignmentRequestableOnTemplate(templateID)) {
        return true;
      } else {
        return false;
      }
    });
  },

  filterAssignments: function (ids) {
    if (ids.length > 0) {
      this.forEach(function (item) {
        item.set({ filtered: !ids.includes(item.id) });
      });
    } else {
      this.forEach(function (item) {
        item.set({ filtered: false });
      });
    }
    store.dispatch(UpdateAssignmentFilterIds(ids));
    this.trigger("change:filteredModel");
  },
});

module.exports = AssignmentCollection;
