type ClassNamesPart = string | { [k: string]: any };

const classnames = (...args: ClassNamesPart[]): string => {
  return <string>args
    .reduce((cls, comp) => {
      if (typeof comp === "string") {
        return cls + " " + comp;
      } else {
        return (
          cls +
          " " +
          Object.keys(comp)
            .filter((k) => comp[k])
            .join(" ")
        );
      }
    }, "")
    .trim();
};

export default classnames;
