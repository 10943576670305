var React = require("react");
var moment = require("moment");
var _ = require("underscore");
var Promise = require("bluebird");

var Router = require("./Router.js");
var AppContext = require("./models/AppContext.js");

// model dependencies
var DepartmentCollection = require("_lib/data/collections/NakedDepartmentCollection.js");
var TemplateCollection = require("_lib/data/collections/NakedTemplateCollection.js");
var PersonnelCollection = require("_lib/data/collections/NakedPersonnelCollection.js");

var Permissions = require("_lib/data/models/Permissions.js");
var User = require("./models/User.js");
var Personnel = require("./models/Personnel.js");
var Destinations = require("_lib/data/models/Destinations.js");
var AccessibleApps = require("./models/AccessibleApps.js");

// managers
var AnalyticsHelper = require("_lib/helpers/AnalyticsHelper.js");
var ErrorsHelper = require("_lib/helpers/ErrorsHelper.js");
var FeaturesHelper = require("_lib/helpers/FeaturesHelper.js");
var PermissionsHelper = require("_lib/helpers/PermissionsHelper.js");
var SwapsHelper = require("_lib/helpers/SwapsHelper.js");
var TimeHelper = require("_lib/helpers/TimeHelper.js");
const SafeJSON = require("../../common/utils/jsonParseShield");
const { getLDUser } = require("@/_lib/utils/launchDarklyHelper");
const { LaunchDarklyClient } = require("@/_lib/utils/launchDarklyClient");

var LbsAppData = (payload) => ({
  // app identifier
  id: "login",

  MILITARY: "military",

  // global models
  AppContext: new AppContext(),
  Router: undefined,
  //LoaderChain: require('./LoadChain.js'),

  // managers
  Helpers: {
    Analytics: new AnalyticsHelper(),
    Errors: new ErrorsHelper(),
    Features: new FeaturesHelper(),
    Permissions: undefined,
    Time: new TimeHelper(),
  },

  // models
  Departments: undefined,
  Templates: new TemplateCollection(), // models added via the department initializer

  Permissions: undefined,
  User: undefined,
  Personnel: undefined,
  MyPersonnel: undefined,
  Destinations: undefined,
  AccessibleApps: undefined,

  init: function (callback) {
    this.User = new User(SafeJSON.parse(payload.user));
    // sync backbone and refresh the token
    window.LbsAppData.AppContext._syncBackbone();

    this.Permissions = new Permissions(SafeJSON.parse(payload.permissions));
    this.Departments = new DepartmentCollection(SafeJSON.parse(payload.departments));
    this.Personnel = new PersonnelCollection(SafeJSON.parse(payload.personnel));
    this.Destinations = new Destinations(SafeJSON.parse(payload.destinations));
    this.AccessibleApps = new AccessibleApps(SafeJSON.parse(payload.applications));

    // important this happens after departments...the personnel models got some init code
    this.MyPersonnel = new Personnel(SafeJSON.parse(payload.user_personnel));

    const ldUserConfig = getLDUser(window.LbsAppData.User.attributes);
    this.launchDarklyClient = new LaunchDarklyClient(ldUserConfig);

    // create the permissions helper
    this.Helpers.Permissions = new PermissionsHelper();

    this.Helpers.Swaps = new SwapsHelper();

    // push the contexts (just for visual on who you are basically in this app)
    this.AppContext.pushContext({
      tag: "customerName",
      text: this.User.attributes.customer_name,
      tooltip: "Your Organization",
      action: undefined,
    });

    var pTip = (
      <span>
        Your Name. Not you? <a href="/logout">Sign out.</a>
      </span>
    );
    this.AppContext.pushContext({
      tag: "userName",
      text: this.MyPersonnel.attributes.first_name + " " + this.MyPersonnel.attributes.last_name,
      tooltip: pTip,
      action: undefined,
    });
  },
  fetchData: function (id, collection) {
    var that = this;
    // fetch and attach additional data to this object
    return new Promise(function (resolve, reject) {
      collection.fetch({
        success: function () {
          that[id] = collection;
          resolve();
        },
      });
    });
  },

  attachRouter: function () {
    this.Router = new Router();

    // apply any current route
    Backbone.history.start();
  },
});

module.exports = LbsAppData;
