import { AppDispatch, Thunk } from "@/modules/types";
import { request } from "@/_lib/utils/fetch";
import { stringifyQuery } from "@/_lib/utils/queryString";

export type Action = { type: "login/login-action"; message: string };

export const login = (
  username: string,
  password: string,
  origin?: string,
  originHash?: string,
  originQuery?: string
): Thunk => {
  return async (dispatch: AppDispatch) => {
    const data = { login_name: username, login_pwd: password };
    const query: { [k: string]: string } = {};
    if (origin) {
      query.origin = origin;
    }

    if (originHash) {
      query.origin_hash = originHash;
    }

    if (originQuery) {
      query.origin_query = originQuery;
    }

    const queryString = stringifyQuery(query);

    const resp = await request(`/api/v1/login?${queryString}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    });

    if (resp.status === 401) {
      const r = await resp.json();
      (window as any).payload = r;
      dispatch({ type: "login/login-action", message: r.message });
    } else if (resp.status === 200) {
      const r = await resp.json();
      if (r.url) {
        window.location.assign(r.url);
      } else {
        window.location.assign("/dashboard");
      }
    }
  };
};
