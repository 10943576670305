const Backbone = require("backbone");
var _ = require("underscore");
var ApiConfig = require("_lib/data/ApiConfig.js");
const SafeJSON = require("../../../common/utils/jsonParseShield");

var UserSettings = Backbone.Model.extend({
  // User model..but we get most (all) of this data from the permissions endpoint
  idAttribute: "settings_id",
  urlRoot: ApiConfig.getPrefix() + "/user/settings/viewer",

  parse: function (response) {
    // parse out the 'data' field as json
    if (response != null) {
      response.settings = SafeJSON.parse(response.settings);
    }

    return response;
  },

  toJSON: function (options) {
    var attributes = _.clone(this.attributes);
    var defaultView = attributes.settings ? attributes.settings.default_view : undefined;
    var defaultTemplate = attributes.settings ? attributes.settings.default_template : undefined;
    attributes.settings = JSON.stringify({
      default_view: attributes.default_view || defaultView,
      default_template: attributes.default_template || defaultTemplate,
    });

    delete attributes.default_view;
    delete attributes.default_template;
    return attributes;
  },
});

module.exports = UserSettings;
