import React from "react";
import useOnClickOutside from "@/_lib/utils/useOnClickOutside";
import {
  DisplayDepartmentNames,
  SettingsContext,
  UIContext,
  ViewDataType,
} from "@/viewer/ui/modules/common/types/context";
import { CallbackContext } from "@/viewer/ui/modules/common/helpers/callbacks";
import classnames from "@/_lib/utils/classnames";
import { useFlags } from "launchdarkly-react-client-sdk";

interface Props {
  settings: SettingsContext;
  ui: UIContext;
  setUIContext: (newUI: Partial<UIContext>) => void;
}

const AdHocSettings: React.FunctionComponent<Props> = ({ ui, setUIContext, settings }: Props) => {
  const dropDownMenu = React.useRef<HTMLDivElement>(null);
  const { layout, hideWeekends, viewDataType } = settings;
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const {
    dateMode,
    displayDepartmentNames,
    leftColumnType,
    showTimes,
    startWeekOnDay,
    showIcons,
    showOriginalPersonnel,
    showOnlyPending,
  } = ui;
  const { openDatesChangeLoadingDialog } = React.useContext(CallbackContext);

  const isStandardView = layout === "hybrid"; // hybrid is standard
  const isCalendarView = layout === "calendar";
  const isColumnsView = layout === "grid"; // grid is columns
  const isBlockView = layout === "block";
  const isGanttView = layout === "gantt";
  const isListView = layout === "list";

  // Checks to show/hide actions
  const showStartDayAction = Boolean(isStandardView || isCalendarView);
  const showBottomActions = Boolean(isColumnsView || isStandardView || isCalendarView || isListView);
  const showOnlyPendingAction = Boolean(!isBlockView && !isGanttView);
  const showOriginalPersonnelAction = Boolean(!isListView && viewDataType !== ViewDataType.request);
  const showDepartmentNamesAction = Boolean(isStandardView || isColumnsView || isCalendarView);

  const showOnlyPendingClassNames = classnames("checkbox-btn", {
    "mb-0": isListView,
  });

  useOnClickOutside(dropDownMenu, () => setShowMenu(false));

  const toggleMenu = async () => {
    setShowMenu(!showMenu);
  };

  const setLeftColumnType = (updatedLeftColumnType: "assignment" | "personnel") => {
    setUIContext({
      leftColumnType: updatedLeftColumnType,
    });
  };

  const setDateMode = (updatedDateMode: "weekly" | "monthly" | "yearly" | "range" | "static" | "anchored") => {
    setUIContext({
      dateMode: updatedDateMode,
    });
    /*
     *  Triggering the openDatesChangeLoadingDialog will refetch the data based on the new date
     *  range.
     */
    openDatesChangeLoadingDialog();
  };

  const setStartWeekOnDay = (updatedStartDay: "Sat" | "Sun" | "Mon") => {
    setUIContext({
      startWeekOnDay: updatedStartDay,
    });
  };

  const setDisplayDepartmentNames = (updatedDisplayDepartmentNames: UIContext["displayDepartmentNames"]) => {
    setUIContext({
      displayDepartmentNames: updatedDisplayDepartmentNames,
    });
  };

  const setShowTimes = (updatedShowTimes: boolean) => {
    setUIContext({
      showTimes: updatedShowTimes,
    });
  };

  const setShowIcons = (updatedShowIcons: boolean) => {
    setUIContext({
      showIcons: updatedShowIcons,
    });
  };

  const setShowOriginalPersonnel = (updatedShowOriginalPersonnel: boolean) => {
    setUIContext({
      showOriginalPersonnel: updatedShowOriginalPersonnel,
    });
  };

  const setShowOnlyPending = (updatedShowOnlyPending: boolean) => {
    setUIContext({
      showOnlyPending: updatedShowOnlyPending,
    });
  };

  const renderDisplayRange = () => {
    return (
      <>
        <div className="option-divider" />

        <div className="option-label">Display Range</div>
        <div className="flex">
          <label className="radio-btn mr-20">
            <input type="radio" checked={dateMode === "monthly"} onChange={() => setDateMode("monthly")} />
            <span className="checkmark" />
            Monthly
          </label>

          <label className="radio-btn">
            <input type="radio" checked={dateMode === "weekly"} onChange={() => setDateMode("weekly")} />
            <span className="checkmark" />
            Weekly
          </label>
        </div>
      </>
    );
  };

  const renderStartDay = () => {
    return (
      <>
        <div className="option-divider" />

        <div className="option-label">Start Day</div>
        <div className="flex">
          <label className={`radio-btn mr-20 ${hideWeekends ? "disabled" : ""}`}>
            <input
              type="radio"
              disabled={hideWeekends}
              checked={startWeekOnDay === "Sat"}
              onChange={() => setStartWeekOnDay("Sat")}
            />
            <span className="checkmark" />
            Saturday
          </label>

          <label className={`radio-btn mr-20 ${hideWeekends ? "disabled" : ""}`}>
            <input
              type="radio"
              disabled={hideWeekends}
              checked={startWeekOnDay === "Sun"}
              onChange={() => setStartWeekOnDay("Sun")}
            />
            <span className="checkmark" />
            Sunday
          </label>

          <label className="radio-btn">
            <input type="radio" checked={startWeekOnDay === "Mon"} onChange={() => setStartWeekOnDay("Mon")} />
            <span className="checkmark" />
            Monday
          </label>
        </div>
      </>
    );
  };

  const renderDisplayDepartmentNames = () => {
    return (
      <>
        <div className="option-divider" />

        <div className="option-label">Display Department Name</div>
        <div className="flex mb-30">
          <label className={`radio-btn mr-20`}>
            <input
              type="radio"
              checked={!displayDepartmentNames || displayDepartmentNames === DisplayDepartmentNames.NONE}
              onChange={() => setDisplayDepartmentNames(DisplayDepartmentNames.NONE)}
            />
            <span className="checkmark" />
            Not Visible
          </label>

          <label className={`radio-btn mr-20`}>
            <input
              type="radio"
              checked={displayDepartmentNames === DisplayDepartmentNames.DEPARTMENT_NAME}
              onChange={() => setDisplayDepartmentNames(DisplayDepartmentNames.DEPARTMENT_NAME)}
            />
            <span className="checkmark" />
            Dept. Name
          </label>

          <label className="radio-btn">
            <input
              type="radio"
              checked={displayDepartmentNames === DisplayDepartmentNames.DEPARTMENT_COMPACT_NAME}
              onChange={() => setDisplayDepartmentNames(DisplayDepartmentNames.DEPARTMENT_COMPACT_NAME)}
            />
            <span className="checkmark" />
            Compt. Name
          </label>
        </div>
      </>
    );
  };

  const renderShowTimes = () => {
    return (
      <>
        <div className="checkbox-btn mb-10">
          <input type="checkbox" id="show_times" checked={showTimes} onChange={() => setShowTimes(!showTimes)} />
          <label htmlFor="show_times">Show Times</label>
        </div>
      </>
    );
  };

  const renderShowIcons = () => {
    return (
      <div className="checkbox-btn">
        <input type="checkbox" id="show_icons" checked={showIcons} onChange={() => setShowIcons(!showIcons)} />
        <label htmlFor="show_icons">Show Request Icons</label>
      </div>
    );
  };

  const renderShowOriginalPersonnel = () => {
    return (
      <div className="checkbox-btn mb-10">
        <input
          type="checkbox"
          id="show_original_personnel"
          checked={showOriginalPersonnel}
          onChange={() => setShowOriginalPersonnel(!showOriginalPersonnel)}
        />
        <label htmlFor="show_original_personnel">Show Original Personnel</label>
      </div>
    );
  };

  const renderShowOnlyPending = () => {
    return (
      <div className={showOnlyPendingClassNames}>
        <input
          type="checkbox"
          id="show_only_pending"
          checked={showOnlyPending}
          onChange={() => setShowOnlyPending(!showOnlyPending)}
        />
        <label htmlFor="show_only_pending">Show Only Pending</label>
      </div>
    );
  };

  const renderShowBottomActions = () => {
    return (
      <>
        {!isListView && <div className="option-divider" />}
        <div className="cal-stan-col-opts-container">
          {showOnlyPendingAction && renderShowOnlyPending()}
          {!isListView && renderShowTimes()}
          {showOriginalPersonnelAction && renderShowOriginalPersonnel()}
          {!isListView && renderShowIcons()}
        </div>
      </>
    );
  };

  return (
    <div className="top-bar-btn dropdown" ref={dropDownMenu}>
      <div onClick={() => toggleMenu()} onTouchEnd={() => toggleMenu()} className={"flex-display-center pointer"}>
        <i className={"fa fa-cog top-bar-icon" + (showMenu ? " selected" : "")} />
      </div>
      <div className={"menu " + (showMenu ? "open auto" : "")}>
        <div className="scrollable flex-1 fit-content">
          {!isListView && (
            <>
              <div className="option-label">Group By</div>
              <div className="flex">
                <label className="radio-btn mr-20">
                  <input
                    type="radio"
                    checked={leftColumnType === "assignment"}
                    onChange={() => setLeftColumnType("assignment")}
                  />
                  <span className="checkmark" />
                  Assignment
                </label>
                <label className="radio-btn">
                  <input
                    type="radio"
                    checked={leftColumnType === "personnel"}
                    onChange={() => setLeftColumnType("personnel")}
                  />
                  <span className="checkmark" />
                  Personnel
                </label>
              </div>
            </>
          )}

          {isStandardView && renderDisplayRange()}

          {showDepartmentNamesAction && renderDisplayDepartmentNames()}

          {showStartDayAction && renderStartDay()}

          {showBottomActions && renderShowBottomActions()}
        </div>
      </div>
    </div>
  );
};

export default AdHocSettings;
