import React from "react";
import {
  DataContext,
  SettingsContext,
  ToolContext,
  UIContext,
  ViewDataType,
} from "@/viewer/ui/modules/common/types/context";
import CalendarSlot from "@/viewer/ui/modules/grid/calendar/components/CalendarSlot";
import { getSlotQuestUUID } from "@/viewer/utils/domain/slotquests";
import { Slot } from "@/viewer/types/domain/slot";
import { Request } from "@/viewer/types/domain/request";
import classnames from "@/_lib/utils/classnames";
import useDateHeaderCallbacks from "@/viewer/ui/modules/common/hooks/useDateHeaderCallbacks";
import CalendarDateBoxGroup from "@/viewer/ui/modules/grid/calendar/components/CalendarDateBoxGroup";
import { formatCalendarDay, formatDayKey } from "@/viewer/utils/dateFormatters";
import { CallbackContext } from "@/viewer/ui/modules/common/helpers/callbacks";
import { isSameDay, isToday, isWeekend } from "date-fns";
import { OriginalPersonnelMap } from "@/viewer/types/viewdata";
import { DepartmentsById } from "@/viewer/types/domain/department";

interface Props {
  settings: SettingsContext;
  ui: UIContext;
  data: DataContext;
  tools: ToolContext;
  isHoliday: boolean;
  focusedStartDate: Date;
  date: Date;
  dateQueryParam: string;
  myEmpId: number;
  scheduled: Slot[];
  requested: Request[];
  originalPersonnelMap: OriginalPersonnelMap;
  departmentsById: DepartmentsById;
}

const CalendarDateBox = (props: Props): JSX.Element => {
  const {
    settings,
    ui,
    data,
    tools,
    scheduled,
    requested,
    myEmpId,
    focusedStartDate,
    date,
    dateQueryParam,
    isHoliday,
    originalPersonnelMap,
    departmentsById,
  } = props;
  const { expandFocusedSlot } = React.useContext(CallbackContext);
  const { viewDataType } = settings;

  React.useEffect(() => {
    const slotQuests = [...scheduled, ...requested];

    if (settings.viewId === "me" && formatDayKey(date) === dateQueryParam && slotQuests.length === 1) {
      const slotQuestUUID = getSlotQuestUUID(slotQuests[0]);

      // TODO: This should not be using a `setTimeout` once we refactor the dialog logic.
      setTimeout(() => expandFocusedSlot(slotQuestUUID), 100);
    }
  }, []);

  const slots: JSX.Element[] = [];
  if (scheduled.length && viewDataType !== ViewDataType.request) {
    scheduled.forEach((slot, index) => {
      if (settings.viewId !== "me" || slot.empId === myEmpId || slot.pendingEmpId === myEmpId) {
        slots.push(
          <CalendarSlot
            key={getSlotQuestUUID(slot)}
            settings={settings}
            ui={ui}
            data={data}
            tools={tools}
            slot={slot}
            myEmpId={myEmpId}
            isDataGroup={false}
            cellType={index % 2}
            originalPersonnelMap={originalPersonnelMap}
            departmentsById={departmentsById}
          />
        );
      }
    });
  }

  const groups: JSX.Element[] = [];

  if (requested.length && viewDataType !== ViewDataType.schedule) {
    groups.push(
      <CalendarDateBoxGroup
        key="requests"
        settings={settings}
        ui={ui}
        data={data}
        tools={tools}
        myEmpId={myEmpId}
        slotData={requested}
        originalPersonnelMap={originalPersonnelMap}
        departmentsById={departmentsById}
      />
    );
  }

  const classes = classnames("DateBox", {
    weekend: isWeekend(date),
    today: isToday(date),
    empty: slots.length === 0,
    "not-visible-day": settings.range === "day" && isSameDay(date, focusedStartDate),
    holiday: isHoliday,
  });

  return (
    <div className={classes}>
      <div className="header" {...useDateHeaderCallbacks(settings, date)}>
        {formatCalendarDay(settings, date)}
      </div>
      <div className="slots">{slots}</div>
      {groups}
    </div>
  );
};

export default CalendarDateBox;
