import * as React from "react";
import "./ModalDialog.scss";

interface Props {
  children: React.ReactNode;
}

const ModalDialog = ({ children }: Props): JSX.Element => {
  return (
    <div className="Dialog Message ModalDialog">
      <div className="position-reset">{children}</div>
    </div>
  );
};

export default ModalDialog;
