"use strict";

var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");

var DatePickerMonthSelect = React.createClass({
  /* privates */
  _handleClick: function (month) {
    this.props.selectMonth(month);
  },

  /* publics */

  /* lifecycle methods */

  render: function () {
    /* jshint ignore:start */

    var months = moment.monthsShort();
    var monthContainers = [];
    for (var i = 0; i < months.length; i++) {
      var monthClasses = React.addons.classSet({
        month: true,
        selected: months[i] == this.props.date.format("MMM"),
      });

      monthContainers.push(
        <div
          className={monthClasses}
          key={i}
          onClick={this._handleClick.bind(this, i)}
          onTouchEnd={this._handleClick.bind(this, i)}
        >
          {months[i]}
        </div>
      );
    }

    var classes = React.addons.classSet({
      DatePickerMonthSelect: true,
    });

    return <div className={classes}>{monthContainers}</div>;

    /* jshint ignore:end */
  },
});

module.exports = DatePickerMonthSelect;
