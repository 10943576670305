"use strict";

// scss requires
require("./_styles.scss");
// dependencies
const React = require("react/addons");
const moment = require("moment");
const _ = require("underscore");

// collections
// mixins
const TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
const SwapMixin = require("_lib/ui/mixins/SwapMixin.jsx");

const SwapDetailsDialog = React.createClass({
  mixins: [TouchMixin, SwapMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {
      step: 1,
      initStep: 1,
    };
  },
  componentDidMount: function () {
    // send a tracking event
    if (!this.props.inline) {
      window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Swap: Modify");
    }
  },

  /* publics */
  _getTotalSteps: function () {
    return 1;
  },

  /* privates */
  _getSelectedSlots: function () {
    const that = this;

    // group them by date
    const groupedSlots = {};

    _.each(this.props.getData("selectedSlots"), function (slot) {
      const groupingKey = slot.get("slot_date");

      if (_.has(groupedSlots, groupingKey)) {
        groupedSlots[groupingKey].push(slot);
      } else {
        groupedSlots[groupingKey] = [slot];
      }
    });

    const groupDivs = [];

    for (let key in groupedSlots) {
      const dateString = moment(key).format("ddd, ll");
      const slotDivs = [];

      _.each(groupedSlots[key], function (slot) {
        const timeElement = {};

        if (that.props.getData("useCustomTime")) {
          const startDate = moment(key + "T" + that.state.startTime, "YYYYMMDDTHH:mm:ss");
          const stopDate = moment(key + "T" + that.state.stopTime, "YYYYMMDDTHH:mm:ss");
          const timeString =
            startDate.format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT")) +
            " - " +
            stopDate.format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"));

          timeElement = (
            <div>
              <span>{timeString}</span>
              <br />
            </div>
          );
        }

        slotDivs.push(
          <div className="slot unclickable" key={slot.id}>
            <span className="text">
              {timeElement}
              <span className="large underline">{slot.get("assign_display_name")}</span>
              <br />
              <span className="large underline">{slot.get("display_name")}</span>
            </span>
          </div>
        );
      });

      groupDivs.push(
        <div className="slot-group" key={key}>
          <div className="date">{dateString}</div>
          <div className="slots-container">{slotDivs}</div>
        </div>
      );
    }

    return groupDivs;
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    const classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      SwapDetail: true,
    });

    let content;

    if (!this.props.inline) {
      content = (
        <div className={classes} data-cy="SDWindowPrompt">
          <div className="position-reset">
            <div
              className="close"
              onClick={this.touchProxy.bind(this, this.props.close, [])}
              onTouchEnd={this.touchProxy.bind(this, this.props.close, [])}
            >
              <i className="fa fa-close" />
            </div>

            <div className="header-container">
              <div className="header">
                <div className="mobile-section-nav">
                  <div className="mobile-section-nav-tab active">Modify</div>
                </div>
              </div>
            </div>

            <div className="section-container">
              <div className={"section _" + this.state.step}>{this._renderStep1()}</div>
            </div>

            <div className="bottom-controls">
              <div className="position-reset">
                <div className="left-side">{this._getBottomLeftControl(this._getTotalSteps())}</div>
                <div className="right-side">{this._getBottomRightControl(this._getTotalSteps())}</div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      content = this._renderInline();
    }

    return content;
  },

  _renderStep1: function () {
    // summary
    const swapSentence = this.props.widgetProxy("sentenceSwap");
    // notes
    const notesElement = this.props.widgetProxy("addNote");
    // only allow time to be changed if an admin
    const customDisplayElement = this.props.widgetProxy("customDisplay");
    // only allow time to be changed if an admin
    const timeElement = this.props.widgetProxy("editTime");
    // call order
    const callOrderElement = this.props.widgetProxy("editCallOrder");
    // loa reason if applicable
    const loaElement = this.props.widgetProxy("selectLoaReason");
    // locations if applicable
    const locElement = this.props.widgetProxy("selectLocations");

    const step1 = (
      <div className="content">
        <div className="details">
          <div className="sentence-container">{swapSentence}</div>
        </div>
        <div className="editables">
          {timeElement}
          {callOrderElement}
          {notesElement}
          {customDisplayElement}
          {loaElement}
          {locElement}
        </div>
      </div>
    );

    return step1;
  },
  _renderInline: function () {
    // note
    const notesElement = this.props.widgetProxy("addNote");
    // only allow time to be changed if an admin
    const timeElement = this.props.widgetProxy("editTime");
    // loa reason if applicable
    const loaElement = this.props.widgetProxy("selectLoaReason");
    // locations if applicable
    const locElement = this.props.widgetProxy("selectLocations");
    // call order if applicable
    const callOrderElement = this.props.widgetProxy("editCallOrderMultipleShifts");

    return (
      <div className="inline-container">
        <div className="editables">
          {timeElement}
          {callOrderElement}
          {notesElement}
          {loaElement}
          {locElement}
        </div>
      </div>
    );
  },
});

module.exports = SwapDetailsDialog;
