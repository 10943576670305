import React from "react";
import "./ConfirmPrompt.scss";

interface Props {
  text: string;
  cancel: () => void;
  confirm: () => void;
}

const ConfirmPrompt = ({ text, cancel, confirm }: Props): JSX.Element => {
  return (
    <div className="delete-container">
      <div>{text}</div>
      <div className="flex-display mt-20">
        <button className="btn" onClick={cancel}>
          Cancel
        </button>
        <button className="btn ml-10" onClick={confirm}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ConfirmPrompt;
