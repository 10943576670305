// Manager that maintains a dictionary of slots for faster lookups

var moment = require("moment");
var _ = require("underscore");
var $ = require("jquery");

var SlotManager = Backbone.Model.extend({
  defaults: {},

  initialize: function () {
    this.lookup = {};
  },

  /* publics */
  add: function (slot) {
    var index1 = slot.attributes.condensed_structure_id;
    var index2 = slot.attributes.slot_date.replace(/-/g, "");
    if (this.lookup.hasOwnProperty(index1)) {
      if (this.lookup[index1].hasOwnProperty(index2)) {
        // push it
        this.lookup[index1][index2].push(slot);
      } else {
        // add the second level
        this.lookup[index1][index2] = [slot];
      }
    } else {
      // add first level
      this.lookup[index1] = {};
      this.lookup[index1][index2] = [slot];
    }
  },
  get: function (index1, index2) {
    return this.lookup[index1] ? (this.lookup[index1][index2] ? this.lookup[index1][index2] : []) : [];
  },
});

module.exports = SlotManager;
