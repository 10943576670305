import ConfigHelper from "@/_lib/data/ConfigHelper";

export const API_PREFIX = ConfigHelper.getURL("host_lbapi");

export const ResourceUtils = (() => {
  const responseIntegerIsOk = (int: number) => int <= 299 && int >= 200;

  return {
    responseIntegerIsOk,
  };
})();
