import { IRegisterCallback, ISocketTransport } from "./SocketTransport";
import { MessageEvent, default as Pubnub } from "pubnub";
import ConfigHelper from "@/_lib/data/ConfigHelper";

const uuid = Pubnub.generateUUID();

export const pubnub = new Pubnub({
  publishKey: ConfigHelper.pubnub_publish_key,
  subscribeKey: ConfigHelper.pubnub_subscribe_key ?? "",
  uuid,
});

export class PubnubTransport implements ISocketTransport {
  constructor() {
    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.send = this.send.bind(this);
    this.registerCallback = this.registerCallback.bind(this);
    this.removeCallback = this.removeCallback.bind(this);
  }

  storedChannel?: string;
  listener?: Pubnub.ListenerParameters;
  subscriber?: Pubnub.SubscribeParameters;

  public connect(customerId: number, channel: string): void {
    this.storedChannel = channel;

    this.subscriber = {
      channels: [channel],
    };

    pubnub.subscribe(this.subscriber);
  }
  public disconnect(): void {
    if (this.subscriber) {
      pubnub.unsubscribe(this.subscriber);
      this.subscriber = undefined;
    }
  }
  public send(type: string, message: unknown): void {
    if (!this.storedChannel) {
      return;
    }

    const body = { type, message };
    pubnub.publish({
      channel: this.storedChannel,
      message: body,
    });
  }
  public registerCallback(callback: IRegisterCallback): void {
    if (this.listener) {
      this.removeCallback();
    }

    const handleMessage = (event: MessageEvent) => {
      const channel = event.channel;
      const { type, message } = event.message;

      if (channel === this.storedChannel) {
        callback(type, message);
      }
    };

    this.listener = {
      message: handleMessage,
    };

    pubnub.addListener(this.listener);
  }

  public removeCallback(): void {
    if (this.listener) {
      pubnub.removeListener(this.listener);
      this.listener = undefined;
    }
  }
}
