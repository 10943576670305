import { Collection, Model } from "backbone";

import { stringifyQuery } from "../utils/queryString";
import { formatDayKey } from "@/viewer/utils/dateFormatters";
import { get } from "@/_lib/utils/fetch";
import { RequestAttributes } from "@/viewer/types/domain/request";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ApiConfig = require("_lib/data/ApiConfig.js");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const RequestDataCollection = require("@/viewer/data/collections/RequestDataCollection.js");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const slotQuestComparatorFactory = require("@/viewer/data/comparators/SlotQuestComparatorFactory.js").default;

const fetchRequests = async (
  startDate: Date,
  endDate: Date,
  viewId: string,
  listed = true
): Promise<Collection<Model<RequestAttributes>>> => {
  const host: string = ApiConfig.getPrefix();
  const params = stringifyQuery({
    start_date: formatDayKey(startDate),
    end_date: formatDayKey(endDate),
    view_id: viewId,
    listed: listed.toString(),
  });

  const url = `${host}/request/range/?${params}`;

  const response = await get<{ data: unknown }>(url);

  if (response.data) {
    return new RequestDataCollection(response.data.data, {
      comparator: slotQuestComparatorFactory(),
    });
  } else {
    return new RequestDataCollection();
  }
};

export default fetchRequests;
