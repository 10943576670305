const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var PublishedMap = require("./../models/PublishedMap.js");

var PublishedMapCollection = Backbone.Collection.extend({
  model: PublishedMap,

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    return (
      ApiConfig.getPrefix() +
      "/schedule/data/map?start_date=" +
      this.attributes.startDate.format("YYYYMMDD") +
      "&end_date=" +
      this.attributes.stopDate.format("YYYYMMDD") +
      "&template_list=" +
      this.attributes.templateList
    );
  },
});

module.exports = PublishedMapCollection;
