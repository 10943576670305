import React from "react";

import classnames from "@/_lib/utils/classnames";
import styles from "./styles.module.scss";

interface Props {
  label?: string;
  disabled?: boolean;
  error?: boolean;
  checked: boolean;
  onChange: (e: React.ChangeEvent) => void;
  [key: string]: unknown;
}

const Checkbox = ({
  label = "",
  disabled = false,
  error = false,
  checked,
  onChange,
  ...rest
}: Props): React.ReactElement => {
  const rootClassNames = classnames({
    [styles.root]: true,
    [styles.disabled]: disabled,
  });

  const inputClassNames = classnames({
    [styles.input]: true,
    [styles.checked]: checked,
    [styles.error]: error,
  });

  return (
    <label className={rootClassNames}>
      <div className={inputClassNames}>
        <input
          style={{ display: "none" }}
          type="checkbox"
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          {...rest}
        />
        <span className={styles.box} />
      </div>
      {label && <div className={styles.label}>{label}</div>}
    </label>
  );
};

export default Checkbox;
