var Router = Backbone.Router.extend({
  routes: {
    "swop/:slot_id/:swop_action": "openSwop",
  },

  openSwop: function (slot_id, swop_action) {
    // open a view
    window.LbsAppData.AppContext.openLoadingDialog("Opening swaportunity...", this, function () {
      // send a tracking event -- putting this here to avoid tracking first-view openings
      //window.LbsAppData.Helpers.Analytics.sendEvent('Open: View', id);

      // open the view
      //window.LbsAppData.AppContext.openView(id);

      // wrap this up with an auth check on the Session to make sure we won't error getting
      // the swop data
      if (!window.LbsAppData.AppContext.session.get("auth")) {
        var interval = window.setInterval(function () {
          if (window.LbsAppData.AppContext.session.get("auth")) {
            window.LbsAppData.AppContext.openSwopReview(slot_id, swop_action);
            window.clearInterval(interval);
          }
        }, 500);
      } else {
        window.LbsAppData.AppContext.openSwopReview(slot_id, swop_action);
      }
    });
  },
});

module.exports = Router;
