var timePickerKeyobardActions = {
  unfocusKeydown: function (counter, isStart) {
    var numberOfDivs = window.LbsAppData.User.get("time_display") == window.LbsAppData.MILITARY ? 4 : 6;
    var count = counter;
    var shiftDivs = numberOfDivs === 4 ? 2 : 3;
    if (!isStart) {
      count += shiftDivs;
    }
    return { focusedCounter: count };
  },
  processKeyDown: function (e, blockKeyboardActions, focusedCounter, spinnerCounter, cbSetState) {
    var numberOfDivs = window.LbsAppData.User.get("time_display") == window.LbsAppData.MILITARY ? 4 : 6;
    var counterUpdate = undefined;
    switch (e.which) {
      case 39: // right
        if (blockKeyboardActions) {
          return undefined;
        }
        if (focusedCounter === undefined) {
          counterUpdate = { focusedCounter: 0 };
        } else {
          counterUpdate = {
            focusedCounter: (focusedCounter + 1) % numberOfDivs,
          };
        }
        // prevent page scrolls?
        e.preventDefault();
        break;
      case 37: // left
        if (blockKeyboardActions) {
          return undefined;
        }
        if (focusedCounter === undefined) {
          counterUpdate = { focusedCounter: numberOfDivs - 1 };
        } else {
          var tempCount = focusedCounter - 1;
          if (tempCount < 0) {
            tempCount = numberOfDivs - 1;
          }
          counterUpdate = { focusedCounter: tempCount };
        }
        // prevent page scrolls?
        e.preventDefault();
        break;
      case 38: //up
        counterUpdate = { spinnerCounter: spinnerCounter + 1 };
        // prevent page scrolls?
        e.preventDefault();
        break;
      case 40: //down
        counterUpdate = { spinnerCounter: spinnerCounter - 1 };
        // prevent page scrolls?
        e.preventDefault();
        break;
      default:
        break;
    }
    cbSetState(counterUpdate);
  },
  submitButtonToggle: function (value, isStart, originalArray) {
    var allowSubmitArray = originalArray.slice();
    if (isStart) {
      allowSubmitArray[0] = value;
    } else {
      allowSubmitArray[1] = value;
    }
    return allowSubmitArray;
  },
  cbCounterStatusUpdate: function (counterStatus) {
    if (counterStatus) {
      counterStatus.focusedCounter !== undefined && this.setState({ focusedCounter: counterStatus.focusedCounter });
      counterStatus.spinnerCounter !== undefined && this.setState({ spinnerCounter: counterStatus.spinnerCounter });
    }
  },
};

module.exports = timePickerKeyobardActions;
