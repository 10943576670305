import React from "react";
import "./HelpIcon.scss";
import Tooltip from "./Tooltip";

interface Props {
  text: string;
  position?: string;
}

const HelpIcon = ({ text, position }: Props): JSX.Element => {
  return (
    <div>
      <Tooltip title={text} position={position} width="400px">
        <i className="fas fa-question-circle help-icon"></i>
      </Tooltip>
    </div>
  );
};

export default HelpIcon;
