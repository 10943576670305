const Backbone = require("backbone");
var moment = require("moment-timezone");
var _ = require("underscore");
var Session = require("_lib/data/session/Session.js");
const SafeJSON = require("../../../common/utils/jsonParseShield");

var AppContext = Backbone.Model.extend({
  session: new Session(),

  defaults: {
    //report_saved: new ReportDefinition(),
    report_saved: undefined,
    report_draft: undefined,
    report_draft_refresh: undefined,

    // refresh timer
    refresh_interval_id: undefined,

    // report draft result focus
    focused_result_index: undefined,

    mode: "run",

    dialogStack: [],
    // displayed contexts
    contextualStack: [],

    focusedCell: undefined,
  },

  initialize: function () {
    var user = SafeJSON.parse(window.payload.user);
    // set a default timezone on the moment object (if specific...otherwise just use the default (local))
    if (user.tz) {
      moment.tz.setDefault(user.tz);
    }
  },

  _syncBackbone: function () {
    var that = this;
    this.session._syncBackbone(function () {
      // attach the router
      // window.LbsAppData.attachRouter();

      // trigger an authorized event
      that.trigger("authorized");
    });
  },

  registerEvents: function () {},

  pushContext: function (contextObject) {
    // check to see if the item already exists
    var item = _.findWhere(this.attributes.contextualStack, {
      tag: contextObject.tag,
    });
    if (!item) {
      this.attributes.contextualStack.push(contextObject);
    } else {
      // update it instead of pushing a new one
      this.updateContextualItem(contextObject.tag, contextObject);
    }

    this.trigger("change:contextualStack");
  },
  popContext: function () {
    var contextualObject = this.attributes.contextualStack.pop();
    this.trigger("change:contextualStack");
  },
  removeContext: function (tag) {
    // find the index
    var index = this.attributes.contextualStack.findIndex(function (i) {
      return i.tag == tag;
    });
    if (index > -1) {
      // exists
      this.attributes.contextualStack.splice(index, 1);
      this.trigger("change:contextualStack");
    }
  },
  updateContextualItem: function (tag, attributes) {
    var item = _.findWhere(this.attributes.contextualStack, { tag: tag });
    for (let key in attributes) {
      item[key] = attributes[key];
    }
    this.trigger("change:contextualStack");
  },

  // displayers
  openDialog: function (object) {
    this.attributes.dialogStack.push(object);
    this.trigger("pushedDialog");
  },
  closeDialog: function (type) {
    if (this.attributes.dialogStack.length) {
      if (!type) {
        var dialog = this.attributes.dialogStack.pop();
        this.trigger("poppedDialog");
      } else {
        // close a specific dialog
        var index = _.findIndex(this.attributes.dialogStack, function (item) {
          return item.type == type;
        });
        if (index > -1) {
          this.attributes.dialogStack.splice(index, 1);
          this.trigger("poppedDialog");
        }
      }
    }
  },
  clearDialogs: function () {
    this.attributes.dialogStack = [];
    this.trigger("poppedDialog");
  },
  openLoadingDialog: function (message, context, callback, timeout) {
    // if there's already a loading dialog push that message into the current container
    var index = _.findIndex(this.attributes.dialogStack, function (item) {
      return item.type == "message";
    });
    if (index > -1) {
      // update the message
      this.attributes.dialogStack[index].message = message;
      this.trigger("pushedDialog");
    } else {
      this.openDialog({ type: "message", message: message });
    }

    if (callback) {
      window.setTimeout(function () {
        callback.call(context);
      }, timeout || 100);
    }
  },
  closeLoadingDialog: function () {
    // pretend like we did something that took a while basically...just so the user has time to see
    // something happened or is happening
    var that = this;
    window.setTimeout(function () {
      that.closeDialog();
    }, 500);
  },

  // trigger a redraw on the schedule container
  triggerRedraw: function () {
    this.trigger("redraw");

    // enable the bar/line options
    this.trigger("enable:bar");
    this.trigger("enable:line");

    this.refreshContext();
  },
  refreshContext: function () {
    var menuItems = [];

    // if this isn't a shadow with a "current" period or a shadow with a lookback starting at 1900 then push the datepicker link
    if (
      this.get("report_draft").get("_definition").properties.data.mode == "adhoc" ||
      this.get("report_draft").get("_definition").properties.data.period_type == "custom"
    ) {
      menuItems.push({
        title: "Edit Report Dates",
        id: "dates",
        onClick: this.openDatePicker.bind(this),
        extClassFn: function () {
          return "action";
        },
      });
    }

    if (window.LbsAppData.AppContext.get("report_draft").results.length === 1) {
      var title = window.LbsAppData.AppContext.get("report_draft").results.at(0).get("result").title;
      this.pushContext({
        tag: "page",
        text: title,
        action: undefined, //window.LbsAppData.AppContext.openDialog.bind(this, {type: 'view-options', tab: 'filter'}),
        menu: menuItems,
        navLeft: undefined,
        navRight: undefined,
      });
    } else {
      var that = this;

      var resultId = window.LbsAppData.AppContext.get("focused_result_index");
      var title = window.LbsAppData.AppContext.get("report_draft").results.at(resultId).get("result").title;
      var resultItems = _.map(window.LbsAppData.AppContext.get("report_draft").results.models, function (r, idx) {
        return {
          title: r.get("result").title,
          id: idx,
          onClick: that.setResultIndex.bind(that, idx),
          extClassFn: function () {
            if (resultId == idx) {
              return "active";
            }
          },
        };
      });

      this.pushContext({
        tag: "page",
        text: title,
        menu: menuItems.concat(resultItems),
        action: undefined, //window.LbsAppData.AppContext.openDialog.bind(this, {type: 'view-options', tab: 'filter'}),
        navLeft: this.decrementResultIndex.bind(this),
        navRight: this.incrementResultIndex.bind(this),
      });
    }

    /*
		var xTitle = window.LbsAppData.AppContext.get('report_draft').getAxisContext('x');
		var yTitle = window.LbsAppData.AppContext.get('report_draft').getAxisContext('y');

		// push the extra contextual items
		this.pushContext({
			tag: 'xAxis',
			text: xTitle,
			tooltip: 'x axis',
			action: undefined, //window.LbsAppData.AppContext.openDialog.bind(this, {type: 'view-options', tab: 'filter'}),
		});
		this.pushContext({
			tag: 'yAxis',
			text: yTitle,
			tooltip: 'y axis',
			action: undefined, //window.LbsAppData.AppContext.openDialog.bind(this, {type: 'view-options', tab: 'filter'}),
		});
		*/
  },

  // result index actions
  setResultIndex: function (val, noEvent) {
    this.set({
      focused_result_index: val,
    });

    if (!noEvent) {
      // track an event
      window.LbsAppData.Helpers.Analytics.sendEvent("Focus: Result", val);
    }

    this.flutterIcons();
    this.triggerRedraw();
  },
  incrementResultIndex: function () {
    // wrap it around
    var newIndex =
      (this.attributes.focused_result_index + 1) % window.LbsAppData.AppContext.get("report_draft").results.length;
    this.setResultIndex(newIndex, true);
    // track an event
    window.LbsAppData.Helpers.Analytics.sendEvent("Increment: Result", newIndex);
  },
  decrementResultIndex: function () {
    // wrap it around
    var newIndex =
      (window.LbsAppData.AppContext.get("report_draft").results.length + (this.attributes.focused_result_index - 1)) %
      window.LbsAppData.AppContext.get("report_draft").results.length;
    this.setResultIndex(newIndex, true);
    // track an event
    window.LbsAppData.Helpers.Analytics.sendEvent("Decrement: Result", newIndex);
  },
  flutterIcons: function () {
    // refresh the graphing icons in case we're now in a report that changes what's possible
    var resultId = this.get("focused_result_index");
    this.get("report_draft").results.at(resultId).attributes.table.objects._triggerIconEvents();
  },

  // refresh monitor
  setReportDraft: function (report, isModifying) {
    this.attributes.report_draft = report;
    // reset the focused_result_index
    this.attributes.focused_result_index = 0;

    // if this is modifying then set a flag on the report definition as well (for the save cues)
    if (this.attributes.report_saved && isModifying) {
      this.attributes.report_saved.set({ is_modified: true });
    }

    // update the context ribbon
    //this.updateReportContext(report, true);

    this.trigger("clearResult");

    //this.triggerRedraw();
  },

  spawnRefreshReport: function () {
    if (this.attributes.report_draft_refresh) {
      // delete the existing report draft
      this.destroyRefreshReport();
    }

    var def = SafeJSON.parse(JSON.stringify(window.LbsAppData.AppContext.get("report_draft").get("_definition")));

    window.LbsAppData.AppContext.attributes.report_draft_refresh =
      window.LbsAppData.Helpers.Reports.defineTallyReport(def);

    // set up the monitor
    this.startRefreshMonitor();
  },
  destroyRefreshReport: function () {
    window.LbsAppData.AppContext.attributes.report_draft_refresh = undefined;
    this.stopRefreshMonitor();
  },

  startRefreshMonitor: function () {
    var that = this;
    var intervalId = window.setInterval(function () {
      if (
        that.attributes.report_draft_refresh.get("last_shadow_change") &&
        moment().isAfter(moment(that.attributes.report_draft_refresh.get("last_shadow_change")).add(5, "seconds"))
      ) {
        // save the previous results and the current selection state
        that.get("report_draft_refresh").snapshot(that.attributes.report_draft);

        var timingStart = new Date(); // timing var
        that.get("report_draft_refresh").save(
          {},
          {
            success: function (model) {
              // copy over the report_draft's results (for diffs)
              //model.prevResults = that.attributes.report_draft.results;

              // replace the report_draft and queue a new refreshing draft
              that.attributes.report_draft = model;
              that.attributes.report_draft_refresh = window.LbsAppData.Helpers.Reports.defineTallyReport(
                model.get("_definition")
              );

              // track a timing event -- shadow
              window.LbsAppData.Helpers.Analytics.sendTiming("Generate: Report", new Date() - timingStart, "shadow");

              // trigger a redraw
              that.trigger("refreshResult");
            },
          }
        );
      }
    }, 5000);

    this.set({ refresh_interval_id: intervalId });
  },
  stopRefreshMonitor: function () {
    window.clearInterval(this.attributes.refresh_interval_id);
    this.set({ refresh_interval_id: undefined });
  },

  updateReportContext: function (model, isDraft) {
    var contextText, properties, tooltip;

    if (this.get("report_saved")) {
      contextText = this.get("report_saved").get("name");
    } else {
      if (isDraft) {
        contextText = "Unsaved Report";
      } else {
        // saved
        contextText = model.get("name");
      }
    }

    if (isDraft) {
      properties = model.get("_definition").properties;
    } else {
      properties = model.get("data")._definition.properties;
    }

    // append shadow context as well
    if (properties.data.shadow) {
      contextText += ' - Shadowing "' + properties.data.shadow.schedule_name + '"';
      tooltip = contextText;
    }

    this.pushContext({
      tag: "report",
      text: contextText,
      tooltip: "edit definition" + (tooltip ? " (" + contextText + ")" : ""),
      limitWidthClass: tooltip ? "limit-width-small" : undefined,
      action: function () {
        window.LbsAppData.AppContext.openDialog({
          type: window.LbsAppData.User.get("is_admin") ? "report-edit" : "manage-reports",
        });
      },
    });

    // update the report_saved model
    if (!isDraft) {
      this.set("report_saved", model);
    }
  },
  fetchReportDraft: function () {
    var newReport = window.LbsAppData.Helpers.Reports.defineTallyReport(
      this.attributes.report_saved.get("data")._definition
    );

    window.LbsAppData.AppContext.openLoadingDialog("Generating report...", this, function () {
      // set the current report (thus clearing it, so we don't have the update highlights when this one returns)
      window.LbsAppData.AppContext.setReportDraft(newReport);

      var timingStart = new Date(); // timing var
      newReport.save(
        {},
        {
          success: function (model) {
            if (!window.location.hash) {
              window.LbsAppData.AppContext.triggerRedraw();
              // track a timing event -- shadow
              window.LbsAppData.Helpers.Analytics.sendTiming("Generate: Report", new Date() - timingStart, "shadow");
            } else {
              // track a timing event -- adhoc
              window.LbsAppData.Helpers.Analytics.sendTiming("Generate: Report", new Date() - timingStart, "adhoc");
            }
          },
        }
      );
    });
  },
  saveReportContext: function (callback) {
    var reportDef = this.get("report_saved");
    reportDef.set({
      data: {
        _definition: SafeJSON.parse(JSON.stringify(this.get("report_draft").get("_definition"))),
      },
    });

    window.LbsAppData.AppContext.openLoadingDialog("Resaving report...", this, function () {
      reportDef.save(
        {},
        {
          success: function (model) {
            // close the loading dialog
            window.LbsAppData.AppContext.closeDialog();

            // display message
            window.LbsAppData.AppContext.openDialog({
              type: "message",
              message: "Report saved",
              timer: 2000,
            });

            model.attributes.is_modified = false;
          },
        }
      );
    });
  },
  deleteReportContext: function () {
    var reportDef = this.get("report_saved");

    // close the name dialog
    this.closeDialog();

    window.LbsAppData.AppContext.openLoadingDialog("Deleting report...", this, function () {
      reportDef.destroy({
        success: function () {
          // close the loading dialog
          window.LbsAppData.AppContext.closeDialog();

          // display message
          window.LbsAppData.AppContext.openDialog({
            type: "message",
            message: "Report deleted... Redirecting to blank report",
            timer: 1000,
            callback: function () {
              // reset the window
              window.location.href = "/reports/";
            },
          });

          // track an event
          window.LbsAppData.Helpers.Analytics.sendEvent("Delete: Report", reportDef.id);

          //that.set({report_saved: undefined});
          //that.updateReportContext(undefined);
        },
      });
    });
  },

  focusCell: function (slot) {
    if (!this.attributes.focusCell && !this.attributes.displayedContextMenu) {
      this.set({ focusedCell: slot });
    }
  },
  unfocusCell: function () {
    if (this.attributes.focusedCell && !this.attributes.focusedCell.expanded) {
      this.set({ focusedCell: undefined });
    }
  },
  openDatePicker: function () {
    // datepicker changes behavior based on report type
    var reportDraft = this.get("report_draft");

    var extOptions = {};
    if (this.get("report_draft").get("_definition").properties.data.mode == "shadow") {
      switch (this.get("report_draft").get("_definition").properties.data.period_type) {
        case "current":
          // shouldn't even be openable
          break;
        case "lookback":
          extOptions.anchorStop = true;
          extOptions.boundaryStart = undefined;
          extOptions.boundaryStop = moment(reportDraft.get("_definition").properties.data.shadow.stop_date);
          break;
        case "custom":
          extOptions.boundaryStart = moment(reportDraft.get("_definition").properties.data.shadow.start_date);
          extOptions.boundaryStop = moment(reportDraft.get("_definition").properties.data.shadow.stop_date);
          break;
        default:
          break;
      }
    }

    var rangeStart = this._calculateDate(reportDraft.get("_definition").properties.data.start_date);
    var rangeStop = this._calculateDate(reportDraft.get("_definition").properties.data.end_date);

    var that = this;
    this.openDialog({
      type: "date-select",
      mode: "range",
      extOptions: extOptions,
      rangeStart: rangeStart,
      rangeStop: rangeStop,
      onSubmit: function (start, stop) {
        // create a new report with edited date values
        var newDefinition = that.attributes.report_draft.attributes._definition;
        newDefinition.properties.data.start_date = start;
        newDefinition.properties.data.end_date = stop;

        var newReport = window.LbsAppData.Helpers.Reports.defineTallyReport(newDefinition);

        window.LbsAppData.AppContext.openLoadingDialog("Generating report...", that, function () {
          // set the current report (thus clearing it, so we don't have the update highlights when this one returns)
          window.LbsAppData.AppContext.setReportDraft(newReport);

          var timingStart = new Date(); // timing var
          newReport.save(
            {},
            {
              success: function (model) {
                // refresh the context
                that.refreshContext();

                window.LbsAppData.AppContext.triggerRedraw();

                if (!window.location.hash) {
                  // track a timing event -- shadow
                  window.LbsAppData.Helpers.Analytics.sendTiming(
                    "Generate: Report",
                    new Date() - timingStart,
                    "shadow"
                  );
                } else {
                  // track a timing event -- adhoc
                  window.LbsAppData.Helpers.Analytics.sendTiming("Generate: Report", new Date() - timingStart, "adhoc");
                }
              },
            }
          );
        });

        // track an event
        window.LbsAppData.Helpers.Analytics.sendEvent("Update: Range", "Jump");
      },
    });
  },

  /* privates */
  _calculateDate: function (obj) {
    // get the text for a date value (this could get complicated with the variable objects)
    var date;
    if (obj) {
      if (obj.type == "specific") {
        // specific date logic -- just return it
        date = moment(obj.value);
      } else if (obj.type == "anchor") {
        // anchor date logic

        // start with the current date
        date = moment();
        // apply the anchor function
        if (obj.anchorFn == "start") {
          date.startOf(obj.anchorType);
        } else {
          // end
          date.endOf(obj.anchorType);
        }
        // apply the offset
        if (obj.offsetVal) {
          date.add(obj.offsetVal, obj.offsetType);
        }
      } else {
        // variable date logic

        // start with the state variable we're pointed at
        date = this._calculateDate(this.state[obj.id]);
        // apply the offset
        if (obj.offsetVal) {
          date.add(obj.offsetVal, obj.offsetType);
        }
      }
    }

    return date;
  },
});

module.exports = AppContext;
