import React from "react";

import { ReportData, ReportResult, TallyResult } from "@/viewer/types/domain/report";

import "./_styles.scss";

interface Props {
  cellClassNames: string;
  leftHandColumnId: number;
  reportData: ReportData | undefined;
  reportResult: ReportResult | undefined;
  tallies: TallyResult[] | undefined;
}

const hydrateTallyItems = (
  tallyData: TallyResult[],
  options: { leftHandColumnId: number; cellClassNames: string }
): JSX.Element => {
  const { cellClassNames, leftHandColumnId } = options;
  const tallyElements: JSX.Element[] = [];

  tallyData.forEach(({ tally_name }) => {
    tallyElements.push(
      <div className="column-print-header-wrapper" key={`${tally_name}_${leftHandColumnId}`}>
        <div className="tally-name-wrapper">
          <div className={cellClassNames}>{tally_name}</div>
        </div>
      </div>
    );
  });

  return <>{tallyElements}</>;
};

const PrintColumnTallyHeaders = (props: Props): JSX.Element => {
  const { cellClassNames, leftHandColumnId, reportData, reportResult, tallies } = props;

  if (!tallies?.length) return <></>;

  if (reportData && reportResult) {
    const tallyItems = hydrateTallyItems(tallies, { cellClassNames, leftHandColumnId });

    return (
      <>
        <div className="left-column-items__tally-header" key={`tallyHeader_${leftHandColumnId}`}>
          Tallies
        </div>
        {tallyItems}
      </>
    );
  }

  return <></>;
};

export default PrintColumnTallyHeaders;
