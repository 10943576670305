export const reportError = ({ colno, error, lineno, message }: ErrorEvent, url: string, origin: string): void => {
  const header = "<h1 data-cy='EmbarrassingError'>Well, this is <span class='red'>embarrassing</span>...</h1>";
  const content = "<div id='page-content'><div class='bad'></div><div class='good'></div></div>";
  const footer = "<div class='footer'></div>";
  $("#content")
    .addClass("error")
    .html(header + content + footer);

  const badContent =
    "<p><span class='title red'>the Bad:</span><span class='text'>Clearly things did not go according to plan.</span></p>";
  $(".bad").html(badContent);

  const goodContent =
    "<p><span class='title green'>the Good:</span><span class='text'>The <span class='orange'>Lightning Bolt</span> staff received an error report and will be working to fix it immediately.</span></p>";
  $(".good").html(goodContent);

  const footerContent = `<p><h3>We sincerely apologize for the inconvenience, \
			please try refreshing the page or <a href="${url}">go back</a>.</h3></p>`;
  $(".footer").html(footerContent);

  if ((window as any).LbsAppData && window.location.hostname !== "localhost") {
    (window as any).LbsAppData.Helpers.Errors.log(message, url, lineno, colno, error, origin);
  }
};
