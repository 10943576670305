import { Collection, Model } from "backbone";

export type ListenerDesc = [Collection | Model, string];

function debounced(callback: () => void, durationMs = 500) {
  let timeout: any;

  return () => {
    return new Promise((resolve) => {
      const execute = () => {
        const result = callback();
        resolve(result);
      };
      if (timeout != null) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(execute, durationMs);
    });
  };
}

export default (callback: () => void, ...listeners: ListenerDesc[]) => {
  // Some updates in this app produce a lot of events; we don't need to rerender for all of them.
  const debouncedCallback = debounced(callback, 10);

  listeners.forEach(([model, eventName]) => {
    if (model) {
      model.on(eventName, debouncedCallback);
    }
  });

  // Clean up listeners on the way out
  return () => {
    listeners.forEach(([model, eventName]) => {
      if (model) {
        model.off(eventName, debouncedCallback);
      }
    });
  };
};
