import { isWeekend } from "date-fns";
import * as React from "react";

import { DataContext, SettingsContext, ToolContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { getSlotQuestUUID } from "@/viewer/utils/domain/slotquests";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import classnames from "@/_lib/utils/classnames";
import StandardSlot from "./StandardSlot";
import { OriginalPersonnelMap } from "@/viewer/types/viewdata";
import { DepartmentsById } from "@/viewer/types/domain/department";

interface Props {
  date: Date;
  slots: SlotOrRequest[];
  settings: SettingsContext;
  ui: UIContext;
  data: DataContext;
  tools: ToolContext;
  originalPersonnelMap: OriginalPersonnelMap;
  leftColId: string;
  isDataGroup: boolean;
  index: number;
  cellEffects: React.CSSProperties;
  departmentsById: DepartmentsById;
}

const StandardWeekDataCell = (props: Props): JSX.Element => {
  const {
    settings,
    ui,
    data,
    tools,
    leftColId,
    date,
    isDataGroup,
    index,
    cellEffects,
    originalPersonnelMap,
    departmentsById,
  } = props;

  const classes = classnames("DataCell", {
    weekend: isWeekend(date),
  });

  return (
    <div data-cy="dataCell" className={classes}>
      {props.slots.map((slot) => {
        return (
          <StandardSlot
            key={getSlotQuestUUID(slot)}
            slot={slot}
            settings={settings}
            ui={ui}
            data={data}
            tools={tools}
            leftColId={leftColId}
            isDataGroup={isDataGroup}
            index={index}
            slotEffects={cellEffects}
            originalPersonnelMap={originalPersonnelMap}
            departmentsById={departmentsById}
          />
        );
      })}
    </div>
  );
};

export default StandardWeekDataCell;
