import React from "react";
import classnames from "@/_lib/utils/classnames";

import useAccessibleApps from "@/_lib/hooks/useAccessibleApps";
import { AppName, SettingsContext } from "@/viewer/ui/modules/common/types/context";
import { User } from "@/viewer/types/domain/user";

import LogoMenuItem from "./LogoMenuItem";

import "./_styles.scss";

export enum Type {
  Dashboard,
  Viewer,
  Editor,
  Reports,
  Administration,
  Analytics,
  ViewManager,
}

interface IProps {
  active: boolean;
  appName: AppName;
  settings: SettingsContext;
  user: User;
  isScheduled: boolean;
}

const LogoMenu = ({ active, appName, isScheduled, settings, user }: IProps): React.ReactElement => {
  const accessibleApps = useAccessibleApps();

  const classes = classnames({
    LogoMenu: true,
    active,
  });

  const renderLogoMenuItems = (): React.ReactElement[] => {
    const items: Type[] = [Type.Dashboard];

    if (accessibleApps.viewer) items.push(Type.Viewer);
    if (accessibleApps.editor) items.push(Type.Editor);
    if (accessibleApps.reports) items.push(Type.Reports);
    if (user.isAdmin || user.isSuperAdmin) items.push(Type.Administration);
    if (accessibleApps.analytics) items.push(Type.Analytics);
    if (accessibleApps.viewManager) items.push(Type.ViewManager);

    return items.map((type) => {
      return <LogoMenuItem key={type} type={type} appName={appName} settings={settings} isScheduled={isScheduled} />;
    });
  };

  return <div className={classes}>{renderLogoMenuItems()}</div>;
};

export default LogoMenu;
