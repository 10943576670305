import React from "react";

import { GROUP_BANNER_ROW_HEIGHT } from "../../constants";

interface Props {
  leftHandHeader: boolean;
}

const PrintColumnRequestGroupHeader = (props: Props): JSX.Element => {
  const { leftHandHeader } = props;

  return (
    <div className="group-banner group-banner--requests" style={{ height: `${GROUP_BANNER_ROW_HEIGHT}px` }}>
      {leftHandHeader ? "Requests" : null}
    </div>
  );
};

export default PrintColumnRequestGroupHeader;
