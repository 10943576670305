import { Middleware } from "redux";

import toastActions, { Type } from "./actions";
import { RootState } from "@/modules/reducer";

const toastMiddleware: Middleware<unknown, RootState> = (store) => (next) => (action) => {
  const state = store.getState();

  if (action.type === Type.Open && state.toast.isOpen) {
    store.dispatch(toastActions.close());
  }

  return next(action);
};

export default toastMiddleware;
