function parseQueryPart(s: string): [string, string] {
  const vs = s.split("=").map(decodeURIComponent);
  if (vs.length <= 0) {
    return ["", ""];
  } else if (vs.length === 1) {
    return [vs[0], ""];
  }
  return [vs[0], vs[1]];
}
export function parseQuery(s: string): { [k: string]: string } {
  const out: { [k: string]: string } = {};
  s = s[0] === "?" ? s.substring(1) : s;
  if (!s || s === "") {
    return out;
  }
  s.split("&")
    .map(parseQueryPart)
    .forEach(([k, v]) => {
      out[k] = v;
    });
  return out;
}
export function stringifyQuery(q: { [k: string]: string }): string {
  return Object.entries(q)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join("&");
}
