const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var TableObject = require("./../models/TableObject.js");
var _ = require("underscore");

var TableObjectCollection = Backbone.Collection.extend({
  model: TableObject,
  //comparator: 'path_id',

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  /* publics */
  addIndex: function (parts, axis) {
    // index this row/column configuration
    var idParts = [];
    var titleParts = [];
    _.each(parts, function (item) {
      if (item.id !== undefined) {
        idParts.push(item.id);
      }

      titleParts.push(item.get("title"));
    });

    if (axis == "x") {
      // this is gross... just trying to put a few more bandages on it though
      var id = (idParts.join("!") + "|").replace("x!", "!"); // replace a 'x!blah' index with just '!blah'
      this.add({
        id: id,
        title: titleParts.join(" "),
        object: parts,
        axis: axis,
      });
    } else {
      // axis == 'y'
      // this is gross... just trying to put a few more bandages on it though
      var id = ("|" + idParts.join("!")).replace("y!", "!"); // replace a 'y!blah' index with just '!blah'
      this.add({
        id: id,
        title: titleParts.join(" "),
        object: parts,
        axis: axis,
      });
    }
  },

  select: function (obj) {
    // obj is technically in the collection -- this is weird but I'm not going to go get something stupidly
    obj.set({ selected: !obj.get("selected") });

    // track an event
    window.LbsAppData.Helpers.Analytics.sendEvent("Highlight: " + obj.get("axis"), obj.id);

    // update the context
    if (this._hasSelection()) {
      var that = this;

      window.LbsAppData.AppContext.pushContext({
        tag: "selection",
        text: "Selection",
        action: undefined, //window.LbsAppData.AppContext.openDialog.bind(this, {type: 'view-options', tab: 'filter'}),
        onClose: function () {
          window.LbsAppData.AppContext.get("report_draft").clearSelectedObjects();
        },
      });
    }

    // update any of the other result table collections (so we can persist selections)
    window.LbsAppData.AppContext.get("report_draft").selectSiblings(obj);

    // pop the context if no other siblings have a selection
    if (!window.LbsAppData.AppContext.get("report_draft").hasSelection()) {
      window.LbsAppData.AppContext.removeContext("selection");
    }

    // if no selection

    // trigger a global 'select' event
    this.trigger("select");

    // activate toolbar options
    this._triggerIconEvents();
  },

  getSelected: function () {
    return this.where({ selected: true });
  },

  /* privates */
  _hasSelection: function () {
    return this.findWhere({ selected: true }) ? true : false;
  },
  _triggerIconEvents: function () {
    var selected = this.getSelected();
    if (selected.length) {
      // if there is a selection, we can compress the table
      window.LbsAppData.AppContext.trigger("enable:compress");

      var selectedSplit = _.groupBy(selected, function (item) {
        return item.get("axis");
      });
      // if x OR y has only 1 selection, then we can create a pie chart
      if ((selectedSplit.x && selectedSplit.x.length === 1) || (selectedSplit.y && selectedSplit.y.length === 1)) {
        window.LbsAppData.AppContext.trigger("enable:pie");
      } else {
        // make sure pie is disabled
        window.LbsAppData.AppContext.trigger("disable:pie");
      }
    } else {
      // nothing selected
      window.LbsAppData.AppContext.trigger("disable:compress");
      // disable the pie --- this could 'technically' be incorrect if the report has only one person/tally
      // but i don't really care... the overhead is stupid to do that check each time
      window.LbsAppData.AppContext.trigger("disable:pie");
    }
  },
});

module.exports = TableObjectCollection;
