const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");

var Request = Backbone.Model.extend({
  idAttribute: "request_id",

  defaults: {},

  initialize: function (models, options) {},

  url: function () {
    return ApiConfig.getPrefix() + (this.id ? "/request/" + this.id : "/request/");
  },
});

module.exports = Request;
