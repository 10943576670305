import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  typography: {
    fontFamily: ['"Open Sans"', "Lucida Grande", "Lucida Sans", "Arial", "sans-serif"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#42B3E3",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FF8000",
      contrastText: "#FFFFFF",
    },
    text: {
      primary: "#080E33",
    },
    divider: "#D9D9D9",
  },
});
