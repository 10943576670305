/**
 * The height of a column month data row, where each cell has at most 1 slot is 22 pixels.
 *
 * This height is otherwise determined by the data cell with the greatest number of slots and is
 * calculated at render time per row.
 *
 * See renderers/renderTallyGroups.tsx, renderers/renderSlots.tsx, and renderers/renderRequests.tsx for details.
 */
export const BASE_COLUMN_MONTH_DATA_ROW_HEIGHT = 22;

export const HEADER_ROW_HEIGHT = 33;
export const HEADER_ROW_CONDENSED_HEIGHT = 52;
export const TOP_SPACER_ROW_HEIGHT = 36;
export const TOP_SPACER_CONDENSED_ROW_HEIGHT = 52;
export const BOTTOM_SPACER_ROW_HEIGHT = 250;
export const GROUP_BANNER_ROW_HEIGHT = 29;
