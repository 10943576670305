"use strict";

// scss requires
//require('./_styles.scss');
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");
var _ = require("underscore");
// components
var RequestsDialogListRequestItem = require("./RequestsDialogListRequestItem.jsx");
var RequestsDialogListSwapItem = require("./RequestsDialogListSwapItem.jsx");

var RequestsDialogListItemsContainer = React.createClass({
  /* lifecycle methods */
  getInitialState: function () {
    var vault = this.props.items.length ? this.props.items : [];

    return {
      activeControl: undefined,

      //filter: 'me',
      //sort: 'date',
      startDate: moment(this.props.start_date),
      endDate: moment(this.props.end_date),

      filterText: "",
      filteredVault: vault,
    };
  },
  componentDidMount: function () {},
  componentWillUnmount: function () {},
  //componentWillUpdate: function() {
  //	this._unregisterBackboneEvents();
  //},
  //componentDidUpdate: function() {
  //	this._registerBackboneEvents();
  //},
  componentWillReceiveProps: function (nextProps) {
    if ((this.props.isLoading && !nextProps.isLoading) || this.props.items.length != nextProps.items.length) {
      var requests = nextProps.items.length ? nextProps.items : [];

      // filter the collection
      var vault = requests,
        newFilteredVault = requests;

      var value = this.props.filterText;
      if (value) {
        newFilteredVault = _.filter(vault, function (item) {
          return item.attributes.attrString.toLowerCase().indexOf(value) > -1;
        });
      }

      this.setState({
        filterText: value,
        filteredVault: newFilteredVault,
      });
    }
  },

  /* publics */
  update: function (options) {
    this.setState({
      filter: options.filter,
      sort: options.sort,
      startDate: options.startDate,
      endDate: options.endDate,
      activeControl: undefined,
    });
  },

  /* privates */
  _toggleControls: function (e) {
    if (this.state.activeControl != "controls") {
      this.setState({ activeControl: "controls" });
    } else {
      this.setState({ activeControl: undefined });
    }

    //if (!this.refs.Options.state.active) {
    //	this.refs.Options.setState({active: true});
    //} else {
    //	this.refs.Options.setState({active: false});
    //}
  },

  _handleSearch: function (e, val) {
    var value = val;
    if (value == null) {
      value = e.currentTarget.value.toLowerCase();
    }

    // filter the collection
    var vault = this.props.items,
      newFilteredVault = this.props.items;

    if (value) {
      newFilteredVault = _.filter(vault, function (item) {
        return item.attrString.toLowerCase().indexOf(value) > -1;
      });
    }

    this.setState({
      filterText: value,
      filteredVault: newFilteredVault,
    });
  },

  _selectTab: function (tab) {
    //this.setState({tab: tab}, function() {
    //	$('.items-container').scrollTop(0);
    //});
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var component;
    //if (this.props.isLoading) {
    //	// push the loader icon
    //	component =
    //		<div className='loader'>
    //			<i className='fa fa-fw fa-spin fa-spinner' />
    //		</div>
    //} else {
    var groupedDivs = [];

    // push the requests
    var groupedVault = _.groupBy(this.state.filteredVault, function (item) {
      return item.attributes.request_date || item.attributes.slot_date;
    });
    for (var key in groupedVault) {
      var groupedVaultItems = groupedVault[key];

      var itemDivs = [];
      for (var i = 0; i < groupedVaultItems.length; i++) {
        if (groupedVaultItems[i].attributes.request_date) {
          itemDivs.push(<RequestsDialogListRequestItem key={i} request={groupedVaultItems[i]} />);
        } else {
          itemDivs.push(<RequestsDialogListSwapItem key={i} swap={groupedVaultItems[i]} />);
        }
      }

      groupedDivs.push(
        <div className="items-date-group-container" key={key}>
          <div className="date-header">{moment(key).format("ddd, LL")}</div>
          {itemDivs}
        </div>
      );
    }

    if (groupedDivs.length == 0) {
      // no items, push a 'none' message
      groupedDivs.push(
        <div className="empty" key="empty">
          Empty list.
        </div>
      );
    }

    //for (var i = 0; i < this.state.filteredVault.length; i++) {
    //	itemDivs.push(<RequestsDialogListRequestItem key={i} request={this.state.filteredVault[i]} />);
    //}

    //component =
    //	<div className='items-container requests'>
    //		{itemDivs}
    //	</div>
    //}

    var classes = React.addons.classSet({
      "items-container": true,
      //'requests': true,
      //'active': this.props.active
    });

    return <div className={classes}>{groupedDivs}</div>;
  },
});

module.exports = RequestsDialogListItemsContainer;
