import { SlotTime } from "@/viewer/types/domain/slot";
import { DateTime } from "luxon";

export enum SlotTimeType {
  Default = "default",
  Custom = "custom",
}

export enum DialogStage {
  Start,
  Last,
}

export interface SlotData {
  selectedDate: DateTime;
  slotStartTime: SlotTime;
  slotStopTime: SlotTime;
  slotCallOrder: number | null;
  slotNote: string;
  addDemand: boolean;
  slotTimeType: SlotTimeType;
  assignStructureId: number | "" | null;
  templateId: number | "" | null;
  dateString: string;
  assignCompactOrDisplayName: string;
  compactOrDisplayName: string;
  reasonName: string | null | undefined;
  slotDate: Date;
}
