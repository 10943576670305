"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var Promise = require("bluebird");
// models
var Personnel = require("_lib/data/models/NakedPersonnel.js");
var Page = require("viewer/data/models/Page.js");
// componentsSlotDetail
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var SlotDetailsDialogPager = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {
      activeTab: "general",
      msgActive: false,
      msgText: "",
    };
  },
  componentDidMount: function () {},
  componentWillUnmount: function () {
    window.LbsAppData.AppContext.off(null, null, this);
  },
  /* publics */
  close: function (e) {
    this.props.close();
  },

  /* privates */

  _getPendingDiff: function (slot) {
    var pendingInfo = slot.get("pending_info");
    var changingDict = {
      emp_id: false,
      loa_reason_name: false,
      location_names: false,
    };

    var pendingType;
    if (slot.get("emp_id") === 4) {
      pendingType = "fill";
      changingDict.emp_id = true;
    }

    return {
      type: pendingType,
      changing: changingDict,
    };
  },

  _toggleMessage: function () {
    this.setState({ msgActive: !this.state.msgActive });
  },

  _handleChange: function (event) {
    this.setState({ msgText: event.target.value });
  },

  _submit: function (personnel) {
    var that = this;
    // just going to post the content to the api, and that'll send a page out
    var pageText = $(".textable-value").val();
    var page = new Page({
      content: pageText,
      recipient_id: personnel.get("emp_id"),
      pager_number: personnel.get("pager"),
    });
    page.save(null, {
      dataType: "text",
      success: function (model, response) {
        var response;
        that.props.close();

        // show a message
        window.LbsAppData.AppContext.openDialog({
          type: "message",
          message: response,
          timer: 2000,
        });
      },
      error: function () {
        that.props.close();
        window.LbsAppData.AppContext.openDialog({
          type: "message",
          message: "Sorry, the message did not send",
          timer: 2000,
        });
      },
    });

    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Submit: Page");
  },

  _getContactDetails: function (personnel) {
    var contactMobileDivs = [];
    if (personnel && personnel.get("pager")) {
      var emailToPagerLink = undefined;
      // mobile
      contactMobileDivs.push(
        <div className="contact-details-item-pager" key={"pager_call"}>
          <a href={"tel:" + personnel.get("pager")}>
            <i className="fa fa-fw fa-phone" />
            <span className="contact-details-item-title">Call</span>
          </a>
        </div>
      );
      if (personnel.get("pager_provider")) {
        contactMobileDivs.push(
          <div className="contact-details-item-pager" key={"pager_msg"}>
            <a onClick={this._toggleMessage.bind(null, this)}>
              <i className="fa fa-fw fa-comments-o" />
              <span className="contact-details-item-title">Page</span>
            </a>
          </div>
        );
      }
      if (personnel.get("email_to_pager")) {
        contactMobileDivs.push(
          <div className="contact-details-item-pager" key={"pager_email"}>
            <a href={"mailto:" + personnel.get("email_to_pager")} target="_blank">
              <i className="fa fa-fw fa-envelope-o" />
              <span className="contact-details-item-title">Email</span>
            </a>
          </div>
        );
      }
    }
    var contactDetails = undefined;
    if (contactMobileDivs.length) {
      contactDetails = (
        <div className="contact-details">
          <div className="contact-details-content-pager">{contactMobileDivs}</div>
        </div>
      );
    }
    return contactDetails;
  },

  _getMessageInput: function (personnel) {
    return (
      <div className="pager-details">
        <span className="phi-warning">Do not include PHI</span>
        <div className="textable">
          <textarea
            ref="Note"
            id="new-request-note"
            className="textable-value"
            rows={10}
            placeholder="Enter Message..."
            style={{ resize: "none" }}
            maxLength={240}
            value={this.state.msgText}
            onChange={this._handleChange}
          />
        </div>
        <div className="textable-counter" align="right">
          {this.state.msgText.length} / 240
        </div>
        <div className="textable-counter" align="right">
          <div className="right-side">
            <span className="submit" onClick={this._submit.bind(this, personnel)}>
              <i className="fa fa-check" />
              Submit
            </span>
          </div>
        </div>
      </div>
    );
  },

  _getSlotPersonnel: function (slot, personnel) {
    var styles = this.props.personnel
      ? {
          backgroundColor: this.props.personnel.get("color"),
          color: this.props.personnel.get("colorText"),
        }
      : {};

    return (
      <div className={"personnel-container current-update"} key={"personnel"}>
        <div className="personnel-name" style={{ backgroundColor: styles.backgroundColor }}>
          <span className="super-large underline" style={{ color: styles.color }}>
            {personnel ? personnel.get("display_name") : ""}
          </span>
          <span className="super-large-sub underline" style={{ color: styles.color }}>
            {personnel && personnel.get("pager") ? personnel.get("pager") : ""}
          </span>
        </div>
        {!this.state.msgActive && this._getContactDetails(personnel)}
        {this.state.msgActive && this._getMessageInput(personnel)}
      </div>
    );
  },

  /* render */
  render: function () {
    /* jshint ignore:start */
    var slot = this.props.dialogObject.data;
    let pendingInfo = slot.get("is_pending") ? this._getPendingDiff(slot) : undefined;
    var personnel = pendingInfo && pendingInfo.changing.emp_id ? this.props.personnelPending : this.props.personnel;
    var sectionPersonnel;
    let content;
    if (slot && !this.props.loading) {
      var sectionHeader;

      sectionHeader = (
        <div className="header">
          <div className="mobile-section-nav">
            <div className={"mobile-section-nav-tab active"}>Pager</div>
          </div>
        </div>
      );

      // core data
      sectionPersonnel = this._getSlotPersonnel(slot, personnel);

      content = (
        <div className="content">
          {sectionHeader}
          <div className="content-body">
            <div className="details">{sectionPersonnel}</div>
          </div>
        </div>
      );
    } else {
      content = (
        <div className="content">
          <div className="header">
            <span className="tag">{slot && slot.get("is_pending") ? "Pending" : "Scheduled"}</span>
          </div>

          <div className="content-body">
            <div className="loading-container">
              <span className="fa fa-fw fa-spin fa-spinner" />
            </div>
          </div>
        </div>
      );
    }

    var classes = React.addons.classSet({
      Dialog: true,
      SlotDetails: true,

      scheduled: true,
      pending: false,
      expanded: false,
      swop: false,

      "general-active": this.state.activeTab == "general",
      "pending-active": this.state.activeTab == "pending",
    });

    return (
      <div className={classes}>
        <div className="position-reset">
          <div
            className="close"
            onClick={this.touchProxy.bind(this, this.close, [])}
            onTouchEnd={this.touchProxy.bind(this, this.close, [])}
          >
            <i className="fa fa-close" />
          </div>

          {content}
        </div>
      </div>
    );
  },
});

module.exports = SlotDetailsDialogPager;
