import React from "react";
import classnames from "@/_lib/utils/classnames";

import "./_styles.scss";
import { REQUEST_ROW_MEMBER_CLASSNAME_PREFIX } from "@/viewer/ui/modules/grid/column/print/constants";

interface Props {
  cellClassNames: string;
  effects: React.CSSProperties;
  height: string;
  leftColText: string;
  leftHandColumnId: number;
  rowClassNames: string;
  rowIndex: number;
}

const PrintColumnRequestHeaderItem = (props: Props): JSX.Element => {
  const { cellClassNames, effects, height, leftColText, leftHandColumnId, rowClassNames, rowIndex } = props;
  const printMatrixIdentifier = `${REQUEST_ROW_MEMBER_CLASSNAME_PREFIX}${rowIndex}-${leftHandColumnId}`;

  const classes = classnames({
    ["column-print-header-wrapper"]: true,
    ["request-row-member"]: true,
    [printMatrixIdentifier]: true,
  });

  return (
    <div
      className={classes}
      style={{ height, backgroundColor: effects?.backgroundColor }}
      key={`${leftColText}-${leftHandColumnId}-request`}
    >
      <div className={rowClassNames} style={effects}>
        <div className={cellClassNames} style={effects}>
          {leftColText}
        </div>
      </div>
    </div>
  );
};

export default PrintColumnRequestHeaderItem;
