import * as React from "react";
import { DataContext, SettingsContext, ToolContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import SlotContextClues from "@/viewer/ui/modules/common/components/SlotContextClues";
import SlotDisplay from "@/viewer/ui/modules/common/components/SlotDisplay";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import SlotContainer from "@/viewer/ui/modules/common/components/SlotContainer";
import { OriginalPersonnelMap } from "@/viewer/types/viewdata";
import { DepartmentsById } from "@/viewer/types/domain/department";

interface Props {
  slot: SlotOrRequest;
  settings: SettingsContext;
  ui: UIContext;
  data: DataContext;
  tools: ToolContext;
  leftColId: string;
  isDataGroup: boolean;
  index: number;
  slotEffects: React.CSSProperties;
  originalPersonnelMap: OriginalPersonnelMap;
  departmentsById: DepartmentsById;
}

const StandardSlot = (props: Props): JSX.Element => {
  const {
    slot,
    settings,
    ui,
    data,
    tools,
    leftColId,
    isDataGroup,
    index,
    slotEffects,
    originalPersonnelMap,
    departmentsById,
  } = props;

  const slots = React.useMemo(() => [slot], [slot]);

  return (
    <SlotContainer
      settings={settings}
      ui={ui}
      data={data}
      tools={tools}
      slots={slots}
      departmentsById={departmentsById}
      className={"StandardSlot"}
    >
      <SlotDisplay
        slot={slot}
        settings={settings}
        ui={ui}
        leftColId={leftColId}
        originalPersonnelMap={originalPersonnelMap}
        departmentsById={departmentsById}
      />
      <SlotContextClues
        slot={slot}
        settings={settings}
        ui={ui}
        data={data}
        tools={tools}
        isDataGroup={isDataGroup}
        cellType={index % 2}
        slotEffects={slotEffects}
      />
    </SlotContainer>
  );
};

export default StandardSlot;
