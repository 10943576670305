const Backbone = require("backbone");
const _ = require("lodash");
const moment = require("moment");

const FiddleDeadConfig = require("_lib/data/FiddleDeadConfig.js");
const FeedItem = require("./../models/FeedItem.js");

const FeedItemCollection = Backbone.Collection.extend({
  model: FeedItem,
  comparator: function (a, b) {
    // descending order on timestamp field
    const a_time = a.attributes.timestamp;
    const b_time = b.attributes.timestamp;

    if (a_time < b_time) {
      return 1;
    } else if (a_time > b_time) {
      return -1;
    } else {
      // go by the targeted date
      if (_.has(a.attributes.message_args, "d") && _.has(b.attributes.message_args, "d")) {
        const a_time_2 = a.attributes.message_args["d"];
        const b_time_2 = b.attributes.message_args["d"];

        if (a_time_2 < b_time_2) {
          return 1;
        } else if (a_time_2 > b_time_2) {
          return -1;
        } else {
          // same?
        }
      }

      // just... whatever
      return 0;
    }
  },

  initialize: function (models, attributes) {
    this.attributes = {};
    for (const key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    let stamp;

    if (this.attributes.last_stamp) {
      stamp = this.attributes.last_stamp;
    } else {
      // look back 1 year
      stamp = moment().subtract(1, "year").startOf("day").unix();
    }
    // save the current time as the next stamp
    this.attributes.last_stamp = moment().unix();

    // base url
    const customerId = window.LbsAppData.User.get("customer_id");
    let base;

    if (this.attributes.emp_id) {
      base = FiddleDeadConfig.getPrefix() + "/employee_feed/" + customerId + "/" + this.attributes.emp_id;
    } else if (this?.attributes?.departments?.length) {
      // department
      base =
        FiddleDeadConfig.getPrefix() + "/department_feed/" + customerId + "/" + this.attributes.departments.join("~");
    }

    // query
    const queryParams = {};
    queryParams["last"] = stamp;

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    return (
      base +
      "?" +
      _.map(cleanQueryParams, function (value, key) {
        return key + "=" + value;
      }).join("&")
    );
  },

  parse: function (response) {
    // overriding this method to only show 'preswap' messages for now
    const ret = [];

    _.each(response.data, function (item) {
      if (item.type.indexOf("preswap") > -1) {
        ret.push(item);
      }
    });

    return ret;
  },

  clearStamp: function () {
    this.attributes.last_stamp = undefined;
  },
});

module.exports = FeedItemCollection;
