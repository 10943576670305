/* eslint-disable @typescript-eslint/no-var-requires */
// Manager to wrap calls to the Permissions model methods cleanly
// eslint-disable-next-line no-undef
const _ = require("underscore");

// eslint-disable-next-line no-undef
const PermissionsHelper = Backbone.Model.extend({
  initialize: function () {
    // eslint-disable-next-line no-undef
    this.p = window.LbsAppData.Permissions;
  },

  BasePermsExist: function () {
    if (!this.p?.get("FEATURE") || !this.p?.get("LEVEL")) {
      return false;
    }

    return true;
  },

  // this action is slot based
  CanIApproveDenySwap: function (slot) {
    if (!this.BasePermsExist()) {
      return false;
    }

    const assessDenialPermsByEmpIdAndEmpType = (empId, empType) =>
      (this.p.hasAccess &&
        this.p.hasAccess(
          this.p.get("FEATURE")["SCHEDULE_ALLOWDENY"],
          slot.get("template"),
          empId,
          empType,
          slot.get("assign_atype"),
          slot.get("assign_id"),
          this.p.get("my_depts"),
          slot.get("department_id"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false;

    let canApproveDeny = assessDenialPermsByEmpIdAndEmpType(slot.get("emp_id"), slot.get("emp_ptype"));

    if (!canApproveDeny) {
      // one more shot...this time with the pending emp_id
      canApproveDeny = assessDenialPermsByEmpIdAndEmpType(
        slot.get("pending_emp_id"),
        slot.get("pending_info")["emp_ptype"]
      );
    }

    // only admins can do this for slots that are pending because of actions created themselves
    if (canApproveDeny) {
      canApproveDeny =
        canApproveDeny &&
        (this.p.get("is_admin") || slot.get("pending_info")["modified_by_emp_id"] !== this.p.get("emp_id"));
    }

    return canApproveDeny;
  },

  // this action is request based
  CanIApproveDenyRequest: function (request) {
    if (!this.BasePermsExist()) {
      return false;
    }

    let canApproveDeny =
      (this.p.hasAccess &&
        this.p.hasAccess(
          this.p.get("FEATURE")["REQUEST_ALLOWDENY"],
          request.get("template"),
          request.get("emp_id"),
          request.get("emp_ptype"),
          request.get("assign_atype"),
          request.get("assign_id"),
          this.p.get("my_depts"),
          request.get("department_id"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false;

    // this isn't enough for the non-persona case..because the REQUEST_ALLOWDENY permission doesn't exist...so admin bit comes into play
    if (canApproveDeny && this.p.get("uses_intersections") === false) {
      canApproveDeny = canApproveDeny && (this.p.get("is_admin") || request.get("emp_id") !== this.p.get("emp_id"));
    }

    return canApproveDeny;
  },

  // this action is slot based
  // need to eval for the situation where we would be chaning emp id's...that might allow the action to happen
  CanICreateSwap: function (slot, otherEmpID) {
    if (!this.BasePermsExist()) {
      return false;
    }

    const assessAccessByEmpId = (empId) =>
      (this.p.hasAccess &&
        this.p.hasAccess(
          this.p.get("FEATURE")["SCHEDULE"],
          slot.get("template"),
          empId,
          slot.get("emp_ptype"),
          slot.get("assign_atype"),
          slot.get("assign_id"),
          this.p.get("my_depts"),
          slot.get("department_id"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false;

    let canCreateSwap = assessAccessByEmpId(slot.get("emp_id"));

    if (!canCreateSwap && otherEmpID !== undefined) {
      canCreateSwap = assessAccessByEmpId(otherEmpID);
    }

    return canCreateSwap;
  },

  // this action is slot based...sort of...its more tied to who made the swap though..and who you are within context of that
  // no need to go to the permissions model for this one
  CanIDeleteSwap: function (slot) {
    if (this.p.get("is_admin") || slot.get("pending_info")["modified_by_emp_id"] === this.p.get("emp_id")) {
      return true;
    }

    return false;
  },

  // this action is request based
  CanIDeleteRequest: function (request) {
    if (!this.BasePermsExist()) {
      return false;
    }

    let canDelete =
      (this.p.hasAccess &&
        this.p.hasAccess(
          this.p.get("FEATURE")["REQUEST_DELETE"],
          request.get("template"),
          request.get("emp_id"),
          request.get("emp_ptype"),
          request.get("assign_atype"),
          request.get("assign_id"),
          this.p.get("my_depts"),
          request.get("department_id"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false;

    // this isn't enough for the non-persona case..because the REQUEST_DELETE permission doesn't exist...so admin bit comes into play
    if (canDelete && this.p.get("uses_intersections") === false) {
      canDelete =
        canDelete &&
        (this.p.get("is_admin") ||
          (request.get("emp_id") === this.p.get("emp_id") && request.get("status") !== "denied"));
    }

    return canDelete;
  },

  // this action is slot based
  CanIChangeSlotDetails: function (slot) {
    return (
      this.CanIChangeSlotNotes(slot) || this.CanIChangeSlotLOAReason(slot) || this.CanIChangeSlotLocationOrTime(slot)
    );
  },

  // this action is slot based
  CanIChangeSlotNotes: function (slot) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccess &&
        this.p.hasAccess(
          this.p.get("FEATURE")["SCHEDULE_NOTES"],
          slot.get("template"),
          slot.get("emp_id"),
          slot.get("emp_ptype"),
          slot.get("assign_atype"),
          slot.get("assign_id"),
          this.p.get("my_depts"),
          slot.get("department_id"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },

  // this action is not slot based
  CanIChangeFillNotes: function (assignment, employee, template) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccess &&
        this.p.hasAccess(
          this.p.get("FEATURE")["SCHEDULE_NOTES"],
          template.get("name_full"),
          employee.id,
          employee.get("ptype_id"),
          assignment._getAssignmentTypeForTemplate(template.id),
          assignment._getAssignmentIDForTemplate(template.id),
          this.p.get("my_depts"),
          template.get("department_id"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },

  // this action is not slot based
  CanIChangeRequestNotes: function (assignment, employee, template) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccess &&
        this.p.hasAccess(
          this.p.get("FEATURE")["REQUEST_NOTES"],
          template.get("name_full"),
          employee.id,
          employee.get("ptype_id"),
          assignment._getAssignmentTypeForTemplate(template.id),
          assignment._getAssignmentIDForTemplate(template.id),
          this.p.get("my_depts"),
          template.get("department_id"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },

  // this action is partially slot based
  CanIChangeSwapPersonnelNotes: function (slot, employee) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccess &&
        this.p.hasAccess(
          this.p.get("FEATURE")["SCHEDULE_NOTES"],
          slot.get("template"),
          employee.id,
          employee.get("ptype_id"),
          slot.get("assign_atype"),
          slot.get("assign_id"),
          this.p.get("my_depts"),
          slot.get("department_id"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },

  // this action is partially slot based
  CanIChangeSwapAssignmentNotes: function (slot, assignment, template) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccess &&
        this.p.hasAccess(
          this.p.get("FEATURE")["SCHEDULE_NOTES"],
          template.get("name_full"),
          slot.get("emp_id"),
          slot.get("emp_ptype"),
          assignment._getAssignmentTypeForTemplate(template.id),
          assignment._getAssignmentIDForTemplate(template.id),
          this.p.get("my_depts"),
          template.get("department_id"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },

  // this action is slot based
  CanIChangeSlotLOAReason: function (slot) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccess &&
        this.p.hasAccess(
          this.p.get("FEATURE")["SCHEDULE_LOA"],
          slot.get("template"),
          slot.get("emp_id"),
          slot.get("emp_ptype"),
          slot.get("assign_atype"),
          slot.get("assign_id"),
          this.p.get("my_depts"),
          slot.get("department_id"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },
  // this action is slot based
  CanIChangeRequestLOAReason: function (slot) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccess &&
        this.p.hasAccess(
          this.p.get("FEATURE")["REQUESTS_LOA"],
          slot.get("template"),
          slot.get("emp_id"),
          slot.get("emp_ptype"),
          slot.get("assign_atype"),
          slot.get("assign_id"),
          this.p.get("my_depts"),
          slot.get("department_id"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },

  // this action is slot based
  CanIChangeSlotLocationOrTime: function (slot) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccess &&
        this.p.hasAccess(
          this.p.get("FEATURE")["SCHEDULE"],
          slot.get("template"),
          slot.get("emp_id"),
          slot.get("emp_ptype"),
          slot.get("assign_atype"),
          slot.get("assign_id"),
          this.p.get("my_depts"),
          slot.get("department_id"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },

  // a different kind of check, needs to look across all accessible templates to see if I have write access in at least one of em
  CanIChangeThisPersonsSchedule: function (employee) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccessPType &&
        this.p.hasAccessPType(
          this.p.get("FEATURE")["SCHEDULE"],
          employee.id,
          employee.get("ptype_id"),
          employee.get("departments"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },

  // a different kind of check, needs to look across all accessible templates to see if I have write access in at least one of em
  CanIChangeThisPersonsRequests: function (employee) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccessPType &&
        this.p.hasAccessPType(
          this.p.get("FEATURE")["REQUESTS"],
          employee.id,
          employee.get("ptype_id"),
          employee.get("departments"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },

  // a different kind of check, needs to look across all accessible templates to see if I have write access in at least one of em
  CanIChangeThisPersonsLOAReasons: function (employee) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccessPType &&
        this.p.hasAccessPType(
          this.p.get("FEATURE")["SCHEDULE_LOA"],
          employee.id,
          employee.get("ptype_id"),
          employee.get("departments"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },

  // a different kind of check, needs to look across all accessible templates to see if I have write access in at least one of em
  CanIChangeThisPersonsRequestLOAReasons: function (employee) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccessPType &&
        this.p.hasAccessPType(
          this.p.get("FEATURE")["REQUESTS_LOA"],
          employee.id,
          employee.get("ptype_id"),
          employee.get("departments"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },

  CanIChangeAnyoneElsesSchedule: function () {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccessAnyOtherPersonnel &&
        this.p.hasAccessAnyOtherPersonnel(this.p.get("FEATURE")["SCHEDULE"], this.p.get("LEVEL")["WRITE"])) ??
      false
    );
  },

  CanIChangeAnyonesSchedule: function () {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccessAnyPersonnel &&
        this.p.hasAccessAnyPersonnel(this.p.get("FEATURE")["SCHEDULE"], this.p.get("LEVEL")["WRITE"])) ??
      false
    );
  },

  CanIChangeAnyoneElsesRequests: function () {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccessAnyOtherPersonnel &&
        this.p.hasAccessAnyOtherPersonnel(this.p.get("FEATURE")["REQUESTS"], this.p.get("LEVEL")["WRITE"])) ??
      false
    );
  },

  CanIChangeAnyonesRequests: function () {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccessAnyPersonnel &&
        this.p.hasAccessAnyPersonnel(this.p.get("FEATURE")["REQUESTS"], this.p.get("LEVEL")["WRITE"])) ??
      false
    );
  },

  CanIChangeTemplateScheduleData: function (template) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccessTemplate &&
        this.p.hasAccessTemplate(
          this.p.get("FEATURE")["SCHEDULE"],
          template.get("name_full"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },

  CanIChangeTemplateRequestData: function (template) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccessTemplate &&
        this.p.hasAccessTemplate(
          this.p.get("FEATURE")["REQUESTS"],
          template.get("name_full"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },

  CanIChangeAssignmentOnScheduleForTemplate: function (assignment, template) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccessATypeOnTemplate &&
        this.p.hasAccessATypeOnTemplate(
          this.p.get("FEATURE")["SCHEDULE"],
          assignment._getAssignmentTypeForTemplate(template.id),
          assignment._getAssignmentIDForTemplate(template.id),
          template.get("department_id"),
          template.get("name_full"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },

  CanIChangeAssignmentOnRequestsForTemplate: function (assignment, template) {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccessATypeOnTemplate &&
        this.p.hasAccessATypeOnTemplate(
          this.p.get("FEATURE")["REQUESTS"],
          assignment._getAssignmentTypeForTemplate(template.id),
          assignment._getAssignmentIDForTemplate(template.id),
          template.get("department_id"),
          template.get("name_full"),
          this.p.get("LEVEL")["WRITE"]
        )) ??
      false
    );
  },

  CanIExchangeTheseSlots: function (slots) {
    const groupedSlots = _.groupBy(slots, (slot) => slot.get("emp_id"));
    const empIds = _.keys(groupedSlots);

    // attempt to find a false -- in which case we cannot exchange
    if (empIds.length === 2) {
      return !!_.find(
        slots,
        (slot) => {
          const otherP = parseInt(_.without(empIds, slot.get("emp_id").toString())[0], 10);

          return slot.get("is_pending") || this.CanICreateSwap(slot, otherP);
        },
        this
      );
    }

    return false;
  },

  CanIApproveDenyAnyoneElsesSchedule: function () {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccessAnyPersonnel &&
        this.p.hasAccessAnyPersonnel(this.p.get("FEATURE")["SCHEDULE_ALLOWDENY"], this.p.get("LEVEL")["WRITE"])) ??
      false
    );
  },

  CanIApproveDenyAnyoneElsesRequest: function () {
    if (!this.BasePermsExist()) {
      return false;
    }

    return (
      (this.p.hasAccessAnyPersonnel &&
        this.p.hasAccessAnyPersonnel(this.p.get("FEATURE")["REQUEST_ALLOWDENY"], this.p.get("LEVEL")["WRITE"])) ??
      false
    );
  },
});

// eslint-disable-next-line no-undef
module.exports = PermissionsHelper;
