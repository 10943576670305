import { ToolContext } from "@/viewer/ui/modules/common/types/context";
import { SlotAttributes } from "@/viewer/types/domain/slot";
import { Collection, Model } from "backbone";
import { RequestAttributes } from "@/viewer/types/domain/request";
import connectedPropertyHookFactory from "@/viewer/ui/modules/common/hooks/connectedPropertyHookFactory";

const getToolContext = (): ToolContext => {
  const { LbsAppData } = window as any;

  const Slots: Collection<Model<SlotAttributes>> = LbsAppData.Slots || [];
  const Requests: Collection<Model<RequestAttributes>> = LbsAppData.Requests || [];
  const ctx: ToolContext = { toolEffectsBySlotUUID: {} };

  Slots.forEach((item) => {
    const tool = item.get("boundTool");

    if (tool) {
      ctx.toolEffectsBySlotUUID[item.attributes.slot_uuid ?? ""] = {
        className: tool.extClasses ? tool.extClasses() : "",
        style: tool.style(),
      };
    }
  });

  Requests.forEach((item) => {
    const tool = item.get("boundTool");

    if (tool) {
      ctx.toolEffectsBySlotUUID[item.attributes.request_id?.toFixed(0) ?? ""] = {
        className: tool.extClasses ? tool.extClasses() : "",
        style: tool.style(),
      };
    }
  });

  return ctx;
};

export default connectedPropertyHookFactory({
  loader: getToolContext,
  getListeners: () => {
    const { LbsAppData } = window as any;
    return [
      [LbsAppData.Slots, "change:boundTool"],
      [LbsAppData.Requests, "change:boundTool"],
    ];
  },
});
