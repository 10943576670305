import React from "react";

import classnames from "@/_lib/utils/classnames";
import useDateHeaderCallbacks from "@/viewer/ui/modules/common/hooks/useDateHeaderCallbacks";
import { isToday, isWeekend } from "date-fns";
import { formatStandardDay, formatUSDate } from "@/viewer/utils/dateFormatters";
import { SettingsContext } from "@/viewer/ui/modules/common/types/context";

import holidaySvg from "@/_lib/ui/images/holiday_icon.svg";

interface Props {
  settings: SettingsContext;
  date: Date;
  isHoliday: boolean;
  isDateInSchedule: boolean;
}

const ColumnHeaderCell = (props: Props): JSX.Element => {
  const { settings, date, isDateInSchedule, isHoliday } = props;

  const headClassList = classnames("cell", {
    "cell--condensed": settings.condenseColumnView,
    "cell--weekend": isWeekend(date),
    "cell--notInSchedule": !isDateInSchedule,
    "cell--today": isToday(date),
    "cell--holiday": isHoliday,
  });

  //Adding the holidaySvg as an image tag is preferable to managing
  //multiple instances across several CSS files and makes manipulation of
  //the svg easier
  return (
    <div className={headClassList} data-date={formatUSDate(settings, date)} {...useDateHeaderCallbacks(settings, date)}>
      <div className="cell__header--container">
        {isHoliday && !settings.condenseColumnView ? <img src={holidaySvg} /> : null}
        <span>{formatStandardDay(settings, date)}</span>
      </div>
    </div>
  );
};

export default ColumnHeaderCell;
