import "./_main.scss";

import * as React from "react";
import useViewData from "@/viewer/ui/modules/common/hooks/useViewData";
import useSettings from "@/viewer/ui/modules/common/hooks/useSettings";

import TopBar from "@/_lib/ui/modules/topbar/TopBar";
import useUIContext from "@/viewer/ui/modules/common/hooks/useUIContext";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const SplashScreen = require("./modules/splash/SplashScreen.jsx");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const SelectionScreen = require("./modules/selection/SelectionScreen.jsx");
// eslint-disable-next-line @typescript-eslint/no-var-requires
// const DialogContainer = require("./modules/dialog/DialogContainer.jsx");
import DialogContainer from "./modules/dialog/DialogContainer";

interface Props {
  loggedIn: boolean;
}

const setDocumentTitle = () => {
  document.title = "Lightning Bolt Login";
};

const logout = () => {
  const { LbsAppData } = window as any;
  LbsAppData.Helpers.Analytics.sendEvent("Sign Out", LbsAppData.User.attributes.emp_id.toString());

  window.location.href = "/logout";
};

const LbsLoginApp = (props: Props): JSX.Element => {
  const { loggedIn } = props;
  const settings = useSettings();
  const [ui, setUIContext] = useUIContext();
  const viewData = useViewData(settings);

  React.useEffect(setDocumentTitle, []);

  const renderTopBar = (): JSX.Element => {
    return (
      <>
        <TopBar
          key="topbar"
          appName="login"
          settings={settings}
          ui={ui}
          setUIContext={setUIContext}
          viewData={viewData}
          signOut={logout}
        />
        <SelectionScreen key="selection_screen" logout={logout} />
      </>
    );
  };

  return (
    <div id="LbsLoginApp">
      <DialogContainer key="dialog_container" />
      {loggedIn ? renderTopBar() : <SplashScreen key="splash_screen" />}
    </div>
  );
};

export default LbsLoginApp;
