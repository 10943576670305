const Backbone = require("backbone");
const React = require("react/addons");
const _ = require("underscore");
const moment = require("moment");

const FeedItem = Backbone.Model.extend({
  // User model..but we get most (all) of this data from the permissions endpoint
  //idAttribute: "timestamp",

  initialize: function () {},

  _isPending: function () {
    const types = this.get("type").split(" ");
    return _.indexOf(types, "pending") > -1 ? true : false;
  },

  getIcon: function () {
    const types = this.get("type").split(" ");

    const icons = [<i className="fa fa-fw fa-stack-2x fa-2x fa-square" key="1" />];

    switch (types[0]) {
      case "new":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-paper-plane" key="2" />);
        break;
      case "delete":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-times" key="2" />);
        break;
      case "grant":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-thumbs-o-up" key="2" />);
        break;
      case "deny":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-thumbs-o-down" key="2" />);
        break;
      case "make_pending":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-meh-o" key="2" />);
        break;
      case "fill":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-sign-in" key="2" />);
        break;
      case "add_as_needed":
      case "clear":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-sign-out" key="2" />);
        break;
      case "replace_personnel":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-exchange" key="2" />);
        break;
      case "replace_assignment":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-exchange" key="2" />);
        break;
      case "details":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-edit" key="2" />);
        break;
      case "grant_swap":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-thumbs-o-up" key="2" />);
        break;
      case "deny_swap":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-thumbs-o-down" key="2" />);
        break;
      case "delete_swap":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-times" key="2" />);
        break;
      case "preswap_create":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-rss" key="2" />);
        break;
      case "preswap_accept":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-user-plus" key="2" />);
        break;
      case "preswap_deny":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-user-times" key="2" />);
        break;
      case "preswap_replacefailure":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-thumbs-o-down" key="2" />);
        break;
      case "preswap_approvefailure":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-thumbs-o-down" key="2" />);
        break;
      case "preswap_finalized":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-hand-o-right" key="2" />);
        break;
      case "preswap_delete":
        icons.push(<i className="fa fa-fw fa-stack-1x fa-times" key="2" />);
        break;
      default:
        break;
    }

    return icons;
  },

  getTime: function () {
    // need to make this locale-aware
    return moment(this.get("timestamp")).format("h:mma");
  },

  getActionText: function () {
    const regex = /(.*):/;
    const matches = regex.exec(this.get("message"));

    return matches[1];
  },

  getMessage: function () {
    // get the message text (substituting the arguments)
    let message = this.get("message");
    _.each(this.get("message_args"), function (value, key) {
      let massagedValue = value;

      switch (key) {
        case "d":
          massagedValue = "<u>" + moment(value, "YYYYMMDD").format("ddd, ll") + "</u>";
          break;

        default:
          massagedValue = "<u>" + massagedValue + "</u>";
          break;
      }

      message = message.replace("$" + key + "$", massagedValue); // replace the strings
    });

    return !this._isPending() ? message.replace(/(.*):/, "") : message.replace(/(.*):/, "PENDING: "); // replace the action text
  },
});

module.exports = FeedItem;
