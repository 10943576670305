import { Action } from "./actions";
import { Assignment } from "@/viewer/types/domain/assignment";
import { Personnel } from "@/viewer/types/domain/personnel";

export interface State {
  assignmentFilterIds: Assignment["assignStructureId"][];
  personnelFilterIds: Personnel["empId"][];
}

export const initialState = (): State => ({
  assignmentFilterIds: [],
  personnelFilterIds: [],
});

const reducer = (state: State | undefined, action: Action): State => {
  if (!state) {
    return reducer(initialState(), action);
  }

  switch (action.type) {
    case "assignment-filter-list/update":
      return { ...state, assignmentFilterIds: action.assignmentFilterIds };
    case "personnel-filter-list/update":
      return { ...state, personnelFilterIds: action.personnelFilterIds };
  }

  return state;
};

export default reducer;
