import * as React from "react";
import { Slot } from "@/viewer/types/domain/slot";
import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";

export type SlotMap = { [k: string]: Slot[] };

const useScheduledMap = (settings: SettingsContext, ui: UIContext, slots: Slot[]): SlotMap => {
  return React.useMemo(() => {
    const out: { [k: string]: Slot[] } = {};
    slots.forEach((slot) => {
      const { dateString } = slot;
      if (!out[dateString]) {
        out[dateString] = [];
      }

      out[dateString].push(slot);
    });

    return out;
  }, [slots]);
};

export default useScheduledMap;
