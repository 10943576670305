import { AppAction } from "@/modules/types";

export interface State {
  initialized: boolean;
  nextUrl?: string;
}

export default (state: State = { initialized: false }, action: AppAction) => {
  switch (action.type) {
    case "app/init":
      return { initialized: true };
    case "app/logout":
      return { initialized: false, nextUrl: action.nextUrl };
  }

  return state;
};
