"use strict";

var classnames = require("_lib/utils/classnames").default;

var React = require("react/addons");
var $ = require("jquery");

var ActionIcon = React.createClass({
  /* lifecycle methods */
  getInitialState: function () {
    return {
      enabled: false,
    };
  },
  componentDidMount: function () {
    // register to AppContext tool change
    //window.LbsAppData.ToolBox.on('change:currentTool', this.forceUpdate.bind(this, null), this);

    // register with the AppContext model
    window.LbsAppData.AppContext.on("enable:" + this.props.data.type, this.enableIcon.bind(this, null), this);
    window.LbsAppData.AppContext.on("disable:" + this.props.data.type, this.disableIcon.bind(this, null), this);
  },
  componentWillUnmount: function () {
    //window.LbsAppData.ToolBox.off(null, null, this);

    window.LbsAppData.AppContext.off(null, null, this);
  },
  componentWillUpdate: function () {
    //window.LbsAppData.ToolBox.off(null, null, this);
  },
  componentDidUpdate: function () {
    //window.LbsAppData.ToolBox.on('change:currentTool', this.forceUpdate.bind(this, null), this);
  },

  /* publics */
  enableIcon: function (e) {
    this.setState({ enabled: true });
  },
  disableIcon: function (e) {
    this.setState({ enabled: false });
  },

  /* privates */
  _handleClick: function (e) {
    this.props.onClick(this.props.data);
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = classnames("ToolBoxIcon", {
      active: this.props.active,
      disabled: !this.state.enabled,
    });

    return (
      <div className={classes} onClick={this.state.enabled ? this._handleClick : undefined}>
        <i className={"fa fa-fw " + this.props.icon} />

        <div className="tool-tip">
          <div className="triangle"></div>

          <div className="message">{this.props.tooltip}</div>
        </div>
      </div>
    );

    /*
		<ToolBoxMenu currentTool={currentTool} />
		{currentToolComponent}
		*/
  },
});

module.exports = ActionIcon;
