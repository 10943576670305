export enum ExoticRules {
  AtPage = "AtPage",
  AtPageSize = "AtPageSize",
}
/**
 * String valued enum which holds non-standardized CSS
 * rules. These values must be valid CSS rules
 * or else validation will not work and browser parsing
 * of the CSS rule will throw an exception.
 */
const NonStandardStyles = {
  [ExoticRules.AtPage]: "@page {}",
  [ExoticRules.AtPageSize]: "@page {size: letter landscape;}",
};

export const NoSupportedStyles = "NONE";
export const ExoticStylesCookieName = "supports_exotic_styles";

export default NonStandardStyles;
