import React, { useState } from "react";
import moment from "moment";

import { UIContext, SettingsContext, ViewDataType } from "@/viewer/ui/modules/common/types/context";
import { cleanRequestAttributes, Request } from "@/viewer/types/domain/request";
import { cleanSlotAttributes, Slot } from "@/viewer/types/domain/slot";
import { ViewData } from "@/viewer/types/viewdata";
import { exportCurrentView, exportListView } from "@/_lib/utils/exportToCsv";
import fetchScheduleData from "@/_lib/hooks/fetchScheduleData";

import { filterSlotsOutsideDateRange, filterRequestsOutsideDateRange } from "@/common/utils/helpers";

import CsvDownload from "../CsvDownload";
import Dialog from "./Dialog";
import useAssignmentTypesById from "@/viewer/ui/modules/common/hooks/useAssignmentTypesById";
import usePersonnelTypesById from "@/viewer/ui/modules/common/hooks/usePersonnelTypesById";
import useAssignmentsById from "@/viewer/ui/modules/common/hooks/useAssignmentsById";
import usePersonnelById from "@/viewer/ui/modules/common/hooks/usePersonnelById";
import useTemplatesById from "@/viewer/ui/modules/common/hooks/useTemplatesById";
import useDepartmentsById from "@/viewer/ui/modules/common/hooks/useDepartmentsById";
import useCurrentlyLoggedInUser from "@/viewer/ui/modules/common/hooks/useCurrentlyLoggedInUser";

interface Props {
  settings: SettingsContext;
  ui: UIContext;
  viewData: ViewData;
  searchTerms: string[];
}

interface DateRange {
  endDate: Date;
  startDate: Date;
}

const ExportDialog = (props: Props): JSX.Element => {
  const { settings, ui, viewData, searchTerms } = props;
  const { columnNames, layout, viewName, viewId } = settings;

  const assignmentTypesById = useAssignmentTypesById();
  const personnelTypesById = usePersonnelTypesById();
  const assignmentsById = useAssignmentsById(settings);
  const personnelById = usePersonnelById(settings);
  const templatesById = useTemplatesById();
  const departmentsById = useDepartmentsById();
  const user = useCurrentlyLoggedInUser();
  const searchCorpusParams = {
    assignmentsById,
    assignmentTypesById,
    columnNames,
    departmentsById,
    personnelById,
    personnelTypesById,
    settings,
    templatesById,
    ui,
  };

  const [requestData, setRequestData] = useState<Request[]>([]);
  const [shouldShowDownload, setShouldShowDownload] = useState<boolean>(false);
  const [slotData, setSlotData] = useState<Slot[]>([]);
  const [startDate, setStartDate] = useState<Date>(ui.startDate);
  const [stopDate, setStopDate] = useState<Date>(ui.stopDate);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fileName = (startDate: Date, stopDate: Date) => {
    const formattedStartDate = moment(startDate).utc().local(false).format("MM-DD-YY");
    const formattedStopDate = moment(stopDate).utc().local(false).format("MM-DD-YY");

    if (viewName) {
      return `${viewName} - ${formattedStartDate} to ${formattedStopDate}.csv`;
    }

    return "";
  };

  const constructAndFilterSlotData = (slots: Backbone.Collection, dateRange: DateRange): Slot[] => {
    const { endDate, startDate } = dateRange;

    const slotsData: Slot[] = slots
      .map((slot) => cleanSlotAttributes(settings, slot.attributes, searchCorpusParams))
      .filter((slot) => !slot.isPending);

    const slotsWithinRequestedRange = filterSlotsOutsideDateRange({
      endDate,
      startDate,
      slots: slotsData,
    });

    return slotsWithinRequestedRange;
  };

  const consturctAndFilterRequestData = (
    slotsData: Backbone.Collection,
    requests: Backbone.Collection,
    dateRange: DateRange
  ): Request[] => {
    const { endDate, startDate } = dateRange;

    const slotsDataComparator = slotsData.map((slot: any) =>
      JSON.stringify({
        assign: slot.assignCompactOrDisplayName,
        name: slot.displayName,
        date: slot.dateString,
      })
    );

    const requestData: Request[] = requests
      .map((requestModel) => cleanRequestAttributes(settings, requestModel.attributes, searchCorpusParams))
      .filter(
        (request) =>
          !slotsDataComparator.some((slotData) => {
            const stringifiedRequestData = JSON.stringify({
              assign: request.assignCompactOrDisplayName,
              name: request.displayName,
              date: request.dateString,
            });

            return slotData === stringifiedRequestData;
          })
      );

    const requestsWithinRequestedRange = filterRequestsOutsideDateRange({
      endDate,
      startDate,
      requests: requestData,
    });

    return requestsWithinRequestedRange;
  };

  const hydrateStateAndAllowDownload = (
    slots: Backbone.Collection,
    requests: Backbone.Collection,
    { startDate, endDate }: DateRange
  ) => {
    const { viewDataType } = settings;

    let slotData: Slot[] = [];
    let requestData: Request[] = [];

    if (viewDataType === ViewDataType.schedule || viewDataType === ViewDataType.combined) {
      slotData = constructAndFilterSlotData(slots, { startDate, endDate });
    }

    if (viewDataType === ViewDataType.request || viewDataType === ViewDataType.combined) {
      requestData = consturctAndFilterRequestData(slots, requests, { startDate, endDate });
    }

    slotData.length && setSlotData(slotData);
    requestData.length && setRequestData(requestData);
    setShouldShowDownload(true);
  };

  const fetchDataAndHydrateState = async (startDate: Date, endDate: Date) => {
    try {
      const startDateWithPadding = moment(startDate).subtract(1, "day");
      const endDateWithPadding = moment(endDate).add(1, "day");

      const { slots, requests } = await fetchScheduleData(
        settings,
        startDateWithPadding.toDate(),
        endDateWithPadding.toDate()
      );

      hydrateStateAndAllowDownload(slots, requests, { startDate, endDate });
    } catch ({ message }) {
      //eslint-disable-next-line no-console
      console.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleActionClick = async (startDate: Date, endDate: Date) => {
    setStartDate(startDate);
    setStopDate(endDate);
    setIsLoading(true);
    await fetchDataAndHydrateState(startDate, endDate);
  };

  const doneCallback = (): void => {
    setShouldShowDownload(false);
  };

  const isDialogEnabled = () => !["gantt", "block"].includes(layout) && viewId !== "me" && viewId !== "today";

  return (
    <Dialog
      ui={ui}
      icon="fa-cloud-download"
      actionText="Export"
      actionOnClick={handleActionClick}
      loading={isLoading}
      enabled={isDialogEnabled()}
    >
      {shouldShowDownload && (
        <CsvDownload
          filename={fileName(startDate, stopDate)}
          getData={() =>
            layout === "list"
              ? exportListView({
                  assignmentsById,
                  assignmentTypesById,
                  departmentsById,
                  filteredRequestData: requestData,
                  filteredSlotData: slotData,
                  personnelById,
                  personnelTypesById,
                  searchTerms,
                  settings,
                  startAndStopDates: { start: startDate, stop: stopDate },
                  templatesById,
                  ui,
                  user,
                })
              : exportCurrentView({
                  filteredRequestData: requestData,
                  filteredSlotData: slotData,
                  startAndStopDates: {
                    start: moment(startDate).subtract(1, "day").local(),
                    stop: moment(stopDate).local(),
                  },
                  ui,
                  viewData,
                })
          }
          doneCallback={doneCallback}
        />
      )}
    </Dialog>
  );
};

export default ExportDialog;
