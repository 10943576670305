import React from "react";
import MomentUtils from "@date-io/moment";
import { Provider } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import store from "../store";
import muiTheme from "@/common/constants/mui-theme";
import AppLoader from "@/_lib/AppLoader";
import LbsLoginApp from "@/login/ui/LbsLoginApp";
import ModalWrapper from "@/common/components/modal/ModalWrapper";
import ToastWrapper from "@/common/components/toast/ToastWrapper";
import Pendo from "@/_lib/Pendo";
import EngageCookie from "@/_lib/EngageCookie";
import withLDProviderUserInjection from "@/viewer/LDProvider";
import myWindow from "@/_lib/utils/window";
import { getLDUserContext, LDUserContextConfig } from "@/_lib/utils/launchDarklyHelper";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const SplashScreen = require("./ui/modules/splash/SplashScreen.jsx");

const App = (): JSX.Element => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AppLoader
            onLoad={() => {
              const LDUserContext: LDUserContextConfig | undefined = getLDUserContext(
                myWindow.LbsAppData?.User?.attributes
              );
              const LDUserInjectionProvider = withLDProviderUserInjection(LDUserContext)(() => {
                const { payload } = window as any;
                if (payload.logged_in) {
                  return (
                    <>
                      <LbsLoginApp loggedIn={payload.logged_in} />
                      <Pendo />
                      <EngageCookie />
                      <ModalWrapper />
                      <ToastWrapper />
                    </>
                  );
                } else {
                  return <SplashScreen key="splash_screen" />;
                }
              });

              return <LDUserInjectionProvider />;
            }}
          />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Provider>
  );
};

export default App;
