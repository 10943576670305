import { toDate } from "date-fns-tz";

export interface TemplateAttributes {
  available: boolean;
  department_id: number;
  filtered: boolean;
  first_published_date: string;
  is_hidden: boolean;
  last_published_date: string;
  name: string;
  name_full: string;
  request_horizon_end: string;
  request_horizon_start: string;
  request_window_end: string;
  request_window_start: string;
  template_id: number;
}

export interface Template {
  id: number | "";
  departmentId: number | "";
  name: string;
  fullName: string;
  available: boolean;
  filtered: boolean;
  isHidden: boolean;
  firstPublishedDate: Date;
  lastPublishedDate: Date;
  requestHorizonStart: Date;
  requestHorizonEnd: Date;
  requestWindowStart: Date;
  requestWindowEnd: Date;
}

export const cleanTemplateAttributes = (attrs: Partial<TemplateAttributes>): Template => {
  return {
    id: attrs.template_id ?? "",
    departmentId: attrs.department_id ?? "",
    name: attrs.name ?? "",
    fullName: attrs.name_full ?? "",
    available: attrs.available ?? false,
    filtered: attrs.filtered ?? false,
    isHidden: attrs.is_hidden ?? false,
    firstPublishedDate: toDate(attrs.first_published_date),
    lastPublishedDate: toDate(attrs.last_published_date),
    requestHorizonStart: toDate(attrs.request_horizon_start),
    requestHorizonEnd: toDate(attrs.request_horizon_end),
    requestWindowStart: toDate(attrs.request_window_start),
    requestWindowEnd: toDate(attrs.request_window_end),
  };
};

export type TemplatesById = Record<string, Template>;
