var moment = require("moment");
var _ = require("underscore");

var HighlightTool = Backbone.Model.extend({
  defaults: {
    type: "highlight",
    desc: "highlight",
    backgroundColor: "#FEFF84",
    color: "#000000",

    target: undefined,
    component: undefined,
    value: undefined,

    // highlighter specific attributes
    slotsHighlighted: 0,
    depObj: undefined,
  },

  onSelect: function () {
    window.LbsAppData.AppContext.openExtra({
      type: "highlight",
      tool: this,
    });
  },

  onAttachPre: function () {
    this._reset();
  },

  onAttach: function () {
    var slot = this.attributes.target;
    // get the assignment/personnel from the slot
    var depObj =
      window.LbsAppData.AppContext.get("GridSettings_leftColumnType") == "assignment"
        ? slot.personnelObj
        : slot.assignmentObj;

    // if the depObj already has a tool (highligher) attached, reset it
    if (depObj.attributes.boundTool) {
      depObj.attributes.boundTool.reset();
    }
    // attach this tool to the dependent object
    depObj.set({ boundTool: this });

    // save the depObj
    this.set({ depObj: depObj });

    // store the number of objects that are listening to the depObj's boundTool attribute
    this.set({
      slotsHighlighted: depObj._events["change:boundTool"].length,
    });

    // analytics calls
    if (window.LbsAppData.AppContext.get("GridSettings_leftColumnType") == "assignment") {
      //window.LbsAppData.AnalyticsManager.sendEvent('Highlight: Personnel', 'Highlighter Tool ' + this.attributes.backgroundColor);
    } else {
      //window.LbsAppData.AnalyticsManager.sendEvent('Highlight: Assignment', 'Highlighter Tool ' + this.attributes.backgroundColor);
    }

    this.trigger("highlighted");
  },

  onExecute: function () {},

  onFinished: function () {},

  onClose: function () {
    // highlighters are weird, push this up to the collection level so ALL highlighters can be closed
    this.collection.reset();
    window.LbsAppData.AppContext.closeExtra();
  },

  /* publics */
  style: function () {
    return {
      backgroundColor: this.attributes.backgroundColor,
      color: this.attributes.color,
    };
  },

  reset: function () {
    this._reset();
  },

  forceUpdateComponent: function () {
    const { component } = this.attributes;
    if (component) {
      component.forceUpdateOnParent();
    }
  },

  hasHighlightedSlots: function () {
    return this.get("slotsHighlighted") > 0;
  },

  getHighlightedSlots: function () {
    return this.get("slotsHighlighted");
  },

  /* privates */
  _reset: function () {
    if (this.attributes.depObj) {
      this.attributes.depObj.set({ boundTool: undefined });
      this.set({
        target: undefined,
        component: undefined,
        slotsHighlighted: 0,
        depObj: undefined,
      });

      this.trigger("highlighted");
    }
  },
});

module.exports = HighlightTool;
