const Backbone = require("backbone");
const FiddleDeadConfig = require("@/_lib/data/FiddleDeadConfig.js");
const moment = require("moment");

const { formatISODateTime } = require("@/viewer/utils/dateFormatters");
const { timezoneConverter } = require("@/viewer/utils/timezones");
const STYLE_CONSTANTS = require("_lib/utils/constants");
const { isTimezoneAwarenessEnabledAndOrIsUserInDifferentTimezone } = require("../../../viewer/utils/timezones");
const { FAKE_DATE_PREFIX } = require("../../../_lib/constants/dates");

const Slot = Backbone.Model.extend({
  idAttribute: "slot_uuid",

  defaults: {
    boundTool: undefined,
    boundError: undefined,
  },

  initialize: function (models, options) {
    this.personnelObj = window.LbsAppData.Personnel.get(this.attributes.emp_id);
    this.assignmentObj = window.LbsAppData.Assignments.get(this.attributes.assign_plus_structure_id);
    const tzStart = timezoneConverter(this.attributes.start_time);
    const tzStop = timezoneConverter(this.attributes.stop_time);

    if (!tzStart || !tzStop) return;
    // A silly hack to save a format:
    // Use substr to get the date from the datetime.
    const startTime = formatISODateTime(tzStart);
    const startDate = startTime.substr(0, 10);

    this.attributes.slot_date_original = this.attributes.slot_date;
    this.attributes.slot_date = startDate;
    this.attributes.start_time_original = this.attributes.start_time;
    this.attributes.start_time = startTime;
    this.attributes.stop_time_original = this.attributes.stop_time;
    this.attributes.stop_time = formatISODateTime(tzStop);

    if (this.attributes.pending_info) {
      const pendingStart = timezoneConverter(this.attributes.pending_info.start_time);
      const pendingStop = timezoneConverter(this.attributes.pending_info.stop_time);

      if (!pendingStart || !pendingStop) return;

      this.attributes.pending_info.start_time_original = this.attributes.pending_info.start_time;
      this.attributes.pending_info.start_time = formatISODateTime(pendingStart);
      this.attributes.pending_info.stop_time_original = this.attributes.pending_info.stop_time;
      this.attributes.pending_info.stop_time = formatISODateTime(pendingStop);
    }

    // subscribe to personnelObj/assignmentObj changes via tools
    if (this.personnelObj) {
      this.listenTo(this.personnelObj, "change:boundTool", this.toggleReceptive);
    }
    if (this.assignmentObj) {
      this.listenTo(this.assignmentObj, "change:boundTool", this.toggleReceptive);
    }
  },
  toJSON: function () {
    // there is a lot of shit we don't need to save to Cassandra here, so just grabbing what we need
    let sendAttr = {};

    sendAttr.emp_id = this.attributes.emp_id;
    sendAttr.prev_emp_id = this.attributes.prev_emp_id;
    sendAttr.assign_struct_id = this.attributes.assign_structure_id;
    sendAttr.assign_id = parseInt(
      this.attributes.assign_plus_structure_id.split(window.LbsAppData.CONDENSED_DELIMITER)[0],
      10
    );
    sendAttr.prev_assign_struct_id = this.attributes.prev_assign_struct_id;
    sendAttr.prev_assign_id = this.attributes.prev_assign_id;
    sendAttr.date = this.attributes.slot_date;
    sendAttr.start_time = this.attributes.start_time;
    sendAttr.stop_time = this.attributes.stop_time;
    sendAttr.is_default_time = this.attributes.is_default_time;
    sendAttr.is_granted_request = this.attributes.is_granted_request;
    sendAttr.is_manual_slot = this.attributes.is_manual_slot;
    sendAttr.note = this.attributes.note; // this will need some work on the api end...always null for now
    sendAttr.loa_reason_id = null; // this will need some work on the api end...always null for now
    sendAttr.display_name_override = this.attributes.display_name_override;
    sendAttr.modified_by = window.LbsAppData.User.attributes.emp_id;

    return sendAttr;
  },

  massageDisplayText: function (rowKey) {
    // a lightning-bolt app would not be complete with some confusing cell display logic...here is said logic
    // rowKey is the id of whatever the row is, so an emp_id or an assignment_id...doesn't apply for calendar mode
    const compactMode = window.LbsAppData.AppContext.attributes.compactMode === "yes";
    let displayText;

    if (window.LbsAppData.AppContext.attributes.GridSettings_leftColumnType === "assignment") {
      if (!this.personnelObj) {
        displayText = "Invalid Personnel";
      } else {
        displayText = !compactMode
          ? this.personnelObj.attributes.display_name
          : this.personnelObj.attributes.compact_name;
      }

      if (this.attributes.is_pending) {
        if (this.attributes.pending_emp_id === window.LbsAppData.DOTGUY) {
          // pending delete
          displayText = "%DEL% " + displayText;
        } else if (this.attributes.emp_id === window.LbsAppData.DOTGUY) {
          // pending fill
          const pendingEmp = window.LbsAppData.Personnel.get(this.attributes.pending_emp_id);
          const pendingDisplayText = !compactMode
            ? pendingEmp.attributes.display_name
            : pendingEmp.attributes.compact_name;
          displayText = "%ADD% " + pendingDisplayText;
        } else if (this.attributes.emp_id === this.attributes.pending_emp_id) {
          // pending for details (loareason...maybe time in the future)
          displayText = "%DTL% " + displayText;
        } else {
          // pending replace
          const pendingEmp = window.LbsAppData.Personnel.get(this.attributes.pending_emp_id);

          displayText = "%RPL% " + displayText + " [" + pendingEmp.attributes.compact_name + "]";
        }
      }

      // if there is custom text...it overrides anything above (mirroring what happens in flex)
      if (this.attributes.display_name_override != null) {
        displayText = this.attributes.display_name_override;
      }
    } else {
      // view by personnel
      if (this.assignmentObj) {
        displayText = !compactMode
          ? this.assignmentObj.attributes.display_name
          : this.assignmentObj.attributes.compact_name;
      }
      if (displayText === undefined) {
        displayText = this.attributes.assign_display_name;
      }
      const origDisplayText = displayText;

      if (this.attributes.is_pending) {
        if (this.attributes.emp_id === this.attributes.pending_emp_id) {
          // pending for details
          displayText = "%DTL% " + displayText;
        } else if (this.attributes.pending_emp_id === window.LbsAppData.DOTGUY) {
          // pending delete
          displayText = "%DEL% " + displayText;
        } else if (this.attributes.emp_id === window.LbsAppData.DOTGUY) {
          // pending fill
          displayText = "%ADD% " + displayText;
        } else if (this.attributes.emp_id === rowKey) {
          // pending replace (remove in context)
          displayText = "%DEL% " + displayText;
        } else if (this.attributes.pending_emp_id === rowKey) {
          // pending replace (add in context)
          displayText = "%ADD% " + displayText;
        }
      }

      // if there is custom text...it overrides anything above (mirroring what happens in flex)
      if (this.attributes.display_name_override != null) {
        displayText = origDisplayText + " [" + this.attributes.display_name_override + "]";
      }
    }

    return displayText;
  },

  getDisplayText: function () {
    let displayText = this.attributes.display_name;

    if (this.attributes.display_name_override) {
      displayText = this.attributes.display_name_override;
    }

    return displayText;
  },

  replacePersonnel: function (personnel) {
    const existingPersonnel = this.personnelObj;
    this.stopListening(this.personnelObj);

    // replace the linked personnel object
    this.personnelObj = personnel;

    // reattach event listeners
    this.listenTo(this.personnelObj, "change:boundTool", this.toggleReceptive);
    // recalculate (any) colors
    this._calculateColors();

    // modify the attributes
    this.set({
      prev_emp_id: existingPersonnel ? existingPersonnel.id : undefined,
      prev_assign_id: this.attributes.assign_plus_structure_id
        ? this.attributes.assign_plus_structure_id.split(window.LbsAppData.CONDENSED_DELIMITER)[0]
        : undefined,
      prev_assign_struct_id: this.attributes.assign_structure_id ? this.attributes.assign_structure_id : undefined,

      emp_id: personnel.id,
      display_name: personnel.attributes.display_name,
      compact_name: personnel.attributes.compact_name,
      // if this happens we clear out the display_name_override to avoid that sticking with the new person
      display_name_override: null,

      // can't carry over the fact it's a granted request anymore
      is_granted_request: false,
      is_manual_slot: true,
    });

    // if there's an error attached to this slot then clear it out
    if (this.attributes.boundError) {
      this.detachError();
    }
  },

  prepAttributesForDBSave: function () {
    // check for TZAwareness before we waste time
    if (isTimezoneAwarenessEnabledAndOrIsUserInDifferentTimezone()) {
      [this.attributes.start_time_original, this.attributes.start_time] = [
        this.attributes.start_time,
        this.attributes.start_time_original ?? this.attributes.start_time,
      ];
      [this.attributes.stop_time_original, this.attributes.stop_time] = [
        this.attributes.stop_time,
        this.attributes.stop_time_original ?? this.attributes.stop_time,
      ];
    }
  },
  updateTimezoneAttributesAfterDBSave: function () {
    if (isTimezoneAwarenessEnabledAndOrIsUserInDifferentTimezone()) {
      [this.attributes.start_time, this.attributes.start_time_original] = [
        this.attributes.start_time_original,
        this.attributes.start_time,
      ];
      [this.attributes.stop_time, this.attributes.stop_time_original] = [
        this.attributes.stop_time_original,
        this.attributes.stop_time,
      ];
    }
  },
  replaceAssignment: function (assignment, structureId, date) {
    const existingAssignment = this.assignmentObj;
    // detach the event listeners
    this.stopListening(this.assignmentObj);

    // replace the linked personnel object
    this.assignmentObj = assignment;

    // reattach event listeners
    this.listenTo(this.assignmentObj, "change:boundTool", this.toggleReceptive);
    // recalculate (any) colors
    this._calculateColors();

    // may need to completely overwrite any schedule-level attributes
    const schedule = window.LbsAppData.AppContext.attributes.displayedSchedule;
    const templateId = assignment.attributes.structure_to_template_map[structureId];
    let scheduleId = schedule.id;

    if (schedule.attributes.template_id !== templateId) {
      // get the published schedule for this structure (mapping yey)
      scheduleId = window.LbsAppData.PublishedMap.get(templateId).getPublishedScheduleId(date.format("YYYYMMDD"));
    }

    // Get the default times for the selected structure
    const defaultTimeMap = assignment.attributes.structure_to_default_times_map[structureId].split("~");
    const defaultTimeMapStartTime = defaultTimeMap[0];
    const defaultTimeMapStopTime = defaultTimeMap[1];
    const baseDate = moment(date);

    // some assignments might have a "NONE" here for default time...it's the case where within the template the
    // there are condensed structures with different times
    let startTime;
    let stopTime;
    const isInvalidTimeMap = !defaultTimeMapStartTime || defaultTimeMapStartTime === "NONE" || !defaultTimeMapStopTime;

    if (!isInvalidTimeMap) {
      // the reason a fake date prefix needs to be used is because there is no way to make a js date from just a time
      // easily and the timezone conversion expects a date or a string that is able to be turned into a date
      //
      // ie defaultTimeMapStartTime is 08:30 and js Date doesn't know what to do with that
      startTime =
        baseDate.format("YYYY-MM-DD") +
        "T" +
        formatISODateTime(timezoneConverter(FAKE_DATE_PREFIX + defaultTimeMapStartTime)).split("T")[1];

      stopTime =
        baseDate.format("YYYY-MM-DD") +
        "T" +
        formatISODateTime(timezoneConverter(FAKE_DATE_PREFIX + defaultTimeMapStopTime)).split("T")[1];
    } else {
      startTime = `${baseDate.startOf("day").format("YYYY-MM-DDTHH:mm:00")}`;
      stopTime = startTime;
    }

    // modify the attributes
    this.set({
      prev_emp_id: this.attributes.emp_id,
      prev_assign_id: existingAssignment
        ? this.attributes.assign_plus_structure_id.split(window.LbsAppData.CONDENSED_DELIMITER)[0]
        : undefined,
      prev_assign_struct_id: existingAssignment ? this.attributes.assign_structure_id : undefined,

      assign_compact_name: assignment.attributes.compact_name,
      assign_display_name: assignment.attributes.display_name,
      condensed_structure_id: assignment.attributes.display_name,
      assign_plus_structure_id: assignment.id,
      // same deal as a replacePersonnel...clear out the display_name_override in the case this happens
      display_name_override: null,

      // no longer can carry over the fact it's a granted request..it's changed now
      is_granted_request: false,
      is_manual_slot: true,

      sched_id: scheduleId,
      template: undefined, // I don't think I can accurately figure this out right now
      template_id: templateId,
      assign_structure_id: structureId,
      slot_date_original: baseDate.format("YYYY-MM-DD"),
      slot_date: baseDate.format("YYYY-MM-DD"),
      start_time_original: this.attributes.start_time,
      start_time: startTime,
      stop_time_original: this.attributes.stop_time,
      stop_time: stopTime,
      is_default_time: true,
    });

    // if there's an error attached to this slot then clear it out
    if (this.attributes.boundError) {
      this.detachError();
    }
  },

  removeListeners: function () {
    this.stopListening(this.personnelObj);
    this.stopListening(this.assignmentObj);
  },
  refreshDependentObjects: function () {
    this.removeListeners();

    this.personnelObj = window.LbsAppData.Personnel.get(this.attributes.emp_id);
    this.assignmentObj = window.LbsAppData.Assignments.get(this.attributes.assign_plus_structure_id);

    // subscribe to personnelObj/assignmentObj changes via tools
    if (this.personnelObj) {
      this.listenTo(this.personnelObj, "change:boundTool", this.toggleReceptive);
    }
    if (this.assignmentObj) {
      this.listenTo(this.assignmentObj, "change:boundTool", this.toggleReceptive);
    }
  },

  toggleReceptive: function (model, tool) {
    // only change this if there isn't a tool bound already, or we're trying to unbind
    // an existing one, I'm not sure if be more robust in the future, but it should be
    // good enough for the select/highlighter combo

    if (!tool || !this.attributes.boundTool) {
      this.set({
        boundTool: tool,
      });
    }
  },
  attachError: function (error) {
    this.set({
      boundError: error,
    });
  },
  detachError: function () {
    this.set({
      boundError: undefined,
    });
  },

  getErrorClasses: function () {
    return this.attributes.boundError.attributes.type;
  },

  isMappedSlot: function () {
    // business logic..basically if the template_id on the slot doesn't match the schedule in context template_id
    // then it is...mapped homie
    return (
      this.attributes.template_id !== window.LbsAppData.AppContext.attributes.displayedSchedule.attributes.template_id
    );
  },

  isManualSlot: function () {
    return this.attributes.is_manual_slot;
  },

  urlRoot: function () {
    return (
      FiddleDeadConfig.getPrefix() +
      "/schedule_change/" +
      window.LbsAppData.User.attributes.customer_id +
      "/" +
      this.attributes.sched_id
    );
  },

  // COLORS!!
  getEffects: function () {
    if (this.attributes.boundTool) {
      // tool takes precedence
      return this.attributes.boundTool.style();
    } else if (this.attributes.boundError) {
      // error is next -- return nothing
      //return this.attributes.boundError.attributes.type;
      return {};
    } else if (window.LbsAppData.AppContext.get("view")) {
      // leeeeeerrrooooyyyyy mmjeeeeenkinsss
      const ret = {};

      if (this.attributes.bg_color) {
        ret["backgroundColor"] = this.attributes.bg_color;
      }

      if (this.attributes.text_color) {
        ret["color"] = this.attributes.text_color;
      }

      return ret;
    }

    return {}; // no view -- no styles
  },

  _calculateColors: function () {
    const attributes = {};

    // bail out if we have no view
    if (!window.LbsAppData.AppContext.attributes.view) return;

    const colorMethod = window.LbsAppData.AppContext.attributes.view.attributes.theme.data.colorMethod;
    const colorTextMethod = window.LbsAppData.AppContext.attributes.view.attributes.theme.data.colorTextMethod;

    const bgColor = colorMethod !== "none" ? this._getBgColor() : undefined;
    const textColor = colorTextMethod !== "none" ? this._getTextColor(bgColor) : undefined;

    if (colorMethod === "mixed" || colorMethod === "cell") {
      if (bgColor) {
        attributes["bg_color"] = bgColor;
      } else {
        attributes["bg_color"] = undefined; // make sure we reset any possible color attached by another view
      }
    }

    if (colorTextMethod === "colored") {
      if (textColor) {
        attributes["text_color"] = textColor;
      } else {
        attributes["text_color"] = undefined; // make sure we reset any possible color attached by another view
      }
    }

    this.set(attributes);
  },

  _getBgColor: function () {
    let bgColor;

    if (window.LbsAppData.AppContext.getLeftColumnType() === "personnel") {
      bgColor = this.assignmentObj ? this.assignmentObj.attributes.color : undefined; // ? this.assignmentObj.attributes.color : this.personnelObj.attributes.color;
    } else {
      bgColor = this.personnelObj ? this.personnelObj.attributes.color : undefined; // ? this.personnelObj.attributes.color : this.assignmentObj.attributes.color;
    }

    return bgColor;
  },

  _getTextColor: function (bgColor) {
    let textColor;

    const personnelTextColor =
      this.personnelObj && this.personnelObj.attributes.colorText ? this.personnelObj.attributes.colorText : undefined;
    const assignmentTextColor =
      this.assignmentObj && this.assignmentObj.attributes.colorText
        ? this.assignmentObj.attributes.colorText
        : undefined;

    // ternary mess (yeah, that is an understatement)-- the idea is for the text color to be, in order:
    // 1. inner text color if defined
    // 2. default text color if not defined, but a background color is
    // 3. outer text color if defined
    // 4. default text color (undefined)
    //TODO Unfuck this whole shit heap
    if (window.LbsAppData.AppContext.getLeftColumnType() === "personnel") {
      textColor = assignmentTextColor
        ? assignmentTextColor
        : bgColor
        ? STYLE_CONSTANTS.VIEWER_TEXT_COLOR
        : personnelTextColor
        ? personnelTextColor
        : undefined;
    } else {
      textColor = personnelTextColor
        ? personnelTextColor
        : bgColor
        ? STYLE_CONSTANTS.VIEWER_TEXT_COLOR
        : assignmentTextColor
        ? assignmentTextColor
        : undefined;
    }

    return textColor;
  },
});

module.exports = Slot;
