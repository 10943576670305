import { Model } from "backbone";

/**
 * Utility for conditionally setting attribute values on a Backbone model.
 * Set will only be called if the given new value is not equal to the value
 * currently set on the model.
 *
 * @param model Backbone model.
 * @param key Attribute name on given Backbone model.
 * @param newValue New value to set on the given attribute.
 */
export default <T>(model: Model, key: string, newValue: T): void => {
  const currentValue = model.get(key);
  if (currentValue !== newValue) {
    model.set(key, newValue);
  }
};
