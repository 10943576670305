const Backbone = require("backbone");
var _ = require("underscore");

var ApiConfig = require("_lib/data/ApiConfig.js");

var PublishedMap = Backbone.Model.extend({
  idAttribute: "template_id",

  getPublishedScheduleId: function (date) {
    for (var i = 0; i < this.attributes.published_schedules.length; i++) {
      var item = this.attributes.published_schedules[i];
      if (this._checkDate(item.start_date, item.stop_date, date)) {
        // found the schedule
        return item.sched_id;
      }
    }
  },

  _checkDate: function (start, stop, checkDate) {
    if (checkDate >= start && checkDate <= stop) {
      return true;
    } else {
      return false;
    }
  },
});

module.exports = PublishedMap;
