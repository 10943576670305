const Backbone = require("backbone");
const { UpdatePersonnelFilterIds } = require("../../../_lib/ui/modules/topbar/actions");
const store = require("../../../store").default;
const _ = require("lodash");
const ApiConfig = require("_lib/data/ApiConfig.js");
const Personnel = require("./../models/Personnel.js");

const PersonnelCollection = Backbone.Collection.extend({
  model: Personnel,

  initialize: function (models, attributes) {
    this.attributes = {};

    for (let key in attributes) {
      this.attributes[key] = attributes[key];
    }

    this.attributes.title = "Personnel";
  },

  url: function () {
    const queryParams = {};

    if (this.attributes.deptList) {
      queryParams["dept_list"] = this.attributes.deptList;
    }

    if (this.attributes.empList) {
      queryParams["emp_list"] = this.attributes.empList;
    }

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    return (
      ApiConfig.getPrefix() +
      "/personnel?" +
      _.map(cleanQueryParams, function (value, key) {
        return key + "=" + value;
      }).join("&")
    );
  },

  toggleFilteredModels: function (ids, value) {
    for (let i = 0; i < ids.length; i++) {
      const model = this.get(ids[i]);

      if (value === undefined) {
        // toggle it
        model.set({ filtered: !model.attributes.filtered });
        this.trigger("change:filteredModel");
      } else {
        // set it explicitly (if it needs to be)
        if (model.attributes.filtered !== value) {
          model.set({ filtered: value });
          // trigger an event
          this.trigger("change:filteredModel");
        }
      }
    }
  },

  filterAll: function () {
    this.forEach(function (item) {
      item.set({ filtered: true });
    });

    // trigger an event
    this.trigger("change:filteredModel");
  },
  unfilterAll: function () {
    this.forEach(function (item) {
      item.set({ filtered: false });
    });

    // trigger an event
    this.trigger("change:filteredModel");
  },

  resetOrdering: function () {
    this.forEach(function (item) {
      item.set({ order: item.attributes.display_name });
    });
  },

  filterPersonnel: function (ids) {
    if (ids.length > 0) {
      this.forEach(function (item) {
        item.set({ filtered: !ids.includes(item.id) });
      });
    } else {
      this.forEach(function (item) {
        item.set({ filtered: false });
      });
    }
    store.dispatch(UpdatePersonnelFilterIds(ids));
    this.trigger("change:filteredModel");
  },
});

module.exports = PersonnelCollection;
