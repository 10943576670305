"use strict";

var React = require("react/addons");
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
var LegacyToken = require("_lib/data/models/LegacyToken.js");

var LegacyLink = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },

  componentDidMount: function () {},

  /* privates */
  _gotoUnity: function () {
    // 2 steps...need to create a Unity token, then redirect to the Unity url with that
    var legacyToken = new LegacyToken();
    legacyToken.fetch({
      success: function (model) {
        // redirect homie
        window.top.location = window.LbsAppData.Destinations.get("unity") + "?source=access&dest=Dashboard.aspx";
      },
    });
  },

  /* render */
  render: function () {
    /* jshint ignore:start */
    var classes = React.addons.classSet({
      LegacyLink: true,
    });

    return (
      <div className={classes}>
        <div className="title">
          <i className="fa fa-fw fa-location-arrow" />
          Link to Legacy
        </div>
        <div className="content">
          <div
            className="content-item"
            onClick={this.touchProxy.bind(this, this._gotoUnity, [])}
            onTouchEnd={this.touchProxy.bind(this, this._gotoUnity, [])}
          >
            <div className="title">Not where you should be?</div>
            <div className="desc">Click here and we'll take you back to a more familiar place.</div>
          </div>
        </div>
      </div>
    );

    /* jshint ignore:end */
  },
});

module.exports = LegacyLink;
