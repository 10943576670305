import * as React from "react";
import { ReportData, ReportResult } from "@/viewer/types/domain/report";
import classnames from "@/_lib/utils/classnames";
import { getDay } from "date-fns";
import { formatDayKey } from "@/viewer/utils/dateFormatters";
import { FilterMap, SettingsContext } from "@/viewer/ui/modules/common/types/context";
import TallyDisplay from "@/viewer/ui/modules/common/components/TallyDisplay";
import { visibleDateRange } from "@/viewer/ui/modules/common/helpers/dates";
import { getTallyDataForDayAndEmp } from "@/viewer/utils/tallies";

interface Props {
  settings: SettingsContext;
  dateStart: Date;
  dateEnd: Date;

  reportData: ReportData;
  reportResult: ReportResult;

  tallyId: string;
  tallyName: string;

  filteredPersonnel: FilterMap;
}

const StandardWeekTallyRow = (props: Props): JSX.Element => {
  const { settings, dateStart, dateEnd, reportData, reportResult, tallyId, tallyName, filteredPersonnel } = props;

  return (
    <div className={"DataRow"}>
      <div className="leftCol">{tallyName}</div>
      {visibleDateRange(settings, dateStart, dateEnd).map((slotDate) => {
        const slotKey = formatDayKey(settings, slotDate);
        let tallyData: number | undefined;
        if (settings.shouldCountTalliesByPersonnelFilter) {
          tallyData = getTallyDataForDayAndEmp(tallyId, slotKey, reportData, filteredPersonnel);
        } else {
          tallyData = reportResult.data[slotKey + "|" + tallyId];
        }

        const reportDataForSlot = reportData.data_by_day[slotKey];
        const target = reportDataForSlot?.targets[tallyId];
        const slotDay = getDay(slotDate);
        const classes = classnames("DataCell", {
          weekend: slotDay === 0 || slotDay === 6,
        });

        return (
          <div key={slotKey} className={classes}>
            <TallyDisplay
              className={"StandardTally"}
              data={tallyData}
              target={target}
              tallyDate={slotDate}
              tallyName={tallyName}
            />
          </div>
        );
      })}
    </div>
  );
};

export default StandardWeekTallyRow;
