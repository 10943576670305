"use strict";

// scss requires
//require('./_styles.scss');
// dependencies
var React = require("react/addons");
var moment = require("moment");

var ErrorDialogItem = React.createClass({
  /* lifecycle methods */

  componentDidMount: function () {},
  componentWillUnmount: function () {},

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      ErrorItem: true,
    });

    var useDate = new moment(this.props.error.date, "YYYYMMDD");

    return (
      <div className={classes}>
        <span className="date">{useDate.format("ddd, ll")}</span>
        <span className="message">{this.props.error.message}</span>
      </div>
    );
  },
});

module.exports = ErrorDialogItem;
