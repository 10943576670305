"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
const ReactDOM = require("react-dom");
var $ = require("jquery");
var moment = require("moment");

// collections

var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var NoteFullDialog = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },

  componentDidMount: function () {
    // send a tracking event
    if (!this.props.inline) {
      window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Note");
    }
  },

  /* publics */
  close: function (e) {
    var isValid = true;
    if (this.props.dialogObject.charLimit) {
      isValid = this._testLength();
    }

    if (isValid) {
      if (this.props.dialogObject.onClose) {
        this.props.dialogObject.onClose(ReactDOM.findDOMNode(this.refs.NoteTextArea).value);
      }
      this.props.close();
    } else {
      // flash message
      this._flashMessage("Error: Note is longer than " + this.props.dialogObject.charLimit + " characters.");
    }
  },

  /* privates */
  _testLength: function () {
    return ReactDOM.findDOMNode(this.refs.NoteTextArea).value.length <= this.props.dialogObject.charLimit;
  },
  _flashMessage: function (message) {
    window.LbsAppData.AppContext.openDialog({
      type: "message",
      message: message,
      timer: 2500,
    });
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      NoteFull: true,
    });

    return (
      <div className={classes}>
        <div className="position-reset">
          <div
            className="close"
            onClick={this.touchProxy.bind(this, this.close, [])}
            onTouchEnd={this.touchProxy.bind(this, this.close, [])}
          >
            <i className={!this.props.dialogObject.readonly ? "fa fa-check" : "fa fa-close"} />
          </div>

          <div className="content">
            <div className="header">Note</div>
          </div>

          <div className="editables">
            <div className="note-text-container">
              <textarea
                ref="NoteTextArea"
                rows="15"
                cols="44"
                placeholder="Edit note"
                defaultValue={this.props.dialogObject.note}
                readOnly={this.props.dialogObject.readonly}
                style={{ resize: "vertical" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
});

module.exports = NoteFullDialog;
