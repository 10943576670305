const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var View = require("./../models/View.js");

var ViewCollection = Backbone.Collection.extend({
  model: View,
  comparator: "name",
  url: ApiConfig.getPrefix() + "/views",

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },
});

module.exports = ViewCollection;
