"use strict";

// scss requires
require("./_styles.scss");

// dependencies
var React = require("react/addons");
// components
var SubMenu = require("./submenu/SubMenu.jsx");

var ContextRibbonItem = React.createClass({
  /* privates */
  _toggleSubMenu: function (val) {
    this.setState({
      subMenu: val !== undefined ? val : !this.state.subMenu,
    });
  },

  /* publics */

  /* lifecycle */
  getInitialState: function () {
    return {
      subMenu: false,
    };
  },
  componentDidMount: function () {},
  componentWillUnmount: function () {},

  render: function () {
    var contextualObject = this.props.object;
    var components = [];
    var limitSmall = false;
    var limitLarge = false;

    var ribbonTextClasses = "ribbon-text";
    if (contextualObject.textClass) {
      ribbonTextClasses += " " + contextualObject.textClass;
    }
    if (contextualObject.limitWidthClass) {
      ribbonTextClasses += " " + contextualObject.limitWidthClass;
      if (contextualObject.limitWidthClass == "limit-width-small") {
        limitSmall = true;
      } else if (contextualObject.limitWidthClass == "limit-width-large") {
        limitLarge = true;
      }
    }

    var tooltip;
    if (contextualObject.hasOwnProperty("tooltip")) {
      tooltip = (
        <div className="tool-tip">
          <div className="triangle"></div>

          <div className="message">{contextualObject.tooltip}</div>
        </div>
      );
    }

    if (!contextualObject.menu || contextualObject.menu.length == 0) {
      // normal tooltip-click one
      if (contextualObject.tag == "date") {
        components.push(
          <div className={ribbonTextClasses} data-cy="TBMonthSelector" key="text" onClick={contextualObject.action}>
            {contextualObject.text}

            {tooltip}
          </div>
        );
      } else {
        components.push(
          <div className={ribbonTextClasses} data-cy="TBOptions" key="text" onClick={contextualObject.action}>
            {contextualObject.text}

            {tooltip}
          </div>
        );
      }
    } else {
      // menued one
      // onClick={contextualObject.action}
      components.push(
        <div
          key="text"
          className={ribbonTextClasses}
          onMouseEnter={this._toggleSubMenu.bind(this, true)}
          onMouseLeave={this._toggleSubMenu.bind(this, false)}
          onClick={this._toggleSubMenu}
          onTouchEnd={this._toggleSubMenu}
        >
          {contextualObject.text}

          <SubMenu active={this.state.subMenu} items={contextualObject.menu} />
        </div>
      );
    }

    if (contextualObject.navLeft) {
      components.push(
        <div className="icons-left" key="nav">
          <i className="fa fa-angle-left" data-cy="TBPrevMonthBtn" onClick={contextualObject["navLeft"]} />
          <i className="fa fa-angle-right" data-cy="TBNextMonthBtn" onClick={contextualObject["navRight"]} />
        </div>
      );
    }

    if (contextualObject.hasOwnProperty("mobileIcon")) {
      components.push(
        <div className="icons-mobile" key="mobile">
          <i className={"fa " + contextualObject.mobileIcon} onClick={contextualObject.action} />
        </div>
      );
    }

    if (contextualObject.hasOwnProperty("onClose")) {
      components.push(
        <div className="icons-right" key="close">
          <i className="fa fa-close" onClick={contextualObject.onClose} />
        </div>
      );
    }

    var classes = React.addons.classSet({
      ContextRibbonItem: true,
      unclickable:
        contextualObject.action || (contextualObject.menu && contextualObject.menu.length > 0) ? false : true,
      "limit-width-small": limitSmall,
      "limit-width-large": limitLarge,
    });
    classes += " " + contextualObject.tag;

    return <div className={classes}>{components}</div>;
  },
});

module.exports = ContextRibbonItem;
