import { UIContext } from "../../../common/types/context";

export const BASE_CELL_HEIGHT_PX_STRING = "22px";
export const ZERO_HEIGHT_CELL_STRING = "0px";
export const CELL_HEIGHT_WITH_SHOW_TIMES_STRING = "35px";
export const BASE_FONT_SIZE_PERCENTAGE = 100;
export const CELL_CLASSNAME = "cell";
export const COLUMN_WIDTH_PX = 100;
export const COLUMN_WIDTH_PX_CONDENSED = 44;
export const LEFT_COLUMN_WIDTH_CONDENSED_PX = 75;
export const LEFT_COLUMN_WIDTH_PX = 150;
export const REQUEST_ROW_MEMBER_CLASSNAME_PREFIX = "request-row-member-id-";
export const SLOT_ROW_MEMBER_CLASSNAME_PREFIX = "slot-row-member-id-";

// Get the widths of the standard and left hand columns based on ui settings
export const getColumnDimensions = (isCondensedView: boolean): { leftColumnWidthPx: number; columnWidthPx: number } => {
  return isCondensedView
    ? { leftColumnWidthPx: LEFT_COLUMN_WIDTH_CONDENSED_PX, columnWidthPx: COLUMN_WIDTH_PX_CONDENSED }
    : { leftColumnWidthPx: LEFT_COLUMN_WIDTH_PX, columnWidthPx: COLUMN_WIDTH_PX };
};

// Get the height of cells based on the show times ui option
export const getCellHeight = ({ showTimes }: UIContext): string =>
  showTimes ? CELL_HEIGHT_WITH_SHOW_TIMES_STRING : BASE_CELL_HEIGHT_PX_STRING;
