"use strict";

const React = require("react/addons");
const TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

const TimePickerMinutePicker = React.createClass({
  mixins: [TouchMixin],

  getInitialState: function () {
    return { value: this.props.defaultVal };
  },

  getValue: function () {
    return parseInt(this.state.value);
  },

  onChange: function (newValue) {
    const callbackFunc = this.props.onChange;
    if (typeof callbackFunc === "function") {
      callbackFunc({ newMinute: newValue });
    }
  },

  /* privates */
  _incrementMin: function (e) {
    let newVal = parseInt(this.state.value) + 5;
    if (newVal % 5 !== 0) {
      newVal = newVal - (newVal % 5);
    }
    if (newVal > 59) {
      newVal = 0;
    }
    let newValStr = newVal.toString();
    if (newValStr.length === 1) {
      newValStr = "0" + newValStr;
    }
    newVal = this._massageMinuteText(newVal);
    this.setState({ value: newVal });
    this.onChange(newVal);
  },

  _decrementMin: function (e) {
    let newVal = parseInt(this.state.value) - 5;
    if (newVal % 5 !== 0) {
      newVal = newVal - (newVal % 5);
    }
    if (newVal < 0) {
      newVal = 55;
    }
    newVal = this._massageMinuteText(newVal);
    this.setState({ value: newVal });
    this.onChange(newVal);
  },

  _handleMinChange: function (e) {
    const origVal = this.state.value;
    let newVal = parseInt(e.target.value);
    if (newVal > 59) {
      newVal = origVal;
    } else if (isNaN(newVal) || newVal < 0) {
      newVal = 0;
    }
    newVal = this._massageMinuteText(newVal);
    this.setState({ value: newVal });
    this.onChange(newVal);
  },

  _massageMinuteText: function (newVal) {
    let newValStr = newVal.toString();
    if (newValStr.length === 1) {
      newValStr = "0" + newValStr;
    }
    return newValStr;
  },

  _roundMinuteText: function () {
    this.props.refocusKeydown();
  },

  /* lifecycle methods */
  componentWillReceiveProps: function (nextProps) {
    if (nextProps.isFocused && !this.props.isFocused) {
      this.setState({
        focused: true,
        spinnerValue: nextProps.spinnerCounter,
      });
    } else if (!nextProps.isFocused && this.props.isFocused) {
      this.setState({ focused: false, spinnerValue: undefined });
    } else if (this.state.focused) {
      // based on the spinner val...move up/down in response to a keyboard event
      if (this.state.spinnerValue < nextProps.spinnerCounter) {
        // up
        this._incrementMin();
      } else if (this.state.spinnerValue > nextProps.spinnerCounter) {
        // down
        this._decrementMin();
      }
      this.setState({ spinnerValue: nextProps.spinnerCounter });
    }

    if (nextProps.defaultVal !== this.props.defaultVal) {
      this.setState({
        value: this._massageMinuteText(nextProps.defaultVal),
      });
    }
  },

  render: function () {
    const classes = React.addons.classSet({
      MinutePicker: true,
      focused: this.state.focused,
    });

    return (
      <div className={classes}>
        <i
          className="fa fa-angle-up"
          onClick={this.touchProxy.bind(this, this._incrementMin, [])}
          onTouchEnd={this.touchProxy.bind(this, this._incrementMin, [])}
        />
        <div className="picker-text-input-container">
          <input
            className="picker-text-input"
            value={this.state.value}
            onChange={this._handleMinChange}
            onFocus={this.props.unfocusKeydown.bind(null, 1, this.props.isStartPicker)}
            onBlur={this._roundMinuteText}
          />
        </div>
        <i
          className="fa fa-angle-down"
          onClick={this.touchProxy.bind(this, this._decrementMin, [])}
          onTouchEnd={this.touchProxy.bind(this, this._decrementMin, [])}
        />
      </div>
    );
  },
});

module.exports = TimePickerMinutePicker;
