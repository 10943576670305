const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");

var Department = Backbone.Model.extend({
  idAttribute: "department_id",

  defaults: {
    available: true, // this will always be true currently
    filtered: false,
  },

  initialize: function () {
    // spawn child Templates
    for (var i = 0; i < this.attributes.templates.length; i++) {
      var templateAttributes = this.attributes.templates[i];
      templateAttributes.department_id = this.attributes.department_id;
      templateAttributes.is_hidden = this.attributes.is_hidden;
      var template = window.LbsAppData.Templates.add(templateAttributes);

      // attach a change event handler to this department's filtered attribute
      template.listenTo(this, "change:filtered", template.toggleAvailability);
    }
  },

  url: function () {
    return ApiConfig.getPrefix() + (this.id ? "/department/" + this.id : "/department");
  },

  _isHidden: function () {
    return this.attributes.is_hidden;
  },
});

module.exports = Department;
