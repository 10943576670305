import React from "react";

// Wrapper around useEffect to issue callback handler when clicked outside of the rect container of the ref object
export default function useOnClickOutside(
  parentRef: any,
  handler: any,
  ignoreClickEventTargetRefs: any[] = [],
  ignoreClickOutside = false
): void {
  const eventTargetIsIgnored = (event: MouseEvent | TouchEvent, targets: any[]): boolean => {
    return (
      !!targets?.length &&
      targets?.some(
        (targetRef: any) =>
          targetRef.current && (targetRef.current === event.target || targetRef.current.contains(event.target))
      )
    );
  };

  React.useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const ignoreEventTargets = [...ignoreClickEventTargetRefs, parentRef];

      if (ignoreClickOutside || eventTargetIsIgnored(event, ignoreEventTargets)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [parentRef, handler]);
}
