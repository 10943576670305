/*
 * Template model that doesn't depend on window object bindings */
const Backbone = require("backbone");

var NakedLocation = Backbone.Model.extend({
  idAttribute: "location_id",

  defaults: {},

  initialize: function (attributes, options) {},
});

module.exports = NakedLocation;
