export enum Device {
  Windows,
  Android,
  IOS,
  Desktop,
}

export const getDevice = (): Device => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return Device.Windows;
  }

  if (/android/i.test(userAgent)) {
    return Device.Android;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return Device.IOS;
  }

  return Device.Desktop;
};
