const Backbone = require("backbone");
var moment = require("moment-timezone");
var _ = require("underscore");
var $ = require("jquery");
var Promise = require("bluebird");

var Session = require("_lib/data/session/Session.js");
var Report = require("./Report.js");

const SafeJSON = require("../../../common/utils/jsonParseShield");
const reportSkeletonFactory = require("../factories/reportSkeletonFactory.ts").default;

var AppContext = Backbone.Model.extend({
  session: new Session(),
  //appSiblings: new Destinations(),

  //	dateContext: new DateContext(),

  defaults: {
    view: undefined,
    layout: "hybrid",
    //// settings-- it makes sense to 'namespace' them like this
    //// as opposed to having them inside a dictionary
    GlobalSettings_compactMode: false,
    GlobalSettings_hideExpired: false,
    GridSettings_leftColumnType: "assignment",
    //
    // ordering configuration
    slotOrdering: undefined,

    // displayed contexts
    contextualStack: [],
    // displayed dialogs
    dialogStack: [],
    // displayed menus
    displayedContextMenu: undefined,

    // focused elements
    focusedHolidayDate: undefined,
    focusedSlot: undefined,
    focusedPane: undefined,

    // toast messages
    toastStack: [],

    // bulk mode -- the last bastion of unflexiness falls
    mode: "view",

    // today view isFetching flag (to make sure we don't break during a redraw)
    isFetching: false,

    // active plugins
    plugins: {},
  },

  initialize: function (attributes, options) {
    var pendingView = window.payload.view_context;
    // screen width hack for default views....since a user can only have 1 default view
    // if they are on the mobile layout we need to override the layout of their default view if it is not today/me or == calendar
    // update...let gantt through untouched...that's a pretty slick view we want to promote for mobile formats
    if (
      pendingView.view_id !== "me" &&
      pendingView.view_id !== "today" &&
      pendingView.theme.data.layout !== "gantt" &&
      pendingView.theme.data.layout !== "list"
    ) {
      if ($(window).width() < 768) {
        // indiana jones hanking that idol from the pedestal
        pendingView.theme.data.layout = "calendar";
        this.attributes.layout = "calendar";

        // also since this sort of works good...let's allow them to select any views...but just force them into a day range
        // on anything but the 'me' view basically (since today and gantt are already locked into a single day)
        //pendingView.theme.data.range = 'day';
        //this.attributes.range = 'day';

        // same idea as above, but we're going to try and heuristically determine if this is necessary
        // avoiding the overhead of json parsing -- that's probably not necessary but we do what we can
        var slot_count = parseInt(window.payload.schedule_data.match(/\"slot_count\":(.*?),/)[1], 10);
        switch (pendingView.theme.data.range) {
          case "week":
            if (slot_count > 500) {
              // set it to a day range
              pendingView.theme.data.range = "day";
              this.attributes.range = "day";
            }
            break;
          case "month":
            if (slot_count > 500 * 4) {
              // need to fall hard -- set it into a day range
              pendingView.theme.data.range = "day";
              this.attributes.range = "day";
            } else if (slot_count > 500) {
              // need to fall back to week range
              pendingView.theme.data.range = "week";
              this.attributes.range = "week";
            }
            break;
          default:
            // do nothing
            break;
        }
      }
    }

    this.attributes.view = pendingView;

    // localization settings
    var user = SafeJSON.parse(window.payload.user);
    var locale = user.locale ? user.locale : "en";
    moment.locale(locale);
    // going to hijack the 'L' formatting string to strip the year... this could be pandora's box but we don't use it anywhere so I think it's fine
    // this provides the grid headers with locale-specific formats and doesn't require us to constantly strip the year when it comes down
    var formatString = moment
      .localeData(locale)
      .longDateFormat("L")
      .replace(/(\/|\.|\-)?YYYY(\/|\.|\-)?/, "");
    moment.localeData(locale)._longDateFormat["L"] = formatString;

    // set a default timezone on the moment object (if specific...otherwise just use the default (local))
    if (user.tz) {
      moment.tz.setDefault(user.tz);
    }

    this.setMomentLocale(locale);

    // scrub the url to check if we're activating any 'plugins'
    this._activatePlugins();
  },

  setMomentLocale: function (locale) {
    const startOnDay = this.get("startOnDay");
    switch (startOnDay) {
      case "Mon":
        moment.locale(locale, {
          week: {
            dow: 1, // start day of week
            doy: 4, // week that contains Jan 4th is the first week of the year
          },
        });
        break;
      case "Sun":
        moment.locale(locale, {
          week: {
            dow: 0, // start day of week
            doy: 4, // week that contains Jan 4th is the first week of the year
          },
        });
        break;
      case "Sat":
        moment.locale(locale, {
          week: {
            dow: 6, // start day of week
            doy: 4, // week that contains Jan 4th is the first week of the year
          },
        });
        break;
    }
  },

  registerEvents: function () {
    window.LbsAppData.Templates.on(
      "change:filteredModel",
      window.LbsAppData.AppContext.pushTemplateContext.bind(this, null),
      this
    );
    window.LbsAppData.AppContext.on("fetch:failed", window.LbsAppData.AppContext.failedToFetchEventHandler.bind(this));
    // refresh data
    window.LbsAppData.DateManager.on("change:dates", window.LbsAppData.AppContext.fetchData.bind(this, null), this);
  },

  failedToFetchEventHandler: function () {
    this.clearDialogs();
    this.openDialog({ type: "message", message: "Failed to fetch view data." });
    setTimeout(() => {
      this.clearDialogs();
    }, 2000);
  },
  _syncBackbone: function (callback) {
    this.session._syncBackbone(callback);
  },

  _activatePlugins: function () {
    // secure message providers
    var re = /[?&]sm_p=([\w]*)&?/;
    var match = window.location.search.match(re);
    if (match) {
      switch (match[1]) {
        case "voalte":
          this.attributes.plugins["sm_p"] = "voalte";
          break;
        default:
          break;
      }
    }
  },

  pushContext: function (contextObject) {
    // check to see if the item already exists
    var item = _.findWhere(this.attributes.contextualStack, {
      tag: contextObject.tag,
    });
    if (!item) {
      this.attributes.contextualStack.push(contextObject);
    } else {
      // update it instead of pushing a new one
      this.updateContextualItem(contextObject.tag, contextObject);
    }

    this.trigger("change:contextualStack");
  },
  popContext: function () {
    var contextualObject = this.attributes.contextualStack.pop();
    this.trigger("change:contextualStack");
  },
  removeContext: function (tag) {
    // find the index
    var index = this.attributes.contextualStack.findIndex(function (i) {
      return i.tag == tag;
    });
    if (index > -1) {
      // exists
      this.attributes.contextualStack.splice(index, 1);
      this.trigger("change:contextualStack");
    }
  },
  updateContextualItem: function (tag, attributes) {
    var item = _.findWhere(this.attributes.contextualStack, { tag: tag });
    for (var key in attributes) {
      item[key] = attributes[key];
    }
    this.trigger("change:contextualStack");
  },
  pushViewContext: function () {
    window.LbsAppData.AppContext.pushContext({
      tag: "view",
      text: this.attributes.view.name,
      tooltip: "open view (" + this.attributes.view.name + ")",
      textClass: "no-mobile",
      mobileIcon: "fa-bars",
      limitWidthClass: "limit-width-large",
      action: function () {
        window.LbsAppData.AppContext.openDialog({
          type: "view-options",
        });
      },
    });
  },

  pushTemplateContext: function () {
    var item = _.findWhere(this.attributes.contextualStack, {
      tag: "today-template",
    });
    if (!item) {
      // push a new context item
      window.LbsAppData.AppContext.pushContext({
        tag: "today-template",
        text: _.findWhere(window.LbsAppData.Templates.models, function (item) {
          return !item.attributes.filtered;
        }),
        tooltip: "shown template(s)",
        textClass: "no-mobile",
        mobileIcon: "fa-filter",
        action: window.LbsAppData.AppContext.openDialog.bind(this, {
          type: "view-options",
          tab: "template",
        }),
      });
    }
    var shownTemplates = _.filter(window.LbsAppData.Templates.models, function (item) {
      return !item.attributes.filtered;
    });

    if (shownTemplates.length == 1) {
      this.updateContextualItem("today-template", {
        text: shownTemplates[0].attributes.name,
      });
    } else {
      this.updateContextualItem("today-template", {
        text: shownTemplates.length + " " + "templates",
      });
    }
  },

  // mode switch
  changeMode: function () {
    if (window.LbsAppData.ToolBox.get("currentTool")) {
      switch (window.LbsAppData.ToolBox.get("currentTool").get("type")) {
        case "bulk-select":
          this.triggerSwappingMode();
          break;
        default:
          break;
      }
    } else {
      this.triggerViewingMode();
    }
  },
  triggerSwappingMode: function () {
    // make sure we have any permissions
    if (
      window.LbsAppData.Helpers.Permissions.CanIChangeAnyonesSchedule() ||
      window.LbsAppData.Helpers.Permissions.CanIChangeAnyonesRequests()
    ) {
      window.LbsAppData.ToolBox.selectTool("bulk-select");
      this.set({ mode: "bulk", focusedPane: { type: "edit" } });
      return true;
    } else {
      return false;
    }
  },
  triggerViewingMode: function () {
    // if the bulk context is there remove it
    //this.removeContext('bulk');
    window.LbsAppData.ToolBox.selectTool(undefined);
    this.set({ mode: "view", focusedPane: undefined });
  },
  openTemplate: function (id) {
    // update the template list
    window.LbsAppData.Slots.attributes.template_list = [id];
    window.LbsAppData.Requests.attributes.template_list = [id];
    // show/hide the appropriate templates
    var oldTemplate = window.LbsAppData.Templates.findWhere({
      filtered: false,
    });
    if (oldTemplate) {
      oldTemplate.set({ filtered: true });
    }

    var newTemplate = window.LbsAppData.Templates.get(id);
    newTemplate.set({ filtered: false });

    this.pushTemplateContext();

    // fetch the data after the session is initialized -- kinda trying to avoid while(true) but maybe
    // that is actually preferred over this if it doesn't lock up the UI thread which i'm guessing it does
    // ... not sure
    var that = this;
    var intervalId = window.setInterval(function () {
      if (that.session.get("auth")) {
        // good to go
        window.clearInterval(intervalId);
        that.fetchData();
      }
    }, 100);
  },

  _isGrantAvailable: function () {
    // All slots must be pending

    var slots = _.map(window.LbsAppData.ToolBox.get("currentTool").get("targets"), function (val, key) {
      return val;
    });
    var pending = 0;
    var nonpending = 0;
    for (var i = 0, len = slots.length; i < len; i++) {
      if (slots[i].get("is_pending")) {
        pending++;
      } else {
        nonpending++;
      }
    }
    if (pending > 0 && nonpending == 0) {
      return true;
    } else {
      return false;
    }
  },
  _isDenyAvailable: function () {
    return this._isGrantAvailable();
  },
  _isModifyAvailable: function () {
    // No pending slots

    var slots = _.map(window.LbsAppData.ToolBox.get("currentTool").get("targets"), function (val, key) {
      return val;
    });
    for (var i = 0, len = slots.length; i < len; i++) {
      if (slots[i].get("is_pending")) return false;
    }
    return true;
  },
  _isFillAvailable: function () {
    return false;
  },
  _isReplaceAvailable: function () {
    // No pending slots
    // Only same personnel or same assignment

    var slots = _.map(window.LbsAppData.ToolBox.get("currentTool").get("targets"), function (val, key) {
      return val;
    });

    var p = [];
    var a = [];
    for (var i = 0, len = slots.length; i < len; i++) {
      if (slots[i].get("is_pending")) return false;

      if (!_.include(p, slots[i].get("emp_id"))) p.push(slots[i].get("emp_id"));
      if (!_.include(a, slots[i].get("assign_id"))) a.push(slots[i].get("assign_id"));
    }
    if (a.length == 1 || p.length == 1) return true;
    return false;
  },
  _isExchangeAvailable: function () {
    // No pending slots
    // Requires exactly 2 personnel

    var slots = _.map(window.LbsAppData.ToolBox.get("currentTool").get("targets"), function (val, key) {
      return val;
    });
    var p = [];
    for (var i = 0, len = slots.length; i < len; i++) {
      if (slots[i].get("is_pending")) return false;
      if (!_.include(p, slots[i].get("emp_id"))) p.push(slots[i].get("emp_id"));
    }
    if (p.length == 2) return true;
    return false;
  },
  _isRemoveAvailable: function () {
    return true;
  },
  _getAvailableBulkActions: function () {
    var that = this;
    var ret = [];
    var test = true;
    if (test && this._isGrantAvailable()) {
      ret.push({
        title: "Grant",
        id: window.LbsAppData.GRANT_ACTION,
        onClick: window.LbsAppData.AppContext.openDialog.bind(this, {
          type: "bulk-action",
          action: window.LbsAppData.GRANT_ACTION,
          slots: window.LbsAppData.ToolBox.get("currentTool").get("targets"),
        }),
        extClassFn: function () {
          return that._isGrantAvailable() ? "hidden" : "";
        },
        icon: "fa-thumbs-o-up",
      });
    }
    if (test && this._isDenyAvailable()) {
      ret.push({
        title: "Deny",
        id: window.LbsAppData.DENY_ACTION,
        onClick: window.LbsAppData.AppContext.openDialog.bind(this, {
          type: "bulk-action",
          action: window.LbsAppData.DENY_ACTION,
          slots: window.LbsAppData.ToolBox.get("currentTool").get("targets"),
        }),
        extClassFn: function () {
          return that._isDenyAvailable() ? "hidden" : "";
        },
        icon: "fa-thumbs-o-down",
      });
    }
    if (test && this._isModifyAvailable()) {
      ret.push({
        title: "Modify",
        id: window.LbsAppData.MODIFY_ACTION,
        onClick: window.LbsAppData.AppContext.openDialog.bind(this, {
          type: "bulk-action",
          action: window.LbsAppData.MODIFY_ACTION,
          slots: window.LbsAppData.ToolBox.get("currentTool").get("targets"),
        }),
        extClassFn: function () {
          return that._isModifyAvailable() ? "hidden" : "";
        },
        icon: "fa-edit",
      });
    }
    if (test && this._isFillAvailable()) {
      ret.push({
        title: "Fill",
        id: window.LbsAppData.FILL_ACTION,
        onClick: window.LbsAppData.AppContext.openDialog.bind(this, {
          type: "bulk-action",
          action: window.LbsAppData.FILL_ACTION,
          slots: window.LbsAppData.ToolBox.get("currentTool").get("targets"),
        }),
        extClassFn: function () {
          return that._isFillAvailable() ? "hidden" : "";
        },
        icon: "fa-sign-in",
      });
    }
    if (test && this._isReplaceAvailable()) {
      ret.push({
        title: "Replace",
        id: window.LbsAppData.REPLACE_ACTION,
        onClick: window.LbsAppData.AppContext.openDialog.bind(this, {
          type: "bulk-action",
          action: window.LbsAppData.REPLACE_ACTION,
          slots: window.LbsAppData.ToolBox.get("currentTool").get("targets"),
        }),
        extClassFn: function () {
          return that._isReplaceAvailable() ? "hidden" : "";
        },
        icon: "fa-exchange",
      });
    }
    if (test && this._isExchangeAvailable()) {
      ret.push({
        title: "Exchange",
        id: window.LbsAppData.EXCHANGE_ACTION,
        onClick: window.LbsAppData.AppContext.openDialog.bind(this, {
          type: "bulk-action",
          action: window.LbsAppData.EXCHANGE_ACTION,
          slots: window.LbsAppData.ToolBox.get("currentTool").get("targets"),
        }),
        extClassFn: function () {
          return that._isExchangeAvailable() ? "hidden" : "";
        },
        icon: "fa-arrows-h",
      });
    }
    if (test && this._isRemoveAvailable()) {
      ret.push({
        title: "Remove",
        id: window.LbsAppData.REMOVE_ACTION,
        onClick: window.LbsAppData.AppContext.openDialog.bind(this, {
          type: "bulk-action",
          action: window.LbsAppData.REMOVE_ACTION,
          slots: window.LbsAppData.ToolBox.get("currentTool").get("targets"),
        }),
        extClassFn: function () {
          return that._isRemoveAvailable() ? "hidden" : "";
        },
        icon: "fa-sign-out",
      });
    }

    return ret;
  },

  focusHolidayDate: function (date) {
    if (this._isHoliday(date.data) && !this.attributes.displayedContextMenu) {
      this.set({ focusedHolidayDate: date });
    }
  },
  unfocusHolidayDate: function (date) {
    if (this.attributes.focusedHolidayDate) {
      this.set({ focusedHolidayDate: date });
    }
  },
  _isHoliday: function (date) {
    var holidays = window.LbsAppData.Holidays.models;
    for (var i = 0; i < holidays.length; i++) {
      if (holidays[i].attributes.holiday_date && date.isSame(holidays[i].attributes.holiday_date, "day")) {
        return true;
      }
    }

    return false;
  },
  expandFocusedHolidayDate: function (date) {
    this.openDialog({ type: "holidays", date: date });
    this.unfocusHolidayDate();
  },
  focusSlot: function (slot) {
    if (!this.attributes.focusedSlot && !this.attributes.displayedContextMenu) {
      this.set({ focusedSlot: slot });
    }
  },
  unfocusSlot: function () {
    if (this.attributes.focusedSlot && !this.attributes.focusedSlot.expanded) {
      this.set({ focusedSlot: undefined });
    }
  },
  expandFocusedSlot: function (slot, openPager) {
    if (!slot) {
      // proxy the data into the slot-details dialog
      var slot = this.attributes.focusedSlot.data;
      this.openDialog({ type: "slot-details", slot: slot });

      this.unfocusSlot();
    } else {
      // expand the passed in slot/request
      var dialogType = slot.attributes.slot_date ? "slot-details" : "request-details";

      this.openDialog({ type: dialogType, data: slot, pager: openPager });
    }
  },
  closeExpandedSlot: function () {
    this.closeDialog();
  },

  // displayers
  openDialog: function (object) {
    this.attributes.dialogStack.push(object);
    this.trigger("pushedDialog");
  },
  closeDialog: function (type, noEvent) {
    if (this.attributes.dialogStack.length) {
      if (!type) {
        var dialog = this.attributes.dialogStack.pop();
        if (!noEvent) {
          this.trigger("poppedDialog");
        }
      } else {
        // close a specific dialog
        var index = _.findIndex(this.attributes.dialogStack, function (item) {
          return item.type == type;
        });
        if (index > -1) {
          this.attributes.dialogStack.splice(index, 1);
          if (!noEvent) {
            this.trigger("poppedDialog");
          }
        }
      }
    }
  },
  closeDialogTo: function (id, clearAllIfNotFound, keepIds) {
    // turn keepIds into an array if necessary
    keepIds = _.isString(keepIds) ? [keepIds] : keepIds;

    var closedDialogs;
    if (_.isString(id)) {
      // type identifier -- find it
      var idx = _.findIndex(this.attributes.dialogStack, function (ds) {
        return ds.type == id;
      });
      if (idx > -1) {
        closedDialogs = this.attributes.dialogStack.splice(idx + 1);
      } else if (clearAllIfNotFound) {
        this.clearDialogs(keepIds);
        return;
      }
    } else {
      // integer -- splice the id+1 (think splice is inclusive so it's 1 based)
      closedDialogs = this.attributes.dialogStack.splice(id + 1);
    }

    // make sure we didn't pop anything we want to keep (i.e. errors above details)
    if (keepIds) {
      for (var i = 0; i < closedDialogs.length; i++) {
        var dialogToKeep = _.find(keepIds, function (k) {
          return k == closedDialogs[i].type;
        })
          ? closedDialogs[i]
          : undefined;
        if (dialogToKeep) {
          this.attributes.dialogStack.push(dialogToKeep);
        }
      }
    }
    // trigger event
    this.trigger("poppedDialog");
  },
  clearDialogs: function (keepIds) {
    var newStack = [];
    if (keepIds) {
      var closedDialogs = this.attributes.dialogStack;
      for (var i = 0; i < closedDialogs.length; i++) {
        var dialogToKeep = _.find(keepIds, function (k) {
          return k == closedDialogs[i].type;
        })
          ? closedDialogs[i]
          : undefined;
        if (dialogToKeep) {
          newStack.push(dialogToKeep);
        }
      }
    }

    this.attributes.dialogStack = newStack;
    this.trigger("poppedDialog");
  },

  openLoadingDialog: function (message, context, callback) {
    this.openDialog({ type: "message", message: message });

    if (callback) {
      window.setTimeout(function () {
        callback.call(context);
      }, 100);
    }
  },

  closeLoadingDialog: function () {
    // pretend like we did something that took a while basically...just so the user has time to see
    // something happened or is happening
    var that = this;
    window.setTimeout(function () {
      that.closeDialog();
    }, 500);
  },

  openContextMenu: function (contextMenuObj) {
    this.set({
      displayedContextMenu: contextMenuObj,
      focusedSlot: undefined,
      focusedHolidayDate: undefined,
    });
  },
  closeContextMenu: function () {
    this.set({
      displayedContextMenu: undefined,
    });
  },

  annoyDefaultView: function () {
    this.openDialog({
      type: "prompt",
      title: "No Default View",
      message:
        "We notice you do not have a default view. We suggest you set one so your experience is as streamlined as possible.",
      yesText: "Pick one",
      noText: "Cancel",
      callback: function () {
        window.LbsAppData.AppContext.closeDialog();
        window.LbsAppData.AppContext.openDialog({
          type: "view-options",
        });
      },
    });
  },

  // toast messages
  addToast: function (args) {
    this.attributes.toastStack.push(args);
    this.trigger("addToast");
  },
  popToast: function () {
    var el = this.attributes.toastStack.shift();
    this.trigger("popToast");
  },

  popToasts: function () {
    // this is complicated only because there is a corner case of changing tabs while
    // a message is mounted -- in which case we want to clear him on the next pass (at least)
    var nextToast = this.attributes.toastStack[0];
    while (nextToast && moment().valueOf() > nextToast.timeMounted + nextToast.duration) {
      var el = this.attributes.toastStack.shift();
      this.trigger("popToast");

      var nextToast = this.attributes.toastStack[0];
    }
  },

  // fetch data after a date change
  fetchData: function () {
    var that = this;

    // set a fetching flag
    this.set({ isFetching: true });

    var prelimPromises = [];
    // if this is a today view, get the personnel/assignments first
    if (window.LbsAppData.AppContext.get("view").view_id == "today") {
      // need to make sure we have a token before we do anything -- probably a cleaner way to do this
      // somewhere upstream but that's gotta take a refactor... band-aid #234
      while (!this.session.get("auth")) {
        // delay 100ms
        _.delay(undefined, 100);
      }

      // out of the while -- fetch stuff
      var fetchingTemplateId = window.LbsAppData.Slots.attributes.template_list[0];
      var fetchingDeptId = window.LbsAppData.Templates.get(fetchingTemplateId).get("department_id");
      if (_.indexOf(window.LbsAppData.Personnel.attributes.deptList, fetchingDeptId) == -1) {
        window.LbsAppData.Personnel.attributes.deptList = [fetchingDeptId];
        prelimPromises.push(
          new Promise(function (resolve, reject) {
            window.LbsAppData.Personnel.fetch({
              success: function (collection) {
                resolve();
              },
              reset: true,
            });
          })
        );
      }
      if (_.indexOf(window.LbsAppData.Assignments.attributes.tempList, fetchingTemplateId) == -1) {
        window.LbsAppData.Assignments.attributes.tempList = [fetchingTemplateId];
        prelimPromises.push(
          new Promise(function (resolve, reject) {
            window.LbsAppData.Assignments.fetch({
              success: function (collection) {
                resolve();
              },
              reset: true,
            });
          })
        );
      }
    }

    window.LbsAppData.Holidays.setDateParams(window.LbsAppData.DateManager);

    window.LbsAppData.Holidays.fetch({ reset: true });

    Promise.all(prelimPromises).then(function () {
      // collect the promised fetches
      var promises = [];
      // dataType is an added field...we might do some sort of converison thing...but if this value is not there...
      // assuming it to mean == combined
      // showing slots conditional

      if (
        ["schedule", "combined"].indexOf(window.LbsAppData.AppContext.attributes.view.theme.data.dataType) > -1 ||
        window.LbsAppData.AppContext.attributes.view.theme.data.dataType === undefined
      ) {
        promises.push(window?.LbsAppData?.Slots?._fetchSlots());
      }
      // showing requests conditional
      if (
        ["request", "combined"].indexOf(window.LbsAppData.AppContext.attributes.view.theme.data.dataType) > -1 ||
        window.LbsAppData.AppContext.attributes.view.theme.data.dataType === undefined
      ) {
        promises.push(window?.LbsAppData?.Requests?._fetchRequests());
      }
      // showing tallies conditional
      if (window.LbsAppData.Report) {
        promises.push(window.LbsAppData.AppContext.fetchReport());
      }

      Promise.all(promises)
        .then(function () {
          that.set({ isFetching: false });
          that.trigger("fetched");
        })
        .catch((err) => {
          that.trigger("fetch:failed");
        });
    });
  },

  // trigger a redraw on the schedule container
  triggerRedraw: function () {
    this.trigger("redraw");
  },

  // helpers
  getLeftColumnType: function () {
    return window.LbsAppData.AppContext.attributes.GridSettings_leftColumnType;
  },
  getLeftColumnCollection: function () {
    if (this.getLeftColumnType() == "assignment") {
      return {
        collection: window.LbsAppData.Assignments,
        title: window.LbsAppData.Assignments.attributes.title,
        models: window.LbsAppData.Assignments.models,
      };
    } else {
      return {
        collection: window.LbsAppData.Personnel,
        title: window.LbsAppData.Personnel.attributes.title,
        models: window.LbsAppData.Personnel.where({ scheduled: true }),
      };
    }
  },
  getLeftColumnCollectionExcludeExpired: function () {
    if (this.getLeftColumnType() == "assignment") {
      return {
        collection: window.LbsAppData.Assignments,
        title: window.LbsAppData.Assignments.attributes.title,
        models: window.LbsAppData.Assignments.where({ expired: false }),
      };
    } else {
      return {
        collection: window.LbsAppData.Personnel,
        title: window.LbsAppData.Personnel.attributes.title,
        models: window.LbsAppData.Personnel.where({
          scheduled: true,
          expired: false,
        }),
      };
    }
  },
  getViewDataType: function () {
    return window.LbsAppData.AppContext.attributes.view.theme.data.dataType;
  },
  forceRequestsGroup: function () {
    return (
      "request|combined".indexOf(this.getViewDataType()) > -1 && this.attributes.view.theme.data.hideBlankRows == "no"
    );
  },
  forceScheduleGroup: function () {
    return (
      "schedule|combined".indexOf(this.getViewDataType()) > -1 && this.attributes.view.theme.data.hideBlankRows == "no"
    );
  },
  fetchReport: function () {
    const dates = window.LbsAppData.DateManager.getFocusedDates();
    const reportSkeleton = reportSkeletonFactory(dates.startWithCushion.toDate(), dates.stopWithCushion.toDate());
    // fill-ins from the view
    reportSkeleton._definition.tallies =
      reportSkeleton._composition.nodes["0"].def.tally_ids =
      reportSkeleton._composition.nodes["1"].def.y.ids =
        _.map(this.get("view").filter.on_tallies, "id");
    reportSkeleton._definition.templates = reportSkeleton._composition.nodes["0"].def.template_ids =
      this.get("view").filter.on_templates;
    reportSkeleton._definition.departments = reportSkeleton._composition.nodes["0"].def.department_ids =
      this.get("view").filter.on_departments;
    reportSkeleton._definition.properties.data.pub_admin = reportSkeleton._composition.nodes["0"].def.pub_admin =
      window.LbsAppData.User.get("is_admin");
    reportSkeleton._composition.nodes["0"].def.emp_ids = window.LbsAppData.Personnel.map((p) => p?.attributes.emp_id);

    // set the report type to be the same as the view type
    const reportType = this.get("view").theme.data.dataType;
    reportSkeleton._definition.properties.data.report_type = reportSkeleton._composition.nodes["0"].def.report_type =
      reportType;

    const newReport = new Report({
      _definition: reportSkeleton._definition,
      _composition: reportSkeleton._composition,
    });

    return new Promise(function (resolve, reject) {
      newReport.save(
        {},
        {
          success: function (model) {
            delete window.LbsAppData.Report;
            window.LbsAppData.Report = newReport;

            resolve();
          },
        }
      );
    });
  },
});

module.exports = AppContext;
