import React from "react";

import { TallyResult, ReportData, ReportResult } from "@/viewer/types/domain/report";
import TallyDisplay from "@/viewer/ui/modules/common/components/TallyDisplay";
import { SettingsContext } from "@/viewer/ui/modules/common/types/context";
import { ViewData } from "@/viewer/types/viewdata";

import { getTallyDataForDayAndEmp } from "@/viewer/utils/tallies";
import classnames from "@/_lib/utils/classnames";
import { getDay } from "date-fns";

import "./_styles.scss";

interface Props {
  cellDate: Date;
  dayKey: any;
  settings: SettingsContext;
  viewData: ViewData;
  reportData?: ReportData;
  reportResult?: ReportResult;
  tallies?: TallyResult[];
}

const PrintColumnTallyItems = (props: Props): JSX.Element => {
  const { cellDate, dayKey, reportData, reportResult, settings, tallies, viewData } = props;
  const { condenseColumnView, shouldCountTalliesByPersonnelFilter } = settings;
  const { filteredPersonnel } = viewData.filters;

  if (!tallies?.length) return <></>;

  const tallyItems: JSX.Element[] = [];
  const reportDataForSlot = reportData?.data_by_day[dayKey];
  const slotDay = getDay(cellDate);
  const cellClassNames = classnames("cell", {
    "cell--weekend": slotDay === 0 || slotDay === 6,
    "cell--condensed": condenseColumnView,
  });

  tallies.forEach(({ tally_id: tallyId, tally_name: tallyName }) => {
    const target = reportDataForSlot?.targets[tallyId];

    let tallyCellData: number | undefined;

    // If target exists then it's using AO and we don't want to filter on personnel
    if (shouldCountTalliesByPersonnelFilter && !target && reportData) {
      tallyCellData = getTallyDataForDayAndEmp(tallyId, dayKey, reportData, filteredPersonnel);
    } else {
      tallyCellData = reportResult?.data[dayKey + "|" + tallyId];
    }

    tallyItems.push(
      <div key={`${tallyName}_${tallyId}_${slotDay}`} className={`column-print-cell-wrapper--tally ${cellClassNames}`}>
        <TallyDisplay
          className="tally-cell"
          data={tallyCellData}
          target={target}
          tallyDate={cellDate}
          tallyName={tallyName}
        />
      </div>
    );
  });

  return <>{tallyItems}</>;
};

export default PrintColumnTallyItems;
