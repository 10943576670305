"use strict";

var React = require("react/addons");
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
var moment = require("moment-timezone");

require("./_styles.scss");

var SubscriptionCollection = require("login/data/collections/SubscriptionCollection.js");
var Subscription = require("login/data/models/Subscription.js");

var Subscription = React.createClass({
  mixins: [TouchMixin],

  getInitialState: function () {
    var subscriptionCollection = new SubscriptionCollection([], {
      emp_id: window.LbsAppData.User.get("emp_id"),
      dash: true,
    });

    return {
      isLoading: true,
      subscriptionCollection: subscriptionCollection,
    };
  },

  componentDidMount: function () {
    var that = this;
    if (window.LbsAppData.AppContext.session.get("auth")) {
      this._fetchSubscriptions();
    } else {
      window.LbsAppData.AppContext.once("authorized", function () {
        that._fetchSubscriptions();
      });
    }
  },

  _fetchSubscriptions: function () {
    var that = this;
    this.state.subscriptionCollection.fetch({
      success: function () {
        that.setState({ isLoading: false });
      },
    });
  },

  _openSubscribeDialog: function () {
    var that = this;
    window.LbsAppData.AppContext.openDialog({
      type: "subscribe",
      email: window.LbsAppData.User.get("email"),
      empID: window.LbsAppData.User.get("emp_id"),

      subscription:
        this.state.subscriptionCollection.models.length > 0 ? this.state.subscriptionCollection.models[0] : undefined,

      closingCallback: function () {
        // reload the subscription in order to get the possibly updated values
        that.setState({ isLoading: true });
        that._fetchSubscriptions();
      },
    });
  },

  render: function () {
    var classes = React.addons.classSet({
      Subscription: true,
    });

    var useAccessedDate = "n/a";
    if (
      this.state.subscriptionCollection.models.length > 0 &&
      this.state.subscriptionCollection.models[0].get("accessed_date")
    ) {
      // this time is stored in pacific time in the db...just do a lame parse/reparse here to get it in the users tz
      var tz = window.LbsAppData.User.get("tz") ? window.LbsAppData.User.get("tz") : "America/Los_Angeles";
      var locale = window.LbsAppData.User.get("locale") ? window.LbsAppData.User.get("locale") : "en-us";

      useAccessedDate = moment
        .tz(this.state.subscriptionCollection.models[0].get("accessed_date"), "America/Los_Angeles")
        .tz(tz)
        .locale(locale)
        .format("LLL z");
    }

    var insideContent = {};
    if (this.state.isLoading) {
      insideContent = (
        <div>
          <i className="fa fa-spinner fa-spin" />
        </div>
      );
    } else {
      insideContent = (
        <div>
          <div className="desc">
            <label>Current Status:</label>{" "}
            {this.state.subscriptionCollection.models.length > 0 ? "Subscribed" : "Not subscribed"}
            <br />
            <label>Last Accessed:</label> {useAccessedDate}
          </div>
        </div>
      );
    }

    return (
      <div className={classes}>
        <div className="title">
          <i className="fa fa-fw fa-calendar-o" />
          Sync with Calendar
        </div>
        <div
          className="content-item"
          onClick={this.touchProxy.bind(this, this._openSubscribeDialog, [])}
          onTouchEnd={this.touchProxy.bind(this, this._openSubscribeDialog, [])}
        >
          {insideContent}
        </div>
      </div>
    );
  },
});

module.exports = Subscription;
