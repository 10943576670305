/**
 * This file is a repository for utilities pertaining to Personnel or Assignments, which
 * are frequently used in the same position as PersonnelOrAssignment.
 *
 * Many of these functions correspond to methods on the Personnel/Assignment models
 */
import { CSSProperties } from "react";
import { Assignment } from "@/viewer/types/domain/assignment";
import { Personnel } from "@/viewer/types/domain/personnel";
import { FilterContext, SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { PersonnelOrAssignment } from "@/viewer/ui/modules/common/types";
import { isAfter } from "date-fns";

export const isAssignment = (d: PersonnelOrAssignment): d is Assignment => {
  return (d as Personnel).empId === undefined;
};

export const getPersonnelOrAssignmentId = (d: PersonnelOrAssignment): string => {
  if (isAssignment(d)) {
    return d.assignStructureId;
  }
  return d.empId.toString();
};

const personnelIsAvailable = ({ scheduled }: Personnel) => {
  // if an employee is scheduled, then they are available
  return scheduled;
};

export const isAvailable = (datum: PersonnelOrAssignment): boolean => {
  return isAssignment(datum) ? true : personnelIsAvailable(datum);
};

export const assignmentIsFiltered = (datum: Assignment, filters: FilterContext): boolean => {
  const id = getPersonnelOrAssignmentId(datum);
  if (filters.filteredAssignments[id]) {
    return true;
  }

  // check assignment's templates to see if any are unfiltered
  const { templates } = datum;
  if (templates.length === 0) {
    return false;
  }

  for (let i = 0; i < templates.length; i++) {
    if (filters.filteredTemplates[templates[i]] !== true) {
      return false;
    }
  }

  return true;
};

export const personnelIsFiltered = (datum: Personnel, filters: FilterContext): boolean => {
  const id = getPersonnelOrAssignmentId(datum);
  if (filters.filteredPersonnel[id]) {
    return true;
  }

  // check employee's departments to see if any are unfiltered
  const { departments } = datum;
  if (departments.length === 0) {
    return false;
  }

  for (let i = 0; i < departments.length; i++) {
    if (filters.filteredDepartments[departments[i]] !== true) {
      return false;
    }
  }

  return true;
};

export const isFiltered = (datum: PersonnelOrAssignment, filters: FilterContext): boolean => {
  return isAssignment(datum) ? assignmentIsFiltered(datum, filters) : personnelIsFiltered(datum, filters);
};

export const assignmentIsExpired = (datum: Assignment, ui: UIContext): boolean => {
  if (datum.lastRecurrenceDate) {
    const { startDateWithCushion } = ui;
    return isAfter(startDateWithCushion, datum.lastRecurrenceDate);
  } else {
    return datum.expired;
  }
};

export const personnelIsExpired = (datum: Personnel, ui: UIContext): boolean => {
  if (datum.lastActiveDate) {
    const { startDateWithCushion } = ui;
    return isAfter(startDateWithCushion, datum.lastActiveDate);
  } else {
    return datum.expired;
  }
};

export const isExpired = (datum: PersonnelOrAssignment, ui: UIContext): boolean => {
  return isAssignment(datum) ? assignmentIsExpired(datum, ui) : personnelIsExpired(datum, ui);
};

export const isVisible = (datum: PersonnelOrAssignment, ui: UIContext, filters: FilterContext): boolean => {
  if (!isAvailable(datum)) {
    return false;
  } else if (isFiltered(datum, filters)) {
    return false;
  } else if (isExpired(datum, ui)) {
    return false;
  }

  return true;
};

// If a datum is filtered (such as by checking the item in
// the list filter component), the datum will not pass the isFiltered
// requirement of isVisible and will not be shown in the item list.
export const isSelectable = (datum: PersonnelOrAssignment, ui: UIContext): boolean => {
  return isAvailable(datum) && !isExpired(datum, ui);
};

export const getEffects = (
  obj: PersonnelOrAssignment,
  { colorMethod, colorTextMethod }: SettingsContext,
  force = false
): CSSProperties => {
  const returnValue: CSSProperties = {};

  const { color, colorText } = obj;

  if ((colorMethod === "cell" && force) || colorMethod === "mixed" || colorMethod === "row") {
    if (color) {
      returnValue["backgroundColor"] = color;
    }
  }

  if (colorText && colorTextMethod === "colored") {
    if (colorText) {
      returnValue["color"] = colorText;
    }
  }

  return returnValue;
};
