import moment, { Moment } from "moment";

// This function checks if startDate or stopDate are within the date range specified
// by scheduleStartDate and scheduleStopDate separately
export const startOrStopDateIsInScheduleDateRange = (
  startDate: Moment,
  stopDate: Moment,
  scheduleStartDate: Moment,
  scheduleStopDate: Moment
): boolean => {
  return (
    (scheduleStartDate.isBefore(startDate) && scheduleStopDate.isAfter(startDate)) ||
    (scheduleStartDate.isBefore(stopDate) && scheduleStopDate.isAfter(stopDate))
  );
};

/**
 * Checks if the given date is within the specified date range.
 * @param Date - Date to check is within the given range and padding.
 * @param Start - Start of date range.
 * @param Stop - Stop of date range.
 * @param StartPadding - Amount of the padding unit to subtract from the start of the date range. (Defaults to 0)
 * @param StopPadding - Amount of the padding unit to add to the end of the date range. (Defaults to 0)
 * @param PaddingUnit - MomentJS unit of time difference. (Defaults to days)
 */

interface IPaddedRangeOptions {
  date: Date | moment.Moment;
  start: Date | moment.Moment;
  stop: Date | moment.Moment;
  startPadding?: number | string;
  stopPadding?: number | string;
  paddingUnit?: moment.unitOfTime.Diff;
}
/**
 * Determines if a date is within a padded date range. Note: The start and end of the
 * padded range will not be included in the comparison.
 *
 * @param date : The date to check
 * @param start: Start date of the range
 * @param stop : Stop date of the range
 * @param startPadding: Number of padding units to add to the start date (Default is 0)
 * @param stopPadding: Number of padding units to add to the end date (Default is 0)
 * @param paddingUnit: A moment.unitOfTime.Diff unit to use as padding (Default is days)
 * @returns True if date is in the range and false if the date is not within the range.
 */
export const isDateInPaddedRange = ({
  date,
  start,
  stop,
  startPadding = 0,
  stopPadding = 0,
  paddingUnit = "days",
}: IPaddedRangeOptions): boolean => {
  const startWithPadding = moment(start).utc(false).subtract(startPadding, paddingUnit);
  const stopDateWithPadding = moment(stop).utc(false).add(stopPadding, paddingUnit);

  return moment(date).isBetween(startWithPadding, stopDateWithPadding, undefined, "[]");
};
