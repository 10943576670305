const Backbone = require("backbone");
const ApiConfig = require("_lib/data/ApiConfig.js");
const Subscription = require("./../models/Subscription.js");
const _ = require("lodash");

const SubscriptionCollection = Backbone.Collection.extend({
  model: Subscription,

  initialize: function (models, attributes) {
    this.attributes = {};

    for (const key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    const queryParams = {};

    if (this.attributes.emp_id) {
      queryParams["emp_id"] = this.attributes.emp_id;
    }

    if (this.attributes.dash) {
      queryParams["dash"] = this.attributes.dash;
    }

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    return (
      ApiConfig.getPrefix() +
      "/subscription?" +
      _.map(cleanQueryParams, function (value, key) {
        return key + "=" + value;
      }).join("&")
    );
  },
});

module.exports = SubscriptionCollection;
