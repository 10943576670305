import * as Sentry from "@sentry/react";

const NOT_AVAILABLE = "N/A";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function injectInstrumentation() {
  const {
    customer_id = NOT_AVAILABLE,
    customer_name = NOT_AVAILABLE,
    emp_id = NOT_AVAILABLE,
    is_admin = NOT_AVAILABLE,
    is_super_admin = NOT_AVAILABLE,
    user_id = NOT_AVAILABLE,
    user_name = NOT_AVAILABLE,
  } = window?.LbsAppData?.User?.attributes ?? {};

  const viewId = window?.LbsAppData?.AppContext?.attributes?.view?.view_id ?? NOT_AVAILABLE;

  // Context is not loaded so skip this call
  if (viewId === NOT_AVAILABLE && emp_id === NOT_AVAILABLE) return;

  // Inject attributes into New Relic
  window?.newrelic?.setCustomAttribute("viewId", viewId);
  window?.newrelic?.setCustomAttribute("userId", user_id.toString());
  window?.newrelic?.setCustomAttribute("userName", user_name);
  window?.newrelic?.setCustomAttribute("empId", emp_id.toString());
  window?.newrelic?.setCustomAttribute("customerId", customer_id.toString());
  window?.newrelic?.setCustomAttribute("customerName", customer_name.toString());
  window?.newrelic?.setCustomAttribute("isAdmin", is_admin.toString());
  window?.newrelic?.setCustomAttribute("isSuperAdmin", is_super_admin.toString());

  // Inject attributes into Sentry
  Sentry.setUser({
    id: user_id,
    username: user_name,
  });

  Sentry.setTag("customer_id", customer_id);
  Sentry.setTag("customer_name", customer_name);
  Sentry.setTag("personnel_id", emp_id);
  Sentry.setTag("is_admin", is_admin);
  Sentry.setTag("is_super_admin", is_super_admin);
  Sentry.setTag("view_id", viewId);
}

export default injectInstrumentation;
