const Backbone = require("backbone");
const ApiConfig = require("@/_lib/data/ApiConfig.js");
const logging = require("@/_lib/utils/logging").default;

const Assignment = Backbone.Model.extend({
  idAttribute: "assign_structure_id",

  defaults: {
    boundTool: undefined,

    available: true,
    filtered: false,
    forced: false,
  },

  initialize: function () {
    // attach event listeners to this assignment's template object
    for (let i = 0; i < this.attributes.templates.length; i++) {
      const template = window.LbsAppData.Templates.get(this.attributes.templates[i]);

      if (template) {
        this.listenTo(template, "change:available", this.setAvailability);
        this.listenTo(template, "change:filtered", this.setAvailability);
        this.listenTo(template, "change:filteredModel", this.setAvailability);
      }
    }

    // default the order to display_name
    //this.attributes.order = this.attributes.display_name.toLowerCase();

    // hide expired setting would cause things to possibly become unavailable
    this.listenTo(window.LbsAppData.AppContext, "change:GlobalSettings_hideExpired", this.setAvailability);
  },

  setAvailability: function (eventObj) {
    const availability = this._checkAvailability(eventObj);

    if (availability !== this.attributes.available) {
      this.set({ available: availability });
    }
  },

  getEffects: function (forceColor) {
    if (this.attributes.boundTool) {
      // tool takes precedence
      return this.attributes.boundTool.style();
    } else {
      const ret = {};

      if (
        (forceColor && window.LbsAppData.AppContext.attributes.colorMethod === "cell") ||
        "mixed|row".indexOf(window.LbsAppData.AppContext.attributes.colorMethod) > -1
      ) {
        // view is set to color rows
        if (this.attributes.color) {
          ret["backgroundColor"] = this.attributes.color;
        }
      }

      if (window.LbsAppData.AppContext.attributes.colorTextMethod === "colored") {
        // view is set to color text
        if (this.attributes.colorText) {
          ret["color"] = this.attributes.colorText;
        }
      }

      return ret;
    }
  },

  _checkAvailability: function () {
    // if assignment is expired AND user is set to hide expired, then they are unavailable
    if (this.attributes.expired && window.LbsAppData.AppContext.attributes.GlobalSettings_hideExpired) {
      return false;
    }

    // check assigment's templates to see if any are unfiltered (and available?)
    for (let i = 0; i < this.attributes.templates.length; i++) {
      const template = window.LbsAppData.Templates.get(this.attributes.templates[i]);

      if (template !== undefined && template.attributes.available && !template.attributes.filtered) {
        // found one
        return true;
      }
    }

    return false;
  },

  // following functions exist because we condense the assignment index (to make it view 'nicer')
  // that means we need additional data lookups to tell what assignment we are actually dealing with
  _isAssignmentOnTemplate: function (template_id) {
    return this.attributes.templates.indexOf(template_id) > -1;
  },

  _getAssignmentIDForTemplate: function (template_id) {
    return this.attributes.template_to_assign_id_map[template_id];
  },

  _getAssignmentTypeForTemplate: function (template_id) {
    return this.attributes.template_to_assign_type_map[template_id];
  },

  _getAssignmentDefaultStartTime: function (template_id) {
    let returnVal = undefined;

    if (this.attributes.template_to_default_times_map[template_id] !== undefined) {
      if (this.attributes.template_to_default_times_map[template_id].split("~").length > 1) {
        returnVal = this.attributes.template_to_default_times_map[template_id].split("~")[0];
      }
    }
    return returnVal;
  },

  _getAssignmentDefaultStopTime: function (template_id) {
    let returnVal = undefined;

    if (this.attributes.template_to_default_times_map[template_id] !== undefined) {
      if (this.attributes.template_to_default_times_map[template_id].split("~").length > 1) {
        returnVal = this.attributes.template_to_default_times_map[template_id].split("~")[1];
      }
    }
    return returnVal;
  },

  _isExpired: function () {
    return this.attributes.expired;
  },

  url: function () {
    if (!this.id) {
      logging.error(
        `[NakedAssignment.js - /assignments/condensed] idAttribute is missing for assignment: ${this?.attributes?.display_name}`
      );

      return "";
    }

    return ApiConfig.getPrefix() + "/assignments/condensed" + this.id;
  },
});

module.exports = Assignment;
