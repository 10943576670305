export interface AccessibleAppsAttributes {
  viewer: boolean;
  maker: boolean;
  monitor: boolean;
  reports: boolean;
  analytics: boolean;
  view_design: boolean;
}

export interface AccessibleApps {
  viewer: boolean;
  editor: boolean;
  monitor: boolean;
  reports: boolean;
  analytics: boolean;
  viewManager: boolean;
}

export const cleanAccessibleAppsAttributes = (attrs: Partial<AccessibleAppsAttributes>): AccessibleApps => {
  return {
    viewer: attrs.viewer ?? false,
    editor: attrs.maker ?? false,
    monitor: attrs.monitor ?? false,
    reports: attrs.reports ?? false,
    analytics: attrs.analytics ?? false,
    viewManager: attrs.view_design ?? false,
  };
};
