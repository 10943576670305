import { Model } from "backbone";

import { SettingsContext } from "@/viewer/ui/modules/common/types/context";
import connectedPropertyHookFactory from "@/viewer/ui/modules/common/hooks/connectedPropertyHookFactory";

import { cleanSlotAttributes, SlotById } from "@/viewer/types/domain/slot";

const getSlots = (settings: SettingsContext): SlotById => {
  const { LbsAppData } = window as any;

  const slotCollection = LbsAppData.Slots;

  if (!slotCollection) return {};

  const out = slotCollection.reduce((list: SlotById, slot: Model) => {
    const cleanSlot = cleanSlotAttributes(settings, slot.attributes);
    list[cleanSlot.id] = cleanSlot;
    return list;
  }, {});

  return out;
};

export default connectedPropertyHookFactory({
  loader: getSlots,
  getListeners: () => {
    const { LbsAppData } = window as any;
    return [[LbsAppData.AppContext, "fetched"]];
  },
});
