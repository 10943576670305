import moment from "moment";
import { Moment } from "moment-timezone";

import { getBrowserTimezone } from "@/viewer/utils/timezones";
import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";

/***
 * For use from legacy JS and JSX code.
 *
 * Currently only used in the legacy JS/JSX List View implementation.
 */

export const SLOT_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const API_COMPACT_DATE_FORMAT = "yyyyMMdd";
export const AMERICAN_DATE_FORMAT = "MM-dd-yyyy";
export const TWELVE_HOUR_FORMAT = "hh:mm a";
export const ISO_8601_DATE_FORMAT = "yyyy-MM-dd";
export const MONTH_DAY_YEAR_FORMAT = "MM/dd/yyyy";

export const getNowV1 = (): Moment => {
  // gets the now with tz from the user
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tzEnabled = (window as any).LbsAppData.User.attributes.parameters.LBLiteTimeZoneAwareness;
  return tzEnabled ? moment().tz(getBrowserTimezone()) : moment();
};

/***
 * Get the current time, checking for timezone awareness.
 * @param settings
 */
export const getNow = ({ timeZoneEnabled }: SettingsContext): Moment => {
  return timeZoneEnabled ? moment().tz(getBrowserTimezone()) : moment();
};
/***
 * Get the time based on timezone awareness.
 * @param settings
 */
export const getStartDateTime = ({ timeZoneEnabled }: SettingsContext, { startDate }: UIContext): Moment => {
  return timeZoneEnabled ? moment(startDate).tz(getBrowserTimezone()) : moment(startDate);
};

export const getReqWindowStartOrLatestScheduledMoment = (
  requestStartDate: string,
  lastPublishedDate: string
): Moment => {
  const lastPublishedDateMinusOneMoment = moment(lastPublishedDate).add(1, "day");
  const requestStartDateMoment = moment(requestStartDate);
  return requestStartDateMoment > lastPublishedDateMinusOneMoment
    ? lastPublishedDateMinusOneMoment
    : requestStartDateMoment;
};
