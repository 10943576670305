import { Model } from "backbone";

import connectedPropertyHookFactory from "@/viewer/ui/modules/common/hooks/connectedPropertyHookFactory";
import { cleanUserAttributes, User, UserAttributes } from "@/viewer/types/domain/user";

const getCurrentlyLoggedInUser = (): User => {
  const { LbsAppData } = window as any;
  const User: Model<UserAttributes> = LbsAppData.User;

  return cleanUserAttributes(User.attributes);
};

/**
 * Hook that gets the `User` object for the currently logged in user.
 */
export default connectedPropertyHookFactory({
  loader: getCurrentlyLoggedInUser,
  getListeners: () => {
    const { LbsAppData } = window as any;
    return [[LbsAppData.MyPersonnel, "fetched"]];
  },
});
