const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var AssignmentType = require("./../models/AssignmentType.js");

var AssignmentTypeCollection = Backbone.Collection.extend({
  model: AssignmentType,
  comparator: "name",

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    // views will only ever be for the template that the current schedule is in
    return ApiConfig.getPrefix() + "/assignments/type";
  },
});

module.exports = AssignmentTypeCollection;
