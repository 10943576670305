import { CsvRow, LeftColumnType, StartAndStopDates } from "@/_lib/ui/modules/topbar/types";
import { isDateInPaddedRange } from "@/_lib/utils/dateChecks";
import { getPrecisionDifferenceInHours } from "@/_lib/utils/dateUtils";
import { AssignmentsById } from "@/viewer/types/domain/assignment";
import { AssignmentTypesById } from "@/viewer/types/domain/assignmentType";
import { DepartmentsById } from "@/viewer/types/domain/department";
import { PersonnelById } from "@/viewer/types/domain/personnel";
import { PersonnelTypesById } from "@/viewer/types/domain/personnelType";
import { Request } from "@/viewer/types/domain/request";
import { Slot } from "@/viewer/types/domain/slot";
import { TemplatesById } from "@/viewer/types/domain/template";
import { User } from "@/viewer/types/domain/user";
import { ViewData } from "@/viewer/types/viewdata";

import { RequestsByDateByName, SlotOrRequest, SlotsByDateByColumn } from "@/viewer/ui/modules/common/types";
import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { checkSearchTerms } from "@/viewer/ui/modules/grid/list/utils";
import { formatFullHumanizedDate, formatLocalTime, formatUSDate } from "@/viewer/utils/dateFormatters";

import { getLeftColumnId, getSlotDisplayObject, isSlot } from "@/viewer/utils/domain/slotquests";
import { appendToPath2 } from "@/viewer/utils/helpers";
import { Parser } from "json2csv";
import moment from "moment";

export const getDateRange = (ui: UIContext): StartAndStopDates => ({
  start: moment(ui.startDate),
  stop: moment(ui.stopDate),
});

const getDates = ({ start, stop }: StartAndStopDates): string[] => {
  const dates = [];
  const date = start.clone();
  const differenceInDays = stop.diff(start, "days");

  for (let day = 0; day <= differenceInDays; day++) {
    dates.push(date.format("YYYY-MM-DD"));
    date.add(1, "day");
  }

  return dates;
};

const getGroupedSlots = (
  datesOfRange: string[],
  filteredSlotData: Slot[],
  ui: UIContext,
  viewData: ViewData
): SlotsByDateByColumn => {
  const { filters } = viewData;
  const { filteredPersonnel, filteredAssignments } = filters;

  const slots = filteredSlotData.filter((slot: Slot) => {
    return (
      datesOfRange.includes(slot.dateString) &&
      !filteredPersonnel.hasOwnProperty(slot.empId.toString()) &&
      !filteredAssignments.hasOwnProperty(slot.condensedStructureId)
    );
  });

  const groupedSlots: SlotsByDateByColumn = {};

  slots.forEach((slot) => {
    const columnId = getLeftColumnId(ui, slot);

    appendToPath2(groupedSlots, [columnId, slot.dateString], slot);
  });

  return groupedSlots;
};

const getGroupedRequests = (
  datesOfRange: string[],
  filteredRequestData: Request[],
  ui: UIContext
): RequestsByDateByName => {
  const requests = filteredRequestData.filter(
    (request) => request.status === "granted" && datesOfRange.includes(request.dateString)
  );

  const groupedRequests: RequestsByDateByName = {};

  requests.forEach((request) => {
    const columnId = getLeftColumnId(ui, request);

    appendToPath2(groupedRequests, [columnId, request.dateString], request);
  });

  return groupedRequests;
};

const mapObjectsToCsv = (
  groupedObjects: SlotsByDateByColumn | RequestsByDateByName,
  ui: UIContext,
  viewData: ViewData
): CsvRow[] => {
  const leftColumnType: LeftColumnType = ui.leftColumnType;
  const leftColumnHeader = leftColumnType === "assignment" ? "Assignment" : "Personnel";
  const rows: CsvRow[] = [];

  // Iterate over each left column entry in the grouped slots object.
  for (const id in groupedObjects) {
    const objectsForId = groupedObjects[id];
    let maxObjectsListLength = 0;

    // Iterate over each object for this left column value and find the longest object list.
    for (const date in objectsForId) {
      const slotQuestList = objectsForId[date];

      maxObjectsListLength = Math.max(slotQuestList.length, maxObjectsListLength);
    }

    // Create a list of row objects with length equal to the longest object list.
    const rowObjects: CsvRow[] = [];

    for (let i = 0; i < maxObjectsListLength; i++) {
      rowObjects.push({});
    }

    // For each entry in this object, insert the corresponding right column value into the corresponding row object.
    for (const date in objectsForId) {
      const slotQuestList = objectsForId[date];

      for (let i = 0; i < slotQuestList.length; i++) {
        const slotQuest: Slot | Request = slotQuestList[i];
        const rowObject = rowObjects[i];

        // If the row hasn't had the left column's display name tacked on yet, do that here.
        if (!rowObject.hasOwnProperty(leftColumnType)) {
          rowObject[leftColumnHeader] =
            leftColumnType === "assignment" ? slotQuest.assignCompactOrDisplayName : slotQuest.compactOrDisplayName;
        }
        rowObject[date] =
          leftColumnType === "assignment" ? slotQuest.compactOrDisplayName : slotQuest.assignCompactOrDisplayName;
      }
    }

    // Push each row object onto the data list.
    rowObjects.forEach((row: CsvRow) => rows.push(row));
  }

  const referencingOrderArray =
    leftColumnType === "assignment"
      ? viewData.assignments.map((a) => a.compactOrDisplayName)
      : viewData.personnel.map((p) => p.compactOrDisplayName);

  rows.sort((a: CsvRow, b: CsvRow) => {
    return referencingOrderArray.indexOf(a[leftColumnHeader]) - referencingOrderArray.indexOf(b[leftColumnHeader]);
  });

  return rows;
};

const getListSlotRowDataFromColumnNames = ({
  assignmentsById,
  assignmentTypesById,
  departmentsById,
  personnelById,
  personnelTypesById,
  settings,
  slotquest,
  templatesById,
  ui,
  user,
}: {
  assignmentsById: AssignmentsById;
  assignmentTypesById: AssignmentTypesById;
  departmentsById: DepartmentsById;
  personnelById: PersonnelById;
  personnelTypesById: PersonnelTypesById;
  settings: SettingsContext;
  slotquest: SlotOrRequest;
  templatesById: TemplatesById;
  ui: UIContext;
  user: User;
}): CsvRow => {
  const rowData: CsvRow = {};
  const { columnNames } = settings;

  columnNames.forEach((name: string) => {
    switch (name) {
      case "Assignment": {
        return (rowData[name] = slotquest.assignCompactOrDisplayName);
      }

      case "Assignment Type": {
        return (rowData[name] = slotquest.assign_atype ? assignmentTypesById[slotquest.assign_atype].name : "");
      }

      case "Assignment Information": {
        return (rowData[name] = assignmentsById[slotquest.condensedStructureId]?.description);
      }

      case "Assignment Units": {
        return (rowData[name] = (isSlot(slotquest) ? slotquest.workUnits : 0)?.toString() ?? "");
      }

      case "Business Phone": {
        return (rowData[name] = personnelById[slotquest.empId].businessPhoneNumber);
      }

      case "Cell Phone": {
        return (rowData[name] = personnelById[slotquest.empId].cellPhoneNumber);
      }

      case "Date": {
        return (rowData[name] = formatFullHumanizedDate(slotquest.date));
      }

      case "Department": {
        const template = templatesById[slotquest.templateId];

        return (rowData[name] = departmentsById[template.departmentId].name);
      }

      case "Email": {
        return (rowData[name] = personnelById[slotquest.empId].email);
      }

      case "Home Phone": {
        return (rowData[name] = personnelById[slotquest.empId].homePhoneNumber);
      }

      case "Message": {
        const { smAccount } = personnelById[slotquest.empId];
        const smProvider = user.secureMessagingClient;
        const messageProvider = smAccount ? smProvider : "";

        return (rowData[name] = messageProvider);
      }

      case "Note": {
        return (rowData[name] = isSlot(slotquest) ? slotquest.note ?? "" : "");
      }

      case "Pager": {
        return (rowData[name] = personnelById[slotquest.empId].pager);
      }

      case "Personnel": {
        const { personnelText } = getSlotDisplayObject(settings, ui, slotquest, slotquest.empId.toString());

        return (rowData[name] = personnelText);
      }

      case "Personnel Type": {
        return (rowData[name] = slotquest.emp_ptype ? personnelTypesById[slotquest.emp_ptype].name : "");
      }

      case "Start Time": {
        let startTime = "";

        if (!slotquest.startTimeString) {
          const assignment = assignmentsById[slotquest.assignStructureId];
          const times = assignment.templateToDefaultTimesMap[slotquest.templateId].split("~");
          const time = new Date(`${slotquest.dateString}T${times[0]}`);

          startTime = formatLocalTime(settings, time);
        } else {
          startTime = formatLocalTime(settings, slotquest.startTime);
        }

        return (rowData[name] = startTime);
      }

      case "Stop Time": {
        let stopTime = "";

        if (!slotquest.stopTimeString) {
          // need to look it up -- requests might be weird?
          const assignment = assignmentsById[slotquest.assignStructureId];
          const times = assignment.templateToDefaultTimesMap[slotquest.templateId].split("~");
          const time = new Date(`${slotquest.dateString}T${times[1]}`);

          stopTime = formatLocalTime(settings, time);
        } else {
          stopTime = formatLocalTime(settings, slotquest.stopTime);
        }

        return (rowData[name] = stopTime);
      }

      case "Template": {
        return (rowData[name] = slotquest.templateDesc);
      }

      case "Location(s)": {
        return (rowData[name] = slotquest.locationNames.join(","));
      }

      case "Weekly Hours": {
        return (rowData[name] = (personnelById[slotquest.empId].weeklyHours ?? 0).toString());
      }

      case "Seniority Date": {
        const { seniorityDate } = personnelById[slotquest.empId];

        return (rowData[name] = seniorityDate ? formatUSDate(seniorityDate) : "");
      }

      case "UniqueID": {
        return (rowData[name] = personnelById[slotquest.empId].uniqueId);
      }

      case "Custom1": {
        return (rowData[name] = personnelById[slotquest.empId].custom1);
      }

      case "Custom2": {
        return (rowData[name] = personnelById[slotquest.empId].custom2);
      }

      case "Custom3": {
        return (rowData[name] = personnelById[slotquest.empId].custom3);
      }

      case "Custom4": {
        return (rowData[name] = personnelById[slotquest.empId].custom4);
      }

      case "Category": {
        const assignment = assignmentsById[slotquest.assignStructureId];

        return (rowData[name] = assignment?.structureToCategoryMap[slotquest.assignStructureId] ?? "");
      }

      case "Title": {
        return (rowData[name] = personnelById[slotquest.empId].title);
      }

      case "Total Hours": {
        return (rowData[name] = getPrecisionDifferenceInHours(slotquest.stopTime, slotquest.startTime).toString());
      }

      default:
        break;
    }
  });

  return rowData;
};

const getSlotQuestCsv = ({
  fields,
  requestRows,
  slotRows,
}: {
  fields: string[];
  requestRows: CsvRow[];
  slotRows: CsvRow[];
}): string => {
  const slotBlockHeader = '"Scheduled",';
  const requestBlockHeader = '"Requested",';
  const json2csvParser = new Parser({ fields });
  const slotCsv = json2csvParser.parse([fields, ...slotRows]);
  const slotCsvBlock = slotRows.length ? `${slotBlockHeader}\r\n${slotCsv}\r\n` : "";
  let unscheduledRequests: CsvRow[] = [];
  let requestCsvBlock = "";
  let requestCsv = "";

  //iterate over the request row objects
  //slice off the personnel value
  //find all scheduled data for the personnel
  //iterate over the dates for the request object
  //filter any dates that match the same already scheduled assignment
  //on the same day for the personnel
  //Rebuild the request object starting with the Personnel
  //add any of the deduped values
  //If the rebuilt object only contains the Personnel
  //This means all request data is already scheduled
  //and the request object should be filtered out of the request rows
  if (slotRows.length) {
    unscheduledRequests = requestRows
      .map((request) => {
        const { Personnel } = request;
        const dates = Object.keys(request).slice(1, Object.keys(request).length);
        const scheduledDataForPersonnel = slotRows.filter((slot) => {
          return slot.Personnel === Personnel;
        });

        if (!scheduledDataForPersonnel.length) {
          return request;
        }

        const deDupedDates = dates.filter(
          (date) => !scheduledDataForPersonnel.filter((data) => data[date] !== request[date])
        );

        const deDupedDateData: { [key: string]: string } = { Personnel };

        for (const key in request) {
          if (deDupedDates.find((value) => value === key)) {
            deDupedDateData[key] = request[key];
          }
        }

        return Object.keys(deDupedDateData).length > 1 ? deDupedDateData : {};
      })
      .filter((data) => data.Personnel ?? false);

    if (unscheduledRequests.length) {
      requestCsv = json2csvParser.parse([fields, ...unscheduledRequests]);
      requestCsvBlock = unscheduledRequests.length ? `${requestBlockHeader}\r\n${requestCsv}\r\n` : "";
    }
  }

  if (requestRows.length && !unscheduledRequests.length) {
    requestCsv = json2csvParser.parse([fields, ...requestRows]);
    requestCsvBlock = requestRows.length ? `${requestBlockHeader}\r\n${requestCsv}\r\n` : "";
  }

  return `${slotCsvBlock}${requestCsvBlock}`.trim();
};

const getListCSVRows = ({
  assignmentsById,
  assignmentTypesById,
  departmentsById,
  personnelById,
  personnelTypesById,
  searchTerms,
  settings,
  slotQuestData,
  startAndStopDates,
  templatesById,
  ui,
  user,
}: {
  assignmentsById: AssignmentsById;
  assignmentTypesById: AssignmentTypesById;
  departmentsById: DepartmentsById;
  personnelById: PersonnelById;
  personnelTypesById: PersonnelTypesById;
  searchTerms: string[];
  settings: SettingsContext;
  slotQuestData: SlotOrRequest[];
  startAndStopDates: { start: Date; stop: Date };
  templatesById: TemplatesById;
  ui: UIContext;
  user: User;
}): CsvRow[] => {
  const rows: CsvRow[] = slotQuestData
    .filter((slotquest: SlotOrRequest) => {
      if (
        isDateInPaddedRange({
          date: slotquest.date,
          paddingUnit: "days",
          ...startAndStopDates,
          startPadding: 1,
          stopPadding: 0,
        })
      ) {
        if (searchTerms.length) {
          return checkSearchTerms(slotquest, searchTerms);
        }

        return true;
      }
    })
    .map((slotquest: SlotOrRequest) =>
      getListSlotRowDataFromColumnNames({
        assignmentsById,
        assignmentTypesById,
        departmentsById,
        personnelById,
        personnelTypesById,
        settings,
        slotquest,
        templatesById,
        ui,
        user,
      })
    );

  return rows;
};

export const exportListView = ({
  assignmentsById,
  assignmentTypesById,
  departmentsById,
  filteredRequestData,
  filteredSlotData,
  personnelById,
  personnelTypesById,
  searchTerms,
  settings,
  startAndStopDates,
  templatesById,
  ui,
  user,
}: {
  assignmentsById: AssignmentsById;
  assignmentTypesById: AssignmentTypesById;
  departmentsById: DepartmentsById;
  filteredRequestData: Request[];
  filteredSlotData: Slot[];
  personnelById: PersonnelById;
  personnelTypesById: PersonnelTypesById;
  searchTerms: string[];
  settings: SettingsContext;
  startAndStopDates: { start: Date; stop: Date };
  templatesById: TemplatesById;
  ui: UIContext;
  user: User;
}): string => {
  const { columnNames: fields } = settings;

  const slotRows = getListCSVRows({
    assignmentsById,
    assignmentTypesById,
    departmentsById,
    personnelById,
    personnelTypesById,
    searchTerms,
    settings,
    slotQuestData: filteredSlotData,
    startAndStopDates,
    templatesById,
    ui,
    user,
  });
  const requestRows = getListCSVRows({
    assignmentsById,
    assignmentTypesById,
    departmentsById,
    personnelById,
    personnelTypesById,
    searchTerms,
    settings,
    slotQuestData: filteredRequestData,
    startAndStopDates,
    templatesById,
    ui,
    user,
  });

  return getSlotQuestCsv({ fields, requestRows, slotRows });
};

export const exportCurrentView = ({
  filteredRequestData,
  filteredSlotData,
  startAndStopDates,
  ui,
  viewData,
}: {
  filteredRequestData: Request[];
  filteredSlotData: Slot[];
  startAndStopDates: StartAndStopDates;
  ui: UIContext;
  viewData: ViewData;
}): string => {
  const datesOfRange = getDates(startAndStopDates);
  const leftColumnHeader = ui.leftColumnType === "assignment" ? "Assignment" : "Personnel";
  const fields = [leftColumnHeader, ...datesOfRange];
  const groupedSlots = getGroupedSlots(datesOfRange, filteredSlotData, ui, viewData);
  const groupedRequests = getGroupedRequests(datesOfRange, filteredRequestData, ui);
  const slotRows = mapObjectsToCsv(groupedSlots, ui, viewData);
  const requestRows = mapObjectsToCsv(groupedRequests, ui, viewData);
  const slotBlockHeader = '"Scheduled",';
  const requestBlockHeader = '"Requested",';
  const json2csvParser = new Parser({ fields });
  const slotCsv = json2csvParser.parse([fields, ...slotRows]);
  const slotCsvBlock = slotRows.length ? `${slotBlockHeader}\r\n${slotCsv}\r\n` : "";
  const requestCsv = json2csvParser.parse([fields, ...requestRows]);
  const requestCsvBlock = requestRows.length ? `${requestBlockHeader}\r\n${requestCsv}\r\n` : "";

  return `${slotCsvBlock}${requestCsvBlock}`;
};
