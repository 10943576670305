const Backbone = require("backbone");
var _ = require("underscore");
var moment = require("moment");

var TableObjectCollection = require("./../collections/TableObjectCollection.js");
var TableObject = require("./TableObject.js");

var ReportResult = Backbone.Model.extend({
  idAttribute: "path_id",

  defaults: {},
  initialize: function () {
    //this.attributes.tableObjects = new TableObjectCollection();

    var def = { x: this._createDef("x"), y: this._createDef("y") };
    this.attributes.table = {
      objects: new TableObjectCollection(),
      grid_definition: def,
    };
    this.attributes.table.exploded = this._explode(def);
  },

  /* publics */
  getCellDisplay: function (data, dataKey) {
    if (data === undefined || dataKey === undefined) {
      // return data -- either blank value or a header cell
      return data;
    }

    // display changes based on cell_display property of the table
    switch (this.collection.attributes._definition.properties.table.cell_display) {
      case "pct_x":
        // data percentage based off x total
        var totalVal = this.get("result").data[dataKey.split("|")[0] + "|"];
        return +((data / totalVal) * 100).toFixed(2) + "%";
      case "pct_y":
        // data percentage based off y total
        var totalVal = this.get("result").data["|" + dataKey.split("|")[1]];
        return +((data / totalVal) * 100).toFixed(2) + "%";
      default:
        return Math.round(data * 100) / 100; // 0, 1 or 2 decimal places
    }
  },

  clearSelections: function () {
    var selectedObjects = this.attributes.table.objects.where({
      selected: true,
    });

    _.each(selectedObjects, function (o) {
      o.set({ selected: false });
    });

    // trigger an event on the objects
    this.attributes.table.objects.trigger("select");

    // disable the table controls
    window.LbsAppData.AppContext.trigger("disable:compress");
    // disable the pie --- this could 'technically' be incorrect if the report has only one person/tally
    // but i don't really care... the overhead is stupid to do that check each time
    window.LbsAppData.AppContext.trigger("disable:pie");

    // clear the context item
    window.LbsAppData.AppContext.removeContext("selection");
  },
  getSelections: function () {
    return this.attributes.table.objects.where({ selected: true });
  },
  hasSelections: function () {
    return this.attributes.table.objects.findWhere({ selected: true }) ? true : false;
  },

  select: function (objects, val) {
    var that = this;
    _.each(objects, function (o) {
      var tableObj = that.attributes.table.objects.get(o.id);
      if (tableObj) {
        tableObj.set({
          selected: val !== undefined ? val : !tableObj.get("selected"),
        });
      }
    });
  },

  hideUnselected: function () {
    // if one axis doesn't have a selection then don't hide anyone from it
    var groups = this.attributes.table.objects.groupBy("axis");

    if (
      _.find(groups.x, function (o) {
        return o.attributes.selected;
      })
    ) {
      _.each(groups.x, function (o) {
        if (!o.attributes.selected) {
          o.set({ hidden: true });
        } else {
          // undo the selection as well
          o.set({ selected: false });
        }
      });
    }
    if (
      _.find(groups.y, function (o) {
        return o.attributes.selected;
      })
    ) {
      _.each(groups.y, function (o) {
        if (!o.attributes.selected) {
          o.set({ hidden: true });
        } else {
          // undo the selection as well
          o.set({ selected: false });
        }
      });
    }

    // enable the expand icon
    window.LbsAppData.AppContext.trigger("enable:expand");
    // disable the compress icon
    window.LbsAppData.AppContext.trigger("disable:compress");
  },
  showHidden: function () {
    // if one axis doesn't have any hidden then don't select anyone from it
    var groups = this.attributes.table.objects.groupBy("axis");

    if (
      _.find(groups.x, function (o) {
        return o.attributes.hidden;
      })
    ) {
      _.each(groups.x, function (o) {
        if (o.attributes.hidden) {
          o.set({ hidden: false });
        } else {
          // select any (previously shown) as well
          o.set({ selected: true });
        }
      });
    }
    if (
      _.find(groups.y, function (o) {
        return o.attributes.hidden;
      })
    ) {
      _.each(groups.y, function (o) {
        if (o.attributes.hidden) {
          o.set({ hidden: false });
        } else {
          // select any (previously shown) as well
          o.set({ selected: true });
        }
      });
    }

    // disable the expand icon
    window.LbsAppData.AppContext.trigger("disable:expand");
    // enable the compress icon
    window.LbsAppData.AppContext.trigger("enable:compress");
  },

  /* privates */
  _createDef: function (axis) {
    var def = [[]]; //new TableObjectCollection();

    var reportDefinition = this.collection.attributes._definition;
    if (reportDefinition) {
      var axisDef = reportDefinition.properties.table[axis];
      var axisObjs = this.get("result")[axis].ids;
      //var axisObjs = reportDefinition[axisDef];
      //if (axisDef == 'tally') {
      //	axisObjs = reportDefinition['tallies'];
      //} else if (axisDef == 'date') {
      //	axisObjs = this.get('result')[axis].ids;
      //}

      var that = this;
      _.each(axisObjs, function (id) {
        // if we're hiding blanks -- check this column's totals
        if (reportDefinition.properties.data.mode != "shadow") {
          var total = axis == "x" ? that.get("result").data[id + "|"] : that.get("result").data["|" + id];
          if (total === undefined) {
            // ignore this one
            return;
          }
        }

        if (axisDef == "tally") {
          def[0].push(
            new TableObject({
              object: that.collection.attributes.lib.TallyCollection.get(id),
              id: id,
              title: that.collection.attributes.lib.TallyCollection.get(id).get("tally_name"),
            })
          );
        } else if (axisDef == "personnel") {
          def[0].push(
            new TableObject({
              object: that.collection.attributes.lib.PersonnelCollection.get(id),
              id: id,
              title: that.collection.attributes.lib.PersonnelCollection.get(id).get("display_name"),
            })
          );
        } else {
          // dates
          def[0].push(
            new TableObject({
              object: { id: id },
              id: id,
              title: moment(id, "YYYYMMDD").format("L"),
            })
          );
        }
      });

      // totals row
      if (reportDefinition.properties.table.show_totals) {
        def[0].push(
          new TableObject({
            object: { id: "" },
            id: axis,
            title: "Total",
            classes: "total",
          })
        );
      }
    }

    // level 2 (optional)
    if (reportDefinition.properties.table[axis + "_dim"] != "none") {
      def.push([]);

      //if (reportDefinition.properties.table.show_totals) {
      def[1].push(
        new TableObject({
          id: undefined,
          object: { id: undefined },
          title: "*",
        })
      ); // no id
      //}

      switch (this.collection.attributes._definition.properties.table[axis + "_dim"]) {
        case "dow":
          def[1].push(
            new TableObject({
              id: 1,
              object: { id: 1 },
              title: "Mo",
            })
          );
          def[1].push(
            new TableObject({
              id: 2,
              object: { id: 2 },
              title: "Tu",
            })
          );
          def[1].push(
            new TableObject({
              id: 3,
              object: { id: 3 },
              title: "We",
            })
          );
          def[1].push(
            new TableObject({
              id: 4,
              object: { id: 4 },
              title: "Th",
            })
          );
          def[1].push(
            new TableObject({
              id: 5,
              object: { id: 5 },
              title: "Fr",
            })
          );
          def[1].push(
            new TableObject({
              id: 6,
              object: { id: 6 },
              title: "Sa",
            })
          );
          def[1].push(
            new TableObject({
              id: 0,
              object: { id: 0 },
              title: "Su",
            })
          );
          break;
        default:
          break;
      }
    }

    return def;
  },

  _explode: function (def) {
    // go through the rows/columns and create table objects for each permutation
    var rows = [];
    this._createRows(rows, 0, 0, [], def.y);

    var cols = [];
    this._createCols(cols, 0, 0, [], def.x);

    return { rows: rows, cols: cols };
  },

  _createRows: function (rows, rLvl, rIdx, parentParts, rowDefinitions) {
    for (var i = 0; i < rowDefinitions[rLvl].length; i++) {
      var rowParts = [].concat(parentParts);
      rowParts.push(rowDefinitions[rLvl][i]);

      // add the item if we're at the end
      if (rLvl == rowDefinitions.length - 1) {
        rows.push(rowParts);
        this.attributes.table.objects.addIndex(rowParts, "y");
      } else {
        // dive deeper
        this._createRows(rows, rLvl + 1, 0, rowParts, rowDefinitions);
      }
    }
  },
  _createCols: function (cols, cLvl, cIdx, parentParts, colDefinitions) {
    for (var i = 0; i < colDefinitions[cLvl].length; i++) {
      var colParts = [].concat(parentParts);
      colParts.push(colDefinitions[cLvl][i]);

      // add the item if we're at the end
      if (cLvl == colDefinitions.length - 1) {
        cols.push(colParts);
        this.attributes.table.objects.addIndex(colParts, "x");
      } else {
        // dive deeper
        this._createCols(cols, cLvl + 1, 0, colParts, colDefinitions);
      }
    }
  },
});

module.exports = ReportResult;
