import Backbone from "backbone";
import { LDFlagEnums } from "../constants/LDFlagEnums";
import { LaunchDarklyClient } from "../utils/launchDarklyClient";
import { getLDUser } from "../utils/launchDarklyHelper";
import { PubnubTransport } from "./transports/PubnubTransport";
import { SocketTransport } from "./transports/SocketTransport";

const SocketHelper = Backbone.Model.extend({
  defaults: {
    enabled: true,
  },
  initialize: function (attributes, options) {
    if (this.attributes.enabled || window.location.hostname !== "localhost") {
      const ldUserConfig = getLDUser(window.LbsAppData.User.attributes);
      this.launchDarklyClient = new LaunchDarklyClient(ldUserConfig, () => {
        this._connectSocket();

        // socket events
        this._registerSocketEvents();
      });
    }
  },

  /* publics */

  // the generalized send function
  send: function (type, data) {
    this.socket.send(type, data);
  },
  flutter: function () {
    // reconnect the socket if the stream id has changed
    var streamId = this._getStreamId();

    if (streamId != this.get("stream_id")) {
      this._disconnectSocket();
      this._connectSocket();
    }
  },

  /* privates */
  _disconnectSocket: function () {
    this.set({ stream_id: undefined });
    this.socket.disconnect();
  },

  _connectSocket: function () {
    var streamId = this._getStreamId();

    // store the stream_id
    this.set({ stream_id: streamId });

    let customerId = window.LbsAppData.User.get("customer_id");

    if (!this.socket) {
      if (
        this.launchDarklyClient &&
        this.launchDarklyClient.evaluateLDFlag(LDFlagEnums.Lv3084PubnubShadowReports, false)
      ) {
        this.socket = new PubnubTransport();
      } else {
        this.socket = new SocketTransport();
      }
    }

    this.socket.connect(customerId, streamId);
  },

  _getStreamId: function () {
    var streamParts = [
      window.LbsAppData.User.get("customer_id"), // customer_id
      window.LbsAppData.id, // app_id
    ];

    switch (window.LbsAppData.id) {
      case "editor":
        // include the schedule id
        streamParts.push(window.LbsAppData.AppContext.get("displayedSchedule").id);
        break;
      case "reports":
        if (
          window.LbsAppData.AppContext.get("report_draft") &&
          window.LbsAppData.AppContext.get("report_draft").get("_definition").properties.data.mode == "shadow"
        ) {
          streamParts = [
            window.LbsAppData.User.get("customer_id"), // customer_id
            "editor", // app_id
            window.LbsAppData.AppContext.get("report_draft").get("_definition").properties.data.shadow.schedule_id,
          ];
        } else {
          // generic reports room (shouldn't do anything)
          streamParts = [
            window.LbsAppData.User.get("customer_id"), // customer_id
            "reports", // app_id
          ];
        }
      default:
        break;
    }

    return streamParts.join("!");
  },

  _registerSocketEvents: function () {
    this.socket.registerCallback((type, message) => this.attributes.receive(type, message));
  },
});

export default SocketHelper;
