const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");

var View = Backbone.Model.extend({
  idAttribute: "view_id",

  defaults: {},

  initialize: function () {},

  url: function () {
    return ApiConfig.getPrefix() + (this.id ? "/views/" + this.id : "/views");
  },
});

module.exports = View;
