"use strict";

require("./_styles.scss");

const React = require("react/addons");

const TimePickerHourPicker = require("./TimePickerHourPicker.jsx");
const TimePickerMinutePicker = require("./TimePickerMinutePicker.jsx");
const TimePickerAMPMPicker = require("./TimePickerAMPMPicker.jsx");

const TimePicker = React.createClass({
  getTimeValue: function () {
    return this.getHourValue() + ":" + this.getMinuteValue() + ":00";
  },

  getHourValue: function () {
    const hour = parseInt(this.refs.HourPicker.getValue());

    return this._formatHourAsMilitary(hour);
  },

  getMinuteValue: function () {
    const minute = parseInt(this.refs.MinutePicker.getValue());

    let minuteStr = minute.toString();
    if (minuteStr.length < 2) {
      minuteStr = "0" + minuteStr;
    }

    return minuteStr;
  },

  onChange: function (args) {
    const callbackFunc = this.props.onChange;
    if (typeof callbackFunc === "function") {
      callbackFunc({
        hours: this._formatHourAsMilitary(
          args.newHour === undefined ? parseInt(this.refs.HourPicker.getValue()) : args.newHour,
          args.newAmPm
        ),
        minutes: args.newMinute === undefined ? this.getMinuteValue() : args.newMinute,
      });
    }
  },

  /* privates */

  _formatHourAsMilitary: function (hour, amOrPm) {
    // ampm modification
    if (!window.LbsAppData.Helpers.Time.isMilitaryTimePreferred()) {
      const ampm = amOrPm || this.refs.AMPMPicker.getValue();
      if (ampm === "PM") {
        if (hour < 12) {
          hour = hour + 12;
        }
      } else if (hour == 12) {
        // 12 am
        hour = 0;
      }
    }

    let hourStr = hour.toString();
    if (hourStr.length == 1) {
      hourStr = "0" + hourStr;
    }

    return hourStr;
  },

  /* lifecycle methods */
  componentDidMount: function () {},
  componentWillUnmount: function () {},
  componentWillUpdate: function () {},
  componentDidUpdate: function () {},

  render: function () {
    const classes = React.addons.classSet({
      TimePicker: true,
      big: this.props.bigMode,
    });

    let isMidnight = false;
    const isMilitary = window.LbsAppData.Helpers.Time.isMilitaryTimePreferred();

    let defaultHour = this.props.defaultTimeStr === undefined ? "12" : this.props.defaultTimeStr.split(":")[0];
    if (!isMilitary && parseInt(defaultHour) > 12) {
      const defaultHourInt = parseInt(defaultHour) - 12;
      defaultHour = defaultHourInt.toString();
    } else if (!isMilitary && parseInt(defaultHour) === 0) {
      // midnight
      defaultHour = 12;
      isMidnight = true;
    } else {
      // just to ensure we only have 1 digit for the hour
      defaultHour = parseInt(defaultHour).toString();
    }
    const defaultMinute = this.props.defaultTimeStr === undefined ? "00" : this.props.defaultTimeStr.split(":")[1];
    const defaultAMPM =
      this.props.defaultTimeStr === undefined || this.props.defaultTimeStr.split(":")[0] < 12 || isMidnight
        ? "AM"
        : "PM";

    return (
      <div className={classes}>
        <TimePickerHourPicker
          ref="HourPicker"
          defaultVal={defaultHour}
          isFocused={this.props.focusedCounter === 0}
          spinnerCounter={this.props.spinnerCounter}
          numberOfDivs={this.props.numberOfDivs}
          isStartPicker={this.props.isStartPicker}
          unfocusKeydown={this.props.unfocusKeydown}
          refocusKeydown={this.props.refocusKeydown}
          submitButtonToggle={this.props.submitButtonToggle}
          onChange={this.onChange}
        />
        :
        <TimePickerMinutePicker
          ref="MinutePicker"
          defaultVal={defaultMinute}
          isFocused={this.props.focusedCounter === 1}
          numberOfDivs={this.props.numberOfDivs}
          isStartPicker={this.props.isStartPicker}
          spinnerCounter={this.props.spinnerCounter}
          unfocusKeydown={this.props.unfocusKeydown}
          refocusKeydown={this.props.refocusKeydown}
          onChange={this.onChange}
        />
        {!isMilitary && (
          <TimePickerAMPMPicker
            ref="AMPMPicker"
            defaultVal={defaultAMPM}
            isFocused={this.props.focusedCounter === 2}
            spinnerCounter={this.props.spinnerCounter}
            onChange={this.onChange}
          />
        )}
      </div>
    );
  },
});

module.exports = TimePicker;
