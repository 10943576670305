import React, { useEffect, useState } from "react";
import { Collection, Model } from "backbone";
import { useReactToPrint } from "react-to-print";

import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { PageSize, PrintOrientation, PrintType } from "@/_lib/ui/modules/print/PrintContainer";
import { ViewData } from "@/viewer/types/viewdata";

import fetchScheduleData from "@/_lib/hooks/fetchScheduleData";

import CalendarPrintContainer from "@/viewer/ui/modules/grid/calendar/print/CalendarPrintContainer";
import StandardPrintView from "@/viewer/ui/modules/grid/standard/print/StandardPrintView";
import LegacyPrintButton from "@/_lib/ui/modules/topbar/components/LegacyPrintButton";
import PrintDialog from "@/_lib/ui/modules/topbar/components/PrintDialog";
import ColumnPrintView from "@/viewer/ui/modules/grid/column/print/ColumnPrintView";
import { isColumnPrintingSupported } from "@/_lib/utils/printUtils";
import { DepartmentsById } from "@/viewer/types/domain/department";

interface Props {
  settings: SettingsContext;
  ui: UIContext;
  viewData: ViewData;
  printRef: React.MutableRefObject<null>;
  departmentsById: DepartmentsById;
}

const PrintModal = (props: Props): JSX.Element => {
  const { settings, viewData, ui, printRef, departmentsById } = props;
  const { layout } = settings;
  const [printType, setPrintType] = useState(PrintType.Minimal);
  const [printOrientation, setPrintOrientation] = useState(PrintOrientation.Portrait);
  const [pageScale, setPageScale] = useState(1);
  const [pageSize, setPageSize] = useState(PageSize.Letter);
  const supportsColumnPrinting = isColumnPrintingSupported();

  const [{ loading, showPrintView, startDate, endDate, scheduleData }, setPrintViewData] = useState({
    loading: false,
    showPrintView: false,
    startDate: new Date(),
    endDate: new Date(),
    scheduleData: {
      slots: new Collection(),
      requests: new Collection(),
      report: new Model(),
    },
  });

  const print = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setPrintViewData((prevState) => ({ ...prevState, loading: false, showPrintView: false })),
  });

  const fetchData = async (startDate: Date, endDate: Date) => {
    try {
      setPrintViewData((prevState) => ({ ...prevState, loading: true }));
      // TODO: Refactor this hook
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const scheduleData = await fetchScheduleData(settings, startDate, endDate);

      setPrintViewData((prevState) => ({
        ...prevState,
        showPrintView: true,
        startDate,
        endDate,
        scheduleData,
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setPrintViewData((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handlePrint = async (date1: Date, date2: Date) => {
    await fetchData(date1, date2);
  };

  const renderPrintComponent = () => {
    switch (layout) {
      case "calendar":
      case "hybrid":
      case "grid":
        return (
          <PrintDialog
            ui={ui}
            print={handlePrint}
            printType={printType}
            printOrientation={printOrientation}
            pageSize={pageSize}
            pageScale={pageScale}
            setPrintType={setPrintType}
            setPageScale={setPageScale}
            setPageSize={setPageSize}
            setPrintOrientation={setPrintOrientation}
            loading={loading}
            layout={layout}
            isColumnPrintingSupported={supportsColumnPrinting}
          />
        );
      default:
        return <LegacyPrintButton />;
    }
  };

  const renderColumnPrinter = (): JSX.Element => {
    if (!supportsColumnPrinting) {
      return (
        <StandardPrintView
          printRef={printRef}
          printType={printType}
          settings={settings}
          ui={ui}
          scheduleData={scheduleData}
          viewData={viewData}
          startDate={startDate}
          endDate={endDate}
          departmentsById={departmentsById}
        />
      );
    }

    return (
      <ColumnPrintView
        printRef={printRef}
        settings={settings}
        ui={ui}
        scheduleData={scheduleData}
        viewData={viewData}
        startDate={startDate}
        endDate={endDate}
        pageScale={pageScale}
        pageSize={pageSize}
        printOrientation={printOrientation}
        supportsColumnPrinting={supportsColumnPrinting}
      />
    );
  };

  const renderPrintView = () => {
    switch (layout) {
      case "calendar":
        return (
          <CalendarPrintContainer
            printRef={printRef}
            printType={printType}
            settings={settings}
            ui={ui}
            scheduleData={scheduleData}
            viewData={viewData}
            startDate={startDate}
            endDate={endDate}
            departmentsById={departmentsById}
          />
        );
      case "hybrid": {
        return (
          <StandardPrintView
            printRef={printRef}
            printType={printType}
            settings={settings}
            ui={ui}
            scheduleData={scheduleData}
            viewData={viewData}
            startDate={startDate}
            endDate={endDate}
            departmentsById={departmentsById}
          />
        );
      }
      case "grid":
        return renderColumnPrinter();

      default:
        return null;
    }
  };

  useEffect(() => {
    if (print && showPrintView && !loading) {
      print();
    }
  }, [showPrintView, loading]);

  return (
    <div style={{ height: "auto" }}>
      {renderPrintComponent()}
      {showPrintView && renderPrintView()}
    </div>
  );
};

export default PrintModal;
