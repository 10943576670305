const Backbone = require("backbone");
// Manager to facilitate actions based on the current tool

var moment = require("moment");
var _ = require("underscore");
var $ = require("jquery");

var KeyBinder = Backbone.Model.extend({
  defaults: {
    upBindings: {},
    downBindings: {},
  },

  initialize: function () {
    // subscribe to key up events
    $(document).on("keyup", null, { context: this }, this._processKeyUp);
    $(document).on("keydown", null, { context: this }, this._processKeyDown);
  },

  /* publics */
  attachBinding: function (id, type, fn) {
    if (type == "up") {
      this.attributes.upBindings[id] = { fn: fn, active: true };
    } else {
      this.attributes.downBindings[id] = { fn: fn, active: true };
    }
  },
  pauseBinding: function (id) {
    var binding = this.attributes.upBindings[id];
    if (binding && binding.active) {
      binding.active = false;
    }

    binding = this.attributes.downBindings[id];
    if (binding && binding.active) {
      binding.active = false;
    }
  },
  resumeBinding: function (id) {
    var binding = this.attributes.upBindings[id];
    if (binding && !binding.active) {
      binding.active = true;
    }

    binding = this.attributes.downBindings[id];
    if (binding && !binding.active) {
      binding.active = true;
    }
  },
  detachBinding: function (id, type) {
    if (type == "up") {
      delete this.attributes.upBindings[id];
    } else if (type == "down") {
      delete this.attributes.downBindings[id];
    } else {
      // type is undefined, do them both (if they exist)
      delete this.attributes.upBindings[id];
      delete this.attributes.downBindings[id];
    }
  },

  /* privates */
  _processKeyUp: function (e) {
    var that = e.data.context;

    // call any active bindings
    for (var key in that.attributes.upBindings) {
      var binding = that.attributes.upBindings[key];
      if (binding.active) {
        binding.fn(e);
      }
    }
  },
  _processKeyDown: function (e) {
    var that = e.data.context;

    // call any active bindings
    for (var key in that.attributes.downBindings) {
      var binding = that.attributes.downBindings[key];
      if (binding.active) {
        binding.fn(e);
      }
    }
  },
});

module.exports = KeyBinder;
