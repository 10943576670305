import React from "react";
import { useDispatch } from "react-redux";

import modalActions from "@/common/components/modal/actions";

import Button from "@/common/components/button/Button";
import AddShiftModal from "@/viewer/ui/modules/modals/add-shift/AddShiftModal";

const AddShiftButton = (): React.ReactElement => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(modalActions.open(<AddShiftModal />));
  };

  return (
    <div id="add-shift-button" className="top-bar-btn dropdown">
      <div className="no-mobile">
        <Button color="primary" onClick={handleClick}>
          <i className="fa fa-plus-circle" /> &nbsp; Add Shift
        </Button>
      </div>
      <div className="icons-mobile" onClick={handleClick}>
        <i className="fa fa-plus-circle" />
      </div>
    </div>
  );
};

export default AddShiftButton;
