import React from "react";
import { FieldProps, getIn } from "formik";

import Label from "../meta/Label";
import ErrorMessage from "../meta/ErrorMessage";
import classnames from "@/_lib/utils/classnames";
import styles from "./styles.module.scss";

interface Props extends FieldProps<string> {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  multiple?: boolean;
  rows?: number;
  size?: "small" | "medium";
  formatter?: null | ((value: string) => boolean);
  [key: string]: unknown;
}

const RenderTextField = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  label = "",
  placeholder = "",
  disabled = false,
  multiple = false,
  rows = 2,
  size = "medium",
  formatter = null,
  ...rest
}: Props): React.ReactElement => {
  const error = getIn(errors, name);
  const touch = getIn(touched, name);

  const rootClassNames = classnames({
    [styles.root]: true,
    [styles.disabled]: disabled,
  });

  const inputClassNames = classnames({
    [styles.input]: true,
    [styles.small]: size === "small",
    [styles.medium]: size === "medium",
    [styles.error]: touch && !!error,
  });

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (formatter && formatter(e.target.value)) onChange(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!formatter) onChange(e);
    onBlur(e);
  };

  const props = {
    className: inputClassNames,
    id: name,
    name,
    ...(formatter ? { value } : {}),
    onChange: handleChange,
    onBlur: handleBlur,
    placeholder,
    disabled,
    ...rest,
  };

  return (
    <div className={rootClassNames}>
      {label && <Label text={label} />}
      {multiple ? <textarea style={{ resize: "none" }} rows={rows} {...props} /> : <input {...props} />}
      {touch && !!error && !disabled && <ErrorMessage text={error} />}
    </div>
  );
};

export default RenderTextField;
