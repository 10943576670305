"use strict";

// scss requires
//require('./_styles.scss');
// dependencies

var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");
// components
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var EMPTY_PERSONNEL_PLACEHOLDER_ID =
  require("../../../../../../../viewer/data/constants").EMPTY_PERSONNEL_PLACEHOLDER_ID;

// some status string constants
var STATUS_PENDING = "pending";
var STATUS_SUSPENDED = "suspended";
var STATUS_GRANTED = "granted";
var STATUS_DENIED = "denied";

var RequestsDialogListSwapItem = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {},
  componentWillUnmount: function () {},
  //componentWillUpdate: function() {
  //	this._unregisterBackboneEvents();
  //},
  //componentDidUpdate: function() {
  //	this._registerBackboneEvents();
  //},

  /* publics */

  /* privates */
  _handleClick: function () {
    window.LbsAppData.AppContext.openDialog({
      type: "slot-details",
      data: this.props.swap,
    });
  },
  _handleClickWithTouch: function (e) {
    var that = this;
    this._endTouch(that._handleClick, e);
  },

  _getStatusText: function () {
    var text;
    if (this.props.swap.attributes.is_pending) {
      // if the employee pending is '4' then it's DOTGUY... and we're just removing ourselves
      if (this.props.swap.attributes.pending_emp_id === EMPTY_PERSONNEL_PLACEHOLDER_ID) {
        text = <span>{this.props.swap.attributes.display_name}</span>;
      } else if (this.props.swap.attributes.emp_id === EMPTY_PERSONNEL_PLACEHOLDER_ID) {
        // not sure if this will happen? I assume it will though... this is a fill into an empty
        text = <span>{this.props.swap.attributes.pending_display_name}</span>;
      } else {
        if (this.props.swap.get("pending_info").preswaps.length > 0) {
          // swaportunity
          text = (
            <span>
              {this.props.swap.attributes.display_name} <i className="fa fa-arrow-right" />{" "}
              <i className="fa fa-question" />
            </span>
          );
        } else {
          // replacing someone with someone (real) else
          text = (
            <span>
              {this.props.swap.attributes.display_name} <i className="fa fa-arrow-right" />{" "}
              {this.props.swap.attributes.pending_display_name}
            </span>
          );
        }
      }
    }

    return <span className="status">{text}</span>;
  },

  _showActionContextMenu: function (e) {
    var that = this;

    var items = [];
    if (window.LbsAppData.Helpers.Permissions.CanIApproveDenySwap(this.props.swap)) {
      items.push({
        text: "Grant",
        icon: "fa-thumbs-o-up",
        onClick: function () {
          // open up the swap remove dialog (pre-filled)
          window.LbsAppData.AppContext.openDialog({
            type: "swap-pending",
            data: {
              action: window.LbsAppData.GRANT_ACTION,
              slots: [that.props.swap],
            },
          });
        },
      });
      items.push({
        text: "Deny",
        icon: "fa-thumbs-o-down",
        onClick: function () {
          // open up the swap remove dialog (pre-filled)
          window.LbsAppData.AppContext.openDialog({
            type: "swap-pending",
            data: {
              action: window.LbsAppData.DENY_ACTION,
              slots: [that.props.swap],
            },
          });
        },
      });
      items.push({
        text: "Suspend",
        icon: "fa-question",
        onClick: function () {
          // open up the swap remove dialog (pre-filled)
          window.LbsAppData.AppContext.openDialog({
            type: "swap-pending",
            data: {
              action: window.LbsAppData.SUSPEND_ACTION,
              slots: [that.props.swap],
            },
          });
        },
      });
    }
    if (window.LbsAppData.Helpers.Permissions.CanIDeleteSwap(this.props.swap)) {
      items.push({
        text: "Delete",
        icon: "fa-trash-o",
        onClick: function () {
          // open up the swap remove dialog (pre-filled)
          window.LbsAppData.AppContext.openDialog({
            type: "swap-pending",
            data: {
              action: window.LbsAppData.DELETE_ACTION,
              slots: [that.props.swap],
            },
          });
        },
      });
    }

    if (items.length) {
      window.LbsAppData.AppContext.openContextMenu({
        component: this,
        items: items,
        extClasses: ["zDialog"],
        coordsRel: { top: 30, left: 1 },
        coordsRelTarget: e.target,
      });
    }

    //e.preventDefault();
  },
  _buildTypeIcon: function () {
    var iconStack = [<i key="1" className="fa fa-fw fa-stack-2x fa-2x fa-square" />];

    if (this.props.swap.attributes.is_pending) {
      // if the employee pending is '4' then it's DOTGUY... and we're just removing ourselves
      if (this.props.swap.attributes.pending_emp_id === EMPTY_PERSONNEL_PLACEHOLDER_ID) {
        iconStack.push(<i key="2" className="fa fa-fw fa-stack-1x fa-sign-out" />);
      } else if (this.props.swap.attributes.emp_id === EMPTY_PERSONNEL_PLACEHOLDER_ID) {
        // not sure if this will happen? I assume it will though... this is a fill into an empty
        iconStack.push(<i key="2" className="fa fa-fw fa-stack-1x fa-sign-in" />);
      } else {
        if (this.props.swap.get("pending_info").preswaps.length > 0) {
          // replacing someone with someone (real) else
          iconStack.push(<i key="2" className="fa fa-fw fa-stack-1x fa-rss" />);
        } else {
          // replacing someone with someone (real) else
          iconStack.push(<i key="2" className="fa fa-fw fa-stack-1x fa-exchange" />);
        }
      }
    }

    return <span className="fa-stack fa-lg">{iconStack}</span>;
  },
  _buildStatusIcon: function () {
    // right now we just have pending swaps... maybe this changes? I don't really know..
    var iconStack = [<i key="1" className="fa fa-fw fa-stack-2x fa-2x fa-square" />];

    //switch(this.props.swap.attributes.status) {
    //	case STATUS_GRANTED:
    //		iconStack.push(<i key='2' className='fa fa-fw fa-stack-1x fa-thumbs-o-up' />);
    //		break;
    //	case STATUS_DENIED:
    //		iconStack.push(<i key='2' className='fa fa-fw fa-stack-1x fa-thumbs-o-down' />);
    //		break;
    //	case STATUS_SUSPENDED:
    //	case STATUS_PENDING:
    iconStack.push(<i key="2" className="fa fa-fw fa-stack-1x fa-question" />);
    //		break;
    //	default:
    //		break;
    //}

    return <span className="fa-stack fa-lg">{iconStack}</span>;
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      Item: true,
      //'granted': this.props.request.attributes.status == STATUS_GRANTED,
      //'denied': this.props.request.attributes.status == STATUS_DENIED,
      //'suspended': this.props.request.attributes.status == STATUS_SUSPENDED,
      pending: this.props.swap.attributes.is_pending,
    });

    var slotDate = moment(this.props.swap.attributes.slot_date);
    //var requestedDate = moment(this.props.swap.attributes.timestamp);
    //var commandText = !this.props.request.attributes.command_type ? "Want" : "Don't Want";
    //var commandText = !this.props.request.attributes.command_type ? "" : <i className='fa fa-ban' />;
    var commandText = "";
    var assignText =
      this.props.swap.attributes.assign_display_name + " (" + this.props.swap.attributes.template_desc + ")";

    //var statusText = "";
    //if (this.props.request.attributes.status == STATUS_GRANTED) {
    //	statusText = 'Granted';
    //} else if (this.props.request.attributes.status == STATUS_DENIED) {
    //	statusText = 'Denied';
    //} else if (this.props.request.attributes.status == STATUS_PENDING) {
    //	statusText = 'Pending (RM)';
    //} else if (this.props.request.attributes.status == STATUS_SUSPENDED){
    //	statusText = 'Pending';
    //}
    var status = this._getStatusText();
    var statusIcon = this._buildStatusIcon();
    var typeIcon = this._buildTypeIcon();

    return (
      <div className={classes}>
        <div className="icons">
          <div className="icon-status">{statusIcon}</div>
          <div className="icon-type">{typeIcon}</div>
        </div>

        <div
          className="info"
          onClick={this._handleClick}
          onTouchStart={this._startTouch}
          onTouchMove={this._moveTouch}
          onTouchEnd={this._handleClickWithTouch}
        >
          <span className="assignment">{assignText}</span>
          <span className="status">{status}</span>
        </div>
      </div>
    );

    /*
			onClick={this._showActionContextMenu}

			<span className='date'>{slotDate.format('ddd, LL')}</span>
		*/
  },
});

module.exports = RequestsDialogListSwapItem;
