"use strict";

var React = require("react/addons");
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var ApplicationElement = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },

  componentDidMount: function () {},

  /* privates */
  _redirect: function () {
    this.props.redirect(this.props.path);
  },
  _redirectWithTouch: function (e) {
    var that = this;
    this._endTouch(that._redirect, e);
  },

  /* render */
  render: function () {
    /* jshint ignore:start */
    var classes = React.addons.classSet({
      ApplicationElement: true,
    });

    var appClasses = {
      viewer: React.addons.classSet({
        ApplicationLogo: true,
        viewer: true,
      }),
      maker: React.addons.classSet({
        ApplicationLogo: true,
        maker: true,
      }),
      view_design: React.addons.classSet({
        ApplicationLogo: true,
        "view-design": true,
      }),
      reports: React.addons.classSet({
        ApplicationLogo: true,
        reports: true,
      }),
      monitor: React.addons.classSet({
        ApplicationLogo: true,
        monitor: true,
      }),
    };

    var iconClasses = {
      viewer: React.addons.classSet({
        fa: true,
        "fa-desktop": true,
      }),
      maker: React.addons.classSet({
        fa: true,
        "fa-cut": true,
      }),
      view_design: React.addons.classSet({
        fa: true,
        "fa-cubes": true,
      }),
      reports: React.addons.classSet({
        fa: true,
        "fa-area-chart": true,
      }),
      monitor: React.addons.classSet({
        fa: true,
        "fa-mercury": true,
      }),
    };

    return (
      <div
        className={classes}
        onClick={this.touchProxy.bind(this, this._redirect, [])}
        onTouchStart={this._startTouch}
        onTouchMove={this._moveTouch}
        onTouchEnd={this._redirectWithTouch}
      >
        <div className="content">
          <div className={appClasses[this.props.type]}>
            <div className={"icon"}>
              <i className={iconClasses[this.props.type]} />
            </div>
          </div>
          <div className="title">
            {this.props.title}
            <span className="title-new">{this.props.newFlag ? "new!" : ""}</span>
          </div>
          <p>{this.props.desc}</p>
        </div>
      </div>
    );

    /* jshint ignore:end */
  },
});

module.exports = ApplicationElement;
