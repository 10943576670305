var Router = require("./Router.js");
var AppContext = require("./models/AppContext.js");

// model dependencies
var User = require("./models/User.js");
var TallyDataMap = require("./models/TallyDataMap.js");
var Personnel = require("./models/Personnel.js");

// collection dependencies
var HolidayCollection = require("./collections/HolidayCollection.js");
var AssignmentCollection = require("./collections/AssignmentCollection.js");
var PersonnelCollection = require("./collections/PersonnelCollection.js");
var RoleCollection = require("./collections/RoleCollection.js");
var SlotCollection = require("./collections/SlotCollection.js");
var TallyCollection = require("./collections/TallyCollection.js");
var AssignmentDemandCollection = require("./collections/AssignmentDemandCollection.js");
var TallyDemandCollection = require("./collections/TallyDemandCollection.js");
var PublishedMapCollection = require("./collections/PublishedMapCollection.js");
var ViewCollection = require("./collections/ViewCollection.js");
var ViewLegacyCollection = require("_lib/data/collections/NakedViewLegacyCollection.js");
var ChangeCollection = require("./collections/ChangeCollection.js");
var ErrorCollection = require("./collections/ErrorCollection.js");
var UserSettingsCollection = require("./collections/UserSettingsCollection.js");
var Destinations = require("_lib/data/models/Destinations.js");
var AccessibleApps = require("_lib/data/models/AccessibleApps.js");

// managers
var ToolBox = require("./models/toolbox/ToolBox.js");
var KeyBinder = require("./models/keybinder/KeyBinder.js");
var DateManager = require("./models/managers/DateManager.js");
var AnalyticsHelper = require("_lib/helpers/AnalyticsHelper.js");
var ErrorsHelper = require("_lib/helpers/ErrorsHelper.js");
var SocketHelper = require("_lib/helpers/SocketHelper").default;
var SortHelper = require("_lib/helpers/SortHelper.js");
var TimeHelper = require("_lib/helpers/TimeHelper.js");
const SafeJSON = require("../../common/utils/jsonParseShield");

// helpers
const injectInstrumentation = require("@/_lib/utils/instrumentationClient").default;

const LbsAppData = (payload) => ({
  // app identifier
  id: "editor",

  MILITARY: "military",

  DOTGUY: 4,
  CONDENSED_DELIMITER: "~!",

  // global models
  AppContext: new AppContext(),
  Router: undefined,
  LoaderChain: require("./LoaderChain.js"),

  // helpers
  Helpers: {
    Analytics: new AnalyticsHelper(),
    Errors: new ErrorsHelper(),
    Socket: undefined,
    Sort: new SortHelper(),
    Time: new TimeHelper(),
  },

  // managers
  ToolBox: new ToolBox(),
  KeyBinder: new KeyBinder(),
  DateManager: new DateManager(),

  // collections
  Assignments: undefined,
  Personnel: undefined,
  MyPersonnel: undefined,
  Roles: undefined,
  Slots: undefined,
  Tallies: undefined,
  TallyDataMap: new TallyDataMap(),
  AssignmentDemand: undefined,
  TallyDemand: undefined,
  PublishedMap: undefined,
  Views: undefined,
  User: new User(SafeJSON.parse(payload.user)),
  UserSettingsCollection: new UserSettingsCollection(),
  UserSettings: undefined,
  Destinations: undefined,
  Changes: new ChangeCollection(),
  Errors: new ErrorCollection(),
  AccessibleApps: new AccessibleApps(),

  init: function (callback) {
    // sync backbone and refresh the token
    window.LbsAppData.AppContext._syncBackbone();
    injectInstrumentation();

    // push the payload objects into the collections
    this.Holidays = new HolidayCollection(SafeJSON.parse(payload.holidays), {
      comparator: this._getHolidayComparator(),
    });
    this.Personnel = new PersonnelCollection(SafeJSON.parse(payload.personnel), {});
    this.MyPersonnel = new Personnel(SafeJSON.parse(payload.user_personnel));
    this.Roles = new RoleCollection(SafeJSON.parse(payload.roles));
    this.Destinations = new Destinations(SafeJSON.parse(payload.destinations));
    this.AccessibleApps = new AccessibleApps(SafeJSON.parse(payload.applications));

    this.AssignmentDemand = new AssignmentDemandCollection(SafeJSON.parse(payload.assignment_demand));
    this.TallyDemand = new TallyDemandCollection(SafeJSON.parse(payload.tally_demand));

    this.Tallies = new TallyCollection(SafeJSON.parse(payload.tallies));

    this.Assignments = new AssignmentCollection(SafeJSON.parse(payload.assignments), {});

    this.Slots = new SlotCollection(payload.schedule_data !== "" ? SafeJSON.parse(payload.schedule_data) : null, {
      comparator: window.LbsAppData.Helpers.Sort._getSlotComparator(),
      parse: true,
    });

    this.PublishedMap = new PublishedMapCollection(SafeJSON.parse(payload.published_map));

    this.Views = new ViewCollection(SafeJSON.parse(payload.views));
    this.ViewsLegacy = new ViewLegacyCollection(SafeJSON.parse(payload.views_legacy));

    if (payload.user_settings && payload.user_settings !== "") {
      this.UserSettingsCollection = new UserSettingsCollection(SafeJSON.parse(payload.user_settings));
    }

    // create the tally map
    this.TallyDataMap = new TallyDataMap({
      schedID: this.AppContext.attributes.displayedSchedule.id,
    });

    this.Changes = new ChangeCollection(SafeJSON.parse(payload.changes), {
      schedID: this.AppContext.attributes.displayedSchedule.id,
    });

    window.LbsAppData.AppContext.on("refreshedScheduleStatus", this._fetchChangeLog.bind(this, null), this);

    // push the contexts (one for schedule, one for view)
    this.AppContext.pushContext({
      tag: "schedule",
      text: this.AppContext.attributes.displayedSchedule.attributes.schedule_name,
      tooltip: "open schedule (" + this.AppContext.attributes.displayedSchedule.attributes.schedule_name + ")",
      limitWidthClass: "limit-width-small",
      action: function () {
        // send a tracking event
        window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Schedule: List");

        window.LbsAppData.AppContext.openDialog({ type: "open" });
      },
    });
    this.AppContext.pushContext({
      tag: "view",
      text: "No View",
      tooltip: "open view",
      limitWidthClass: "limit-width-small",
      action: function () {
        window.LbsAppData.AppContext.openDialog({ type: "options" });
      },
    });

    // register events on the AppContext model
    this.AppContext.registerEvents();

    var templateSettings = this.UserSettingsCollection.findWhere({
      template_id: this.AppContext.attributes.displayedSchedule.attributes.template_id,
    });

    // hash takes precedence over defaults
    if (!window.location.hash) {
      // see if they have a default view for the template that is in context --
      if (templateSettings !== undefined) {
        this.UserSettings = templateSettings;
        // they do...right now only default_view exists as a setting..so I'm not going to do any checks
        this.AppContext.openView(templateSettings.attributes.settings.default_view);
      } else {
        // they don't...annoy them to set one..semi-delayed
        var that = this;
        window.setTimeout(function () {
          that.AppContext.annoyDefaultView();
        }, 2000);
      }
    } else {
      if (templateSettings !== undefined) {
        this.UserSettings = templateSettings;
      }

      var match = /\/v\/(\d+)/.exec(window.location.hash);

      if (match) {
        this.AppContext.openView(match[1], true); // don't set a default
      } else {
        // no match... probably should flash a dialog
      }
    }

    // date manager
    this.DateManager.setScheduleEndpoints(
      this.AppContext.attributes.displayedSchedule.attributes.start_date,
      this.AppContext.attributes.displayedSchedule.attributes.stop_date,
      this.AppContext.attributes.view ? this.AppContext.attributes.view.attributes.theme.data.range === "week" : false
    );

    // register the socket helper for realtime communication
    this.Helpers.Socket = new SocketHelper({
      receive: this._receiveSocketMessage,
    });
  },

  _getHolidayComparator: function () {
    var holidayComparator = function (a, b) {
      var orderA = a.attributes.dept_name.toLowerCase();
      var orderB = b.attributes.dept_name.toLowerCase();

      if (orderA == orderB) {
        return 0;
      } else {
        if (orderA < orderB) {
          return -1;
        } else {
          return 1;
        }
      }
    };

    return holidayComparator;
  },

  _fetchChangeLog: function () {
    this.Changes.fetch({
      success: function () {},
    });
  },

  _receiveSocketMessage: function (type, data) {},

  attachRouter: function () {
    // will skip the first load
    Backbone.history.start();
    this.Router = new Router();
  },
});

module.exports = LbsAppData;
