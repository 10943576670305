var ApiConfig = require("_lib/data/ApiConfig.js");
var PersonnelCollection = require("_lib/data/collections/NakedPersonnelCollection.js");

var PersonnelNode = Backbone.Model.extend({
  //idAttribute: "emp_id",
  //urlRoot: (ApiConfig.getPrefix() + '/personnel'),

  defaults: {
    objects: undefined,
  },
  initialize: function () {},

  // return a list of available personnel properties
  properties: function () {
    // name is what we call it
    // key is what the api calls it
    // desc is how we describe it
    return [
      { name: "id", key: "emp_id", desc: "unique identifier" },
      { name: "first_name", desc: "person's first name" },
      { name: "last_name", desc: "person's last name" },
      { name: "display_name", desc: "person's display name" },
    ];
  },

  // return a list of available personnel objects
  objects: function () {
    this.get("objects") ? this.get("objects") : this._fetchObjects();
  },

  // fetch the actual personnel objects -- kind of trying to do a lazy load throughout this application
  _fetchObjects: function () {
    var that = this;
    var objects = new PersonnelCollection().fetch({
      success: function () {
        that.set({ objects: objects });
      },
    });
  },
});

module.exports = PersonnelNode;
