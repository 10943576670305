const Backbone = require("backbone");
var moment = require("moment");
var ApiConfig = require("_lib/data/ApiConfig.js");

var Request = Backbone.Model.extend({
  idAttribute: "request_id",

  defaults: {
    boundTool: undefined,
  },

  initialize: function (models, options) {
    this.personnelObj = window.LbsAppData.Personnel.get(this.attributes.emp_id);
    //this.assignmentObj = window.LbsAppData.Assignments.get(this.attributes.condensed_structure_id);

    // subscribe to personnelObj/assignmentObj changes via tools
    if (this.personnelObj) {
      this.listenTo(this.personnelObj, "change:boundTool", this.toggleReceptive);

      // check to see if it's already bound
      if (this.personnelObj.attributes.boundTool) {
        this.toggleReceptive(this.personnelObj, this.personnelObj.attributes.boundTool);
      }
    }
    //if (this.assignmentObj) {
    //	this.listenTo(this.assignmentObj, 'change:boundTool', this.toggleReceptive);
    //
    //	// check to see if it's already bound
    //	if (this.assignmentObj.attributes.boundTool) {
    //		this.toggleReceptive(this.assignmentObj, this.assignmentObj.attributes.boundTool);
    //	}
    //}

    // create a searchable concatenation of some of the attributes
    this._createSearchableAttributeString();
  },

  url: function () {
    return ApiConfig.getPrefix() + (this.id ? "/request/" + this.id : "/request/");
  },

  massageDisplayText: function () {
    return window.LbsAppData.AppContext.getLeftColumnType() == "assignment"
      ? this.get("display_name")
      : this.get("assign_display_name");
  },

  getEffects: function () {
    if (this.attributes.boundTool) {
      // tool takes precedence
      return this.attributes.boundTool.style();
    } else {
      var ret = {};
      if (this.attributes.bg_color) {
        ret["backgroundColor"] = this.attributes.bg_color;
      }
      if (this.attributes.text_color) {
        ret["color"] = this.attributes.text_color;
      }

      return ret;
    }
  },

  _createSearchableAttributeString: function () {
    var searchableAttributes = this._getSearchableAttributes();
    this.attrString = "";
    for (var i = 0; i < searchableAttributes.length; i++) {
      this.attrString += searchableAttributes[i].name + "=" + searchableAttributes[i].data + ";";
    }
  },
  _getSearchableAttributes: function () {
    var ret = [
      {
        name: "date",
        data: moment(this.attributes.request_date).format("ddd, LL"),
      },
      {
        name: "assignment",
        data: this.attributes.assign_display_name,
      },
      {
        name: "personnel",
        data: this.attributes.display_name,
      },
    ];

    return ret;
  },
});

module.exports = Request;
