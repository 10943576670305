"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");
// components
var TimePicker = require("_lib/ui/modules/widgets/timepicker/TimePicker.jsx");
var timePickerKeyboardActions = require("_lib/utils/timePickerKeyboardActions.js");
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var TimeEditDialog = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {
      focusedCounter: undefined,
      spinnerCounter: 0, // just going to be so I can pass down a keyboard event to the spinners
      numberOfDivs: window.LbsAppData.User.get("time_display") == window.LbsAppData.MILITARY ? 4 : 6,
      blockKeyboardActions: false,
      allowSubmitArray: [true, true],
    };
  },

  componentDidMount: function () {
    // going to listen for left/right arrow here
    $(document).on("keydown", this._processKeyDown);
    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Request: Custom Time");
  },
  componentWillUnmount: function () {
    $(document).off("keydown");
  },

  /* publics */
  _setTime: function (e) {
    this.props.submit(this.refs.StartTimePicker.getTimeValue(), this.refs.StopTimePicker.getTimeValue());
    // this.props.dialogObject.updateStopTime(this.refs.StopTimePicker.getTimeValue());
    this.props.close();
  },

  _unfocusKeydown: function (counter, isStart) {
    var keyboardActions = timePickerKeyboardActions.unfocusKeydown(counter, isStart);
    this.setState({
      focusedCounter: keyboardActions.focusedCounter,
      blockKeyboardActions: true,
    });
  },

  _refocusKeydown: function () {
    this.setState({ blockKeyboardActions: false });
  },

  _submitButtonToggle: function (value, isStart) {
    var allowSubmitArray = timePickerKeyboardActions.submitButtonToggle(value, isStart, this.state.allowSubmitArray);
    this.setState({ allowSubmitArray: allowSubmitArray });
  },

  _processKeyDown: function (e) {
    timePickerKeyboardActions.processKeyDown(
      e,
      this.state.blockKeyboardActions,
      this.state.focusedCounter,
      this.state.spinnerCounter,
      timePickerKeyboardActions.cbCounterStatusUpdate.bind(this)
    );
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      ScheduleEditsExt: true,
      TimeEditDialog: true,
    });

    // focus logic is sort of weird, but we want to allow a user to quickly focus the spinners
    var divsInFocusedTime = this.state.numberOfDivs / 2;
    var isStartTimeFocus = this.state.focusedCounter < divsInFocusedTime;
    var isStopTimeFocus = this.state.focusedCounter >= divsInFocusedTime;

    return (
      <div className={classes}>
        <div className="position-reset">
          <div className="header">Customize Time</div>
          <div
            className="close"
            onClick={this.touchProxy.bind(this, this.props.close, [])}
            onTouchEnd={this.touchProxy.bind(this, this.props.close, [])}
          >
            <i className="fa fa-close" />
          </div>
          <div className="content">
            <div className="timepicker active">
              <TimePicker
                ref="StartTimePicker"
                defaultTimeStr={this.props.dialogObject.startTime}
                bigMode={true}
                focusedCounter={isStartTimeFocus ? this.state.focusedCounter % divsInFocusedTime : undefined}
                spinnerCounter={this.state.spinnerCounter}
                isStartPicker={true}
                unfocusKeydown={this._unfocusKeydown}
                refocusKeydown={this._refocusKeydown}
                submitButtonToggle={this._submitButtonToggle}
              />
              -
              <TimePicker
                ref="StopTimePicker"
                defaultTimeStr={this.props.dialogObject.stopTime}
                bigMode={true}
                focusedCounter={isStopTimeFocus ? this.state.focusedCounter % divsInFocusedTime : undefined}
                spinnerCounter={this.state.spinnerCounter}
                isStartPicker={false}
                unfocusKeydown={this._unfocusKeydown}
                refocusKeydown={this._refocusKeydown}
                submitButtonToggle={this._submitButtonToggle}
              />
            </div>
          </div>
          <div className="bottom-buttons">
            <span className="submit">
              {_.indexOf(this.state.allowSubmitArray, undefined) === -1 && (
                <i
                  className="fa fa-check"
                  data-cy="submitTimeEditBtn"
                  onClick={this.touchProxy.bind(this, this._setTime, [])}
                  onTouchEnd={this.touchProxy.bind(this, this._setTime, [])}
                />
              )}
              {_.indexOf(this.state.allowSubmitArray, undefined) !== -1 && <i className="fa fa-check disabled" />}
            </span>
          </div>
        </div>
      </div>
    );
  },
});

module.exports = TimeEditDialog;
