const Backbone = require("backbone");
var _ = require("underscore");

var ApiConfig = require("_lib/data/ApiConfig.js");
var AssignmentDemand = require("./../models/AssignmentDemand.js");

var AssignmentDemandCollection = Backbone.Collection.extend({
  model: AssignmentDemand,
  //comparator: 'display_name',

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    return ApiConfig.getPrefix() + "/demand/" + this.attributes.schedID;
  },
  //parse: function(response) {
  //
  //},

  getAssignmentsWithDemand: function (date) {
    var assignments = [];
    for (var i = 0; i < this.models.length; i++) {
      var model = this.models[i];
      _.each(model.attributes.struct_demand, function (val, key) {
        if (val.hasOwnProperty(date)) {
          assignments.push(model.id);
        }
      });
    }

    return assignments;
  },
});

module.exports = AssignmentDemandCollection;
