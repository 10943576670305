"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");

// collections

var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var SlotHistoryDialog = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },

  componentDidMount: function () {
    // send a tracking event
    if (!this.props.inline) {
      window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Slot: History");
    }
  },

  /* publics */
  close: function (e) {
    this.props.close();
  },

  /* privates */

  /* render */
  render: function () {
    /* jshint ignore:start */
    const slotHistory = this.props.dialogObject.slot.getHistory() || [];

    const classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      SlotHistory: true,
    });

    return (
      <div className={classes}>
        <div className="position-reset">
          <div className="close">
            <i
              className="fa fa-close"
              onClick={this.touchProxy.bind(this, this.close, [])}
              onTouchEnd={this.touchProxy.bind(this, this.close, [])}
            />
          </div>

          <div className="content">
            <div className="header">History</div>
            <div className="num-entries">{this.props.dialogObject.slot.getHistory().length} changes</div>
            <div className="slot-history-container">
              {slotHistory.map((historyItem, index) => (
                <div className="slot-history-item" key={index}>
                  <div className="slot-history-item-date">
                    {moment(historyItem.timestamp).format(window.LbsAppData.Helpers.Time.preferredTimeFormat("llll"))}
                  </div>
                  <div className="slot-history-item-content">{historyItem.text}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  },
});

module.exports = SlotHistoryDialog;
