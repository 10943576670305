import { Collection, Model } from "backbone";
import { Assignment, AssignmentAttributes, cleanAssignmentAttributes } from "@/viewer/types/domain/assignment";
import * as React from "react";
import batchRegisterListeners from "@/viewer/ui/modules/common/helpers/batchRegisterListeners";
import { FilterMap, SettingsContext } from "@/viewer/ui/modules/common/types/context";

export interface AssignmentData {
  assignments: Assignment[];
  filteredAssignments: FilterMap;
}

const getAssignmentData = (settings: SettingsContext): AssignmentData => {
  const { LbsAppData } = window as any;

  if (!LbsAppData.Assignments) return { assignments: [], filteredAssignments: {} };

  const Assignments: Collection<Model<AssignmentAttributes>> = LbsAppData.Assignments;
  const assignments: Assignment[] = [];
  const filteredAssignments: FilterMap = {};
  Assignments.forEach((item) => {
    assignments.push(cleanAssignmentAttributes(settings, item.attributes));
    if (item.attributes.filtered) {
      filteredAssignments[item.attributes.assign_structure_id ?? ""] = true;
    }
  });

  return {
    assignments,
    filteredAssignments,
  };
};

const getFilteredAssignments = (): FilterMap => {
  const { LbsAppData } = window as any;

  if (!LbsAppData.Assignments) return {};

  const Assignments: Collection = LbsAppData.Assignments;
  const filteredAssignments: FilterMap = {};
  Assignments.forEach((item) => {
    if (item.attributes.filtered) {
      filteredAssignments[item.attributes.assign_structure_id] = true;
    }
  });
  return filteredAssignments;
};

const useAssignmentData = (settings: SettingsContext): AssignmentData => {
  const [assignmentData, setAssignmentData] = React.useState(getAssignmentData(settings));
  React.useEffect(() => {
    const { LbsAppData } = window as any;
    const onFilterChange = () => {
      setAssignmentData({ ...assignmentData, filteredAssignments: getFilteredAssignments() });
    };

    const onDataChange = () => {
      setAssignmentData(getAssignmentData(settings));
    };

    const cleanupFilterListeners = batchRegisterListeners(onFilterChange, [LbsAppData.Assignments, "change:filtered"]);

    const cleanupListeners = batchRegisterListeners(
      onDataChange,
      [LbsAppData.Assignments, "sort"],
      [LbsAppData.Assignments, "change:available"]
    );

    return () => {
      cleanupFilterListeners();
      cleanupListeners();
    };
  }, [settings]);

  return assignmentData;
};

export default useAssignmentData;
