var ApiConfig = require("_lib/data/ApiConfig.js");

var TableNode = Backbone.Model.extend({
  //idAttribute: "emp_id",
  //urlRoot: (ApiConfig.getPrefix() + '/personnel'),

  defaults: {},
  initialize: function () {},

  // return a list of available tally properties
  properties: function () {
    // name is what we call it
    // key is what the api calls it
    // desc is how we describe it
    return [];
  },
});

module.exports = TableNode;
