"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var moment = require("moment");
var Promise = require("bluebird");
// collections
// components
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
var SwapMixin = require("_lib/ui/mixins/SwapMixin.jsx");

var SwapExchangeDialog = React.createClass({
  mixins: [TouchMixin, SwapMixin],

  /* lifecycle methods */
  getInitialState: function () {
    var preData = this.props.dialogObject.data;

    return {
      step: 1,
    };
  },

  componentDidMount: function () {
    // send a tracking event
    if (!this.props.inline) {
      window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Swap: Exchange");
    }
  },

  /* publics */

  /* privates */

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      SwapExchange: true,
    });

    var content;
    if (!this.props.inline) {
      content = (
        <div className={classes}>
          <div className="position-reset">
            <div
              className="close"
              onClick={this.touchProxy.bind(this, this.props.close, [])}
              onTouchEnd={this.touchProxy.bind(this, this.props.close, [])}
            >
              <i className="fa fa-close" />
            </div>

            <div className="section-container">
              <div className={"section _" + this.state.step}>{this._renderStep1()}</div>
            </div>
          </div>
        </div>
      );
    } else {
      content = this._renderInline();
    }

    return content;
  },

  /* rendering functions */
  _renderStep1: function () {
    return (
      <div className="content">
        <div className="header">Exchange</div>

        <div className="editables"></div>
      </div>
    );
  },
  _renderInline: function () {
    return <div className="inline-container"></div>;
  },
});

module.exports = SwapExchangeDialog;
