"use strict";

var React = require("react/addons");
var $ = require("jquery");

var ModeIcon = React.createClass({
  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {
    // register to AppContext tool change
    //window.LbsAppData.ToolBox.on('change:currentTool', this.forceUpdate.bind(this, null), this);
    window.LbsAppData.AppContext.on("change:mode", this.forceUpdate.bind(this, null), this);
  },
  componentWillUnmount: function () {
    //window.LbsAppData.ToolBox.off(null, null, this);
    window.LbsAppData.AppContext.off(null, null, this);
  },
  componentWillUpdate: function () {
    //window.LbsAppData.ToolBox.off(null, null, this);
    window.LbsAppData.AppContext.off(null, null, this);
  },
  componentDidUpdate: function () {
    //window.LbsAppData.ToolBox.on('change:currentTool', this.forceUpdate.bind(this, null), this);
    window.LbsAppData.AppContext.on("change:mode", this.forceUpdate.bind(this, null), this);
  },

  /* publics */

  /* privates */
  _selectMode: function (e) {
    window.LbsAppData.AppContext.set({ mode: this.props.type });
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      ToolBoxIcon: true,
      active: window.LbsAppData.AppContext.get("mode") == this.props.type,
    });

    return (
      <div className={classes} onClick={this.props.onClick || this._selectMode}>
        <i className={"fa fa-fw " + this.props.icon} />

        <div className="tool-tip">
          <div className="triangle"></div>

          <div className="message">{this.props.tooltip}</div>
        </div>
      </div>
    );

    /*
		<ToolBoxMenu currentTool={currentTool} />
		{currentToolComponent}
		*/
  },
});

module.exports = ModeIcon;
