import React, { useRef } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFormik, FormikValues } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import userResource, { NotificationEmailUpdateResponse } from "@/resources/userResource/user.resource";
import Toast, { ToastStatus } from "@/common/components/toast/Toast";
import toastActions from "@/common/components/toast/actions";

import { ResourceUtils } from "@/resources/root/root.resource";

import { LDFlagEnums } from "@/_lib/constants/LDFlagEnums";

import "../_styles.scss";

interface Props {
  readonly currentNotificationEmails: string;
  readonly empId: number | "";
}

const NotificationForm = (props: Props): JSX.Element => {
  const { empId, currentNotificationEmails } = props;
  const flags = useFlags();
  const [updatedNotificationEmails, setUpdatedNotificationEmails] = React.useState<string>(
    currentNotificationEmails ?? ""
  );
  const currentNotificationEmailsRef = useRef<string | undefined>(currentNotificationEmails);
  const dispatch = useDispatch();

  const handleSubmit = async (values: FormikValues) => {
    const discreteEmails = [...new Set(values.notificationEmails.replaceAll(" ", "").split(","))]
      .filter((email) => email)
      .join(",");

    const result = await userResource.updateUserNotificationEmails({ email: discreteEmails });

    if (Reflect.has(result, "status")) {
      const coercedResult = result as NotificationEmailUpdateResponse;
      const { status } = coercedResult;

      if (status && ResourceUtils.responseIntegerIsOk(status)) {
        const notificationEmailWasSet = coercedResult?.email === discreteEmails;

        if (notificationEmailWasSet) {
          setUpdatedNotificationEmails(coercedResult?.email);
          dispatch(
            toastActions.open(
              <Toast status={ToastStatus.Success}>
                <span>{"Notification email(s) successfully updated."}</span>
              </Toast>
            )
          );

          return;
        }
      }

      dispatch(
        toastActions.open(
          <Toast status={ToastStatus.Error}>
            <span>{coercedResult?.Message ?? "Could not update notification email(s)."}</span>
          </Toast>
        )
      );

      return;
    }

    dispatch(
      toastActions.open(
        <Toast status={ToastStatus.Error}>
          <span>{(result as Error).message ?? "Could not reach server."}</span>
        </Toast>
      )
    );
  };

  const formik = useFormik({
    initialValues: {
      notificationEmails: updatedNotificationEmails,
    },
    validationSchema: Yup.object({
      notificationEmails: Yup.array()
        .transform((_currVal, originalValue) => Array.from(new Set(originalValue.replaceAll(" ", "").split(","))))
        .of(Yup.string().email(({ value }) => `${value} is not a valid email.`))
        .required("Please provide at least one email"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (flags[LDFlagEnums.Lv4729NotificationEditButtonShowNotificationSettingsEditButton]) {
        await handleSubmit(values);
        resetForm();
      }
    },
  });

  React.useEffect(() => {
    if (updatedNotificationEmails !== currentNotificationEmailsRef.current) {
      (window as any).LbsAppData.Personnel.get(empId).set("notification_email", updatedNotificationEmails);
      currentNotificationEmailsRef.current = updatedNotificationEmails;
    }
  }, [updatedNotificationEmails]);

  return (
    <form className="body-form__notificationEmails" onSubmit={formik.handleSubmit}>
      <label>Current Notification Email(s):</label>
      <span className={"notificationEmailsContainer"}>{updatedNotificationEmails}</span>
      <div className="form-group">
        <label htmlFor={"notificationEmails"}>New Notification Email(s):</label>
        <input
          type="text"
          name="notificationEmails"
          value={formik.values.notificationEmails}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <span style={{ fontSize: "10px" }}>Multiple emails should be separated with a comma.</span>
        {formik.touched.notificationEmails && formik.errors.notificationEmails && (
          <div className={"error-text"}>{formik.errors.notificationEmails}</div>
        )}
      </div>

      <p>Set new notification email(s) for your account</p>
      <div className="action-bar">
        <button type="submit">Update</button>
      </div>
    </form>
  );
};

export default NotificationForm;
