"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var _ = require("underscore");
var moment = require("moment");
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var ListDialogItem = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {},
  componentWillUnmount: function () {},
  //componentWillUpdate: function() {
  //	this._unregisterBackboneEvents();
  //},
  //componentDidUpdate: function() {
  //	this._registerBackboneEvents();
  //},

  /* publics */
  close: function (e) {
    this.props.close();
  },

  /* privates */
  _handleClick: function (e) {
    this.props.onClick(this.props.data);
  },

  _handleClickWithTouch: function (e) {
    var that = this;
    this._endTouch(that._handleClick, e);
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    const icon = this.props.icon;

    var filtered = this.props.data.attributes.filtered;

    var classes = React.addons.classSet({
      ListItem: true,
      //'selected': this.props.selected,
      //'on': !filtered
    });

    var displayContainer = !_.isFunction(this.props.display)
      ? this.props.data.attributes[this.props.display]
      : this.props.display(this.props.data);

    return (
      <div
        className={classes}
        onClick={this.touchProxy.bind(this, this._handleClick, [])}
        onTouchStart={this._startTouch}
        onTouchMove={this._moveTouch}
        onTouchEnd={this.touchProxy.bind(this, this._handleClickWithTouch, [])}
      >
        <span className={this.props.selected ? "text selected" : "text"}>
          {displayContainer} {icon}
        </span>
      </div>
    );
  },
});

module.exports = ListDialogItem;
