/**
 * This file exports a utility to aid in the creation of backbone-listening hooks.
 *
 * Accepts two functions:
 * * One returning the desired data collected from the current global state, and
 * * one returning a list of <ListenerDesc> to trigger re-loading of this data.
 *
 * Also accepts an optional function called onUpdate, in case you want to log something here.
 */
import batchRegisterListeners, { ListenerDesc } from "../helpers/batchRegisterListeners";
import * as React from "react";
import useUpdateEffect from "@/viewer/ui/modules/common/hooks/useUpdateEffect";

interface ConnectedPropertyHookFactoryOpts<T, TArgs extends Array<unknown> = []> {
  loader: (...args: TArgs) => T;
  getListeners: () => ListenerDesc[];
  onUpdate?: () => void;
}

const connectedPropertyHookFactory = <T, TArgs extends Array<unknown> = []>(
  opts: ConnectedPropertyHookFactoryOpts<T, TArgs>
) => {
  return (...args: TArgs): T => {
    const initialValue = React.useMemo(() => opts.loader(...args), []);
    const [cachedProperty, setCachedProperty] = React.useState<T>(initialValue);

    React.useEffect(() => {
      const onChange = () => {
        opts.onUpdate && opts.onUpdate();
        setCachedProperty(opts.loader(...args));
      };

      return batchRegisterListeners(onChange, ...opts.getListeners());
    });

    if (args && args.length > 0) {
      // Watch the list of deps and reload data when they change.
      useUpdateEffect(() => {
        setCachedProperty(opts.loader(...args));
      }, args);
    }

    return cachedProperty;
  };
};

export default connectedPropertyHookFactory;
