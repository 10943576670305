const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var PreSwap = require("./../models/swaps/Swap.js");

var PreSwapCollection = Backbone.Collection.extend({
  model: PreSwap,
  url: ApiConfig.getPrefix() + "/schedule/preswap",

  save: function (success_callback, error_callback) {
    Backbone.sync("create", this, {
      success: function (response) {
        success_callback(response);
      },
      error: function (response) {
        error_callback(response);
      },
    });
  },
});

module.exports = PreSwapCollection;
