"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var moment = require("moment");
// components
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var BulkActionDialog = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {},
  componentWillUnmount: function () {},

  /* privates */

  /* publics */

  /* render */
  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      SlotList: true,
      exchange: this.props.action == window.LbsAppData.EXCHANGE_ACTION,
    });

    var swapSentences = this.props.widgetProxy("sentenceSwaps");

    return <div className="details">{swapSentences}</div>;
  },
});

module.exports = BulkActionDialog;
