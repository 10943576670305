import React from "react";
import moment from "moment";
import { KeyboardTimePicker, KeyboardTimePickerProps } from "@material-ui/pickers";
import { FieldProps, getIn } from "formik";

import Label from "../meta/Label";
import ErrorMessage from "../meta/ErrorMessage";
import classnames from "@/_lib/utils/classnames";
import styles from "./styles.module.scss";

type Variant = "dialog" | "inline" | "static";
type Orientation = "landscape" | "portrait";
type View = "hours" | "minutes" | "seconds";

interface Props extends Partial<KeyboardTimePickerProps>, FieldProps<null | moment.Moment> {
  label?: string;
  placeholder?: string;
  variant?: Variant;
  orientation?: Orientation;
  views?: View[];
  mask?: string;
  format?: string;
  ampm?: boolean;
  disabled?: boolean;
  [key: string]: unknown;
}

const RenderTimeField = ({
  field: { name, value, onBlur },
  form: { errors, touched, setFieldValue },
  label = "",
  placeholder = "",
  variant = "dialog",
  orientation = "landscape",
  views = ["hours", "minutes"],
  ampm = true,
  format = ampm ? "hh:mm A" : "HH:mm",
  mask = ampm ? "__:__ _M" : "",
  disabled = false,
  ...rest
}: Props): React.ReactElement => {
  const error = getIn(errors, name);
  const touch = getIn(touched, name);

  const rootClassNames = classnames({
    [styles.root]: true,
    [styles.disabled]: disabled,
  });

  const inputClassNames = classnames({
    [styles.input]: true,
    [styles.error]: touch && !!error,
  });

  const handleChange = (date: null | moment.Moment) => {
    setFieldValue(name, date);
  };

  return (
    <div className={rootClassNames}>
      {label && <Label text={label} />}
      <KeyboardTimePicker
        InputAdornmentProps={{ id: "time-picker-adornment" }}
        className={inputClassNames}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        variant={variant}
        orientation={orientation}
        mask={mask}
        format={format}
        ampm={ampm}
        views={views}
        invalidDateMessage={null}
        maxDateMessage={null}
        minDateMessage={null}
        inputVariant="outlined"
        {...rest}
      />
      {touch && !!error && !disabled && <ErrorMessage text={error} />}
    </div>
  );
};

export default RenderTimeField;
