// Don't use `format` directly! Use one of these helpers instead, or add one, for consistency's sake
// TODO support locales
// https://date-fns.org/v2.15.0/docs/format

import { SettingsContext } from "@/viewer/ui/modules/common/types/context";
import { format } from "date-fns-tz";

const dateFormatterFactory =
  (fmt: string, militaryFmt?: string) => (dateOrSettings: Date | SettingsContext, d?: Date) => {
    if (d === undefined) {
      // No settings only date
      return format(dateOrSettings as Date, fmt);
    }

    // Both are present
    const settings: SettingsContext = dateOrSettings as SettingsContext;
    if (settings.useMilitaryTime) {
      return format(d, militaryFmt ?? fmt);
    }
    return format(d, fmt);
  };

// 2001-02-03
export const formatISODate = dateFormatterFactory("yyyy-MM-dd");

// 2001-02-03T04:05:06 (No tz)
export const formatISODateTime = dateFormatterFactory("yyyy-MM-dd'T'HH:mm:ss");

// 20010203
export const formatDayKey = dateFormatterFactory("yyyyMMdd");

// 02/03/2001
export const formatUSDate = dateFormatterFactory("MM/dd/yyyy");

// 02/03
export const formatUSDateNoYear = dateFormatterFactory("MM/dd");

// 04:05am also turns out that date-fns doesn't have a way to do lower case am/pm so you just tack m on the side, working as intended...
export const formatLocalTime = dateFormatterFactory("h:mmaaaaa'm'", "HH:mm");

// 04:05am also turns out that date-fns doesn't have a way to do lower case am/pm so you just tack m on the side, working as intended...
export const formatLocalTimeWithMonthAndDay = dateFormatterFactory("h:mmaaaaa'm' (M/dd)", "HH:mm (M/dd)");

// Sa 02/03
// equivalent moment fmt: dd L
export const formatStandardDay = dateFormatterFactory("EEEEEE MM/dd");

// Sa 02/03
// equivalent moment fmt: ll
export const formatHumanizedDate = dateFormatterFactory("MMM d, yyyy");

// January 1, 2021
// equivalent moment fmt: LL
export const formatFullHumanizedDate = dateFormatterFactory("MMMM d, yyyy");

// 2/3 Sat
// equivalent moment format: l ddd
export const formatCalendarDay = dateFormatterFactory("M/d EEE");

/**
 * Translate some custom time formats to date-fn standards
 */
export const applyPreferredTimeFormat = (settings: SettingsContext, d: Date, standard: string): string => {
  const { timeZoneEnabled, timeZone, useMilitaryTime } = settings;

  if (!useMilitaryTime) {
    return standard;
  }

  let toFormat = standard;
  switch (standard) {
    case "LT z":
      // return "HH:mm z";
      toFormat = "hh:mm z";
      break;

    case "LT":
      toFormat = "HH:mm";
      break;

    case "lll":
      // return "MMM D YYYY HH:mm";
      toFormat = "MMM d YYYY HH:mm";
      break;

    case "llll":
      // return "ddd, MMM D YYYY HH:mm";
      toFormat = "EEE, MMM d YYYY HH:mm";
      break;

    case "h:mma":
      toFormat = "h:mm";
      break;

    case "h:mma (l)":
      // return "HH:mm (l)";
      toFormat = "h:mm (P)";
      break;
  }

  if (timeZoneEnabled && timeZone) {
    return format(d, toFormat, { timeZone });
  } else {
    return format(d, toFormat);
  }
};
