import {
  AO_CUSTOM,
  AO_DISPLAY,
  AO_TIME_CUSTOM,
  AO_TIME_DISPLAY,
  PO_CUSTOM,
  PO_DISPLAY,
  PO_LAST,
  PO_TIME_CUSTOM,
  PO_TIME_DISPLAY,
  PO_TIME_LAST,
  PO_TIME_SUBMITTED,
} from "../constants";

/**
 * Factory for the sorting comparator to be used with Slot and Request collections.
 *
 * Note: To function correctly, the respective collections must implement `_getSlotComparatorValue(a, b)`
 *       See the returned comparator for details.
 *
 * @returns {function(*=, *=): number}
 */
const SlotQuestComparatorFactory = () => {
  // this can pull double duty now on slots AND requests..now that we have assignment structure id's on requests
  const sortPersonnelBy = window.LbsAppData.AppContext.attributes.view.filter.sort_personnel_by;
  const sortAssignmentsBy = window.LbsAppData.AppContext.attributes.view.filter.sort_assignments_by;
  const layout = window.LbsAppData.AppContext.attributes.view.theme.data.layout;
  const leftColumnType = window.LbsAppData.AppContext.getLeftColumnType();

  let slotOrdering = window.LbsAppData.AppContext.attributes.slotOrdering;
  // the only views that have slotOrdering defined directly on them are today and me.  the user-defined views
  // will fall into this other logic
  if (!slotOrdering) {
    if (leftColumnType === "personnel") {
      // focus is on assignments on the slots
      if (sortAssignmentsBy === AO_CUSTOM) {
        slotOrdering = "assign_order";
      } else if (sortAssignmentsBy === AO_TIME_CUSTOM) {
        slotOrdering = "start_time!assign_order";
      } else if (sortAssignmentsBy === AO_DISPLAY) {
        slotOrdering = "assign_display_name";
      } else if (sortAssignmentsBy === AO_TIME_DISPLAY) {
        slotOrdering = "start_time!assign_display_name";
      }
    } else {
      // focus is on personnel on the slots
      if (sortPersonnelBy === PO_CUSTOM) {
        slotOrdering = "personnel_order";
      } else if (sortPersonnelBy === PO_TIME_CUSTOM) {
        slotOrdering = "start_time!personnel_order";
      } else if (sortPersonnelBy === PO_DISPLAY) {
        slotOrdering = "display_name";
      } else if (sortPersonnelBy === PO_TIME_DISPLAY) {
        slotOrdering = "start_time!display_name";
      } else if (sortPersonnelBy === PO_LAST) {
        slotOrdering = "personnel_last_name";
      } else if (sortPersonnelBy === PO_TIME_LAST) {
        slotOrdering = "start_time!personnel_last_name";
      } else if (sortPersonnelBy === PO_TIME_SUBMITTED) {
        slotOrdering = "personnel_time_submitted";
      }
    }

    // calendar/gantt/list...not a row to sort...so shove in the left column type in front of the sentence
    // that we just constructed
    if (layout === "calendar" || layout === "gantt") {
      if (leftColumnType === "personnel") {
        if (sortPersonnelBy === PO_CUSTOM || sortPersonnelBy === PO_TIME_CUSTOM) {
          slotOrdering = "personnel_order!" + slotOrdering;
        } else if (sortPersonnelBy === PO_DISPLAY || sortPersonnelBy === PO_TIME_DISPLAY) {
          slotOrdering = "display_name!" + slotOrdering;
        } else if (sortPersonnelBy === PO_LAST || sortPersonnelBy === PO_TIME_LAST) {
          slotOrdering = "personnel_last_name!" + slotOrdering;
        }
      } else {
        if (sortAssignmentsBy === AO_CUSTOM || sortAssignmentsBy === AO_TIME_CUSTOM) {
          slotOrdering = "assign_order!" + slotOrdering;
        } else if (sortAssignmentsBy === AO_DISPLAY || sortAssignmentsBy === AO_TIME_DISPLAY) {
          slotOrdering = "assign_display_name!" + slotOrdering;
        }
      }
    }

    if (layout === "list") {
      // list view can sort the outer axis by time as well...none of this || shit here
      if (leftColumnType === "personnel") {
        if (sortPersonnelBy === PO_CUSTOM) {
          slotOrdering = "personnel_order!" + slotOrdering;
        } else if (sortPersonnelBy === PO_TIME_CUSTOM) {
          slotOrdering = "start_time!personnel_order!" + slotOrdering;
        } else if (sortPersonnelBy === PO_DISPLAY) {
          slotOrdering = "display_name!" + slotOrdering;
        } else if (sortPersonnelBy === PO_TIME_DISPLAY) {
          slotOrdering = "start_time!display_name!" + slotOrdering;
        } else if (sortPersonnelBy === PO_LAST) {
          slotOrdering = "personnel_last_name!" + slotOrdering;
        } else if (sortPersonnelBy === PO_TIME_LAST) {
          slotOrdering = "start_time!personnel_last_name!" + slotOrdering;
        }
      } else {
        if (sortAssignmentsBy === AO_CUSTOM) {
          slotOrdering = "assign_order!" + slotOrdering;
        } else if (sortAssignmentsBy === AO_TIME_CUSTOM) {
          slotOrdering = "start_time!assign_order!" + slotOrdering;
        } else if (sortAssignmentsBy === AO_DISPLAY) {
          slotOrdering = "assign_display_name!" + slotOrdering;
        } else if (sortAssignmentsBy === AO_TIME_DISPLAY) {
          slotOrdering = "start_time!assign_display_name!" + slotOrdering;
        }
      }

      // list has the addition issue that you can span multiple days within the slot containing element (the table in this case)
      // so need to put in the date into the ordering first in that case
      // -- now last cuz UK shall have what they desire
      if (layout === "list") {
        slotOrdering = slotOrdering + "!start_time";
      }
    }
  }

  // set the sentence
  window.LbsAppData.AppContext.set({ slotOrdering: slotOrdering });

  /*
     This _has_ to be a function. The calls to `this._getSlotComparatorValue(...)`, must have
     `this` reference the object this comparator will be called from, not the current value of
     `this` which would be undefined.
   */
  const slotQuestComparator = function (a, b) {
    const splitOrdering = window.LbsAppData.AppContext.attributes.slotOrdering.split("!");
    // compare level 1, then level 2, then level 3
    let i = 0;
    let compareA, compareB;
    do {
      // get the values to compare
      // fyi the SlotComparatorValue function is in the _collection_
      compareA = this._getSlotComparatorValue(a, splitOrdering[i]);
      compareB = this._getSlotComparatorValue(b, splitOrdering[i]);

      if (compareA < compareB) {
        return -1;
      } else if (compareB < compareA) {
        return 1;
      }

      i++;
    } while (compareA == compareB && i < splitOrdering.length); // implicit equality check (provided for clarity)

    // everything was the same
    return 0;
  };

  return slotQuestComparator;
};

export default SlotQuestComparatorFactory;
