// Utils to generate the required slot data

import { SlotsByDateByColumn } from "@/viewer/ui/modules/common/types";
import { Slot } from "@/viewer/types/domain/slot";
import { appendToPath2 } from "@/viewer/utils/helpers";
import { DataContext, FilterContext, SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import useFilteredSlotData from "@/viewer/ui/modules/common/hooks/useFilteredSlotData";
import * as React from "react";
import { Collection } from "backbone";

const getSlotDataForAssignment = (slots: Slot[]): SlotsByDateByColumn => {
  // No pending data here, just gather up and return the slots

  const scheduled: SlotsByDateByColumn = {};

  slots.forEach((slot) => {
    const columnId = slot.condensedStructureId;
    appendToPath2(scheduled, [columnId, slot.dateString], slot);
  });

  return scheduled;
};

const getSlotDataForPersonnel = (slots: Slot[]): SlotsByDateByColumn => {
  const scheduled: SlotsByDateByColumn = {};

  slots.forEach((slot) => {
    const columnId = slot.empId.toString();
    appendToPath2(scheduled, [columnId, slot.dateString], slot);

    if (slot.isPending && slot.pendingEmpId && slot.pendingEmpId !== slot.empId) {
      const columnId = slot.pendingEmpId.toFixed(0);
      appendToPath2(scheduled, [columnId, slot.dateString], slot);
    }
  });

  return scheduled;
};

const getColumnSlotData = (ui: UIContext, slots: Slot[]): SlotsByDateByColumn => {
  if (ui.leftColumnType === "assignment") {
    return getSlotDataForAssignment(slots);
  }

  return getSlotDataForPersonnel(slots);
};

const useColumnSlotData = (
  settings: SettingsContext,
  ui: UIContext,
  filters: FilterContext,
  data: DataContext,
  slotCollection: Collection
): SlotsByDateByColumn => {
  const slots = useFilteredSlotData(settings, ui, filters, data, slotCollection);
  return React.useMemo(() => getColumnSlotData(ui, slots), [ui.leftColumnType, slots]);
};

export default useColumnSlotData;
