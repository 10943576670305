import checkAndSet from "@/_lib/utils/checkAndSet";
import { Model } from "backbone";
import * as React from "react";
import batchRegisterListeners, { ListenerDesc } from "@/viewer/ui/modules/common/helpers/batchRegisterListeners";

type FieldTransform<T> = (v: any) => T;
type InverseFieldTransform<T> = (v: T | undefined) => any;
type PropertyAccessor<T, TKey extends keyof T> =
  | [Model, string]
  | [Model, string, FieldTransform<T[TKey]>, InverseFieldTransform<T[TKey]>];

type ListenerOpts<T> = {
  [K in keyof T]: PropertyAccessor<T, K>;
};

interface SettableConnectedPropertyHookFactoryOpts<T> {
  listenerOpts: ListenerOpts<T>;
  onUpdate?: () => void;
}

export default <T>(optsFunc: () => SettableConnectedPropertyHookFactoryOpts<T>): [T, (v: Partial<T>) => void] => {
  const { listenerOpts, onUpdate } = optsFunc();

  const values: PropertyAccessor<T, keyof T>[] = Object.values(listenerOpts);
  const listeners: ListenerDesc[] = values.map(([model, modelKey, _]) => [model, `change:${modelKey}`]);

  const loader = (): T => {
    const out: Record<string, any> = {};
    const entries: [string, PropertyAccessor<T, keyof T>][] = Object.entries(listenerOpts);
    for (const [tFieldName, value] of entries) {
      const [model, modelField, transformer] = value;
      const modelVal = model?.get(modelField as string);
      out[tFieldName] = transformer ? transformer(modelVal) : modelVal;
    }
    return out as T;
  };

  const propertySetter = (valuesToSet: Partial<T>): void => {
    if (!valuesToSet) {
      return;
    }
    const keys: string[] = Object.keys(valuesToSet);
    keys.forEach((key) => {
      const propertyAccessor = listenerOpts[key as keyof T];
      const [model, modelField, _, inverseTransform] = propertyAccessor;
      if (model) {
        const valueToSet = valuesToSet[key as keyof T];
        const newValue = inverseTransform ? inverseTransform(valueToSet) : valueToSet;
        checkAndSet(model, modelField, newValue);
      }
    });
  };

  const initialValue = React.useMemo(loader, []);
  const [cachedProperty, setCachedProperty] = React.useState<T>(initialValue);
  const isMounted = React.useRef<boolean>(true);

  React.useEffect(() => {
    const onChange = () => {
      onUpdate && onUpdate();

      if (isMounted.current) {
        setCachedProperty(loader());
      }
    };

    return batchRegisterListeners(onChange, ...listeners);
  });

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  return [cachedProperty, propertySetter];
};
