const Backbone = require("backbone");
const _ = require("lodash");
const Promise = require("bluebird");

const ApiConfig = require("_lib/data/ApiConfig.js");
const Request = require("./../models/Request.js");
const logging = require("@/_lib/utils/logging");
const injectInstrumentation = require("@/_lib/utils/instrumentationClient").default;

const RequestDataCollection = Backbone.Collection.extend({
  model: Request,
  url: function () {
    const queryParams = {};

    if (this.attributes.adhoc) {
      // we're creating an adhoc collection... so don't rely on anything outside of this object
      if (this.attributes.start_date) {
        queryParams["start_date"] = this.attributes.start_date.format("YYYYMMDD");
      }

      if (this.attributes.end_date) {
        queryParams["end_date"] = this.attributes.end_date.format("YYYYMMDD");
      }

      queryParams["listed"] = true;

      if (this.attributes.emp_id) {
        queryParams["emp_id"] = this.attributes.emp_id;
      }

      if (this.attributes.only_pending) {
        queryParams["only_pending"] = this.attributes.only_pending;
      }
    } else {
      // if start/end attributes where defined...use them...otherwise use the date manager
      if (this.attributes.startDate) {
        queryParams["start_date"] = this.attributes.startDate.format("YYYYMMDD");
      } else if (window?.LbsAppData?.DateManager?.attributes?.start_date) {
        queryParams["start_date"] = window.LbsAppData.DateManager.attributes.start_date.format("YYYYMMDD");
      }

      // ditto
      if (this.attributes.endDate) {
        queryParams["end_date"] = this.attributes.endDate.format("YYYYMMDD");
      } else if (window?.LbsAppData?.DateManager?.attributes?.stop_date) {
        queryParams["end_date"] = window.LbsAppData.DateManager.attributes.stop_date.format("YYYYMMDD");
      }

      // if this isn't the 'today' or 'me' view then pass in the view_id
      if (window?.LbsAppData?.AppContext?.attributes?.view?.view_id === "today") {
        // nothing
      } else if (window?.LbsAppData?.AppContext?.attributes?.view?.view_id === "me") {
        const empId = window?.LbsAppData?.User?.attributes?.emp_id;

        if (!empId) {
          logging.error("Emp id is null. Refusing to build /request/range request. - Login_RequestDataCollection");

          return null;
        }

        queryParams["emp_id"] = empId;
      } else if (window?.LbsAppData?.AppContext?.attributes?.view?.view_id) {
        queryParams["view_id"] = window.LbsAppData.AppContext.attributes.view.view_id;
      }
    }

    queryParams["listed"] = "true";

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    return (
      ApiConfig.getPrefix() +
      "/request/range/?" +
      _.map(cleanQueryParams, function (value, key) {
        return key + "=" + value;
      }).join("&")
    );
  },

  initialize: function (models, attributes) {
    this.attributes = {};

    for (const key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  comparator: function (a, b) {
    const orderA = a.attributes.request_date;
    const orderB = b.attributes.request_date;

    if (orderA === orderB) {
      return 0;
    } else {
      if (orderA < orderB) {
        return -1;
      } else {
        return 1;
      }
    }
  },

  parse: function (response) {
    return response.data;
  },

  _fetchRequests: function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    injectInstrumentation();

    return new Promise(function (resolve, reject) {
      that.fetch({
        success: function (collection, response, options) {
          resolve();
        },
        error: function () {
          reject();
        },
      });
    });
  },
});

module.exports = RequestDataCollection;
