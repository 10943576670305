const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");

var Personnel = Backbone.Model.extend({
  idAttribute: "emp_id",

  defaults: {
    boundTool: undefined,
    available: true,
    filtered: false,
    //"forced": false
  },

  initialize: function () {
    // attach event listeners to this employee's department objects
    //for (var i = 0; i < this.attributes.departments.length; i++) {
    //	var dept = window.LbsAppData.Departments.get(this.attributes.departments[i]);
    //
    //	if (dept) {
    //		this.listenTo(dept, 'change:filtered', this.setAvailability);
    //	}
    //}

    // display + compact attributes (if it's different)
    if (this.attributes.compact_name != this.attributes.display_name) {
      this.attributes.display_plus_compact = this.attributes.display_name + " [" + this.attributes.compact_name + "]";
    } else {
      this.attributes.display_plus_compact = this.attributes.display_name;
    }

    // default the order to display_name
    this.attributes.order = this.attributes.display_name.toLowerCase();

    // list to store assignments available to this personnel
    this.roles = [];

    // component map (for realtime updates that don't require total redraws)
    this._components = {};

    // hide expired setting would cause things to possibly become unavailable
    //this.listenTo(window.LbsAppData.AppContext, 'change:GlobalSettings_hideExpired', this.setAvailability);
  },

  url: function () {
    return ApiConfig.getPrefix() + (this.id ? "/personnel/" + this.id : "/personnel");
  },

  setAvailability: function (eventObj) {
    //var availability = this._checkAvailability();
    //if (availability != this.attributes.available) {
    //	this.set({'available': availability});
    //}
  },

  _checkAvailability: function () {
    // if employee is not scheduled, then they are unavailable
    //if (!this.attributes.scheduled){
    //	return false;
    //}
    //
    //// if employee is expired AND user is set to hide expired, then they are unavailable
    //if (this.attributes.expired && window.LbsAppData.AppContext.attributes.GlobalSettings_hideExpired){
    //	return false;
    //}
    //
    //// check employee's departments to see if any are unfiltered
    //for (var i = 0; i < this.attributes.departments.length; i++) {
    //	var dept = window.LbsAppData.Departments.get(this.attributes.departments[i]);
    //	if (dept && !dept.attributes.filtered) {
    //		// found one
    //		return true;
    //	}
    //}
    //
    //return false;

    return true;
  },

  getDisplayText: function () {
    if (window.LbsAppData.AppContext.attributes.compactMode != "yes") {
      // non compact mode
      return this.attributes.display_name;
    } else {
      // compact mode
      return this.attributes.display_plus_compact;
    }
  },

  getEffects: function () {
    if (this.attributes.boundTool) {
      // tool takes precedence
      return this.attributes.boundTool.style();
    } else if (window.LbsAppData.AppContext.attributes.view) {
      var ret = {};

      var colorMethod = window.LbsAppData.AppContext.attributes.view.attributes.theme.data.colorMethod;
      var colorTextMethod = window.LbsAppData.AppContext.attributes.view.attributes.theme.data.colorTextMethod;

      if (colorMethod == "mixed" || colorMethod == "row") {
        // view is set to color rows
        if (this.attributes.color) {
          ret["backgroundColor"] = this.attributes.color;
        }
      }

      if (colorTextMethod == "colored") {
        // view is set to color text
        if (this.attributes.colorText) {
          ret["color"] = this.attributes.colorText;
        }
      }

      return ret;
    } else {
      // nothing
      return {};
    }
  },

  _isExpired: function () {
    return this.attributes.expired;
  },
});

module.exports = Personnel;
