const Backbone = require("backbone");
var Template = Backbone.Model.extend({
  idAttribute: "template_id",

  defaults: {
    available: true,
    filtered: false,
  },

  initialize: function (attributes, options) {
    // set the department_id 'foreign key'
    //this.set({ "department_id": options.department_id });
  },

  toggleAvailability: function () {
    this.set({ available: !this.attributes.available });
  },

  toggleFiltered: function () {
    this.set({ filtered: !this.attributes.filtered });

    this.trigger("change:filteredModel");
  },

  _isHidden: function () {
    return this.attributes.is_hidden;
  },
});

module.exports = Template;
