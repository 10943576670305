import { TWELVE_HOUR_FORMAT } from "@/_lib/utils/time";
import { Assignment } from "@/viewer/types/domain/assignment";
import { RequestAttributes } from "@/viewer/types/domain/request";
import { SlotAttributes } from "@/viewer/types/domain/slot";
import { ViewData } from "@/viewer/types/viewdata";
import { UIContext } from "@/viewer/ui/modules/common/types/context";
import { isInViewDefinition } from "@/viewer/ui/modules/modals/add-shift/helpers";
import { getTimeBasedOnSlotTimesTZConversionState } from "@/viewer/utils/timezones";

/**
 * Check if a slot or request is within the displayed range
 * By using the padded start date, we can determine if a slot should be visible
 * in the current displayed view range, NOT the actual view range.
 * @param item - Slot or Request
 * @param viewData - View Data
 * @param uiContext - UI Context
 * @param usePaddedStartDate - If true, will check if the slot is in the displayed view range, NOT the actual view range
 * e.g. If the view range is 1/1/2021 - 1/31/2021, this will check if the slot is visible for any days displayed prior to 1/1/2021
 */
export const isItemInView = (
  item: RequestAttributes | SlotAttributes,
  viewData: ViewData,
  uiContext: UIContext,
  usePaddedStartDate = false
): boolean => {
  if (!item) return false;

  // eslint-disable-next-line prefer-const
  let { request_date: slotDate, assign_id, emp_id } = item;

  // Is item a slot and has a valid date?
  if ((item as SlotAttributes)?.slot_uuid) {
    slotDate = (item as SlotAttributes).slot_date;
  }

  // If SlotQuest does not have a valid date BAIL!
  if (!slotDate) return false;

  //Mapping the assignments id's from the View's assignments
  const currentAssignments = viewData.assignments
    .map(({ templateToAssignIdMap }) => Object.values(templateToAssignIdMap))
    .flat();

  // Mapping the personnel id's of View's personnel
  const currentPersonnel = viewData.personnel.map(({ empId }) => {
    return empId;
  });

  const { stopDate, startDateWithCushion, startDate } = uiContext;

  // If usePaddedStartDate is true, we will use the padded start date
  // to determine if the slot is visible in the current view range.
  if (usePaddedStartDate) {
    return isInViewDefinition(
      startDateWithCushion,
      stopDate,
      slotDate,
      assign_id,
      emp_id,
      currentAssignments,
      currentPersonnel
    );
  }

  return isInViewDefinition(startDate, stopDate, slotDate, assign_id, emp_id, currentAssignments, currentPersonnel);
};

interface DefaultTimes {
  startTime: string;
  endTime: string;
}

export const getSlotDefaultTime = (
  assignment: Assignment,
  templateId: number,
  slotDateString: string
): DefaultTimes => {
  const assignmentDefaultTimeMapString = assignment.templateToDefaultTimesMap[templateId];
  const defaultTimes = assignmentDefaultTimeMapString.split("~").map((time) => `${slotDateString}T${time}`);

  const convertedTimes = getTimeBasedOnSlotTimesTZConversionState({
    times: defaultTimes,
    formatString: TWELVE_HOUR_FORMAT,
  });

  return {
    startTime: convertedTimes[0],
    endTime: convertedTimes[1],
  };
};
