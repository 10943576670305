const Backbone = require("backbone");
var moment = require("moment");

var TallyDataByDate = Backbone.Model.extend({
  idAttribute: "date",

  defaults: {},

  initialize: function (attributes, options) {},
});

module.exports = TallyDataByDate;
