/*
 * LOA Reasons collection that doesn't depend on window object bindings */
const Backbone = require("backbone");

const ApiConfig = require("_lib/data/ApiConfig.js");
const NakedLoaReason = require("./../models/NakedLoaReason.js");

const NakedLoaReasonCollection = Backbone.Collection.extend({
  model: NakedLoaReason,
  comparator: "name",

  initialize: function (models, attributes) {
    this.attributes = {};

    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    const loareasons = ApiConfig.getPrefix() + "/loareasons";

    return loareasons;
  },
});

module.exports = NakedLoaReasonCollection;
