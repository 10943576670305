import { Collection, Model } from "backbone";
import { RequestAttributes } from "@/viewer/types/domain/request";

const useRequestCollection = (): Collection<Model<RequestAttributes>> => {
  const { LbsAppData } = window as any;

  return LbsAppData.Requests;
};

export default useRequestCollection;
