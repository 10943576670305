import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "@/modules/reducer";
import { AppDispatch } from "@/modules/types";
import { initialize } from "@/modules/init/actions";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MessageDialog from "@/viewer/ui/modules/dialog/types/message/MessageDialog";
import ModalDialog from "@/components/ModalDialog";

interface Props {
  // The only child is a renderer function
  onLoad: () => JSX.Element;
}

/**
 * AppLoader is responsible for awaiting the payload before allowing rendering to continue.
 */
const AppLoader = ({ onLoad }: Props) => {
  const { initialized, nextUrl } = useSelector((state: AppState) => state.init);
  const dispatch = useDispatch<AppDispatch>();

  // Dispatch initialize action on first load
  React.useEffect(() => {
    dispatch(initialize());
  }, []);

  // Attach the client side router after first render, since it needs to run after
  // LbsViewerApp is done.
  React.useLayoutEffect(() => {
    const { LbsAppData } = window as any;
    if (LbsAppData) {
      LbsAppData.attachRouter();
    }
  }, [(window as any).LbsAppData]);

  if (initialized) {
    // This must be imported after we're sure that window.LbsAppData has been instantiated
    return onLoad();
  } else if (nextUrl) {
    return (
      <ModalDialog>
        <div className="message">Your session has expired.</div>
        <div className="link-container">
          <div className="btn" onClick={() => (location.href = nextUrl)}>
            Login
          </div>
        </div>
      </ModalDialog>
    );
  } else {
    if (window.location.pathname !== "/login") {
      return (
        <div id="SplashScreen" data-cy="SplashScreen">
          <MessageDialog
            isTop={true}
            dialogObject={{ message: "Loading" }}
            key={"Main Loading Dialog"}
            close={() => null}
          />
        </div>
      );
    } else {
      return null;
    }
  }
};

export default AppLoader;
