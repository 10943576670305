var Router = Backbone.Router.extend({
  routes: {
    "s/:schedule_id": "shadowScheduleDraft",
    "s/:schedule_id/:view_id": "shadowScheduleDraftWithView",
  },

  shadowScheduleDraft: function (id) {
    // create the refresh report and start shadowing schedule changes

    //if (!window.LbsAppData.AppContext.get('report_draft')) {
    // if there isn't a report_draft then we're coming in from the url trying to
    // shadow a schedule -- need to bridge the gaps

    // might have to clear dialogs
    //window.LbsAppData.AppContext.clearDialogs();

    /*
			if (window.LbsAppData.AppContext.get('report_saved')) {
				// looking at a saved report -- just fetch it
				window.LbsAppData.AppContext.get('report_saved').get('data').properties.data.mode = 'shadow';
				window.LbsAppData.AppContext.openLoadingDialog('Creating report context...', this, function() {
					// update the shadow-specific settings
					console.log(window.LbsAppData.AppContext.get('report_saved'));
				});


					/*
					window.LbsAppData.Helpers.Reports.defineDefaultShadowReport(id, undefined, function(newReport) {
						window.LbsAppData.AppContext.set({report_draft: newReport});

						window.LbsAppData.AppContext.openDialog({
							type: window.LbsAppData.User.get('is_admin') ? 'report-edit' : 'manage-reports',
							options: {
								forceSubmit: true,
							}
						});
					});
			} else {
			*/
    if (window.LbsAppData.AppContext.get("report_saved")) {
      window.LbsAppData.AppContext.openLoadingDialog("Creating report context...", this, function () {
        window.LbsAppData.Helpers.Reports.defineDefaultShadowReport(id, undefined, function (newReport) {
          window.LbsAppData.AppContext.set({
            report_draft: newReport,
          });

          window.LbsAppData.AppContext.openDialog({
            type: window.LbsAppData.User.get("is_admin") ? "report-edit" : "manage-reports",
            options: {
              forceSubmit: false,
              quickSubmit: true,
            },
            hidden: true,
          });
        });
      });
    } else {
      // unsaved report -- need to define it
      window.LbsAppData.AppContext.openLoadingDialog("Creating report context...", this, function () {
        window.LbsAppData.Helpers.Reports.defineDefaultShadowReport(id, undefined, function (newReport) {
          window.LbsAppData.AppContext.set({
            report_draft: newReport,
          });

          window.LbsAppData.AppContext.openDialog({
            type: window.LbsAppData.User.get("is_admin") ? "report-edit" : "manage-reports",
            options: {
              forceSubmit: true,
            },
          });
        });
      });
    }

    //var newReport = window.LbsAppData.Helpers.Reports.defineTallyReport(composition);

    //}

    // spawn the refresh report
    //window.LbsAppData.AppContext.spawnRefreshReport();

    //if (composition.properties.data.shadow) {
    //	window.LbsAppData.AppContext.spawnRefreshReport();
    //} else {
    //	// make sure we turn off the refresh monitor
    //}

    //window.LbsAppData.AppContext.openLoadingDialog('Opening view...', this, function() {
    //	// send a tracking event -- putting this here to avoid tracking first-view openings
    //	window.LbsAppData.Helpers.Analytics.sendEvent('Open: View', id);
    //
    //	// open the view
    //	window.LbsAppData.AppContext.openView(id);
    //});
  },

  shadowScheduleDraftWithView: function (sched_id, view_id) {
    window.LbsAppData.AppContext.openLoadingDialog("Creating report context...", this, function () {
      window.LbsAppData.Helpers.Reports.defineDefaultShadowReport(sched_id, view_id, function (newReport) {
        window.LbsAppData.AppContext.set({
          report_draft: newReport,
        });

        window.LbsAppData.AppContext.openDialog({
          type: window.LbsAppData.User.get("is_admin") ? "report-edit" : "manage-reports",
          options: {
            forceSubmit: true,
          },
        });
      });
    });
  },
});

module.exports = Router;
