import moment from "moment";

import useSettableConnectedProperty from "@/viewer/ui/modules/common/hooks/useSettableConnectedProperty";
import { momentToDate } from "@/viewer/ui/modules/common/helpers/dates";

import { UIContext as UIContextType } from "../types/context";

const useUIContext = (): [UIContextType, (newUIContext: Partial<UIContextType>) => void] => {
  const { LbsAppData } = window as any;
  const { AppContext, DateManager } = LbsAppData;

  return useSettableConnectedProperty<UIContextType>(() => ({
    listenerOpts: {
      dateMode: [DateManager, "mode"],
      displayDepartmentNames: [AppContext, "displayDepartmentNames"],
      isBulkMode: [AppContext, "mode", (mode) => mode === "bulk", (mode) => (mode ? "bulk" : "view")],
      leftColumnType: [AppContext, "GridSettings_leftColumnType"],
      viewMode: [AppContext, "mode"],
      showActivityFeed: [
        AppContext,
        "showActivityFeed",
        (showActivityFeed) => showActivityFeed ?? false,
        (showActivityFeed) => showActivityFeed,
      ],
      showTimes: [
        AppContext,
        "showTimes",
        (showTimes) => showTimes === "yes",
        (showTimes) => (showTimes ? "yes" : "no"),
      ],
      showIcons: [AppContext, "showIcons", (showIcons) => showIcons ?? true, (showIcons) => showIcons],
      lv10335FlagOn: [
        AppContext,
        "lv10335FlagOn",
        (lv10335FlagOn) => lv10335FlagOn ?? false,
        (lv10335FlagOn) => lv10335FlagOn,
      ],
      showOriginalPersonnel: [
        AppContext,
        "showOriginalPersonnel",
        (showOriginalPersonnel) => showOriginalPersonnel ?? false,
        (showOriginalPersonnel) => showOriginalPersonnel,
      ],
      showOnlyPending: [
        AppContext,
        "showOnlyPending",
        (showOnlyPending) => showOnlyPending ?? false,
        (showOnlyPending) => showOnlyPending,
      ],
      startWeekOnDay: [AppContext, "startOnDay"],
      startDate: [
        DateManager,
        "schedule_start_date",
        (startDate) => (startDate ? momentToDate(startDate) : new Date()),
        (startDate) => moment(startDate),
      ],
      stopDate: [
        DateManager,
        "schedule_stop_date",
        (stopDate) => (stopDate ? momentToDate(stopDate) : new Date()),
        (stopDate) => moment(stopDate),
      ],
      startDateWithCushion: [
        DateManager,
        "start_date",
        (startDateWithCushion) => (startDateWithCushion ? momentToDate(startDateWithCushion) : new Date()),
        (startDateWithCushion) => moment(startDateWithCushion),
      ],
      stopDateWithCushion: [
        DateManager,
        "stop_date",
        (stopDateWithCushion) => (stopDateWithCushion ? momentToDate(stopDateWithCushion) : new Date()),
        (stopDateWithCushion) => moment(stopDateWithCushion),
      ],
      currentFragment: [DateManager, "currentFragment"],
    },
  }));
};

export default useUIContext;
