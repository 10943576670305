"use strict";

// styles
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");
var _ = require("underscore");
// components
var SubMenuItem = require("./SubMenuItem.jsx");
React.createClass = require("create-react-class");

var SubMenu = React.createClass({
  /* privates */
  _registerBackboneEvents: function () {},
  _unregisterBackboneEvents: function () {},

  _close: function () {
    this.props.closeMenu();
  },

  /* publics */
  getLogoMenuItems: function () {
    var menuItems = [];

    _.each(this.props.items, function (i, idx) {
      menuItems.push(<SubMenuItem key={idx} item={i} />);
    });

    // everyone gets the dashboard
    //menuItems.push(<SubMenuItem key='dashboard' type='dashboard' />);

    /*
		var accessibleApps = window.LbsAppData.AccessibleApps.attributes;
		if (accessibleApps.viewer) {
			menuItems.push(<LogoMenuItem key='viewer' type='viewer' />);
		}
		if (accessibleApps.maker) {
			menuItems.push(<LogoMenuItem key='editor' type='editor' />);
		}
		if (accessibleApps.view_design) {
			menuItems.push(<LogoMenuItem key='view_design' type='view_design' />);
		}
		if (accessibleApps.reports) {
			menuItems.push(<LogoMenuItem key='reports' type='reports' />);
		}
		if (accessibleApps.monitor) {
			menuItems.push(<LogoMenuItem key='monitor' type='monitor' />);
		}
		*/

    return menuItems;
  },

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {
    this._registerBackboneEvents();
  },
  componentWillUnmount: function () {
    this._unregisterBackboneEvents();
  },
  componentWillUpdate: function (nextProps, nextState) {},
  componentDidUpdate: function () {},

  render: function () {
    /* jshint ignore:start */

    var menuItems = this.getLogoMenuItems();

    var classes = React.addons.classSet({
      SubMenu: true,
      active: this.props.active,
    });

    return <div className={classes}>{menuItems}</div>;

    /* jshint ignore:end */
  },
});

module.exports = SubMenu;
