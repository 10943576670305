const Backbone = require("backbone");
const ApiConfig = require("_lib/data/ApiConfig.js");

const Change = Backbone.Model.extend({
  idAttribute: "c_id",

  defaults: {
    c_id: undefined,
    old_slot: undefined,
    new_slot: undefined,
    timestamp: undefined,
    change_type: undefined,
    user_empid: undefined,
    sched_id: undefined,
    user_display_name: undefined,
  },

  url: function () {
    return (
      ApiConfig.getPrefix() +
      (this.attributes.c_id
        ? "/schedule/rollback/" + this.attributes.sched_id + "/" + this.attributes.c_id
        : "/schedule/rollback/" + this.attributes.sched_id)
    );
  },

  printAttr: function (attribute) {
    switch (attribute) {
      case "old_slot":
        return this.attributes.old_slot ? this.attributes.old_slot.slot_id : "";
      case "new_slot":
        return this.attributes.new_slot ? this.attributes.new_slot.slot_id : "";
      default:
        return this.attributes[attribute];
    }
  },

  getChange: function () {
    const oldSlot = this.attributes.old_slot;
    const newSlot = this.attributes.new_slot;

    switch (this.attributes.change_type) {
      case "replace_ass": {
        if (newSlot && oldSlot) {
          return (
            "Assignment '" +
            this.attributes.old_slot.assign_display_name +
            "' replaced with '" +
            this.attributes.new_slot.assign_display_name +
            "' for personnel " +
            this.attributes.new_slot.display_name +
            " on " +
            this.attributes.new_slot.slot_date
          );
        }

        return "Unknown Assignment change.";
      }

      case "replace_pers": {
        if (newSlot && oldSlot) {
          return (
            "Personnel " +
            this.attributes.old_slot.display_name +
            " replaced with " +
            this.attributes.new_slot.display_name +
            " for assignment '" +
            this.attributes.new_slot.assign_display_name +
            "' on " +
            this.attributes.new_slot.slot_date
          );
        }

        return "Unknown Personnel change.";
      }

      case "clear": {
        if (oldSlot) {
          return (
            "Personnel " +
            this.attributes.old_slot.display_name +
            " removed from assignment '" +
            this.attributes.old_slot.assign_display_name +
            "' on " +
            this.attributes.old_slot.slot_date
          );
        }

        return "Unknown delete.";
      }

      case "fill": {
        if (newSlot) {
          return (
            "Personnel " +
            this.attributes.new_slot.display_name +
            " added to assignment '" +
            this.attributes.new_slot.assign_display_name +
            "' on " +
            this.attributes.new_slot.slot_date
          );
        }

        return "Unknown fill.";
      }

      case "details": {
        if (newSlot) {
          return (
            "Details have been changed for " +
            this.attributes.new_slot.display_name +
            " on assignment '" +
            this.attributes.new_slot.assign_display_name +
            "' on " +
            this.attributes.new_slot.slot_date
          );
        }

        return "Unknown details change.";
      }

      default: {
        return "Unknown change.";
      }
    }
  },
});

module.exports = Change;
