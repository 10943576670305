// Helper to describe node models

var moment = require("moment");
var _ = require("underscore");
var $ = require("jquery");

var CacheHelper = Backbone.Model.extend({
  defaults: {
    fetchList: {},
  },

  initialize: function () {},

  getCollection: function (id, newCollection, onSuccess, onFail) {
    if (this.check(id)) {
      return this.get(id);
    } else {
      var that = this;
      // reserve the id
      newCollection.attributes.isFetching = true;
      that.attributes[id] = newCollection;
      // fetch the collection then save it in the id
      newCollection.fetch({
        success: function (collection, models) {
          // set the isFetching flag to false
          //that.attributes[id] = collection;
          that.attributes[id].attributes.isFetching = false;
          // trigger event
          collection.trigger("fetched");
          //onSuccess(collection, models);
        },
        error: function () {
          that.fail.call(that, id, onFail);
        },
      });

      // return the new collection
      return newCollection;
    }
  },

  store: function (id, object) {
    // store the object at the id -- so long as it isn't still 'fetching'... otherwise
    this.attributes[id] = object;
  },

  retrieve: function (id) {
    return this.get(id);
  },

  fetch: function (collection, onSuccess, onFail, invalidate) {
    var that = this;

    var collectionUrl = _.isFunction(collection.url) ? collection.url() : collection.url;

    // check the fetchList keyed by the url, unless we're invalidating -- then just overwrite it anyways
    if (!_.has(this.attributes.fetchList, collectionUrl) || invalidate) {
      // tag the collection with an 'isFetching' attribute
      collection.attributes.isFetching = true;
      // store the collection url in the fetchList
      this.attributes.fetchList[collectionUrl] = collection;
      // fetch shit
      collection.fetch({
        success: function () {
          // update the isFetching attribute
          collection.attributes.isFetching = false;
          // trigger the fetched:success event
          collection.trigger("fetched:success");
          // callback
          onSuccess(collection);
        },
        error: function () {
          // remove the item from the fetchList
          delete that.attributes.fetchList[collectionUrl];
          // trigger the fetched:error event
          collection.trigger("fetched:error");
          // call the onFail
          onFail(collection);
        },
      });
    } else {
      var savedCollection = that.attributes.fetchList[collectionUrl];

      if (savedCollection.attributes.isFetching) {
        // -- subscribe to the saved collection
        //console.log('subscribing to ' + collectionUrl)

        // tag the collection with an 'isFetching' attribute
        collection.attributes.isFetching = true;
        // subscribe to the fetched event on the collection stored in the fetchList
        collection.listenTo(this.attributes.fetchList[collectionUrl], "fetched:success", function () {
          // stop listening to the fetched events -- this should turn 'collection' into fodder
          // for the garbage collector.. we don't need him anymore
          collection.stopListening(that.attributes.fetchList[collectionUrl]);
          collection.attributes.isFetching = false;

          //var models = _.map(savedCollection.models, function(i) { return i.attributes; });
          //collection.reset(models, { silent: true, parse: true });

          // reset the collection with the savedCollection models
          collection.reset(savedCollection.models, {
            silent: true,
          });
          // call the success function
          onSuccess(collection);
        });

        // subscribe to the fetched event on the collection stored in the fetchList
        collection.listenTo(this.attributes.fetchList[collectionUrl], "fetched:error", function () {
          collection.stopListening(); // kill it
          // call the fail function
          onFail(collection);
        });
      } else {
        // -- already fetched and we didn't invalidate, so copy his models over and send him back
        collection.reset(savedCollection.models, { silent: true });
        onSuccess(collection);
      }
    }
  },

  check: function (id) {
    return _.has(this.attributes, id);
  },

  fail: function (id, onFail) {
    // reset the saved id
    delete this.attributes[id];
    // call the callback
    onFail();
  },
});

module.exports = CacheHelper;
