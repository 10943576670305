"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");
var _ = require("underscore");
var Promise = require("bluebird");
// collections
var FeedItemCollection = require("login/data/collections/FeedItemCollection.js");
// components
var FeedItem = require("./FeedItem.jsx");

var Feed = React.createClass({
  /* lifecycle methods */
  getInitialState: function () {
    var mode, feed;
    if (window.LbsAppData.MyPersonnel.get("scheduled")) {
      mode = "me";
      feed = new FeedItemCollection([], {
        emp_id: window.LbsAppData.User.get("emp_id"),
      });
    } else {
      mode = "all";
      feed = new FeedItemCollection([], {
        departments: window.LbsAppData.MyPersonnel.get("departments"),
      });
    }

    return {
      isLoading: true,
      mode: mode,
      feed: feed,
      feedIntervalId: undefined,
    };
  },
  componentDidMount: function () {
    if (window.LbsAppData.AppContext.session.get("auth")) {
      var that = this;
      window.setTimeout(function () {
        // wait a tiny bit to let the animation go through on the first open
        that._fetchFeed();
      }, 200);

      // set a timer to refetch the feed
      this.state.feedIntervalId = window.setInterval(function () {
        if (that.isMounted()) {
          that._fetchFeed();
        } else {
          // remove it
          window.clearInterval(that.state.feedIntervalId);
        }
      }, 10000);
    } else {
      // wait for it once
      window.LbsAppData.AppContext.once(
        "authorized",
        function () {
          this._fetchFeed();

          var that = this;
          // set a timer to refetch the feed
          this.state.feedIntervalId = window.setInterval(function () {
            if (that.isMounted()) {
              that._fetchFeed();
            } else {
              // remove it
              window.clearInterval(that.state.feedIntervalId);
            }
          }, 10000);
        },
        this
      );
    }
  },
  componentWillUnmount: function () {},

  /* privates */
  _fetchFeed: function (doRemove) {
    if (window.LbsAppData.AppContext.session.get("auth")) {
      // make sure we're authorized by now
      var that = this;
      this.state.feed.fetch({
        success: function (model, response, options) {
          that.setState({
            isLoading: false,
          });
        },
        remove: doRemove ? true : false,
      });
    }
  },
  _selectMode: function (mode) {
    // don't update if this is the same
    if (this.state.mode != mode) {
      this.state.feed.clearStamp();

      switch (mode) {
        case "me":
          this.state.feed.attributes.emp_id = window.LbsAppData.User.get("emp_id");
          break;
        case "all":
          this.state.feed.attributes.emp_id = undefined;
          this.state.feed.attributes.departments = window.LbsAppData.MyPersonnel.get("departments");
          break;
        default:
          break;
      }

      this._fetchFeed(true);

      this.setState({
        isLoading: true,
        mode: mode,
      });
    }
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var feedList;
    if (this.state.isLoading) {
      feedList = (
        <div className="loader">
          <i className="fa fa-spinner fa-spin" />
        </div>
      );
    } else {
      var upcomingItems = this.state.feed.filter(function (item) {
        return !moment(item.attributes.message_args.d, "YYYYMMDD").isBefore(moment(), "day");
      });
      var feed = _.groupBy(upcomingItems, function (item) {
        return moment(item.attributes.timestamp).format("YYYY-MM-DD");
      });

      var feedDateContainers = [];
      for (var key in feed) {
        var feedItems = feed[key];

        var feedItemDivs = [];
        for (var i = 0; i < feedItems.length; i++) {
          var feedItem = feedItems[i];

          var icon = feedItem.getIcon();
          var pendingIcon = undefined;
          if (feedItem._isPending()) {
            pendingIcon = (
              <span className="fa-stack fa-lg pending">
                <i className="fa fa-fw fa-stack-2x fa-2x fa-square" />
                <i className="fa fa-fw fa-stack-1x fa-question" />
              </span>
            );
          }

          feedItemDivs.push(
            <div className="feed-item" key={i}>
              <div className="feed-item-header">
                {pendingIcon}
                <span className="fa-stack fa-lg">{icon}</span>
                <div className="feed-item-header-text">
                  <div className="time">{feedItem.getTime()}</div>
                  <div className="action-text">{feedItem.getActionText()}</div>
                </div>
              </div>

              <div className="message">
                <span
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: feedItem.getMessage(),
                  }}
                />
              </div>
            </div>
          );
        }

        feedDateContainers.push(
          <div className="feed-items-container" key={key}>
            <div className="date">{moment(feedItem.get("timestamp")).format("ddd, LL")}</div>
            <div className="feed-items">{feedItemDivs}</div>
          </div>
        );
      }

      if (feedDateContainers.length == 0) {
        feedDateContainers.push(
          <div className="empty" key="empty">
            No activity
          </div>
        );
      }

      feedList = feedDateContainers;
    }

    var options = [];
    if (window.LbsAppData.MyPersonnel.get("scheduled")) {
      options.push(
        <div
          key="me"
          className={this.state.mode == "me" ? "option active" : "option"}
          onClick={this._selectMode.bind(this, "me")}
        >
          <i className="fa fa-user" />
          Me
        </div>
      );
    }
    if (window.LbsAppData.User.get("is_admin")) {
      options.push(
        <div
          key="department"
          className={this.state.mode == "all" ? "option active" : "option"}
          onClick={this._selectMode.bind(this, "all")}
        >
          <i className="fa fa-group" />
          My Departments
        </div>
      );
    }

    var classes = React.addons.classSet({
      Feed: true,
    });

    return (
      <div className={classes}>
        <div className="options">{options}</div>
        <div className="feed-list">{feedList}</div>
      </div>
    );

    /* jshint ignore:end */
  },
});

module.exports = Feed;
