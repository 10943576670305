const Backbone = require("backbone");
var _ = require("underscore");
var ApiConfig = require("_lib/data/ApiConfig.js");

var NakedView = Backbone.Model.extend({
  idAttribute: "view_id",
  urlRoot: ApiConfig.getPrefix() + "/views",

  parse: function (response, options) {
    return response;
  },

  setContext: function () {
    var context = this._createContextElements();
    this.attributes.data.context = context;
  },

  setFilters: function () {
    var filters = this._createFilterElements();
    this.attributes.data.filters = filters;
  },

  toJSON: function (options) {
    var attributes = _.clone(this.attributes);
    var context = this._createContextElements();
    var filters = this._createFilterElements();
    attributes.data = JSON.stringify({
      context: context,
      filters: filters,
    });

    return attributes;
  },

  savePartial: function (attrs, options) {
    options || (options = {});

    options.contentType = "application/json";
    options.data = JSON.stringify(attrs);

    Backbone.Model.prototype.save.call(this, attrs, options);
  },

  _createContextElements: function () {
    // round up pertinent contextual data
    var attr = window.LbsAppData.AppContext.attributes;
    return {
      layout: attr.layout,
      GlobalSettings_compactMode: attr.GlobalSettings_compactMode,
      GlobalSettings_autoAddNewPersonnel: attr.GlobalSettings_autoAddNewPersonnel,
      GlobalSettings_autoAddNewAssign: attr.GlobalSettings_autoAddNewAssign,
      CalendarSettings_startOfWeekIsSunday: attr.CalendarSettings_startOfWeekIsSunday,
      CalendarSettings_showWeekends: attr.CalendarSettings_showWeekends,
      CalendarSettings_highDateBoxes: attr.CalendarSettings_highDateBoxes,
      GridSettings_leftColumnType: attr.GridSettings_leftColumnType,
      slotOrdering: attr.slotOrdering,
    };
  },
  _createFilterElements: function () {
    // round up filter information
    var appData = window.LbsAppData;
    var onDepartmentArray = appData.Departments
      ? _.map(
          appData.Departments.where({
            available: true,
            filtered: false,
          }),
          function (el) {
            return el.id;
          }
        )
      : [];
    var onTemplateArray = appData.Departments
      ? _.map(appData.Templates.where({ available: true, filtered: false }), function (el) {
          return el.id;
        })
      : [];

    // we keep track of offAssignments for cases where new assignments are added to the db...and being to react to that
    var onAssignmentArray = [];
    var offAssignmentArray = [];
    for (var i = 0; i < appData.Assignments.length; i++) {
      var assign = appData.Assignments.at(i);
      if (assign.attributes.available === true && assign.attributes.filtered === false) {
        onAssignmentArray.push(assign.id);
      } else {
        for (var j = 0; j < onTemplateArray.length; j++) {
          if (assign.attributes.templates.indexOf(onTemplateArray[j]) > -1) {
            offAssignmentArray.push(assign.id);
            break;
          }
        }
      }
    }

    // offPersonnel...same reason as assignments
    var onPersonnelArray = [];
    var offPersonnelArray = [];
    for (var i = 0; i < appData.Personnel.length; i++) {
      var personnel = appData.Personnel.at(i);
      if (personnel.attributes.available === true && personnel.attributes.filtered === false) {
        onPersonnelArray.push(personnel.id);
      } else if (personnel.attributes.scheduled === true) {
        for (var j = 0; j < onDepartmentArray.length; j++) {
          if (personnel.attributes.departments.indexOf(onDepartmentArray[j]) > -1) {
            offPersonnelArray.push(personnel.id);
            break;
          }
        }
      }
    }

    return {
      onDepartments: onDepartmentArray,
      onTemplates: onTemplateArray,
      onAssignments: onAssignmentArray,
      onPersonnel: onPersonnelArray,
      offAssignments: offAssignmentArray,
      offPersonnel: offPersonnelArray,
    };
  },
});

module.exports = NakedView;
