import React from "react";
import moment from "moment";
import { Button } from "@material-ui/core";
import { FieldProps, getIn } from "formik";
import { DatePicker, DatePickerProps } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ToolbarComponentProps } from "@material-ui/pickers/Picker/Picker";
import _ from "underscore";

import classnames from "@/_lib/utils/classnames";

import { BlockDay } from "./types";
import Label from "../meta/Label";
import ErrorMessage from "../meta/ErrorMessage";
import styles from "./styles.module.scss";

type Variant = "dialog" | "inline" | "static";
type Orientation = "landscape" | "portrait";
type View = "date" | "month" | "year";

interface ShiftData {
  firstPublishedDate: moment.Moment;
  lastPublishedDate: moment.Moment;
  requestWindowStart: moment.Moment;
  requestWindowEnd: moment.Moment;
}

interface BlockData {
  dayRange: BlockDay[];
  startWeekDay: BlockDay;
  duration: number;
}

interface Props extends Partial<DatePickerProps>, FieldProps<null | moment.Moment | moment.Moment[]> {
  variant?: Variant;
  orientation?: Orientation;
  openTo?: View;
  views?: View[];
  label?: string;
  placeholder?: string;
  initialFocusedDate?: moment.Moment;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  disableFuture?: boolean;
  disableToolbar?: boolean;
  disablePast?: boolean;
  disabled?: boolean;
  onClose?: () => void;
  valueType?: DateValueType;
  shiftData?: null | ShiftData;
  blockData?: null | BlockData;
  limit?: number; //Number of dates that can be selected
}

export enum DateValueType {
  Single,
  Multi,
  Block,
}

const fitsInBounds = (date: moment.Moment, minDate: moment.Moment, maxDate: moment.Moment) => {
  return (
    (date.isAfter(minDate) || date.isSame(minDate, "day")) && (date.isBefore(maxDate) || date.isSame(maxDate, "day"))
  );
};

const ToolbarComponent = ({ date, setOpenView, openView, isLandscape }: ToolbarComponentProps) => {
  const invisibleMonthButtonClassNames = classnames({
    [styles.invisibleMonthButton]: true,
    [styles.portrait]: !isLandscape,
    [styles.landscape]: isLandscape,
  });

  return (
    <div className={styles.toolbar}>
      <Button className={styles.toolbarButton} size="small" variant="text" onClick={() => setOpenView("year")}>
        {(date as moment.Moment).format("YYYY")}
      </Button>
      {openView === "date" && (
        <Button className={invisibleMonthButtonClassNames} onClick={() => setOpenView("month")} />
      )}
    </div>
  );
};

const RenderDateField = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      field: { name, value },
      form: { errors, touched, setFieldValue, setFieldTouched },
      variant = "dialog",
      orientation = "landscape",
      openTo = "date",
      views = ["date"],
      label = "",
      placeholder = "",
      minDate: initialMinDate = moment([1901, 0, 1]),
      maxDate: initialMaxDate = moment([3000, 11, 31]),
      initialFocusedDate = moment(),
      disabled = false,
      onClose = () => null,
      valueType = DateValueType.Single,
      shiftData = null,
      blockData = null,
      limit = null,
      ...rest
    },
    ref
  ) => {
    const [isShiftKeyPressed, setShiftKeyPressed] = React.useState(false);
    const [{ minDate, maxDate }, setSelectableDateRange] = React.useState({
      minDate: initialMinDate,
      maxDate: initialMaxDate,
    });

    React.useEffect(() => {
      setSelectableDateRange({
        minDate: initialMinDate,
        maxDate: initialMaxDate,
      });
    }, [
      (initialMinDate as moment.Moment).date(),
      (initialMinDate as moment.Moment).month(),
      (initialMinDate as moment.Moment).year(),
      (initialMaxDate as moment.Moment).date(),
      (initialMaxDate as moment.Moment).month(),
      (initialMaxDate as moment.Moment).year(),
    ]);

    const error = getIn(errors, name);
    const touch = getIn(touched, name);

    const singleDateValue = value as null | moment.Moment;
    const multiOrBlockValue = value as moment.Moment[];

    const rootClassNames = classnames({
      [styles.root]: true,
      [styles.disabled]: disabled,
    });

    const datePickerClassNames = classnames({
      [styles.datePicker]: true,
      [styles.error]: touch && !!error,
    });

    const portalClassNames = classnames({
      [styles.isMultiOrBlock]: valueType === DateValueType.Multi || valueType === DateValueType.Block,
    });

    const isSelectedViewHighestPriority = (view: View) => {
      let highestPriorityView: View;

      if (views?.includes("date")) {
        highestPriorityView = "date";
      } else if (views?.includes("month") && !views?.includes("date")) {
        highestPriorityView = "month";
      } else {
        highestPriorityView = "year";
      }

      return highestPriorityView === view;
    };

    const isDateDisabled = (date: moment.Moment) => {
      if (minDate?.isSame(maxDate, "day")) return true;
      if (shiftData && date.isBetween(shiftData.lastPublishedDate, shiftData.requestWindowStart, "day")) return true;

      const isInBounds = fitsInBounds(date, minDate, maxDate);

      switch (valueType) {
        case DateValueType.Single: {
          return !isInBounds;
        }
        case DateValueType.Multi: {
          return !isInBounds;
        }
        case DateValueType.Block: {
          const currentDayOfWeek = date.day() as BlockDay;
          const weekDayDifference = blockData?.dayRange.indexOf(currentDayOfWeek);
          const currentWeekBlockStart = date
            .clone()
            .subtract(blockData?.startWeekDay === -1 ? 0 : weekDayDifference, "days");

          const isInBlockRange =
            blockData?.startWeekDay === -1 ? true : blockData?.dayRange.includes(date.day() as BlockDay);

          const isInBlockBounds = _.range(0, blockData?.duration).every((i) => {
            const date = currentWeekBlockStart.clone().add(i, "days");
            return fitsInBounds(date, minDate, maxDate);
          });

          return !(isInBounds && isInBlockBounds && isInBlockRange);
        }
      }
    };

    const isDateSelected = (date: moment.Moment) => {
      switch (valueType) {
        case DateValueType.Single:
          return singleDateValue && singleDateValue.isSame(date, "day");
        case DateValueType.Multi:
        case DateValueType.Block:
          return multiOrBlockValue.some((d) => d.isSame(date, "day"));
      }
    };

    const getSelectedDates = (dates: moment.Moment[]) => {
      //Select only the number of dates that are defined by the limit
      return limit ? dates.slice(0, limit) : dates;
    };

    const handleDateChange = (date: moment.Moment) => {
      if (isSelectedViewHighestPriority("date")) {
        let newValue: moment.Moment | moment.Moment[] = [];

        switch (valueType) {
          /**
           * Most simple case. Just select a single date.
           */
          case DateValueType.Single: {
            newValue = date;
            setFieldValue(name, newValue);
            break;
          }

          /**
           * Multi selection can have two modes: regular and shift key selection.
           * If the shift key is held down, all dates from the last selected date
           * to the clicked date will be selected. If no shift key is held, either
           * append or remove the date based on if it is already selected or not.
           */
          case DateValueType.Multi: {
            if (isShiftKeyPressed) {
              const startingDate = multiOrBlockValue[multiOrBlockValue.length - 1];
              const startingDateToSelectedDateDifference = date.diff(startingDate, "days");
              const isDifferenceNegative = startingDateToSelectedDateDifference < 0;

              newValue = _.range(0, Math.abs(startingDateToSelectedDateDifference) + 1).reduce((acc, i) => {
                const date = isDifferenceNegative
                  ? startingDate.clone().subtract(i, "days")
                  : startingDate.clone().add(i, "days");
                const isSelected = multiOrBlockValue.some((d) => d.isSame(date, "day"));
                const isDisabled = isDateDisabled(date);

                return isSelected || isDisabled ? acc : [...acc, date];
              }, multiOrBlockValue);

              setFieldValue(name, getSelectedDates(newValue));
            } else {
              const filteredValue = multiOrBlockValue.filter((d) => !d.isSame(date, "day"));
              newValue =
                filteredValue.length !== multiOrBlockValue.length ? filteredValue : [...multiOrBlockValue, date];
              setFieldValue(name, getSelectedDates(newValue));
            }
            break;
          }

          /**
           * Block selection makes use of the `blockData` prop to determine which
           * dates should be selected/deselected based on the parameters. In a nutshell,
           * this finds the starting day of the block based on the selected day and uses
           * that to append or remove the entire block based on if it is already selected
           * or not.
           */
          case DateValueType.Block: {
            const isSelected = multiOrBlockValue.some((d) => d.isSame(date, "day"));
            const selectedDayOfWeek = date.day() as BlockDay;
            const weekDayDifference = blockData?.dayRange.indexOf(selectedDayOfWeek);
            const selectedWeekBlockStart = date
              .clone()
              .subtract(blockData?.startWeekDay === -1 ? 0 : weekDayDifference, "days");

            newValue = _.range(0, blockData?.duration).reduce(
              (acc, i) => {
                const date = selectedWeekBlockStart.clone().add(i, "days");
                return isSelected ? acc.filter((d) => !d.isSame(date, "day")) : [...acc, date];
              },
              [...(blockData?.startWeekDay !== -1 ? multiOrBlockValue : [])]
            );

            setFieldValue(name, newValue);
            break;
          }
        }

        /**
         * If `shiftData` is passed, we want to disable either all published or all requestable
         * dates based on what was just selected. For example, if the user has Schedule and Request
         * permissions and selects a published date, we want to disable all requestable dates by setting
         * a new `maxDate` for the calendar.
         */
        if (shiftData) {
          if (Array.isArray(newValue) && newValue.length === 0) {
            setSelectableDateRange({ minDate: initialMinDate, maxDate: initialMaxDate });
          } else {
            const isSelectedDateRequestable = fitsInBounds(date, shiftData.requestWindowStart, maxDate);
            setSelectableDateRange(
              isSelectedDateRequestable
                ? { minDate: shiftData.requestWindowStart, maxDate }
                : { minDate, maxDate: shiftData.lastPublishedDate }
            );
          }
        }
      }
    };

    const handleMonthChange = (date: moment.Moment) => {
      if (isSelectedViewHighestPriority("month")) {
        setFieldValue(name, date);
      }
    };

    const handleYearChange = (date: moment.Moment) => {
      if (isSelectedViewHighestPriority("year")) {
        setFieldValue(name, date);
      }
    };

    const handleBlur = () => {
      setFieldTouched(name, true);
    };

    const handleClear = () => {
      setFieldValue(name, valueType === DateValueType.Single ? null : []);
      setSelectableDateRange({ minDate: initialMinDate, maxDate: initialMaxDate });
    };

    const renderDay = (
      day: MaterialUiPickersDate,
      selectedDate: MaterialUiPickersDate,
      dayInCurrentMonth: boolean,
      dayComponent: React.ReactElement
    ) => {
      return React.cloneElement(dayComponent, {
        onClick: () => handleDateChange(day as moment.Moment),
        selected: isDateSelected(day as moment.Moment),
        disabled: isDateDisabled(day as moment.Moment),
      });
    };

    const renderLabel = () => {
      const format = "ddd, MMM DD, YYYY";
      switch (valueType) {
        case DateValueType.Single:
          if (singleDateValue) return moment(singleDateValue).format(format);
          return "";
        case DateValueType.Multi:
          if (multiOrBlockValue.length === 1) return moment(multiOrBlockValue[0]).format(format);
          if (multiOrBlockValue.length > 1) return `${multiOrBlockValue.length} dates selected`;
          return "";
        case DateValueType.Block:
          if (multiOrBlockValue.length > 0)
            return `${multiOrBlockValue.length / (blockData ? blockData.duration : 0)} blocks selected`;
          return "";
      }
    };

    React.useEffect(() => {
      const shiftDown = (e: KeyboardEvent) => e.key === "Shift" && setShiftKeyPressed(true);
      const shiftUp = (e: KeyboardEvent) => e.key === "Shift" && setShiftKeyPressed(false);

      document.addEventListener("keydown", shiftDown);
      document.addEventListener("keyup", shiftUp);

      return () => {
        document.removeEventListener("keydown", shiftDown);
        document.removeEventListener("keyup", shiftUp);
      };
    }, []);

    const baseProps: DatePickerProps = {
      innerRef: ref,
      className: datePickerClassNames,
      ToolbarComponent,
      onChange: (date) => !date && handleClear(),
      onMonthChange: (date) => handleMonthChange(date as moment.Moment),
      onYearChange: (date) => handleYearChange(date as moment.Moment),
      renderDay,
      labelFunc: renderLabel,
      placeholder,
      initialFocusedDate,
      minDate,
      maxDate,
      variant,
      orientation,
      openTo,
      views,
      disabled,
      inputVariant: "outlined",
      invalidDateMessage: null,
      maxDateMessage: null,
      minDateMessage: null,
      autoOk: valueType === DateValueType.Single,
      value: valueType === DateValueType.Single ? singleDateValue : multiOrBlockValue[multiOrBlockValue.length - 1],
      ...rest,
    };

    const dialogProps: Partial<DatePickerProps> = {
      clearable: true,
      clearLabel: "Reset",
      cancelLabel: "Close",
      DialogProps: {
        className: portalClassNames,
        onBlur: handleBlur,
        onClose,
      },
    };

    const inlineProps: Partial<DatePickerProps> = {
      PopoverProps: {
        className: portalClassNames,
        onBlur: handleBlur,
        onClose,
      },
    };

    const staticProps: Partial<DatePickerProps> = {
      onBlur: handleBlur,
    };

    return (
      <div className={rootClassNames}>
        {label && <Label text={label} />}
        <DatePicker
          {...(variant === "dialog" ? dialogProps : {})}
          {...(variant === "inline" ? inlineProps : {})}
          {...(variant === "static" ? staticProps : {})}
          {...baseProps}
        />
        {!_.isEmpty(value) && variant !== "static" && (
          <div className={styles.clear} onClick={handleClear}>
            <i className="fas fa-times-circle" />
          </div>
        )}
        {touch && !!error && !disabled && <ErrorMessage text={error} />}
      </div>
    );
  }
);

export default RenderDateField;
