"use strict";

const React = require("react/addons");
const TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

const TimePickerAMPMPicker = React.createClass({
  mixins: [TouchMixin],

  getInitialState: function () {
    return {
      value: this.props.defaultVal,
      focused: false,
      spinnerValue: undefined,
    };
  },

  getValue: function () {
    return this.state.value;
  },

  /* privates */
  _handleChange: function (e) {
    let newValue;
    if (this.state.value === "AM") {
      newValue = "PM";
    } else {
      newValue = "AM";
    }
    this.setState({ value: newValue });

    let callbackFunc = this.props.onChange;
    if (typeof callbackFunc === "function") {
      callbackFunc({ newAmPm: newValue });
    }
  },

  /* lifecycle methods */
  componentWillReceiveProps: function (nextProps) {
    if (nextProps.isFocused && !this.props.isFocused) {
      this.setState({
        focused: true,
        spinnerValue: nextProps.spinnerCounter,
      });
    } else if (!nextProps.isFocused && this.props.isFocused) {
      this.setState({ focused: false, spinnerValue: undefined });
    } else if (this.state.focused) {
      // based on the spinner val...move up/down in response to a keyboard event
      if (this.state.spinnerValue < nextProps.spinnerCounter) {
        // up
        this._handleChange();
      } else if (this.state.spinnerValue > nextProps.spinnerCounter) {
        // down
        this._handleChange();
      }
      this.setState({ spinnerValue: nextProps.spinnerCounter });
    }

    if (nextProps.defaultVal !== this.props.defaultVal) {
      this.setState({ value: nextProps.defaultVal });
    }
  },

  render: function () {
    const classes = React.addons.classSet({
      AMPMPicker: true,
      focused: this.state.focused,
    });

    return (
      <div className={classes}>
        <i
          className="fa fa-angle-up"
          onClick={this.touchProxy.bind(this, this._handleChange, [])}
          onTouchEnd={this.touchProxy.bind(this, this._handleChange, [])}
        />
        <div className="picker-text-input-container">
          <div className={"picker-text-input"}>{this.state.value}</div>
        </div>
        <i
          className="fa fa-angle-down"
          onClick={this.touchProxy.bind(this, this._handleChange, [])}
          onTouchEnd={this.touchProxy.bind(this, this._handleChange, [])}
        />
      </div>
    );
  },
});

module.exports = TimePickerAMPMPicker;
