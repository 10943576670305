import React from "react";
import { Box, Typography } from "@material-ui/core";
import { SlotTime } from "@/viewer/types/domain/slot";
import { produce } from "immer";

interface CustomTimePickerProps {
  slotStartTime: SlotTime;
  slotStopTime: SlotTime;
  handleStartTimeChange: (slotStartTime: SlotTime) => void;
  handleStopTimeChange: (slotStopTime: SlotTime) => void;
}

const SLOT_MAX_HOURS = 23;
const SLOT_MAX_MINUTES = 55;
const MINUTES_STEP = 5;
const HOURS_STEP = 1;
const SLOT_MIN_HOURS = 0;
const SLOT_MIN_MINUTES = 0;

const CustomTimePicker = (props: CustomTimePickerProps): JSX.Element => {
  const { slotStartTime, slotStopTime, handleStartTimeChange, handleStopTimeChange } = props;

  const getBoundedSlotHoursIncrease = (hours: number): number => {
    return hours + HOURS_STEP > SLOT_MAX_HOURS ? SLOT_MIN_HOURS : hours + HOURS_STEP;
  };

  const getBoundedSlotMinutesIncrease = (minutes: number): number => {
    return minutes + MINUTES_STEP > SLOT_MAX_MINUTES ? SLOT_MIN_MINUTES : minutes + MINUTES_STEP;
  };

  const getBoundedSlotHoursDecrease = (hours: number): number => {
    return hours - HOURS_STEP < SLOT_MIN_HOURS ? SLOT_MAX_HOURS : hours - HOURS_STEP;
  };

  const getBoundedSlotMinutesDecrease = (minutes: number): number => {
    return minutes - MINUTES_STEP < SLOT_MIN_MINUTES ? SLOT_MAX_MINUTES : minutes - MINUTES_STEP;
  };

  const handleIncreaseSlotStartHours = () => {
    const currentSlotStartTime = produce(slotStartTime, (draft) => draft);
    const hours = getBoundedSlotHoursIncrease(currentSlotStartTime.hours);

    handleStartTimeChange(
      produce(currentSlotStartTime, (draft) => {
        draft.hours = hours;
      })
    );
  };

  const handleDecreaseSlotStartHours = () => {
    const currentSlotStartTime = produce(slotStartTime, (draft) => draft);
    const hours = getBoundedSlotHoursDecrease(currentSlotStartTime.hours);

    handleStartTimeChange(
      produce(currentSlotStartTime, (draft) => {
        draft.hours = hours;
      })
    );
  };

  const handleIncreaseSlotStartMinutes = () => {
    const currentSlotStartTime = produce(slotStartTime, (draft) => draft);
    const minutes = getBoundedSlotMinutesIncrease(currentSlotStartTime.minutes);

    handleStartTimeChange(
      produce(currentSlotStartTime, (draft) => {
        draft.minutes = minutes;
      })
    );
  };

  const handleDecreaseSlotStartMinutes = () => {
    const currentSlotStartTime = produce(slotStartTime, (draft) => draft);
    const minutes = getBoundedSlotMinutesDecrease(currentSlotStartTime.minutes);

    handleStartTimeChange(
      produce(currentSlotStartTime, (draft) => {
        draft.minutes = minutes;
      })
    );
  };

  const handleIncreaseSlotStopHours = () => {
    const currentSlotStopTime = produce(slotStopTime, (draft) => draft);
    const hours = getBoundedSlotHoursIncrease(currentSlotStopTime.hours);

    handleStopTimeChange(
      produce(currentSlotStopTime, (draft) => {
        draft.hours = hours;
      })
    );
  };

  const handleDecreaseSlotStopHours = () => {
    const currentSlotStopTime = produce(slotStopTime, (draft) => draft);
    const hours = getBoundedSlotHoursDecrease(currentSlotStopTime.hours);

    handleStopTimeChange(
      produce(currentSlotStopTime, (draft) => {
        draft.hours = hours;
      })
    );
  };

  const handleIncreaseSlotStopMinutes = () => {
    const currentSlotStopTime = produce(slotStopTime, (draft) => draft);
    const minutes = getBoundedSlotMinutesIncrease(currentSlotStopTime.minutes);

    handleStopTimeChange(
      produce(currentSlotStopTime, (draft) => {
        draft.minutes = minutes;
      })
    );
  };

  const handleDecreaseSlotStopMinutes = () => {
    const currentSlotStopTime = produce(slotStopTime, (draft) => draft);
    const minutes = getBoundedSlotMinutesDecrease(currentSlotStopTime.minutes);

    handleStopTimeChange(
      produce(currentSlotStopTime, (draft) => {
        draft.minutes = minutes;
      })
    );
  };

  return (
    <Box
      display={"flex"}
      sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
      width={"230px"}
    >
      <Box display={"flex"} sx={{ flexDirection: "column", alignItems: "center" }}>
        <div className={"fa fa-chevron-up"} onClick={handleIncreaseSlotStartHours}></div>
        <input
          className={"timeInput"}
          type={"number"}
          min={0}
          max={23}
          value={slotStartTime.hours}
          style={{ width: "40px", textAlign: "center" }}
          onChange={(e) =>
            handleStartTimeChange(
              produce(slotStartTime, (draft) => {
                draft.hours = parseInt(e.target.value);
              })
            )
          }
        />
        <div className={"fa fa-chevron-down"} onClick={handleDecreaseSlotStartHours}></div>
      </Box>
      <Typography>:</Typography>
      <Box display={"flex"} sx={{ flexDirection: "column", alignItems: "center" }}>
        <div className={"fa fa-chevron-up"} onClick={handleIncreaseSlotStartMinutes}></div>
        <input
          className={"timeInput"}
          type={"number"}
          min={0}
          max={55}
          step={5}
          width={"20px"}
          style={{ width: "40px", textAlign: "center" }}
          value={slotStartTime.minutes.toString().padStart(2, "0")}
          onChange={(e) =>
            handleStartTimeChange(
              produce(slotStartTime, (draft) => {
                draft.minutes = parseInt(e.target.value);
              })
            )
          }
        />
        <div className={"fa fa-chevron-down"} onClick={handleDecreaseSlotStartMinutes}></div>
      </Box>
      <Typography>-</Typography>
      <Box display={"flex"} sx={{ flexDirection: "column", alignItems: "center" }}>
        <div className={"fa fa-chevron-up"} onClick={handleIncreaseSlotStopHours}></div>
        <input
          className={"timeInput"}
          type={"number"}
          min={0}
          max={23}
          width={"20px"}
          style={{ width: "40px", textAlign: "center" }}
          value={slotStopTime.hours}
          onChange={(e) =>
            handleStopTimeChange(
              produce(slotStopTime, (draft) => {
                draft.hours = parseInt(e.target.value);
              })
            )
          }
        />
        <div className={"fa fa-chevron-down"} onClick={handleDecreaseSlotStopHours}></div>
      </Box>
      <Typography>:</Typography>
      <Box display={"flex"} sx={{ flexDirection: "column", alignItems: "center" }}>
        <div className={"fa fa-chevron-up"} onClick={handleIncreaseSlotStopMinutes}></div>
        <input
          className={"timeInput"}
          type={"number"}
          min={0}
          max={55}
          step={5}
          style={{ width: "40px", textAlign: "center" }}
          value={slotStopTime.minutes.toString().padStart(2, "0")}
          onChange={(e) =>
            handleStopTimeChange(
              produce(slotStopTime, (draft) => {
                draft.minutes = parseInt(e.target.value);
              })
            )
          }
        />
        <div className={"fa fa-chevron-down"} onClick={handleDecreaseSlotStopMinutes}></div>
      </Box>
    </Box>
  );
};

export default CustomTimePicker;
