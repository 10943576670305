const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");

var Personnel = Backbone.Model.extend({
  idAttribute: "emp_id",

  urlRoot: ApiConfig.getPrefix() + "/personnel",
});

module.exports = Personnel;
