// Utils to generate the required slot data

import { SlotsByDateByColumnByWeek } from "@/viewer/ui/modules/common/types";
import { Slot } from "@/viewer/types/domain/slot";
import { appendToPath3 } from "@/viewer/utils/helpers";
import { DataContext, FilterContext, SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import useFilteredSlotData from "@/viewer/ui/modules/common/hooks/useFilteredSlotData";
import * as React from "react";
import { getWeekKey } from "@/viewer/ui/modules/common/helpers/dates";
import { getLeftColumnId } from "@/viewer/utils/domain/slotquests";
import { Collection } from "backbone";

const getSlotDataForAssignment = (
  settings: SettingsContext,
  ui: UIContext,
  slots: Slot[]
): SlotsByDateByColumnByWeek => {
  // No pending data here, just gather up and return the slots

  const scheduled: SlotsByDateByColumnByWeek = {};

  slots.forEach((slot) => {
    const weekKey: string = getWeekKey(settings, ui, slot.date);
    const columnId = getLeftColumnId(ui, slot);
    appendToPath3(scheduled, [weekKey, columnId, slot.dateString], slot);
  });

  return scheduled;
};

const getSlotDataForPersonnel = (
  settings: SettingsContext,
  ui: UIContext,
  slots: Slot[]
): SlotsByDateByColumnByWeek => {
  const scheduled: SlotsByDateByColumnByWeek = {};

  slots.forEach((slot) => {
    const weekKey: string = getWeekKey(settings, ui, slot.date);

    // Store all data in scheduled
    const columnId = getLeftColumnId(ui, slot);
    appendToPath3(scheduled, [weekKey, columnId, slot.dateString], slot);

    // Store pending data in pending (except where duplicated)
    if (slot.isPending && slot.pendingEmpId && slot.pendingEmpId !== slot.empId) {
      const columnId = slot.pendingEmpId.toFixed(0);
      appendToPath3(scheduled, [weekKey, columnId, slot.dateString], slot);
    }
  });

  return scheduled;
};

const getStandardSlotData = (settings: SettingsContext, ui: UIContext, slots: Slot[]): SlotsByDateByColumnByWeek => {
  if (ui.leftColumnType === "assignment") {
    return getSlotDataForAssignment(settings, ui, slots);
  }

  return getSlotDataForPersonnel(settings, ui, slots);
};

export default (
  settings: SettingsContext,
  ui: UIContext,
  filters: FilterContext,
  data: DataContext,
  slotCollection: Collection
): SlotsByDateByColumnByWeek => {
  const slots = useFilteredSlotData(settings, ui, filters, data, slotCollection);
  return React.useMemo(() => getStandardSlotData(settings, ui, slots), [ui.leftColumnType, slots]);
};
