import React from "react";

import { getEffects } from "@/viewer/utils/domain/perssignment";
import classnames from "@/_lib/utils/classnames";

import { PersonnelOrAssignment, SlotsByDateByColumn } from "@/viewer/ui/modules/common/types";
import { SettingsContext } from "@/viewer/ui/modules/common/types/context";
import { columnRowHeightMap } from "../ColumnPrintView";

import PrintColumnSlotHeaderItem from "./printColumnSlotHeaderItem";

import "./_styles.scss";

interface Props {
  columnRowHeightMap: columnRowHeightMap;
  condenseColumnView: boolean;
  forceScheduleGroup: boolean;
  height: string;
  hideBlankRows: boolean;
  leftColId: string;
  leftColumn: PersonnelOrAssignment;
  leftHandColumnId: number;
  rowIndex: number;
  settings: SettingsContext;
  slotData: SlotsByDateByColumn;
  visibleItemCount: number;
}

const getSlotHeaderItem = (props: Props): JSX.Element => {
  const { condenseColumnView, height, leftColumn, leftHandColumnId, rowIndex, settings, visibleItemCount } = props;
  const leftColText = leftColumn.compactOrDisplayName;

  const effects = getEffects(leftColumn, settings);
  const rowClassNames = classnames("row", {
    "row--even": visibleItemCount % 2 === 0,
    "row--odd": visibleItemCount % 2 !== 0,
  });
  const cellClassNames = classnames("fixed-cell", {
    "fixed-cell--condensed": condenseColumnView,
  });

  const slotProps = { cellClassNames, effects, height, leftColText, leftHandColumnId, rowClassNames, rowIndex };

  return <PrintColumnSlotHeaderItem {...slotProps} />;
};

const PrintColumnRenderSlotHeader = (props: Props): JSX.Element => {
  return getSlotHeaderItem(props);
};

export default PrintColumnRenderSlotHeader;
