import { Model } from "backbone";

import { cleanPersonnelTypeAttributes, PersonnelTypesById } from "@/viewer/types/domain/personnelType";
import connectedPropertyHookFactory from "@/viewer/ui/modules/common/hooks/connectedPropertyHookFactory";

const getPersonnelTypes = (): PersonnelTypesById => {
  const { LbsAppData } = window as any;

  const out: PersonnelTypesById = {};
  const personnelTypeCollection = LbsAppData.PersonnelTypes;

  if (!personnelTypeCollection) return out;

  personnelTypeCollection.forEach((personnelTypeModel: Model) => {
    const personnelType = cleanPersonnelTypeAttributes(personnelTypeModel.attributes);
    out[personnelType.id] = personnelType;
  });

  return out;
};

export default connectedPropertyHookFactory({
  loader: getPersonnelTypes,
  getListeners: () => {
    const { LbsAppData } = window as any;
    return [[LbsAppData.AppContext, "fetched"]];
  },
});
