"use strict";

// scss requires
require("./_styles.scss");

// dependencies
var React = require("react/addons");

// components
var ContextRibbonItem = require("./ContextRibbonItem.jsx");

var ContextRibbon = React.createClass({
  /* privates */

  /* publics */

  /* lifecycle */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {
    window.LbsAppData.AppContext.on("change:contextualStack", this.forceUpdate.bind(this, null), this);
  },
  componentWillUnmount: function () {
    window.LbsAppData.AppContext.off(null, null, this);
  },

  render: function () {
    var contextualStack = window.LbsAppData.AppContext.get("contextualStack");

    var contextRibbonItems = [];
    for (var i = 0; i < contextualStack.length; i++) {
      contextRibbonItems.push(<ContextRibbonItem key={i} object={contextualStack[i]} />);
    }
    //contextRibbonItems.push(<ContextRibbonItem navControls={true} text={'November 2014'} closeable={false} />);
    //contextRibbonItems.push(<ContextRibbonItem navControls={false} text={'Settings'} closeable={true} />);

    return (
      <div id="ContextRibbon" data-cy="ContextRibbon">
        {contextRibbonItems}
      </div>
    );
  },
});

module.exports = ContextRibbon;
