import * as React from "react";

import { BASE_STANDARD_WEEK_DATA_ROW_HEIGHT } from "@/viewer/ui/modules/grid/standard/constants";
import { getPersonnelOrAssignmentId, isVisible } from "@/viewer/utils/domain/perssignment";
import { calculateSlotQuestCellHeight } from "@/viewer/ui/modules/common/helpers/virtualization";
import { VirtualizedRow } from "@/viewer/ui/modules/common/types/virtualization";
import {
  DataContext,
  FilterContext,
  SettingsContext,
  ToolContext,
  UIContext,
} from "@/viewer/ui/modules/common/types/context";
import {
  PersonnelOrAssignment,
  SlotOrRequest,
  SlotsByDate,
  SlotsByDateByColumn,
} from "@/viewer/ui/modules/common/types";
import StandardWeekDataCell from "@/viewer/ui/modules/grid/standard/components/StandardWeekDataCell";
import StandardWeekDataRow from "@/viewer/ui/modules/grid/standard/components/StandardWeekDataRow";
import { formatISODate } from "@/viewer/utils/dateFormatters";
import { visibleDateRange } from "@/viewer/ui/modules/common/helpers/dates";
import { getEffects } from "@/viewer/utils/domain/perssignment";
import { OriginalPersonnelMap } from "@/viewer/types/viewdata";
import { DepartmentsById } from "@/viewer/types/domain/department";

interface RenderStaticWeekOpts {
  settings: SettingsContext;
  ui: UIContext;
  filters: FilterContext;
  data: DataContext;
  tools: ToolContext;
  originalPersonnelMap: OriginalPersonnelMap;
  leftColumnData: PersonnelOrAssignment[];
  dateStart: Date;
  dateEnd: Date;
  slotData: SlotsByDateByColumn | undefined;
  departmentsById: DepartmentsById;
}

const RenderSlots = (opts: RenderStaticWeekOpts): VirtualizedRow[] => {
  const {
    dateStart,
    dateEnd,
    settings,
    ui,
    filters,
    data,
    tools,
    leftColumnData,
    slotData,
    originalPersonnelMap,
    departmentsById,
  } = opts;

  const { forceScheduleGroup } = settings;

  const rows: VirtualizedRow[] = [];
  const weekKey = formatISODate(settings, dateStart);
  let index = 1;

  leftColumnData.forEach((leftColumn) => {
    // Skip filtered or unavailable rows
    if (!isVisible(leftColumn, ui, filters)) {
      return;
    }

    const leftColId = getPersonnelOrAssignmentId(leftColumn);

    // Get the slots present in this row.
    const slotsByDate: SlotsByDate | undefined = slotData ? slotData[leftColId] : undefined;
    if (!slotsByDate && settings.hideBlankRows && !forceScheduleGroup) {
      // Nothing to show
      return;
    }

    let maxRowHeight = 0;
    const cells = visibleDateRange(settings, dateStart, dateEnd).map((rollingDate) => {
      const slotDate = formatISODate(settings, rollingDate);
      const slots: SlotOrRequest[] = slotsByDate ? slotsByDate[slotDate] ?? [] : [];
      const cellEffects = getEffects(leftColumn, settings);
      const cellHeight = calculateSlotQuestCellHeight(ui, slots);

      if (cellHeight > maxRowHeight) {
        maxRowHeight = cellHeight;
      }

      return (
        <StandardWeekDataCell
          key={slotDate}
          leftColId={leftColId}
          settings={settings}
          ui={ui}
          data={data}
          tools={tools}
          date={rollingDate}
          slots={slots}
          isDataGroup={false}
          index={index}
          cellEffects={cellEffects}
          originalPersonnelMap={originalPersonnelMap}
          departmentsById={departmentsById}
        />
      );
    });

    if (maxRowHeight > 0 || (!settings.hideBlankRows && forceScheduleGroup)) {
      rows.push({
        height: Math.max(maxRowHeight, BASE_STANDARD_WEEK_DATA_ROW_HEIGHT),
        key: `slots-${leftColId}-${weekKey}`,
        wrapperClassName: "data-rows",
        element: (
          <StandardWeekDataRow index={index++} settings={settings} ui={ui} leftCol={leftColumn}>
            {cells}
          </StandardWeekDataRow>
        ),
      });
    }
  });

  return rows;
};

export default RenderSlots;
