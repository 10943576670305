"use strict";

const React = require("react/addons");

const SwitchButtonGroup = require("_lib/ui/modules/widgets/switchbutton/SwitchButtonGroup.jsx");
const TimeRangePicker = require("_lib/ui/modules/widgets/timerangepicker/TimeRangePicker.jsx");
const TimeRangeSlider = require("_lib/ui/modules/widgets/timerangeslider/TimeRangeSlider.jsx");
const SuccessPopup = require("../../../../../popup/success_popup/SuccessPopup.jsx");

const TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
const SwapMixin = require("_lib/ui/mixins/SwapMixin.jsx");

require("./_styles.scss");

let TimeHelper;

const SplitShiftDialog = React.createClass({
  mixins: [TouchMixin, SwapMixin],

  getInitialState: function () {
    TimeHelper = window.LbsAppData.Helpers.Time;

    const slotAttributes = this.props.dialogObject.data.slots[0].attributes;
    const isAdmin = window.LbsAppData.User.attributes.is_admin;

    const startDate = new Date(slotAttributes.start_time);
    const endDate = new Date(slotAttributes.stop_time);

    const totalRangeMinutes = TimeHelper.millisecondsToMinutes(endDate - startDate);
    const leftDefaultValue = TimeHelper.addMinutesToDate(totalRangeMinutes * 0.25, startDate);
    const rightDefaultValue = TimeHelper.addMinutesToDate(totalRangeMinutes * 0.75, startDate);
    const singleDefaultValue = TimeHelper.addMinutesToDate(totalRangeMinutes * 0.5, startDate);

    return {
      isSingleSplit: true,
      isAdmin: isAdmin,
      startDate: startDate,
      endDate: endDate,
      leftSplitDate: leftDefaultValue,
      rightSplitDate: rightDefaultValue,
      singleSplitDate: singleDefaultValue,
      slotAttributes: slotAttributes,
      step: 1, // This has to be here for SwapMixin
      submissionSuccess: false,
    };
  },

  componentDidMount: function () {
    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Swap: Split Shift");

    window.LbsAppData.AppContext.on(
      "showSubmissionSuccess",
      function () {
        this.setState({ submissionSuccess: true }, this._delayAndCloseWindow);
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    window.LbsAppData.AppContext.off("showSubmissionSuccess");
  },

  splitButtonUpdated: function (selectedButton) {
    this.setState({
      isSingleSplit: selectedButton.index === 0,
    });
  },

  splitDatesUpdated: function (newDates) {
    this.setState({
      leftSplitDate: newDates.leftSplitDate,
      rightSplitDate: newDates.rightSplitDate,
      singleSplitDate: newDates.singleSplitDate,
    });
  },

  splitNoteUpdated: function (event) {
    this.setState({
      noteValue: event.target.value,
    });
  },

  _getSplitShiftTimesAndDropDowns: function () {
    if (this.state.isSingleSplit) {
      return (
        <div>
          <div className="split-time-text-container">
            <span>{TimeHelper.formatTimeAsPreferred(this.state.startDate)}</span>
            <span>{TimeHelper.formatTimeAsPreferred(this.state.singleSplitDate)}</span>
            {this._getPersonnelWidget("")}
          </div>
          <div className="split-time-text-container">
            <span>{TimeHelper.formatTimeAsPreferred(this.state.singleSplitDate)}</span>
            <span>{TimeHelper.formatTimeAsPreferred(this.state.endDate)}</span>
            {this._getPersonnelWidget("2")}
          </div>
        </div>
      );
    }

    return (
      <div>
        <div
          className="split-time-text-container"
          style={this._getDisplayStyle(this.state.leftSplitDate.getTime() !== this.state.startDate.getTime())}
        >
          <span>{TimeHelper.formatTimeAsPreferred(this.state.startDate)}</span>
          <span>{TimeHelper.formatTimeAsPreferred(this.state.leftSplitDate)}</span>
          {this._getPersonnelWidget("")}
        </div>
        <div className="split-time-text-container">
          <span>{TimeHelper.formatTimeAsPreferred(this.state.leftSplitDate)}</span>
          <span>{TimeHelper.formatTimeAsPreferred(this.state.rightSplitDate)}</span>
          {this._getPersonnelWidget("2")}
        </div>
        <div
          className="split-time-text-container"
          style={this._getDisplayStyle(this.state.rightSplitDate.getTime() !== this.state.endDate.getTime())}
        >
          <span>{TimeHelper.formatTimeAsPreferred(this.state.rightSplitDate)}</span>
          <span>{TimeHelper.formatTimeAsPreferred(this.state.endDate)}</span>
          {this._getPersonnelWidget("3")}
        </div>
      </div>
    );
  },

  _getPersonnelWidget: function (appendNumber) {
    const personnelSelectable = this.props.widgetProxy("selectPersonnel" + appendNumber);

    return (
      <div className="personelDropDown">
        <div className="editables ypad">{personnelSelectable}</div>
      </div>
    );
  },

  _getDisplayStyle: function (shouldDisplay) {
    return { display: shouldDisplay ? "unset" : "none" };
  },

  _getCancelButton: function () {
    return (
      <span
        className="cancel"
        style={this._getDisplayStyle(this.props.showWarningDialog)}
        onClick={this.touchProxy.bind(this, this.props.close, [])}
        onTouchEnd={this.touchProxy.bind(this, this.props.close, [])}
      >
        Cancel
      </span>
    );
  },

  _getBottomControls: function () {
    return (
      <div className="bottom-controls">
        <div className="position-reset">
          <div className="right-side">
            {this._getBottomRightControl(1)}
            {this._getCancelButton()}
          </div>
        </div>
      </div>
    );
  },

  _getCloseButton: function () {
    return (
      <div
        className="close"
        onClick={this.touchProxy.bind(this, this.props.close, [])}
        onTouchEnd={this.touchProxy.bind(this, this.props.close, [])}
      >
        <i className="fa fa-close" />
      </div>
    );
  },

  _getHeaderContainer: function () {
    return (
      <div className="header-container">
        <div className="header">
          <div className="mobile-section-nav">
            <div className="mobile-section-nav-tab active">
              {"Split Shift:  " + this.state.slotAttributes.assign_display_name}
            </div>
          </div>
        </div>
      </div>
    );
  },

  _getSplitShiftContents: function () {
    return (
      <div>
        <div className="shift-info">
          <div className="row">
            <div className="shift-date">
              {
                // Get's the formatted shift date
                // ex: Tue, Apr 28, 2020
                this.state.startDate.toString().slice(0, 3) +
                  "," +
                  this.state.startDate.toString().slice(3, 10) +
                  "," +
                  this.state.startDate.toString().slice(10, 15)
              }
            </div>
            <div className="shift-time">
              {TimeHelper.formatTimeAsPreferred(this.state.startDate)}
              {" - "}
              {TimeHelper.formatTimeAsPreferred(this.state.endDate)}
            </div>
          </div>
          <div className="row">
            <div className="shift-personnel">{this.state.slotAttributes.display_name}</div>
            <div className="shift-location">{this.state.slotAttributes.location_names.join(", ")}</div>
          </div>
        </div>

        <div className="split-text">
          {"How many shifts would you like to split " + this.state.slotAttributes.assign_display_name + " into?"}
          <SwitchButtonGroup
            buttons={["2 Shifts", "3 Shifts"]}
            activeButtonIndex={this.state.isSingleSplit ? 0 : 1}
            onItemChange={this.splitButtonUpdated}
          />
        </div>

        <TimeRangePicker
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          isSingleSplit={this.state.isSingleSplit}
          onChange={this.splitDatesUpdated}
          leftSplitDate={this.state.leftSplitDate}
          rightSplitDate={this.state.rightSplitDate}
          singleSplitDate={this.state.singleSplitDate}
          canIncludeStartOrEndDates={!this.state.isAdmin}
        />

        <TimeRangeSlider
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          step={15}
          isSingleSplit={this.state.isSingleSplit}
          onChange={this.splitDatesUpdated}
          leftSplitDate={this.state.leftSplitDate}
          rightSplitDate={this.state.rightSplitDate}
          singleSplitDate={this.state.singleSplitDate}
          canIncludeStartOrEndDates={!this.state.isAdmin}
        />
        <div>
          <div className="split-time-text-container">
            <span>Start Time</span>
            <span>Stop Time</span>
            <span>Personnel</span>
          </div>
          {this._getSplitShiftTimesAndDropDowns()}
        </div>
        <div>
          <div className="note-label">Note</div>
          <textarea onChange={this.splitNoteUpdated} />
        </div>
      </div>
    );
  },

  _getNonErrorDialog: function () {
    return (
      <div>
        {this._getCloseButton()}
        {this._getHeaderContainer()}
        <div>{this._getSplitShiftContents()}</div>
        {this._getBottomControls()}
      </div>
    );
  },

  _getWarningDialog: function () {
    return (
      <div>
        {this._getCloseButton()}
        <div className="split-warning-dialog">
          <p>You have only selected yourself for the split shift. Would you like to proceed?</p>
        </div>
        {this._getBottomControls()}
      </div>
    );
  },
  _shouldWarningDialogBeShown: function () {
    const showWarningDialog = this.props.showWarningDialog;

    return showWarningDialog ? this._getWarningDialog() : this._getNonErrorDialog();
  },

  _getContent: function () {
    if (this.state.submissionSuccess) {
      return <SuccessPopup />;
    }

    return this._shouldWarningDialogBeShown();
  },

  _delayAndCloseWindow: function () {
    const id = setTimeout(
      function () {
        clearInterval(id);
        this.props.close();
      }.bind(this),
      1500
    );
  },

  render: function () {
    const classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      SplitShift: true,
    });

    return (
      <div className={classes}>
        <div className="position-reset">{this._getContent()}</div>
      </div>
    );
  },
});

module.exports = SplitShiftDialog;
