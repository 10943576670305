const Backbone = require("backbone");
var AssignmentDemand = Backbone.Model.extend({
  idAttribute: "assign_id",

  defaults: function () {
    //this.slots = new SlotCollection();
  },

  initialize: function () {
    //alert(this.slots);
    //thisslots.add(this.attributes.slots);
    //alert(slots.length);
  },

  url: function () {
    //return this.id ? 'http://mossberg/lbapiv11/schedule/range/?start_date=' + startDate + '&end_date=' + endDate;
  },
});

module.exports = AssignmentDemand;
