import * as React from "react";

import {
  GROUP_BANNER_ROW_HEIGHT,
  BASE_STANDARD_WEEK_DATA_ROW_HEIGHT,
} from "@/viewer/ui/modules/grid/standard/constants";
import { getPersonnelOrAssignmentId, isVisible, getEffects } from "@/viewer/utils/domain/perssignment";
import { calculateSlotQuestCellHeight } from "@/viewer/ui/modules/common/helpers/virtualization";
import { VirtualizedRow } from "@/viewer/ui/modules/common/types/virtualization";
import {
  DataContext,
  FilterContext,
  SettingsContext,
  ToolContext,
  UIContext,
} from "@/viewer/ui/modules/common/types/context";
import { PersonnelOrAssignment, RequestsByDateByName } from "@/viewer/ui/modules/common/types";
import StandardWeekDataCell from "@/viewer/ui/modules/grid/standard/components/StandardWeekDataCell";
import StandardWeekDataRow from "@/viewer/ui/modules/grid/standard/components/StandardWeekDataRow";
import { formatISODate } from "@/viewer/utils/dateFormatters";
import { visibleDateRange } from "@/viewer/ui/modules/common/helpers/dates";
import { OriginalPersonnelMap } from "@/viewer/types/viewdata";
import { DepartmentsById } from "@/viewer/types/domain/department";

interface RenderStaticWeekOpts {
  settings: SettingsContext;
  ui: UIContext;
  filters: FilterContext;
  data: DataContext;
  tools: ToolContext;
  leftColumnData: PersonnelOrAssignment[];
  originalPersonnelMap: OriginalPersonnelMap;
  dateStart: Date;
  dateEnd: Date;
  requestData: RequestsByDateByName | undefined;
  departmentsById: DepartmentsById;
}

const RenderRequests = (opts: RenderStaticWeekOpts): VirtualizedRow[] => {
  const {
    dateStart,
    dateEnd,
    settings,
    ui,
    filters,
    data,
    tools,
    leftColumnData,
    requestData,
    originalPersonnelMap,
    departmentsById,
  } = opts;

  const { forceRequestsGroup, hideBlankRows } = settings;

  const rows: VirtualizedRow[] = [];
  const weekKey = formatISODate(settings, dateStart);

  // Pending requests group (This logic replaces StandardWeekDataGroup)
  if (forceRequestsGroup || requestData) {
    const requestRows: VirtualizedRow[] = [];
    leftColumnData.forEach((leftColumn) => {
      if (!isVisible(leftColumn, ui, filters)) {
        return;
      }

      const leftColId = getPersonnelOrAssignmentId(leftColumn);
      const rowData = requestData ? requestData[leftColId] : undefined;

      // Compute the max # of requests in a given day
      let maxRowHeight = settings.hideBlankRows ? 0 : BASE_STANDARD_WEEK_DATA_ROW_HEIGHT;
      const cells = visibleDateRange(settings, dateStart, dateEnd).map((rollingDate) => {
        const requestDate = formatISODate(settings, rollingDate);
        const requests = (rowData ? rowData[requestDate] : undefined) ?? [];

        const cellHeight = calculateSlotQuestCellHeight(ui, requests);
        if (cellHeight > maxRowHeight) {
          maxRowHeight = cellHeight;
        }

        const cellEffects = getEffects(leftColumn, settings);

        return (
          <StandardWeekDataCell
            key={requestDate}
            leftColId={leftColId}
            settings={settings}
            ui={ui}
            data={data}
            tools={tools}
            date={rollingDate}
            slots={requests}
            isDataGroup
            index={-1}
            cellEffects={cellEffects}
            originalPersonnelMap={originalPersonnelMap}
            departmentsById={departmentsById}
          />
        );
      });

      if (!hideBlankRows || rowData) {
        requestRows.push({
          height: maxRowHeight,
          key: `requests-${leftColId}-${weekKey}`,
          wrapperClassName: "data-group requests",
          element: (
            <div className={"data-rows"}>
              <StandardWeekDataRow
                // Don't include index,
                index={0}
                leftCol={leftColumn}
                settings={settings}
                ui={ui}
              >
                {cells}
              </StandardWeekDataRow>
            </div>
          ),
        });
      }
    });

    if (forceRequestsGroup || requestRows.length > 0) {
      rows.push({
        height: GROUP_BANNER_ROW_HEIGHT,
        key: `requests-head-${weekKey}`,
        wrapperClassName: "data-group requests",
        element: (
          <div className={"group-title group-title-requests"}>
            <i className="fa fa-caret-up" />
            Requests
          </div>
        ),
      });

      rows.push(...requestRows);
    }
  }

  return rows;
};

export default RenderRequests;
