"use strict";

require("./_styles.scss");

var React = require("react/addons");
var $ = require("jquery");

var ActionBarIcon = require("./ActionBarIcon.jsx");
var ToolBoxIcon = require("./ToolBoxIcon.jsx");
var ModeIcon = require("./ModeIcon.jsx");
var ActionIcon = require("./ActionIcon.jsx");

var ToolBox = React.createClass({
  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {
    // register to AppContext tool change
    window.LbsAppData.ToolBox.on("change:currentTool", this.forceUpdate.bind(this, null), this);
  },
  componentWillUnmount: function () {
    window.LbsAppData.ToolBox.off(null, null, this);
  },
  componentWillUpdate: function () {
    window.LbsAppData.ToolBox.off(null, null, this);
  },
  componentDidUpdate: function () {
    window.LbsAppData.ToolBox.on("change:currentTool", this.forceUpdate.bind(this, null), this);
  },

  /* publics */

  /* privates */
  _saveScheduleChanges: function () {
    // save the changes
    window.LbsAppData.AppContext.saveSchedule();
  },
  _clearScheduleChanges: function () {
    // clear the changes
    window.LbsAppData.AppContext.clearSchedule();
  },

  _triggerSwappingMode: function () {
    if (window.LbsAppData.AppContext.get("mode") == "view") {
      window.LbsAppData.AppContext.triggerSwappingMode();
    } else {
      window.LbsAppData.AppContext.triggerViewingMode();
    }
  },

  _openReportManager: function () {
    window.LbsAppData.AppContext.openDialog({
      type: "manage-reports",
    });
  },
  _openSubReport: function (data) {
    // open the reporting app in a separate tab
    window.LbsAppData.AppContext.openDialog({
      type: "sub-report",
      data: data,
    });
  },
  _exportReport: function () {
    // the a tag will take care of the actual link ability -- this is for the event
    var reportDef = window.LbsAppData.AppContext.get("report_saved");
    window.LbsAppData.Helpers.Analytics.sendEvent("Export: Report", reportDef ? reportDef.id : undefined);
  },
  _getDownloadUrl: function () {
    var csvString;
    var reportDraft = window.LbsAppData.AppContext.get("report_draft");
    if (reportDraft) {
      csvString = reportDraft.convertToCsv();
    }

    return "data:application/csv;charset=utf-8," + encodeURIComponent(csvString);
  },
  _compressTable: function () {
    window.LbsAppData.AppContext.get("report_draft").compress();
  },
  _expandTable: function () {
    window.LbsAppData.AppContext.get("report_draft").expand();
  },
  _checkDisabled: function (type) {
    switch (type) {
      case "export":
        return window.LbsAppData.AppContext.get("report_draft") ? false : true;
    }
  },

  /* render */
  render: function () {
    /* jshint ignore:start */

    var currentTool = window.LbsAppData.ToolBox.get("currentTool");

    var actions = [];
    var tools = [];

    //if (this.props.appName == 'viewer') {
    //tools.push(<ModeIcon key='swaps' icon='fa-magic' tooltip={'bulk actions'} type='bulk' onClick={this._triggerSwappingMode} />);
    //	tools.push(<ToolBoxIcon key='bulk-select' icon='fa-magic' active={currentTool && currentTool.attributes.type == 'bulk-select'} tooltip={'bulk actions'} type={'bulk-select'} />);
    //} else if (this.props.appName == 'editor') {
    if (this.props.appName == "editor") {
      // actions
      //actions.push(<ActionBarIcon key='report' icon='fa-calculator' tooltip={'open report tab'} onClick={this._openReport} appName={this.props.appName} />);
      actions.push(
        <ActionBarIcon
          key="floppy"
          icon="fa-floppy-o"
          tooltip={"save changes"}
          onClick={this._saveScheduleChanges}
          appName={this.props.appName}
        />
      );
      actions.push(
        <ActionBarIcon
          key="trash"
          icon="fa-trash-o"
          tooltip={"clear changes"}
          onClick={this._clearScheduleChanges}
          appName={this.props.appName}
        />
      );
      // tools
      tools.push(
        <ToolBoxIcon key="select" icon="fa-magic" active={currentTool.attributes.type == "select"} type={"select"} />
      );
      tools.push(
        <ToolBoxIcon key="fill" icon="fa-pencil" active={currentTool.attributes.type == "fill"} type={"fill"} />
      );
      tools.push(
        <ToolBoxIcon
          key="alternates"
          icon="fa-crosshairs"
          active={currentTool.attributes.type == "alternates"}
          type={"alternates"}
        />
      );
      tools.push(
        <ToolBoxIcon key="remove" icon="fa-eraser" active={currentTool.attributes.type == "remove"} type={"remove"} />
      );
      tools.push(
        <ToolBoxIcon
          key="highlight"
          icon="fa-paint-brush"
          active={currentTool.attributes.type == "highlight"}
          type={"highlight"}
        />
      );
    } else if (this.props.appName == "reports") {
      // actions
      //actions.push(<ActionBarIcon key='hdd' icon='fa-hdd-o' tooltip={'manage reports'} onClick={this._openReportManager} />);
      actions.push(
        <ActionBarIcon
          key="export"
          url={this._getDownloadUrl()}
          filename={"lb_reportdata.csv"}
          icon="fa-cloud-download"
          tooltip={"download"}
          isDisabled={this._checkDisabled.bind(this, "export")}
          onClick={this._exportReport}
        />
      );
      //actions.push(<ActionBarIcon key='files-o' icon='fa-files-o' tooltip={'clone report'} onClick={this._cloneReport} />);
      //actions.push(<ActionBarIcon key='delete' icon='fa-trash-o' tooltip={'delete report'} onClick={this._deleteReport} />);

      //tools.push(<ModeIcon key='design' icon='fa-sitemap' active={window.LbsAppData.AppContext.get('mode') == 'design'} type={'design'} />);
      //tools.push(<ModeIcon key='run' icon='fa-play-circle-o' active={window.LbsAppData.AppContext.get('mode') == 'run'} type={'run'} />);

      //tools.push(<ActionIcon key='column' icon='fa-columns' tooltip={'define column'} onClick={this._openSubReport} data={{type: 'column'}} />);
      //tools.push(<ActionIcon key='expand' icon='fa-expand' tooltip={'expand table'} onClick={this._expandTable} data={{type: 'expand'}} />);
      //tools.push(<ActionIcon key='compress' icon='fa-compress' tooltip={'compress table'} onClick={this._compressTable} data={{type: 'compress'}} />);
      tools.push(
        <ActionIcon
          key="bar"
          icon="fa-bar-chart"
          tooltip={"plot bar chart"}
          onClick={this._openSubReport}
          data={{ type: "bar" }}
        />
      );
      tools.push(
        <ActionIcon
          key="line"
          icon="fa-line-chart"
          tooltip={"plot line chart"}
          onClick={this._openSubReport}
          data={{ type: "line" }}
        />
      );
      tools.push(
        <ActionIcon
          key="pie"
          icon="fa-pie-chart"
          tooltip={"plot pie chart"}
          onClick={this._openSubReport}
          data={{ type: "pie" }}
        />
      );
    }

    var actionsDiv;
    if (actions.length) {
      actionsDiv = <div className="action-icons">{actions}</div>;
    }
    var toolsDiv;
    if (tools.length) {
      toolsDiv = <div className="tool-icons">{tools}</div>;
    }

    return (
      <div id="ToolBox">
        {actionsDiv}
        {toolsDiv}
      </div>
    );

    /*
		<ToolBoxMenu currentTool={currentTool} />
		{currentToolComponent}

				<div className='tool-desc'>
					{currentTool.attributes.type}
				</div>
		*/
  },
});

module.exports = ToolBox;
