const React = require("react");
const moment = require("moment");
const _ = require("underscore");

var BulkSelectTool = Backbone.Model.extend({
  defaults: {
    type: "bulk-select",
    desc: "bulk select",
    backgroundColor: "#333333",
    color: "#ffffff",

    target: undefined,
    component: undefined,
    value: undefined,

    // tool specific attributes
    targets: {},
    components: {},
    targetActions: {},
  },

  onSelect: function () {
    /*
		window.LbsAppData.AppContext.openExtra({
			type: 'highlight',
			tool: this,
		});
		*/

    // subscribe to fetches -- in which case we need to flutter potentially selected
    // slots to reconnect potentially mounted component objects
    window.LbsAppData.AppContext.on("fetched", this._flutter.bind(this, null), this);
  },

  onAttachPre: function () {
    this._reset();
  },

  onAttach: function (args) {
    if (!_.isArray(args)) {
      this._attachSingleSlot(args);
    } else {
      // loop through the args
      for (var i = 0; i < args.length; i++) {
        // don't show error toasts
        this._attachSingleSlot(args[i], { silent: true });
      }
    }

    this.trigger("attached");
  },

  onExecute: function () {},

  onFinished: function () {},

  onClose: function () {
    // highlighters are weird, push this up to the collection level so ALL highlighters can be closed
    //this.collection.reset();
    //window.LbsAppData.AppContext.closeExtra();
    this.reset();

    // remove the listeners
    window.LbsAppData.AppContext.off(null, null, this);
  },

  /* publics */
  style: function () {
    return {
      backgroundColor: this.attributes.backgroundColor,
      color: this.attributes.color,
      //background: 'linear-gradient(to bottom, rgba(23,23,23,1) 0%, rgba(45,45,45,1) 5%, rgba(254,255,132,1) 6%, rgba(254,255,132,1) 94%, rgba(23,23,23,1) 95%, rgba(45,45,45,1) 100%)',
      background: "repeating-linear-gradient(55deg,#232323,#232323 6%,#323232 6%,#323232 12%)",
    };
  },
  extClasses: function () {
    return {
      "trans-hover": true,
    };
  },

  reset: function () {
    this._reset();
    this.trigger("reset");
  },

  forceUpdateComponent: function () {
    if (this.attributes.component) {
      this.attributes.component.forceUpdateOnParent();
    }
  },

  hasSelectedSlots: function () {
    return this.get("slotsSelected") > 0;
  },

  getHighlightedSlots: function () {
    return this.get("slotsSelected");
  },

  getAvailableActions: function () {
    var actions = {
      // adders
      fill: window.LbsAppData.Helpers.Permissions.CanIChangeAnyonesSchedule(),
      request: window.LbsAppData.Helpers.Permissions.CanIChangeAnyonesRequests(),

      // modifiers
      modify: false,
      remove: false,
      replace: false,
      exchange: false,

      // deciders
      grant: false,
      deny: false,
      suspend: false,
      delete: false,
      accept: false,
      decline: false,
      finalize: false,
      cancel: false,
    };

    // disable actions based on selection
    var targets = this.get("targets");
    var availableActions = this._calculateSelectionActions();

    // if there is a selection disable fill/request
    if (_.keys(targets).length > 0) {
      actions.fill = false;
      actions.request = false;

      // test the first target to see if it is a swap group or a request group
      if (targets[_.keys(targets)[0]].get("slot_date")) {
        // slot -- only enable swap actions
        actions.modify = _.indexOf(availableActions, window.LbsAppData.DETAILS_ACTION) > -1; //true;
        actions.remove = _.indexOf(availableActions, window.LbsAppData.REMOVE_ACTION) > -1;
        actions.replace = _.indexOf(availableActions, window.LbsAppData.REPLACE_ACTION) > -1;

        // if there are exactly two people enable exchange
        var emps = _.groupBy(targets, function (t) {
          return t.get("emp_id");
        });
        if (_.keys(emps).length == 2) {
          actions.exchange = window.LbsAppData.Helpers.Permissions.CanIExchangeTheseSlots(targets);
        }

        // if all these are pending allow for grant/denys/deletes
        if (
          !_.find(targets, function (t) {
            return !t.get("is_pending");
          })
        ) {
          // swops or not swops
          if (
            !_.find(targets, function (t) {
              return true;
            }).get("pending_info").preswaps.length
          ) {
            // swaps
            actions.grant = _.indexOf(availableActions, window.LbsAppData.GRANT_ACTION) > -1;
            actions.deny = _.indexOf(availableActions, window.LbsAppData.DENY_ACTION) > -1;
            actions.delete = _.indexOf(availableActions, window.LbsAppData.DELETE_ACTION) > -1;
          } else {
            // swaportunities
            actions.accept = _.indexOf(availableActions, window.LbsAppData.ACCEPT_ACTION) > -1;
            actions.decline = _.indexOf(availableActions, window.LbsAppData.DECLINE_ACTION) > -1;
            actions.finalize = _.indexOf(availableActions, window.LbsAppData.FINALIZE_ACTION) > -1;
            actions.cancel = _.indexOf(availableActions, window.LbsAppData.CANCEL_SWOP_ACTION) > -1;
          }
        }
      } else {
        // request -- only enable request actions
        actions.suspend = _.indexOf(availableActions, window.LbsAppData.SUSPEND_ACTION) > -1;
        actions.delete = _.indexOf(availableActions, window.LbsAppData.DELETE_ACTION) > -1;
        actions.grant = _.indexOf(availableActions, window.LbsAppData.GRANT_ACTION) > -1;
        actions.deny = _.indexOf(availableActions, window.LbsAppData.DENY_ACTION) > -1;

        // if they're all the same, gray that action out
        var statuses = _.uniq(
          _.map(targets, function (t) {
            return t.get("status");
          })
        );
        if (statuses.length == 1) {
          switch (statuses[0]) {
            case "pending":
              actions.suspend = false;
              break;
            case "deleted":
              actions.delete = false;
              break;
            case "granted":
              actions.grant = false;
              break;
            case "denied":
              actions.deny = false;
              break;
            default:
              break;
          }
        }
      }
    }

    return actions;
  },

  canAttach: function (slot) {
    // permissions first
    let actions;

    if (slot.get("slot_date")) {
      // get available slot actions
      actions = window.LbsAppData.Helpers.Swaps.getSwapActions(window.LbsAppData.User, slot);
    } else {
      // get available request actions
      actions = window.LbsAppData.Helpers.Swaps.getRequestActions(
        window.LbsAppData.User,
        slot,
        window.LbsAppData.Templates.getReqWindowStartOrLatestScheduled(),
        true
      );
    }
    if (!actions.length) {
      // no permission
      return { code: 2, actions: actions };
    }

    // slot/request homogeneity
    if (_.keys(this.attributes.targets).length == 0) {
      // return true if this is the first selection
      return { code: 0, actions: actions };
    } else {
      var tester = _.find(this.attributes.targets, function (t) {
        return true;
      });
      if (
        !slot.get("slot_date") == !tester.get("request_date") ||
        !slot.get("request_date") == !tester.get("slot_date")
      ) {
        return { code: 1, actions: actions };
      }
    }

    // passed
    return { code: 0, actions: actions };
  },

  decodeError: function (code) {
    const errorCodes = {
      1: "Selection cannot involve both shifts and requests.",
      2: "You do not have permission to edit this.",
    };

    return (
      <>
        <div className="message-container">
          <div className="header">Invalid</div>
          <span className="text">{errorCodes[code]}</span>
        </div>
      </>
    );
  },

  getTargetType: function () {
    // type = swap|request|swop
    if (_.keys(this.attributes.targets).length) {
      var test = _.find(this.attributes.targets, function (t) {
        return true;
      });
      if (test.get("slot_date")) {
        // slot
        if (!test.get("is_pending") || !test.get("pending_info").preswaps.length) {
          return "swap";
        } else {
          // swaportunity
          return "swop";
        }
      } else {
        // request
        return "request";
      }
    } else {
      // no target
      return undefined;
    }
  },

  /* privates */
  _reset: function () {
    // unbind this tool to the slots
    for (var key in this.attributes.targets) {
      this.attributes.targets[key].set({ boundTool: undefined });
    }

    for (var key in this.attributes.components) {
      var component = this.attributes.components[key];
      if (component && component.isMounted() && component.forceUpdateOnParent) {
        component.forceUpdateOnParent();
      }
    }

    this.set({ targets: {}, components: {}, targetActions: {} });
  },
  _flutter: function () {
    // loop through our targets and resave the components they're mounted to
    var savedKeys = _.keys(this.attributes.targets);
    for (var i = 0; i < savedKeys.length; i++) {
      var slot;
      if (this.attributes.targets[savedKeys[i]].get("slot_date")) {
        // slot
        slot = window.LbsAppData.Slots.get(savedKeys[i]);
      } else {
        // request
        slot = window.LbsAppData.Requests.get(savedKeys[i]);
      }

      if (!slot) {
        // out of range -- don't flutter
      } else {
        // clear the current target/component
        delete this.attributes.targets[savedKeys[i]];
        delete this.attributes.components[savedKeys[i]];

        this.onAttach({
          slot,
        });
      }
    }
  },
  _calculateSelectionActions: function () {
    var actions;
    for (var key in this.attributes.targetActions) {
      var ta = this.attributes.targetActions[key];
      actions = actions ? _.intersection(actions, ta) : ta;
    }

    return actions;
  },
  _attachSingleSlot: function (args, options) {
    var slot;
    if (args.slot) {
      slot = args.slot;
    } else if (args.component) {
      slot = args.component.props.slot;
    } else {
      // Nothing to attach to
      return;
    }

    // check validity
    var test = this.canAttach(slot);
    if (test.code > 0) {
      // no message if silent options is set
      if (!options || !options.silent) {
        window.LbsAppData.AppContext.addToast({
          message: this.decodeError(test.code),
          timeMounted: moment().valueOf(), // unix ms
          duration: 3000,
        });
      }
      return;
    }

    // test if this slot is already selected
    if (!this.attributes.targets[slot.id]) {
      this.attributes.targets[slot.id] = slot;
      if (args.component) this.attributes.components[slot.id] = args.component;
      this.attributes.targetActions[slot.id] = test.actions;
      // attach this tool to the slot object
      slot.set({ boundTool: this });
    } else {
      // pop this slot
      var target = this.attributes.targets[slot.id];
      var component = this.attributes.components[slot.id];
      target.set({ boundTool: undefined });
      if (component && component.forceUpdateOnParent) {
        component.forceUpdateOnParent();
      }

      // delete
      delete this.attributes.targets[slot.id];
      delete this.attributes.components[slot.id];
      delete this.attributes.targetActions[slot.id];
    }
  },
});

module.exports = BulkSelectTool;
