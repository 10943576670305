import React from "react";

import "./SearchInput.scss";

interface OwnProps {
  searchString: string;
  updateSearchString: (searchString: string) => void;
}

class SearchInput extends React.PureComponent<OwnProps> {
  updateSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.updateSearchString(e.target.value);
  };

  clearSearch = (): void => {
    this.props.updateSearchString("");
  };

  render(): JSX.Element {
    const { searchString } = this.props;

    return (
      <div className="search-bar">
        <i className="fa fa-search magnifying-glass"></i>
        <input
          type="text"
          className="search-input"
          data-cy="SearchInput"
          value={searchString}
          onChange={this.updateSearch}
        ></input>
        {searchString && (
          <span onClick={() => this.clearSearch()} className="search-clear">
            <i className="fa fa-times-circle " data-cy="SearchClear"></i>
          </span>
        )}
      </div>
    );
  }
}

export default SearchInput;
