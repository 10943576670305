"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var _ = require("underscore");
var moment = require("moment");
var Promise = require("bluebird");
// components
var DatePicker = require("_lib/ui/modules/widgets/datepicker/DatePicker.jsx");
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var DateSelectDialog = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {},
  componentWillUnmount: function () {},

  /* privates */
  _reset: function () {
    var that = this;
    this.refs.DatePicker.setState({ selectedDates: {} }, function () {
      that.props.submit(that.refs.DatePicker.state.selectedDates);
    });
  },
  _submit: function () {
    this.props.submit(this.refs.DatePicker.state.selectedDates);
    this.props.close();
  },

  /* publics */

  /* render */
  render: function () {
    const { dialogObject, isTop } = this.props;
    const { datepickerProperties } = dialogObject;
    const { mode, date, lowerBound, upperBound, selectedDates, options, blockOptions, checkForStructure } =
      datepickerProperties;

    const classes = React.addons.classSet({
      Dialog: true,
      isTop: isTop,
      hasBlockDetails: blockOptions?.length,
      ScheduleEditsExt: true,
      DateSelectDialog: true,
    });

    return (
      <div className={classes}>
        <div className="position-reset">
          <div className="content">
            <DatePicker
              ref="DatePicker"
              mode={mode}
              date={date}
              lowerBound={lowerBound}
              upperBound={upperBound}
              selectedDates={selectedDates}
              options={options}
              blockOptions={blockOptions}
              checkForStructure={checkForStructure}
            />
            <div
              className="reset"
              onClick={this.touchProxy.bind(this, this._reset, [])}
              onTouchEnd={this.touchProxy.bind(this, this._reset, [])}
            >
              <u>Reset</u>
            </div>
          </div>
          <div className="bottom-buttons">
            <span
              className="submit"
              data-cy="dateSelectDialogSubmitBtn"
              onClick={this.touchProxy.bind(this, this._submit, [])}
              onTouchEnd={this.touchProxy.bind(this, this._submit, [])}
            >
              <i className="fa fa-check" />
            </span>
          </div>
        </div>
      </div>
    );
  },
});

module.exports = DateSelectDialog;
