import React from "react";
import moment from "moment";
import _ from "underscore";
import { useFormikContext } from "formik";

import ConfigHelper from "@/_lib/data/ConfigHelper";
import useUpdateEffect from "@/viewer/ui/modules/common/hooks/useUpdateEffect";
import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { ViewData } from "@/viewer/types/viewdata";
import { User } from "@/viewer/types/domain/user";
import { Personnel, PersonnelAttributes } from "@/viewer/types/domain/personnel";
import {
  Assignment,
  AssignmentAttributes,
  cleanVrmAssignmentAttributes,
  VrmAssignment,
  VrmAssignmentAttributes,
} from "@/viewer/types/domain/assignment";
import { Reason, ReasonAttributes } from "@/viewer/types/domain/reason";
import { Location, LocationAttributes } from "@/viewer/types/domain/location";
import { BlockDay } from "@/common/components/fields/date-field/types";
import { FormValues } from "@/viewer/ui/modules/modals/add-shift/AddShiftModal";
import { DateValueType } from "@/common/components/fields";
import { getAppContext } from "@/_lib/utils/urls";
import { get } from "@/_lib/utils/fetch";
import {
  CanIChangeAssignmentOnRequestsForTemplate,
  CanIChangeAssignmentOnScheduleForTemplate,
  CanIChangeThisPersonsRequests,
  CanIChangeThisPersonsSchedule,
  CanIChangeFillNotes,
  CanIChangeRequestNotes,
} from "@/common/utils";
import {
  getBlockDayRange,
  getAssignmentDefaultTimes,
  transformAssignments,
  transformLocations,
  transformPersonnel,
  transformReasons,
  permutateTargets,
} from "./helpers";
import { isVisible } from "@/viewer/utils/domain/perssignment";
import { CallOrder, callOrderOptions as CallOrderData, keepDefaultsOption } from "@/viewer/types/domain/callOrder";
import { useFlags } from "launchdarkly-react-client-sdk";
import { LDFlagEnums } from "@/_lib/constants/LDFlagEnums";

type PersonnelData = {
  loading: boolean;
  options: { label: string; options: Personnel[] }[];
};

type AssignmentData = {
  loading: boolean;
  options: { label: string; options: Assignment[] }[];
};

type DateData = {
  valueType: DateValueType;
  minDate: moment.Moment;
  maxDate: moment.Moment;
  initialFocusedDate: moment.Moment;
  limit?: number; //Number of dates that can be selected
  blockData: null | {
    dayRange: BlockDay[];
    startWeekDay: BlockDay;
    duration: number;
  };
  shiftData: null | {
    firstPublishedDate: moment.Moment;
    lastPublishedDate: moment.Moment;
    requestWindowStart: moment.Moment;
    requestWindowEnd: moment.Moment;
  };
};

type ReasonData = {
  options: Reason[];
};

type LocationData = {
  options: Location[];
  disableManySelect: boolean;
};

type ShiftTimesData = {
  defaultStartTime: null | moment.Moment;
  defaultEndTime: null | moment.Moment;
  showResetButton: boolean;
};

type NoteData = {
  showField: boolean;
};

type AddDemandIfNecessaryData = {
  showField: boolean;
};

export default function useFormData(
  settings: SettingsContext,
  viewData: ViewData,
  uiContext: UIContext,
  user: User
): {
  personnelData: PersonnelData;
  assignmentData: AssignmentData;
  dateData: DateData;
  reasonData: ReasonData;
  locationData: LocationData;
  shiftTimesData: ShiftTimesData;
  noteData: NoteData;
  addDemandIfNecessaryData: AddDemandIfNecessaryData;
  vrmAssignment: VrmAssignment | undefined;
  callOrderOptions: CallOrder[] | undefined;
  callOrderVisible?: boolean;
} {
  const { values, setValues, setFieldValue } = useFormikContext<FormValues>();
  const [personnelData, setPersonnelData] = React.useState<PersonnelData>({ options: [], loading: false });
  const [assignmentData, setAssignmentData] = React.useState<AssignmentData>({ options: [], loading: false });
  const [reasonData, setReasonData] = React.useState<ReasonData>({ options: [] });
  const [locationData, setLocationData] = React.useState<LocationData>({ options: [], disableManySelect: true });
  const [vrmAssignment, setVrmAssignment] = React.useState<VrmAssignment | undefined>();
  const [callOrderOptions, setCallOrderOptions] = React.useState<CallOrder[]>(CallOrderData);
  const [callOrderVisible, setCallOrderVisible] = React.useState<boolean>(false);

  const LDFlags = useFlags();

  const initialMountOfPersonnel = React.useRef(true);
  const initialMountOfAssignments = React.useRef(true);

  const [defaultStartTime, defaultEndTime] = getAssignmentDefaultTimes(values);
  const isFill = values.date.some((date) => date.isBefore(values.template.requestWindowStart, "day"));

  const doesEverySelectedPersonnelHaveSchedulePermissions = React.useMemo(
    () => (!values.personnel.length ? false : values.personnel.every((p) => CanIChangeThisPersonsSchedule(p))),
    [values.personnel]
  );

  const doesEverySelectedPersonnelHaveRequestPermissions = React.useMemo(
    () => (!values.personnel.length ? false : values.personnel.every((p) => CanIChangeThisPersonsRequests(p))),
    [values.personnel]
  );

  const doesEverySelectedAssignmentHaveSchedulePermissions = React.useMemo(
    () =>
      !values.assignment.length
        ? false
        : values.assignment.every((a) => CanIChangeAssignmentOnScheduleForTemplate(a, values.template)),
    [values.assignment]
  );

  const doesEverySelectedAssignmentHaveRequestPermissions = React.useMemo(
    () =>
      !values.assignment.length
        ? false
        : values.assignment.every((a) => CanIChangeAssignmentOnRequestsForTemplate(a, values.template)),
    [values.assignment]
  );

  const doesEverySelectedPersonnelAndAssignmentHaveFillNotePermissions = React.useMemo(
    () =>
      !values.date.length
        ? false
        : permutateTargets(values).every((item) =>
            CanIChangeFillNotes(item.personnel, item.assignment, values.template)
          ),
    [values.personnel, values.assignment, values.date]
  );

  const doesEverySelectedPersonnelAndAssignmentHaveRequestNotePermissions = React.useMemo(
    () =>
      !values.date.length
        ? false
        : permutateTargets(values).every((item) =>
            CanIChangeRequestNotes(item.personnel, item.assignment, values.template)
          ),
    [values.personnel, values.assignment, values.date]
  );

  const blockingAssignment = React.useMemo(
    () => (!values.assignment.length ? null : values.assignment.find((a) => a.isBlock)),
    [values.assignment]
  );

  const shouldDatePickerShowScheduleAndRequestableDates =
    doesEverySelectedPersonnelHaveSchedulePermissions &&
    doesEverySelectedPersonnelHaveRequestPermissions &&
    doesEverySelectedAssignmentHaveSchedulePermissions &&
    doesEverySelectedAssignmentHaveRequestPermissions;

  const shouldDatePickerShowOnlyScheduleDates =
    !shouldDatePickerShowScheduleAndRequestableDates &&
    doesEverySelectedPersonnelHaveSchedulePermissions &&
    doesEverySelectedAssignmentHaveSchedulePermissions;

  const shouldDatePickerShowOnlyRequestableDates =
    !shouldDatePickerShowScheduleAndRequestableDates &&
    doesEverySelectedPersonnelHaveRequestPermissions &&
    doesEverySelectedAssignmentHaveRequestPermissions;

  const getInitialFocusedDate = () => {
    if (getAppContext() === "dashboard") {
      return moment();
    }

    return moment(uiContext.startDate);
  };

  const getMinDate = () => {
    if (vrmAssignment) {
      return moment(vrmAssignment.requestWindowStart);
    }

    if (shouldDatePickerShowScheduleAndRequestableDates || shouldDatePickerShowOnlyScheduleDates) {
      return moment(values.template.firstPublishedDate);
    }

    if (shouldDatePickerShowOnlyRequestableDates) {
      return moment(values.template.requestWindowStart);
    }

    return moment();
  };

  const getMaxDate = () => {
    if (vrmAssignment) {
      return moment(vrmAssignment.requestWindowEnd);
    }

    if (shouldDatePickerShowScheduleAndRequestableDates || shouldDatePickerShowOnlyRequestableDates) {
      const isRequestWindowEndBeforeFirstPublishedDate = moment(values.template.requestWindowEnd).isBefore(
        values.template.firstPublishedDate
      );

      if (user.isAdmin || user.isSuperAdmin) return moment([2099, 11, 31]);
      if (isRequestWindowEndBeforeFirstPublishedDate) return moment(values.template.lastPublishedDate);

      return moment(values.template.requestWindowEnd);
    }

    if (shouldDatePickerShowOnlyScheduleDates) {
      return moment(values.template.lastPublishedDate);
    }

    return moment();
  };

  const getMaxNumberOfSelectableDates = (): number | undefined => {
    if (vrmAssignment && vrmAssignment?.allotted && vrmAssignment?.granted) {
      return vrmAssignment?.allotted - vrmAssignment?.granted;
    }
    return undefined;
  };

  const dateData = {
    valueType: blockingAssignment ? DateValueType.Block : DateValueType.Multi,
    minDate: getMinDate(),
    maxDate: getMaxDate(),
    initialFocusedDate: getInitialFocusedDate(),
    limit: getMaxNumberOfSelectableDates(),
    blockData: !blockingAssignment
      ? null
      : {
          dayRange: getBlockDayRange(blockingAssignment.blockStartDay, blockingAssignment.blockDuration),
          startWeekDay: blockingAssignment.blockStartDay,
          duration: blockingAssignment.blockDuration ?? 0,
        },
    shiftData: vrmAssignment
      ? {
          firstPublishedDate: moment(values.template.firstPublishedDate),
          lastPublishedDate: moment(values.template.lastPublishedDate),
          requestWindowStart: moment(vrmAssignment.requestWindowStart),
          requestWindowEnd: moment(vrmAssignment.requestWindowEnd),
        }
      : user.isAdmin || user.isSuperAdmin
      ? null
      : {
          firstPublishedDate: moment(values.template.firstPublishedDate),
          lastPublishedDate: moment(values.template.lastPublishedDate),
          requestWindowStart: moment(values.template.requestWindowStart),
          requestWindowEnd: moment(values.template.requestWindowEnd),
        },
  };

  const shiftTimesData = {
    defaultStartTime,
    defaultEndTime,
    showResetButton: values.personnel.length > 0 && values.assignment.length > 0,
  };

  const noteData = {
    showField:
      (isFill && doesEverySelectedPersonnelAndAssignmentHaveFillNotePermissions) ||
      (!isFill && doesEverySelectedPersonnelAndAssignmentHaveRequestNotePermissions),
  };

  const addDemandIfNecessaryData = {
    showField: settings.isUserAdmin && isFill,
  };

  const fetchPersonnel = async () => {
    const url = `${ConfigHelper.host_lbapi}/personnel?dept_list=${values.template.departmentId}`;
    const { data: personnel } = await get<PersonnelAttributes[]>(url);
    return personnel ? transformPersonnel(settings, viewData, personnel) : [];
  };

  const fetchAssignments = async () => {
    const url = `${ConfigHelper.host_lbapi}/assignments/condensed/?template_list=${values.template.id}`;
    const { data: assignments } = await get<AssignmentAttributes[]>(url);
    return assignments ? transformAssignments(settings, viewData, values, assignments) : [];
  };

  const fetchVrmAssignment = async (vrmAssignmentId: number) => {
    const url = `${ConfigHelper.host_lbapi}/vrm/series/assignment/${vrmAssignmentId}/employee/${values.personnel[0].empId}`;
    const { data: vrmAssignment } = await get<VrmAssignmentAttributes>(url);
    return vrmAssignment ? cleanVrmAssignmentAttributes(vrmAssignment) : undefined;
  };

  const fetchReasons = async () => {
    const url = `${ConfigHelper.host_lbapi}/loareasons`;
    const { data: reasons } = await get<ReasonAttributes[]>(url);
    return reasons ? transformReasons(settings, values, reasons) : [];
  };

  const fetchLocations = async () => {
    const url = `${ConfigHelper.host_lbapi}/locations`;
    const { data: locations } = await get<LocationAttributes[]>(url);
    return locations ? transformLocations(settings, values, locations) : [];
  };

  const getNewPersonnelValue = (personnel: Personnel[]) => {
    return values.personnel.filter((p) => personnel.map((p) => p.empId).includes(p.empId));
  };

  const getNewAssignmentValue = (assignment: Assignment[]) => {
    return values.assignment.filter((a) => {
      return assignment
        .map((a) => a.templateToAssignIdMap[values.template.id])
        .includes(a.templateToAssignIdMap[values.template.id]);
    });
  };

  const getNewDateValue = () => {
    return blockingAssignment ? [] : values.date.filter((date) => date.isBetween(dateData.minDate, dateData.maxDate));
  };

  const getNewShiftStartAndEndValues = () => {
    return [defaultStartTime, defaultEndTime];
  };

  const getNewReasonValue = (reasons: Reason[]) => {
    return reasons.find((r) => r.loaReasonId === values.reason?.loaReasonId) ?? null;
  };

  const getNewLocationValue = (locations: Location[]) => {
    if (!locations.length) return undefined;

    const selectedAssignmentLocationsMap = values.assignment.map((a) => {
      const assignmentId = a.templateToAssignIdMap[values.template.id];
      return a.assignmentToLocationsMap[assignmentId];
    });

    const areAllAssignmentLocationsMultiple = selectedAssignmentLocationsMap.every((i) => i.isLocationMultiple);
    const defaultAssignmentLocations = selectedAssignmentLocationsMap
      .flatMap(({ locations }) => locations)
      .filter((l) => l.isDefault && locations.map((l) => l.locationId).includes(l.locationId));
    const defaultAssignmentLocationsIds = defaultAssignmentLocations.map((l) => l.locationId);

    const [defaultLocations, locationsRest] = _.partition(locations, (l) =>
      defaultAssignmentLocationsIds.includes(l.locationId)
    );

    return areAllAssignmentLocationsMultiple
      ? [
          ...defaultLocations,
          ...locationsRest.filter((location) =>
            Array.isArray(values.locations)
              ? values.locations.map((l) => l.locationId).includes(location.locationId)
              : values.locations?.locationId === location.locationId
          ),
        ]
      : defaultLocations[0] ??
          (Array.isArray(values.locations)
            ? null
            : locationsRest.find((l) => l.locationId === (values.locations as Location)?.locationId) ?? null);
  };

  /**
   * Everytime the template changes, fetch new personnel and re-set(?) them
   * based on the selected template.
   */
  React.useEffect(() => {
    (async () => {
      setPersonnelData((prevState) => ({ ...prevState, loading: true }));

      const personnel = await fetchPersonnel();
      const newPersonnelValue =
        initialMountOfPersonnel.current && personnel[0]?.length === 1
          ? personnel[0]
          : getNewPersonnelValue(personnel.flat());

      setFieldValue("personnel", newPersonnelValue);
      setPersonnelData({
        loading: false,
        options: [
          { label: "", options: personnel[0] },
          {
            label: "All Personnel",
            options: personnel[1],
          },
        ],
      });

      if (initialMountOfPersonnel.current) initialMountOfPersonnel.current = false;
    })();
  }, [values.template.id]);

  /**
   * Everytime the personnel changes, fetch new assignments and re-set(?) them
   * based on the selected personnel.
   */
  useUpdateEffect(() => {
    (async () => {
      setAssignmentData((prevState) => ({ ...prevState, loading: true }));

      const assignments = await fetchAssignments();
      const newAssignmentValue =
        initialMountOfPersonnel.current && assignments[0].length === 1
          ? assignments[0]
          : getNewAssignmentValue(assignments.flat());

      setFieldValue("assignment", newAssignmentValue);
      setAssignmentData({
        loading: false,
        options: [
          { label: "", options: assignments[0] },
          { label: "All Assignments", options: assignments[1] },
        ],
      });

      if (initialMountOfAssignments.current) initialMountOfAssignments.current = false;
    })();
  }, [values.personnel]);

  const getAssignmentsValue = (assignment: Assignment[]) => {
    const selectedAssignmentsAreNonVrm = assignment.every((item) => !item.isVrm);

    if (!selectedAssignmentsAreNonVrm) {
      if (assignment.length > 1) {
        const lastAssignmentSelected = assignment.at(-1);
        if (lastAssignmentSelected?.isVrm) {
          //Last vrm assignment
          return [lastAssignmentSelected];
        }
        return assignment.filter((item) => !item.isVrm);
      }
    }
    return assignment;
  };

  /**
   * Everytime the assignment changes, calculate the new date, shift start/end,
   * reason and location values.
   */
  useUpdateEffect(() => {
    (async () => {
      let newDateValue: FormValues["date"] = [],
        newShiftStartValue: FormValues["shiftStart"] = null,
        newShiftEndValue: FormValues["shiftEnd"] = null,
        newReasonValue: FormValues["reason"] = null,
        newCallOrderValue: FormValues["callOrder"] = undefined,
        newLocationValue: FormValues["locations"] = undefined;

      // Remove previous VRM assignment
      setVrmAssignment(undefined);

      const { assignment: assignments, personnel } = values;

      if (assignments.length > 0) {
        if (assignments.length === 1 && assignments[0].isVrm && personnel.length === 1) {
          const assignmentId = assignments[0].templateToAssignIdMap[values.template.id];
          const vrmAssignment = await fetchVrmAssignment(assignmentId);

          setVrmAssignment(vrmAssignment);
        }

        //Get all selected assignments ids from templateToAssignIdMap
        const assignmentIds = assignments.map((item) => Object.values(item.templateToAssignIdMap)).flat();
        //Get distinct selected assignments ids
        const assignmentIdsDistinct = [...new Set(assignmentIds)];
        const reasons = await fetchReasons();
        const locations = await fetchLocations();

        //If personnel is not selected
        if (personnel.length === 0) {
          setPersonnelData((prevState) => ({ ...prevState, loading: true }));

          const personnelResult = await fetchPersonnel();
          //Get only the eligible personnel(the intersection) for the selected assignments
          const eligiblePersonnel = personnelResult[0].filter((item) => {
            return assignmentIdsDistinct.every((id) => item.roles.includes(id));
          });

          const eligiblePersonnelNotInView = personnelResult[1].filter((item) =>
            assignmentIdsDistinct.every((id) => item.roles.includes(id))
          );

          //Set personnel options only for the eligible ones
          setPersonnelData({
            loading: false,
            options: [
              { label: "", options: eligiblePersonnel },
              { label: "All Personnel", options: eligiblePersonnelNotInView },
            ],
          });

          if (initialMountOfPersonnel.current) initialMountOfPersonnel.current = false;
        }

        if (assignments.length === 1) [newShiftStartValue, newShiftEndValue] = getNewShiftStartAndEndValues();
        newDateValue = getNewDateValue();
        newReasonValue = getNewReasonValue(reasons);
        newLocationValue = getNewLocationValue(locations);
        setReasonData({ options: reasons });
        setLocationData({ options: locations, disableManySelect: !Array.isArray(newLocationValue) });
      } else {
        setReasonData({ options: [] });
        setLocationData({ options: [], disableManySelect: true });
      }

      // Call Order
      // If the LD flag is false, call order visibility will always be false
      if (LDFlags[LDFlagEnums.Lv6192ShowAssignmentCallOrder]) {
        if (assignments.length > 0) {
          // fill in the options (1-10 and none)
          setCallOrderOptions(CallOrderData);

          // check if we have only 1 assignment selected
          if (assignments.length === 1) {
            const selectedAssignment = assignments[0];
            // if the selected assignment has the call order value set (1-10)
            if (selectedAssignment.callOrder) {
              // show the select box on the form
              setCallOrderVisible(true);

              // set the call order value to the call order of the assignment
              newCallOrderValue = CallOrderData.find((item) => item.value === selectedAssignment.callOrder);
            }
          } else {
            // check if any of the selected assignments has call order set to none
            const hasNoneValue = assignments.some((item) => !item.callOrder);
            if (hasNoneValue) {
              // do not show the select box on the form
              setCallOrderVisible(false);
              // call order value removed
              newCallOrderValue = undefined;
            } else {
              // show the select box on the form
              setCallOrderVisible(true);

              // check if all the selected assignments have the same value for call order
              const allHaveTheSameValue = assignments.every((item) => item.callOrder === assignments[0].callOrder);
              if (allHaveTheSameValue) {
                // set call order to the same value as in all assignments
                newCallOrderValue = CallOrderData.find((item) => item.value === assignments[0].callOrder);
              } else {
                // set the call order value to default if call order values of the selected assignments are different
                newCallOrderValue = keepDefaultsOption;

                // add to options the default option if not present
                setCallOrderOptions((prevState) => {
                  if (!prevState.find((item) => item.value === "default")) {
                    return [...prevState, keepDefaultsOption];
                  }
                  return prevState;
                });
              }
            }
          }
        }
      }

      setValues((prevValues) => ({
        template: prevValues.template,
        personnel: prevValues.personnel,
        assignment: getAssignmentsValue(prevValues.assignment),
        date: newDateValue,
        shiftStart: newShiftStartValue,
        shiftEnd: newShiftEndValue,
        reason: newReasonValue,
        locations: newLocationValue,
        note: prevValues.note,
        addDemandIfNecessary: prevValues.addDemandIfNecessary,
        callOrder: newCallOrderValue,
      }));
    })();
  }, [values.assignment]);

  return {
    personnelData,
    assignmentData,
    dateData,
    reasonData,
    locationData,
    shiftTimesData,
    noteData,
    addDemandIfNecessaryData,
    vrmAssignment,
    callOrderOptions,
    callOrderVisible,
  };
}
