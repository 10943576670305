import { SettingsContext } from "@/viewer/ui/modules/common/types/context";

export interface ReasonAttributes {
  created_at: Date;
  updated_at: Date;
  name: string;
  description: string;
  loa_reason_id: number;
}

export interface Reason {
  createdAt: Date;
  updatedAt: Date;
  name: string;
  description: string;
  loaReasonId: number;
}

export const cleanReasonAttributes = (settings: SettingsContext, attrs: ReasonAttributes): Reason => {
  return {
    createdAt: attrs.created_at,
    updatedAt: attrs.updated_at,
    name: attrs.name,
    description: attrs.description,
    loaReasonId: attrs.loa_reason_id,
  };
};
