import React from "react";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";

import toastActions from "@/common/components/toast/actions";
import { FormValues } from "./EditTallyCountModal";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { cleanTallyAttributes, Tally } from "@/viewer/types/domain/tally";
import { deleteTally, fetchTallies } from "./helpers";

import Toast, { ToastStatus } from "@/common/components/toast/Toast";
import { FetchReport, TriggerRedraw } from "@/common/utils";

export default function useFormData(slotQuest: SlotOrRequest): {
  loading: boolean;
  handleResetTally: (tally: Tally) => void;
} {
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext<FormValues>();
  const [loading, setLoading] = React.useState(false);

  const handleDeleteTally = async (tally: Tally) => {
    const response = await deleteTally(slotQuest, tally);

    if (response.error) {
      dispatch(
        toastActions.open(<Toast status={ToastStatus.Error}>{tally.name} is already at its default value.</Toast>)
      );
    } else {
      dispatch(
        toastActions.open(<Toast status={ToastStatus.Success}>{tally.name} successfully reset to default value.</Toast>)
      );
    }
  };

  const handleFetchTallies = async () => {
    const response = await fetchTallies(slotQuest);

    if (response.error) {
      dispatch(
        toastActions.open(
          <Toast status={ToastStatus.Error}>
            {response.error.message || "Could not fetch tally counts. Please refresh and try again."}
          </Toast>
        )
      );
    } else {
      setFieldValue(
        "tallies",
        response.data ? response.data.map((t) => ({ ...cleanTallyAttributes(t), initialValue: t.Value })) : []
      );
    }
  };

  const handleResetTally = async (tally: Tally) => {
    await handleDeleteTally(tally);
    await handleFetchTallies();
    await FetchReport();
    TriggerRedraw();
  };

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      await handleFetchTallies();
      setLoading(false);
    })();
  }, []);

  return {
    loading,
    handleResetTally,
  };
}
