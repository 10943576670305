/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { ViewData } from "@/viewer/types/viewdata";

import usePermissions, { defaultPermissionsPlaceholder } from "@/viewer/ui/modules/common/hooks/usePermissions";
import ExportDialog from "@/_lib/ui/modules/topbar/components/ExportDialog";

import { UpdateAssignmentFilterIds, UpdatePersonnelFilterIds } from "@/_lib/ui/modules/topbar/actions";
import PersonalFilters from "@/_lib/ui/modules/topbar/components/PersonalFilters";
import AddShiftButton from "@/_lib/ui/modules/topbar/components/AddShiftButton";
import AdHocSettings from "@/_lib/ui/modules/topbar/components/AdHocSettings";
import FilterByList from "@/_lib/ui/modules/topbar/components/FilterByList";
import { isVisible, isSelectable } from "@/viewer/utils/domain/perssignment";
import PrintModal from "@/components/PrintModal";
import { AppState } from "@/modules/reducer";
import myWindow, { getDepartment } from "@/_lib/utils/window";
import { DepartmentsById } from "@/viewer/types/domain/department";
import { useFlags } from "launchdarkly-react-client-sdk";
import { LDFlagEnums } from "@/_lib/constants/LDFlagEnums";
import ActivityFeedIcon from "./components/ActivityFeedIcon";
import useGroupsData from "@/viewer/ui/modules/common/hooks/useGroupsData";
import { GroupOption } from "@/viewer/ui/modules/common/types/groups.types";

interface Props {
  settings: SettingsContext;
  setUIContext: (newUI: Partial<UIContext>) => void;
  ui: UIContext;
  viewData: ViewData;
  departmentsById: DepartmentsById;
  printRef?: React.MutableRefObject<null>;
}

const viewsWithoutDoubleFiltering = ["list", "block", "gantt"];

const viewShouldHaveDoubleFiltering = (viewId: string, layout: string): boolean => {
  // ME Views should not have filtering enabled.
  if (viewId === "me") {
    return false;
  }
  // Only list and gantt views can be "daily", so this will prevent filtering on them as well.
  return viewsWithoutDoubleFiltering.indexOf(layout) === -1;
};

const shouldShowAssignmentFilter = (settings: SettingsContext, ui: UIContext): boolean => {
  const { viewId, layout } = settings;
  const { leftColumnType } = ui;
  const doubleFilteringEnabled = viewShouldHaveDoubleFiltering(viewId as string, layout);

  if (doubleFilteringEnabled) {
    return true;
  } else if (layout !== "list") {
    return leftColumnType === "assignment";
  }

  return false;
};

const shouldShowPersonnelFilter = (settings: SettingsContext, ui: UIContext): boolean => {
  const { viewId, layout } = settings;
  const { leftColumnType } = ui;
  const doubleFilteringEnabled = viewShouldHaveDoubleFiltering(viewId as string, layout);

  if (doubleFilteringEnabled) {
    return true;
  } else if (layout !== "list") {
    return leftColumnType === "personnel";
  }

  return false;
};

const openUserDialog = () => {
  myWindow.LbsAppData.AppContext.openDialog({
    type: "ext-menu",
  });
};

const TopBarContainer = (props: Props): JSX.Element => {
  const { LbsAppData } = window as any;
  const { settings, viewData, setUIContext, ui, printRef, departmentsById } = props;
  const [groupOptions, setGroupOptions] = useState<GroupOption[]>([]);
  const [personnelMenuOpen, setPersonnelMenuOpen] = useState<boolean>(false);
  const [assignmentsMenuOpen, setAssignmentsMenuOpen] = useState<boolean>(false);

  const { getPersonnelGroups, getAssignmentsGroups } = useGroupsData();
  const LDFlags = useFlags();

  const { assignments, personnel, filters } = viewData;
  const { app, viewId } = settings;
  const { showActivityFeed } = ui;

  const showAssignmentFilter = shouldShowAssignmentFilter(settings, ui);
  const showPersonnelFilter = shouldShowPersonnelFilter(settings, ui);
  const showPersonalFilters = (showPersonnelFilter || showAssignmentFilter) && settings.personalFiltersEnabled;
  const isViewer = app === "viewer";
  const isLogin = app === "login";
  const isReports = app === "reports";

  const {
    canIChangeAnyonesSchedule,
    canIChangeAnyonesRequests,
    canIApproveDenyAnyoneElsesRequest,
    canIApproveDenyAnyoneElsesSchedule,
  } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    isViewer || isLogin ? usePermissions() : defaultPermissionsPlaceholder;

  const showAddShiftButton = canIChangeAnyonesSchedule || canIChangeAnyonesRequests;
  const showActivityFeedIcon =
    (canIApproveDenyAnyoneElsesRequest || canIApproveDenyAnyoneElsesSchedule) &&
    LDFlags[LDFlagEnums.Lv5467ActivityFeedShowActivityFeedButton];

  // Department of the view (with a single department)
  const department = getDepartment();

  // We check typeof department because it's value (the id of the department) can be 0
  const showGroupDropdown = LDFlags[LDFlagEnums.LV7216FilterByGroup] && typeof department === "number";

  const handlePersonnelGroups = async () => {
    if (showGroupDropdown) {
      const data = await getPersonnelGroups(department);
      const options: GroupOption[] = data.map((item) => {
        return {
          value: item.group_id,
          label: item.name,
          description: item.description,
          dept_id: item.dept_id,
          members: item.members,
        };
      });
      setGroupOptions(options);
    }
  };

  const handleAssignmentGroups = async () => {
    if (showGroupDropdown) {
      const data = await getAssignmentsGroups(department);
      const options: GroupOption[] = data.map((item) => {
        return {
          value: item.group_id,
          label: item.name,
          description: item.description,
          dept_id: item.dept_id,
          members: item.members,
        };
      });
      setGroupOptions(options);
    }
  };

  useEffect(() => {
    if (assignmentsMenuOpen) {
      (async () => await handleAssignmentGroups())();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentsMenuOpen]);

  useEffect(() => {
    if (personnelMenuOpen) {
      (async () => await handlePersonnelGroups())();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personnelMenuOpen]);

  const { assignmentFilterIds, personnelFilterIds } = useSelector((state: AppState) => state.filterList);
  const { searchTerms } = useSelector((state: AppState) => state.list);
  const dispatch = useDispatch();

  const assignmentItems = React.useMemo(
    () =>
      assignments
        .filter((a) => isVisible(a, ui, filters))
        .map((a) => ({ id: a.assignStructureId, display_name: a.compactOrDisplayName })),
    [assignments, ui, filters]
  );

  const assignmentListItems = React.useMemo(
    () =>
      assignments
        .filter((assignment) => isSelectable(assignment, ui))
        .map((a) => ({ id: a.assignStructureId, display_name: a.compactOrDisplayName })),
    [assignments, ui]
  );

  const assignmentIds = React.useMemo(() => assignmentListItems.map((a) => a.id), [assignmentListItems]);

  const personnelItems = React.useMemo(
    () =>
      personnel
        .filter((p) => isVisible(p, ui, filters) && p.scheduled)
        .map((p) => ({ id: p.empId, display_name: p.compactOrDisplayName })),
    [personnel, ui, filters]
  );

  const personnelListItems = React.useMemo(
    () =>
      personnel.filter((p) => isSelectable(p, ui)).map((p) => ({ id: p.empId, display_name: p.compactOrDisplayName })),
    [personnel, ui]
  );

  const personnelIds = React.useMemo(() => personnelListItems.map((p) => p.id), [personnelListItems]);

  const showAllAssignments = () => {
    LbsAppData.Assignments.unfilterAll();
    dispatch(UpdateAssignmentFilterIds(assignmentIds));
  };

  const showAllPersonnel = () => {
    LbsAppData.Personnel.unfilterAll();
    dispatch(UpdatePersonnelFilterIds(personnelIds));
  };

  const filterByAssignments = (filters: (string | number)[]) => {
    LbsAppData.Assignments.filterAssignments(filters);
  };

  const filterByPersonnel = (filters: (string | number)[]) => {
    LbsAppData.Personnel.filterPersonnel(filters);
  };

  const shouldShowAdHocSetting = (viewId: string | unknown) => {
    return viewId !== "me";
  };

  const getExcludedAssignmentsIdsFromSelectedItems = () => {
    return assignmentListItems
      .filter(({ id }) => !assignmentItems.find(({ id: selectedId }) => id === selectedId))
      .map(({ id }) => id);
  };

  const getExcludedPersonnelIdsFromSelectedItems = () => {
    return personnelListItems
      .filter(({ id }) => !personnelItems.find(({ id: selectedId }) => id === selectedId))
      .map(({ id }) => id);
  };

  const onActivity = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setUIContext({
      showActivityFeed: !showActivityFeed,
    });
  };

  return (
    <div className="flex-between flex-1">
      {isViewer ? (
        <>
          <div className="flex">
            {shouldShowAdHocSetting(viewId) && (
              <AdHocSettings ui={props.ui} setUIContext={setUIContext} settings={settings} />
            )}
            {showPersonnelFilter && (
              <FilterByList
                emitterEvent={"personnelFilterChange"}
                filter={filterByPersonnel}
                filterIds={personnelFilterIds}
                filters={personnelListItems}
                listName={"Personnel"}
                showAllItems={showAllPersonnel}
                showGroupDropdown={showGroupDropdown}
                setPersonnelMenuOpen={setPersonnelMenuOpen}
                setAssignmentsMenuOpen={setAssignmentsMenuOpen}
                groupOptions={groupOptions}
              />
            )}
            {showAssignmentFilter && (
              <FilterByList
                emitterEvent={"assignmentsFilterChange"}
                filter={filterByAssignments}
                filterIds={assignmentFilterIds}
                filters={assignmentListItems}
                listName={"Assignments"}
                showAllItems={showAllAssignments}
                showGroupDropdown={showGroupDropdown}
                setPersonnelMenuOpen={setPersonnelMenuOpen}
                setAssignmentsMenuOpen={setAssignmentsMenuOpen}
                groupOptions={groupOptions}
              />
            )}
            {showPersonalFilters && (
              <PersonalFilters
                assignmentFilters={assignmentIds}
                filterByAssignments={filterByAssignments}
                filterByPersonnel={filterByPersonnel}
                personnelFilters={personnelIds}
                selectedAssignmentFilters={getExcludedAssignmentsIdsFromSelectedItems()}
                selectedPersonnelFilters={getExcludedPersonnelIdsFromSelectedItems()}
              />
            )}
          </div>
        </>
      ) : (
        <div className="flex-between flex-1" />
      )}
      <div className="flex">
        {showAddShiftButton && <AddShiftButton />}
        {showActivityFeedIcon && <ActivityFeedIcon onClick={onActivity} />}
        {isViewer && (
          <>
            <ExportDialog searchTerms={searchTerms} settings={settings} ui={ui} viewData={viewData} />
            {printRef && (
              <PrintModal
                printRef={printRef}
                settings={settings}
                ui={ui}
                viewData={viewData}
                departmentsById={departmentsById}
              />
            )}
          </>
        )}
        {isReports && showActivityFeedIcon && <ActivityFeedIcon onClick={onActivity} />}
        <button className="top-bar-btn btn-icon" onClick={openUserDialog} onTouchEnd={openUserDialog}>
          <div className="icon">
            <i className="fa fa-user" data-cy="TBUserBtn" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default TopBarContainer;
