import moment from "moment";
import _ from "underscore";

import { BANDAID_MRC_ID } from "@/common/constants";
import { BlockDay } from "@/common/components/fields/date-field/types";
import { FormValues } from "@/viewer/ui/modules/modals/add-shift/AddShiftModal";
import { Template, TemplatesById } from "@/viewer/types/domain/template";
import { SettingsContext } from "@/viewer/ui/modules/common/types/context";
import { ViewData } from "@/viewer/types/viewdata";
import { getChosenTimezone } from "@/viewer/utils/timezones";
import { cleanPersonnelAttributes, Personnel, PersonnelAttributes } from "@/viewer/types/domain/personnel";
import { cleanAssignmentAttributes, Assignment, AssignmentAttributes } from "@/viewer/types/domain/assignment";
import { cleanReasonAttributes, Reason, ReasonAttributes } from "@/viewer/types/domain/reason";
import { cleanLocationAttributes, Location, LocationAttributes } from "@/viewer/types/domain/location";
import {
  CanIChangeAssignmentOnRequestsForTemplate,
  CanIChangeAssignmentOnScheduleForTemplate,
  CanIChangeTemplateRequestData,
  CanIChangeTemplateScheduleData,
  CanIChangeThisPersonsRequests,
  CanIChangeThisPersonsSchedule,
  TimezoneConverter,
} from "@/common/utils";

interface PayloadItem {
  type: "fill" | "new";
  template_id: Template["id"];
  emp_id: Personnel["empId"];
  assign_structure_id: Assignment["assignStructureId"];
  assign_name: Assignment["compactOrDisplayName"];
  assign_id: number;
  date: string;
  start_date: null | string;
  end_date: null | string;
  note: null | string;
  auto_add_demand: boolean;
  loa_reason_id?: Reason["loaReasonId"];
  location_ids?: Location["locationId"][];
  allow_force_save?: boolean;
  call_order?: number | null;
}

enum BlockDuration {
  TWO_DAYS = 2,
  THREE_DAYS = 3,
  FOUR_DAYS = 4,
  FIVE_DAYS = 5,
  SIX_DAYS = 6,
  SEVEN_DAYS = 7,
}

enum BlockStartDay {
  ANYDAY = -1,
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

/**
 * Sorts a list alphabetically.
 */
const alphabeticalComparator = (a: string, b: string) => {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

/**
 * Creates an array of the dates, personnel and assignments.
 */
export const permutateTargets = (
  values: FormValues
): { personnel: Personnel; assignment: Assignment; date: moment.Moment }[] => {
  const items = [];

  for (let i = 0; i < values.date.length; i++) {
    for (let j = 0; j < values.personnel.length; j++) {
      for (let k = 0; k < values.assignment.length; k++) {
        items.push({
          personnel: values.personnel[j],
          assignment: values.assignment[k],
          date: values.date[i],
        });
      }
    }
  }

  return items;
};

export const isInViewDefinition = (
  startDate: Date,
  stopDate: Date,
  itemDate: string,
  assignId: number,
  empId: number,
  viewAssignments: number[],
  viewPersonnel: (number | string)[]
): boolean => {
  const momentRequestDate = moment(itemDate);
  const momentStartDate = moment(startDate);
  const momentStopDate = moment(stopDate);

  return (
    momentRequestDate.isSameOrAfter(momentStartDate) &&
    momentRequestDate.isSameOrBefore(momentStopDate) &&
    viewAssignments.includes(assignId) &&
    viewPersonnel.includes(empId)
  );
};

/**
 * Creates an array of all the days within a block, starting
 * from the beginning of the block, to the end.
 */
export const getBlockDayRange = (blockStartDay: BlockDay, blockDuration: null | number): BlockDay[] => {
  const startDay = moment().day(blockStartDay);
  return _.range(0, blockDuration ?? 0).reduce<BlockDay[]>((acc, i) => {
    const date = startDay.clone().add(i, "days");
    return [...acc, date.day() as BlockDay];
  }, []);
};

/**
 * Gets the default shift times for the first assignment selected.
 */
export const getAssignmentDefaultTimes = (values: FormValues): [null | moment.Moment, null | moment.Moment] => {
  if (values.assignment.length !== 1) return [null, null];

  const newShiftTimeValue = values.assignment[0].templateToDefaultTimesMap[values.template.id];
  if (!newShiftTimeValue || newShiftTimeValue === "NONE") return [null, null];

  const tz = getChosenTimezone();
  const [startTime, endTime] = newShiftTimeValue.split("~") ?? [];
  return [moment(startTime, "hh:mm A").tz(tz), moment(endTime, "hh:mm A").tz(tz)];
};

/**
 * Filter / sort down templates for the select field.
 */
export const transformTemplates = (templatesById: TemplatesById): Template[] => {
  return Object.values(templatesById)
    .filter((t) => t.departmentId !== BANDAID_MRC_ID)
    .filter((t) => CanIChangeTemplateScheduleData(t) || CanIChangeTemplateRequestData(t))
    .sort((a, b) => a.name.localeCompare(b.name));
};

/**
 * Filter / sort down personnel for the select field.
 */
export const transformPersonnel = (
  settings: SettingsContext,
  viewData: ViewData,
  personnelAttributes: PersonnelAttributes[]
): Personnel[][] => {
  const personnelInSelectedTemplate = personnelAttributes
    .map((p) => cleanPersonnelAttributes(settings, p))
    .filter((p) => !p.expired && p.scheduled)
    .filter((p) => CanIChangeThisPersonsSchedule(p) || CanIChangeThisPersonsRequests(p))
    .sort((a, b) => a.compactOrDisplayName.localeCompare(b.compactOrDisplayName));

  const personnelInCurrentViewAndSelectedTemplate = viewData.personnel.filter((p) => {
    const personnelInSelectedTemplateIds = personnelInSelectedTemplate.map((p) => p.empId);
    return personnelInSelectedTemplateIds.includes(p.empId);
  });

  const filteredPersonnelInCurrentViewAndSelectedTemplate = personnelInCurrentViewAndSelectedTemplate.filter((p) => {
    const filteredPersonnelIds = Object.keys(viewData.filters.filteredPersonnel).map((id) => id);
    return filteredPersonnelIds.length && !filteredPersonnelIds.includes(p.empId.toString());
  });

  const doesCurrentViewContainThisPersonnel = (personnel: Personnel) => {
    return (
      filteredPersonnelInCurrentViewAndSelectedTemplate.length
        ? filteredPersonnelInCurrentViewAndSelectedTemplate
        : personnelInCurrentViewAndSelectedTemplate
    )
      .map((p) => p.empId)
      .includes(personnel.empId);
  };

  const excludedPersonnel: Personnel[] = [];
  const includedPersonnel: Personnel[] = personnelInSelectedTemplate.filter((p) => {
    if (doesCurrentViewContainThisPersonnel(p)) return true;
    else {
      excludedPersonnel.push(p);

      return false;
    }
  });

  return [includedPersonnel, excludedPersonnel];
};

/**
 * Filter / sort down assignments for the select field.
 */
export const transformAssignments = (
  settings: SettingsContext,
  viewData: ViewData,
  values: FormValues,
  assignmentAttributes: AssignmentAttributes[]
): Assignment[][] => {
  const isAssignmentOnTemplate = (assignment: Assignment) => {
    return assignment.templates.some((t) => t === values.template.id);
  };

  const isAssignmentInPersonnelRoles = (assignment: Assignment) => {
    if (!values.personnel.length) return true;
    let roleCandidates = values.personnel[0].roles || [];
    for (let i = 1; i < values.personnel.length; i++) {
      roleCandidates = _.intersection(roleCandidates, values.personnel[i].roles);
    }
    return roleCandidates.includes(assignment.templateToAssignIdMap[values.template.id]);
  };

  const assignmentsInSelectedTemplate = assignmentAttributes
    .map((a) => cleanAssignmentAttributes(settings, a))
    .filter((a) => !a.expired)
    .filter((a) => isAssignmentOnTemplate(a) && isAssignmentInPersonnelRoles(a))
    .filter(
      (a) =>
        CanIChangeAssignmentOnScheduleForTemplate(a, values.template) ||
        CanIChangeAssignmentOnRequestsForTemplate(a, values.template)
    )
    .sort((a, b) => a.compactOrDisplayName.localeCompare(b.compactOrDisplayName));

  const assignmentsInCurrentViewAndSelectedTemplate = viewData.assignments.filter((a) => {
    const assignmentsInSelectedTemplateIds = assignmentsInSelectedTemplate.map(
      (a) => a.templateToAssignIdMap[values.template.id]
    );
    return assignmentsInSelectedTemplateIds.includes(a.templateToAssignIdMap[values.template.id]);
  });

  const filteredAssignmentsInCurrentViewAndSelectedTemplate = assignmentsInCurrentViewAndSelectedTemplate.filter(
    (a) => {
      const filteredAssignmentIds = Object.keys(viewData.filters.filteredAssignments).map((id) => id);
      return filteredAssignmentIds.length && !filteredAssignmentIds.includes(a.assignStructureId);
    }
  );

  const doesCurrentViewContainThisAssignment = (assignment: Assignment) => {
    return (
      filteredAssignmentsInCurrentViewAndSelectedTemplate.length
        ? filteredAssignmentsInCurrentViewAndSelectedTemplate
        : assignmentsInCurrentViewAndSelectedTemplate
    )
      .map((p) => p.templateToAssignIdMap[values.template.id])
      .includes(assignment.templateToAssignIdMap[values.template.id]);
  };

  return [
    assignmentsInSelectedTemplate.filter((a) => doesCurrentViewContainThisAssignment(a)),
    assignmentsInSelectedTemplate.filter((a) => !doesCurrentViewContainThisAssignment(a)),
  ];
};

/**
 * Filter / sort down reasons for the select field.
 */
export const transformReasons = (
  settings: SettingsContext,
  values: FormValues,
  reasonAttributes: ReasonAttributes[]
): Reason[] => {
  const isReasonInAssignmentLoaReasons = (reason: Reason) => {
    let reasonCandidates = values.assignment[0].loaReasons;
    for (let i = 1; i < values.assignment.length; i++) {
      reasonCandidates = _.intersection(reasonCandidates, values.assignment[i].loaReasons);
    }
    return reasonCandidates.includes(reason.loaReasonId);
  };

  return reasonAttributes
    .map((r) => cleanReasonAttributes(settings, r))
    .filter((r) => isReasonInAssignmentLoaReasons(r))
    .sort((l1, l2) => alphabeticalComparator(l1.name, l2.name));
};

/**
 * Filter / sort down locations for the select field.
 */
export const transformLocations = (
  settings: SettingsContext,
  values: FormValues,
  locationAttributes: LocationAttributes[]
): Location[] => {
  const isLocationInAssignmentLocations = (location: Location) => {
    const assignmentId = values.assignment[0].templateToAssignIdMap[values.template.id];
    let locationCandidates = values.assignment[0].assignmentToLocationsMap[assignmentId].locations.map(
      (l) => l.locationId
    );

    for (let i = 1; i < values.assignment.length; i++) {
      const assignmentId = values.assignment[i].templateToAssignIdMap[values.template.id];
      locationCandidates = _.intersection(
        locationCandidates,
        values.assignment[i].assignmentToLocationsMap[assignmentId].locations.map((l) => l.locationId)
      );
    }

    return locationCandidates.includes(location.locationId);
  };

  const areAllAssignmentLocationsEditable = values.assignment.every(
    (a) => a.assignmentToLocationsMap[a.templateToAssignIdMap[values.template.id]].isLocationEditable
  );

  return !areAllAssignmentLocationsEditable
    ? []
    : locationAttributes
        .map((l) => cleanLocationAttributes(settings, l))
        .filter((l) => isLocationInAssignmentLocations(l))
        .sort((l1, l2) => alphabeticalComparator(l1.name, l2.name));
};

/**
 * Prepares the form values for submission to the API.
 */
export const createPayload = (values: FormValues, type: "fill" | "new", forceThrough?: boolean): PayloadItem[] => {
  const convertTimes = (date: moment.Moment) => {
    const [defaultStartTime, defaultEndTime] = getAssignmentDefaultTimes(values);
    const usesDefaultTimes =
      moment.isMoment(defaultStartTime) &&
      moment.isMoment(defaultEndTime) &&
      moment.isMoment(values.shiftStart) &&
      moment.isMoment(values.shiftEnd) &&
      values.shiftStart.isSame(defaultStartTime) &&
      values.shiftEnd.isSame(defaultEndTime);

    if (values.shiftStart && values.shiftEnd && !usesDefaultTimes) {
      const startDate = date.format("YYYY-MM-DD") + "T" + values.shiftStart.format("HH:mm:ss");
      let endDate = date.format("YYYY-MM-DD") + "T" + values.shiftEnd.format("HH:mm:ss");
      const startDateMoment = moment(startDate);
      const endDateMoment = moment(endDate);

      // Add a day if endDate is before the startDate (or exactly equal to)
      if (endDateMoment.isBefore(startDateMoment) || endDateMoment.isSame(startDateMoment)) {
        endDate = endDateMoment.clone().add(1, "days").format("YYYY-MM-DDTHH:mm:ss");
      }

      const startTimeTimezoneConverted = TimezoneConverter(startDate, true);
      const stopTimeTimezoneConverted = TimezoneConverter(endDate, true);

      return {
        date: startTimeTimezoneConverted.format("YYYY-MM-DDT00:00:00"),
        start_date: startTimeTimezoneConverted.format("YYYY-MM-DDTHH:mm:ss"),
        end_date: stopTimeTimezoneConverted.format("YYYY-MM-DDTHH:mm:ss"),
      };
    }

    return {
      date: date.format("YYYY-MM-DDT00:00:00"),
      start_date: null,
      end_date: null,
    };
  };

  return permutateTargets(values).map((item) => {
    const { date, start_date, end_date } = convertTimes(item.date);

    const payloadItem: PayloadItem = {
      type,
      template_id: values.template.id,
      emp_id: item.personnel.empId,
      assign_id: item.assignment.templateToAssignIdMap[values.template.id],
      assign_structure_id:
        (item.assignment.structureMappingLookup[item.assignment.structures[0]] as string) ||
        (item.assignment.structures[0] as unknown as string),
      assign_name: item.assignment.compactOrDisplayName,
      date,
      start_date,
      end_date,
      note: values.note || null,
      auto_add_demand: values.addDemandIfNecessary,
    };

    if (values.reason) {
      payloadItem.loa_reason_id = values.reason.loaReasonId;
    }

    if (values.locations) {
      payloadItem.location_ids = Array.isArray(values.locations)
        ? values.locations.map((l) => l.locationId)
        : [values.locations.locationId];
    }

    // This gets set when the user accepts the API warning popup.
    if (forceThrough) payloadItem.allow_force_save = true;

    if (type === "fill" && values.callOrder) {
      if (values.callOrder.value && values.callOrder.value !== "default") {
        payloadItem.call_order = values.callOrder.value !== "none" ? Number(values.callOrder.value) : null;
      }
    }

    return payloadItem;
  });
};

/**
 * Returns the block length as string from the numeric value.
 */
export const getBlockLength = (value: number | null): string => {
  switch (value) {
    case BlockDuration.TWO_DAYS:
      return "2 Days";
    case BlockDuration.THREE_DAYS:
      return "3 Days";
    case BlockDuration.FOUR_DAYS:
      return "4 Days";
    case BlockDuration.FIVE_DAYS:
      return "5 Days";
    case BlockDuration.SIX_DAYS:
      return "6 Days";
    case BlockDuration.SEVEN_DAYS:
      return "7 Days";
    default:
      return "-";
  }
};

/**
 * Returns the block start day as string from the numeric value.
 */
export const getBlockStartDay = (value: number | null): string => {
  switch (value) {
    case BlockStartDay.ANYDAY:
      return "Anyday";
    case BlockStartDay.SUNDAY:
      return "Sunday";
    case BlockStartDay.MONDAY:
      return "Monday";
    case BlockStartDay.TUESDAY:
      return "Tuesday";
    case BlockStartDay.WEDNESDAY:
      return "Wednesday";
    case BlockStartDay.THURSDAY:
      return "Thursday";
    case BlockStartDay.FRIDAY:
      return "Friday";
    case BlockStartDay.SATURDAY:
      return "Saturday";
    default:
      return "-";
  }
};
