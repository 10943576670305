"use strict";

// scss requires
const React = require("react/addons");
const ReactDOM = require("react-dom");
require("./_styles.scss");

var AssignmentCollection = require("_lib/data/collections/NakedAssignmentCollection.js");
var Subscription = require("login/data/models/Subscription.js");

var SubscribeDialog = React.createClass({
  getInitialState: function () {
    return {
      loadingAssignments: false,
      email: this.props.dialogObject.email,

      useExcludeAssigns: this.props.dialogObject.subscription
        ? this.props.dialogObject.subscription.get("use_assignment_exclude_list")
        : false,

      excludeAssignIDs: this.props.dialogObject.subscription
        ? this.props.dialogObject.subscription.get("exclude_assigns")
        : [],

      formErrors: {},
    };
  },

  componentDidMount: function () {
    var that = this;
    // lame hack...for whatever reason...focus on these dialogs is hard..something is overridding them during the initial render
    window.setTimeout(function () {
      ReactDOM.findDOMNode(that.refs.emailInput).focus();
    }, 200);
  },

  _checkAllAssign: function () {
    this.setState({ useExcludeAssigns: false });
  },

  _checkExcludeAssign: function () {
    this.setState({ useExcludeAssigns: true });
  },

  _changeEmail: function (e) {
    var newFormErrors = this.state.formErrors;
    delete newFormErrors.email;
    this.setState({
      email: e.currentTarget.value,
      formErrors: newFormErrors,
    });
  },

  _submit: function (e) {
    var that = this;

    if (this._validate()) {
      // bring it all together on a subscription model
      var subscription = new Subscription({
        id: this.props.dialogObject.subscription ? this.props.dialogObject.subscription.id : undefined,
        emp_id: this.props.dialogObject.empID,
        email: this.state.email,
        use_assignment_exclude_list: this.state.useExcludeAssigns,
        exclude_assigns: this.state.excludeAssignIDs,
      });

      // the rest just leave as is on the subscription...it'll get ignored currently by the api
      subscription.save(null, {
        success: function () {
          that._close();
        },
      });
    }

    // prevent form driven redirect
    e.preventDefault();
  },
  _close: function (e) {
    // want to hook this into an update on the dashboard...do it here
    if (this.props.dialogObject.closingCallback) {
      this.props.dialogObject.closingCallback();
    }
    this.props.close();
  },
  _editExclude: function (e) {
    // setup the collection to be used for this adhoc
    var that = this;

    var collection = new AssignmentCollection([], {
      empID: that.props.dialogObject.empID,
      comparator: function (a, b) {
        var orderA = a.attributes.display_name.toLowerCase();
        var orderB = b.attributes.display_name.toLowerCase();

        if (orderA == orderB) {
          return 0;
        } else if (orderA < orderB) {
          return -1;
        } else {
          return 1;
        }
      },
    });
    // some feedback to the user
    this.setState({ loadingAssignments: true });

    collection.fetch({
      success: function (collection) {
        that.setState({ loadingAssignments: false });
        that._editExcludePostFetch(collection);
      },
    });
  },

  _editExcludePostFetch: function (collection) {
    // some weird logic here, cause the assignment collection id's are not assignment id's...they are structures...
    // l-b gonna l-b here...will translate the result later down the line when it's submitted
    var that = this;

    var allNames = collection.pluck("assign_structure_id");

    // Create a dictionary to link IDs & names
    let assignIDToName = {};
    collection.models.forEach(function (model) {
      let idProperties = model.get("template_to_assign_id_map");

      // Currently we can't do "for (x of obj)", but once we can, this can be simplified
      for (const index in idProperties) {
        let id = idProperties[index];
        assignIDToName[id] = model.id; // model.id is actually the name
      }
    });

    let excludedNames = this.state.excludeAssignIDs.map(function (id) {
      return assignIDToName[id];
    });

    // Pull out the models that aren't excluded
    let selectedAssignments = collection.models.filter(function (m) {
      return excludedNames.indexOf(m.id) < 0;
    });

    window.LbsAppData.AppContext.openDialog({
      type: "list",
      unfetched: false,
      multiselect: true,
      collection: collection,
      result: selectedAssignments,
      title: "Selected Assignments",
      property: "display_name",

      action: function () {
        // gets fired after the user hits close on the list dialog for multi select

        let selectedNames = selectedAssignments.map(function (a) {
          return a.id;
        });

        let excludedIds = [];
        for (const id in assignIDToName) {
          if (selectedNames.indexOf(assignIDToName[id]) < 0) {
            excludedIds.push(id);
          }
        }

        that.setState({ excludeAssignIDs: excludedIds });
      },
    });
  },

  _validate: function () {
    // make sure the email field works out...true if it does...false if not...
    // not all browsers supported the type = email input..which would be nice if they did
    var returnVal = true;

    if (!this.state.email || this.state.email.length == 0) {
      returnVal = false;
      var newFormErrors = this.state.formErrors;
      newFormErrors.email = "required field";

      this.setState({ formErrors: newFormErrors });
    } else {
      // run a real simple (wrong) regex on it...won't catch everything...but the server side also does a check
      var re = /.*?@.*?\..*/;
      var match = re.exec(this.state.email);
      if (!match || match.length == 0) {
        returnVal = false;
        var newFormErrors = this.state.formErrors;
        newFormErrors.email = "invalid email";

        this.setState({ formErrors: newFormErrors });
      }
    }

    return returnVal;
  },

  render: function () {
    /* jshint ignore:start */

    var classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      Subscribe: true,
    });

    var editText = "Edit...";
    if (this.state.loadingAssignments) {
      editText = <i className={"fa fa-spinner fa-spin"} />;
    }

    var emailInputClass = "";
    if (this.state.formErrors && this.state.formErrors.email && this.state.formErrors.email.length > 0) {
      emailInputClass = "error";
    }

    return (
      <div className={classes}>
        <div className="title-container">
          <div className="text">
            <i className="fa fa-calendar-o" />
            Sync with Calendar
          </div>
        </div>

        <div className="body-container">
          <form className="body-form" onSubmit={this._submit} action="">
            <div className="form-group">
              <label>Your e-mail:</label>
              <input
                type="text"
                className={emailInputClass}
                ref="emailInput"
                name="emailInput"
                defaultValue={this.state.email}
                onChange={this._changeEmail}
              />
            </div>

            <div className="form-group">
              <label>Which assignments would you like to see?</label>
              <br />
              <input
                type="radio"
                name="excludeInput"
                value="all"
                checked={!this.state.useExcludeAssigns}
                onChange={this._checkAllAssign}
              />{" "}
              All assignments
              <br />
              <input
                type="radio"
                name="excludeInput"
                value="exclude"
                checked={this.state.useExcludeAssigns}
                onChange={this._checkExcludeAssign}
              />{" "}
              Selected Assignments:
              <span className="edit-exclude" onClick={this._editExclude}>
                {" "}
                {editText}
              </span>
              <br />
            </div>

            <p>
              Import your Lightning Bolt assignments into a calendar program such as Google Calendar, iCalendar,
              Outlook, or Lotus Notes. After completing this form, you will receive an email with instructions on how to
              import your new subscription into each program. Please note that changes to your schedule may take up to
              twenty-four hours to appear on a third party calendar.
            </p>

            <div className="action-bar">
              <button type="submit" className="submit-button">
                Submit
              </button>
              <button type="button" className="cancel-button" onClick={this._close} onTouchEnd={this._close}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  },
});

module.exports = SubscribeDialog;
