/**
 * TallyDisplay displays a tally
 */
import classnames from "@/_lib/utils/classnames";
import { CallbackContext } from "@/viewer/ui/modules/common/helpers/callbacks";
import React, { useContext } from "react";

interface Props {
  className?: string;
  target?: number;
  data?: number;
  tallyDate: Date;
  tallyName: string;
}

function TallyDisplay(props: Props): JSX.Element {
  const { className = "", target, data, tallyDate, tallyName } = props;
  const { focusTallySlot, unFocusSlot } = useContext(CallbackContext);

  let displayText: string;
  let diff: number | undefined = undefined;
  const digits = data !== undefined ? data.toString().split(".")[1]?.length || 0 : 0;

  if (data === undefined) {
    displayText = "-";
  } else if (target === undefined) {
    displayText = data.toFixed(digits);
  } else {
    diff = target - data;
    const sign = diff > 0 ? "-" : "+";
    displayText = `${data} / ${target} (${sign}${Math.abs(diff).toFixed(digits)})`;
  }

  const classes = classnames(className, {
    over: diff && diff < 0,
    under: diff && diff > 0,
  });

  if (target !== undefined) {
    const percent = ((data ?? 0) / target) * 100;
    const percentRound = Math.round(percent * 100) / 100;
    const overUnder = diff === undefined ? undefined : diff < 0 ? "over" : "under";

    const onMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
      focusTallySlot({
        boundingRect: e.currentTarget.getBoundingClientRect(),
        percentLabel: `${percentRound.toFixed(2)}%`,
        tallyName,
        tallyDate,
        overUnder,
      });
    };

    return (
      <div className={classes} onMouseEnter={onMouseEnter} onMouseLeave={unFocusSlot}>
        <span className="text">{displayText}</span>
      </div>
    );
  }

  return (
    <div className={classes}>
      <span className="text">{displayText}</span>
    </div>
  );
}

export default TallyDisplay;
