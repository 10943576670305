import { SlotData } from "@/_lib/ui/modules/dialog/types/schedule_edits/swaps/move/types";
import { MONTH_DAY_YEAR_FORMAT } from "@/_lib/utils/time";
import { Box, Divider, Typography } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { produce } from "immer";
import { DateTime } from "luxon";
import { Moment } from "moment";
import React from "react";

interface CalendarStageProps {
  setSlotData: (slotData: SlotData) => void;
  slotData: SlotData;
}

const CalendarStage = (props: CalendarStageProps): JSX.Element => {
  const { slotData, setSlotData } = props;

  return (
    <Box sx={{ flexDirection: "column", justifyContent: "space-between" }}>
      <div style={{ marginBottom: "5px" }}></div>
      <Typography id={"modal-modal-title"}>{`Current Scheduled Date: ${DateTime.fromJSDate(slotData.slotDate).toFormat(
        MONTH_DAY_YEAR_FORMAT
      )}`}</Typography>
      <div style={{ marginBottom: "5px" }}></div>
      <Divider />
      <DatePicker
        className={"moveDialog-datePicker"}
        variant={"static"}
        // Prevents TZA conversion from jumping to the wrong date
        value={slotData.selectedDate.set({ hour: 12 }).toString()}
        onChange={(date) =>
          setSlotData(
            produce(slotData, (draft) => {
              // The date returned from this version of MUI's DatePicker is a Moment object,
              // so we need to convert it to a JS Date
              // See: https://www.npmjs.com/package/@material-ui/pickers
              // and package.json which uses @date-io/moment

              const jsDate = (date as Moment).toDate();

              if (!jsDate) return;

              draft.selectedDate = DateTime.fromJSDate(jsDate);
            })
          )
        }
      />
    </Box>
  );
};

export default CalendarStage;
