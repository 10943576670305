const Backbone = require("backbone");
var _ = require("underscore");

var TableObject = Backbone.Model.extend({
  idAttribute: "id",

  defaults: {
    object: undefined,
    title: undefined,

    selected: false,
    hidden: false,
  },

  initialize: function (attributes, options) {
    // initialize the object and title -- which are really just proxies into functions
    //if (this.attributes.object === undefined) {
    //	this.attributes.object = this.attributes.proxy().get(this.attributes.id);
    //	this.attributes.title = this.attributes.object.get(this.attributes.displayAttr);
    //}
  },

  /* publics */
  //object: function() { return this.attributes.object; },
  //title: function() { return this.attributes.title; },
});

module.exports = TableObject;
