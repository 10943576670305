/*
 * Personnel collection that doesn't depend on window object bindings */
const Backbone = require("backbone");

const ApiConfig = require("_lib/data/ApiConfig.js");
const NakedPersonnel = require("./../models/NakedPersonnel.js");
const _ = require("lodash");

const NakedPersonnelCollection = Backbone.Collection.extend({
  model: NakedPersonnel,

  initialize: function (models, attributes) {
    this.attributes = {};

    for (const key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    const queryParams = {};
    const assignmentList = this.attributes.assignmentList;
    const departmentList = this.attributes.departmentList;
    const excludeExpired = this.attributes.excludeExpired;

    // Check for the assignment list, fall back to department list.
    if (assignmentList?.length) {
      queryParams["assignment_list"] = assignmentList.join("~");

      if (excludeExpired) {
        queryParams["exclude_expired"] = true;
      }
    } else if (departmentList) {
      if (typeof departmentList === "function") {
        const depts = departmentList();

        if (depts?.length) {
          queryParams["dept_list"] = depts.join("~");
        }
      } else {
        if (departmentList?.length) {
          queryParams["dept_list"] = departmentList.join("~");
        }
      }
    }

    if (this.attributes?.empList) {
      queryParams["emp_list"] = this.attributes.empList;
    }

    if (this.attributes.permissionsFilter) {
      queryParams["pf"] = this.attributes.permissionsFilter;
    }

    const cleanQueryParams = _.pickBy(queryParams, (value) => value !== undefined && value !== null && value !== "");

    return (
      ApiConfig.getPrefix() +
      "/personnel?" +
      _.map(cleanQueryParams, function (value, key) {
        return key + "=" + value;
      }).join("&")
    );
  },
});

module.exports = NakedPersonnelCollection;
