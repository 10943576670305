/*
 * Tally model that doesn't depend on window object bindings */
const Backbone = require("backbone");

var NakedTally = Backbone.Model.extend({
  idAttribute: "tally_id",

  defaults: {},

  initialize: function (attributes, options) {},
});

module.exports = NakedTally;
