import { Collection, Model } from "backbone";

import { DataContext, FilterContext, SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import connectedPropertyHookFactory from "@/viewer/ui/modules/common/hooks/connectedPropertyHookFactory";
import { cleanSlotAttributes, Slot, SlotAttributes } from "@/viewer/types/domain/slot";
import { SearchCorpusParams } from "@/_lib/utils/constructSearchCorpus";
import { isSlotVisible } from "@/viewer/utils/domain/slotquests";

/**
 * This hook should serve as the basis for views that display slots. It handles filtering slots for
 * visibility, watching settings/filters/data for changes, and registering for backbone events
 * as necessary.
 */
export default connectedPropertyHookFactory({
  loader: (
    settings: SettingsContext,
    ui: UIContext,
    filters: FilterContext,
    data: DataContext,
    slotCollection: Collection<Model<SlotAttributes>>,
    searchCorpusParams?: SearchCorpusParams
  ): Slot[] => {
    const out: Slot[] = [];

    if (!slotCollection) return out;

    slotCollection.forEach((item) => {
      const slot = cleanSlotAttributes(settings, item.attributes, searchCorpusParams);
      if (isSlotVisible(slot, ui, filters, data)) {
        out.push(slot);
      }
    });

    return out;
  },
  getListeners: () => {
    const { LbsAppData } = window as any;
    return [
      [LbsAppData.AppContext, "fetched"],
      [LbsAppData.AppContext, "redraw"],
      [LbsAppData.Slots, "add"],
      [LbsAppData.Slots, "change"],
      [LbsAppData.Slots, "remove"],
    ];
  },
});
