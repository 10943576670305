// Manager to handle communication with Google Analytics
const logger = require("../utils/logging").default;

const ErrorsHelper = Backbone.Model.extend({
  defaults: {
    active: true,
  },

  initialize: function () {
    // don't send analytics if we're on the dev server
    if (window.top.location.hostname === "localhost") {
      //this.attributes.active = false;
    }
  },

  /* publics */
  log: function (msg, url, line, col, error, origin) {
    const customer_id = window.LbsAppData.User.attributes.customer_id;
    const user_id = window.LbsAppData.User.attributes.user_id;

    const errorData = {
      customer_id: customer_id,
      origin,
      user_id: user_id,
      emp_id: window.LbsAppData.User.attributes.emp_id,
      url: url + "(Line):" + line + "(Col):" + col,
      user_name: window.LbsAppData.User.attributes.user_name,
      api_key: "1bc4e40b-0373-42a8-bfed-979f10b0743a",
      exception: msg,
      stack_trace: error ? error.stack : "no stack trace",
    };

    logger.error(msg, errorData);
  },

  /* privates */
  _isActive: function () {
    return this.attributes.active;
  },
});

module.exports = ErrorsHelper;
