import React, { useEffect, useLayoutEffect } from "react";
import moment from "moment";

import PrintContainer, {
  getPageDimensionsFromOrientationAndPageSize,
  PageScale,
  PageSize,
  PrintOrientation,
} from "@/_lib/ui/modules/print/PrintContainer";
import { getPersonnelOrAssignmentId, isVisible } from "@/viewer/utils/domain/perssignment";
import useColumnSlotData from "@/viewer/ui/modules/common/hooks/useColumnSlotData";
import { calculateSlotQuestHeight } from "../../../common/helpers/virtualization";
import getLeftColumnTitle from "../../../common/helpers/getLeftColumnTitle";
import classnames from "@/_lib/utils/classnames";

import { ScheduleData, SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { PersonnelOrAssignment, RequestsByDate, SlotsByDate } from "../../../common/types";
import { ViewData } from "@/viewer/types/viewdata";

import useTallyReportData from "@/viewer/ui/modules/common/hooks/useTallyReportData";
import useColumnRequestData from "../../../common/hooks/useColumnRequestData";

import {
  CELL_CLASSNAME,
  getColumnDimensions,
  SLOT_ROW_MEMBER_CLASSNAME_PREFIX,
  REQUEST_ROW_MEMBER_CLASSNAME_PREFIX,
} from "./constants";

import { convertDateArrayIntoDateRowsOfXWidth } from "@/_lib/utils/arrayUtils";

import PrintColumnFactory, { ColumnType } from "./renderers/printColumnFactory";

import { isWeekend } from "date-fns";

import "../_styles.scss";
import useDepartmentsById from "@/viewer/ui/modules/common/hooks/useDepartmentsById";

export type heightsValuesObject = { rowHeights: Array<string> | []; index: number };
export type columnRowHeightMap = Map<string, { slot: heightsValuesObject; request: heightsValuesObject }>;

interface Props {
  endDate: Date;
  scheduleData: ScheduleData;
  settings: SettingsContext;
  startDate: Date;
  ui: UIContext;
  viewData: ViewData;
  supportsColumnPrinting: boolean;
  printRef?: React.MutableRefObject<null>;
  pageScale?: PageScale;
  printOrientation?: PrintOrientation;
  pageSize?: PageSize;
}

const ColumnPrintView = (props: Props): JSX.Element => {
  const {
    endDate,
    pageScale: scale = 1,
    pageSize = PageSize.Letter,
    printOrientation = PrintOrientation.Portrait,
    printRef,
    scheduleData,
    settings,
    startDate,
    ui,
    viewData,
    supportsColumnPrinting,
  } = props;
  const { width: PAGE_WIDTH } = getPageDimensionsFromOrientationAndPageSize(pageSize, printOrientation);
  const { report, requests, slots } = scheduleData;
  const { data, filters } = viewData;
  const { condenseColumnView, hideWeekends, viewDataType } = settings;
  const { leftColumnType } = ui;
  const { assignments, personnel } = viewData;
  const leftColumnData = leftColumnType === "assignment" ? assignments : personnel;
  const { columnWidthPx, leftColumnWidthPx } = getColumnDimensions(condenseColumnView);
  const LEFT_COLUMN_WIDTH_SCALED = leftColumnWidthPx * scale;
  const COLUMN_WIDTH_SCALED = columnWidthPx * scale;

  const leftHandColumnId = React.useRef(0);
  const tallyData = useTallyReportData(settings, report);
  const requestData = { ...useColumnRequestData(settings, ui, filters, data, requests) };
  const slotData = { ...useColumnSlotData(settings, ui, filters, data, slots) };
  const departmentsById = useDepartmentsById();

  // Certain left hand columns in the left column data are not
  // visible, these include the lightning bolt user and other system
  // accounts. In order to correctly adjust the heights of rows after
  // the DOM has been rendered, we need this in scope to get the correct
  // number of items. We could also filter over the leftColData in the
  // height adjuster layoutEffect hook, however, as the data are already
  // iterated over once during mount, there is no need to iterate again
  let visibleLeftHandColumnCount = 0;

  // Determine how many rows we can fit on a page with the given scale
  // This is used by the height map to construct a matrix of heights
  // based on the max amount of columns for a page
  // as it is not particularly clear that the view is in fact rendered as
  // true columns, I will leave the name as slots instead of columns for page width
  // they are however, entirely interchangeable
  const determineMaximumSlotsForPageWidth = (pageWidth: number, numberOfSlots = 0): number => {
    if (pageWidth - COLUMN_WIDTH_SCALED < 0) return numberOfSlots;

    const newPageWidth = pageWidth - COLUMN_WIDTH_SCALED;

    return determineMaximumSlotsForPageWidth(newPageWidth, ++numberOfSlots);
  };

  const maximumSlotsForPageWidth = React.useMemo(
    () => determineMaximumSlotsForPageWidth(PAGE_WIDTH - LEFT_COLUMN_WIDTH_SCALED),
    [PAGE_WIDTH, scale]
  );

  // Iterate over visible slots and requests by date
  // Determine which date for each row has the largest height in the viewport (note)
  // this height is subject to change based off of numerous styling effects and
  // scaling which is why we start with base heights and then re-evaluate
  // the heights after each element has been rendered to the DOM and these
  // effects have been applied.
  // Add a map entry for that index (the row) for either slot and / or
  // request (these heights can and do differ so we must separate them)
  // If we are not hiding blank rows, slots default to their normal height
  // so they can be displayed without screwing up the print format
  // The height map is used in the column print renderer and the
  // height adjustment function below.
  const getColumnRowHeightAndIndexMap = (): columnRowHeightMap => {
    // Stores a collection of row heights of length (leftHandGroup Count)
    // and an index used as a row identifier within a CSS selector
    // in the height adjuster layoutEffect
    const columnHeightMap: columnRowHeightMap = new Map();

    leftColumnData.forEach((leftColumn: PersonnelOrAssignment) => {
      if (!isVisible(leftColumn, ui, filters)) {
        return;
      }

      const leftColId = getPersonnelOrAssignmentId(leftColumn);
      const slotsByDate: SlotsByDate = slotData?.[leftColId];
      const requestsByDate: RequestsByDate = requestData?.[leftColId];

      let slot: heightsValuesObject = { rowHeights: [], index: visibleLeftHandColumnCount };
      let request: heightsValuesObject = { rowHeights: [], index: visibleLeftHandColumnCount };

      // Accepts a matrix of row date keys
      // assembled based on the maximum number of slots that can
      // fit horizontally across the page at the given scale
      // This matrix is then iterated over by row and
      // for each date of the row, all item heights are summed
      // once all dates in a row have been summed, the row is then
      // iterated over by a reducer which determines the maximum
      // height needed for the row, at this point in time,
      // that height will be a multiple of the predetermined cell heights
      // located in the constants file as it is not possible to determine
      // the true DOM height of the cells prior to them being rendered
      const determineHeightForSlotQuestRows = (
        slotQuestDateKeys: string[][],
        slotQuestItems: SlotsByDate | RequestsByDate
      ) => {
        const rowHeightsInOrderOfRenderingInPixels = slotQuestDateKeys
          .map((row) => {
            const maximumHeightForRow = row
              ?.map((rowItemDate) =>
                slotQuestItems[rowItemDate]
                  ?.map((slotQuest) => calculateSlotQuestHeight(ui, slotQuest))
                  .reduce((heightForLastItem, heightForCurrentItem) => heightForLastItem + heightForCurrentItem, 0)
              )
              .reduce(
                (previousMaxHeight, currentMaxHeight) =>
                  previousMaxHeight < currentMaxHeight ? currentMaxHeight : previousMaxHeight,
                0
              );

            return maximumHeightForRow;
          })
          .map((height) => `${height}px`);

        return rowHeightsInOrderOfRenderingInPixels;
      };

      const slotDateKeys = slotsByDate && Object.keys(slotsByDate);

      if (viewDataType !== "request" && slotDateKeys) {
        const rowDatesBoundedByRowWidth = convertDateArrayIntoDateRowsOfXWidth(
          slotDateKeys,
          maximumSlotsForPageWidth,
          startDate.toString(),
          endDate.toString()
        );

        // Holds the maximum height for each row applicable to this leftColumn
        // convertDateArrayIntoDateRowsOfXWidth will handle rows without any members
        // thus ensuring every row height array aligns with the expected number of
        // left hand groups
        const rowHeights = determineHeightForSlotQuestRows(rowDatesBoundedByRowWidth, slotsByDate);

        slot = { rowHeights, index: visibleLeftHandColumnCount };
      }

      if (requestsByDate) {
        const requestDateKeys = Object.keys(requestsByDate);

        const rowDatesBoundedByRowWidth = convertDateArrayIntoDateRowsOfXWidth(
          requestDateKeys,
          maximumSlotsForPageWidth,
          startDate.toString(),
          endDate.toString()
        );

        // Holds the maximum height for each row applicable to this leftColumn
        // convertDateArrayIntoDateRowsOfXWidth will handle rows without any members
        // thus ensuring every row height array aligns with the expected number of
        // left hand groups
        const rowHeights = determineHeightForSlotQuestRows(rowDatesBoundedByRowWidth, requestsByDate);

        request = { rowHeights, index: visibleLeftHandColumnCount };
      }

      columnHeightMap.set(leftColId, {
        slot,
        request,
      });

      visibleLeftHandColumnCount++;
    });

    return columnHeightMap;
  };

  const columnRowHeightMap = React.useMemo(
    () => getColumnRowHeightAndIndexMap(),
    [leftColumnData, slotData, settings, scale, PAGE_WIDTH]
  );

  const containerClassNames = classnames("ColumnContainer", {
    "ColumnContainer--no-weekends": hideWeekends,
    "ColumnContainer--print-container": true,
  });

  // Get the column which contains all the visible
  // leftColId items. This can be thought of as a
  // vertical table header
  const getLeftColumn = (rowNumber: number): JSX.Element => {
    const title = getLeftColumnTitle(settings, ui);

    return (
      <PrintColumnFactory
        columnRowHeightMap={columnRowHeightMap}
        isRequestOnlyView={false}
        leftColumnData={leftColumnData}
        leftHandColumnId={rowNumber}
        requestData={requestData}
        scale={scale}
        settings={settings}
        slotData={slotData}
        ui={ui}
        viewData={viewData}
        {...tallyData}
        type={ColumnType.LEFT_COLUMN}
        title={title}
        key={`leftcolumn-${rowNumber}`}
        departmentsById={departmentsById}
      />
    );
  };

  // Creates a map of Dates keyed as the string of the date
  // for all dates between the start and end dates INCLUSIVE
  // because of how UI context handles datetimes
  const getDateRangeHeaders = (): Map<string, Date> => {
    const endMoment = moment(endDate);
    const startMoment = moment(startDate);
    const dateMap = new Map();

    while (startMoment < endMoment) {
      const dateString = startMoment.format("dd MM/DD");
      const currentDate = startMoment.toDate();

      dateMap.set(dateString, currentDate);
      startMoment.add(1, "day");
    }

    return dateMap;
  };

  const getStandardColumn = ({
    key,
    columnDate,
    rowNumber,
  }: {
    key: string;
    columnDate: Date;
    rowNumber: number;
  }): JSX.Element => {
    return (
      <PrintColumnFactory
        columnRowHeightMap={columnRowHeightMap}
        isRequestOnlyView={false}
        leftColumnData={leftColumnData}
        leftHandColumnId={rowNumber}
        requestData={requestData}
        scale={scale}
        settings={settings}
        slotData={slotData}
        ui={ui}
        viewData={viewData}
        {...tallyData}
        type={ColumnType.STANDARD_COLUMN}
        columnDate={columnDate}
        departmentsById={departmentsById}
        key={key}
      />
    );
  };

  /*
      Iterate over the dateRangeMap which includes all date strings
      between the start and end dates indicated in the ui context.
      Each key of the map represents a single date value which is used
      as the startEndDate. Doing this allows the column print renderer
      to create columns for all items which appear on this date.

      Next, the a "row" is defined as a line of items across the width
      of the printed page. The function then assigns the starting row
      width to that of the width of the left column multiplied by the scale.

      The columns multi-dimensional array is then hydrated with the left
      column at index 0 (to start the row).

      Now, each date in the dateRangeMap is iterated over and as long
      as the currentRowWidth + the COLUMN_WIDTH_SCALED will fit within the 
      width of the page, the column is rendered and added to the columns index
      at the correct rowNumber. The currentRowWidth is then updated with
      the previous amount + the COLUMN_WIDTH_SCALED and the process continues.

      If the addition of a new column will cause the rowWidth to be greater
      than the page width, the currentRowWidth is reset to that of the LEFT_COLUMN_WIDTH_SCALED 
      AND COLUMN_WIDTH_SCALED. A new array is created for the new row, the leftHandColumn is added
      and then the new column is added and the process continues until no dateKeys remain in the map.
     */
  const getColumns = (): JSX.Element => {
    const dateRangeMap: Map<string, Date> = getDateRangeHeaders();
    const columns: JSX.Element[][] = [[]];
    const dateKeys = dateRangeMap.keys();

    let currentRowWidth = LEFT_COLUMN_WIDTH_SCALED;

    columns[leftHandColumnId.current].push(getLeftColumn(leftHandColumnId.current));

    for (const key of dateKeys) {
      const columnDate = dateRangeMap.get(key);

      if (!columnDate || (hideWeekends && isWeekend(columnDate))) {
        continue;
      }

      if (currentRowWidth + COLUMN_WIDTH_SCALED <= PAGE_WIDTH) {
        columns[leftHandColumnId.current].push(
          getStandardColumn({ key, rowNumber: leftHandColumnId.current, columnDate })
        );

        currentRowWidth += COLUMN_WIDTH_SCALED;
      } else if (currentRowWidth + COLUMN_WIDTH_SCALED > PAGE_WIDTH) {
        currentRowWidth = LEFT_COLUMN_WIDTH_SCALED + COLUMN_WIDTH_SCALED;
        leftHandColumnId.current++;
        columns[leftHandColumnId.current] = [];
        columns[leftHandColumnId.current].push(getLeftColumn(leftHandColumnId.current));
        columns[leftHandColumnId.current].push(
          getStandardColumn({ key, rowNumber: leftHandColumnId.current, columnDate })
        );
      }
    }

    return (
      <div className={"columns-items"}>
        {columns.map((row, idx) => {
          return (
            <div className="columns-row__wrapper" key={`columns_row_${idx}`} style={{ width: "100%" }}>
              <div className="columns-row">{row}</div>
            </div>
          );
        })}
      </div>
    );
  };

  /*
      PrintContainer has a CSS setting of visibility: hidden. This allows
      the container to exist within the DOM without being seen by the user
      or interfering with page flow and is VITAL to the ability for this function to work. 
      Each member of a "row" is assigned a classname such as
      'row-member-id-<rowIndex> in the columnPrintRenderer.

      This function is triggered by a useLayoutEffect once the DOM is hydrated. Once triggered, 
      iterate over the selectors assigned to each matrix member and determine the height of
      the largest member of the row, then assign that height to all members of the row so it
      looks nice and purrty
     */
  const newAdjustHeightsOfRenderedRows = () => {
    const modifyTargetHeightsToMaxForRow = (selector: string): void => {
      const targetEls = Array.from(document.querySelectorAll(`${selector}`)) as HTMLElement[];

      if (!targetEls.length) return;

      const targetCells: HTMLElement[] = targetEls
        .filter((el) => (Array.from(el.getElementsByClassName(CELL_CLASSNAME)) as HTMLElement[]).length !== 0)
        .map((parentEle) => parentEle.querySelector(`.${CELL_CLASSNAME}`) as HTMLElement);

      if (!targetCells.length) return;

      const maxRenderedHeight = targetCells.reduce((acc, curr) => {
        return acc.clientHeight > curr.clientHeight ? acc : curr;
      }, targetCells[0]).clientHeight;

      targetEls.forEach((el) => (el.style.height = `${maxRenderedHeight}px`));
    };

    const maxRowIndex = visibleLeftHandColumnCount;
    const maxWeekRowIndex = leftHandColumnId.current;

    // Iterate over each matrix identifier and use that as a selector to
    // establish targets for height adjustment "therapy", yeah, that's what we will
    // call it, "therapy"
    // Yes this is O(n^2), it DO be dat way sometimes
    for (let leftHandGroup = 0; leftHandGroup <= maxWeekRowIndex; leftHandGroup++) {
      for (let leftHandRow = 0; leftHandRow < maxRowIndex; leftHandRow++) {
        const requestRowItemSelector = `.${REQUEST_ROW_MEMBER_CLASSNAME_PREFIX}${leftHandRow}-${leftHandGroup}`;
        const slotRowItemSelector = `.${SLOT_ROW_MEMBER_CLASSNAME_PREFIX}${leftHandRow}-${leftHandGroup}`;
        modifyTargetHeightsToMaxForRow(slotRowItemSelector);
        modifyTargetHeightsToMaxForRow(requestRowItemSelector);
      }
    }
  };

  // As the component is never removed from the DOM
  // This ref needs to be reset
  useEffect(() => {
    leftHandColumnId.current = 0;
  });

  useLayoutEffect(() => {
    newAdjustHeightsOfRenderedRows();
  });

  const getContent = (): JSX.Element => {
    const columns = getColumns();

    return (
      <PrintContainer
        ref={printRef}
        showOutsidePrintPreview={false}
        scale={scale}
        orientation={printOrientation}
        pageSize={pageSize}
        supportsColumnPrinting={supportsColumnPrinting}
        columnsOrCondensedView={true}
      >
        <div className={containerClassNames} data-testid="grid-container">
          {columns}
        </div>
      </PrintContainer>
    );
  };

  return <div style={{ margin: "0 auto" }}>{getContent()}</div>;
};

export default ColumnPrintView;
