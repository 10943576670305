/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import SearchInput from "../../../../../components/SearchInput";
import { ListItem } from "@/_lib/ui/modules/topbar/types";
import Select, { StylesConfig, ValueType } from "react-select";
import { GroupOption } from "@/viewer/ui/modules/common/types/groups.types";

interface OwnProps {
  filterIds: (string | number)[];
  filters: ListItem[];
  filter: (filters: (string | number)[]) => void;
  showAllItems: () => void;
  listName: string;
  emitterEvent: string;
  showGroupDropdown: boolean;
  setPersonnelMenuOpen: (open: boolean) => void;
  setAssignmentsMenuOpen: (open: boolean) => void;
  groupOptions: GroupOption[];
}

interface OwnState {
  showMenu: boolean;
  selectedItems: ListItem[];
  searchString: string;
  clearingSearch: boolean;
  selectedGroup: GroupOption;
}

type Props = OwnProps;

type IsMulti = false;

const customSelectStyles: StylesConfig<GroupOption, IsMulti> = {
  control: (base) => ({
    ...base,
    minHeight: 32,
    height: 32,
  }),

  valueContainer: (base) => ({
    ...base,
    height: 32,
    padding: "0 6px",
  }),

  placeholder: (base) => ({
    ...base,
    top: "45%",
  }),

  input: (base) => ({
    ...base,
    margin: "0px",
  }),

  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),

  indicatorsContainer: (base) => ({
    ...base,
    height: 32,
  }),
};

const defaultGroup: GroupOption = {
  value: -1,
  label: "Any",
  description: "All groups",
  dept_id: -1,
  members: [],
};

export default class FilterByList extends React.PureComponent<Props, OwnState> {
  protected dropdownMenu: any;

  public constructor(props: Props) {
    super(props);

    this.state = {
      showMenu: false,
      selectedItems: [],
      searchString: "",
      clearingSearch: false,
      selectedGroup: defaultGroup,
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    const ids = this.props.filterIds;

    if (this.props.filterIds !== prevProps.filterIds) {
      this.setState({ selectedItems: this.props.filters.filter((f) => ids.includes(f.id)) });
    }
  }

  public showMenu(event: any): void {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });

    if (this.props.listName === "Personnel") {
      this.props.setPersonnelMenuOpen(true);
    } else {
      this.props.setAssignmentsMenuOpen(true);
    }
  }

  public closeMenu(event?: any): void {
    if (this.state.clearingSearch) {
      this.setState({ clearingSearch: false });
    } else {
      if ((event && !this.dropdownMenu.contains(event.target)) || !event) {
        this.setState({ showMenu: false }, () => {
          document.removeEventListener("click", this.closeMenu);
        });
        if (this.props.listName === "Personnel") {
          this.props.setPersonnelMenuOpen(false);
        } else {
          this.props.setAssignmentsMenuOpen(false);
        }
      }
    }
  }

  public selectAll = (): void => {
    // Select all items if there is no search string and the selected group is "Any"
    if (!this.state.searchString && this.state.selectedGroup.value === -1) {
      this.setState(
        {
          selectedItems: this.state.selectedItems,
        },
        () => this.props.showAllItems()
      );
    } else {
      this.setState(
        {
          selectedItems: this.state.selectedItems.concat(
            this.getFilteredItemsList().filter((filter) => !this.state.selectedItems.includes(filter))
          ),
        },
        () => this.filter()
      );
    }
  };

  public clearFilters = (): void => {
    this.setState(
      {
        selectedItems: this.state.selectedItems.filter((filter) => !this.getFilteredItemsList().includes(filter)),
        searchString: "",
        selectedGroup: defaultGroup,
      },
      () => this.filter()
    );
  };

  public getFilteredItemsByGroup = (): ListItem[] => {
    return this.props.filters.filter((x) => {
      // Return all items if the selected group is "Any"
      if (this.state.selectedGroup.value === -1) {
        return true;
      }

      return this.state.selectedGroup.members.includes(x.id as number);
    });
  };

  public getFilteredItemsList = (): ListItem[] => {
    const items = this.getFilteredItemsByGroup();
    return items.filter((x) => {
      if (!this.state.searchString) return true;

      return x.display_name.toLowerCase().includes(this.state.searchString.toLowerCase());
    });
  };

  public selectFilter = (e: ListItem): void => {
    this.setState(
      {
        selectedItems: this.state.selectedItems.find((a) => a.id === e.id)
          ? this.state.selectedItems.filter((a) => a.id != e.id)
          : [...this.state.selectedItems, e],
      },
      () => this.filter()
    );
  };

  public updateSearchString = (searchString: string): void => {
    if (searchString) {
      this.setState({ searchString: searchString });
    } else {
      this.setState({ searchString: "", clearingSearch: true });
    }
  };

  public filter = (): void => {
    this.props.filter(this.state.selectedItems.map((x) => x.id));
  };

  public onGroupChange = (option: ValueType<GroupOption, false>): void => {
    this.setState({ selectedGroup: option as GroupOption });
  };

  // To prevent groups select to close the filter popup
  public handleSelectClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  render(): JSX.Element {
    const { filters, listName, groupOptions, showGroupDropdown } = this.props;
    const { selectedItems, searchString, showMenu, selectedGroup } = this.state;

    // Add default option ("Any") to the list of options
    const options = [defaultGroup, ...groupOptions];

    return (
      <div className="top-bar-btn dropdown">
        <div
          onClick={this.showMenu}
          onTouchEnd={this.showMenu}
          className="ribbon-text no-mobile limit-width-large max-width"
          data-cy={`TBFilter${listName}`}
        >
          {selectedItems.length > 0 || selectedItems.length == filters.length
            ? selectedItems.length === 1
              ? selectedItems[0].display_name
              : selectedItems.length + ` ${listName}`
            : `Filter ${listName}`}
        </div>
        <div className="icons-mobile" onClick={this.showMenu} onTouchEnd={this.showMenu}>
          <i className={"fa " + (listName === "Personnel" ? "fa-user-circle-o" : "fa-tasks")} />
        </div>

        <div
          className={"menu " + (showMenu ? "open" : "")}
          data-cy="TBMenu"
          ref={(element) => {
            this.dropdownMenu = element;
          }}
        >
          <div className="flex-between mb-15">
            <div>
              <button className="btn" data-cy="TBSelectAllBtn" onClick={this.selectAll} onTouchEnd={this.selectAll}>
                Select All
              </button>
              <button
                className="btn ml-10"
                data-cy="TBClearBtn"
                onClick={this.clearFilters}
                onTouchEnd={this.clearFilters}
                disabled={this.state.selectedItems.length < 1}
              >
                Clear Filters
              </button>
            </div>
            <div>
              <i
                className="fa fa-times pointer"
                data-cy="TBCloseBtn"
                aria-hidden="true"
                onClick={() => this.closeMenu()}
                onTouchEnd={() => this.closeMenu()}
              />
            </div>
          </div>

          <SearchInput searchString={searchString} updateSearchString={this.updateSearchString} />

          {showGroupDropdown && (
            <div className="dropdown-container">
              <span>GROUP:</span>
              <div className="dropdown" onClick={this.handleSelectClick}>
                <Select
                  onChange={this.onGroupChange}
                  styles={customSelectStyles}
                  value={selectedGroup}
                  options={options}
                  isSearchable
                />
              </div>
            </div>
          )}

          <div className="scrollable mt-10" data-cy="TBScroller">
            {filters && filters.length == 0 && (
              <div className="flex-display-justify-center mt-20 mb-20">
                There are no personnel filters for this view.
              </div>
            )}
            {this.getFilteredItemsList().map((m, idx) => {
              const isSelectedItem = selectedItems.find((a) => a.id === m.id);
              return (
                <div
                  key={idx}
                  className={`pointer listitem ${isSelectedItem ? "selected bold" : ""}`}
                  data-cy={`${isSelectedItem ? "selectedListItem" : "listItem"}`}
                  onClick={(_e) => this.selectFilter(m)}
                  onTouchStart={(_e) => this.selectFilter(m)}
                >
                  <i className={`fa-checkbox fa ${isSelectedItem ? "fa-check-square-o" : "fa-square-o"}`} />{" "}
                  {m.display_name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
