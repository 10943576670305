export interface CallOrder {
  value: number | string | null | undefined;
  label: string;
}

export const callOrderOptions: CallOrder[] = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: "none",
    label: "None",
  },
];

export const keepDefaultsOption: CallOrder = {
  value: "default",
  label: "Keep Defaults",
};
