import React from "react";
import { Grid } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { Field, FastField, Form, FieldProps, useFormikContext } from "formik";
import { StateManager } from "react-select/src/stateManager";

import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { ViewData } from "@/viewer/types/viewdata";
import { User } from "@/viewer/types/domain/user";
import { Template } from "@/viewer/types/domain/template";
import { Personnel } from "@/viewer/types/domain/personnel";
import { Assignment } from "@/viewer/types/domain/assignment";
import { Reason } from "@/viewer/types/domain/reason";
import { Location } from "@/viewer/types/domain/location";
import { FormValues } from "./AddShiftModal";

import { FADE_IN_DURATION } from "@/common/components/modal/Modal";
import useFormData from "./useFormData";
import Tooltip from "@/common/components/tooltip/Tooltip";
import Button from "@/common/components/button/Button";
import {
  RenderSelectField,
  RenderDateField,
  RenderTextField,
  RenderTimeField,
  RenderCheckboxField,
} from "@/common/components/fields";
import Label from "@/common/components/fields/meta/Label";
import { getBlockLength, getBlockStartDay } from "./helpers";
import { formatUSDate } from "@/viewer/utils/dateFormatters";
import { CallOrder } from "@/viewer/types/domain/callOrder";

interface Props {
  settings: SettingsContext;
  viewData: ViewData;
  uiContext: UIContext;
  user: User;
  templates: Template[];
}

const AddShiftModalContent = ({ settings, viewData, uiContext, user, templates }: Props): React.ReactElement => {
  const { values, setValues } = useFormikContext<FormValues>();
  const {
    personnelData,
    assignmentData,
    dateData,
    reasonData,
    locationData,
    shiftTimesData,
    noteData,
    addDemandIfNecessaryData,
    vrmAssignment,
    callOrderOptions,
    callOrderVisible,
  } = useFormData(settings, viewData, uiContext, user);

  const [focusedField, setFocusedField] = React.useState<null | "personnel" | "assignment" | "date">(null);
  const [autoFocusFinished, setAutoFocusFinished] = React.useState(false);
  const personnelRef = React.useRef<StateManager<{ [key: string]: unknown }>>(null);
  const assignmentRef = React.useRef<StateManager<{ [key: string]: unknown }>>(null);
  const dateRef = React.useRef<HTMLDivElement>(null);

  const handleFinishAutoFocus = () => {
    setFocusedField(null);
    setAutoFocusFinished(true);
  };

  React.useLayoutEffect(() => {
    setTimeout(() => setFocusedField("personnel"), FADE_IN_DURATION);
  }, []);

  React.useEffect(() => {
    switch (focusedField) {
      case "personnel":
        personnelRef?.current?.focus();
        break;
      case "assignment":
        assignmentRef?.current?.focus();
        break;
      case "date":
        dateRef?.current?.focus();
        break;
    }
  }, [focusedField]);

  return (
    <Form id="add-shift-form">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                {/** Template */}
                <Grid item xs={12}>
                  <FastField name="template">
                    {({ field, form, meta }: FieldProps) => (
                      <RenderSelectField
                        field={field}
                        form={form}
                        meta={meta}
                        id="template"
                        label="Template"
                        placeholder="Required"
                        disableManySelect
                        clearable={false}
                        options={templates}
                        getOptionLabel={(option: Template) => option.name}
                        getOptionValue={(option: Template) => option.id}
                        menuPortalTarget={document.body}
                      />
                    )}
                  </FastField>
                </Grid>

                {/** Personnel */}
                <Grid item xs={12}>
                  <Tooltip
                    title="Must have a template selected first"
                    disabled={!!values.template}
                    placement="bottom"
                    arrow
                  >
                    <div>
                      <Field name="personnel">
                        {({ field, form, meta }: FieldProps) => (
                          <RenderSelectField
                            field={field}
                            form={form}
                            meta={meta}
                            ref={personnelRef}
                            id="personnel"
                            label="Personnel"
                            placeholder="Required"
                            disabled={!values.template}
                            loading={personnelData.loading}
                            options={personnelData.options}
                            getOptionLabel={(option: Personnel) => option.compactOrDisplayName}
                            getOptionValue={(option: Personnel) => option.empId}
                            menuPortalTarget={document.body}
                            menuIsOpen={focusedField === "personnel"}
                            onMenuOpen={() => setFocusedField("personnel")}
                            onSelectItem={(multi: boolean) => {
                              !multi && setFocusedField(autoFocusFinished ? null : "assignment");
                            }}
                            onClose={() => handleFinishAutoFocus()}
                          />
                        )}
                      </Field>
                    </div>
                  </Tooltip>
                </Grid>

                {/** Assignment */}
                <Grid item xs={12}>
                  <Tooltip
                    title="Must have a template selected first"
                    disabled={!!values.template}
                    placement="bottom"
                    arrow
                  >
                    <div>
                      <Field name="assignment">
                        {({ field, form, meta }: FieldProps) => (
                          <RenderSelectField
                            field={field}
                            form={form}
                            meta={meta}
                            ref={assignmentRef}
                            id="assignment"
                            label="Assignment"
                            placeholder="Required"
                            disabled={!values.template}
                            getOptionLabel={(o: Assignment) => o.compactOrDisplayName}
                            getOptionValue={(o: Assignment) => o.templateToAssignIdMap[form.values.template.id]}
                            loading={assignmentData.loading}
                            options={assignmentData.options}
                            menuPortalTarget={document.body}
                            menuIsOpen={focusedField === "assignment"}
                            onMenuOpen={() => setFocusedField("assignment")}
                            onSelectItem={(multi: boolean) => {
                              !multi && setFocusedField(autoFocusFinished ? null : "date");
                            }}
                            onClose={() => handleFinishAutoFocus()}
                          />
                        )}
                      </Field>
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            {vrmAssignment ? (
              <Grid item sm={6} xs={12}>
                <Grid container spacing={2}>
                  {vrmAssignment?.blockDuration && (
                    <>
                      <Grid item xs={6} sm={6}>
                        <Label text={"Block Length:"} />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Label text={getBlockLength(vrmAssignment.blockDuration)} />
                      </Grid>
                    </>
                  )}
                  {vrmAssignment?.blockStartDay && (
                    <>
                      <Grid item xs={6} sm={6}>
                        <Label text={"Block Start Day:"} />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Label text={getBlockStartDay(vrmAssignment.blockStartDay)} />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={6} sm={6}>
                    <Label text={"Allotted Days:"} />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Label text={`${vrmAssignment.granted}/${vrmAssignment.allotted} Days`} />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Label text={"Requestable Window:"} />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Label
                      text={`${formatUSDate(vrmAssignment.requestWindowStart)} - ${formatUSDate(
                        vrmAssignment.requestWindowEnd
                      )}`}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Label text={"Pick Window:"} />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Label
                      text={`${formatUSDate(vrmAssignment.pickWindowStart)} - ${formatUSDate(
                        vrmAssignment.pickWindowEnd
                      )}`}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Hidden xsDown>
                <React.Fragment>
                  <Grid item sm={6} />
                </React.Fragment>
              </Hidden>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                {/** Date */}
                <Grid item xs={12}>
                  <Tooltip
                    title="Please select personnel and assignments first"
                    disabled={!!values.assignment.length && !!values.personnel.length}
                    placement="bottom"
                    arrow
                  >
                    <div>
                      <Field name="date">
                        {({ field, form, meta }: FieldProps) => (
                          <RenderDateField
                            field={field}
                            form={form}
                            meta={meta}
                            ref={dateRef}
                            id="date"
                            label="Date"
                            placeholder="Required"
                            variant="inline"
                            disabled={!values.personnel.length || !values.assignment.length}
                            valueType={dateData.valueType}
                            initialFocusedDate={dateData.initialFocusedDate}
                            minDate={dateData.minDate}
                            maxDate={dateData.maxDate}
                            blockData={dateData.blockData}
                            shiftData={dateData.shiftData}
                            limit={dateData.limit}
                            open={focusedField === "date"}
                            onOpen={() => setFocusedField("date")}
                            onClose={() => handleFinishAutoFocus()}
                            views={["year", "month", "date"]}
                            openTo="date"
                          />
                        )}
                      </Field>
                    </div>
                  </Tooltip>
                </Grid>

                {/** Call Order */}
                {(user.isAdmin || user.isSuperAdmin) && callOrderVisible && (
                  <Grid item xs={12}>
                    <Field name="callOrder">
                      {({ field, form, meta }: FieldProps) => (
                        <RenderSelectField
                          lightMode={field.value?.value === "default"}
                          field={field}
                          form={form}
                          meta={meta}
                          id="callOrder"
                          label="Call Order"
                          placeholder="Optional"
                          disableManySelect
                          options={callOrderOptions}
                          getOptionLabel={(option: CallOrder) => option.label}
                          getOptionValue={(option: CallOrder) => option.value}
                          menuPortalTarget={document.body}
                        />
                      )}
                    </Field>
                  </Grid>
                )}

                {/** Reason */}
                {reasonData.options.length > 0 && (
                  <Grid item xs={12}>
                    <Field name="reason">
                      {({ field, form, meta }: FieldProps) => (
                        <RenderSelectField
                          field={field}
                          form={form}
                          meta={meta}
                          id="reason"
                          label="Reason"
                          placeholder="Optional"
                          disableManySelect
                          options={reasonData.options}
                          getOptionLabel={(option: Reason) => option.name}
                          getOptionValue={(option: Reason) => option.loaReasonId}
                          menuPortalTarget={document.body}
                        />
                      )}
                    </Field>
                  </Grid>
                )}

                {/** Locations */}
                {locationData.options.length > 0 && (
                  <Grid item xs={12}>
                    <Field name="locations">
                      {({ field, form, meta }: FieldProps) => (
                        <RenderSelectField
                          field={field}
                          form={form}
                          meta={meta}
                          id="locations"
                          label="Locations"
                          placeholder="Required"
                          disableManySelect={locationData.disableManySelect}
                          options={locationData.options}
                          getOptionLabel={(option: Location) => option.name}
                          getOptionValue={(option: Location) => option.locationId}
                          menuPortalTarget={document.body}
                        />
                      )}
                    </Field>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} style={{ position: "relative" }}>
              {/** Reset times */}
              {shiftTimesData.showResetButton && (
                <Grid container justifyContent="flex-end" style={{ position: "absolute", top: 0, right: 15 }}>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="text"
                      size="small"
                      onClick={() =>
                        setValues((prevValues) => ({
                          ...prevValues,
                          shiftStart: shiftTimesData.defaultStartTime,
                          shiftEnd: shiftTimesData.defaultEndTime,
                        }))
                      }
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {/** Shift start & end */}
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field name="shiftStart">
                        {({ field, form, meta }: FieldProps) => (
                          <RenderTimeField
                            field={field}
                            form={form}
                            meta={meta}
                            id="shift-start"
                            label="Shift start"
                            variant="inline"
                            placeholder={values.shiftEnd ? "Required" : "Optional"}
                            ampm={!settings.useMilitaryTime}
                            disabled={!values.personnel.length || !values.assignment.length}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field name="shiftEnd">
                        {({ field, form, meta }: FieldProps) => (
                          <RenderTimeField
                            field={field}
                            form={form}
                            meta={meta}
                            id="shift-end"
                            label="Shift end"
                            variant="inline"
                            placeholder={values.shiftStart ? "Required" : "Optional"}
                            ampm={!settings.useMilitaryTime}
                            disabled={!values.personnel.length || !values.assignment.length}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>

                {/** Note */}
                {noteData.showField && (
                  <Grid item xs={12}>
                    <Field name="note">
                      {({ field, form, meta }: FieldProps) => (
                        <RenderTextField
                          field={field}
                          form={form}
                          meta={meta}
                          id="note"
                          label="Note"
                          placeholder="Optional"
                          rows={3}
                          multiple
                        />
                      )}
                    </Field>
                  </Grid>
                )}

                {/** Add demand if necessary */}
                {addDemandIfNecessaryData.showField && (
                  <Grid item xs={12}>
                    <Field name="addDemandIfNecessary">
                      {({ field, form, meta }: FieldProps) => (
                        <RenderCheckboxField
                          field={field}
                          form={form}
                          meta={meta}
                          id="add-demand-if-necessary"
                          label="Add Demand if Necessary"
                        />
                      )}
                    </Field>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default AddShiftModalContent;
