import NonStandardStyles, { ExoticStylesCookieName } from "@/_lib/constants/NonStandardStyles";

const normalizeStyle = (style: string): string => style.replace(/ /g, "").replace(/\n/g, "");

const verifyNonStandardStyles = (styleSheet: CSSStyleSheet): string[] => {
  const rules = styleSheet.cssRules;
  const supportedStyles: string[] = [];

  for (const [key, value] of Object.entries(NonStandardStyles)) {
    styleSheet.insertRule(value);

    const result = normalizeStyle(rules[0].cssText) === normalizeStyle(value);

    result && supportedStyles.push(key) && styleSheet.deleteRule(0);
  }

  return supportedStyles;
};

export const getSupportedNonStandardStyles = (): string[] => {
  const styleContainer = document.createElement("style");

  document.head.appendChild(styleContainer);

  const styleSheet = styleContainer.sheet as CSSStyleSheet;

  styleSheet.disabled = true;

  return verifyNonStandardStyles(styleSheet);
};

export const areAllOfTheseExoticStylesSupported = (styles: string[] | string): boolean => {
  if (!styles.length) return false;

  const exoticStylesCookie = document.cookie
    .split(";")
    .find((row) => row.trim().startsWith(ExoticStylesCookieName))
    ?.split("=");

  let exoticStylesCookieValue: string | undefined;

  if (exoticStylesCookie) {
    exoticStylesCookieValue = exoticStylesCookie[1];
  } else {
    exoticStylesCookieValue = undefined;
  }

  switch (typeof styles) {
    case "string": {
      return exoticStylesCookieValue?.includes(styles) ?? false;
    }
    case "object": {
      return Array.isArray(styles) && styles.every((rule: string) => exoticStylesCookieValue?.includes(rule));
    }
    default:
      return false;
  }
};
