// Helper to export data
var moment = require("moment");
var _ = require("underscore");

var ExportHelper = Backbone.Model.extend({
  defaults: {},

  initialize: function (attributes, options) {},

  /* publics */
  download: function (fileName, mimeType) {
    // build content
    var content = this._buildCsv();

    // create a new link, click it, and then remove it
    var a = document.createElement("a");
    mimeType = mimeType || "application/octet-stream";

    if (navigator.msSaveBlob) {
      // IE10
      navigator.msSaveBlob(
        new Blob([content], {
          type: mimeType,
        }),
        fileName
      );
    } else if (URL && "download" in a) {
      //html5 A[download]
      a.href = URL.createObjectURL(
        new Blob([content], {
          type: mimeType,
        })
      );
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      location.href = "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
    }
  },

  /* privates */
  _buildCsv: function () {
    /* STUB? */

    var ret = [];
    var columns = _.map(window.LbsAppData.AppContext.get("view").theme.data.listColumns, function (lc) {
      return '"' + lc + '"';
    });
    ret.push(columns);

    // delimit the lines and return it
    return ret.join("\n");
  },
});

module.exports = ExportHelper;

/* algorithmz */
