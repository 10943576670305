// Set diplay times as 12 or 24 hour

const moment = require("moment");

const TimeHelper = Backbone.Model.extend({
  initialize: function () {},

  /* publics */

  preferredTimeFormat: function (standard) {
    if (!this.isMilitaryTimePreferred()) {
      return standard;
    }

    switch (standard) {
      case "LT z":
        return "HH:mm z";
      case "LT":
        return "HH:mm";
      case "lll":
        return "MMM D YYYY HH:mm";
      case "llll":
        return "ddd, MMM D YYYY HH:mm";
      case "h:mma":
        return "HH:mm";
      case "h:mma (l)":
        return "HH:mm (l)";
      default:
        return standard;
    }
  },

  // This function accepts a string which may contain 12 hour times and, if
  // military time is preferred, will convert them to 24 hour times.
  string24Conversion: function (inputString) {
    if (!this.isMilitaryTimePreferred()) {
      return inputString;
    }

    const globalTimeRegex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/g;
    const timeRegex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/;
    const count = (inputString.match(globalTimeRegex) || []).length;
    let i;
    let replace = inputString;
    for (i = 0; i < count; i++) {
      let times = [];
      times[i] = moment(replace.match(timeRegex), "h:mm a").format("HH:mm");
      replace = replace.replace(timeRegex, times[i]);
    }
    return replace;
  },

  stringTimezoneConversion: function (inputString, timeRegex, count) {
    let replaced = inputString;
    for (var i = 0; i < count; i++) {
      let times = [];
      times[i] = this.timezoneConverter(moment(replaced.match(timeRegex), "h:mm a"))
        .format("h:mm a")
        .replace("am", "[[AM]]")
        .replace("pm", "[[PM]]");

      replaced = replaced.replace(timeRegex, times[i]);
    }
    replaced = replaced.replace(/\[\[/g, "").replace(/\]\]/g, "");
    return replaced;
  },

  fullStringConversion: function (inputString) {
    const globalTimeRegex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/g;
    const timeRegex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/;
    const count = (inputString.match(globalTimeRegex) || []).length;

    // first convert to proper time zone
    const stringWithTZConverted = this.stringTimezoneConversion(inputString, timeRegex, count);

    // Military time converted string
    return this.string24Conversion(stringWithTZConverted, timeRegex, count);
  },

  timezoneConverter: function (inputTime, dbSubmit) {
    const userTimezone = this.userTimezoneChosen();
    if (userTimezone) {
      if (dbSubmit) {
        const dbTimezone = window.LbsAppData.User.get("tz");
        const inputTimeWithUT = moment.tz(inputTime, userTimezone);
        return moment(inputTimeWithUT).tz(dbTimezone);
      } else {
        return moment(inputTime).tz(userTimezone);
      }
    }

    const dbTimezone = window.LbsAppData.User.get("tz");
    return dbTimezone ? moment(inputTime).tz(dbTimezone) : moment(inputTime);
  },

  timezoneConverterSlotHistoryTimestamp: function (inputTime) {
    const historyTZ = window.LbsAppData.HISTORY_TIME_ZONE;
    const userTimezone = this.userTimezoneChosen();
    const inputTimeWithPacificTZ = moment.tz(inputTime, historyTZ);
    return moment(inputTimeWithPacificTZ).tz(userTimezone);
  },

  userTimezoneChosen: function () {
    // if time zone awareness is enabled then use browser time zone, otherwise use DB time zone
    const isTZAwarenessEnabled = window.LbsAppData.User.attributes.parameters.LBLiteTimeZoneAwareness;
    const timezoneChosen = isTZAwarenessEnabled
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : window.LbsAppData.User.get("tz");
    return timezoneChosen;
  },

  timezoneBeforeOrAfter: function () {
    const userTime = moment().tz(this.userTimezoneChosen()).format("YYYYMMDDHHmmss");
    const dbTime = window.LbsAppData.User.get("tz")
      ? moment().tz(window.LbsAppData.User.get("tz")).format("YYYYMMDDHHmmss")
      : userTime;
    if (userTime < dbTime) {
      return "before";
    }
    if (userTime > dbTime) {
      return "after";
    }
    return undefined;
  },

  millisecondsToMinutes: function (milliseconds) {
    return milliseconds / 60000;
  },

  minutesToMilliseconds: function (minutes) {
    return minutes * 60000;
  },

  addMinutesToDate: function (minutes, date) {
    return new Date(date.getTime() + this.minutesToMilliseconds(minutes));
  },

  formatTimeAsPreferred: function (date) {
    if (this.isMilitaryTimePreferred()) {
      return this.formatTimeAsMilitary(date);
    }

    return this.formatTimeAs12Hour(date);
  },

  formatTimeAsMilitary: function (date) {
    return date.getHours() + ":" + this._addLeadingZero(date.getMinutes());
  },

  formatTimeAs12Hour: function (date) {
    let hours = date.getHours();

    let postFix = hours > 11 ? " PM" : " AM";

    if (hours > 12) {
      hours = hours - 12;
    } else if (hours === 0) {
      // Hour 0 is 12 AM
      hours = 12;
    }

    return hours + ":" + this._addLeadingZero(date.getMinutes()) + postFix;
  },

  isMilitaryTimePreferred: function () {
    return window.LbsAppData.User.get("time_display") === window.LbsAppData.MILITARY;
  },

  // This is very similar to the Date function 'toISOString()'
  // But it ignores the time offset and does no time conversions
  formatDateAsIsoString: function (date) {
    const year = date.getFullYear();
    const month = this._addLeadingZero(date.getMonth() + 1);
    const day = this._addLeadingZero(date.getDate());
    const hours = this._addLeadingZero(date.getHours());
    const minutes = this._addLeadingZero(date.getMinutes());
    const seconds = this._addLeadingZero(date.getSeconds());

    return year + "-" + month + "-" + day + "T" + hours + ":" + minutes + ":" + seconds;
  },

  /* privates */
  _addLeadingZero: function (number) {
    const str = number.toString();

    if (str.length < 2) {
      return "0" + str;
    }

    return str;
  },
});

module.exports = TimeHelper;
