"use strict";

// scss requires
require("./_styles.scss");

const React = require("react/addons");

//components
var SwitchButton = require("./SwitchButton.jsx");

const SwitchButtonGroup = React.createClass({
  getInitialState: function () {
    let index = this.props.activeButtonIndex || 0;
    return {
      activeButton: this.props.buttons[index],
    };
  },

  onButtonClick: function (currentButton) {
    if (!(currentButton.props.label === this.state.activeButton)) {
      let callbackFunc = this.props.onItemChange;
      this.setState({ activeButton: currentButton.props.label });
      if (typeof callbackFunc === "function") {
        callbackFunc({
          label: currentButton.props.label,
          index: this.props.buttons.indexOf(currentButton.props.label),
        });
      }
    }
  },

  render: function () {
    let onButtonClick = this.onButtonClick;
    let buttons = this.props.buttons;
    let activeButton = this.state.activeButton;
    return (
      <div className="buttons">
        <ol className="button-list">
          {buttons.map(function (item) {
            return (
              <SwitchButton isButtonActive={activeButton === item} key={item} label={item} onClick={onButtonClick} />
            );
          })}
        </ol>
      </div>
    );
  },
});

module.exports = SwitchButtonGroup;
