import React from "react";
import moment from "moment";
import { Icon, IconButton } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import useUpdateEffect from "@/viewer/ui/modules/common/hooks/useUpdateEffect";
import { isSlot } from "@/viewer/utils/domain/slotquests";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { ErrorOrWarning } from "./AddShiftModal";
import styles from "./styles.module.scss";

interface Props {
  errors: ErrorOrWarning[];
  successes: SlotOrRequest[];
}

const ApiResponse = ({ errors, successes }: Props): React.ReactElement | null => {
  const [showErrorMessages, setShowErrorMessages] = React.useState(true);
  const [showSuccessMessages, setShowSuccessMessages] = React.useState(true);

  React.useLayoutEffect(() => {
    if (errors.length > 0 || successes.length > 0) {
      const alertBoxes = document.getElementsByClassName(styles.alertBox);
      alertBoxes?.[0]?.scrollIntoView();
    }
  }, [errors, successes]);

  useUpdateEffect(() => {
    setShowErrorMessages(true);
  }, [errors]);

  useUpdateEffect(() => {
    setShowSuccessMessages(true);
  }, [successes]);

  const renderAlertBox = (status: "error" | "warning" | "success") => {
    const wasFill = successes.some((s) => isSlot(s));

    const handleOnClose = () => {
      switch (status) {
        case "error":
          return setShowErrorMessages(false);
        case "success":
          return setShowSuccessMessages(false);
      }
    };

    const getTitle = () => {
      switch (status) {
        case "error":
          return "The following errors occurred:";
        case "success":
          return `The following ${wasFill ? "fills" : "requests"} saved successfully:`;
      }
    };

    const getListItems = () => {
      switch (status) {
        case "error":
          return errors.map(({ date, message }, i) => (
            <li key={`${i}-${date}-${message}`}>
              {moment(date).format("MMM DD, YYYY")} - {message}
            </li>
          ));
        case "success":
          return successes.map((slotQuest: SlotOrRequest) => (
            <li key={slotQuest.id}>
              {moment(slotQuest.date).format("MMM DD, YYYY")} - {slotQuest.compactOrDisplayName} on{" "}
              {slotQuest.assignCompactOrDisplayName}
            </li>
          ));
      }
    };

    return (
      <Alert className={styles.alertBox} severity={status}>
        <IconButton className={styles.closeIcon} onClick={() => handleOnClose()}>
          <Icon className="fa fa-times" />
        </IconButton>
        <AlertTitle>{getTitle()}</AlertTitle>
        <ul>{getListItems()}</ul>
      </Alert>
    );
  };

  return (
    <>
      {showSuccessMessages && successes.length > 0 && renderAlertBox("success")}
      {showErrorMessages && errors.length > 0 && renderAlertBox("error")}
    </>
  );
};

export default ApiResponse;
