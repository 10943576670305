const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var Department = require("./../models/Department.js");

var DepartmentCollection = Backbone.Collection.extend({
  model: Department,
  comparator: "name",

  url: ApiConfig.getPrefix() + "/departments",
  //parse: function(response) {
  //	return response[0];
  //}
});

module.exports = DepartmentCollection;
