const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var PersonnelType = require("./../models/PersonnelType.js");

var PersonnelTypeCollection = Backbone.Collection.extend({
  model: PersonnelType,
  comparator: "name",

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    // views will only ever be for the template that the current schedule is in
    return ApiConfig.getPrefix() + "/personnel/type";
  },
});

module.exports = PersonnelTypeCollection;
