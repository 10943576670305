import { useEffect, useState } from "react";
import ConfigHelper from "@/_lib/data/ConfigHelper";
import { get } from "@/_lib/utils/fetch";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useActivityFeedIconData() {
  const [hasPendingItems, setHasPendingItems] = useState<boolean | null>(false);
  const host = ConfigHelper.host_lbapi;

  const getHasPendingItems = () => {
    const url = `${host}/activityfeed/hasPendingItems`;

    (async () => {
      const { status, data } = await get<boolean>(url);
      // We check the status because the data is boolean and it can be false
      if (status === 200) {
        setHasPendingItems(data);
      }
    })();
  };

  useEffect(() => {
    getHasPendingItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    hasPendingItems,
  };
}
