const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var ReportDefinition = require("./../models/ReportDefinition.js");

var ReportDefinitionCollection = Backbone.Collection.extend({
  model: ReportDefinition,
  comparator: "created_stamp",

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    return ApiConfig.getPrefix() + "/report/view";
  },
});

module.exports = ReportDefinitionCollection;
