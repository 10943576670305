import * as React from "react";

// dependencies
import classnames from "@/_lib/utils/classnames";

import { getEffects, getPersonnelOrAssignmentId } from "@/viewer/utils/domain/perssignment";

import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { PersonnelOrAssignment } from "@/viewer/ui/modules/common/types";
import { CallbackContext } from "@/viewer/ui/modules/common/helpers/callbacks";

interface Props {
  index: number;
  settings: SettingsContext;
  ui: UIContext;
  leftCol: PersonnelOrAssignment;
  children: React.ReactNode;
}

const StandardWeekDataRow = (props: Props): JSX.Element | null => {
  const { index, settings, ui, leftCol, children } = props;

  const { leftColumnType } = ui;
  const leftColId = getPersonnelOrAssignmentId(leftCol);

  const { openFilterContextMenu } = React.useContext(CallbackContext);
  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    openFilterContextMenu({
      items: [
        {
          text: 'Only Show "' + leftCol.compactOrDisplayName + '"',
          icon: "fa-filter",
          type: leftColumnType,
          contextData: {
            objectId: leftColId,
          },
        },
      ],
      top: e.pageY,
      left: e.pageX,
    });
  };

  const className = classnames("DataRow", {
    even: index % 2 === 0,
    odd: index % 2 !== 0,
  });

  const leftColText = leftCol.compactOrDisplayName;
  const effects = getEffects(leftCol, settings);

  return (
    <div className={className} style={effects}>
      <div className="leftCol" data-cy="leftCol" style={effects} onContextMenu={handleContextMenu}>
        {leftColText}
      </div>
      {children}
    </div>
  );
};

export default StandardWeekDataRow;
