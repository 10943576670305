import { SettingsContext } from "@/viewer/ui/modules/common/types/context";
import { toDate } from "date-fns-tz";
import constructSearchCorpus, { SearchCorpusParams } from "@/_lib/utils/constructSearchCorpus";

export interface RequestAttributes {
  boundTool: any;
  request_id: number;
  request_date: string; // 2020-06-27
  request_date_original: string | null | undefined; // 2020-06-27
  emp_id: number;
  assign_id: number;
  command_type: 0 | unknown;
  status: "granted" | "denied" | string;
  template: string | null;
  template_id: number;
  template_desc: string | null;
  department_id: number;
  target_template: string;
  target_template_id: number;
  target_template_desc: string;
  target_department_id: number;
  start_time: string;
  stop_time: string;
  timestamp: string;
  message: string | null;
  display_name: string;
  compact_name: string;
  last_name: string;
  assign_display_name: string;
  assign_compact_name: string;
  condensed_structure_id: string | null;
  created_by_emp_id: number | null;
  created_by_display_name: string | null;
  modified_by_emp_id: number | null;
  modified_by_display_name: string | null;
  modified_timestamp: string | null;
  violation_msg: null | unknown;
  decision_msg: string;
  loa_reason_id: null | unknown;
  loa_reason_name: null | unknown;
  location_ids: number[];
  location_names: string[];
  denial_reason_id: null | unknown;
  denial_reason_name: null | string;
  assign_structure_id: number;
  parent_assign_structure_id: number | null;
  emp_ptype: number | null;
  assign_atype: number | null;
  available: boolean;
}

// Cleaned up version of SlotAttributes
export interface Request {
  [index: string]: Request[keyof Request];

  id: number | "";
  date: Date;
  dateString: string;
  startTime: Date;
  startTimeString: string;
  stopTime: Date;
  stopTimeString: string;
  status: "granted" | "denied" | "pending" | string;
  available: boolean;

  message: string | null;
  displayName: string;
  compactOrDisplayName: string;
  assignCompactOrDisplayName: string;
  templateDesc: string;
  locationNames: string[];

  empId: number | "";
  assignStructureId: number | "";
  assignId: number | "";
  condensedStructureId: string;
  templateId: number | "";
  assign_atype: number | null;
  emp_ptype: number | null;

  keyValueSearchCorpus?: string;
  searchCorpus?: string;
}

export const cleanRequestAttributes = (
  settings: SettingsContext,
  attrs: Partial<RequestAttributes>,
  searchCorpusParams?: SearchCorpusParams
): Request => {
  const request: Request = {
    id: attrs.request_id ?? "",
    date: toDate(attrs.request_date),
    dateString: attrs.request_date ?? "",
    startTime: toDate(attrs.start_time),
    startTimeString: attrs.start_time ?? "",
    stopTime: toDate(attrs.stop_time),
    stopTimeString: attrs.stop_time ?? "",
    status: attrs.status ?? "",
    available: attrs.available ?? false,
    displayName: attrs.display_name ?? "",
    compactOrDisplayName: settings.isCompact ? attrs.compact_name ?? "" : attrs.display_name ?? "",
    assignCompactOrDisplayName: settings.isCompact ? attrs.assign_compact_name ?? "" : attrs.assign_display_name ?? "",
    templateDesc: attrs.template_desc ?? "",
    locationNames: attrs.location_names ?? [],
    departmentId: attrs.department_id ?? null,

    message: attrs.message ?? "",
    empId: attrs.emp_id ?? "",
    assignId: attrs.assign_id ?? "",
    assignStructureId: attrs.assign_structure_id ?? "",
    condensedStructureId: attrs.condensed_structure_id ?? "",
    templateId: attrs.template_id ?? "",
    assign_atype: attrs.assign_atype ?? null,
    emp_ptype: attrs.emp_ptype ?? null,
  };

  const searchableAttributes = constructSearchCorpus(request, searchCorpusParams);
  request.keyValueSearchCorpus = searchableAttributes?.keyValueSearchCorpus;
  request.searchCorpus = searchableAttributes?.searchCorpus;

  return request;
};
