import { Action } from "./actions";

export interface State {
  accessToken: string | null;
}

export const initialState = (): State => ({
  accessToken: null,
});

const reducer = (state: State | undefined, action: Action): State => {
  if (!state) {
    return reducer(initialState(), action);
  }

  switch (action.type) {
    case "access-token/update":
      return { ...state, accessToken: action.accessToken };
  }

  return state;
};

export default reducer;
