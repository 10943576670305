import React from "react";
import moment from "moment";
import { Collection } from "backbone";

import { getWeekRange } from "@/viewer/ui/modules/common/helpers/dates";
import { dateRange } from "@/viewer/utils/helpers";
import { formatISODate, formatUSDate } from "@/viewer/utils/dateFormatters";
import { parseQuery } from "@/_lib/utils/queryString";

import {
  DataContext,
  FilterContext,
  SettingsContext,
  ToolContext,
  UIContext,
} from "@/viewer/ui/modules/common/types/context";

import useCurrentlyLoggedInUser from "@/viewer/ui/modules/common/hooks/useCurrentlyLoggedInUser";
import useFilteredRequestData from "@/viewer/ui/modules/common/hooks/useFilteredRequestData";
import useFilteredSlotData from "@/viewer/ui/modules/common/hooks/useFilteredSlotData";
import useRequestedMap from "@/viewer/ui/modules/grid/calendar/hooks/useRequestedMap";
import useScheduledMap from "@/viewer/ui/modules/grid/calendar/hooks/useScheduledMap";

import CalendarDateBox from "@/viewer/ui/modules/grid/calendar/components/CalendarDateBox";
import { OriginalPersonnelMap } from "@/viewer/types/viewdata";
import { DepartmentsById } from "@/viewer/types/domain/department";

const RenderCalendarWeeksDateRange = (
  settings: SettingsContext,
  ui: UIContext,
  filters: FilterContext,
  data: DataContext,
  tools: ToolContext,
  startDate: Date,
  stopDate: Date,
  slotCollection: Collection,
  requestCollection: Collection,
  originalPersonnelMap: OriginalPersonnelMap,
  departmentsById: DepartmentsById
): JSX.Element[] => {
  const { LbsAppData } = window as any;
  const { date: dateQueryParam } = parseQuery(window.location.search);

  const weekContainers: JSX.Element[] = [];

  const currentlyLoggedInUser = useCurrentlyLoggedInUser();
  const slots = useFilteredSlotData(settings, ui, filters, data, slotCollection);
  const scheduled = useScheduledMap(settings, ui, slots);
  const requests = useFilteredRequestData(settings, ui, filters, data, requestCollection);
  const requested = useRequestedMap(settings, ui, requests, filters, data);

  getWeekRange(settings, ui, startDate, stopDate).forEach(([weekStart, weekEnd]) => {
    const weekCells = dateRange(weekStart, weekEnd).map((rollingDate) => {
      const date = formatISODate(settings, rollingDate);

      return (
        <CalendarDateBox
          key={date}
          dateQueryParam={dateQueryParam}
          date={rollingDate}
          tools={tools}
          scheduled={scheduled[date] ?? []}
          requested={requested[date] ?? []}
          settings={settings}
          ui={ui}
          data={data}
          focusedStartDate={startDate}
          myEmpId={currentlyLoggedInUser?.empId || -1}
          isHoliday={LbsAppData.AppContext._isHoliday(moment(rollingDate))}
          originalPersonnelMap={originalPersonnelMap}
          departmentsById={departmentsById}
        />
      );
    });

    weekContainers.push(
      <div key={weekStart.getTime()} className="WeekContainer WeeksDateRangeContainer">
        <div className="DateRange">
          <h5>
            {formatUSDate(startDate)}-{formatUSDate(stopDate)}
          </h5>
        </div>
        <div className="WeekCellsContainer">{weekCells}</div>
      </div>
    );
  });

  return weekContainers;
};

export default RenderCalendarWeeksDateRange;
