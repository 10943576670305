import moment, { Moment } from "moment-timezone";

import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { ListViewPartitionCategory } from "@/viewer/ui/modules/grid/list/types";

import { isToday } from "@/viewer/ui/modules/common/helpers/dates";
import { getNow } from "@/_lib/utils/time";
import { DateTime } from "luxon";

/**
 * Iterates over the provided search terms and returns true if the slot or request's search corpus contains all of the
 * provided terms.
 *
 * @param slotquest
 * @param searchTerms
 */
export const checkSearchTerms = (slotquest: SlotOrRequest, searchTerms: string[]): boolean => {
  if (!slotquest.available) return false;

  // If we don't have either search corpus, just bail out and don't bother filtering.
  if (!slotquest.keyValueSearchCorpus || !slotquest.searchCorpus) return true;

  const keyValueSearchCorpus = slotquest.keyValueSearchCorpus.toLowerCase();
  const searchCorpus = slotquest.searchCorpus.toLowerCase();

  return searchTerms.every((searchTerm) => {
    searchTerm = searchTerm.toLowerCase();

    if (searchTerm.includes("=")) {
      return keyValueSearchCorpus.includes(searchTerm);
    }

    return searchCorpus.includes(searchTerm);
  });
};

/**
 * Determines what partition of the list view the slot or request will fall into.
 *
 * - If the provided time is within the slot or requests time bounds "Current" will be returned.
 * - If the provided time is before the slot or requests start time then "Upcoming" will be returned.
 * - If the provided time is after the slot or requests end time then "Finished" will be returned.
 *
 * @param settings
 * @param ui
 * @param now
 * @param slotquest
 */
export const getModelTimeState = (
  settings: SettingsContext,
  ui: UIContext,
  slotquest: SlotOrRequest
): ListViewPartitionCategory => {
  if (!isToday(settings, ui)) return ListViewPartitionCategory.Upcoming;

  const DBTimeZone = settings.timeZone;
  const timeZoneAware = settings.timeZoneEnabled;

  const slotStartTimeAsDBTimeZone = timeZoneAware
    ? DateTime.fromISO(slotquest.startTimeString)
    : DateTime.fromISO(slotquest.startTimeString, { zone: DBTimeZone ?? "America/New_York" });
  const slotStopTimeAsDBTimeZone = timeZoneAware
    ? DateTime.fromISO(slotquest.stopTimeString)
    : DateTime.fromISO(slotquest.stopTimeString, { zone: DBTimeZone ?? "America/New_York" });

  const slotStartTimeAsLocalTimeZone = slotStartTimeAsDBTimeZone.toLocal();
  const slotStopTimeAsLocalTimeZone = slotStopTimeAsDBTimeZone.toLocal();

  // negative if current time is after start time
  const slotStartTimeAsLocalTimeIsOnOrAfterLocalNowTime = slotStartTimeAsLocalTimeZone.diffNow("minutes");

  // negative if current time is after stop time
  const slotStopTimeAsLocalTimeIsAfterLocalNowTime = slotStopTimeAsLocalTimeZone.diffNow("minutes");

  if (
    slotStartTimeAsLocalTimeIsOnOrAfterLocalNowTime.get("minutes") <= 0 &&
    slotStopTimeAsLocalTimeIsAfterLocalNowTime.get("minutes") >= 0
  ) {
    return ListViewPartitionCategory.Current;
  } else if (slotStartTimeAsLocalTimeIsOnOrAfterLocalNowTime.minutes > 0) {
    return ListViewPartitionCategory.Upcoming;
  } else if (
    slotStartTimeAsLocalTimeIsOnOrAfterLocalNowTime.minutes < 0 &&
    slotStopTimeAsLocalTimeIsAfterLocalNowTime.minutes <= 0
  ) {
    return ListViewPartitionCategory.Finished;
  }

  return ListViewPartitionCategory.Finished;
};

/**
 * Return whether the provided moment is within the slot or request's startTime and stopTime,
 * taking into account timezones.
 *
 * @param slotquest
 * @param time
 * @param tz
 */
export const isWithinSlotQuestTimeBounds = (slotquest: SlotOrRequest, time: Moment): boolean => {
  return time.isAfter(moment(slotquest.startTimeString)) && time.isBefore(moment(slotquest.stopTimeString));
};

/**
 * Partition the slots and requests into working and not working sections within an array.
 *
 * If the dateMode is anything other than `daily`, returns the original array.
 *
 * @param settings
 * @param ui
 * @param slotquests
 */
export const partitionWorkingSlotQuests = (
  settings: SettingsContext,
  ui: UIContext,
  slotquests: SlotOrRequest[]
): SlotOrRequest[] => {
  if (ui.dateMode === "daily") {
    const now = getNow(settings);
    const working: SlotOrRequest[] = [];
    const notWorking: SlotOrRequest[] = [];

    slotquests.forEach((slotquest) => {
      if (isWithinSlotQuestTimeBounds(slotquest, now)) {
        working.push(slotquest);
      } else {
        notWorking.push(slotquest);
      }
    });

    return [...working, ...notWorking];
  } else {
    return slotquests;
  }
};
