const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");

var Role = Backbone.Model.extend({
  idAttribute: "assign_id",

  defaults: {},

  initialize: function () {
    // loop through and push this assignment id onto the personnel members
    for (var i = 0; i < this.attributes.members.length; i++) {
      var member = this.attributes.members[i];
      var personnel = window.LbsAppData.Personnel.get(member);
      if (personnel) {
        window.LbsAppData.Personnel.get(member).roles.push(this.attributes.assign_id);
      } else {
        // this should be alright...just dont' want to error here for this...
        // most likely this is some awesome case of department level permissions at work
        // the roles endpoint has zero permissions...so it's possible things trickle in that people don't have access to
        // console.log(member);
      }
    }
  },

  url: function () {
    return ApiConfig.getPrefix() + (this.id ? "/roles/" + this.id : "/roles");
  },
});

module.exports = Role;
