/*
 * Template model that doesn't depend on window object bindings */
const Backbone = require("backbone");

var NakedDenialReason = Backbone.Model.extend({
  idAttribute: "id",

  defaults: {},

  initialize: function (attributes, options) {},
});

module.exports = NakedDenialReason;
