import React from "react";

import { AppLayout, UIContext } from "@/viewer/ui/modules/common/types/context";
import { PageScale, PageSize, PrintOrientation, PrintType } from "@/_lib/ui/modules/print/PrintContainer";

import Dialog from "./Dialog";
import Slider from "@/common/components/slider/slider";
import Button from "@/common/components/button/Button";
import InformativeIcon, { IconType } from "@/components/InformativeIcon";
import CustomPopper from "@/components/CustomPopper";
import { COLUMN_PRINTING_NOT_SUPPORTED_MSG } from "@/_lib/ui/modules/print/constants";

interface Props {
  ui: UIContext;
  print: (startDate: Date, endDate: Date) => void;
  setPrintType: (printType: PrintType) => void;
  setPageScale: (pageScale: PageScale) => void;
  setPageSize: (pageSize: PageSize) => void;
  setPrintOrientation: (printOrientation: PrintOrientation) => void;
  loading: boolean;
  pageSize: PageSize;
  pageScale: PageScale;
  printOrientation: PrintOrientation;
  isColumnPrintingSupported: boolean;
  layout?: AppLayout;
  printType?: PrintType;
}

const PrintDialog = (props: Props): JSX.Element => {
  const {
    setPrintOrientation,
    setPrintType,
    setPageScale,
    setPageSize,
    print,
    printType,
    loading,
    ui,
    layout,
    printOrientation = PrintOrientation,
    pageScale,
    pageSize,
    isColumnPrintingSupported,
  } = props;

  const [isPopperVisible, setIsPopperVisible] = React.useState(false);
  const [hasShownColPrintNotSupportedMsg, setHasShownColPrintNotSupportedMsg] = React.useState(false);
  const isGridLayout = React.useCallback((): boolean => (layout && layout === "grid") || false, [layout]);

  const buttonDisabledRef = React.useRef<boolean>(false);
  const popperRef = React.useRef<HTMLDivElement>(null);
  const iconRef = React.useRef<HTMLDivElement>(null);

  const handleMouseEnter = (): void => {
    setIsPopperVisible(true);
  };

  const handleMouseLeave = (): void => {
    if (buttonDisabledRef.current) return;

    setIsPopperVisible(false);
  };

  const userHasAcknowledgedPopper = (e: React.MouseEvent): void => {
    buttonDisabledRef.current = false;
    setIsPopperVisible(false);
  };

  const getMsgPopper = (): JSX.Element => {
    const popperContent = (
      <div className={"column-print__popper-container"} ref={popperRef}>
        <span>{COLUMN_PRINTING_NOT_SUPPORTED_MSG}</span>
        {buttonDisabledRef.current && (
          <Button type="button" onClick={userHasAcknowledgedPopper} style={{ marginTop: "20px", float: "right" }}>
            Okay
          </Button>
        )}
      </div>
    );

    return (
      <CustomPopper
        content={popperContent}
        anchor={iconRef}
        isOpen={isPopperVisible}
        placement="top-end"
        style={{
          backgroundColor: "white",
          border: "1px solid rgba(0,0,0,0.2)",
          fontSize: "14px",
          width: "250px",
          height: "auto",
          padding: "10px",
          borderRadius: "5px",
          lineHeight: "25px",
          zIndex: 3333,
        }}
      />
    );
  };

  const msgPopper = React.useMemo(getMsgPopper, [isPopperVisible]);

  const getInfoIcon = (): JSX.Element => {
    if (isColumnPrintingSupported || !isGridLayout()) return <></>;

    return (
      <div ref={iconRef}>
        <InformativeIcon
          iconType={IconType.warning}
          pulse={true}
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        {msgPopper}
      </div>
    );
  };

  const infoIcon = React.useMemo(getInfoIcon, [msgPopper]);

  const getColumnsPrintOptions = (): JSX.Element => {
    return (
      <>
        <div className="container mb-20">
          <div className="option-label">Print Orientation</div>
          <div className="flex">
            <label className="radio-btn mr-20">
              <input
                type="radio"
                checked={printOrientation === PrintOrientation.Portrait}
                onChange={() => setPrintOrientation(PrintOrientation.Portrait)}
              />
              <span className="checkmark" />
              Portrait
            </label>
            <label className="radio-btn">
              <input
                type="radio"
                checked={printOrientation === PrintOrientation.Landscape}
                onChange={() => setPrintOrientation(PrintOrientation.Landscape)}
              />
              <span className="checkmark" />
              Landscape
            </label>
          </div>
        </div>
        <div className="mb-20">
          <div className="option-label">Page Size</div>
          <div className="flex">
            <label className="radio-btn mr-20">
              <input
                type="radio"
                checked={pageSize === PageSize.Letter}
                onChange={() => setPageSize(PageSize.Letter)}
              />
              <span className="checkmark" />
              Letter
            </label>
            <label className="radio-btn">
              <input type="radio" checked={pageSize === PageSize.A4} onChange={() => setPageSize(PageSize.A4)} />
              <span className="checkmark" />
              A4
            </label>
          </div>
        </div>
        <>
          <div className="option-label">Page Scale</div>
          <>
            <Slider
              min={0.1}
              max={1}
              valueDisplayMultiplier={100}
              label={`Scale: `}
              value={pageScale}
              changeHandler={setPageScale}
              step={0.01}
              unit={"%"}
            />
          </>
        </>
      </>
    );
  };

  const getNonColumnsPrintOptions = (): JSX.Element => (
    <>
      <div className="option-label">Print Settings</div>
      <div className="flex">
        <label className="radio-btn mr-20">
          <input
            type="radio"
            checked={printType === PrintType.Minimal}
            onChange={() => setPrintType(PrintType.Minimal)}
          />
          <span className="checkmark" />
          Minimal
        </label>
        <label className="radio-btn">
          <input
            type="radio"
            checked={printType === PrintType.PageSpacing}
            onChange={() => setPrintType(PrintType.PageSpacing)}
          />
          <span className="checkmark" />
          One Week Per Page
        </label>
      </div>
      <>
        <div className={"column-print__warning-container"}>{infoIcon}</div>
      </>
    </>
  );

  const getInternalContent = (): JSX.Element => (
    <>
      <div className="mt-20 mb-20" onClick={(e: React.MouseEvent) => e.stopPropagation()}>
        {isGridLayout() && isColumnPrintingSupported ? getColumnsPrintOptions() : getNonColumnsPrintOptions()}
      </div>
    </>
  );

  const handleActionClick = (startDate: Date, endDate: Date): void => {
    if (isGridLayout() && !isColumnPrintingSupported && !hasShownColPrintNotSupportedMsg) {
      setIsPopperVisible(true);
      setHasShownColPrintNotSupportedMsg(true);
      buttonDisabledRef.current = true;

      return;
    }

    print(startDate, endDate);
  };

  return (
    <Dialog
      ui={ui}
      icon="fa-print"
      loading={loading || buttonDisabledRef.current}
      actionText="Print"
      actionOnClick={handleActionClick}
      ignoreClickEventTargetRefs={[popperRef]}
      ignoreClickOutside={isPopperVisible}
    >
      {getInternalContent()}
    </Dialog>
  );
};

export default PrintDialog;
