import React from "react";

import { FilterContext, SettingsContext } from "@/viewer/ui/modules/common/types/context";
import { ViewData } from "@/viewer/types/viewdata";

import { makeDataContext } from "@/viewer/ui/modules/common/helpers/context";
import useToolContext from "@/viewer/ui/modules/common/hooks/useToolContext";
import usePersonnelData from "@/viewer/ui/modules/common/hooks/usePersonnelData";
import useAssignmentData from "@/viewer/ui/modules/common/hooks/useAssignmentData";
import useFilteredTemplates from "@/viewer/ui/modules/common/hooks/useFilteredTemplates";
import useFilteredDepartments from "@/viewer/ui/modules/common/hooks/useFilteredDepartments";

const useViewData = (settings: SettingsContext): ViewData => {
  const tools = useToolContext();

  const { personnel, filteredPersonnel } = usePersonnelData(settings);
  const { assignments, filteredAssignments } = useAssignmentData(settings);

  // Build data context

  const data = React.useMemo(() => makeDataContext(assignments, personnel), [assignments, personnel]);
  const originalPersonnelMap = React.useMemo(() => {
    const opMap = new Map();
    personnel.forEach(({ empId, compactOrDisplayName }) => {
      opMap.set(empId, { empId, compactOrDisplayName });
    });

    return opMap;
  }, [personnel]);
  // Build filter context

  const filteredTemplates = useFilteredTemplates();
  const filteredDepartments = useFilteredDepartments();
  const filters: FilterContext = React.useMemo(
    () => ({
      filteredAssignments,
      filteredDepartments,
      filteredPersonnel,
      filteredTemplates,
    }),
    [filteredAssignments, filteredPersonnel, filteredTemplates, filteredDepartments]
  );

  return {
    originalPersonnelMap,
    personnel,
    assignments,
    data,
    filters,
    tools,
  };
};

export default useViewData;
