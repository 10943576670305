"use strict";

const React = require("react/addons");

const TouchMixin = {
  isDragging: false,
  _endTouch: function (callback, event) {
    // if user isn't currently dragging, callback...otherwise nothing happens
    if (!this.isDragging) {
      callback(event);
    }
  },

  _startTouch: function () {
    this.isDragging = false;
  },

  _moveTouch: function () {
    this.isDragging = true;
  },

  touchProxy: function (fn, args, e) {
    fn.apply(this, args);
    if (e.type === "touchend") {
      e.preventDefault();
    }
  },
};

module.exports = TouchMixin;
