"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var _ = require("underscore");
var moment = require("moment");
var Promise = require("bluebird");
// dialogs
var DateSelectDialog = require("./date_select/DateSelectDialog.jsx");
var ErrorDialog = require("./error/ErrorDialog.jsx");
var TimeEditDialog = require("./time_edit/TimeEditDialog.jsx");
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");

var ScheduleEditsExtBase = React.createClass({
  mixins: [TouchMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {};
  },
  componentDidMount: function () {},
  componentWillUnmount: function () {},

  /* publics */
  close: function (e) {
    this.props.close();
  },
  /*submit: function(dates) {
		this.props.dialogObject.submit(dates);
	},*/

  /* privates */

  /* render */
  render: function () {
    /* jshint ignore:start */
    var dialog;
    switch (this.props.dialogObject.type) {
      case "schedule-edits-date-select":
        dialog = (
          <DateSelectDialog
            ref="DialogChild"
            dialogObject={this.props.dialogObject}
            isTop={this.props.isTop}
            close={this.close}
            submit={this.props.dialogObject.submit}
          />
        );
        break;
      case "schedule-edits-error":
        dialog = (
          <ErrorDialog
            ref="DialogChild"
            dialogObject={this.props.dialogObject}
            isTop={this.props.isTop}
            close={this.close}
            submit={this.props.dialogObject.callback}
          />
        );
        break;
      case "schedule-edits-details-error":
        dialog = (
          <ErrorDialog
            ref="DialogChild"
            isDetailsError={true}
            dialogObject={this.props.dialogObject}
            isTop={this.props.isTop}
            close={this.close}
            submit={this.props.dialogObject.callback}
          />
        );
        break;
      case "schedule-edits-fill-error":
        dialog = (
          <ErrorDialog
            ref="DialogChild"
            isFillError={true}
            dialogObject={this.props.dialogObject}
            isTop={this.props.isTop}
            close={this.close}
            submit={this.props.dialogObject.callback}
          />
        );
        break;
      case "schedule-edits-time-edit":
        dialog = (
          <TimeEditDialog
            ref="DialogChild"
            dialogObject={this.props.dialogObject}
            isTop={this.props.isTop}
            close={this.close}
            submit={this.props.dialogObject.updateTimes}
          />
        );
        break;
      default:
        break;
    }

    return dialog;
  },
});

module.exports = ScheduleEditsExtBase;
