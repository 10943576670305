/* eslint @typescript-eslint/no-var-requires: "off" */
"use strict";

// scss requires

require("./_styles.scss");
// dependencies
const React = require("react/addons");
const ReactDOM = require("react-dom");
const moment = require("moment");
const Promise = require("bluebird");
const _ = require("underscore");
// collections
const AssignmentStructure = require("viewer/data/models/AssignmentStructure.js");
const DepartmentCollection = require("_lib/data/collections/NakedDepartmentCollection.js");
const TemplateCollection = require("_lib/data/collections/NakedTemplateCollection.js");
const AssignmentCollection = require("_lib/data/collections/NakedAssignmentCollection.js");
const PersonnelCollection = require("_lib/data/collections/NakedPersonnelCollection.js");
const LoaReasonCollection = require("_lib/data/collections/NakedLoaReasonCollection.js");
const DenialReasonCollection = require("_lib/data/collections/NakedDenialReasonCollection.js");
const LocationCollection = require("_lib/data/collections/NakedLocationCollection.js");
const SlotCollection = require("_lib/data/collections/NakedSlotCollection.js");
const CompatibilityMap = require("_lib/data/models/NakedCompatibilityMap");

const SwapCollection = require("_lib/data/collections/SwapCollection.js");
const SwapSplitShift = require("_lib/data/models/swaps/SwapSplitShift.js");
const SwapFill = require("_lib/data/models/swaps/SwapFill.js");
const SwapReplace = require("_lib/data/models/swaps/SwapReplace.js");
const SwapAssignment = require("_lib/data/models/swaps/SwapAssignment.js");
const SwapExchange = require("_lib/data/models/swaps/SwapExchange.js");
const SwapRemove = require("_lib/data/models/swaps/SwapRemove.js");
const SwapDetail = require("_lib/data/models/swaps/SwapDetail.js");
const SwapDelete = require("_lib/data/models/swaps/SwapDelete.js");
const SwapGrant = require("_lib/data/models/swaps/SwapGrant.js");
const SwapDeny = require("_lib/data/models/swaps/SwapDeny.js");
const RequestCollection = require("_lib/data/collections/RequestCollection.js");
const RequestNew = require("_lib/data/models/requests/RequestNew.js");
const RequestDelete = require("_lib/data/models/requests/RequestDelete.js");
const RequestGrant = require("_lib/data/models/requests/RequestGrant.js");
const RequestDeny = require("_lib/data/models/requests/RequestDeny.js");
const RequestSuspend = require("_lib/data/models/requests/RequestSuspend.js");
const PreSwapCollection = require("_lib/data/collections/PreSwapCollection.js");
const PreSwapCreate = require("_lib/data/models/preswaps/PreSwapCreate.js");
const PreSwapDelete = require("_lib/data/models/preswaps/PreSwapDelete.js");
const PreSwapAccept = require("_lib/data/models/preswaps/PreSwapAccept.js");
const PreSwapDeny = require("_lib/data/models/preswaps/PreSwapDeny.js");
const PreSwapFinalize = require("_lib/data/models/preswaps/PreSwapFinalize.js");

// dialogs
const BulkActionDialogSlotList = require("./bulk/BulkActionDialogSlotList.jsx");
const SwapFillDialog = require("./swaps/fill/SwapFillDialog.jsx");
const SwapReplaceDialog = require("./swaps/replace/SwapReplaceDialog.jsx");
const SwapExchangeDialog = require("./swaps/exchange/SwapExchangeDialog.jsx");
const SwapRemoveDialog = require("./swaps/remove/SwapRemoveDialog.jsx");
const SwapDetailsDialog = require("./swaps/details/SwapDetailsDialog.jsx");
const SplitShiftDialog = require("./swaps/split_shift/SplitShiftDialog.jsx");
const SwapPendingDialog = require("./swaps/pending/SwapPendingDialog.jsx");
const PreSwapPendingDialog = require("./swaps/pending/PreSwapPendingDialog.jsx");
const PreSwapFinalizeDialog = require("./swaps/finalize/PreSwapFinalizeDialog.jsx");
const RequestActionDialog = require("./swaps/pending/RequestActionDialog.jsx");
const MoveDialog = require("@/_lib/ui/modules/dialog/types/schedule_edits/swaps/move/MoveDialog").default;

// components
const DatePicker = require("_lib/ui/modules/widgets/datepicker/DatePicker.jsx");
const TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
const CustomTimeMixin = require("_lib/ui/mixins/CustomTimeMixin.jsx");
const SafeJSON = require("../../../../../../common/utils/jsonParseShield");
const { LDFlagEnums } = require("@/_lib/constants/LDFlagEnums");

const getReqWindowStartOrLatestScheduledMoment = require("_lib/utils/time.ts").getReqWindowStartOrLatestScheduledMoment;

const ScheduleEditsBase = React.createClass({
  mixins: [TouchMixin, CustomTimeMixin],
  /* lifecycle methods */
  getInitialState: function () {
    // check to see which app we're in
    if (window.LbsAppData.id === "viewer") {
      const seedData = this._getSeedData(this.props.dialogObject.data);

      return seedData;
    } else {
      // login app -- don't have ancillary collections so go get them before
      // doing anything else
      const promises = [];
      if (!window.LbsAppData.LoaReasons) {
        promises.push(window.LbsAppData.fetchData("LoaReasons", new LoaReasonCollection()));
      }
      if (!window.LbsAppData.DenialReasons) {
        promises.push(window.LbsAppData.fetchData("DenialReasons", new DenialReasonCollection()));
      }
      if (!window.LbsAppData.Locations) {
        promises.push(window.LbsAppData.fetchData("Locations", new LocationCollection()));
      }

      var that = this;
      Promise.all(promises).then(function () {
        const seedData = that._getSeedData(that.props.dialogObject.data);
        that.setState(
          _.extend({}, seedData, {
            isLoading: false,
            fillAssignStructure: undefined,
          })
        );
      });

      return {
        isLoading: true,
        showWarningDialog: false,
      };
    }
  },
  componentDidMount: function () {
    if (this.props.dialogObject.type === "swap-details") {
      this._checkForSlotStructure();
    }
  },

  /* privates */
  _isSwap: function () {
    switch (this.props.dialogObject.type) {
      case "swap-fill":
      case "swap-replace":
      case "split-shift":
      case "swap-exchange":
      case "swap-remove":
      case "swap-details":
      case "swap-pending":
      case "move":
      case "preswap-pending":
      case "preswap-finalize":
        return true;
      default:
        return false;
    }
  },
  _isCurrentUserSlots: function () {
    // if no selected slots, return false
    if (!this.state.selectedSlots.length) {
      return false;
    }

    // return true if every slot in the collection belongs to this user
    for (let i = 0; i < this.state.selectedSlots.length; i++) {
      if (this.state.selectedSlots[i].get("emp_id") !== window.LbsAppData.MyPersonnel.get("emp_id")) {
        return false;
      }
    }

    return true;
  },
  _isCurrentlyScheduledOnAllSelectedSlots: function (personnel) {
    // no slots -- not 'scheduled'
    if (!this.state.selectedSlots || !this.state.selectedSlots.length) {
      return false;
    }

    // return true if every slot in the collection belongs to the personnel
    for (let i = 0; i < this.state.selectedSlots.length; i++) {
      if (this.state.selectedSlots[i].get("emp_id") !== personnel.get("emp_id")) {
        return false;
      }
    }

    // had slots and went through all of them
    return true;
  },
  _getBlockData: function () {
    const blockingAssignment = this.state.selectedAssignments.find((a) => a.attributes.is_block);

    const hasBlockingAssignment = !!blockingAssignment;
    const blockStartWeekDay = blockingAssignment?.attributes.block_start_day;
    const blockLength = blockingAssignment?.attributes.block_duration;

    const getBlockDayRange = () => {
      const startDay = moment().day(blockStartWeekDay);
      return _.range(0, blockLength).reduce((acc, i) => {
        const date = startDay.clone().add(i, "days");
        return [...acc, date.day()];
      }, []);
    };

    return {
      hasBlockingAssignment,
      blockDayRange: hasBlockingAssignment ? getBlockDayRange() : [],
      blockStartWeekDay,
      blockLength,
    };
  },
  _getFormScenario: function (slot) {
    if (slot.get("emp_id") === window.LbsAppData.MyPersonnel.id) {
      // this is my slot -- open it up by sending nothing
      return {};
    }
    let lockedPersonnel = false;
    let lockedAssignment = false;
    let initialPersonnel;
    if (!window.LbsAppData.Helpers.Permissions.CanIChangeAnyoneElsesSchedule()) {
      initialPersonnel = window.LbsAppData.MyPersonnel;
      lockedPersonnel = true;
      lockedAssignment = true;
    }

    return {
      lockedPersonnel: lockedPersonnel,
      lockedAssignment: lockedAssignment,
      initialPersonnel: initialPersonnel,
    };
  },
  _updateFormScenario: function () {
    // lock/unlock personnel and assignment options based on the current state of the dialog
    let lockedAssignment = false;
    let lockedPersonnel = false;
    let defaultLocations = [];

    if (
      this.state.action === "swap-replace" &&
      !window.LbsAppData.Helpers.Permissions.CanIChangeAnyoneElsesSchedule() &&
      !this._isCurrentUserSlots()
    ) {
      lockedPersonnel = true;
      lockedAssignment = true;
    }
    if (this.state.selectedSlots.length && this.state.selectedAssignments.length) {
      // selected assignment(s); lock personnel
      lockedPersonnel = true;
    }
    if (this.state.selectedSlots.length && this.state.selectedPersonnel.length) {
      // selected personnel; lock assignments
      lockedAssignment = true;
    }

    if (this.state.selectedAssignments.length === 1) {
      const defaultIds = _.map(
        _.filter(this.state.selectedAssignments[0].get("locations"), function (l) {
          return l.is_default;
        }),
        function (loc) {
          return loc.location_id;
        }
      );
      defaultLocations = _.filter(this.state.locations.models, function (loc) {
        return _.contains(defaultIds, loc.attributes.location_id);
      });
    }

    this.setState({
      lockedPersonnel: lockedPersonnel,
      lockedAssignment: lockedAssignment,
      selectedReason: undefined,
      selectedLocations: defaultLocations,
    });
  },
  _checkForStructure: function () {
    let key;
    let date;
    const targetDates = _.keys(this.state.selectedDates);
    const targetTemplate = this._getTargetTemplate();
    const targetPersonnel = this.state.selectedPersonnel;
    const targetAssignments = this.state.selectedAssignments;
    const targetSlots = this.state.selectedSlots;
    if (targetAssignments.length && targetPersonnel.length && targetDates.length) {
      for (let i = 0; i < targetDates.length; i++) {
        for (let j = 0; j < targetPersonnel.length; j++) {
          for (let k = 0; k < targetAssignments.length; k++) {
            let assignId;
            if (targetAssignments[k].attributes.template_to_assign_id_map[targetTemplate.id] !== undefined) {
              assignId = targetAssignments[k].attributes.template_to_assign_id_map[targetTemplate.id];
            }
            date = moment(targetDates[i], "YYYYMMDD").format("YYYYMMDD");
            key = assignId + "~" + date;
            this._addAssignmentDefaultTimesToDict(
              assignId,
              targetTemplate.id,
              targetPersonnel[j].get("emp_id"),
              date,
              key,
              targetAssignments[k]
            );
          }
        }
      }
    } else if (targetAssignments.length && targetSlots.length) {
      for (let i = 0; i < targetSlots.length; i++) {
        for (let j = 0; j < targetAssignments.length; j++) {
          let assignId;
          if (targetAssignments[j].attributes.template_to_assign_id_map[targetTemplate.id] !== undefined) {
            assignId = targetAssignments[j].attributes.template_to_assign_id_map[targetTemplate.id];
          }
          date = moment(targetSlots[i].get("slot_date"), "YYYY-MM-DD").format("YYYYMMDD");
          key = assignId + "~" + date;
          this._addAssignmentDefaultTimesToDict(
            assignId,
            targetTemplate.id,
            targetSlots[i].get("emp_id"),
            date,
            key,
            targetAssignments[j]
          );
        }
      }
    } else if (!_.isEmpty(this.state.assignmentStructureTimesDict)) {
      this.setState({ assignmentStructureTimesDict: {} });
    }
  },
  _checkForSlotStructure: function () {
    const targetTemplate = this._getTargetTemplate();
    const targetSlots = this.state.selectedSlots;
    if (targetTemplate && targetSlots.length) {
      for (let i = 0; i < targetSlots.length; i++) {
        const targetAssignment = targetSlots[i].assignmentObj;
        const assignId = targetSlots[i].get("assign_id");
        const date = moment(targetSlots[i].get("slot_date"), "YYYY-MM-DD").format("YYYYMMDD");
        const key = assignId + "~" + date;
        this._addAssignmentDefaultTimesToDict(
          assignId,
          targetTemplate.id,
          targetSlots[i].get("emp_id"),
          date,
          key,
          targetAssignment
        );
      }
    }
  },
  _getSeedData: function (preData) {
    let initialStep;
    let formScenario;
    let lockedAssignment;
    let initialDepartmentsAndTemplates;
    const ret = {};

    if (this.props.dialogObject.type === "swap-fill" || this.props.dialogObject.type === "new-request") {
      // initial step depends on a few factors
      const initialTemplate = this._getInitialTemplate(preData);
      const initialPersonnel = this._getInitialPersonnel(preData, initialTemplate);
      // collections
      ret.departments = this._createDepartmentCollection(preData);
      ret.templates = this._createTemplateCollection(preData);
      ret.personnel = this._createPersonnelCollection([], () => [this.state.selectedTemplate.attributes.department_id]);
      ret.assignments = this._createAssignmentsCollection([initialTemplate.id]);
      ret.reasons = this._createLoaReasonsCollection();

      ret.locations = this._createLocationsCollection();

      // template
      ret.selectedTemplate = initialTemplate;
      // assignment
      ret.selectedAssignments = [];
      ret.assignmentStructureTimesDict = {};
      ret.assignmentStructureErrorDayDict = {};
      // personnel
      ret.selectedPersonnel = !initialPersonnel ? [] : [initialPersonnel];
      ret.lockedPersonnel = false;
      ret.lockedAssignment = false;
      // reason
      ret.selectedReason = undefined;
      // locations
      ret.selectedLocations = [];
      // dates
      ret.selectedDates = preData ? preData.selectedDates : {};
      if (preData) {
        const selectedDates = _.keys(preData.selectedDates);
        ret.lastSelectedDate = selectedDates.length > 0 ? selectedDates[0] : undefined;
      } else {
        ret.lastSelectedDate = undefined;
      }
      // slots -- not relevant
      ret.selectedSlots = [];
      // details
      ret.useCustomTime = false;
      ret.startTime = undefined;
      ret.stopTime = undefined;
      ret.addDemand = false;
      //need a submit error array for time zone issues

      ret.fillErrors = [];
    } else if (this.props.dialogObject.type === "swap-replace" || this.props.dialogObject.type === "move") {
      // initial step depends on a few factors
      const { assignmentList, departmentList, templateList } = this._getInitialDepartmentsTemplatesAssignments(preData);
      let lockedPersonnel,
        lockedAssignment = false,
        initialPersonnel;
      if (preData) {
        const formScenario = this._getFormScenario(preData.slots[0]);
        lockedPersonnel = formScenario.lockedPersonnel ? formScenario.lockedPersonnel : false;
        lockedAssignment = formScenario.lockedAssignment ? formScenario.lockedAssignment : false;
        initialPersonnel = formScenario.initialPersonnel ? formScenario.initialPersonnel : undefined;
      }

      // collections
      ret.personnel = this._createPersonnelCollection(assignmentList, departmentList, preData);
      ret.assignments = this._createAssignmentsCollection(templateList, preData);
      ret.reasons = this._createLoaReasonsCollection();
      ret.locations = this._createLocationsCollection();

      ret.isFetchingSlots = true;
      // assignment
      ret.selectedAssignments = [];
      ret.assignmentStructureTimesDict = {};
      ret.assignmentStructureErrorDayDict = {};
      // personnel
      ret.selectedPersonnel = !initialPersonnel ? [] : [initialPersonnel];
      ret.lockedPersonnel = lockedPersonnel;
      ret.lockedAssignment = lockedAssignment;

      // the naked collections
      ret.departments = this._createDepartmentCollection(preData);
      ret.templates = this._createTemplateCollection(preData);

      // reason
      ret.selectedReason = undefined;
      // locations
      ret.selectedLocations = [];
      // dates
      ret.selectedDates = preData ? preData.selectedDates : {};
      // slots
      ret.selectedSlots =
        preData && preData.selectedSlot
          ? [preData.selectedSlot]
          : preData && preData.slots
          ? _.values(preData.slots)
          : [];

      ret.swapIntoSlot = preData ? preData.swapIntoSlot : undefined;
      ret.departmentList = departmentList;
      ret.templateList = templateList;
      ret.selectedTemplate = ret.templates.get(templateList[0]);
    } else if (this.props.dialogObject.type === "split-shift") {
      const { assignmentList, departmentList, templateList } = this._getInitialDepartmentsTemplatesAssignments(preData);
      let lockedPersonnel,
        lockedAssignment = false,
        initialPersonnel;
      if (preData) {
        let formScenario = this._getFormScenario(preData.slots[0]);
        lockedPersonnel = formScenario.lockedPersonnel ? formScenario.lockedPersonnel : false;
        lockedAssignment = formScenario.lockedAssignment ? formScenario.lockedAssignment : false;
        initialPersonnel = formScenario.initialPersonnel ? formScenario.initialPersonnel : undefined;
      }

      // collections
      ret.personnel = this._createPersonnelCollection(assignmentList, departmentList, preData);
      ret.assignments = this._createAssignmentsCollection(templateList, preData);
      ret.reasons = this._createLoaReasonsCollection();
      ret.locations = this._createLocationsCollection();

      ret.isFetchingSlots = true;
      // assignment
      ret.selectedAssignments = [];
      // personnel
      ret.selectedPersonnel = !initialPersonnel ? [] : [initialPersonnel];
      ret.selectedPersonnel2 = [];
      ret.selectedPersonnel3 = [];
      ret.lockedPersonnel = lockedPersonnel;
      ret.lockedAssignment = lockedAssignment;

      // the naked collections
      ret.departments = this._createDepartmentCollection(preData);
      ret.templates = this._createTemplateCollection(preData);

      // reason
      ret.selectedReason = undefined;
      // locations
      ret.selectedLocations = [];
      // dates
      ret.selectedDates = preData ? preData.selectedDates : {};
      // slots
      ret.selectedSlots =
        preData && preData.selectedSlot
          ? [preData.selectedSlot]
          : preData && preData.slots
          ? _.values(preData.slots)
          : [];

      ret.swapIntoSlot = preData ? preData.swapIntoSlot : undefined;

      ret.departmentList = departmentList;
      ret.templateList = templateList;
      ret.selectedTemplate = ret.templates.get(templateList[0]);
    } else if (this.props.dialogObject.type === "swap-exchange") {
      ret.selectedSlots = _.values(preData.slots);
      ret.reasons = this._createLoaReasonsCollection();
      ret.selectedPersonnel = [];
      ret.selectedAssignments = [];
    } else if (this.props.dialogObject.type === "swap-remove") {
      const initialPersonnel = this._getInitialPersonnel(preData);
      const { assignmentList, departmentList } = this._getInitialDepartmentsTemplatesAssignments(preData);

      ret.selectedDates = preData ? preData.selectedDates : {};
      ret.selectedSlots = preData ? _.values(preData.slots) : [];
      // the naked collections
      ret.personnel = this._createPersonnelCollection(assignmentList, departmentList);
      ret.selectedPersonnel = !initialPersonnel ? [] : [initialPersonnel];
      ret.selectedAssignments = [];
      ret.reasons = this._createLoaReasonsCollection();
      ret.departments = this._createDepartmentCollection(preData);
      ret.templates = this._createTemplateCollection(preData);
      ret.slots = this._createSlotsCollection(preData);
    } else if (this.props.dialogObject.type === "swap-details") {
      ret.templates = this._createTemplateCollection(preData);
      ret.selectedSlots = preData.slots ? _.values(preData.slots) : [];
      ret.selectedTemplate = window.LbsAppData.Templates.get(preData.slots[0].get("template_id"));

      ret.selectedPersonnel = [];
      ret.selectedAssignments = [];
      ret.assignmentStructureTimesDict = {};
      ret.assignmentStructureErrorDayDict = {};

      ret.selectedLocations = [];
      ret.useCustomTime = preData.slots[0].get("is_default_time") === false;
      ret.startTime = preData.slots[0].get("start_time").split("T")[1];
      ret.stopTime = preData.slots[0].get("stop_time").split("T")[1];

      ret.personnel = this._createPersonnelCollection([], [], preData);
      ret.assignments = this._createAssignmentsCollection([], preData);
      ret.reasons = this._createLoaReasonsCollection();
      ret.locations = this._createLocationsCollection();

      //need a submit error array for time zone issues

      ret.detailsErrors = [];
    } else if (this.props.dialogObject.type === "swap-pending") {
      ret.pendingAction = preData.action; // grant/deny/delete
      ret.selectedSlots = _.values(preData.slots);
      ret.reasons = this._createLoaReasonsCollection();
      ret.denialReasons = this._createDenialReasonsCollection(preData);
      ret.templates = this._createTemplateCollection(preData);
      ret.selectedDenialReason = undefined;
      ret.selectedPersonnel = [];
      ret.selectedAssignments = [];
      ret.assignments = this._createAssignmentsCollection([], preData);
      ret.locations = this._createLocationsCollection();
    } else if (this.props.dialogObject.type === "preswap-pending") {
      ret.pendingAction = preData.action; // accept/decline/delete
      ret.selectedSlots = _.values(preData.slots);
      ret.reasons = this._createLoaReasonsCollection();
      ret.selectedPersonnel = [];
      ret.selectedAssignments = [];
    } else if (this.props.dialogObject.type === "preswap-finalize") {
      ret.selectedSlots = _.values(preData.slots);
      ret.selectedPreswap = undefined;
      ret.reasons = this._createLoaReasonsCollection();
      ret.personnel = this._createPersonnelCollection([], [], preData);
      ret.templates = this._createTemplateCollection(preData);
      ret.selectedPersonnel = [];
      ret.selectedAssignments = [];
    } else if (this.props.dialogObject.type === "request-action") {
      ret.pendingAction = this.props.dialogObject.data.action; // grant/deny/delete
      ret.selectedSlots = _.values(preData.requests);
      ret.templates = this._createTemplateCollection(preData);
      ret.reasons = this._createLoaReasonsCollection();
      ret.denialReasons = this._createDenialReasonsCollection(preData);
      ret.selectedDenialReason = undefined;
      ret.selectedPersonnel = [];
      ret.selectedAssignments = [];
      ret.assignments = this._createAssignmentsCollection([], preData);
      ret.locations = this._createLocationsCollection();
    }

    // cross-action
    ret.action = this.props.dialogObject.type;

    return ret;
  },
  _getInitialDepartmentsTemplatesAssignments: function (preData) {
    // single template view...use the template that is in context...this can only happen if we come in from the grid (aka preData is not undefined)
    // multi template view or coming in from a menu, we'll use the slot's data
    let departmentList = [];
    let templateList = [];
    let assignmentList = [];

    const view = window.LbsAppData.AppContext.get("view");

    if (preData) {
      // fill out the department/assignment/template lists
      // skip any of the MRC shit
      _.each(preData.slots, function (s) {
        if (s.get("department_id") !== -98765) {
          // push the department and template
          departmentList.push(s.get("department_id"));
          templateList.push(s.get("template_id"));
          assignmentList.push(s.get("assign_id"));
        }
      });

      // remove duplicates
      departmentList = _.uniq(departmentList);
      templateList = _.uniq(templateList);
      assignmentList = _.uniq(assignmentList);

      // if templateList is empty, use the view
      if (!templateList.length) {
        if (view && view.filter.on_templates) {
          templateList.push(view.filter.on_templates[0]);
        }
      }
    }

    return {
      departmentList: departmentList,
      templateList: templateList,
      assignmentList: assignmentList,
    };
  },
  _getInitialTemplate: function (preData) {
    var initialTemplate;
    if (window.LbsAppData.AppContext.get("view")) {
      // if there is a view in context then seed the selected template with a currently shown one
      var sharedTemplates = _.intersection(
        window.LbsAppData.AppContext.get("view").filter.on_templates,
        this.props.dialogObject.templateCollection.pluck("template_id")
      );
      if (sharedTemplates.length) {
        initialTemplate = this.props.dialogObject.templateCollection.get(sharedTemplates[0]);
      }
    }
    // if initial template is still null seed it with the first one in the list
    if (!initialTemplate) {
      initialTemplate = this.props.dialogObject.templateCollection.at(0);
    }

    return initialTemplate;
  },
  _getInitialPersonnel: function (preData, initialTemplate) {
    switch (this.props.dialogObject.type) {
      case "swap-remove":
        var initialPersonnel = window.LbsAppData.MyPersonnel.get("scheduled")
          ? window.LbsAppData.MyPersonnel
          : undefined;
        if (preData) {
          initialPersonnel = undefined;
        }
        return initialPersonnel;

      default:
        if (
          window.LbsAppData.MyPersonnel.get("scheduled") &&
          initialTemplate &&
          _.intersection(window.LbsAppData.MyPersonnel.attributes.departments, [
            initialTemplate.attributes.department_id,
          ]).length > 0
        ) {
          return window.LbsAppData.MyPersonnel;
        } else {
          return undefined;
        }
    }
  },
  _getInitialStep: function (preData) {
    switch (this.props.dialogObject.type) {
      case "swap-remove":
        var changePermission = window.LbsAppData.Helpers.Permissions.CanIChangeAnyoneElsesSchedule();
        // initial step depends on a few factors
        var initialStep;
        var initialPersonnel = window.LbsAppData.MyPersonnel;
        if (preData) {
          initialStep = 4;
          initialPersonnel = window.LbsAppData.Personnel.get(preData.slots[0].get("emp_id"));
        } else {
          initialPersonnel = window.LbsAppData.MyPersonnel;
          if (changePermission) {
            // permissions allow them to edit other peoples schedules...let it happen
            initialStep = 1;
          } else {
            // permissions only allow them to change themselves
            initialStep = 2;
          }
        }
        break;

      default:
        break;
    }
  },
  _getTargetDepartment: function () {
    // 1. first selectedDepartment in state
    // 2. undefined
    if (this.state.selectedDepartment) {
      return this.state.selectedDepartment;
    } else {
      return undefined;
    }
  },
  _getTargetTemplate: function () {
    // 1. first selectedTemplate in state
    // 2. template from the first selected slot
    // 3. undefined
    if (this.state.selectedTemplate) {
      return this.state.selectedTemplate;
    } else if (this.state.selectedSlots.length) {
      var template = this.state.templates.get(this.state.selectedSlots[0].get("template_id"));
      if (!template) {
        // heeeere's MRC!
        if (window.LbsAppData.Templates) {
          template = window.LbsAppData.Templates.findWhere({
            template_id: this.state.selectedSlots[0].get("template_id"),
          });
        }
      }
      return template;
    } else {
      return undefined;
    }
  },
  _getTargetPersonnel: function (options) {
    // 1. targetSlot from options
    // 2. first selectedPersonnel in state
    // 3. personnel from slot emp_id
    // 4. undefined
    if (options && options.targetSlot) {
      return window.LbsAppData.Personnel
        ? window.LbsAppData.Personnel.get(options.targetSlot.get("emp_id"))
        : undefined;
    } else if (this.state.selectedPersonnel.length == 1) {
      return this.state.selectedPersonnel[0];
    } else if (this.state.selectedPersonnel.length > 1) {
      return this.state.selectedPersonnel;
    } else if (this.state.selectedSlots.length) {
      return window.LbsAppData.Personnel
        ? window.LbsAppData.Personnel.get(this.state.selectedSlots[0].get("emp_id"))
        : undefined;
    } else {
      return undefined;
    }
  },
  _getTargetAssignment: function (options) {
    // 1. targetSlot from options
    // 2. first selectedAssignments in state if only is 1
    // 3. all selectedAssignments in state if more than 1
    // 4. assignment from selectedSlots condensed_structure_id if only 1 unique
    // 5. all assignments from selectedSlots condensed_structure_id if more than 1 unique
    // 6. undefined
    let assignment;
    let slot;
    if (options && options.targetSlot) {
      // heeeeere's MRC!
      if (options.targetSlot.get("department_id") === -98765) {
        // not sure if this is going to work but if it does.. it might open some doors in other places
        return window.LbsAppData.Assignments
          ? _.find(window.LbsAppData.Assignments.models, function (m) {
              return _.indexOf(m.get("structures_include_expired"), options.targetSlot.get("assign_structure_id")) > -1;
            })
          : undefined;
      } else {
        return window.LbsAppData.Assignments
          ? window.LbsAppData.Assignments.get(options.targetSlot.get("condensed_structure_id"))
          : undefined;
      }
    } else if (this.state.selectedAssignments.length === 1) {
      return this.state.selectedAssignments[0];
    } else if (this.state.selectedAssignments.length > 1) {
      return this.state.selectedAssignments;
    } else if (this.state.selectedSlots.length === 1) {
      // heeeeere's MRC!
      if (this.state.selectedSlots[0].get("department_id") === -98765) {
        // not sure if this is going to work but if it does.. it might open some doors in other places
        slot = this.state.selectedSlots[0];
        return window.LbsAppData.Assignments
          ? _.find(window.LbsAppData.Assignments.models, function (m) {
              return _.indexOf(m.get("structures_include_expired"), slot.get("assign_structure_id")) > -1;
            })
          : undefined;
      } else {
        return window.LbsAppData.Assignments
          ? window.LbsAppData.Assignments.get(this.state.selectedSlots[0].get("condensed_structure_id"))
          : undefined;
      }
    } else if (this.state.selectedSlots.length > 1) {
      const ret = [];
      if (!window.LbsAppData.Assignments) {
        return undefined;
      } else {
        for (let i = 0; i < this.state.selectedSlots.length; i++) {
          // heeeeere's MRC!
          if (this.state.selectedSlots[i].get("department_id") === -98765) {
            // not sure if this is going to work but if it does.. it might open some doors in other places
            slot = this.state.selectedSlots[i];
            assignment = _.find(window.LbsAppData.Assignments.models, (m) => {
              return _.indexOf(m.get("structures_include_expired"), slot.get("assign_structure_id")) > -1;
            });
            if (
              assignment &&
              !_.find(ret, function (m) {
                return m.id === assignment.id;
              })
            ) {
              ret.push(assignment);
            }
          } else {
            assignment = window.LbsAppData.Assignments.get(this.state.selectedSlots[i].get("condensed_structure_id"));
            if (
              assignment &&
              !_.find(ret, function (m) {
                return m.id === assignment.id;
              })
            ) {
              ret.push(assignment);
            }
          }
        }
        return ret;
      }
    } else {
      return undefined;
    }
  },
  _getTargetAssignmentStructure: function (options) {
    return undefined;
  },
  _permutateTargets: function () {
    // permutate target arrays and return the combinations
    const targetDates = _.keys(this.state.selectedDates);
    const targetPersonnel = this.state.selectedPersonnel;
    const targetAssignments = this.state.selectedAssignments;
    const targetTemplate = this._getTargetTemplate();

    const ret = [];
    for (let i = 0; i < targetDates.length; i++) {
      for (let j = 0; j < targetPersonnel.length; j++) {
        for (let k = 0; k < targetAssignments.length; k++) {
          const date = moment(targetDates[i], "YYYYMMDD");
          const targetStructure = this._getTargetStructureTimes(
            targetAssignments[k].attributes.template_to_assign_id_map[targetTemplate.id],
            date
          );
          ret.push({
            targetDate: targetDates[i],
            targetPersonnel: targetPersonnel[j],
            targetAssignment: targetAssignments[k],
            targetStructure: targetStructure,
          });
        }
      }
    }

    return ret;
  },
  _getDisplayStrings: function (options) {
    let personnelString,
      assignmentString,
      dateString = "";
    // personnel logic
    personnelString = this._getPersonnelDisplayString(options);
    // assignment logic
    assignmentString = this._getAssignmentDisplayString(options);
    // date logic
    dateString = this._getDateDisplayString(options);

    const dateOnlyString = this._getDateDisplayString(options, "date");
    const timeOnlyString = this._getDateDisplayString(options, "time");

    // return personnel, assignment, date display values
    return {
      personnel: personnelString,
      assignment: assignmentString,
      date: dateString,
      dateOnly: dateOnlyString,
      timeOnly: timeOnlyString,
    };
  },
  _getPersonnelDisplayString: function (options) {
    let selectedPersonnel = this.state.selectedPersonnel;
    if (options && options.selectedPersonnelListNumber) {
      // These selectedPersonnelList "Number" s are actually strings.
      if (options.selectedPersonnelListNumber === "2") {
        selectedPersonnel = this.state.selectedPersonnel2;
      } else if (options.selectedPersonnelListNumber === "3") {
        selectedPersonnel = this.state.selectedPersonnel3;
      }
    }
    let displayText;
    if (options && options.targetPersonnel) {
      displayText = options.targetPersonnel.get("display_name");
    } else if (selectedPersonnel.length === 1) {
      // one person
      displayText = selectedPersonnel[0].get("display_name");
    } else if (selectedPersonnel.length > 1) {
      // multiple people
      let prefix = "";
      if (this.state.action === "new-request" || this.state.action === "swap-fill") {
        prefix = "All of ";
      } else if (this.state.action === "swap-replace" || this.state.action === "split-shift") {
        prefix = "Any of ";
      }
      displayText = prefix + selectedPersonnel.length + " people";
    } else if (options && options.slot) {
      displayText = options.slot.get("display_name");
    } else if (this.state.selectedSlots.length === 1) {
      // specific slot
      displayText = this.state.selectedSlots[0].get("display_name");
    } else if (this.state.lockedPersonnel) {
      // can't update (replace)
      displayText = "Current";
    } else {
      // blank
      displayText = "Select";
    }

    return displayText;
  },
  _getAssignmentDisplayString: function (options) {
    let displayText;
    if (options && options.targetAssignment) {
      displayText = options.targetAssignment.get("display_name");
    } else if (this.state.selectedAssignments.length === 1) {
      // one assignment
      displayText = this.state.selectedAssignments[0].get("display_name");
    } else if (this.state.selectedAssignments.length > 1) {
      // multiple assignments
      displayText = this.state.selectedAssignments.length + " assignments";
    } else if (options && options.slot) {
      displayText = options.slot.get("assign_display_name");
    } else if (this.state.selectedSlots.length === 1) {
      // specific slot
      displayText = this.state.selectedSlots[0].get("assign_display_name");
    } else if (this.state.lockedAssignment) {
      // can't update (replace)
      displayText = "Current";
    } else {
      // blank
      displayText = "Select";
    }

    return displayText;
  },
  _addAssignmentDefaultTimesToDict: function (assign_id, template_id, emp_id, date, key, targetAssign) {
    if (this._canUseSimpleStructureLookup(targetAssign, template_id)) {
      this._getSimpleStructureDefaultTimes(key, targetAssign, template_id);
    } else {
      this._getComplexStructureDefaultTimes(assign_id, template_id, emp_id, date, key, targetAssign);
    }
  },

  _canUseSimpleStructureLookup: function (targetAssign, template_id) {
    // can use old method if there is a default times map for the template
    // AND the assignment will not change days due to time zones
    var startTime = targetAssign && targetAssign._getAssignmentDefaultStartTime(template_id);
    var stopTime = targetAssign && targetAssign._getAssignmentDefaultStopTime(template_id);
    if (!startTime || !stopTime) {
      return false;
    }

    var originalInputStart = moment(startTime, "HH:mm:ss").format("YYYYMMDD");
    var shiftedInputStart = window.LbsAppData.Helpers.Time.timezoneConverter(moment(startTime, "HH:mm:ss")).format(
      "YYYYMMDD"
    );
    if (originalInputStart !== shiftedInputStart) {
      return false;
    }

    return true;
  },

  _getSimpleStructureDefaultTimes: function (key, targetAssign, template_id) {
    var originalDict = this.state.assignmentStructureTimesDict;
    var startTime = targetAssign && targetAssign._getAssignmentDefaultStartTime(template_id);
    var stopTime = targetAssign && targetAssign._getAssignmentDefaultStopTime(template_id);
    var timeString = startTime + "~" + stopTime;
    originalDict[key] = timeString;
    this.setState({ assignmentStructureTimesDict: originalDict });
  },

  _getComplexStructureDefaultTimes: function (assign_id, template_id, emp_id, date, key, targetAssign) {
    var that = this;
    var returnVal = undefined;
    var assignmentStructure = new AssignmentStructure();
    var getTime = new Promise(function (resolve, reject) {
      assignmentStructure.fetch({
        data: $.param({ emp_id: emp_id, assignment_id: assign_id, date: date }),
        success: function (response) {
          resolve();
          var convertedTimes = that._convertStructureTimes(response.attributes, template_id, date);
          if (convertedTimes.today) {
            returnVal = convertedTimes.today;
          } else if (convertedTimes.yesterday) {
            returnVal = convertedTimes.yesterday;
          } else if (convertedTimes.tomorrow) {
            returnVal = convertedTimes.tomorrow;
          }
          var originalDict = that.state.assignmentStructureTimesDict;
          originalDict[key] = returnVal;

          var originalErrorDict = that.state.assignmentStructureErrorDayDict;
          if (!returnVal) {
            var errorDay = that._addStructureErrors(
              response.attributes,
              convertedTimes,
              date,
              targetAssign,
              template_id
            );
            originalErrorDict[key] = errorDay;
          }

          that.setState({
            assignmentStructureTimesDict: originalDict,
            assignmentStructureErrorDayDict: originalErrorDict,
          });
        },
      });
    });
  },

  _convertStructureTimes: function (data, template_id, date) {
    var checkDate = date;
    var resultTimes = {};
    var yesterday = moment(checkDate, "YYYYMMDD").subtract(1, "days").format("YYYYMMDD");
    var today = date;
    var tomorrow = moment(checkDate, "YYYYMMDD").add(1, "days").format("YYYYMMDD");

    if (data.today) {
      resultTimes.today = this._convertStructureTime(data.today, template_id, date, today);
    }
    if (data.yesterday) {
      resultTimes.yesterday = this._convertStructureTime(data.yesterday, template_id, date, yesterday);
    }
    if (data.tomorrow) {
      resultTimes.tomorrow = this._convertStructureTime(data.tomorrow, template_id, date, tomorrow);
    }
    return resultTimes;
  },

  _convertStructureTime: function (data, template_id, date, checkDate) {
    if (data.template_to_default_times_map[template_id] !== undefined) {
      if (data.template_to_default_times_map[template_id].split("~").length > 1) {
        const preconvertedVal = data.template_to_default_times_map[template_id];
        const convertedDate = window.LbsAppData.Helpers.Time.timezoneConverter(
          moment(checkDate + preconvertedVal.split("~")[0], "YYYYMMDDHH:mm:ss")
        ).format("YYYYMMDD");
        if (date == convertedDate) {
          return preconvertedVal;
        } else {
          return null;
        }
      }
    }
  },
  _addStructureErrors: function (times, timesConverted, date, targetAssign, template_id) {
    // check if it is a single structure assignment, if so use that time to find the proper error date
    // otherwise if there is no structure today we can just submit on the same day so add the day in the error dict
    //otherwise we will have to try to submit on the day of the actual assignment. this will lead to a misleading error
    // but it is requested
    if (targetAssign && targetAssign._getAssignmentDefaultStartTime(template_id)) {
      return this._convertErrorDate(targetAssign.attributes, template_id, date);
    }
    if (this._didShiftMoveBack(times)) {
      return moment(date, "YYYYMMDD").add(1, "days").format("YYYYMMDD");
    } else if (this._didShiftMoveForward(times)) {
      return moment(date, "YYYYMMDD").subtract(1, "days").format("YYYYMMDD");
    }
    // just add today as the error if we cannot guess it
    return date;
  },
  _convertErrorDate: function (data, template_id, date) {
    var preconvertedVal = data.template_to_default_times_map[template_id];
    var convertedDate = window.LbsAppData.Helpers.Time.timezoneConverter(
      moment(date + preconvertedVal.split("~")[0], "YYYYMMDDHH:mm:ss")
    ).format("YYYYMMDD");

    if (date > convertedDate) {
      return moment(date, "YYYYMMDD").add(1, "days").format("YYYYMMDD");
    } else if (convertedDate > date) {
      return moment(date, "YYYYMMDD").subtract(1, "days").format("YYYYMMDD");
    }

    return date;
  },

  _didShiftMoveBack: function (times) {
    return window.LbsAppData.Helpers.Time.timezoneBeforeOrAfter() == "before" && times.today && !times.tomorrow;
  },

  _didShiftMoveForward: function (times) {
    return window.LbsAppData.Helpers.Time.timezoneBeforeOrAfter() == "after" && times.today && !times.yesterday;
  },

  _getDateDisplayString: function (options, dateOnly) {
    let targetDates;
    if (options && options.targetDate) {
      targetDates = [options.targetDate];
    } else if (this.state.selectedDates) {
      targetDates = _.keys(this.state.selectedDates);
    } else if (this.state.selectedSlots.length) {
      targetDates = _.uniq(
        _.map(this.state.selectedSlots, function (s) {
          return s.get("slot_date") || s.get("request_date");
        })
      );
    }

    const dateDict = this._getDisplayDateAndDateText(options, targetDates);
    const dateText = dateDict.dateText;
    const timeText = this._getDisplayTimeText(options, dateDict.date);

    let displayText = timeText ? dateText + ", " + timeText : dateText;
    if (dateOnly === "date") {
      displayText = dateText;
    } else if (dateOnly === "time") {
      displayText = timeText ? timeText : undefined;
    }
    return displayText;
  },
  _getDisplayDateAndDateText: function (options, targetDates) {
    let date, dateText;
    if (options && options.slot) {
      date = moment(options.slot.get("slot_date") || options.slot.get("request_date"), "YYYYMMDD");
      dateText = date.format("ddd, ll");
    } else if (options && options.targetDate) {
      date = moment(options.targetDate, "YYYYMMDD");
      dateText = date.format("ddd, ll");
    } else if (targetDates.length === 1) {
      date = moment(targetDates[0], "YYYYMMDD");
      dateText = date.format("ddd, ll");
    } else if (targetDates.length > 1) {
      dateText = targetDates.length + " dates";
    } else {
      dateText = undefined;
    }
    return { dateText: dateText, date: date };
  },
  _getDisplayTimeText: function (options, date) {
    let startTime;
    let stopTime;
    let timeText;
    if (!(options && options.noTime)) {
      if (this.state.useCustomTime) {
        date = moment();
        startTime = this.state.startTime;
        stopTime = this.state.stopTime;
        timeText = this._getDisplayFullTime(
          moment(date.format("YYYY-MM-DD") + "T" + startTime, "YYYY-MM-DDTHH:mm:s"),
          moment(date.format("YYYY-MM-DD") + "T" + stopTime, "YYYY-MM-DDTHH:mm:s")
        );
      } else if (date && this.state.selectedAssignments.length === 1) {
        // an undefined/null start_time means its a default time...the way requests work..thats not so easy to figure out what the default time is
        // as time is tied to structures, and requests don't have a structure until they are scheduled.
        const targetTemplate = this._getTargetTemplate();
        const targetAssignment = this.state.selectedAssignments[0];
        startTime = targetAssignment && targetAssignment._getAssignmentDefaultStartTime(targetTemplate.id);
        stopTime = targetAssignment && targetAssignment._getAssignmentDefaultStopTime(targetTemplate.id);
        timeText =
          moment(date.format("YYYY-MM-DD") + "T" + startTime, "YYYY-MM-DDTHH:mm:s")
            .format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"))
            .replace(" AM", "a")
            .replace(" PM", "p") +
          " - " +
          moment(date.format("YYYY-MM-DD") + "T" + stopTime, "YYYY-MM-DDTHH:mm:s")
            .format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"))
            .replace(" AM", "a")
            .replace(" PM", "p");
      } else if (date && options && options.targetAssignment) {
        const targetTemplate = this._getTargetTemplate();
        const targetAssignment = options.targetAssignment;
        startTime = targetAssignment && targetAssignment._getAssignmentDefaultStartTime(targetTemplate.id);
        stopTime = targetAssignment && targetAssignment._getAssignmentDefaultStopTime(targetTemplate.id);
        timeText =
          moment(date.format("YYYY-MM-DD") + "T" + startTime, "YYYY-MM-DDTHH:mm:s")
            .format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"))
            .replace(" AM", "a")
            .replace(" PM", "p") +
          " - " +
          moment(date.format("YYYY-MM-DD") + "T" + stopTime, "YYYY-MM-DDTHH:mm:s")
            .format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"))
            .replace(" AM", "a")
            .replace(" PM", "p");
      } else if (options && options.slot) {
        startTime = options.slot.get("start_time");
        stopTime = options.slot.get("stop_time");
        timeText =
          moment(startTime)
            .format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"))
            .replace(" AM", "a")
            .replace(" PM", "p") +
          " - " +
          moment(stopTime)
            .format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"))
            .replace(" AM", "a")
            .replace(" PM", "p");
      } else if (this.state.selectedSlots.length === 1) {
        startTime = this.state.selectedSlots[0].get("start_time");
        stopTime = this.state.selectedSlots[0].get("stop_time");
        timeText =
          moment(startTime)
            .format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"))
            .replace(" AM", "a")
            .replace(" PM", "p") +
          " - " +
          moment(stopTime)
            .format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"))
            .replace(" AM", "a")
            .replace(" PM", "p");
      } else {
        timeText = undefined;
      }
    }

    return timeText;
  },
  _getTargetStructureTimes: function (assignId, date) {
    var targetTemplate = this._getTargetTemplate();
    var assignIdKey = assignId + "~" + date.format("YYYYMMDD");
    var targetStructureTimes = !_.isEmpty(this.state.assignmentStructureTimesDict)
      ? this.state.assignmentStructureTimesDict[assignIdKey]
      : undefined;
    return targetStructureTimes;
  },
  _getTargetStructureErrorDay: function (assignId, date) {
    var targetTemplate = this._getTargetTemplate();
    var assignIdKey = assignId + "~" + date.format("YYYYMMDD");
    var targetStructureErrorDay = !_.isEmpty(this.state.assignmentStructureErrorDayDict)
      ? this.state.assignmentStructureErrorDayDict[assignIdKey]
      : undefined;
    return targetStructureErrorDay;
  },
  _getDisplayStartTime: function (targetAssignment, targetStructure) {
    var targetTemplate = this._getTargetTemplate();
    var startTime = targetAssignment && targetAssignment._getAssignmentDefaultStartTime(targetTemplate.id);
    if (!startTime) {
      startTime = targetStructure ? targetStructure.split("~")[0] : undefined;
    }
    return startTime;
  },
  _getDisplayStopTime: function (targetAssignment, targetStructure) {
    var targetTemplate = this._getTargetTemplate();
    var stopTime = targetAssignment && targetAssignment._getAssignmentDefaultStopTime(targetTemplate.id);
    if (!stopTime) {
      stopTime = targetStructure ? targetStructure.split("~")[1] : undefined;
    }
    return stopTime;
  },
  _getDisplayFullTime: function (startTimeConverted, stopTimeConverted) {
    var preferredFormat = window.LbsAppData.Helpers.Time.preferredTimeFormat("LT");
    var formattedStartTime = startTimeConverted.format(preferredFormat).replace(" AM", "a").replace(" PM", "p");
    var formattedStopTime = stopTimeConverted.format(preferredFormat).replace(" AM", "a").replace(" PM", "p");
    return formattedStartTime + " - " + formattedStopTime;
  },
  _convertDisplayTime: function (date, time) {
    return window.LbsAppData.Helpers.Time.timezoneConverter(
      moment(date.format("YYYY-MM-DD") + "T" + time, "YYYY-MM-DDTHH:mm:s")
    );
  },
  _createDepartmentCollection: function (preData) {
    let departments;
    if (window.LbsAppData.Departments) {
      departments = window.LbsAppData.Departments;
    } else {
      // shove in a fake department/template using preData
      if (preData) {
        departments = new DepartmentCollection([], {});
      }
    }

    return departments;
  },
  _createTemplateCollection: function (preData) {
    let templates;
    if (window.LbsAppData.Departments) {
      templates = this._scrubTemplates(window.LbsAppData.Departments);
    } else {
      if (preData) {
        templates = new TemplateCollection(
          [
            {
              template_name: preData.get("template"),
            },
          ],
          {}
        );
      }
    }

    return templates;
  },
  _createPersonnelCollection: function (assignmentList, departmentList, preData) {
    const that = this;

    let personnel;
    switch (this.props.dialogObject.type) {
      case "swap-details":
        // get personnel from the swaps selected
        const models = _.uniq(
          _.map(preData.slots, function (s) {
            return window.LbsAppData.Personnel.get(s.get("emp_id"));
          })
        );
        personnel = new PersonnelCollection(models, {
          assignmentList: assignmentList,
          departmentList: departmentList,
          comparator: function (a, b) {
            if (a.attributes.display_name.toLowerCase() === b.attributes.display_name.toLowerCase()) {
              return 0;
            } else {
              if (a.attributes.display_name.toLowerCase() < b.attributes.display_name.toLowerCase()) {
                return -1;
              } else {
                return 1;
              }
            }
          },
        });
        break;
      case "preswap-finalize":
        // swop finalize -- make a collection of _accepters_
        let pArray = _.map(
          _.filter(preData.slots[0].get("pending_info").preswaps, function (ps) {
            return ps.response === 2;
          }),
          function (a) {
            return {
              emp_id: a.emp_id,
              display_name: a.display_name,
            };
          }
        );
        for (let i = 1; i < preData.slots.length; i++) {
          pArray = _.map(
            _.filter(preData.slots[i].get("pending_info").preswaps, function (ps) {
              return (
                _.find(pArray, function (pa) {
                  return pa.emp_id === ps.emp_id;
                }) && ps.response === 2
              );
            }),
            function (a) {
              return {
                emp_id: a.emp_id,
                display_name: a.display_name,
              };
            }
          );
        }

        personnel = new PersonnelCollection(pArray, {
          comparator: function (a, b) {
            if (a.attributes.display_name.toLowerCase() === b.attributes.display_name.toLowerCase()) {
              return 0;
            } else {
              if (a.attributes.display_name.toLowerCase() < b.attributes.display_name.toLowerCase()) {
                return -1;
              } else {
                return 1;
              }
            }
          },
        });
        break;
      default:
        if (this.props.dialogObject.type === "split-shift") {
          personnel = new PersonnelCollection([], {
            assignmentList: assignmentList,
            departmentList: departmentList,
            comparator: function (a, b) {
              if (a.attributes.display_name.toLowerCase() === b.attributes.display_name.toLowerCase()) {
                return 0;
              } else {
                if (a.attributes.display_name.toLowerCase() < b.attributes.display_name.toLowerCase()) {
                  return -1;
                } else {
                  return 1;
                }
              }
            },
            excludeExpired: true,
          });
        } else {
          personnel = new PersonnelCollection([], {
            assignmentList: assignmentList,
            departmentList: departmentList,
            comparator: function (a, b) {
              if (a.attributes.display_name.toLowerCase() === b.attributes.display_name.toLowerCase()) {
                return 0;
              } else {
                if (a.attributes.display_name.toLowerCase() < b.attributes.display_name.toLowerCase()) {
                  return -1;
                } else {
                  return 1;
                }
              }
            },
          });
        }
        personnel.postFetch = function () {
          const collection = this;
          const template = that._getTargetTemplate();
          const targetAssignment = that._getTargetAssignment();
          let assignIds;
          if (_.isArray(targetAssignment)) {
            assignIds = [];
            // use a unique concat of the assign ids
            for (var i = 0; i < targetAssignment.length; i++) {
              assignIds = assignIds.concat(targetAssignment[i]._getAssignmentIDForTemplate(template.id));
            }
            if (assignIds.length) {
              assignIds = _.uniq(assignIds);
            } else {
              // none so set it to undefined for the check inside the promise
              assignIds = undefined;
            }
          } else {
            // single assignment
            assignIds = targetAssignment ? [targetAssignment._getAssignmentIDForTemplate(template.id)] : undefined;
          }

          const promises = [];

          const compatibility = that._canCheckCompatibility() ? that._createCompatibilityMap() : undefined;
          if (compatibility) {
            // push compatibility fetch
            promises.push(
              new Promise(function (resolve, reject) {
                compatibility.fetch({
                  success: function (model) {
                    resolve();
                  },
                });
              })
            );
          }
          // push role filter
          promises.push(
            new Promise(function (resolve, reject) {
              const filteredModels = collection.filter(function (model) {
                if (model.get("expired") === true) {
                  return false;
                }
                // check for roles for every (possibly) selected assignment
                if (assignIds) {
                  if (_.difference(assignIds, model.get("roles")).length > 0) {
                    return false;
                  }
                }
                // permissions/expiration/schedulable
                if (that._isSwap()) {
                  if (
                    !model.get("scheduled") ||
                    !model.isActiveDuringDates(_.keys(that.state.selectedDates)) ||
                    (!window.LbsAppData.Helpers.Permissions.CanIChangeThisPersonsSchedule(model) &&
                      !that._isCurrentUserSlots()) ||
                    that._isCurrentlyScheduledOnAllSelectedSlots(model)
                  ) {
                    return false;
                  }
                } else {
                  if (
                    !model.get("scheduled") ||
                    !model.isActiveDuringDates(_.keys(that.state.selectedDates)) ||
                    !window.LbsAppData.Helpers.Permissions.CanIChangeThisPersonsRequests(model)
                  ) {
                    return false;
                  }
                }
                return true;
              });
              // reset the collection with the filteredModels
              collection.reset(filteredModels, { silent: true });
              resolve();
            })
          );

          return Promise.all(promises).then(function () {
            if (compatibility) {
              // return the merge of compatibility and personnel
              _.each(collection.models, function (m) {
                if (_.has(compatibility.get("dict_personnel_by_id"), m.id)) {
                  // attach compat attributes
                  m.attributes.compatibility = compatibility.get("dict_personnel_by_id")[m.id];
                }
              });
            }
          });
        };
        break;
    }
    return personnel;
  },
  _createAssignmentsCollection: function (templateList, preData) {
    var that = this;
    var assignments;
    switch (this.props.dialogObject.type) {
      case "swap-details":
        var models = _.uniq(
          _.map(preData.slots, function (s) {
            return window.LbsAppData.Assignments.get(s.get("condensed_structure_id"));
          })
        );
        assignments = new AssignmentCollection(models, {
          tempList: templateList,
          comparator: function (a, b) {
            if (a.attributes.display_name.toLowerCase() == b.attributes.display_name.toLowerCase()) {
              return 0;
            } else {
              if (a.attributes.display_name.toLowerCase() < b.attributes.display_name.toLowerCase()) {
                return -1;
              } else {
                return 1;
              }
            }
          },
        });
        break;
      case "swap-replace":
      case "swap-pending":
        var models = _.uniq(
          _.map(preData.slots, function (s) {
            return window.LbsAppData.Assignments.get(s.get("condensed_structure_id"));
          })
        );
        assignments = new AssignmentCollection(models, {
          tempList: templateList,
          comparator: function (a, b) {
            if (a.attributes.display_name.toLowerCase() == b.attributes.display_name.toLowerCase()) {
              return 0;
            } else {
              if (a.attributes.display_name.toLowerCase() < b.attributes.display_name.toLowerCase()) {
                return -1;
              } else {
                return 1;
              }
            }
          },
        });

        assignments.postFetch = function () {
          const collection = this;
          const template = that.state.templates.get(collection.attributes.tempList[0]); /*that._getTargetTemplate();*/ //that.state.selectedTemplate ? that.state.selectedTemplate : that.state.templates.get(that.state.selectedSlots[0].get('template_id'));
          const targetPersonnel = that._getTargetPersonnel();
          const empRoles =
            targetPersonnel && targetPersonnel.attributes.hasOwnProperty("roles")
              ? targetPersonnel?.get("roles")
              : undefined;

          return new Promise(function (resolve, reject) {
            const filteredModels = collection.filter(function (model) {
              // possible roles lookup for a selected personnel
              if (empRoles) {
                if (_.indexOf(empRoles, model._getAssignmentIDForTemplate(template?.id)) === -1) {
                  return false;
                }
              }

              // permissions/expiration
              if (that._isSwap()) {
                if (
                  model._isExpired() ||
                  !model._isAssignmentOnTemplate(template?.id) ||
                  !window.LbsAppData.Helpers.Permissions.CanIChangeAssignmentOnScheduleForTemplate(model, template)
                ) {
                  return false;
                }
              } else {
                if (
                  model._isExpired() ||
                  !model._isAssignmentOnTemplate(template?.id) ||
                  !window.LbsAppData.Helpers.Permissions.CanIChangeAssignmentOnRequestsForTemplate(model, template)
                ) {
                  return false;
                }
              }

              return true;
            });

            // reset the collection with the filteredModels
            collection.reset(filteredModels, { silent: true });
            resolve();
          });
        };

        break;

      case "request-action":
        var models = _.uniq(
          _.map(preData.requests, function (s) {
            return window.LbsAppData.Assignments.get(s.get("condensed_structure_id"));
          })
        );
        assignments = new AssignmentCollection(models, {
          tempList: templateList,
          comparator: function (a, b) {
            if (a.attributes.display_name.toLowerCase() == b.attributes.display_name.toLowerCase()) {
              return 0;
            } else {
              if (a.attributes.display_name.toLowerCase() < b.attributes.display_name.toLowerCase()) {
                return -1;
              } else {
                return 1;
              }
            }
          },
        });
        assignments.postFetch = function () {
          var collection = this;
          var template = that.state.templates.get(collection.attributes.tempList[0]); /*that._getTargetTemplate();*/ //that.state.selectedTemplate ? that.state.selectedTemplate : that.state.templates.get(that.state.selectedSlots[0].get('template_id'));
          var targetPersonnel = that._getTargetPersonnel();
          var empRoles = targetPersonnel ? targetPersonnel.get("roles") : undefined;
          return new Promise(function (resolve, reject) {
            var filteredModels = collection.filter(function (model) {
              // possible roles lookup for a selected personnel
              if (empRoles) {
                if (_.indexOf(empRoles, model._getAssignmentIDForTemplate(template.id)) == -1) {
                  return false;
                }
              }
              // permissions/expiration
              if (that._isSwap()) {
                if (
                  model._isExpired() ||
                  !model._isAssignmentOnTemplate(template.id) ||
                  !window.LbsAppData.Helpers.Permissions.CanIChangeAssignmentOnScheduleForTemplate(model, template)
                ) {
                  return false;
                }
              } else {
                if (
                  model._isExpired() ||
                  !model._isAssignmentOnTemplate(template.id) ||
                  !window.LbsAppData.Helpers.Permissions.CanIChangeAssignmentOnRequestsForTemplate(model, template)
                ) {
                  return false;
                }
              }
              return true;
            });
            // reset the collection with the filteredModels
            collection.reset(filteredModels, { silent: true });
            resolve();
          });
        };
        break;
      default:
        assignments = new AssignmentCollection([], {
          tempList: templateList,
          comparator: function (a, b) {
            if (a.attributes.display_name.toLowerCase() == b.attributes.display_name.toLowerCase()) {
              return 0;
            } else {
              if (a.attributes.display_name.toLowerCase() < b.attributes.display_name.toLowerCase()) {
                return -1;
              } else {
                return 1;
              }
            }
          },
        });
        assignments.postFetch = function () {
          var collection = this;
          var template = that.state.templates.get(collection.attributes.tempList[0]); /*that._getTargetTemplate();*/ //that.state.selectedTemplate ? that.state.selectedTemplate : that.state.templates.get(that.state.selectedSlots[0].get('template_id'));
          var targetPersonnel = that._getTargetPersonnel();
          var empRoles = undefined;
          if (targetPersonnel && !targetPersonnel.length) {
            empRoles = targetPersonnel.get("roles");
          } else if (targetPersonnel && targetPersonnel.length > 1) {
            var roleCandidates = targetPersonnel[0].get("roles");
            for (var i = 1; i < targetPersonnel.length; i++) {
              roleCandidates = _.intersection(roleCandidates, targetPersonnel[i].get("roles"));
            }
            empRoles = roleCandidates;
          }
          return new Promise(function (resolve, reject) {
            var filteredModels = collection.filter(function (model) {
              // possible roles lookup for a selected personnel
              if (empRoles) {
                if (_.indexOf(empRoles, model._getAssignmentIDForTemplate(template.id)) == -1) {
                  return false;
                }
              }
              // permissions/expiration
              if (that._isSwap()) {
                if (
                  model._isExpired() ||
                  !model._isAssignmentOnTemplate(template.id) ||
                  !window.LbsAppData.Helpers.Permissions.CanIChangeAssignmentOnScheduleForTemplate(model, template)
                ) {
                  return false;
                }
              } else {
                if (
                  model._isExpired() ||
                  !model._isAssignmentOnTemplate(template.id) ||
                  !window.LbsAppData.Helpers.Permissions.CanIChangeAssignmentOnRequestsForTemplate(model, template)
                ) {
                  return false;
                }
              }
              return true;
            });
            // reset the collection with the filteredModels
            collection.reset(filteredModels, { silent: true });
            resolve();
          });
        };
        break;
    }
    return assignments;
  },
  _createLoaReasonsCollection: function () {
    return new LoaReasonCollection(SafeJSON.parse(JSON.stringify(window.LbsAppData.LoaReasons.models)), {
      comparator: function (a, b) {
        if (a.attributes.name.toLowerCase() === b.attributes.name.toLowerCase()) {
          return 0;
        } else {
          if (a.attributes.name.toLowerCase() < b.attributes.name.toLowerCase()) {
            return -1;
          } else {
            return 1;
          }
        }
      },
    });
  },
  _createDenialReasonsCollection: function (preData) {
    // must have a slot selected if we're creating this
    var aType = preData.slots ? preData.slots[0].get("assign_atype") : preData.requests[0].get("assign_atype");
    var filteredReasons = window.LbsAppData.DenialReasons.filter(function (d) {
      return _.indexOf(d.get("assignment_types"), aType) > -1;
    });
    var denialReasons = new DenialReasonCollection(SafeJSON.parse(JSON.stringify(filteredReasons)), {
      //assign_type: aType,
      comparator: function (a, b) {
        if (a.attributes.name.toLowerCase() == b.attributes.name.toLowerCase()) {
          return 0;
        } else {
          if (a.attributes.name.toLowerCase() < b.attributes.name.toLowerCase()) {
            return -1;
          } else {
            return 1;
          }
        }
      },
    });

    return denialReasons;
  },
  _createLocationsCollection: function () {
    var locations = new LocationCollection(SafeJSON.parse(JSON.stringify(window.LbsAppData.Locations.models)), {
      comparator: function (a, b) {
        if (a.attributes.name.toLowerCase() == b.attributes.name.toLowerCase()) {
          return 0;
        } else {
          if (a.attributes.name.toLowerCase() < b.attributes.name.toLowerCase()) {
            return -1;
          } else {
            return 1;
          }
        }
      },
    });

    return locations;
  },
  _createSlotsCollection: function () {
    var slots = new SlotCollection([], {});
    return slots;
  },
  _createCompatibilityMap: function () {
    var targetDates =
      _.keys(this.state.selectedDates).length > 0
        ? _.keys(this.state.selectedDates)
        : _.map(this.state.selectedSlots, function (s) {
            return s.get("slot_date").replace(/-/g, "");
          });
    var targetAssignment = this._getTargetAssignment();
    var templateId = this._getTargetTemplate().id;
    var assignmentId = targetAssignment.get("template_to_assign_id_map")[templateId];
    return new CompatibilityMap({
      assign_id: assignmentId,
      date: targetDates[0],
    });
  },
  _addDetailsError: function (targetData, startTimeSubmitted) {
    var detailsErrors = this.state.detailsErrors;
    var errorDate = moment(targetData.get("slot_date")).format("YYYYMMDD");
    var errorTime = moment(startTimeSubmitted).format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"));
    var originalTime = moment(targetData.get("start_time")).format(
      window.LbsAppData.Helpers.Time.preferredTimeFormat("LT")
    );
    var errorAssignment = targetData.get("assign_display_name");
    var errorMessage = errorAssignment + " from " + originalTime + " to " + errorTime;
    detailsErrors.push({ date: errorDate, message: errorMessage });
    this.setState({ detailsErrors: detailsErrors });
  },
  _addFillError: function (targetData) {
    var fillErrors = this.state.fillErrors;
    var errorMessage = targetData.targetAssignment.get("display_name");
    const newFillError = { date: targetData.targetDate, message: errorMessage };

    if (this._isUniqueFillError(fillErrors, newFillError)) {
      fillErrors.push(newFillError);
    }
    this.setState({ fillErrors: fillErrors });
  },
  _isUniqueFillError: function (fillErrors, newFillError) {
    if (!fillErrors.length) {
      return true;
    }

    return _.find(fillErrors, function (error) {
      return error.date !== newFillError.date || error.message !== newFillError.message;
    });
  },
  _convertSwapDateAndTime: function (targetData) {
    var targetTemplate = this._getTargetTemplate();
    var targetDate = new moment(targetData.targetDate, "YYYYMMDD");
    var targetDateConverted = new moment(targetData.targetDate, "YYYYMMDD");
    var customTime = this.gatherCustomTimes(targetDate);
    var startTime = targetData.targetStructure ? targetData.targetStructure.split("~")[0] : undefined;
    var startTimeConverted = null;
    var stopTimeConverted = null;

    var targetAssignment = targetData.targetAssignment;
    var originalStartTime = moment(targetData.targetDate + startTime, "YYYYMMDDHH:mm:ss");

    if (customTime && customTime["s"] && customTime["e"]) {
      var customTimesConverted = this._convertTimesForDBSubmit(customTime);
      targetDateConverted = customTimesConverted["date"]; //window.LbsAppData.Helpers.Time.timezoneConverter(customTime['s'], true);
      startTimeConverted = customTimesConverted["start"];
      stopTimeConverted = customTimesConverted["stop"];
    } else if (startTime) {
      targetDateConverted = this._convertTargetDate(originalStartTime);
    } else {
      targetDateConverted = this._convertForApiError(
        targetAssignment.attributes.template_to_assign_id_map[targetTemplate.id],
        targetDate
      );
      if (!targetDateConverted) {
        return;
      }
    }

    return {
      start: startTimeConverted,
      stop: stopTimeConverted,
      date: targetDateConverted,
    };
  },
  _convertTimesForDBSubmit: function (timeToConvert) {
    var targetDateConverted = window.LbsAppData.Helpers.Time.timezoneConverter(timeToConvert["s"], true);
    var startTimeConverted = targetDateConverted.format("YYYY-MM-DDTHH:mm:ss");
    var stopTimeConverted = window.LbsAppData.Helpers.Time.timezoneConverter(timeToConvert["e"], true).format(
      "YYYY-MM-DDTHH:mm:ss"
    );

    return {
      start: startTimeConverted,
      stop: stopTimeConverted,
      date: targetDateConverted,
    };
  },
  _convertTargetDate: function (originalStartTime) {
    var originalInputStart = originalStartTime.format("YYYYMMDD");
    var shiftedInputStart = window.LbsAppData.Helpers.Time.timezoneConverter(originalStartTime).format("YYYYMMDD");
    var targetDateConverted = originalStartTime;
    if (originalInputStart > shiftedInputStart) {
      targetDateConverted = targetDateConverted.add(1, "days");
    } else if (originalInputStart < shiftedInputStart) {
      targetDateConverted = targetDateConverted.subtract(1, "days");
    }
    return targetDateConverted;
  },
  _convertForApiError: function (assignId, originalStartDate) {
    var errorDateToSubmit = this._getTargetStructureErrorDay(assignId, originalStartDate);
    if (errorDateToSubmit) {
      errorDateToSubmit = moment(errorDateToSubmit, "YYYYMMDD");
    }
    return errorDateToSubmit;
  },
  _isSameDate: function (originalTime, timeToSubmit) {
    return moment(originalTime).isSame(moment(timeToSubmit), "day");
  },
  _gatherSubmissionAndValidationTimes: function (customTime, date, targetData) {
    let timeToSubmit;
    if (!customTime["s"] || !customTime["e"]) {
      // default time submission
      var defaultSlotTime = this._getTargetStructureTimes(targetData.get("assign_id"), date);
      if (defaultSlotTime) {
        var selectedStartTimeString = date.format("YYYY-MM-DD") + "T" + defaultSlotTime.split("~")[0];
        var originalStartTime = moment(selectedStartTimeString, "YYYY-MM-DDTHH:mm:ss");
        var convertedStartTime = this._convertTargetDate(originalStartTime);
        // default times are submitted as null
        timeToSubmit = { start: null, stop: null };
      }
    } else {
      timeToSubmit = this._convertTimesForDBSubmit(customTime);
      var selectedStartTimeString = customTime["s"];
      var convertedStartTime = timeToSubmit["start"];
    }
    return {
      timeToSubmit: timeToSubmit,
      selectedStartTimeString: selectedStartTimeString,
      convertedStartTime: convertedStartTime,
    };
  },

  _isTZAwarenessEnabled: function () {
    return window.LbsAppData.User.attributes.parameters.LBLiteTimeZoneAwareness;
  },

  _buildSwapCollection: function (forceThrough) {
    const that = this;
    let submissionText,
      trackingLabel = "";
    let swaps = new SwapCollection();

    switch (this.state.action) {
      case "swap-fill":
        submissionText = "Submitting fill";
        trackingLabel = "Fill";
        var permutations = this._permutateTargets();
        _.each(permutations, function (permutation) {
          let swap = new SwapFill();
          var targetDate = new moment(permutation.targetDate, "YYYYMMDD");
          var targetPersonnel = permutation.targetPersonnel;
          var targetAssignment = permutation.targetAssignment;
          var newDateTime = that._convertSwapDateAndTime(permutation);
          var customTime = that.gatherCustomTimes(targetDate);

          // here we goooo.. all of this is necessary.. all this is what you _must_ do to l-b
          // take it away A
          let assignStructure = targetAssignment.attributes.structures[0];
          // this by itself isn't enough...we just picked a structure that probably will work..unless...you guessed it...mapping
          if (targetAssignment.attributes.structure_mapping_lookup[assignStructure]) {
            // this is mapped...use whats in here
            assignStructure = targetAssignment.attributes.structure_mapping_lookup[assignStructure];
          }
          if (!newDateTime) {
            // if there is a fill error bail out of the fill
            that._addFillError(permutation);
          } else {
            swap = new SwapFill();
            swap.set({
              emp_id: targetPersonnel?.id || targetPersonnel.Id,
              date: newDateTime["date"].format("YYYY-MM-DDT00:00:00"),
              assign_id: targetAssignment._getAssignmentIDForTemplate(that.state.selectedTemplate.id),
              assign_structure_id: assignStructure,
              assign_name: targetAssignment.attributes.display_name,
              template_id: that.state.selectedTemplate.id,
              note:
                that.refs.DialogChild.getRef("Note") && ReactDOM.findDOMNode(that.refs.DialogChild.getRef("Note")).value
                  ? ReactDOM.findDOMNode(that.refs.DialogChild.getRef("Note")).value
                  : null,
              start_date: newDateTime["start"],
              end_date: newDateTime["stop"],
              auto_add_demand: that.state.addDemand,
            });

            // optional loa reason
            if (that.state.selectedReason) {
              swap.set({
                loa_reason_id: that.state.selectedReason.id,
              });
            }
            // optional location
            if (that.state.selectedLocations.length) {
              swap.set({
                location_ids: _.map(that.state.selectedLocations, function (l) {
                  return l.id;
                }),
              });
            }

            //call order
            let call_order =
              that.refs.DialogChild.getRef("CallOrderFill") &&
              ReactDOM.findDOMNode(that.refs.DialogChild.getRef("CallOrderFill")).value
                ? ReactDOM.findDOMNode(that.refs.DialogChild.getRef("CallOrderFill")).value
                : null;

            if (call_order && call_order !== "default") {
              swap.set({
                call_order: call_order !== "none" ? Number(call_order) : null,
              });
            }

            // this will bypass the compat check...maybe more in the future
            if (forceThrough) {
              swap.set({ allow_force_save: true });
            }

            // add it to our new (fake) collection
            swaps.add(swap);
          }
        });
        break;

      case "swap-replace":
        submissionText = "Submitting replace";
        // switch swaps out to a preswap collection if necessary
        swaps = this.state.selectedPersonnel.length < 2 ? swaps : new PreSwapCollection();
        var submitSlots = _.indexBy(this.state.selectedSlots, function (s) {
          return s.id;
        });
        // create swaps for each slot
        _.each(submitSlots, function (value, key) {
          var swap;
          var slot = value;
          var note =
            that.refs.DialogChild.getRef("Note") && ReactDOM.findDOMNode(that.refs.DialogChild.getRef("Note")).value
              ? ReactDOM.findDOMNode(that.refs.DialogChild.getRef("Note")).value
              : null;
          var slotReasonId = that.state.selectedSlots[0] ? that.state.selectedSlots[0].get("loa_reason_id") : undefined;
          if (that.state.selectedPersonnel.length === 1) {
            trackingLabel = "Replace: Personnel";

            swap = new SwapReplace({
              slot_id: slot.get("slot_id"),
              emp_id: that.state.selectedPersonnel[0].id || that.state.selectedPersonnel[0].Id,
              note,
              template_id: that.state.selectedTemplate?.id ?? slot.templateObj.id,
            });
          } else if (that.state.selectedPersonnel.length > 1) {
            trackingLabel = "Swaportunity: Create";

            swap = new PreSwapCreate({
              slot_id: slot.get("slot_id"),
              emp_ids: _.map(that.state.selectedPersonnel, function (p) {
                return p.id || p.Id;
              }),
            });
          } else if (that.state.selectedAssignments.length) {
            trackingLabel = "Replace: Assignment";

            swap = new SwapAssignment();
            var useTemplate = that.state.selectedTemplate.id;
            // here we goooo.. all of this is necessary.. all this is what you _must_ do to l-b
            // take it away A
            var assignStructure = that.state.selectedAssignments[0].attributes.structures[0];
            // this by itself isn't enough...we just picked a structure that probably will work..unless...you guessed it...mapping
            if (that.state.selectedAssignments[0].attributes.structure_mapping_lookup[assignStructure]) {
              // this is mapped...use whats in here
              assignStructure = that.state.selectedAssignments[0].attributes.structure_mapping_lookup[assignStructure];
            }

            swap.set({
              slot_id: slot.get("slot_id"),
              assign_structure_id: assignStructure,
              template_id: useTemplate,
              note,
            });
          }
          // more possible shit..
          // optional loa reason
          if (that.state.selectedReason) {
            swap.set({
              loa_reason_id: that.state.selectedReason.id,
            });
          } else if (!that.state.selectedAssignments.length && slotReasonId) {
            swap.set({
              loa_reason_id: slotReasonId,
            });
          }
          // optional location
          if (that.state.selectedLocations.length) {
            swap.set({
              location_ids: _.map(that.state.selectedLocations, function (l) {
                return l.id;
              }),
            });
          }

          if (that.state.addDemand) {
            swap.set({ auto_add_demand: that.state.addDemand });
          }

          // this will bypass the compat check...maybe more in the future
          if (forceThrough) {
            swap.set({ allow_force_save: true });
          }

          swaps.add(swap);
        });
        break;
      case "split-shift":
        submissionText = "Submitting split shift";

        // create swaps for each slot
        var slot = this.state.selectedSlots[0];
        var splitShiftDialog = that.refs.DialogChild;
        var note = splitShiftDialog.state.noteValue;

        var splits;
        if (splitShiftDialog.state.isSingleSplit) {
          splits = [
            {
              start_time: that._isTZAwarenessEnabled()
                ? window.LbsAppData.Helpers.Time.timezoneConverter(splitShiftDialog.state.startDate, true).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )
                : window.LbsAppData.Helpers.Time.formatDateAsIsoString(splitShiftDialog.state.startDate),
              end_time: that._isTZAwarenessEnabled()
                ? window.LbsAppData.Helpers.Time.timezoneConverter(splitShiftDialog.state.singleSplitDate, true).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )
                : window.LbsAppData.Helpers.Time.formatDateAsIsoString(splitShiftDialog.state.singleSplitDate),
              emp_ids: that._getSplitPersonnelIds(that.state.selectedPersonnel, slot),
            },
            {
              start_time: that._isTZAwarenessEnabled()
                ? window.LbsAppData.Helpers.Time.timezoneConverter(splitShiftDialog.state.singleSplitDate, true).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )
                : window.LbsAppData.Helpers.Time.formatDateAsIsoString(splitShiftDialog.state.singleSplitDate),
              end_time: that._isTZAwarenessEnabled()
                ? window.LbsAppData.Helpers.Time.timezoneConverter(splitShiftDialog.state.endDate, true).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )
                : window.LbsAppData.Helpers.Time.formatDateAsIsoString(splitShiftDialog.state.endDate),
              emp_ids: that._getSplitPersonnelIds(that.state.selectedPersonnel2, slot),
            },
          ];
        } else {
          splits = [];

          if (splitShiftDialog.state.startDate < splitShiftDialog.state.leftSplitDate) {
            splits.push({
              start_time: that._isTZAwarenessEnabled()
                ? window.LbsAppData.Helpers.Time.timezoneConverter(splitShiftDialog.state.startDate, true).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )
                : window.LbsAppData.Helpers.Time.formatDateAsIsoString(splitShiftDialog.state.startDate),
              end_time: that._isTZAwarenessEnabled()
                ? window.LbsAppData.Helpers.Time.timezoneConverter(splitShiftDialog.state.leftSplitDate, true).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )
                : window.LbsAppData.Helpers.Time.formatDateAsIsoString(splitShiftDialog.state.leftSplitDate),
              emp_ids: that._getSplitPersonnelIds(that.state.selectedPersonnel, slot),
            });
          }

          splits.push({
            start_time: that._isTZAwarenessEnabled()
              ? window.LbsAppData.Helpers.Time.timezoneConverter(splitShiftDialog.state.leftSplitDate, true).format(
                  "YYYY-MM-DDTHH:mm:ss"
                )
              : window.LbsAppData.Helpers.Time.formatDateAsIsoString(splitShiftDialog.state.leftSplitDate),
            end_time: that._isTZAwarenessEnabled()
              ? window.LbsAppData.Helpers.Time.timezoneConverter(splitShiftDialog.state.rightSplitDate, true).format(
                  "YYYY-MM-DDTHH:mm:ss"
                )
              : window.LbsAppData.Helpers.Time.formatDateAsIsoString(splitShiftDialog.state.rightSplitDate),
            emp_ids: that._getSplitPersonnelIds(that.state.selectedPersonnel2, slot),
          });

          if (splitShiftDialog.state.rightSplitDate < splitShiftDialog.state.endDate) {
            splits.push({
              start_time: that._isTZAwarenessEnabled()
                ? window.LbsAppData.Helpers.Time.timezoneConverter(splitShiftDialog.state.rightSplitDate, true).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )
                : window.LbsAppData.Helpers.Time.formatDateAsIsoString(splitShiftDialog.state.rightSplitDate),
              end_time: that._isTZAwarenessEnabled()
                ? window.LbsAppData.Helpers.Time.timezoneConverter(splitShiftDialog.state.endDate, true).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )
                : window.LbsAppData.Helpers.Time.formatDateAsIsoString(splitShiftDialog.state.endDate),
              emp_ids: that._getSplitPersonnelIds(that.state.selectedPersonnel3, slot),
            });
          }
        }

        trackingLabel = "Split Shift";

        swaps = new SwapSplitShift({
          slot_id: slot.get("slot_id"),
          splits: splits,
          note: note,
        });

        // this will bypass the compat check...maybe more in the future
        if (forceThrough) {
          swaps.set({ allow_force_save: true });
        }

        break;
      case "swap-exchange":
        submissionText = "Submitting exchange";
        trackingLabel = "Exchange";
        _.each(this.state.selectedSlots, function (i) {
          var swap = new SwapExchange();
          swap.set({
            slot_id: i.get("slot_id"),
            allow_force_save: true,
          });

          swaps.add(swap);
        });
        break;

      case "swap-remove":
        submissionText = "Submitting remove";
        trackingLabel = "Remove";
        _.each(this.state.selectedSlots, function (i) {
          var swap = new SwapRemove();
          swap.set({
            slot_id: i.get("slot_id"),
          });

          if (forceThrough) {
            swap.set({ allow_force_save: true });
          }

          swaps.add(swap);
        });
        break;

      case "swap-details":
        submissionText = "Updating details";
        trackingLabel = "Modify";
        _.each(this.state.selectedSlots, function (i) {
          var date = new moment(i.get("slot_date"), "YYYYMMDD");
          var startDate = null;
          var endDate = null;
          var customTime = that.gatherCustomTimes(date);
          var gatheredTimes = that._gatherSubmissionAndValidationTimes(customTime, date, i);
          var timeToSubmit = gatheredTimes.timeToSubmit;
          // if the user is using custom time or time zone awareness is enabled
          // check if submission will be valid
          if (timeToSubmit || that._isTZAwarenessEnabled()) {
            if (!that._isSameDate(i.get("start_time_original"), gatheredTimes.convertedStartTime)) {
              // if there is a details error bail out of the submitted time
              that._addDetailsError(i, gatheredTimes.selectedStartTimeString);
              timeToSubmit = undefined;
            }
          }
          var swap = new SwapDetail();
          var slotReasonId = i.get("loa_reason_id");
          // if there is no Note ref then we can't modify notes -- so carry over the current value
          // otherwise read it from the text field
          var note;
          if (!that.refs.DialogChild.getRef("Note")) {
            note = i.get("note");
          } else {
            note = ReactDOM.findDOMNode(that.refs.DialogChild.getRef("Note")).value
              ? ReactDOM.findDOMNode(that.refs.DialogChild.getRef("Note")).value
              : null;
          }
          var display_name_override;
          if (!that.refs.DialogChild.getRef("CustomDisplay")) {
            display_name_override = i.get("display_name_override");
          } else {
            display_name_override =
              ReactDOM.findDOMNode(that.refs.DialogChild.getRef("CustomDisplay")).value.trim() || null;
          }
          let call_order;
          if (!that.refs.DialogChild.getRef("CallOrder")) {
            call_order = i.get("call_order");
          } else {
            call_order = ReactDOM.findDOMNode(that.refs.DialogChild.getRef("CallOrder")).value
              ? ReactDOM.findDOMNode(that.refs.DialogChild.getRef("CallOrder")).value
              : null;
          }
          swap.set({
            slot_id: i.get("slot_id"),
            note: note,
            display_name_override: display_name_override,
            start_date: timeToSubmit ? timeToSubmit["start"] : undefined,
            end_date: timeToSubmit ? timeToSubmit["stop"] : undefined,
          });
          // optional call order
          // if call order exists, isn't falsey (ie 0), send the number or null
          // if it doesn't exist, or is 0 or undefined/null, don't send
          if (call_order && call_order !== "default") {
            swap.set({
              call_order: call_order !== "none" ? Number(call_order) : null,
            });
          }

          // optional loa reason
          if (that.state.selectedReason) {
            swap.set({
              loa_reason_id: that.state.selectedReason.id,
            });
          } else if (slotReasonId) {
            swap.set({
              loa_reason_id: slotReasonId,
            });
          }
          // optional location
          if (that.state.selectedLocations.length) {
            swap.set({
              location_ids: _.map(that.state.selectedLocations, function (l) {
                return l.id;
              }),
            });
          }

          if (forceThrough) {
            swap.set({ allow_force_save: true });
          }

          swaps.add(swap);
        });
        break;

      case "swap-pending":
        _.each(this.state.selectedSlots, function (i) {
          var swap;
          switch (that.state.pendingAction) {
            case "grant":
              swap = new SwapGrant();
              submissionText = "Granting swap";
              trackingLabel = "Grant";
              break;
            case "deny":
              swap = new SwapDeny();
              submissionText = "Denying swap";
              trackingLabel = "Deny";
              break;
            case "delete":
            case "cancel":
              swap = new SwapDelete();
              submissionText = "Deleting swap";
              trackingLabel = "Delete";
              break;
          }
          swap.set({
            slot_id: i.get("slot_id"),
            emp_id: i.get("pending_emp_id"), // API needs this to do things quicker...
            decision_note:
              that.refs.DialogChild.getRef("Note") && ReactDOM.findDOMNode(that.refs.DialogChild.getRef("Note")).value
                ? ReactDOM.findDOMNode(that.refs.DialogChild.getRef("Note")).value
                : null,
            location_ids: that.state.selectedLocations && that.state.selectedLocations.map((l) => l.id),
            loa_reason_id: that.state.selectedReason && that.state.selectedReason.get("loa_reason_id"),
          });
          // optional reason
          if (that.state.selectedDenialReason) {
            swap.set({
              denial_reason_id: that.state.selectedDenialReason.id,
            });
          }

          if (forceThrough) {
            swap.set({ allow_force_save: true });
          }

          swaps.add(swap);
        });
        break;

      case "preswap-pending":
        // use a PreSwapCollection
        swaps = new PreSwapCollection();
        _.each(this.state.selectedSlots, function (i) {
          var swap;
          switch (that.state.pendingAction) {
            case "accept":
              swap = new PreSwapAccept();
              submissionText = "Accepting swaportunity";
              trackingLabel = "Accept";
              break;
            case "decline":
              swap = new PreSwapDeny();
              submissionText = "Declining swaportunity";
              trackingLabel = "Decline";
              break;
            case "delete":
            case "cancel":
              swap = new PreSwapDelete();
              submissionText = "Canceling swaportunity";
              trackingLabel = "Cancel";
              break;
          }
          swap.set({
            slot_id: i.get("slot_id"),
            emp_id: window.LbsAppData.User.get("emp_id"), // API needs this to do things quicker...
          });

          // this will bypass the compat check...maybe more in the future...should only be triggered for Accept's
          if (forceThrough) {
            swap.set({ allow_force_save: true });
          }

          swaps.add(swap);
        });
        break;

      case "preswap-finalize":
        submissionText = "Finalizing swaportunity";
        trackingLabel = "Finalize";
        // use a PreSwapCollection
        swaps = new PreSwapCollection();
        _.each(this.state.selectedSlots, function (i) {
          var swap = new PreSwapFinalize();
          // if there is no Note ref then we can't modify notes -- so carry over the current value
          // otherwise read it from the text field
          var note =
            that.refs.DialogChild.getRef("Note") && ReactDOM.findDOMNode(that.refs.DialogChild.getRef("Note")).value
              ? ReactDOM.findDOMNode(that.refs.DialogChild.getRef("Note")).value
              : null;
          var selectedPreswap = _.find(i.get("pending_info").preswaps, function (ps) {
            return ps.emp_id == that.state.selectedPersonnel[0].get("emp_id");
          });
          swap.set({
            slot_id: selectedPreswap.slot_id,
            emp_id: selectedPreswap.emp_id,
            note: note,
          });

          if (forceThrough) {
            swap.set({ allow_force_save: true });
          }

          swaps.add(swap);
        });
        break;

      default:
        break;
    }

    // return swaps collection as well as some extras that we figured out in here as well
    // -- i.e. tracking labels and message texts
    return {
      items: swaps,
      ext: {
        submissionText: submissionText,
        trackingLabel: trackingLabel,
      },
    };
  },
  _getSplitPersonnelIds: function (selectedPersonnel, slot) {
    if (selectedPersonnel.length > 0) {
      return selectedPersonnel.map(function (p) {
        return p.id;
      });
    } else {
      return [slot.get("emp_id")];
    }
  },
  _buildRequestCollection: function () {
    var that = this;
    var submissionText,
      trackingLabel = "";
    let requests = new RequestCollection();
    switch (this.state.action) {
      case "new-request":
        submissionText = "Submitting request";
        trackingLabel = "New";
        var permutations = this._permutateTargets();
        _.each(permutations, function (permutation) {
          var targetDate = new moment(permutation.targetDate, "YYYYMMDD");
          var targetPersonnel = permutation.targetPersonnel;
          var targetAssignment = permutation.targetAssignment;
          var newDateTime = that._convertSwapDateAndTime(permutation);
          let request = new RequestNew();
          var startDate = null;
          var endDate = null;
          var customTime = that.gatherCustomTimes(targetDate);

          var assignStructure = targetAssignment.attributes.structures[0];
          // this by itself isn't enough...we just picked a structure that probably will work..unless...you guessed it...mapping
          if (targetAssignment.attributes.structure_mapping_lookup[assignStructure]) {
            // this is mapped...use whats in here
            assignStructure = targetAssignment.attributes.structure_mapping_lookup[assignStructure];
          }
          if (!newDateTime) {
            that._addFillError(permutation);
          } else {
            request = new RequestNew();
            request.set({
              emp_id: targetPersonnel.id,
              date: newDateTime["date"].format("YYYY-MM-DDT00:00:00"),
              assign_id: targetAssignment._getAssignmentIDForTemplate(that.state.selectedTemplate.id),
              assign_structure_id: assignStructure,
              assign_name: targetAssignment.attributes.display_name,
              template_id: that.state.selectedTemplate.id,
              command_type: 0, // 'want'...hard coded for now
              note:
                that.refs.DialogChild.getRef("Note") && ReactDOM.findDOMNode(that.refs.DialogChild.getRef("Note")).value
                  ? ReactDOM.findDOMNode(that.refs.DialogChild.getRef("Note")).value
                  : null,
              start_date: newDateTime["start"],
              end_date: newDateTime["stop"],
            });
          }

          // optional loa reason
          if (that.state.selectedReason) {
            request.set({
              loa_reason_id: that.state.selectedReason.id,
            });
          }
          // optional location
          if (that.state.selectedLocations.length) {
            request.set({
              location_ids: _.map(that.state.selectedLocations, function (l) {
                return l.id;
              }),
            });
          }

          // add it to our new (fake) collection
          requests.add(request);
        });
        break;

      case "request-action":
        requests = new RequestCollection();
        _.each(this.state.selectedSlots, function (i) {
          var request;
          switch (that.state.pendingAction) {
            case "grant":
              request = new RequestGrant();
              submissionText = "Granting request";
              trackingLabel = "Grant";
              break;
            case "deny":
              request = new RequestDeny();
              submissionText = "Denying request";
              trackingLabel = "Deny";
              break;
            case "delete":
              request = new RequestDelete();
              submissionText = "Deleting request";
              trackingLabel = "Delete";
              break;
            case "suspend":
              request = new RequestSuspend();
              submissionText = "Suspending request";
              trackingLabel = "Suspend";
              break;
          }
          request.set({
            request_id: i.id,
            decision_note:
              that.refs.DialogChild.getRef("Note") && ReactDOM.findDOMNode(that.refs.DialogChild.getRef("Note")).value
                ? ReactDOM.findDOMNode(that.refs.DialogChild.getRef("Note")).value
                : null,
            location_ids: that.state.selectedLocations && that.state.selectedLocations.map((l) => l.id),
            loa_reason_id: that.state.selectedReason && that.state.selectedReason.get("loa_reason_id"),
          });
          // optional reason
          if (that.state.selectedDenialReason) {
            request.set({
              denial_reason_id: that.state.selectedDenialReason.id,
            });
          }

          requests.add(request);
        });
        break;

      default:
        break;
    }

    // return requests collection as well as some extras that we figured out in here as well
    // -- i.e. tracking labels and message texts
    return {
      items: requests,
      ext: {
        submissionText: submissionText,
        trackingLabel: trackingLabel,
      },
    };
  },
  _checkStateValidity: function () {
    var ret = false;

    switch (this.state.action) {
      case "new-request":
      case "swap-fill":
        if (!_.keys(this.state.selectedDates).length) {
          ret = "Please select at least one date";
        } else if (this._canChangeLocations() && !this.state.selectedLocations.length) {
          ret = "Please select a location";
        } else if (!this.state.selectedPersonnel.length) {
          ret = "Please select at least one provider";
        } else if (!this.state.selectedAssignments.length) {
          ret = "Please select at least one assignment";
        }
        break;
      case "swap-replace":
        if (
          !this.state.selectedPersonnel.length &&
          !this.state.selectedAssignments.length &&
          !this._canChangeLocations()
        ) {
          ret = "No changes have been made.";
        }

        if (this.state.selectedPersonnel.length) {
          //replace by personnel
          if (
            this._canChangeLocations() &&
            !this.state.selectedLocations.length &&
            this.state.selectedSlots[0].get("location_names").length === 0
          ) {
            ret = "Please select a location";
          }
        } else {
          //replace by assignment
          if (this._canChangeLocations() && !this.state.selectedLocations.length) {
            ret = "Please select a location";
          }
        }
        break;
      case "swap-details":
        if (
          this._canChangeLocations() &&
          !this.state.selectedLocations.length &&
          this.state.selectedSlots[0].get("location_names").length == 0
        ) {
          ret = "Please select a location";
        }
        break;
      default:
        break;
    }

    return ret;
  },
  _submitSwap: function (forceThrough, forceDetails) {
    var that = this;
    var requests = this._buildRequestCollection();
    var swaps = this._buildSwapCollection(forceThrough);

    var isNotValid = this._checkStateValidity();

    if (isNotValid) {
      window.LbsAppData.AppContext.openDialog({
        type: "message",
        message: isNotValid,
        timer: 1500,
      });
      // abort
      return;
    }
    if (this._blockFillErrors(forceDetails)) {
      this._checkFillTimeZoneChange(requests);
      return;
    }
    if (this._blockDetailsErrors(forceDetails)) {
      this._checkDetailsTimeZoneChange(swaps);
      return;
    }

    if (this._blockFillErrors(forceDetails)) {
      this._checkFillTimeZoneChange(swaps);
      return;
    }

    window.LbsAppData.AppContext.openLoadingDialog(swaps.ext.submissionText + "...", this, function () {
      //save the swaps via the api
      swaps.items.save(that._handleSwapSubmitSuccess, that._handleSubmitFailure);
    });

    // send a tracking event
    var trackingAction = _.indexOf("preswap", this.state.action) == -1 ? "Swap" : "Preswap";
    window.LbsAppData.Helpers.Analytics.sendEvent(trackingAction + ": Submit", swaps.ext.trackingLabel, swaps.length);
  },
  _submitRequest: function (forceDetails) {
    var that = this;

    var isNotValid = this._checkStateValidity();
    if (isNotValid) {
      window.LbsAppData.AppContext.openDialog({
        type: "message",
        message: isNotValid,
        timer: 1500,
      });
      // abort
      return;
    }

    var requests = this._buildRequestCollection();
    if (this._blockFillErrors(forceDetails)) {
      this._checkFillTimeZoneChange(requests);
      return;
    }
    window.LbsAppData.AppContext.openLoadingDialog(requests.ext.submissionText + "...", this, function () {
      // save the requests via the api
      requests.items.save(that._handleRequestSubmitSuccess, that._handleSubmitFailure);
    });

    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Request: Submit", requests.ext.trackingLabel, requests.length);
  },
  _handleSwapSubmitSuccess: function (response) {
    var that = this;
    // trigger a swap submission event
    window.LbsAppData.AppContext.trigger("submittedSwap");
    // close the "submitting" message
    window.LbsAppData.AppContext.closeDialog();

    // there could be errors (as well as successes if this was a bulk operation)
    if (response.warnings && response.warnings.length > 0) {
      window.LbsAppData.AppContext.openDialog({
        type: "schedule-edits-error",
        errors: response.warnings,
        callback: this.resendSwap, // we'll call this with forceThrough == true
      });
    } else if (response.errors && response.errors.length > 0) {
      window.LbsAppData.AppContext.openDialog({
        type: "schedule-edits-error",
        errors: response.errors,
      });
    } else {
      // call a possible onSuccess function
      if (this.props.onSuccess) {
        this.props.onSuccess();
      }

      window.LbsAppData.AppContext.trigger("showSubmissionSuccess");
    }

    // meanwhile... we're gonna use the 'request submitted' curtain to trigger a redraw
    // --	has to be on the entire schedule I think for now, in case entirely new rows
    // 		need to be mounted
    if (response.slots && response.slots.length > 0) {
      if (window.LbsAppData.Slots) {
        // add/remove/merge slots from the response
        this._coalesceSwapResponse(response.slots);

        // regenerate the report (if it exists) before the redraw
        var promises = [];
        if (window.LbsAppData.Report) {
          promises.push(window.LbsAppData.AppContext.fetchReport());
          window.LbsAppData.AppContext.openLoadingDialog("Refreshing report...", this, undefined);
        }

        Promise.all(promises).then(function () {
          // show the message
          that._flashSubmissionMessage();
          // clean up the submission
          that._finishSubmission();
          // trigger the redraw (slight reverb)
          _.delay(function () {
            window.LbsAppData.AppContext.triggerRedraw();
          }, 10);
        });
      } else {
        // show the message
        this._flashSubmissionMessage();
        // no schedule -- dashboard page basically
        this._finishSubmission();
      }
    }
  },
  _handleRequestSubmitSuccess: function (response) {
    var that = this;
    // trigger a request submission event
    window.LbsAppData.AppContext.trigger("submittedRequest");
    // close the "submitting" message
    window.LbsAppData.AppContext.closeDialog();

    if (response.requests.length > 0 || (response.requests.length == 0 && this.state.pendingAction == "delete")) {
      if (window.LbsAppData.Requests) {
        this._coalesceRequestResponse(response.requests);

        this._finishSubmission();
        // trigger a redraw
        window.LbsAppData.AppContext.trigger("fetched");
      } else {
        this._finishSubmission();
      }
    }

    // non-delete operation
    if (response.errors.length > 0) {
      window.LbsAppData.AppContext.openDialog({
        type: "schedule-edits-error",
        errors: response.errors,
      });
    } else {
      const submitCallback = () => {
        // show the success message
        this._flashSubmissionMessage();

        // call a possible onSuccess function
        if (this.props.onSuccess) {
          this.props.onSuccess();
        }
      };

      if (window.LbsAppData.Report && this.props?.flags[LDFlagEnums.Lv6823TalliesInRequestCombinedViews]) {
        var promises = [];
        promises.push(window.LbsAppData.AppContext.fetchReport());

        Promise.all(promises).then(function () {
          submitCallback();
          // trigger the redraw (slight reverb)
          _.delay(function () {
            window.LbsAppData.AppContext.triggerRedraw();
          }, 10);
        });
      } else {
        submitCallback();
      }
    }

    //if (response.requests.length > 0) {
    // meanwhile... we're gonna use the 'request submitted' curtain to trigger a redraw
    // --	has to be on the entire schedule I think for now, in case entirely new rows
    // 		need to be mounted
    /*if (window.LbsAppData.Requests) {
        this._coalesceRequestResponse(response.requests);
        this._finishSubmission();
        // trigger a redraw
        window.LbsAppData.AppContext.triggerRedraw();
      }*/
    //}
  },
  _handleSubmitFailure: function (response) {
    // close the loading dialog
    window.LbsAppData.AppContext.closeDialog();
    // flash a message
    window.LbsAppData.AppContext.openDialog({
      type: "message",
      message: "There was a problem with your submission. Please refresh and try again.",
      timer: 4000,
    });
  },
  _checkDetailsTimeZoneChange: function (swaps) {
    window.LbsAppData.AppContext.openDialog({
      type: "schedule-edits-details-error",
      errors: this.state.detailsErrors,
      callback: swaps.items.length ? this.resendDetailsErrorSwap : undefined,
    });
    this.setState({ detailsErrors: [] });
  },
  _checkFillTimeZoneChange: function (swaps) {
    window.LbsAppData.AppContext.openDialog({
      type: "schedule-edits-fill-error",
      errors: this.state.fillErrors,
      callback: swaps.items.length ? this.resendDetailsErrorSwap : undefined,
    });
    this.setState({ fillErrors: [] });
  },
  _blockDetailsErrors: function (forceDetails) {
    return this.state.detailsErrors && this.state.detailsErrors.length && !forceDetails;
  },
  _blockFillErrors: function (forceDetails) {
    return this.state.fillErrors && this.state.fillErrors.length && !forceDetails && !this.state.addDemand;
  },
  _finishSubmission: function () {
    // close the loading dialog
    //window.LbsAppData.AppContext.closeDialog();
    // close the slot dialog
    //this.props.close();

    if (this._isSwap()) {
      window.LbsAppData.AppContext.closeDialogTo("slot-details", true, ["schedule-edits-error", "message"]);
    } else {
      window.LbsAppData.AppContext.closeDialogTo("request-details", true, ["schedule-edits-error", "message"]);
    }
  },
  _flashSubmissionMessage: function () {
    // figure out what to say
    var successMessage = this._getSwapSuccessMessage();
    // close the "submitting" message if it exists
    window.LbsAppData.AppContext.closeDialog("message");
    _.delay(function () {
      // display finished message
      window.LbsAppData.AppContext.openDialog({
        type: "message",
        message: successMessage,
        timer: 2000,
      });
    }, 10);
  },
  _getSwapSuccessMessage: function () {
    switch (this.state.action) {
      case "new-request":
        return "Request submitted";
      case "swap-fill":
        return "Fill submitted";
      case "swap-replace":
        return "Replace submitted";
      case "split-shift":
        return "Split Shift submitted";
      case "swap-exchange":
        return "Exchange submitted";
      case "swap-remove":
        return "Remove submitted";
      case "swap-details":
        return "Details modificiation submitted";
      case "swap-pending":
        var messagesDict = {
          grant: "Swap granted",
          deny: "Swap denied",
          delete: "Swap deleted",
        };
        return messagesDict[this.state.pendingAction];
      case "preswap-pending":
        var messagesDict = {
          accept: "Swaportunity accepted",
          decline: "Swaportunity declined",
          delete: "Swaportunity canceled",
          cancel: "Swaportunity canceled",
        };
        return messagesDict[this.state.pendingAction];
      case "preswap-finalize":
        return "Swaportunity finalized";
      case "request-action":
        var messagesDict = {
          grant: "Request granted",
          deny: "Request denied",
          delete: "Request deleted",
          suspend: "Request suspended",
        };
        return messagesDict[this.state.pendingAction];
      default:
        break;
    }
  },
  _coalesceSwapResponse: function (slots) {
    switch (this.state.action) {
      case "swap-fill":
        window.LbsAppData.Slots.add(slots);
        break;
      case "split-shift":
      case "swap-replace":
        for (var i = 0; i < slots.length; i++) {
          var swapAttrs = slots[i];
          // check to see if this slot is present on the grid
          var slot = window.LbsAppData.Slots.get(swapAttrs.slot_uuid);
          if (slot) {
            if (swapAttrs.emp_id !== 4) {
              // merge it
              slot.set(swapAttrs, { silent: true });
              slot.initialize();
            } else {
              // here's dotguy! merge it if it's pending, otherwise remove it
              if (swapAttrs.is_pending) {
                // merge it
                slot.set(swapAttrs, { silent: true });
                slot.initialize();
              } else {
                window.LbsAppData.Slots.remove(slot, {
                  silent: true,
                });
              }
            }
          } else {
            // need to add the slot to the collection
            window.LbsAppData.Slots.add(slots[i], { silent: true });
          }
        }
        break;

      case "swap-exchange":
        for (var i = 0; i < slots.length; i++) {
          var swapAttrs = slots[i];
          var slot = window.LbsAppData.Slots.get(swapAttrs.slot_uuid);
          if (slot) {
            slot.set(swapAttrs, { silent: true });
            slot.initialize();
          }
        }
        break;

      case "swap-remove":
        for (var i = 0; i < slots.length; i++) {
          var swapAttrs = slots[i];
          // check to see if this slot is present on the grid
          var slot = window.LbsAppData.Slots.get(swapAttrs.slot_uuid);
          if (slot) {
            // if the remove pended, then we update it...if it didn't pend we wipe it out of the collection
            if (swapAttrs.is_pending) {
              slot.set(swapAttrs, { silent: true });
              slot.initialize();
            } else {
              window.LbsAppData.Slots.remove(slot);
            }
          }
        }
        break;

      case "swap-details":
        // loop through the sent slots
        for (var i = 0; i < slots.length; i++) {
          var swapAttrs = slots[i];
          // check to see if this slot is present on the grid
          var slot = window.LbsAppData.Slots.get(swapAttrs.slot_uuid);
          if (slot) {
            slot.set(swapAttrs, { silent: true });
            slot.initialize();
          }
        }
        break;

      case "swap-pending":
        for (var i = 0; i < slots.length; i++) {
          var swapAttrs = slots[i];
          // check to see if this slot is present on the grid
          var slot = window.LbsAppData.Slots.get(swapAttrs.slot_uuid);
          if (slot) {
            // majority of the cases we update the slot and redraw it...but if dot_guy is the result of the slot
            // then we remove the slot from the collection as it's a blank slot at that point
            // --	getting into some shit logic here... if we're in the 'me' view and we just went unpending with
            // 		an emp_id that isn't ours remove it as well
            if (
              swapAttrs.emp_id !== window.LbsAppData.DOTGUY &&
              (window.LbsAppData.AppContext.get("view").view_id != "me" ||
                swapAttrs.emp_id == window.LbsAppData.User.get("emp_id"))
            ) {
              slot.set(swapAttrs, { silent: true });
              slot.initialize();
            } else {
              window.LbsAppData.Slots.remove(slot);
            }
          }
        }
        break;

      case "preswap-pending":
        for (var i = 0; i < slots.length; i++) {
          var swapAttrs = slots[i];
          // check to see if this slot is present on the grid
          var slot = window.LbsAppData.Slots.get(swapAttrs.slot_uuid);
          if (slot) {
            // majority of the cases we update the slot and redraw it...but if dot_guy is the result of the slot
            // then we remove the slot from the collection as it's a blank slot at that point
            // --	getting into some shit logic here... if we're in the 'me' view and we just went unpending with
            // 		an emp_id that isn't ours remove it as well
            if (
              swapAttrs.emp_id !== window.LbsAppData.DOTGUY &&
              (window.LbsAppData.AppContext.get("view").view_id != "me" ||
                swapAttrs.emp_id == window.LbsAppData.User.get("emp_id"))
            ) {
              slot.set(swapAttrs, { silent: true });
              slot.initialize();
            } else {
              window.LbsAppData.Slots.remove(slot);
            }
          }
        }
        break;

      case "preswap-finalize":
        for (var i = 0; i < slots.length; i++) {
          var swapAttrs = slots[i];
          // check to see if this slot is present on the grid
          var slot = window.LbsAppData.Slots.get(swapAttrs.slot_uuid);
          if (slot) {
            // majority of the cases we update the slot and redraw it...but if dot_guy is the result of the slot
            // then we remove the slot from the collection as it's a blank slot at that point
            // --	getting into some shit logic here... if we're in the 'me' view and we just went unpending with
            // 		an emp_id that isn't ours remove it as well
            if (
              swapAttrs.emp_id !== window.LbsAppData.DOTGUY &&
              (window.LbsAppData.AppContext.get("view").view_id != "me" ||
                swapAttrs.emp_id == window.LbsAppData.User.get("emp_id"))
            ) {
              slot.set(swapAttrs, { silent: true });
              slot.initialize();
            } else {
              window.LbsAppData.Slots.remove(slot);
            }
          }
        }
        break;

      default:
        break;
    }
  },
  _coalesceRequestResponse: function (requests) {
    switch (this.state.action) {
      case "new-request":
        window.LbsAppData.Requests.add(requests);
        break;

      case "request-action":
        if (this.state.pendingAction == "delete") {
          // was a delete...rip out the slots that did NOT come back...if they came back...they errored
          var deletedIDs = _.pluck(requests, "request_id");
          for (var i = 0; i < this.state.selectedSlots.length; i++) {
            if (deletedIDs.indexOf(this.state.selectedSlots[i].get("request_id")) == -1) {
              var request = window.LbsAppData.Requests
                ? window.LbsAppData.Requests.get(this.state.selectedSlots[i].get("request_id"))
                : undefined;
              if (request) {
                window.LbsAppData.Requests.remove(request);
              }
            }
          }
        } else {
          // loop through the sent slots
          for (var i = 0; i < requests.length; i++) {
            var reqAttrs = requests[i];
            // check to see if this slot is present on the grid
            var request = window.LbsAppData.Requests ? window.LbsAppData.Requests.get(reqAttrs.request_id) : undefined;
            if (request) {
              request.set(reqAttrs, { silent: true });
              request.initialize();
            }
          }
        }
        break;

      default:
        break;
    }
  },
  _scrubTemplates: function (collection) {
    var that = this;
    var departments = collection;

    var templates = [];
    _.each(departments.models, function (d) {
      if (d.id != -98765) {
        // MRC... i hate you.
        templates.push(d.get("templates"));
      }
    });

    return new TemplateCollection(_.flatten(templates), {
      comparator: function (a, b) {
        if (a.attributes.name.toLowerCase() == b.attributes.name.toLowerCase()) {
          return 0;
        } else {
          if (a.attributes.name.toLowerCase() < b.attributes.name.toLowerCase()) {
            return -1;
          } else {
            return 1;
          }
        }
      },
    });
  },
  _getLoaReasons: function (e) {
    var that = this;

    this.state.reasons.fetch({
      success: function (collection) {
        // do nothing
      },
    });
  },
  _getDenialReasons: function (e) {
    var that = this;

    this.state.denialReasons.fetch({
      success: function (collection) {
        // do nothing
      },
    });
  },

  _getCallOrderOptions: function (slotCallOrder, assignmentCallOrder) {
    if (assignmentCallOrder === null) {
      return (
        <>
          <option value={slotCallOrder}>{slotCallOrder}</option>
          <option value={"none"}>None</option>
        </>
      );
    }

    return (
      <>
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
        <option value={6}>6</option>
        <option value={7}>7</option>
        <option value={8}>8</option>
        <option value={9}>9</option>
        <option value={10}>10</option>
        <option value={"none"}>None</option>
      </>
    );
  },

  _getCallOrderFillData: function (assignments) {
    let defaultValue = "";
    let callOrderVisible = false;
    if (assignments.length > 0) {
      // check if we have only 1 assignment selected
      if (assignments.length === 1) {
        const selectedAssignment = assignments[0];
        // if the selected assignment has the call order value set (1-10)
        if (selectedAssignment.get("call_order")) {
          // show the select box on the form
          callOrderVisible = true;
          // set the call order value to the call order of the assignment
          defaultValue = selectedAssignment.get("call_order");
        }
      } else {
        // check if any of the selected assignments has call order set to none
        const hasNoneValue = assignments.some((item) => !item.get("call_order"));
        if (hasNoneValue) {
          // do not show the select box on the form
          callOrderVisible = false;
          // call order value removed
          defaultValue = undefined;
        } else {
          // show the select box on the form
          callOrderVisible = true;

          // check if all the selected assignments have the same value for call order
          const allHaveTheSameValue = assignments.every(
            (item) => item.get("call_order") === assignments[0].get("call_order")
          );
          if (allHaveTheSameValue) {
            // set call order to the same value as in all assignments
            defaultValue = assignments[0].get("call_order");
          } else {
            // set the call order value to default if call order values of the selected assignments are different
            defaultValue = "default";
          }
        }
      }
    }
    return {
      defaultValue,
      callOrderVisible,
    };
  },

  _getCallOrderMultipleShiftsData: function (assignments) {
    const defaultValue = "default";
    let callOrderVisible = true;
    // all options
    let options = (
      <>
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
        <option value={6}>6</option>
        <option value={7}>7</option>
        <option value={8}>8</option>
        <option value={9}>9</option>
        <option value={10}>10</option>
        <option value={"none"}>None</option>
        <option value={"default"}>Keep Current</option>
      </>
    );

    if (assignments.length > 0) {
      // check if any of the selected assignments has call order set to none
      const hasNoneValue = assignments.some((item) => !item.get("call_order"));
      const allHaveNoneValue = assignments.every((item) => !item.get("call_order"));
      if (allHaveNoneValue) {
        callOrderVisible = false;
      }
      if (hasNoneValue) {
        // only 'None' and 'Keep Current' options will be available
        options = (
          <>
            <option value={"none"}>None</option>
            <option value={"default"}>Keep Current</option>
          </>
        );
      }
    }
    return {
      callOrderVisible,
      defaultValue,
      options,
    };
  },

  _canCheckCompatibility: function () {
    // disable this right now -- force false
    return false;

    // only create compat maps if we're:
    // 1. swap-replace, swap-fill
    if ("swap-replace|swap-fill".indexOf(this.state.action) == -1) {
      return false;
    }
    // 2. single date/slot
    // 3. single assignment
    var targetDates =
      _.keys(this.state.selectedDates).length > 0
        ? _.keys(this.state.selectedDates)
        : _.map(this.state.selectedSlots, function (s) {
            return s.get("slot_date");
          });
    var targetAssignment = this._getTargetAssignment();
    if (!targetAssignment || _.isArray(targetAssignment) || targetDates.length != 1) {
      return false;
    }

    return true;
  },
  _canChangeNotes: function () {
    // can change if:
    // 1. permissions for every slot
    var targetTemplate = this._getTargetTemplate();

    var notesPermissions = true;
    switch (this.state.action) {
      case "swap-fill":
        var permutations = this._permutateTargets();
        for (var i = 0; i < permutations.length; i++) {
          var targetAssignment = permutations[i].targetAssignment;
          var targetPersonnel = permutations[i].targetPersonnel;
          // if we find one that we can't change shut it down
          if (
            !window.LbsAppData.Helpers.Permissions.CanIChangeFillNotes(
              targetAssignment,
              targetPersonnel,
              targetTemplate
            )
          ) {
            return false;
          }
        }
        break;
      case "new-request":
        var permutations = this._permutateTargets();
        for (var i = 0; i < permutations.length; i++) {
          var targetAssignment = permutations[i].targetAssignment;
          var targetPersonnel = permutations[i].targetPersonnel;
          // if we find one that we can't change shut it down
          if (
            !window.LbsAppData.Helpers.Permissions.CanIChangeRequestNotes(
              targetAssignment,
              targetPersonnel,
              targetTemplate
            )
          ) {
            return false;
          }
        }
        break;
      case "swap-details":
        for (var i = 0; i < this.state.selectedSlots.length; i++) {
          var slot = this.state.selectedSlots[i];
          if (!window.LbsAppData.Helpers.Permissions.CanIChangeSlotNotes(slot)) {
            return false;
          }
        }
        break;
      case "swap-replace":
        for (var i = 0; i < this.state.selectedSlots.length; i++) {
          var slot = this.state.selectedSlots[i];
          var targetPersonnel = this._getTargetPersonnel({
            targetSlot: slot,
          });
          var targetAssignment = this._getTargetAssignment({
            targetSlot: slot,
          });
          if (this.state.selectedPersonnel.length == 0) {
            // replace by assignment
            if (
              !targetTemplate ||
              !targetAssignment ||
              !window.LbsAppData.Helpers.Permissions.CanIChangeSwapAssignmentNotes(
                slot,
                targetAssignment,
                targetTemplate
              )
            ) {
              return false;
            }
          } else if (this.state.selectedPersonnel.length == 1) {
            // replace by personnel
            if (
              !targetPersonnel ||
              !window.LbsAppData.Helpers.Permissions.CanIChangeSwapPersonnelNotes(slot, targetPersonnel)
            ) {
              return false;
            }
          } else {
            // swaportunity (no note)
            return false;
          }
        }
        break;
      default:
        break;
    }
    // no intersection to check
    return true;
  },
  _canChangeCustomText: function (slot, user) {
    //only admins are allowed to view and update custom text
    return window.LbsAppData.User.get("is_admin") && window.LbsAppData.Helpers.Permissions.CanICreateSwap(slot, user);
  },
  _canChangeTime: function () {
    // can change if:
    // 1. permissions for every slot (assuming permission check happening to initiate fill, request, replace already)
    switch (this.state.action) {
      case "swap-details":
        for (var i = 0; i < this.state.selectedSlots.length; i++) {
          var slot = this.state.selectedSlots[i];
          if (!window.LbsAppData.Helpers.Permissions.CanIChangeSlotLocationOrTime(slot)) {
            return false;
          }
        }
        break;
      case "swap-fill":
      case "new-request":
        break;
      default:
        // for now revert to is admin check in cases we may have missed
        if (!window.LbsAppData.User.get("is_admin")) {
          return false;
        }
        break;
    }
    // no intersection to check
    return true;
  },
  _canChangeCallOrder: function () {
    // can change if:
    // 1. Flag is enabled and the user is admin
    if (this.props?.flags[LDFlagEnums.Lv6192ShowAssignmentCallOrder] && window.LbsAppData.User.get("is_admin")) {
      return true;
    }
    return false;
  },
  _canChangeLoaReasons: function () {
    // can change if:
    // 1. permissions for every person
    // 2. every assignment shares at least 1 reason
    let ret = true;
    let personnel = this.state.selectedPersonnel;
    const selectedSlots = this.state.selectedSlots;

    //if no selected personnel
    //but we have selected slots
    if (!personnel.length && selectedSlots.length) {
      personnel = selectedSlots.map((slot) => slot.personnelObj);
    }

    for (let i = 0; i < personnel.length; i++) {
      const targetPersonnel = personnel[i];

      if (this._isSwap()) {
        // swap
        ret = window.LbsAppData.Helpers.Permissions.CanIChangeThisPersonsLOAReasons(targetPersonnel);
      } else {
        // request
        ret = window.LbsAppData.Helpers.Permissions.CanIChangeThisPersonsRequestLOAReasons(targetPersonnel);
      }

      // return if this check didn't work
      if (!ret) {
        return false;
      }
    }
    // permissions were ok so check intersection
    return this._intersectLoaReasons().length;
  },
  _intersectLoaReasons: function () {
    const assignments = [...this.state.selectedAssignments];
    let loaReasonCandidates = [];

    const doLoaReasonCandidatesExistForAssignment = ({ assignment, assignmentId, idx }) => {
      const assignmentReasonsMap = { ...assignment?.get("assignment_to_loa_reason_map") };
      const assignmentReasons = assignmentReasonsMap[assignmentId];

      if (!Object.keys(assignmentReasonsMap).length || !assignmentReasons) {
        return false;
      }

      if (idx === 0) {
        loaReasonCandidates = [...assignmentReasons];

        return true;
      }

      loaReasonCandidates = _.intersection(loaReasonCandidates, assignmentReasons);

      if (!loaReasonCandidates.length) {
        return false;
      }

      return true;
    };

    // This case is used for bulk tool dialogs
    if (this.state.selectedAssignments.length) {
      const targetTemplate = this._getTargetTemplate();

      for (let i = 0; i < assignments.length; i++) {
        const assignmentId = assignments[i].get("template_to_assign_id_map")[targetTemplate.id];
        const result = doLoaReasonCandidatesExistForAssignment({
          assignment: assignments[i],
          assignmentId,
          idx: i,
        });

        if (!result) {
          return [];
        }
      }

      return loaReasonCandidates;
    } else if (this.state.selectedSlots.length) {
      for (let i = 0; i < this.state.selectedSlots.length; i++) {
        const slot = this.state.selectedSlots[i];
        const assignment = this.state.assignments.get(slot.get("condensed_structure_id"));
        const assignmentId = slot.get("assign_id");
        const result = doLoaReasonCandidatesExistForAssignment({ assignment, assignmentId, idx: i });

        if (!result) {
          return [];
        }
      }

      return loaReasonCandidates;
    }

    return [];
  },
  _canChangeLocations: function () {
    // can change if:
    // 1. every slot has an _editable_ location
    // 2. every assignment has an _editable_ location
    // 3. every assignment shares at least 1 location
    // 4. have permission to edit schedule (for modify details)...forgot this one scotty boy
    if (this.state.selectedAssignments.length) {
      var targetTemplate = this.state.selectedTemplate;
      var assignments = this.state.selectedAssignments;
      for (var i = 0; i < assignments.length; i++) {
        var assignId = assignments[i].get("template_to_assign_id_map")[targetTemplate.id];
        var locationDict = assignments[i].get("assignment_to_locations_map")[assignId];
        if (!locationDict.is_location_editable) {
          return false;
        }
      }
    } else if (this.state.selectedSlots.length) {
      var slots = this.state.selectedSlots;
      for (var i = 0; i < slots.length; i++) {
        var slot = slots[i];
        // look up editable/multiple attributes in the assignment object
        var assignment = this.state.assignments.get(slot.get("condensed_structure_id"));
        const assignmentId = slot.get("assign_id");
        const assignmentExists = assignment && assignment.get("assignment_to_locations_map")[assignmentId];
        const isLocationEditable = assignmentExists?.is_location_editable;
        // check if location is _editable_ [kgjergji@ritech.co]
        if (!assignmentId || !assignmentExists || !isLocationEditable) {
          return false;
        }

        if (!window.LbsAppData.Helpers.Permissions.CanIChangeSlotLocationOrTime(slot)) {
          return false;
        }
      }
    }
    // all editable so check intersection
    return this._intersectLocations().length;
  },
  _intersectLocations: function () {
    var that = this;
    var targetTemplate = this._getTargetTemplate();
    // locations intersection
    if (this.state.selectedAssignments.length) {
      var assignments = this.state.selectedAssignments;
      var assignId = assignments[0].get("template_to_assign_id_map")[targetTemplate.id];
      var locationCandidates = _.map(
        assignments[0].get("assignment_to_locations_map")[assignId].locations,
        function (l) {
          return l.location_id;
        }
      );
      for (var i = 1; i < assignments.length; i++) {
        assignId = assignments[i].get("template_to_assign_id_map")[targetTemplate.id];
        locationCandidates = _.intersection(
          locationCandidates,
          _.map(assignments[i].get("assignment_to_locations_map")[assignId].locations, function (l) {
            return l.location_id;
          })
        );
        if (!locationCandidates.length) {
          // length is 0, cannot change -- just return false
          break;
        }
      }
      return locationCandidates;
    } else if (this.state.selectedSlots.length) {
      // need to match the slot with the assignment and get the locations array
      var assignId = this.state.selectedSlots[0].get("assign_id");
      var assignment = _.find(this.state.assignments.models, function (a) {
        return a.id == that.state.selectedSlots[0].get("condensed_structure_id");
      });
      //var assignment = this._getTargetAssignment(); //_.find(this.state.assignments.models, function(a) { return a.id == that.state.selectedSlots[0].get('condensed_structure_id'); });
      var locationCandidates = _.map(assignment.get("assignment_to_locations_map")[assignId].locations, function (l) {
        return l.location_id;
      });
      for (var i = 1; i < this.state.selectedSlots.length; i++) {
        assignId = this.state.selectedSlots[i].get("assign_id");
        assignment = _.find(this.state.assignments.models, function (a) {
          return a.id == that.state.selectedSlots[i].get("condensed_structure_id");
        });
        locationCandidates = _.intersection(
          locationCandidates,
          _.map(assignment.get("assignment_to_locations_map")[assignId].locations, function (l) {
            return l.location_id;
          })
        );
        if (!locationCandidates.length) {
          // length is 0, cannot change -- just return false
          break;
        }
      }
      return locationCandidates;
    } else {
      return [];
    }
  },
  _canChangeDenialReasons: function () {
    // can change if:
    // 1. every assignment type shares at least 1 reason
    var ret = true;
    if (this.state.pendingAction != "deny") {
      return false;
    }
    // denial -- check intersection
    return this._intersectDenialReasons().length;
  },
  _intersectDenialReasons: function () {
    // denial reason intersection
    var denialReasons = this.state.denialReasons;
    if (denialReasons.length) {
      var denialReasonCandidates = [];
      var assignmentTypes = _.uniq(
        _.map(this.state.selectedSlots, function (s) {
          return s.get("assign_atype");
        })
      );
      for (var i = 0; i < denialReasons.length; i++) {
        var denialReason = denialReasons.at(i);
        // if this denial reason has all the atypes connected to it push it to candidates
        if (_.intersection(assignmentTypes, denialReason.get("assignment_types")).length == assignmentTypes.length) {
          denialReasonCandidates.push(denialReason);
        }
      }
      return denialReasonCandidates;
    } else {
      return [];
    }
  },
  _filterTemplatesList: function (templates, action) {
    var filteredTemplates = new TemplateCollection(
      _.filter(templates.models, function (t) {
        if (action == "new-request") {
          return window.LbsAppData.Helpers.Permissions.CanIChangeTemplateRequestData(t);
        } else if (action !== "new-request") {
          return window.LbsAppData.Helpers.Permissions.CanIChangeTemplateScheduleData(t);
        }
      }),
      {}
    );

    return filteredTemplates;
  },
  _showTemplatesList: function () {
    var that = this;

    window.LbsAppData.AppContext.openDialog({
      type: "list",
      collection: this._filterTemplatesList(this.state.templates, this.state.action),
      multiselect: false,
      result: this.state.selectedTemplate,
      title: "Select Template",
      property: "name",
      noReset: true,
      action: function (item) {
        // slightly different depending on the action type
        switch (that.state.action) {
          case "new-request":
          case "swap-fill":
            // if we're moving into a template that this person(s) is not a member of then
            // reset the fields
            var resetPersonnel = false;
            if (that.state.selectedPersonnel.length) {
              for (var i = 0; i < that.state.selectedPersonnel.length; i++) {
                var personnel = that.state.selectedPersonnel[i];
                if (personnel.get("departments").indexOf(item.get("department_id")) == -1) {
                  // doesn't belong to this department -- reset personnel selection
                  resetPersonnel = true;
                  break;
                }
              }
            }
            // set the selected template
            that.setState(
              {
                selectedTemplate: item,
                selectedAssignments: [],
                selectedPersonnel: !resetPersonnel ? that.state.selectedPersonnel : [],
              },
              function () {
                this._updateFormScenario();
              }
            );
            // reset the assignments collections
            that.state.assignments.attributes.tempList = [item.id];
            that.state.assignments.reset(); // fetch will happen in the ListDialog component
            // for fill/requests reset the personnel as well
            that.state.personnel.attributes.deptList = [item.get("department_id")];
            that.state.personnel.reset();
            break;

          case "swap-replace":
            // reset the assignments collections
            that.state.assignments.attributes.tempList = [item.id];
            that.state.assignments.reset();
            break;
          default:
            break;
        }

        // close the selection dialog
        window.LbsAppData.AppContext.closeDialog();

        // send a tracking event
        if (that._isSwap()) {
          window.LbsAppData.Helpers.Analytics.sendEvent("Swap: Update", "Template");
        } else {
          window.LbsAppData.Helpers.Analytics.sendEvent("Request: Update", "Template");
        }
      },
    });
  },
  _showAssignmentsList: function (e) {
    const that = this;
    const multiselect = "swap-fill|new-request".indexOf(this.state.action) == -1 ? false : true;

    const dialogObject = {
      type: "list",
      unfetched: true,
      shouldShowBlocks: this.state.action === "new-request",
      collection: this.state.assignments,
      multiselect: multiselect,
      selectedDates: this.state.selectedDates,
      result: this.state.selectedAssignments,
      noReset: false,
      title: "Select Assignment",
      property: "display_name",
      action: function (item) {
        if (!multiselect) {
          // set the selected assignment/template
          that.setState(
            {
              selectedTemplate: that.state.templates.get(that.state.assignments.attributes.tempList[0]),
              selectedAssignments: [item],
              useCustomTime: false,
            },
            function () {
              // update form scenario
              that._updateFormScenario();
              // check for structure
              that._checkForStructure();
            }
          );

          // close the selection dialog
          window.LbsAppData.AppContext.closeDialog();
        } else {
          // update form scenario
          that._updateFormScenario();
          // check for structure
          that._checkForStructure();
        }

        // send a tracking event
        if (that._isSwap()) {
          window.LbsAppData.Helpers.Analytics.sendEvent("Swap: Update", "Assignment");
        } else {
          window.LbsAppData.Helpers.Analytics.sendEvent("Request: Update", "Assignment");
        }
      },
      onReset: function () {
        window.LbsAppData.AppContext.closeDialog();
        that.setState(
          {
            selectedAssignments: [],
            assignmentStructureTimesDict: {},
            assignmentStructureErrorDayDict: {},
          },
          function () {
            that._updateFormScenario();
          }
        );
      },
      onUpdate: function (item) {
        that._updateFormScenario();
        that._checkForStructure();
      },
    };
    if (this.state.action == "swap-replace") {
      // add a template selector
      dialogObject.subCollection = this.state.templates;
      dialogObject.subResult = function () {
        return that.state.templates?.get(that.state.assignments.attributes.tempList[0])?.get("name");
      };
      dialogObject.onSubCollectionFocus = function () {
        that._showTemplatesList();
      };
    }

    window.LbsAppData.AppContext.openDialog(dialogObject);
  },
  _showPersonnelList: function (listNumber) {
    let resultList;
    // These list "Number" s are actually strings.
    if (listNumber === "2") {
      resultList = this.state.selectedPersonnel2;
      this.setState({
        currentSelectedPersonnelList: 2,
      });
    } else if (listNumber === "3") {
      resultList = this.state.selectedPersonnel3;
      this.setState({
        currentSelectedPersonnelList: 3,
      });
    } else {
      resultList = this.state.selectedPersonnel;
      this.setState({
        currentSelectedPersonnelList: 1,
      });
    }
    const that = this;
    // finalizes already have the personnel list they need.
    var unfetched = "preswap-finalize".indexOf(this.state.action) == -1 ? true : false;
    var multiselect = "preswap-finalize|swap-remove".indexOf(this.state.action) == -1 ? true : false;
    var noReset = "preswap-finalize".indexOf(this.state.action) == -1 ? false : true;
    var includeCompat = !this._canCheckCompatibility() ? false : true;

    window.LbsAppData.AppContext.openDialog({
      type: "list",
      unfetched: unfetched,
      collection: this.state.personnel,
      multiselect: multiselect,
      result: resultList,
      noReset: noReset,
      title: "Select Personnel",
      property: "display_name",
      groupings: includeCompat
        ? [
            {
              title: "Unscheduled",
              extClasses: ["unscheduled"],
              filterFn: function (o) {
                return (
                  !o.get("compatibility").assignment_conflicts.length &&
                  !o.get("compatibility").assignment_nonconflicts.length
                );
              },
            },
            {
              title: "Compatible",
              extClasses: ["compatible"],
              filterFn: function (o) {
                return o.get("compatibility").assignment_nonconflicts.length && o.get("compatibility").eligible;
              },
            },
            {
              title: "Incompatible",
              extClasses: ["incompatible"],
              filterFn: function (o) {
                return !o.get("compatibility").eligible;
              },
            },
          ]
        : null,
      action: function (item) {
        if (!multiselect) {
          // update selectedPersonnel
          that.setState(
            {
              selectedPersonnel: that.state.currentSelectedPersonnelList === 1 ? [item] : that.state.selectedPersonnel,
              selectedPersonnel2:
                that.state.currentSelectedPersonnelList === 2 ? [item] : that.state.selectedPersonnel2,
              selectedPersonnel3:
                that.state.currentSelectedPersonnelList === 3 ? [item] : that.state.selectedPersonnel3,
            },
            function () {
              that._updateFormScenario();
              that._checkForStructure();
            }
          );
          // close the selection dialog
          window.LbsAppData.AppContext.closeDialog();
        } else {
          // update form scenario
          that._updateFormScenario();
          // check for structure
          that._checkForStructure();
        }

        // send a tracking event
        if (that._isSwap()) {
          window.LbsAppData.Helpers.Analytics.sendEvent("Swap: Update", "Personnel");
        } else {
          window.LbsAppData.Helpers.Analytics.sendEvent("Request: Update", "Personnel");
        }
      },
      onReset: function () {
        window.LbsAppData.AppContext.closeDialog();
        that.setState(
          {
            selectedPersonnel: that.state.currentSelectedPersonnelList === 1 ? [] : that.state.selectedPersonnel,
            selectedPersonnel2: that.state.currentSelectedPersonnelList === 2 ? [] : that.state.selectedPersonnel2,
            selectedPersonnel3: that.state.currentSelectedPersonnelList === 3 ? [] : that.state.selectedPersonnel3,
          },
          function () {
            that._updateFormScenario();
          }
        );
      },
      onUpdate: function (item) {
        that._updateFormScenario();
      },
    });
  },
  _showAvailablePersonnel: function () {
    const that = this;

    window.LbsAppData.AppContext.openDialog({
      type: "availability-check",
      selectedRows: that.state.selectedRows ? that.state.selectedRows : [],
      assign_id: that.state.selectedSlots.length && that.state.selectedSlots[0].get("assign_id"),
      slot_id: that.state.selectedSlots.length && that.state.selectedSlots[0].get("slot_id"),
      onSelect: function (items, skipFiltering = false) {
        let filteredArray = items;
        const windowModels = window.LbsAppData.Personnel.models;

        if (!skipFiltering) {
          filteredArray = filteredArray
            .map((el) => {
              const item = windowModels.find((element) => element.id === el.Id);
              return item;
            })
            .filter((item) => item !== undefined);
        } else {
          filteredArray.forEach((item) => {
            item.display_name = item.DisplayName;
            item.get = (key) => item[key];
          });
        }

        that.setState(
          {
            selectedPersonnel: filteredArray,
            selectedRows: items,
          },
          () => {
            that._updateFormScenario();
            that._checkForStructure();
          }
        );
      },
      onClose: function () {
        window.LbsAppData.AppContext.closeDialog();
      },
      window: window,
    });
  },
  _handlePersonnel: function (listNumber) {
    if (this.state.action === "swap-replace") {
      if (this.props?.isHidden === false && this.props?.flags[LDFlagEnums.AvailabilityWindowShowDropdown]) {
        this._showAvailablePersonnel();

        return;
      }
    }

    this._showPersonnelList(listNumber);
  },
  _showReasonsList: function (e) {
    const that = this;
    const intersectedReasons = this._intersectLoaReasons();

    const reasonsCollection = new LoaReasonCollection(
      _.map(intersectedReasons, function (r_id) {
        return that.state.reasons.get(r_id).attributes;
      })
    );

    window.LbsAppData.AppContext.openDialog({
      type: "list",
      collection: reasonsCollection,
      result: this.state.selectedReason,
      title: "Select Reason",
      property: "name",
      action: function (item) {
        // set the selected template
        that.setState({
          selectedReason: item,
        });

        // close the selection dialog
        window.LbsAppData.AppContext.closeDialog();

        // send a tracking event
        if (that._isSwap()) {
          window.LbsAppData.Helpers.Analytics.sendEvent("Swap: Update", "Reason");
        } else {
          window.LbsAppData.Helpers.Analytics.sendEvent("Request: Update", "Reason");
        }
      },
    });
  },
  _showDenialReasonsList: function (e) {
    var that = this;

    var intersectedReasons = this._intersectDenialReasons();
    var reasonsCollection = new DenialReasonCollection(SafeJSON.parse(JSON.stringify(intersectedReasons)));

    window.LbsAppData.AppContext.openDialog({
      type: "list",
      collection: reasonsCollection,
      //unfetched: true,
      result: this.state.selectedDenialReason,
      title: "Select Reason",
      property: "name",
      action: function (item) {
        // set the selected template
        that.setState({
          selectedDenialReason: item,
        });

        // close the selection dialog
        window.LbsAppData.AppContext.closeDialog();

        // send a tracking event
        window.LbsAppData.Helpers.Analytics.sendEvent("Swap: Update", "Denial Reason");
      },
    });
  },
  _showLocationsList: function (e) {
    var that = this;

    var intersectedLocations = this._intersectLocations();
    var locationsCollection = new LocationCollection(
      _.map(intersectedLocations, function (l_id) {
        return that.state.locations.get(l_id).attributes;
      })
    );

    // all this for multiselect locations -- so lame.
    var isMultiselect = true;
    if (this.state.selectedAssignments.length) {
      var targetTemplate = this.state.selectedTemplate;
      var assignments = this.state.selectedAssignments;
      for (var i = 0; i < assignments.length; i++) {
        var assignId = assignments[i].get("template_to_assign_id_map")[targetTemplate.id];
        var locationDict = assignments[i].get("assignment_to_locations_map")[assignId];
        if (!locationDict.is_location_multiple) {
          isMultiselect = false;
          break;
        }
      }
    } else if (this.state.selectedSlots.length) {
      var slots = this.state.selectedSlots;
      for (var i = 0; i < slots.length; i++) {
        var slot = slots[i];
        // look up editable/multiple attributes in the assignment object
        var assignment = this.state.assignments.get(slot.get("condensed_structure_id"));
        if (!assignment.get("assignment_to_locations_map")[slot.get("assign_id")].is_location_multiple) {
          isMultiselect = false;
          break;
        }
      }
    }

    var resultArr = _.map(this.state.selectedLocations, function (sl) {
      return sl.id;
    });
    window.LbsAppData.AppContext.openDialog({
      type: "list",
      collection: locationsCollection,
      multiselect: isMultiselect,
      result: resultArr,
      title: "Select Location",
      property: "name",
      action: function (item) {
        // set the selected personnel
        if (!isMultiselect) {
          // this logic branch forces a _single_ location
          that.setState({
            selectedLocations: [item],
          });

          // close the selection dialog
          window.LbsAppData.AppContext.closeDialog();
        } else {
          // selectedLocations modified inside the list dialog
          // -- apparently we just store ids (who coded this? ... :|)

          const seenIds = new Set();
          const selectedLocations = [];

          resultArr.forEach((item) => {
            const location = locationsCollection.get(item);
            const locationId = location.attributes.location_id;

            if (!seenIds.has(locationId)) {
              selectedLocations.push(location);
              seenIds.add(locationId);
            }
          });

          that.setState({
            selectedLocations,
          });
        }

        // send a tracking event
        window.LbsAppData.Helpers.Analytics.sendEvent("Swap: Update", "Location");
      },
    });
  },
  _showDatePicker: function () {
    var that = this;
    var template = this.state.selectedTemplate;

    var pickerProperties;
    switch (this.state.action) {
      case "swap-fill":
        pickerProperties = {
          mode: "daily",
          date:
            _.keys(this.state.selectedDates).length > 0
              ? moment(_.pairs(this.state.selectedDates)[0][0], "YYYYMMDD")
              : moment(),
          lowerBound: template.attributes.first_published_date,
          upperBound: template.attributes.last_published_date,
          selectedDates: this.state.selectedDates,
          options: {
            multiselect: true,
            noSubmit: true,
          },
          checkForStructure: this._checkForStructure,
        };
        break;
      case "new-request":
        const isAdminOrSuperAdmin =
          window.LbsAppData.User.get("is_admin") || window.LbsAppData.User.get("is_super_admin");

        const requestWindowStart = isAdminOrSuperAdmin
          ? getReqWindowStartOrLatestScheduledMoment(
              template.attributes.request_window_start,
              template.attributes.last_published_date
            )
          : moment(template.attributes.request_window_start);
        const requestWindowEnd = moment(template.attributes.request_window_end);

        const { hasBlockingAssignment, blockDayRange, blockStartWeekDay, blockLength } = this._getBlockData();

        pickerProperties = {
          mode: "daily",
          date:
            _.keys(this.state.selectedDates).length > 0
              ? moment(_.pairs(this.state.selectedDates)[0][0], "YYYYMMDD")
              : moment(requestWindowStart),
          lowerBound: requestWindowStart,
          upperBound: requestWindowEnd,
          selectedDates: this.state.selectedDates,
          options: {
            multiselect: !hasBlockingAssignment,
            blockselect: hasBlockingAssignment,
            noSubmit: true,
          },
          blockOptions: {
            dayRange: blockDayRange,
            startWeekDay: blockStartWeekDay,
            length: blockLength,
          },
          checkForStructure: this._checkForStructure,
        };
        break;
      default:
        // generic
        pickerProperties = {
          mode: "daily",
          date: moment(),
          selectedDates: this.state.selectedDates,
          options: {
            multiselect: true,
          },
        };
        break;
    }

    window.LbsAppData.AppContext.openDialog({
      type: "schedule-edits-date-select",
      datepickerProperties: pickerProperties,
      submit: function (dates) {
        // make sure the selectedDates values are in sync
        that.setState(
          {
            selectedDates: dates,
          },
          that._checkForStructure
        );
      },
    });
  },
  _expandNoteDialog: function () {
    var that = this;
    window.LbsAppData.AppContext.openDialog({
      type: "note-full",
      note: ReactDOM.findDOMNode(this.refs.DialogChild.getRef("Note")).value,
      onClose: function (val) {
        ReactDOM.findDOMNode(that.refs.DialogChild.getRef("Note")).value = val;
      },
      charLimit: !this._isSwap() ? 2000 : 5000,
    });
  },
  _selectPreswap: function (preswap) {
    var that = this;
    this.setState({ selectedPreswap: preswap }, function () {
      that.refs.DialogChild.stepForward();
    });
  },
  _fetchSlots: function () {
    var that = this;
    // fetch a day at a time for now, but eventually let's try and figure out ranges to speed it up
    var slotCollections = [];
    var promises = [];

    var slots = new SlotCollection([], {
      adhoc: true,
      emp_id: this.state.selectedPersonnel[0].id,
    });
    _.each(this.state.selectedDates, function (value, key) {
      // reminder: key is the date.. value is meaningless (just a boolean)
      // must fetch before and after in order to make sure slots...
      // ...changing days due to time zones are accounted for
      var newCollection = new SlotCollection([], {
        adhoc: true,
        start_date: moment(key, "YYYYMMDD").subtract(1, "days"),
        end_date: moment(key, "YYYYMMDD").add(1, "days"),
        emp_id: that.state.selectedPersonnel[0].id,
      });

      slotCollections.push(newCollection);
      promises.push(newCollection._fetchSlots());
    });

    // once everyone has come back, merge them all and trigger a refresh
    this.setState({ isFetchingSlots: true }, function () {
      Promise.all(promises).then(function () {
        _.each(slotCollections, function (item) {
          slots.add(item.models, { silent: true });
        }); // don't bother with events
        that.setState({ slots: slots, isFetchingSlots: false });
      });
    });
  },

  /* publics */
  close: function (e) {
    this.props.close();
  },
  stepForward: function (step) {
    switch (this.state.action) {
      case "swap-remove":
        switch (step) {
          case 3:
            this._fetchSlots();
          default:
            break;
        }
      default:
        break;
    }
    return;
  },
  stepBackward: function () {
    switch (this.state.action) {
      default:
        break;
    }
    return;
  },
  submit: function () {
    // create the requests, and save them
    if (this._isSwap()) {
      this._submitSwap();
    } else {
      this._submitRequest();
    }
  },
  resendSwap: function () {
    this._submitSwap(true);
  },
  resendDetailsErrorSwap: function () {
    this._submitSwap(false, true);
  },
  getStateAttribute: function (name) {
    return this.state[name];
  },

  getAttributeWidget: function (id, options) {
    var that = this;

    let displayText;
    let selectedDate, request_window_start, request_window_end, first_published_date, last_published_date;
    const { lastSelectedDate } = this.state;
    const template = this.state.selectedTemplate;
    if (template && template.attributes) {
      request_window_start = template.attributes.request_window_start;
      request_window_end = template.attributes.request_window_end;
      first_published_date = template.attributes.first_published_date;
      last_published_date = template.attributes.last_published_date;
    }
    switch (id) {
      case "selectTemplate":
        return (
          <div
            className="selectable two-line"
            onClick={this.touchProxy.bind(this, this._showTemplatesList, [])}
            onTouchEnd={this.touchProxy.bind(this, this._showTemplatesList, [])}
          >
            <div className="selectable-label">Template</div>
            <div className="selectable-value" data-cy="CFSelectTemplate">
              {this.state.selectedTemplate.attributes.name}
            </div>
          </div>
        );
        break;

      case "selectAssignment":
        var changeable = !this.state.lockedAssignment;
        displayText = this._getAssignmentDisplayString();
        if (changeable) {
          return (
            <div
              className="selectable two-line"
              onClick={this.touchProxy.bind(this, this._showAssignmentsList, [])}
              onTouchEnd={this.touchProxy.bind(this, this._showAssignmentsList, [])}
            >
              <div className="selectable-label">Assignment</div>
              <div className="selectable-value" data-cy="CFSelectAssignment">
                {displayText}
              </div>
            </div>
          );
        } else {
          return (
            <div className="selectable two-line disabled">
              <div className="selectable-label">Assignment</div>
              <div className="selectable-value" data-cy="CFSelectAssignment">
                {displayText}
              </div>
            </div>
          );
        }
        break;

      case "selectPersonnel3":
      case "selectPersonnel2":
      case "selectPersonnel":
        var changeable;

        if (this._isSwap()) {
          changeable =
            !this.state.lockedPersonnel &&
            (this._isCurrentUserSlots() || window.LbsAppData.Helpers.Permissions.CanIChangeAnyoneElsesSchedule()) &&
            (this._isCurrentUserSlots() ||
              !this.state.selectedTemplate ||
              window.LbsAppData.Helpers.Permissions.CanIChangeTemplateScheduleData(this.state.selectedTemplate));
        } else {
          changeable =
            !this.state.lockedPersonnel &&
            window.LbsAppData.Helpers.Permissions.CanIChangeTemplateRequestData(this.state.selectedTemplate);
        }

        const listNumber = id.slice(-1);
        displayText = this._getPersonnelDisplayString({
          selectedPersonnelListNumber: listNumber,
        });

        if (changeable) {
          return (
            <div
              className="selectable two-line"
              onClick={this.touchProxy.bind(this, this._handlePersonnel, [listNumber])}
              onTouchEnd={this.touchProxy.bind(this, this._handlePersonnel, [listNumber])}
            >
              <div className="selectable-label">Personnel</div>
              <div className="selectable-value" data-cy="CFSelectPersonnel">
                {displayText}
              </div>
            </div>
          );
        } else {
          return (
            <div className="selectable two-line disabled">
              <div className="selectable-label">Personnel</div>
              <div className="selectable-value" data-cy="CFSelectPersonnel">
                {displayText}
              </div>
            </div>
          );
        }
        break;
      case "selectDate":
        var dateDisplay = this._getDateDisplayString({ noTime: true });

        if ("swap-fill|new-request".indexOf(this.state.action) > -1) {
          return (
            <div
              className="selectable two-line"
              onClick={this.touchProxy.bind(this, this._showDatePicker, [])}
              onTouchEnd={this.touchProxy.bind(this, this._showDatePicker, [])}
            >
              <div className="selectable-label">Date</div>
              <div className="selectable-value" data-cy="CFSelectDate">
                {dateDisplay ? dateDisplay : "Select"}
              </div>
            </div>
          );
        } else {
          return (
            <div className="selectable disabled two-line">
              <div className="selectable-label">Date</div>
              <div className="selectable-value" data-cy="CFSelectDate">
                {dateDisplay ? dateDisplay : "Select"}
              </div>
            </div>
          );
        }
        break;

      case "datePickerGeneric":
        return (
          <DatePicker
            ref="DatePicker"
            mode="daily"
            date={moment()}
            selectedDates={this.state.selectedDates}
            options={{ multiselect: true }}
            checkForStructure={this._checkForStructure}
            setSelectedDateData={(date, selectedDates) =>
              this.setState(
                {
                  lastSelectedDate: date,
                  selectedDates: selectedDates,
                },
                this._checkForStructure
              )
            }
          />
        );
        break;
      case "datePickerSwap":
        const firstPublishedDate = moment(first_published_date);
        const lastPublishedDate = moment(last_published_date);
        if (lastSelectedDate) {
          const lastSelectedDateAsMoment = moment(lastSelectedDate, "YYYYMMDD");
          if (
            lastSelectedDateAsMoment.isBefore(firstPublishedDate) ||
            lastSelectedDateAsMoment.isAfter(lastPublishedDate)
          ) {
            selectedDate = moment(first_published_date);
            const selectedDateKey = selectedDate.format("YYYYMMDD");
            // Delete selected date.
            const selectedDates = _.omit(this.state.selectedDates, lastSelectedDateAsMoment.format("YYYYMMDD"));
            selectedDates[selectedDateKey] = true;
            this.setState({
              lastSelectedDate: selectedDateKey,
              selectedDates: selectedDates,
            });
          } else {
            selectedDate = lastSelectedDateAsMoment;
          }
        }
        return (
          <DatePicker
            ref="DatePicker"
            mode="daily"
            date={selectedDate}
            lowerBound={first_published_date}
            upperBound={last_published_date}
            selectedDates={this.state.selectedDates}
            options={{ multiselect: true, noSubmit: true }}
            checkForStructure={this._checkForStructure}
            setSelectedDateData={(date, selectedDates) => {
              this.setState(
                {
                  lastSelectedDate: date,
                  selectedDates: selectedDates,
                },
                this._checkForStructure
              );
            }}
          />
        );

      case "datePickerRequest":
        const { hasBlockingAssignment, blockDayRange, blockStartWeekDay, blockLength } = this._getBlockData();

        const isAdminOrSuperAdmin =
          window.LbsAppData.User.get("is_admin") || window.LbsAppData.User.get("is_super_admin");

        const requestWindowStart = isAdminOrSuperAdmin
          ? getReqWindowStartOrLatestScheduledMoment(request_window_start, last_published_date)
          : moment(request_window_start);
        const requestWindowEnd = moment(request_window_end);
        let selectedDates = this.state.selectedDates;
        if (lastSelectedDate) {
          const lastSelectedDateAsMoment = moment(lastSelectedDate, "YYYYMMDD");
          if (
            lastSelectedDateAsMoment.isBefore(requestWindowStart) ||
            lastSelectedDateAsMoment.isAfter(requestWindowEnd)
          ) {
            selectedDate = moment(requestWindowStart);
            const selectedDateKey = selectedDate.format("YYYYMMDD");

            if (!hasBlockingAssignment) {
              // Delete selected date.
              selectedDates = _.omit(this.state.selectedDates, lastSelectedDateAsMoment.format("YYYYMMDD"));
              selectedDates[selectedDateKey] = true;
            }
          } else {
            selectedDate = lastSelectedDateAsMoment;
          }
        }

        return (
          <DatePicker
            ref="DatePicker"
            mode="daily"
            date={selectedDate}
            lowerBound={requestWindowStart}
            upperBound={requestWindowEnd}
            selectedDates={selectedDates}
            options={{
              multiselect: !hasBlockingAssignment,
              blockselect: hasBlockingAssignment,
              noSubmit: true,
            }}
            blockOptions={{
              dayRange: blockDayRange,
              startWeekDay: blockStartWeekDay,
              length: blockLength,
            }}
            checkForStructure={this._checkForStructure}
            setSelectedDateData={(date, selectedDates) => {
              this.setState(
                {
                  lastSelectedDate: date,
                  selectedDates: selectedDates,
                },
                this._checkForStructure
              );
            }}
          />
        );
        break;

      case "addDemand":
        if (this._isSwap() && window.LbsAppData.User.get("is_admin")) {
          return (
            <div className="checkable">
              <div className="checkable-value">
                <span className="value" onClick={this.touchProxy.bind(this, this.toggleAddDemand, [])}>
                  <span className="label">Add demand if necessary</span>
                  <input
                    type="checkbox"
                    checked={this.state.addDemand ? "checked" : ""}
                    readOnly={true}
                    tabIndex={-1}
                  />
                  <span className="checkmark" data-cy="addDemandCheckBox" />
                </span>
              </div>
            </div>
          );
        }
        break;

      case "selectLoaReason":
        if (this._canChangeLoaReasons()) {
          if (this.state.selectedReason) {
            displayText = this.state.selectedReason.attributes.name;
          } else if (this.state.action == "swap-replace" && this.state.lockedPersonnel) {
            // replace by assignment -- reset value
            displayText = "Select";
          } else if (this.state.selectedSlots.length == 1) {
            displayText = this.state.selectedSlots[0].get("loa_reason_name")
              ? this.state.selectedSlots[0].get("loa_reason_name")
              : "Select";
          } else if (this.state.selectedSlots.length > 1) {
            var isSame =
              _.uniq(this.state.selectedSlots, function (s) {
                return s.get("loa_reason_name");
              }).length == 1;
            displayText =
              isSame && this.state.selectedSlots[0].get("loa_reason_name")
                ? this.state.selectedSlots[0].get("loa_reason_name")
                : "Select";
          } else {
            displayText = "Select";
          }

          if (this.state.action !== "swap-replace" || this.state.lockedPersonnel || this.state.lockedAssignment) {
            //need to make sure this does not mount if it is a swap replace without something locked
            return (
              <div className="selectable">
                <div className="selectable-label">Reason</div>
                <div
                  className="selectable-value"
                  data-cy="shiftReasonSelctor"
                  onClick={this.touchProxy.bind(this, this._showReasonsList, [])}
                  onTouchEnd={this.touchProxy.bind(this, this._showReasonsList, [])}
                >
                  {displayText}
                </div>
              </div>
            );
          }
        }

        break;

      case "selectDenialReason":
        // denial reason
        if (this._canChangeDenialReasons()) {
          if (this.state.selectedDenialReason) {
            displayText = this.state.selectedDenialReason.attributes.name;
          } else if (this.state.selectedSlots.length == 1) {
            displayText = this.state.selectedSlots[0].get("denial_reason_name")
              ? this.state.selectedSlots[0].get("denial_reason_name")
              : "Select";
          } else if (this.state.selectedSlots.length > 1) {
            var isSame =
              _.uniq(this.state.selectedSlots, function (s) {
                return s.get("denial_reason_name");
              }).length == 1;
            displayText =
              isSame && this.state.selectedSlots[0].get("denial_reason_name")
                ? this.state.selectedSlots[0].get("denial_reason_name")
                : "Select";
          } else {
            displayText = "Select";
          }

          return (
            <div className="selectable">
              <div className="selectable-label">Reason</div>
              <div
                className="selectable-value"
                onClick={this.touchProxy.bind(this, this._showDenialReasonsList, [])}
                onTouchEnd={this.touchProxy.bind(this, this._showDenialReasonsList, [])}
              >
                {displayText}
              </div>
            </div>
          );
        }
        break;

      case "selectLocations":
        // locations
        const canChangeLocations = this._canChangeLocations();
        if (canChangeLocations) {
          var locDisplay;
          if (this.state.selectedLocations && this.state.selectedLocations.length) {
            locDisplay = _.map(this.state.selectedLocations, function (l) {
              return l.attributes.name;
            }).join(", ");
          } else {
            if (this.state.lockedPersonnel && this.state.selectedAssignments.length) {
              // replace by assignment -- reset value to default
              var templateId = this._getTargetTemplate().id;
              var assignment = this.state.selectedAssignments[0];
              var assignmentId = this.state.selectedAssignments[0].get("template_to_assign_id_map")[templateId];
              var defaultLocations = _.filter(
                assignment.get("assignment_to_locations_map")[assignmentId],
                function (l) {
                  return l.is_default;
                }
              );
              locDisplay = defaultLocations.length
                ? _.map(defaultLocations, function (dl) {
                    return dl.location_name;
                  }).join(", ")
                : "Select a location";
            } else if (this.state.lockedAssignment && this.state.selectedPersonnel.length) {
              // modifying personnel -- carry over the current
              if (this.state.selectedSlots.length && this.state.selectedSlots[0].get("location_names").length) {
                locDisplay = this.state.selectedSlots[0].get("location_names").join(", ");
              } else {
                locDisplay = "Select a location";
              }
            } else {
              if (this.state.selectedSlots.length == 1) {
                // modifying details -- check slot

                let locations = this.state.selectedSlots[0].get("location_names");
                const pendingInfo = this.state.selectedSlots[0].get("pending_info");

                const hasPendingLocations =
                  pendingInfo && pendingInfo?.location_names && pendingInfo?.location_names?.length > 0;

                // If slot is pending and has pending locations, get the location names from pending info
                if (hasPendingLocations) {
                  locations = pendingInfo.location_names;
                }

                locDisplay = locations.length
                  ? _.map(locations, function (l) {
                      return l;
                    }).join(", ")
                  : "Select a location";
              } else {
                // multiple assignments -- show 'Select'
                locDisplay = "Select";
              }
            }
          }

          if (this.state.action !== "swap-replace" || this.state.lockedPersonnel || this.state.lockedAssignment) {
            //need to make sure this does not mount if it is a swap replace without something locked
            return (
              <div className="selectable">
                <div className="selectable-label">Location</div>
                <div
                  className="selectable-value"
                  onClick={this.touchProxy.bind(this, this._showLocationsList, [])}
                  onTouchEnd={this.touchProxy.bind(this, this._showLocationsList, [])}
                >
                  {locDisplay}
                </div>
              </div>
            );
          }
        }
        break;

      case "addNote":
        // note
        var component;
        if (this._canChangeNotes()) {
          var areNotesSame =
            !this.state.selectedSlots.length ||
            _.find(this.state.selectedSlots, function (s) {
              return that.state.selectedSlots[0].get("note") != s.get("note");
            })
              ? false
              : true;
          if (this.state.action == "swap-replace") {
            // in the case of swap-replace do not mount until we have a locked type
            if (this.state.lockedPersonnel || this.state.lockedAssignment) {
              var noteValue = null;
              if (!this.state.lockedPersonnel) {
                // this is NOT a swap assignment -- if it was we don't want to carry over a note value
                noteValue = areNotesSame ? this.state.selectedSlots[0].get("note") : null;
              }
              return (
                <div className="textable">
                  <label className="textable-label" htmlFor="new-request-note">
                    Note
                  </label>
                  <textarea
                    ref="Note"
                    id="new-request-note"
                    data-cy="newRequstNoteTextBox"
                    className="textable-value"
                    rows={1}
                    defaultValue={noteValue}
                    style={{ resize: "none" }}
                    maxLength={this._isSwap() ? 5000 : 150}
                  />
                  <i className="fa fa-fw fa-ellipsis-v" data-cy="editNoteEllipsis" onClick={this._expandNoteDialog} />
                </div>
              );
            }
          } else if ("swap-pending|request-action".indexOf(this.state.action) > -1) {
            var noteValue = null; // decision note -- don't prepopulate it with anything
            return (
              <div className="textable">
                <label className="textable-label" htmlFor="new-request-note">
                  Note
                </label>
                <textarea
                  ref="Note"
                  id="new-request-note"
                  data-cy="textField"
                  className="textable-value"
                  rows={1}
                  defaultValue={noteValue}
                  style={{ resize: "none" }}
                  maxLength={this._isSwap() ? 5000 : 150}
                />
                <i className="fa fa-fw fa-ellipsis-v" data-cy="editNoteEllipsis" onClick={this._expandNoteDialog} />
              </div>
            );
          } else {
            var noteValue = areNotesSame ? this.state.selectedSlots[0].get("note") : null;
            return (
              <div className="textable">
                <label className="textable-label" htmlFor="new-request-note">
                  Note
                </label>
                <textarea
                  ref="Note"
                  id="new-request-note"
                  data-cy="textField"
                  className="textable-value"
                  rows={1}
                  defaultValue={noteValue}
                  style={{ resize: "none" }}
                  maxLength={this._isSwap() ? 5000 : 150}
                />
                <i className="fa fa-fw fa-ellipsis-v" data-cy="editNoteEllipsis" onClick={this._expandNoteDialog} />
              </div>
            );
          }
        }
        break;

      case "customDisplay":
        // customDisplay
        var slot = this.state.selectedSlots[0];
        var user = window.LbsAppData.User;
        if (this._canChangeCustomText(slot, user)) {
          // using key as per the current response.
          var customTextValue = this.state.selectedSlots[0].get("display_name_override") || null;
          return (
            <div className="customizable">
              <label className="custom-label" htmlFor="custom-display">
                Custom Display
              </label>
              <textarea
                ref="CustomDisplay"
                id="custom-display"
                data-cy="textField"
                className="custom-value"
                rows={1}
                defaultValue={customTextValue}
                style={{ resize: "none" }}
                maxLength={100}
              />
            </div>
          );
        }
        break;

      case "editTime":
        // times
        if (this._canChangeTime()) {
          return (
            <div className="timeable">
              <div className="timeable-label">Time</div>
              <div className="timeable-value">
                <span
                  className="value"
                  onClick={this.touchProxy.bind(this, this.setCustomTime, [false])}
                  onTouchEnd={this.touchProxy.bind(this, this.setCustomTime, [false])}
                >
                  <span className="label">Default</span>
                  <input
                    type="checkbox"
                    data-cy="defaultCheckBox"
                    checked={!this.state.useCustomTime ? "checked" : ""}
                    readOnly={true}
                    tabIndex={-1}
                  />
                  <span className="checkmark" data-cy="timeableCheckmark" />
                </span>
                <span
                  className="value"
                  onClick={this.touchProxy.bind(this, this.setCustomTime, [true])}
                  onTouchEnd={this.touchProxy.bind(this, this.setCustomTime, [true])}
                >
                  <span className="label">Custom</span>
                  <input
                    type="checkbox"
                    data-cy="customCheckBox"
                    checked={this.state.useCustomTime ? "checked" : ""}
                    readOnly={true}
                    tabIndex={-1}
                  />
                  <span className="checkmark" data-cy="customCheckmark" />
                </span>
              </div>
            </div>
          );
        }
        break;

      case "editCallOrder":
        if (this._canChangeCallOrder()) {
          const slotCallOrder = this.state.selectedSlots[0].get("call_order");
          const assignmentCallOrder =
            this.state.selectedSlots[0]?.assignmentObj ?? this.state.assignments.models[0].attributes.call_order;

          if (slotCallOrder || assignmentCallOrder) {
            return (
              <div className="selectable-order">
                <label className="selectable-order-label" htmlFor="select-call-order">
                  Call Order
                </label>

                <select
                  ref="CallOrder"
                  name="select-call-order"
                  className="selectable-order-value"
                  defaultValue={slotCallOrder ?? "none"}
                >
                  {this._getCallOrderOptions(slotCallOrder, assignmentCallOrder)}
                </select>
              </div>
            );
          }
        }
        break;

      case "editCallOrderFill":
        if (this._canChangeCallOrder()) {
          const { defaultValue, callOrderVisible } = this._getCallOrderFillData(this.state.selectedAssignments);
          if (callOrderVisible) {
            return (
              <div className="selectable-order">
                <label className="selectable-order-label" htmlFor="select-call-order">
                  Call Order
                </label>

                <select
                  ref="CallOrderFill"
                  name="select-call-order"
                  className={`selectable-order-value${defaultValue === "default" ? " light" : ""}`}
                  defaultValue={defaultValue}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={"none"}>None</option>
                  {defaultValue === "default" ? <option value={"default"}>Keep Defaults</option> : <></>}
                </select>
              </div>
            );
          }
        }
        break;

      case "editCallOrderMultipleShifts":
        if (this._canChangeCallOrder()) {
          const { defaultValue, options, callOrderVisible } = this._getCallOrderMultipleShiftsData(
            this.state.assignments.models
          );
          if (callOrderVisible) {
            return (
              <div className="selectable-order">
                <label className="selectable-order-label" htmlFor="select-call-order">
                  Call Order
                </label>

                <select
                  ref="CallOrder"
                  name="select-call-order"
                  className={`selectable-order-value${defaultValue === "default" ? " light" : ""}`}
                  defaultValue={defaultValue}
                >
                  {options}
                </select>
              </div>
            );
          }
        }
        break;

      case "listSwopCandidates":
        var slot = this.state.selectedSlots[0];
        var candidates = [];
        if (slot.get("pending_info")) {
          candidates = _.filter(slot.get("pending_info").preswaps, function (preswap) {
            return preswap.response === 2;
          });
        }
        // sort the candidates alphabetically
        candidates = _.sortBy(candidates, "display_name");
        var candidateDivs = [];
        for (var i = 0; i < candidates.length; i++) {
          candidateDivs.push(
            <div
              className={
                this.state.selectedPreswap && this.state.selectedPreswap.pre_pending_id == candidates[i].pre_pending_id
                  ? "item on"
                  : "item"
              }
              key={i}
              onClick={this._selectPreswap.bind(this, candidates[i])}
            >
              {candidates[i].display_name}
            </div>
          );
        }

        return <div className="items">{candidateDivs}</div>;
        break;

      case "listSlots":
        var slotData;
        var groupDivs = [];
        // group them by day and filter out any slots that they're currently holding (not pending for)
        var nonPendingSlots = this.state.slots.where({
          is_pending: false,
        });
        // now filter out extra slots needed for time zone awareness
        var dateKeys = _.keys(this.state.selectedDates);
        var nonPendingSlotsWithExtraDatesFiltered = _.filter(nonPendingSlots, function (s) {
          return _.contains(dateKeys, moment(s.get("slot_date")).format("YYYYMMDD"));
        });
        var slotData = _.groupBy(nonPendingSlotsWithExtraDatesFiltered, function (item) {
          return item.get("slot_date");
        });

        if (!this.state.isFetchingSlots) {
          for (var key in slotData) {
            var slots = slotData[key];

            var slotDivs = [];
            for (var i = 0; i < slots.length; i++) {
              var slot = slots[i];
              var isSelected = _.find(this.state.selectedSlots, function (s) {
                return s.id == slot.id;
              })
                ? true
                : false;

              slotDivs.push(
                <div className="checkable" key={i}>
                  <div className="checkable-value">
                    <span
                      className="value"
                      onClick={this.touchProxy.bind(this, this.toggleSlot, [slot])}
                      onTouchEnd={this.touchProxy.bind(this, this.toggleSlot, [slot])}
                    >
                      <span className="label">{slot.attributes.assign_display_name}</span>
                      <input type="checkbox" checked={isSelected ? "checked" : ""} readOnly={true} tabIndex={-1} />
                      <span className="checkmark" data-cy="labelCheckmark" />
                    </span>
                  </div>
                </div>
              );
            }

            groupDivs.push(
              <div className="slot-group" key={key}>
                <div className="date">{moment(slot.attributes.slot_date).format("ddd, ll")}</div>
                <div className="slots-container">{slotDivs}</div>
              </div>
            );
          }
          if (!groupDivs.length) {
            groupDivs.push(
              <div className="slot-group" key="empty">
                <div className="date">0 slots found.</div>
              </div>
            );
          }
        } else {
          groupDivs.push(
            <div className="loading-container" key="loading">
              <i className="fa fa-fw fa-spinner fa-spin" />
            </div>
          );
        }
        //}
        return groupDivs;
        break;

      case "sentenceSwap":
        var targetSlot =
          options && options.slot
            ? options.slot
            : this.state.selectedSlots.length
            ? this.state.selectedSlots[0]
            : undefined;
        var targetAssignment =
          options && options.targetAssignment ? options.targetAssignment : this._getTargetAssignment();
        var targetPersonnel = options && options.targetPersonnel ? options.targetPersonnel : this._getTargetPersonnel();
        //var type = request.attributes.command_type == 1 ? "Don't schedule" : "Schedule";
        var targetAssignmentStructures = this.state.assignmentStructureTimesDict;
        var type = "Schedule";
        var date =
          options && options.targetDate
            ? moment(options.targetDate, "YYYYMMDD")
            : _.keys(this.state.selectedDates).length
            ? moment(_.keys(this.state.selectedDates)[0], "YYYYMMDD")
            : targetSlot
            ? moment(targetSlot.get("slot_date") || targetSlot.get("request_date"), "YYYYMMDD")
            : undefined;

        var displayVars = this._getDisplayStrings(options);
        switch (this.state.action) {
          case "swap-fill":
          case "new-request":
            if (targetAssignment && targetPersonnel && date && !_.isEmpty(targetAssignmentStructures)) {
              return (
                <div className="sentence">
                  <span className="semi-large">{displayVars.date}</span>
                  <br />
                  <span className="large underline">{displayVars.assignment}</span>
                  <br />
                  <span className="large underline">{displayVars.personnel}</span>
                </div>
              );
            }
            break;

          case "swap-replace":
            if (targetSlot) {
              var personnelLine, assignmentLine, dateLine;
              if (this.state.selectedPersonnel.length) {
                // personnel swap
                var oldPersonnel = targetSlot.get("display_name");
                var newPersonnel = displayVars.personnel; //this.state.selectedPersonnel.length == 1 ? targetPersonnel.get('display_name') : 'Any of ' + this.state.selectedPersonnel.length + ' people';
                dateLine = (
                  <div className="swap-line">
                    <span className="semi-large">{displayVars.date}</span>
                  </div>
                );
                personnelLine = (
                  <div className="swap-line">
                    <span className="large gray">{oldPersonnel}</span> <i className="fa fa-fw fa-arrow-right" />{" "}
                    <span className="large underline">{newPersonnel}</span>
                  </div>
                );
                assignmentLine = (
                  <div className="swap-line">
                    <span className="large underline">{displayVars.assignment}</span>
                  </div>
                );
              } else if (this.state.selectedAssignments.length) {
                // assignment swap
                var oldAssignment = targetSlot.get("assign_display_name");
                var newAssignment = displayVars.assignment; //targetAssignment.get('display_name');
                dateLine = (
                  <div className="swap-line">
                    <span className="semi-large">{displayVars.date}</span>
                  </div>
                );
                assignmentLine = (
                  <div className="swap-line">
                    <span className="large gray">{oldAssignment}</span> <i className="fa fa-fw fa-arrow-right" />{" "}
                    <span className="large underline">{newAssignment}</span>
                  </div>
                );
                personnelLine = (
                  <div className="swap-line">
                    <span className="large underline">{displayVars.personnel}</span>
                  </div>
                );
              } else {
                // no change yet
                var oldPersonnel = targetSlot.get("display_name");
                var oldAssignment = targetSlot.get("assign_display_name");
                dateLine = (
                  <div className="swap-line">
                    <span className="semi-large">{displayVars.date}</span>
                  </div>
                );
                assignmentLine = (
                  <div className="swap-line">
                    <span className="large underline">{oldAssignment}</span>
                  </div>
                );
                personnelLine = (
                  <div className="swap-line">
                    <span className="large underline">{oldPersonnel}</span>
                  </div>
                );
              }
              return (
                <div className="sentence">
                  {dateLine}
                  {assignmentLine}
                  {personnelLine}
                </div>
              );
            }
            break;
          case "swap-remove":
            if (targetSlot) {
              return (
                <div className="sentence">
                  <span className="semi-large">{displayVars.date}</span>
                  <br />
                  <span className="large underline">{displayVars.assignment}</span>
                  <br />
                  <span className="large underline">{displayVars.personnel}</span>
                </div>
              );
            }
            break;
          case "swap-details":
            if (targetSlot) {
              return (
                <div className="sentence">
                  <span className="semi-large">{displayVars.date}</span>
                  <br />
                  <span className="large underline">{displayVars.assignment}</span>
                  <br />
                  <span className="large underline">{displayVars.personnel}</span>
                </div>
              );
            }
            break;
          case "preswap-pending":
          case "swap-pending":
            if (targetSlot && targetSlot.get("pending_info")) {
              var personnelLine;

              var pendingInfo = targetSlot.get("pending_info");
              var pendingType;
              if (pendingInfo["emp_id"] === 4) {
                pendingType = "remove";
              } else if (targetSlot.get("emp_id") === 4) {
                pendingType = "fill";
              } else if (pendingInfo["emp_id"] !== targetSlot.get("emp_id")) {
                pendingType = "replace";
              } else if (pendingInfo["preswaps"].length) {
                pendingType = "swaportunity";
              } else if (pendingInfo["emp_id"] === targetSlot.get("emp_id")) {
                pendingType = "details";
              }

              var personnelLine, assignmentLine, dateLine, pendingTime, pendingReasonLine, pendingLocationLine;
              if (pendingType == "remove") {
                var oldPersonnel = targetSlot.get("display_name");
                personnelLine = (
                  <div className="swap-line">
                    <i className="fa fa-fw fa-minus" /> <span className="large underline">{oldPersonnel}</span>
                  </div>
                );
              } else if (pendingType == "fill") {
                var newPersonnel = targetSlot.get("pending_display_name");
                personnelLine = (
                  <div className="swap-line">
                    <i className="fa fa-fw fa-plus" /> <span className="large underline">{newPersonnel}</span>
                  </div>
                );
              } else if (pendingType == "replace") {
                var oldPersonnel = targetSlot.get("display_name");
                var newPersonnel = targetSlot.get("pending_display_name");
                personnelLine = (
                  <div className="swap-line">
                    <span className="large gray">{oldPersonnel}</span> <i className="fa fa-fw fa-arrow-right" />{" "}
                    <span className="large underline">{newPersonnel}</span>
                  </div>
                );
              } else if (pendingType == "swaportunity") {
                var oldPersonnel = targetSlot.get("display_name");
                personnelLine = (
                  <div className="swap-line">
                    <span className="large underline">{oldPersonnel}</span> <i className="fa fa-fw fa-rss" />
                  </div>
                );
              } else if (pendingType == "details") {
                var oldPersonnel = targetSlot.get("display_name");
                personnelLine = (
                  <div className="swap-line">
                    <i className="fa fa-fw fa-question" /> <span className="large underline">{oldPersonnel}</span>
                  </div>
                );
              } else {
                // shouldn't be here
              }
              dateLine = (
                <div className="swap-line">
                  <span className="semi-large">{displayVars.date}</span>
                </div>
              );
              if (pendingInfo["status"] == "pending slot details") {
                if (
                  pendingInfo["start_time"] !== targetSlot.get("start_time") ||
                  pendingInfo["stop_time"] !== targetSlot.get("stop_time")
                ) {
                  var pendingStartString = pendingInfo["start_time"].split("T")[1].slice(0, -3);
                  var pendingEndString = pendingInfo["stop_time"].split("T")[1].slice(0, -3);
                  var currentStartString = displayVars.timeOnly.split(" - ")[0];
                  var currentEndString = displayVars.timeOnly.split(" - ")[1];
                  var pendingTimeText =
                    moment(pendingInfo["start_time"])
                      .format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"))
                      .replace(" AM", "a")
                      .replace(" PM", "p") +
                    " - " +
                    moment(pendingInfo["stop_time"])
                      .format(window.LbsAppData.Helpers.Time.preferredTimeFormat("LT"))
                      .replace(" AM", "a")
                      .replace(" PM", "p");
                  var newDateText = displayVars.dateOnly + ", " + pendingTimeText;
                  var oldSafeText = displayVars.dateOnly + ", ";
                  var oldStrikeText, oldAdditionalSafeText;
                  if (pendingStartString !== currentStartString && pendingEndString == currentEndString) {
                    oldStrikeText = currentStartString;
                    oldAdditionalSafeText = " - " + currentEndString;
                  } else if (pendingStartString == currentStartString && pendingEndString !== currentEndString) {
                    oldSafeText = oldSafeText + currentStartString + " - ";
                    oldStrikeText = currentEndString;
                  } else {
                    oldStrikeText = displayVars.timeOnly;
                  }
                  dateLine = (
                    <div>
                      <div className="swap-line">
                        <span
                          className="semi-large"
                          style={{
                            display: "inline-block",
                            width: 35,
                          }}
                        >
                          Was:
                        </span>
                        <span className="semi-large">{oldSafeText}</span>
                        <span
                          className="semi-large"
                          style={{
                            textDecorationLine: "line-through",
                          }}
                        >
                          {oldStrikeText}
                        </span>
                        <span className="semi-large">{oldAdditionalSafeText}</span>
                      </div>
                      <div className="swap-line">
                        <span
                          className="semi-large"
                          style={{
                            display: "inline-block",
                            width: 35,
                          }}
                        >
                          Now:
                        </span>
                        <span className="semi-large">{newDateText}</span>
                      </div>
                    </div>
                  );
                }
              }

              if (pendingInfo["loa_reason_name"]) {
                pendingReasonLine = (
                  <div className="swap-line">
                    <span className="semi-large">New Reason: {pendingInfo["loa_reason_name"]}</span>
                  </div>
                );
              }
              if (pendingInfo["location_names"].length == 1) {
                pendingLocationLine = (
                  <div className="swap-line">
                    <span className="semi-large">New Location: {pendingInfo["location_names"]}</span>
                  </div>
                );
              }
              if (pendingInfo["location_names"].length > 1) {
                pendingLocationLine = (
                  <div className="swap-line">
                    <span className="semi-large">New Locations: {pendingInfo["location_names"].join(", ")}</span>
                  </div>
                );
              }

              return (
                <div className="sentence">
                  {dateLine}
                  <div className="swap-line">
                    <span className="large underline">{displayVars.assignment}</span>
                  </div>
                  {personnelLine}
                  {pendingReasonLine}
                  {pendingLocationLine}
                </div>
              );
            }
            break;
          case "preswap-finalize":
            if (targetSlot && targetPersonnel) {
              var oldPersonnel = targetSlot.get("display_name");
              var newPersonnel = targetPersonnel.get("display_name");

              return (
                <div className="sentence">
                  <span className="semi-large">{displayVars.date}</span>
                  <br />
                  <span className="large underline">{displayVars.assignment}</span>
                  <br />
                  <span className="large gray">{oldPersonnel}</span> <i className="fa fa-fw fa-arrow-right" />{" "}
                  <span className="large underline">{newPersonnel}</span>
                </div>
              );
            }
            break;
          case "request-action":
            if (targetSlot) {
              return (
                <div className="sentence">
                  <span className="semi-large">{displayVars.date}</span>
                  <br />
                  <span className="large underline">{displayVars.assignment}</span>
                  <br />
                  <span className="large underline">{displayVars.personnel}</span>
                </div>
              );
            }
            break;
          case "swap-exchange":
            if (targetSlot) {
              var oldPersonnel = targetSlot.get("display_name");
              var newPersonnel = options.forceEmpTargetDisplay;

              return (
                <div className="sentence">
                  <span className="semi-large">{displayVars.date}</span>
                  <br />
                  <span className="large underline">{displayVars.assignment}</span>
                  <br />
                  <span className="large gray">{oldPersonnel}</span> <i className="fa fa-fw fa-arrow-right" />{" "}
                  <span className="large underline">{newPersonnel}</span>
                </div>
              );
            }
            break;
          default:
            break;
        }
        break;

      case "sentenceSwaps":
        var isExchange = this.state.action == "swap-exchange";

        var swapSentences = [];
        if (this.state.selectedSlots.length) {
          for (var i = 0; i < this.state.selectedSlots.length; i++) {
            var swapSentence;
            if (!isExchange) {
              swapSentence = this.getAttributeWidget("sentenceSwap", {
                slot: this.state.selectedSlots[i],
              });
            } else {
              var oppositeEmpDisplay = _.find(this.state.selectedSlots, function (s) {
                return s.get("emp_id") !== that.state.selectedSlots[i].get("emp_id");
              }).get("display_name");
              swapSentence = this.getAttributeWidget("sentenceSwap", {
                slot: this.state.selectedSlots[i],
                forceEmpTargetDisplay: oppositeEmpDisplay,
              });
            }

            swapSentences.push(
              <div className="sentence-container" key={i}>
                <div className="swap-num">{i + 1}</div>
                {swapSentence}
              </div>
            );
          }
        } else {
          // no slots -- we're filling/requesting
          const targets = this._permutateTargets();
          for (var i = 0; i < targets.length; i++) {
            var swapSentence = this.getAttributeWidget("sentenceSwap", {
              targetDate: targets[i].targetDate,
              targetPersonnel: targets[i].targetPersonnel,
              targetAssignment: targets[i].targetAssignment,
              targetStructure: targets[i].targetStructure,
            });
            swapSentences.push(
              <div className="sentence-container" key={i}>
                <div className="swap-num">{i + 1}</div>
                {swapSentence}
              </div>
            );
          }
        }

        return <div className="swap-sentences">{swapSentences}</div>;
        break;
      default:
        break;
    }
  },
  isUserAnAdmin: function () {
    return window.LbsAppData.User.get("is_admin");
  },
  checkAndNotifyIfOnlyUserOnSplit: function () {
    const isOnlyTheUserSelected =
      this.state.selectedPersonnel.length === 0 &&
      this.state.selectedPersonnel2.length === 0 &&
      this.state.selectedPersonnel3.length === 0;

    this.setState({ showWarningDialog: isOnlyTheUserSelected });

    return isOnlyTheUserSelected;
  },
  isNonAdminSelectedForAllSplits: function () {
    return !this.isUserAnAdmin() && this.checkAndNotifyIfOnlyUserOnSplit();
  },
  handleSplitShiftDialogSubmit: function () {
    if (!this.isNonAdminSelectedForAllSplits() || this.state.showWarningDialog) {
      this.submit();
      this.setState({ showWarningDialog: false });
    }
  },
  handleSplitShiftDialogClose: function () {
    if (this.state.showWarningDialog) {
      return this.setState({ showWarningDialog: false });
    }

    this.close();
  },
  toggleAddDemand: function () {
    this.setState({ addDemand: !this.state.addDemand });
  },
  toggleSlot: function (item) {
    var idx = _.findIndex(this.state.selectedSlots, function (i) {
      return i.id === item.id;
    });
    if (idx > -1) {
      // already there, remove it
      this.state.selectedSlots.splice(idx, 1);
    } else {
      // not there, add it
      this.state.selectedSlots.push(item);
    }
    // force update
    this.forceUpdate();
  },

  /* render */
  render: function () {
    if (this.state.isLoading) {
      return (
        <div className="swap-wrapper">
          <div className="container-middle">
            <div className="loading-container">
              <span className="fa fa-fw fa-spin fa-spinner" />
            </div>
          </div>
        </div>
      );
    } else {
      var form;
      switch (this.state.action) {
        case "swap-fill":
        case "new-request": {
          form = (
            <SwapFillDialog
              ref="DialogChild"
              dialogObject={this.props.dialogObject}
              isTop={this.props.isTop}
              inline={this.props.inline}
              close={this.close}
              getData={this.getStateAttribute}
              widgetProxy={this.getAttributeWidget}
              submit={this.submit}
              flags={this.props.flags}
            />
          );
          break;
        }
        case "swap-replace":
          form = (
            <SwapReplaceDialog
              ref="DialogChild"
              dialogObject={this.props.dialogObject}
              isTop={this.props.isTop}
              inline={this.props.inline}
              close={this.close}
              getData={this.getStateAttribute}
              widgetProxy={this.getAttributeWidget}
              submit={this.submit}
              flags={this.props.flags}
            />
          );
          break;
        case "swap-exchange":
          form = (
            <SwapExchangeDialog
              ref="DialogChild"
              dialogObject={this.props.dialogObject}
              isTop={this.props.isTop}
              inline={this.props.inline}
              close={this.close}
              getData={this.getStateAttribute}
              widgetProxy={this.getAttributeWidget}
              submit={this.submit}
            />
          );
          break;
        case "swap-remove":
          form = (
            <SwapRemoveDialog
              ref="DialogChild"
              dialogObject={this.props.dialogObject}
              isTop={this.props.isTop}
              inline={this.props.inline}
              close={this.close}
              getData={this.getStateAttribute}
              widgetProxy={this.getAttributeWidget}
              onStepForward={this.stepForward}
              onStepBackward={this.stepBackward}
              submit={this.submit}
            />
          );
          break;
        case "split-shift":
          form = (
            <SplitShiftDialog
              ref="DialogChild"
              dialogObject={this.props.dialogObject}
              isTop={this.props.isTop}
              inline={this.props.inline}
              close={this.handleSplitShiftDialogClose}
              getData={this.getStateAttribute}
              widgetProxy={this.getAttributeWidget}
              submit={this.handleSplitShiftDialogSubmit}
              showWarningDialog={this.state.showWarningDialog}
            />
          );

          break;

        case "swap-details":
          form = (
            <SwapDetailsDialog
              ref="DialogChild"
              dialogObject={this.props.dialogObject}
              isTop={this.props.isTop}
              inline={this.props.inline}
              close={this.close}
              getData={this.getStateAttribute}
              widgetProxy={this.getAttributeWidget}
              submit={this.submit}
            />
          );
          break;
        case "swap-pending":
          form = (
            <SwapPendingDialog
              ref="DialogChild"
              dialogObject={this.props.dialogObject}
              isTop={this.props.isTop}
              inline={this.props.inline}
              close={this.close}
              getData={this.getStateAttribute}
              widgetProxy={this.getAttributeWidget}
              submit={this.submit}
            />
          );
          break;
        case "preswap-pending":
          form = (
            <PreSwapPendingDialog
              ref="DialogChild"
              dialogObject={this.props.dialogObject}
              isTop={this.props.isTop}
              inline={this.props.inline}
              close={this.close}
              getData={this.getStateAttribute}
              widgetProxy={this.getAttributeWidget}
              submit={this.submit}
            />
          );
          break;
        case "preswap-finalize":
          form = (
            <PreSwapFinalizeDialog
              ref="DialogChild"
              dialogObject={this.props.dialogObject}
              isTop={this.props.isTop}
              inline={this.props.inline}
              close={this.close}
              getData={this.getStateAttribute}
              widgetProxy={this.getAttributeWidget}
              submit={this.submit}
            />
          );
          break;
        case "request-action":
          form = (
            <RequestActionDialog
              ref="DialogChild"
              dialogObject={this.props.dialogObject}
              isTop={this.props.isTop}
              inline={this.props.inline}
              close={this.close}
              getData={this.getStateAttribute}
              widgetProxy={this.getAttributeWidget}
              submit={this.submit}
              flags={this.props.flags}
            />
          );
          break;
        default:
          break;
      }

      let content;
      if (!this.props.inline) {
        content = form;
      } else {
        var slotList = (
          <BulkActionDialogSlotList
            ref="SlotList"
            action={this.props.dialogObject.action}
            widgetProxy={this.getAttributeWidget}
          />
        );

        content = (
          <div className="position-reset">
            <div className="subheaders">
              <div className="subheader edit">Customize</div>
              <div className="subheader preview">Preview</div>
            </div>
            <div className="content-body">
              <div className="form-container">{form}</div>
              {slotList}
            </div>
          </div>
        );
      }

      return content;
    }
  },
});

module.exports = ScheduleEditsBase;
