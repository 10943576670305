import React from "react";

import { PermissionsContext as PermissionsContextType } from "@/viewer/ui/modules/common/types/context";
import { User } from "@/viewer/types/domain/user";

import env from "@/_lib/utils/env";

import useCurrentlyLoggedInUser from "@/viewer/ui/modules/common/hooks/useCurrentlyLoggedInUser";
import usePermissions from "@/viewer/ui/modules/common/hooks/usePermissions";
import useSettings from "@/viewer/ui/modules/common/hooks/useSettings";

const getUserPendoData = (user: User, app: string, env: string, permissions: PermissionsContextType) => ({
  visitor: {
    id: user.id,
    username: user.userName,
    timezone: user.timeZone,
    admin: user.isAdmin,
    super_admin: user.isSuperAdmin,
    app,
    modifySchedule: permissions.canIChangeAnyonesSchedule,
    modifyRequests: permissions.canIChangeAnyonesRequests,
  },
  account: {
    id: user.customerId,
    environment: env,
  },
});

const Pendo = (): null => {
  const settings = useSettings();
  const user = useCurrentlyLoggedInUser();
  const permissions = usePermissions();

  React.useEffect(() => {
    const apiKey = env.PENDO_API_KEY;

    if (env.PENDO_ENABLED && user && apiKey) {
      // Initialize pendo object on window.
      (window as any).pendo = {
        _q: [["initialize", getUserPendoData(user, settings.app, env.ENVIRONMENT, permissions)]],
      };

      // Load pendo.js
      const script = document.createElement("script");
      const body = document.getElementsByTagName("body")[0];

      script.src = `https://content.analytics.lightning-bolt.com/agent/static/${apiKey}/pendo.js`;
      script.async = true;
      body.appendChild(script);
    }
  }, []);

  return null;
};

export default Pendo;
