import { Model } from "backbone";

import { SettingsContext } from "@/viewer/ui/modules/common/types/context";
import connectedPropertyHookFactory from "@/viewer/ui/modules/common/hooks/connectedPropertyHookFactory";

import { cleanPersonnelAttributes, PersonnelById } from "@/viewer/types/domain/personnel";

const getPersonnel = (settings: SettingsContext): PersonnelById => {
  const { LbsAppData } = window as any;

  const out: PersonnelById = {};
  const personnelCollection = LbsAppData.Personnel;

  if (!personnelCollection) return out;

  personnelCollection.forEach((personnelModel: Model) => {
    const personnel = cleanPersonnelAttributes(settings, personnelModel.attributes);
    out[personnel.empId] = personnel;
  });

  return out;
};

export default connectedPropertyHookFactory({
  loader: getPersonnel,
  getListeners: () => {
    const { LbsAppData } = window as any;
    return [[LbsAppData.AppContext, "fetched"]];
  },
});
