import { ScheduleData, SettingsContext } from "@/viewer/ui/modules/common/types/context";
import reportSkeletonFactory from "@/viewer/data/factories/reportSkeletonFactory";

import fetchSlots from "@/_lib/hooks/fetchSlots";
import fetchRequests from "@/_lib/hooks/fetchRequests";
import postReport from "@/_lib/hooks/postReport";

/**
 * Fetch the relevant `ScheduleData` for a view.
 *
 * Fetches slots and requests for the given date range.
 * Posts to Staghorn to create a new `Report` for the given date range.
 *
 * @param settings `SettingsContext`
 * @param startDate `Date`
 * @param endDate `Date`
 */

const fetchScheduleData = async (settings: SettingsContext, startDate: Date, endDate: Date): Promise<ScheduleData> => {
  const viewId = settings.viewId as string;
  const slots = await fetchSlots(startDate, endDate, viewId);
  const requests = await fetchRequests(startDate, endDate, viewId);
  const reportSkeleton = reportSkeletonFactory(startDate, endDate, settings);
  const report = await postReport(reportSkeleton);

  return {
    slots,
    requests,
    report,
  };
};

export default fetchScheduleData;
