import React from "react";
import moment from "moment";

import useStandardSlotData from "@/viewer/ui/modules/grid/standard/hooks/useStandardSlotData";
import useStandardRequestData from "@/viewer/ui/modules/grid/standard/hooks/useStandardRequestData";
import useTallyReportData from "@/viewer/ui/modules/common/hooks/useTallyReportData";

import { BOTTOM_SPACER_ROW_HEIGHT, TOP_SPACER_ROW_HEIGHT } from "@/viewer/ui/modules/grid/standard/constants";
import { ScheduleData, SettingsContext, UIContext, ViewDataType } from "@/viewer/ui/modules/common/types/context";
import { ViewData } from "@/viewer/types/viewdata";
import { VirtualizedRow } from "@/viewer/ui/modules/common/types/virtualization";
import { getWeekKey } from "@/viewer/ui/modules/common/helpers/dates";

import renderHeaderRow from "@/viewer/ui/modules/grid/standard/renderers/renderHeaderRow";
import renderTallyGroups from "@/viewer/ui/modules/grid/standard/renderers/renderTallyGroups";
import renderSlots from "@/viewer/ui/modules/grid/standard/renderers/renderSlots";
import renderRequests from "@/viewer/ui/modules/grid/standard/renderers/renderRequests";
import { DepartmentsById } from "@/viewer/types/domain/department";

export type RenderedStandardRows = { rows: VirtualizedRow[]; weekStart: Date; weekEnd: Date };

const RenderStandardRows = (
  settings: SettingsContext,
  ui: UIContext,
  weekRange: [Date, Date][],
  viewData: ViewData,
  scheduleData: ScheduleData,
  dateRange: Date[],
  departmentsById: DepartmentsById,
  isPrinting?: boolean
): RenderedStandardRows => {
  const { LbsAppData } = window as any;

  const { personnel, assignments, data, filters, tools, originalPersonnelMap } = viewData;
  const { slots, requests, report } = scheduleData;
  const { filteredPersonnel } = filters;
  const { viewDataType } = settings;
  const slotData = useStandardSlotData(settings, ui, filters, data, slots);
  const requestData = useStandardRequestData(settings, ui, filters, data, requests, dateRange);
  const reportData = useTallyReportData(settings, report);

  // Prepare data rows

  const rows: VirtualizedRow[] = [];

  let weekStart: Date = new Date(),
    weekEnd: Date = new Date();
  weekRange.forEach(([start, end]) => {
    weekStart = start;
    weekEnd = end;
    const dateStart = weekStart;
    const dateEnd = weekEnd;
    const weekKey: string = getWeekKey(settings, ui, start);

    // Skip the header for weekly views -- it will be displayed in an alternate fashion to support a sticky header
    // in this case.
    if (ui.dateMode === "weekly" && !isPrinting) {
      rows.push({
        wrapperClassName: "header-spacer",
        height: TOP_SPACER_ROW_HEIGHT,
        element: <div />,
        key: `header-spacer-${weekStart.toString()}`,
      });
    } else {
      rows.push(
        renderHeaderRow({
          settings,
          ui,
          dateEnd,
          dateStart,
          isDateInSchedule: (date) => LbsAppData.DateManager.isDateInSchedule(moment(date)),
          isHoliday: (date) => LbsAppData.AppContext._isHoliday(moment(date)),
        })
      );
    }

    rows.push(
      ...renderTallyGroups({
        settings,
        dateEnd,
        dateStart,
        filteredPersonnel,
        ...reportData,
      })
    );

    if (viewDataType === ViewDataType.schedule || viewDataType === ViewDataType.combined) {
      rows.push(
        ...renderSlots({
          settings,
          ui,
          filters,
          data,
          tools,
          dateStart,
          dateEnd,
          slotData: slotData[weekKey],
          leftColumnData: ui.leftColumnType === "assignment" ? assignments : personnel,
          originalPersonnelMap,
          departmentsById,
        })
      );
    }

    if (viewDataType === ViewDataType.request || viewDataType === ViewDataType.combined) {
      rows.push(
        ...renderRequests({
          settings,
          ui,
          filters,
          data,
          tools,
          dateStart,
          dateEnd,
          requestData: requestData[weekKey],
          leftColumnData: ui.leftColumnType === "assignment" ? assignments : personnel,
          originalPersonnelMap,
          departmentsById,
        })
      );
    }
  });

  // We need to make some space at the bottom of the list so that the toolbar doesn't
  // overlap too badly.
  rows.push({
    height: BOTTOM_SPACER_ROW_HEIGHT,
    key: "spacer",
    wrapperClassName: "spacer",
    element: <div style={{ height: BOTTOM_SPACER_ROW_HEIGHT }}>&nbsp;</div>,
  });

  return {
    rows,
    weekStart,
    weekEnd,
  };
};

export default RenderStandardRows;
