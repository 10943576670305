// Helper to describe node models

var moment = require("moment");
var _ = require("underscore");
var $ = require("jquery");

// object models
var PersonnelNode = require("./node_models/objects/PersonnelNode.js");
var TalliesNode = require("./node_models/objects/TalliesNode.js");

// display models
var TableNode = require("./node_models/displays/TableNode.js");

var NodeHelper = Backbone.Model.extend({
  defaults: {},

  initialize: function () {
    // create node model instances
    this.descriptions = {
      objects: {},
      data: {},
      controls: {},
      displays: {},
    };

    // objects
    this.descriptions.objects.personnelNode = new PersonnelNode();
    this.descriptions.objects.talliesNode = new TalliesNode();

    // display
    this.descriptions.displays.tableNode = new TableNode();
  },

  /* publics */
});

module.exports = NodeHelper;
