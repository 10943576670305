const Backbone = require("backbone");
const moment = require("moment");
const _ = require("underscore");

const Session = require("_lib/data/session/Session.js");
const Schedule = require("./Schedule.js");
// models
const ScheduleStatus = require("_lib/data/models/ScheduleStatus.js");

const Destinations = require("./Destinations");

const SafeJSON = require("../../../common/utils/jsonParseShield");
const { LEGACY_VIEW_ID_OFFSET } = require("@/_lib/constants/globals");

const AppContext = Backbone.Model.extend({
  session: new Session(),
  appSiblings: new Destinations(),

  defaults: {
    layout: "hybrid",
    view: undefined,

    GridSettings_leftColumnType: "assignment",

    focusedPane: undefined,
    focusedExtra: undefined,
    focusedHolidayDate: undefined,
    focusedSlot: undefined,

    // displayed contexts
    contextualStack: [],
    // displayed dialogs
    dialogStack: [],
    // displayed menus

    displayedDialog: undefined,
    displayedSchedule: undefined,
    displayedScheduleStatus: undefined,
    displayedContextMenu: undefined,

    slotOrdering: undefined,

    forceDefault: false,
  },

  initialize: function () {
    if (window.payload.schedule_context) {
      this.attributes.displayedSchedule = new Schedule(window.payload.schedule_context);
      this.attributes.displayedScheduleStatus = new ScheduleStatus({
        schedule_id: this.get("displayedSchedule").id,
      });
    }

    // localization settings
    this.setupLocale();

    // get the sibling application urls
    this.appSiblings.fetch();
  },

  setupLocale: function (view) {
    // localization settings
    const user = SafeJSON.parse(window.payload.user);
    const locale = user.locale ? user.locale : "en";

    moment.locale(locale);
    // going to hijack the 'L' formatting string to strip the year... this could be pandora's box but we don't use it anywhere so I think it's fine
    // this provides the grid headers with locale-specific formats and doesn't require us to constantly strip the year when it comes down
    const formatString = moment
      .localeData(locale)
      .longDateFormat("L")
      .replace(/(\/|\.|\-)?YYYY(\/|\.|\-)?/, "");
    moment.localeData(locale)._longDateFormat["L"] = formatString;

    // start day of week -- get this from the view settings, NOT the locale
    if (view !== undefined) {
      const startOnDay = view.attributes.theme.data.startOnDay;
      switch (startOnDay) {
        case "Mon":
          moment.locale(locale, {
            week: {
              dow: 1, // start day of week
              doy: 4, // week that contains Jan 4th is the first week of the year
            },
          });
          break;
        case "Sun":
          moment.locale(locale, {
            week: {
              dow: 0, // start day of week
              doy: 4, // week that contains Jan 4th is the first week of the year
            },
          });
          break;
        case "Sat":
          moment.locale(locale, {
            week: {
              dow: 6, // start day of week
              doy: 4, // week that contains Jan 4th is the first week of the year
            },
          });
          break;
      }
    } else {
      moment.locale(locale, {
        week: {
          dow: 0, // start day of week
          doy: 4, // week that contains Jan 4th is the first week of the year
        },
      });
    }
  },

  registerEvents: function () {
    window.LbsAppData.Assignments.on(
      "change:filteredModel",
      window.LbsAppData.AppContext.pushFilteredContext.bind(this, null),
      this
    );
    window.LbsAppData.Personnel.on(
      "change:filteredModel",
      window.LbsAppData.AppContext.pushFilteredContext.bind(this, null),
      this
    );
  },

  _syncBackbone: function (callback) {
    const that = this;

    this.session._syncBackbone(function (callback) {
      if (callback) {
        callback();
      }
      // see if there are any pending changes for this schedule right off the bat
      that.fetchScheduleStatus();
    });
  },

  /* publics */
  pushContext: function (contextObject) {
    this.attributes.contextualStack.push(contextObject);
    this.trigger("change:contextualStack");
  },
  popContext: function () {
    this.trigger("change:contextualStack");
  },
  updateContextualItem: function (tag, attributes) {
    var item = _.findWhere(this.attributes.contextualStack, { tag: tag });
    for (key in attributes) {
      item[key] = attributes[key];
    }
    this.trigger("change:contextualStack");
  },
  pushViewContext: function () {
    window.LbsAppData.AppContext.pushContext({
      tag: "view",
      text: this.attributes.view.name,
      tooltip: "open view",
      textClass: "no-mobile",
      mobileIcon: "fa-bars",
      action: function () {
        window.LbsAppData.AppContext.openDialog({ type: "options" });
      },
    });
  },
  pushFilteredContext: function () {
    const leftColumnCollection = this.getLeftColumnCollection();

    const item = _.findWhere(this.attributes.contextualStack, {
      tag: "filter",
    });

    if (!item) {
      // push a new context item
      this.pushContext({
        tag: "filter",
        text:
          _.filter(leftColumnCollection.models, function (item) {
            return !item.attributes.filtered;
          }).length +
          " " +
          leftColumnCollection.title,
        tooltip: "open filters",
        action: window.LbsAppData.AppContext.openDialog.bind(this, {
          type: "options",
          tab: "filter",
        }),
        onClose: function () {
          window.LbsAppData[leftColumnCollection.title].unfilterAll();

          // send a tracking event
          const rowTypeTitle =
            window.LbsAppData.AppContext.getLeftColumnType() === "assignment" ? "Assignment" : "Personnel";
          window.LbsAppData.Helpers.Analytics.sendEvent("Filter: " + rowTypeTitle + " None", "Context Ribbon");
        },
      });
    } else {
      const shownItems = _.filter(leftColumnCollection.models, function (item) {
        return !item.attributes.filtered;
      });

      if (shownItems.length === leftColumnCollection.models.length) {
        // if no assignments are filtered then just drop the contextual item
        this.popContext();
      } else if (shownItems.length === 1) {
        // if there is only one assignment show the name
        this.updateContextualItem("filter", {
          text: shownItems[0].attributes.display_name,
        });
      } else {
        // update the existing one with the count
        this.updateContextualItem("filter", {
          text: shownItems.length + " " + leftColumnCollection.title,
        });
      }
    }
  },

  focusHolidayDate: function (date) {
    if (this._isHoliday(date.data) && !this.attributes.displayedContextMenu) {
      this.set({ focusedHolidayDate: date });
    }
  },
  unfocusHolidayDate: function (date) {
    if (this.attributes.focusedHolidayDate) {
      this.set({ focusedHolidayDate: date });
    }
  },
  _isHoliday: function (date) {
    const holidays = window.LbsAppData.Holidays.models;

    for (let i = 0; i < holidays.length; i++) {
      if (holidays[i].attributes.holiday_date && date.isSame(holidays[i].attributes.holiday_date, "day")) {
        return true;
      }
    }

    return false;
  },
  focusSlot: function (slot) {
    if (!this.attributes.focusedSlot && !this.attributes.displayedContextMenu) {
      this.set({ focusedSlot: slot });
    }
  },
  unfocusSlot: function () {
    if (this.attributes.focusedSlot && !this.attributes.focusedSlot.expanded) {
      this.set({ focusedSlot: null });
    }
  },
  expandFocusedSlot: function (slot) {
    if (!slot) {
      // proxy the data into the slot-details dialog
      const slot = this.attributes.focusedSlot.data;

      this.openDialog({ type: "slot-details", slot });
      this.unfocusSlot();
    } else {
      this.openDialog({ type: "slot-details", slot });
    }
  },
  closeExpandedSlot: function () {
    this.closeDialog();
  },

  openContextMenu: function (contextMenuObj) {
    this.set({
      displayedContextMenu: contextMenuObj,
      focusedSlot: undefined,
      focusedHolidayDate: undefined,
    });
  },
  closeContextMenu: function () {
    this.set({
      displayedContextMenu: undefined,
    });
  },

  // displayers
  openDialog: function (object) {
    this.attributes.dialogStack.push(object);
    this.trigger("pushedDialog");
  },
  closeDialog: function () {
    var dialog = this.attributes.dialogStack.pop();
    this.trigger("poppedDialog");
  },
  clearDialogs: function () {
    this.attributes.dialogStack = [];
    this.trigger("poppedDialog");
  },

  openLoadingDialog: function (message, context, callback) {
    this.openDialog({ type: "message", message: message });

    if (callback) {
      window.setTimeout(function () {
        callback.call(context);
      }, 100);
    }
  },

  closeLoadingDialog: function () {
    // pretend like we did something that took a while basically...just so the user has time to see
    // something happened or is happening
    const that = this;

    window.setTimeout(function () {
      that.closeDialog();
    }, 500);
  },

  selectLayout: function (type) {
    if (type === "grid") {
      if (this.attributes.focusedPane && this.attributes.focusedPane.type === "fragments") {
        // close the pane
        this.closePane();
      }
    } else {
      // if it's fragmented open the pane
      if (window.LbsAppData.DateManager.isFragmented()) {
        this.openPane({ type: "fragments" });
      }
    }
    // open and close the tool, will run into issues if we are still bound to objects on the switch
    window.LbsAppData.ToolBox.flutterTool();
    window.LbsAppData.AppContext.set({ layout: type });
  },
  openPane: function (paneObj) {
    this.set({
      focusedPane: paneObj,
    });
  },
  closePane: function () {
    this.set({
      focusedPane: undefined,
    });
  },
  openExtra: function (extraObj) {
    this.set({
      focusedExtra: extraObj,
    });
  },
  closeExtra: function (extraObj) {
    this.set({
      focusedExtra: extraObj ? extraObj : undefined,
    });
  },
  showContextMenu: function (contextMenuObj) {
    this.set({
      displayedContextMenu: contextMenuObj,
    });
  },

  saveSchedule: function (force) {
    // need to save, and then refetch for things to work right
    const that = this;

    // welp...we now have published schedules...and we don't do logging of changings or tracking history on slots
    // with it...Im sure we will have to at some point but for now..make the sure intimately aware of it

    // don't prompt on the force safe...they would have already gotten the prompt in that flow
    if (!force && this.attributes.displayedSchedule.get("is_published") === true) {
      // prompt them soooo hard
      window.LbsAppData.AppContext.openDialog({
        type: "prompt",
        message:
          "You are about to commit to a published schedule.  Editor currently does not track changes or send notification emails to affected personnel.  If that is okay then proceed, otherwise cancel out of this operation.",
        callback: function () {
          window.LbsAppData.AppContext.openLoadingDialog("Saving Changes...", that, function () {
            that._innerScheduleSave(force);
          });
        },
      });
    } else {
      window.LbsAppData.AppContext.openLoadingDialog("Saving Changes...", this, function () {
        this._innerScheduleSave(force);
      });
    }
  },

  _innerScheduleSave: function (force) {
    const that = this;

    this.attributes.displayedSchedule.save(
      { allow_force_save: force ? true : false },
      {
        success: function () {
          // refresh the page
          window.location.reload();
        },
        error: function (model, resp) {
          that.clearDialogs();
          that.openDialog({
            type: "save-error",
            errors: resp.responseJSON,
          });
        },
      }
    );

    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent(
      "Save: Schedule " + (force ? "Forced" : "Unforced"),
      this.attributes.displayedSchedule.id
    );
  },

  fetchScheduleStatus: function () {
    const that = this;

    this.attributes.displayedScheduleStatus.fetch({
      success: function () {
        that.trigger("refreshedScheduleStatus");
      },
      error: function () {},
    });
  },

  clearSchedule: function () {
    // clear out any highlighted errors prior to the delete

    window.LbsAppData.AppContext.openDialog({
      type: "prompt",
      message:
        "Are you sure you want to delete all the changes since the last save for this schedule?  This is an unrecoverable action!",
      callback: function () {
        window.LbsAppData.AppContext.openLoadingDialog("Clearing changes since last save...", this, function () {
          // clear the changes
          window.LbsAppData.AppContext.attributes.displayedSchedule.destroy({
            success: function () {
              // send a message to felicity
              window.LbsAppData.Helpers.Socket.send("schedule_clear", {
                id: window.LbsAppData.AppContext.get("displayedSchedule").id,
              });

              // refresh the page
              window.location.reload();
            },
          });
        });
      },
    });

    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Clear: Schedule", this.attributes.displayedSchedule.id);
  },

  openView: function (viewID, noSetDefault) {
    let model;

    try {
      // if we can't find the view..(maybe it was deleted?  something else) just bail out
      if (viewID < LEGACY_VIEW_ID_OFFSET) {
        //normal LBLite View
        model = window.LbsAppData.Views.get(viewID);
      } else {
        //Legacy Views
        model = window.LbsAppData.ViewsLegacy.get(viewID - LEGACY_VIEW_ID_OFFSET);
        if (model !== undefined) {
          model.is_legacy = true;
        }
      }
      if (model === undefined) {
        return;
      }

      // turn filter the personnel/assignments based on the view...depending on what the leftCol type is
      // maker for now is NOT going to filter the non-row element...as we think that's going to be real confusing for the user

      let leftCol;

      if (model.attributes.theme.data.GridSettings_leftColumnType === "assignment") {
        leftCol = "assignment";

        _.forEach(window.LbsAppData.Assignments.models, function (i) {
          // do _not_ trigger the filter yet
          i.attributes.available = false;
          i.attributes.order = undefined;

          // clear any possible colors
          i.attributes.color = undefined;
          i.attributes.colorText = undefined;
        });

        _.forEach(model.attributes.filter.on_assignments, function (i, index) {
          // views are saved with the assign_structure_id....this index isn't the same...
          // need to look at the structures_include_expired field (as expired could be == child structures via mapping)
          const matchingAssignments = window.LbsAppData.Assignments.filter(function (a) {
            return a.attributes.structures_include_expired.indexOf(i.id) > -1;
          });

          _.forEach(matchingAssignments, function (j) {
            // do _not_ trigger the filter yet
            j.attributes.available = true;
            j.attributes.order = index;

            // also colors
            j.attributes.color = i.color;
            j.attributes.colorText = i.colorText;
          });
        });

        // loop through the personnel and apply any colors from the view as well
        _.forEach(model.attributes.filter.on_personnel, function (i, index) {
          // do _not_ trigger the filter yet
          const personnel = window.LbsAppData.Personnel.get(i);

          if (personnel) {
            // order
            personnel.attributes.order = index;
            // colors
            personnel.attributes.color = i.color;
            personnel.attributes.colorText = i.colorText;
          }
        });
      } else if (model.attributes.theme.data.GridSettings_leftColumnType === "personnel") {
        leftCol = "personnel";

        _.forEach(window.LbsAppData.Personnel.models, function (i) {
          // do _not_ trigger the filter yet
          i.attributes.available = false;
          i.attributes.order = undefined;

          // clear any possible colors
          i.attributes.color = undefined;
          i.attributes.colorText = undefined;
        });

        _.forEach(model.attributes.filter.on_personnel, function (i, index) {
          // do _not_ trigger the filter yet
          const personnel = window.LbsAppData.Personnel.get(i);

          if (personnel) {
            personnel.attributes.available = true;
            personnel.attributes.order = index;

            // also colors
            personnel.attributes.color = i.color;
            personnel.attributes.colorText = i.colorText;
          }
        });

        // loop through the assignments and apply any colors from the view as well
        _.forEach(model.attributes.filter.on_assignments, function (i, index) {
          // views are saved with the assign_structure_id....this index isn't the same...
          // need to look at the structures_include_expired field (as expired could be == child structures via mapping)
          const matchingAssignments = window.LbsAppData.Assignments.filter(function (a) {
            return a.attributes.structures_include_expired.indexOf(i.id) > -1;
          });

          _.forEach(matchingAssignments, function (j) {
            j.attributes.order = index;

            // colors
            j.attributes.color = i.color;
            j.attributes.colorText = i.colorText;
          });
        });
      }

      // layout selection
      const useLayout = model.attributes.theme.data.layout === "grid" ? "grid" : "hybrid";

      // setup locale (with a view in context)...this is important to be HERE cause the redraw is triggered on the next
      // line with the view is set on this model
      this.setupLocale(model);

      // this is where the update will happen now
      this.set({
        view: model,
        layout: useLayout,
        GridSettings_leftColumnType: leftCol,
        compactMode: model.attributes.theme.data.compactMode,
      });

      this.updateContextualItem("view", {
        text: model.attributes.name,
        tooltip: "open view (" + model.attributes.name + ")",
      });

      // date manager
      window.LbsAppData.DateManager.setScheduleEndpoints(
        this.attributes.displayedSchedule.attributes.start_date,
        this.attributes.displayedSchedule.attributes.stop_date,
        model.attributes.theme.data.range === "week"
      );

      // update the attributes

      // flutter the tool
      window.LbsAppData.ToolBox.flutterTool();

      // if there is no default view set, then just set the default view to be whatever they just opened
      if (!noSetDefault && this.attributes.forceDefault) {
        if (window.LbsAppData.UserSettings === undefined) {
          if (model.is_legacy) {
            const newId = model.id + LEGACY_VIEW_ID_OFFSET;

            this.setDefaultView(newId);
          } else {
            this.setDefaultView(model.id);
          }
          this.set({ forceDefault: false });
        }
      }

      // calculate the slot colors
      window.LbsAppData.Slots._calculateColors();

      // resort the collection(s)
      window.LbsAppData.Personnel.comparator = window.LbsAppData.Helpers.Sort._getPersonnelComparator();
      window.LbsAppData.Personnel.sort();

      window.LbsAppData.Assignments.comparator = window.LbsAppData.Helpers.Sort._getAssignComparator();
      window.LbsAppData.Assignments.sort();

      window.LbsAppData.Slots.comparator = window.LbsAppData.Helpers.Sort._getSlotComparator();
      window.LbsAppData.Slots.sort();

      // trigger the redraw
      this.trigger("redraw");
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Load view error: ", e);
    } finally {
      window.LbsAppData.AppContext.closeLoadingDialog();
    }
  },

  closeView: function () {
    // take everything out of context
    _.forEach(window.LbsAppData.Assignments.models, function (i) {
      i.set(
        {
          available: true,
          order: i.attributes.display_name.toLowerCase(),
        },
        { silent: true }
      );
    });
    window.LbsAppData.Assignments.sort();

    _.forEach(window.LbsAppData.Personnel.models, function (i) {
      i.set(
        {
          available: true,
          order: i.attributes.display_name.toLowerCase(),
        },
        { silent: true }
      );
    });
    window.LbsAppData.Personnel.sort();

    // send a tracking event
    window.LbsAppData.Helpers.Analytics.sendEvent("Close: View", this.attributes.view.id);

    this.set({
      view: undefined,
      layout: "hybrid",
      GridSettings_leftColumnType: "assignment",
      compactMode: "no",
    });

    this.updateContextualItem("view", { text: "No View" });

    // date manager
    window.LbsAppData.DateManager.setScheduleEndpoints(
      this.attributes.displayedSchedule.attributes.start_date,
      this.attributes.displayedSchedule.attributes.stop_date,
      false
    );

    // flutter the tool
    window.LbsAppData.ToolBox.flutterTool();

    // resort the slot collection
    window.LbsAppData.Slots.comparator = window.LbsAppData.Helpers.Sort._getSlotComparator();
    window.LbsAppData.Slots.sort();

    // trigger the redraw
    this.trigger("redraw");
  },

  annoyDefaultView: function () {
    const that = this;

    this.openDialog({
      type: "prompt",
      title: "No Default View",
      message:
        "We notice you do not have a default view for this template. We suggest you set one so your experience is as streamlined as possible.",
      yesText: "Pick one",
      noText: "Cancel",
      callback: function () {
        window.LbsAppData.AppContext.closeDialog();
        window.LbsAppData.AppContext.openDialog({ type: "options" });
        that.set({ forceDefault: true });
      },
    });
  },

  setDefaultView: function (view_id, callback) {
    // an update to the current default view
    if (window.LbsAppData.UserSettings) {
      window.LbsAppData.UserSettings.set({
        default_view: view_id,
        template_id: window.LbsAppData.AppContext.attributes.displayedSchedule.attributes.template_id,
      });

      window.LbsAppData.UserSettings.save(null, {
        success: function () {
          if (callback) {
            callback();
          }
        },
      });
      // the first default view saved (for the template in the case of editor)
    } else {
      const attributes = {
        default_view: view_id,
        template_id: window.LbsAppData.AppContext.attributes.displayedSchedule.attributes.template_id,
      };

      window.LbsAppData.UserSettingsCollection.create(attributes, {
        success: function (model) {
          window.LbsAppData.UserSettings = model;
          if (callback) {
            callback();
          }
        },
      });
    }
  },

  reset: function () {
    this.set({
      displayedSchedule: undefined,
    });
  },

  updateScheduleContext: function (schedObj) {
    this.set({
      displayedSchedule: schedObj,
    });

    // push the context
    window.LbsAppData.AppContext.pushContext({
      tag: "schedule",
      text: schedObj.attributes.schedule_name,
      tooltip: "open schedule",
      action: function () {
        window.LbsAppData.AppContext.openDialog({ type: "open" });
      },
    });
    // push a blank view context
    window.LbsAppData.AppContext.pushContext({
      tag: "view",
      text: "No View",
      tooltip: "open view/filters",
      action: function () {
        window.LbsAppData.AppContext.openDialog({ type: "options" });
      },
    });

    // flutter the current tool, its most certainly boned
    window.LbsAppData.ToolBox.flutterTool();
    // clear out any tally data, cause if you switched templates you are screwed
    window.LbsAppData.TallyDataMap.clear();
  },

  getLeftColumnType: function () {
    return window.LbsAppData.AppContext.attributes.GridSettings_leftColumnType;
  },
  getLeftColumnCollection: function () {
    if (this.getLeftColumnType() === "assignment") {
      return {
        collection: window.LbsAppData.Assignments,
        title: window.LbsAppData.Assignments.attributes.title,
        models: window.LbsAppData.Assignments.models,
      };
    } else {
      return {
        collection: window.LbsAppData.Personnel,
        title: window.LbsAppData.Personnel.attributes.title,
        models: window.LbsAppData.Personnel.where({ scheduled: true }),
      };
    }
  },
  // the opposite of above
  getInnerCollection: function () {
    if (this.getLeftColumnType() === "assignment") {
      return {
        collection: window.LbsAppData.Personnel,
        title: window.LbsAppData.Personnel.attributes.title,
        models: window.LbsAppData.Personnel.where({ scheduled: true }),
      };
    } else {
      return {
        collection: window.LbsAppData.Assignments,
        title: window.LbsAppData.Assignments.attributes.title,
        models: window.LbsAppData.Assignments.models,
      };
    }
  },
  getLeftColumnCollectionExcludeExpired: function () {
    if (this.getLeftColumnType() === "assignment") {
      return {
        collection: window.LbsAppData.Assignments,
        title: window.LbsAppData.Assignments.attributes.title,
        models: window.LbsAppData.Assignments.where({ expired: false }),
      };
    } else {
      return {
        collection: window.LbsAppData.Personnel,
        title: window.LbsAppData.Personnel.attributes.title,
        models: window.LbsAppData.Personnel.where({
          scheduled: true,
          expired: false,
        }),
      };
    }
  },

  loginRefreshWithSession: function (callback) {
    this.session._syncBackbone(callback);
  },

  loginWithToken: function (token, callback) {
    // used for application that forward a token to lblite..they won't have a refresh token in this case
    // so currently...this only makes sense to use for LB internal applications
    Backbone.sync = function (method, model, options) {
      options.headers = options.headers || {};
      _.extend(options.headers, { Authorization: "Bearer " + token });
      _sync(method, model, options);
    };

    callback();
  },
});

module.exports = AppContext;
