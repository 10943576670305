import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { DisplayDepartmentNames, UIContext } from "@/viewer/ui/modules/common/types/context";

interface MaskToPxHeightMap {
  [key: number]: number;
}

const slotWillDisplayOriginalEmp = (slot: SlotOrRequest): boolean => {
  const originalEmpId = slot?.originalEmpId ? (slot.originalEmpId as number) : null;

  return !!originalEmpId;
};

const slotWillDisplayDepartmentName = (slot: SlotOrRequest): boolean => {
  const deptId = slot?.departmentId ? (slot.departmentId as number) : null;

  return !!deptId;
};

export const calculateSlotQuestHeight = (ui: UIContext, slot: SlotOrRequest): number => {
  const isDisplayingOriginalEmp = ui.showOriginalPersonnel && slotWillDisplayOriginalEmp(slot);
  const isDisplayingDepartmentNames =
    ui.displayDepartmentNames &&
    ui.displayDepartmentNames !== DisplayDepartmentNames.NONE &&
    slotWillDisplayDepartmentName(slot);
  const isDisplayingTimes = ui.showTimes && Boolean(slot.startTime);

  const deptOptsBitMask = [
    Number(isDisplayingTimes ?? 0),
    Number(isDisplayingOriginalEmp ?? 0),
    Number(isDisplayingDepartmentNames ?? 0),
  ];

  const result = Number.parseInt(deptOptsBitMask.join(""), 2);

  const bitMaskToPxHeightMap: MaskToPxHeightMap = {
    0: 21, // no options selected
    1: 35, // only departmentNames
    2: 30, // only original personnel
    3: 45, // original personnel and department names
    4: 35, // only times
    5: 49, // times and department names
    6: 46, // times and original personnel
    7: 58, // all options selected
  };

  return bitMaskToPxHeightMap[result];
};

export const calculateSlotQuestCellHeight = (ui: UIContext, slots: SlotOrRequest[]): number => {
  if (slots.length == 0) {
    return 0;
  }

  return slots.reduce((total, slot) => total + calculateSlotQuestHeight(ui, slot), 1);
};
