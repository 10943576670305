const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var Personnel = require("./../models/Personnel.js");

var PersonnelCollection = Backbone.Collection.extend({
  model: Personnel,

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }

    this.attributes.title = "Personnel";
  },

  comparator: function (a, b) {
    var orderA = a.attributes.order;
    var orderB = b.attributes.order;

    // if any of these is an integer (as opposed to a string) then it comes first
    if (typeof orderA === "number" && typeof orderB === "string") {
      return -1;
    } else if (typeof orderB === "number" && typeof orderA === "string") {
      return 1;
    }

    // must be same type, so do normal int, string comparisons
    if (orderA == orderB) {
      return 0;
    } else {
      if (orderA < orderB) {
        return -1;
      } else {
        return 1;
      }
    }
  },

  url: function () {
    return ApiConfig.getPrefix() + "/personnel?dept_list=" + this.attributes.deptList;
  },
  //parse: function(response) {
  //	return response.data;
  //}

  toggleFilteredModels: function (ids, value) {
    for (var i = 0; i < ids.length; i++) {
      var model = this.get(ids[i]);

      if (value === undefined) {
        // toggle it
        model.set({ filtered: !model.attributes.filtered });
        this.trigger("change:filteredModel");
      } else {
        // set it explicitly (if it needs to be)
        if (model.attributes.filtered != value) {
          model.set({ filtered: value });
          // trigger an event
          this.trigger("change:filteredModel");
        }
      }
    }
  },

  filterAll: function () {
    this.forEach(function (item) {
      item.set({ filtered: true });
      //item.attributes.filtered = true;
    });

    // trigger an event
    this.trigger("change:filteredModel");
  },
  unfilterAll: function () {
    this.forEach(function (item) {
      item.set({ filtered: false });
      //item.attributes.filtered = false;
    });

    // trigger an event
    this.trigger("change:filteredModel");
  },

  resetOrdering: function () {
    this.forEach(function (item) {
      item.set({ order: item.attributes.display_name });
    });
  },
});

module.exports = PersonnelCollection;
