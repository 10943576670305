const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var TallyDataByDate = require("./../models/TallyDataByDate.js");
var TallyDataByEmp = require("./../models/TallyDataByEmp.js");
var TallyDataByTally = require("./../models/TallyDataByTally.js");

var TallyDataCollection = Backbone.Collection.extend({
  model: function (attributes, options) {
    switch (options.tallyType) {
      case "date":
        return new TallyDataByDate(attributes, options);
      case "emp":
        return new TallyDataByEmp(attributes, options);
      case "tally":
        return new TallyDataByTally(attributes, options);
      default:
        break;
    }
  },

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },
});

module.exports = TallyDataCollection;
