const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");

var AssignmentStructure = Backbone.Model.extend({
  urlRoot: ApiConfig.getPrefix() + "/assignments/structure",

  _getAssignmentDefaultStartTime: function (template_id) {
    var returnVal = undefined;
    if (this.attributes.template_to_default_times_map[template_id] !== undefined) {
      if (this.attributes.template_to_default_times_map[template_id].split("~").length > 1) {
        returnVal = this.attributes.template_to_default_times_map[template_id].split("~")[0];
      }
    }
    return returnVal;
  },
});

module.exports = AssignmentStructure;
