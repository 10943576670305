import React from "react";
import classnames from "@/_lib/utils/classnames";

import "./_styles.scss";

export enum PrintType {
  PageSpacing,
  Minimal,
}

export enum PrintOrientation {
  Landscape = "landscape",
  Portrait = "portrait",
}

export enum PageSize {
  A4 = "A4",
  Letter = "letter",
}

export type PageScale = number;

//dimensions at 96 PPI
export const PageSizeInPxDimensionsMap: Record<PageSize, { pageWidth: number; pageHeight: number }> = {
  [PageSize.A4]: { pageWidth: 794, pageHeight: 1193 },
  [PageSize.Letter]: { pageWidth: 816, pageHeight: 1056 },
};

type PageDimensions = { height: number; width: number };

// //4 mm to pixels
export const PRINT_MARGIN_MM_IN_PIXEL_WIDTH = 15;

//12 mm to pixels
export const PRINT_MARGIN_MM_IN_PIXEL_HEIGHT = 45;

export const getPageDimensionsFromOrientationAndPageSize = (
  pageSize: PageSize,
  orientation: PrintOrientation
): PageDimensions => {
  const { pageWidth, pageHeight } = PageSizeInPxDimensionsMap[pageSize];
  let [height, width] = [pageHeight, pageWidth];

  [height, width] = orientation === PrintOrientation.Landscape ? ([height, width] = [width, height]) : [height, width];

  return { height, width };
};

interface BaseProps {
  children: React.ReactNode;
  showOutsidePrintPreview?: boolean;
  printType?: PrintType;
  orientation?: PrintOrientation;
  pageSize?: PageSize;
  scale?: number;
}

type SupportedColumnsView = { columnsOrCondensedView: boolean; supportsColumnPrinting: boolean };
type UnsupportedColumnsView = { columnsOrCondensedView?: never; supportsColumnPrinting?: never };
type Props = (BaseProps & SupportedColumnsView) | (BaseProps & UnsupportedColumnsView);

export default React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      showOutsidePrintPreview = true,
      printType = PrintType.PageSpacing,
      orientation = PrintOrientation.Portrait,
      pageSize = PageSize.Letter,
      columnsOrCondensedView = false,
      supportsColumnPrinting = false,
      scale = 1,
    }: Props,
    ref
  ): JSX.Element => {
    const rootClassNames = classnames("print-container", {
      "print-container--outside": showOutsidePrintPreview,
    });

    const getPageDescriptor = (): JSX.Element => {
      scale = scale >= 1 ? 1 : scale;

      let [pageHeight, pageWidth] = [
        PageSizeInPxDimensionsMap[pageSize].pageHeight,
        PageSizeInPxDimensionsMap[pageSize].pageWidth,
      ];

      if (orientation === PrintOrientation.Landscape) {
        [pageHeight, pageWidth] = [pageWidth, pageHeight];
      }

      const descriptor = `@media print{html {transform-origin: "top left", transform: scale(${scale})} @page {size: ${pageSize} ${orientation}}}`;

      return <style type="text/css">{descriptor}</style>;
    };

    const printContainerClassNames = classnames("print-container-content", {
      "print-container-content--page-spacing": printType === PrintType.PageSpacing,
      "print-container-content--minimal": printType === PrintType.Minimal,
      [`print-container--orientation-${orientation}`]: true,
    });

    return (
      <>
        <div className={rootClassNames}>
          <div className={printContainerClassNames} ref={ref}>
            {columnsOrCondensedView && supportsColumnPrinting ? getPageDescriptor() : null}
            {children}
          </div>
        </div>
      </>
    );
  }
);
