/**
 * Wrap common useFilteredRequestData to return the expected format
 */
import { appendToPath2 } from "@/viewer/utils/helpers";
import { RequestsByDateByName } from "@/viewer/ui/modules/common/types";
import { DataContext, FilterContext, SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import useFilteredRequestData from "@/viewer/ui/modules/common/hooks/useFilteredRequestData";
import { useMemo } from "react";
import { getLeftColumnId } from "@/viewer/utils/domain/slotquests";
import { Collection } from "backbone";

const useColumnRequestData = (
  settings: SettingsContext,
  ui: UIContext,
  filters: FilterContext,
  data: DataContext,
  requestCollection: Collection
): RequestsByDateByName => {
  const requests = useFilteredRequestData(settings, ui, filters, data, requestCollection);

  return useMemo(() => {
    const out: RequestsByDateByName = {};
    requests.forEach((request) => {
      if (request.date >= ui.startDateWithCushion && request.date <= ui.stopDateWithCushion) {
        const columnId = getLeftColumnId(ui, request);
        const requestDate = request.dateString;
        appendToPath2(out, [columnId, requestDate], request);
      }
    });
    return out;
  }, [requests, settings]);
};

export default useColumnRequestData;
