const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");

var Permissions = Backbone.Model.extend({
  // User model..but we get most (all) of this data from the permissions endpoint
  urlRoot: ApiConfig.getPrefix() + "/permissions",

  isS1Client: function () {
    return this.attributes.is_s1_client;
  },
});

module.exports = Permissions;
