import ConfigHelper from "@/_lib/data/ConfigHelper";
import useRequestCollection from "@/_lib/hooks/useRequestCollection";
import useSlotCollection from "@/_lib/hooks/useSlotCollection";
import { post } from "@/_lib/utils/fetch";
import { isItemInView } from "@/_lib/utils/slotUtils";
import Button from "@/common/components/button/Button";
import ButtonGroup from "@/common/components/button/button-group/ButtonGroup";
import modalActions from "@/common/components/modal/actions";

import Modal, { MaxWidth } from "@/common/components/modal/Modal";
import toastActions from "@/common/components/toast/actions";
import Toast, { ToastStatus } from "@/common/components/toast/Toast";
import { FetchReport, TriggerRedraw } from "@/common/utils";
import { Assignment } from "@/viewer/types/domain/assignment";
import { CallOrder } from "@/viewer/types/domain/callOrder";
import { Location } from "@/viewer/types/domain/location";
import { Personnel } from "@/viewer/types/domain/personnel";
import { Reason } from "@/viewer/types/domain/reason";
import { cleanRequestAttributes, RequestAttributes } from "@/viewer/types/domain/request";
import { ResponseData } from "@/viewer/types/domain/response";
import { cleanSlotAttributes, SlotAttributes } from "@/viewer/types/domain/slot";
import { Template } from "@/viewer/types/domain/template";
import useCurrentlyLoggedInUser from "@/viewer/ui/modules/common/hooks/useCurrentlyLoggedInUser";
import useSettings from "@/viewer/ui/modules/common/hooks/useSettings";
import useTemplatesById from "@/viewer/ui/modules/common/hooks/useTemplatesById";
import useUIContext from "@/viewer/ui/modules/common/hooks/useUIContext";
import useViewData from "@/viewer/ui/modules/common/hooks/useViewData";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { Box, Grid, Popper } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Formik, FormikHelpers, setNestedObjectValues } from "formik";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import _ from "underscore";
import * as Yup from "yup";
import ApiResponse from "./ApiResponse";
import Content from "./Content";
import { createPayload, transformTemplates } from "./helpers";
import Preview from "./Preview";
import styles from "./styles.module.scss";

export interface ErrorOrWarning {
  date: string;
  message: string;
}

interface SubmitOptions {
  shouldLeaveDialogOpen?: boolean;
  forceThrough?: boolean;
}

export interface FormValues {
  template: Template;
  personnel: Personnel[];
  assignment: Assignment[];
  date: moment.Moment[];
  shiftStart: null | moment.Moment;
  shiftEnd: null | moment.Moment;
  reason: null | Reason;
  locations: null | undefined | Location | Location[];
  note: string;
  addDemandIfNecessary: boolean;
  callOrder: CallOrder | null | undefined;
}

const AddShiftModal = (): React.ReactElement => {
  const settings = useSettings();
  const viewData = useViewData(settings);
  const [uiContext] = useUIContext();
  const user = useCurrentlyLoggedInUser();
  const slotCollection = useSlotCollection();
  const requestCollection = useRequestCollection();
  const templatesById = useTemplatesById();
  const dispatch = useDispatch();

  const [apiErrors, setApiErrors] = React.useState<ErrorOrWarning[]>([]);
  const [apiWarnings, setApiWarnings] = React.useState<ErrorOrWarning[]>([]);
  const [apiSuccesses, setApiSuccesses] = React.useState<SlotOrRequest[]>([]);
  const [shouldShowPreview] = React.useState(false);
  const [loading, setLoading] = React.useState({ firstAction: false, secondAction: false });
  const [popperAnchorEl, setPopperAnchorEl] = React.useState<HTMLElement | null>(null);

  const templates = transformTemplates(templatesById);
  const currentViewTemplateId = settings.view?.filter?.on_templates?.[0];
  const defaultTemplate = templates.find(({ id }) => id === currentViewTemplateId) ?? templates[0];

  const handleWarnings = (data: ResponseData | null) => {
    if (data?.warnings?.length) {
      setApiWarnings(data.warnings);
    } else {
      setApiWarnings([]);
    }
  };

  const handleErrors = (data: ResponseData | null) => {
    if (data?.errors?.length) {
      setApiErrors(data.errors);
    } else if (!data?.warnings?.length) {
      setApiErrors([]);
    }
  };

  const handleSuccesses = async (data: ResponseData | null, options: SubmitOptions, isFill: boolean) => {
    if (data?.slots?.length || data?.requests?.length) {
      let willItemBeShown = false;
      const slots = (data.slots ?? []) as SlotAttributes[];
      const requests = (data.requests ?? []) as RequestAttributes[];
      const currentRequest = requests[0];
      const currentSlot = slots[0];

      // When adding a shift, we must check if the fill is in view definition
      // A fill is in View Definition if the request date is within view's date range, selected assignment is within View definition and selected personnel is within View Definition
      //If any of the above conditions is not true, it means that the fill is not in ViewDefinition
      const shouldAddRequestToCollection =
        currentRequest && Object.keys(currentRequest).length && isItemInView(currentRequest, viewData, uiContext);

      const shouldAddSlotToSlotCollection =
        currentSlot && Object.keys(currentSlot).length && isItemInView(currentSlot, viewData, uiContext);

      willItemBeShown = !!shouldAddSlotToSlotCollection || !!shouldAddRequestToCollection;

      //Check if slot should be added
      if (shouldAddRequestToCollection) {
        if (requests.length && requestCollection) requestCollection.add(requests);
      }

      if (shouldAddSlotToSlotCollection) {
        if (slots.length && slotCollection) slotCollection.add(slots);
      }

      if (!data.errors?.length) {
        await FetchReport();

        if (!options.shouldLeaveDialogOpen) dispatch(modalActions.close());

        TriggerRedraw();

        return dispatch(
          toastActions.open(
            <Toast status={ToastStatus.Success}>
              Your {isFill ? "fill" : "request"} was successfully created
              {willItemBeShown ? "." : " but is not currently visible."}
            </Toast>
          )
        );
      } else {
        setApiSuccesses(slots.map((s) => cleanSlotAttributes(settings, s)));
        setApiSuccesses(requests.map((r) => cleanRequestAttributes(settings, r)));
      }
    } else if (!data?.warnings?.length) {
      setApiSuccesses([]);
    }
  };

  const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>, options: SubmitOptions = {}) => {
    const errors = await helpers.validateForm();
    const fieldsToTouch = setNestedObjectValues<{ [key: string]: boolean }>(errors, true);
    // noinspection JSVoidFunctionReturnValueUsed
    const outstandingErrors = await helpers.setTouched(fieldsToTouch, true);

    if (!_.isEmpty(outstandingErrors)) return;

    setLoading({
      firstAction: options.shouldLeaveDialogOpen ?? false,
      secondAction: !options.shouldLeaveDialogOpen,
    });

    const isFill = values.date.some((date) => date.isBefore(values.template.requestWindowStart, "day"));
    const shiftType = isFill ? "fill" : "new";
    const url = isFill ? `${ConfigHelper.host_lbapi}/schedule/update` : `${ConfigHelper.host_lbapi}/request`;
    const payload = createPayload(values, shiftType, options.forceThrough);
    const response = await post<ResponseData>(url, payload);

    if (response.error) {
      setApiErrors([]);
      setApiWarnings([]);
      setApiSuccesses([]);
      setLoading({
        firstAction: false,
        secondAction: false,
      });

      return dispatch(
        toastActions.open(
          <Toast status={ToastStatus.Error}>
            {response.error.message || "An unexpected error has occurred. Please refresh and try again"}
          </Toast>
        )
      );
    }

    await handleWarnings(response.data);
    await handleErrors(response.data);
    await handleSuccesses(response.data, options, isFill);

    setLoading({
      firstAction: false,
      secondAction: false,
    });
  };

  const initialValues: FormValues = {
    template: defaultTemplate,
    personnel: [],
    assignment: [],
    date: [],
    shiftStart: null,
    shiftEnd: null,
    reason: null,
    locations: undefined,
    note: "",
    addDemandIfNecessary: false,
    callOrder: null,
  };

  const validationSchema = Yup.object().shape({
    template: Yup.object().required("Must select template"),
    personnel: Yup.array()
      .of(Yup.object())
      .min(1, "Must select at least 1 personnel")
      .required("Must select personnel"),
    assignment: Yup.array()
      .of(Yup.object())
      .min(1, "Must select at least 1 assignment")
      .required("Must select assignment"),
    date: Yup.array()
      .of(Yup.object().test("DT", "Invalid date format", (value) => moment.isMoment(value) && value.isValid()))
      .min(1, "Must select at least 1 date")
      .required("Must select date"),
    shiftStart: Yup.lazy(() =>
      Yup.object().when(["shiftEnd"], {
        is: (value: null | moment.Moment) => !!value,
        then: Yup.object()
          .nullable()
          .required("Must select shift start")
          .test("SS", "Invalid time format", (value) => moment.isMoment(value) && value.isValid()),
        otherwise: Yup.object()
          .nullable()
          .test("SS", "Invalid time format", (value) => value === null || (moment.isMoment(value) && value.isValid())),
      })
    ),
    shiftEnd: Yup.lazy(() =>
      Yup.object().when(["shiftStart"], {
        is: (value: null | moment.Moment) => !!value,
        then: Yup.object()
          .nullable()
          .required("Must select shift end")
          .test("SE", "Invalid time format", (value) => moment.isMoment(value) && value.isValid()),
        otherwise: Yup.object()
          .nullable()
          .test("SE", "Invalid time format", (value) => value === null || (moment.isMoment(value) && value.isValid())),
      })
    ),
    reason: Yup.object().nullable().optional(),
    locations: Yup.mixed().test(
      "LO",
      "Must select locations",
      (v) => (Array.isArray(v) && v.length > 0) || (!Array.isArray(v) && v !== null)
    ),
    note: Yup.string().optional(),
    addDemandIfNecessary: Yup.boolean().required("Required"),
    callOrder: Yup.object().optional(),
  });

  return (
    <Formik
      onSubmit={(values, helpers: FormikHelpers<FormValues>) => handleSubmit(values, helpers)}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ values, ...helpers }) => (
        <Modal
          id="add-shift-modal"
          title="Add Shift"
          draggable
          fullWidth
          maxWidth={shouldShowPreview ? MaxWidth.Md : MaxWidth.Sm}
          disableClickAway={loading.firstAction || loading.secondAction || apiWarnings.length > 0}
          positiveAction={
            <ButtonGroup onClick={(e: React.MouseEvent<HTMLElement>) => setPopperAnchorEl(e.currentTarget)}>
              <Button
                id="group-action-1"
                key="group-action-1"
                onClick={() => handleSubmit(values, helpers, { shouldLeaveDialogOpen: true })}
                loading={loading.firstAction && !apiWarnings.length}
                disabled={apiWarnings.length > 0 || loading.secondAction}
                color="default"
                variant="filled"
              >
                Add Another
              </Button>
              <Button
                id="group-action-2"
                key="group-action-2"
                onClick={() => handleSubmit(values, helpers)}
                loading={loading.secondAction && !apiWarnings.length}
                disabled={apiWarnings.length > 0 || loading.firstAction}
                color="primary"
                variant="filled"
              >
                Submit
              </Button>
            </ButtonGroup>
          }
        >
          <Grid className={styles.root} container>
            <Grid className={styles.content} item xs={12} sm={shouldShowPreview ? 8 : 12}>
              <div className={styles.contentItems}>
                <Content
                  settings={settings}
                  viewData={viewData}
                  uiContext={uiContext}
                  user={user}
                  templates={templates}
                />
                <ApiResponse errors={apiErrors} successes={apiSuccesses} />
              </div>
            </Grid>
            {shouldShowPreview && (
              <Grid className={styles.preview} item xs={12} sm={4}>
                <div className={styles.previewItems}>
                  <Preview />
                </div>
              </Grid>
            )}
          </Grid>

          {/** Warning popper */}
          <Popper
            open={!!popperAnchorEl && apiWarnings.length > 0}
            anchorEl={popperAnchorEl}
            placement="top-end"
            transition
            disablePortal
          >
            <Box boxShadow={6} borderRadius={6}>
              <Alert className={`${styles.alertBox} ${styles.warningBox}`} severity="warning">
                <AlertTitle>The following warnings were found:</AlertTitle>
                <ul>
                  {apiWarnings.map(({ date, message }, i) => (
                    <li key={`${i}-${date}-${message}`}>
                      {moment(date).format("MMM DD, YYYY")} - {message}
                    </li>
                  ))}
                </ul>
                <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                  <Grid item>
                    <Button variant="text" onClick={() => setApiWarnings([])}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => handleSubmit(values, helpers, { forceThrough: true, shouldLeaveDialogOpen: true })}
                      loading={Object.values(loading).some((l) => l)}
                      color="primary"
                      variant="text"
                    >
                      Accept
                    </Button>
                  </Grid>
                </Grid>
              </Alert>
            </Box>
          </Popper>
        </Modal>
      )}
    </Formik>
  );
};

export default AddShiftModal;
