const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");

var User = Backbone.Model.extend({
  // User model..but we get most (all) of this data from the permissions endpoint
  urlRoot: ApiConfig.getPrefix() + "/permissions",
  idAttribute: "emp_id",

  initialize: function () {},

  isScheduled: function () {
    var thisPerson = window.LbsAppData.MyPersonnel;
    if (thisPerson !== undefined) {
      return thisPerson.attributes.scheduled;
    }
    return false;
  },

  isS1Client: function () {
    return this.attributes.is_s1_client;
  },
});

module.exports = User;
