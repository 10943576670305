import { DialogStage, SlotData } from "@/_lib/ui/modules/dialog/types/schedule_edits/swaps/move/types";
import { ISO_8601_DATE_FORMAT } from "@/_lib/utils/time";
import toastActions from "@/common/components/toast/actions";
import Toast, { ToastStatus } from "@/common/components/toast/Toast";
import { Box, Button } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";

interface FooterControlsProps {
  dialogStage: number;
  setDialogStage: (dialogStage: number) => void;
  handleSubmit: () => void;
  isLoading: boolean;
  slotData: SlotData;
}

const FooterControls = (props: FooterControlsProps): JSX.Element => {
  const { dialogStage, setDialogStage, handleSubmit, isLoading, slotData } = props;
  const dispatch = useDispatch();

  const verifyStage = (callback: () => void): void => {
    if (dialogStage === DialogStage.Start) {
      if (slotData.selectedDate.toFormat(ISO_8601_DATE_FORMAT) === slotData.dateString) {
        dispatch(
          toastActions.open(
            <Toast status={ToastStatus.Error}>
              <span>{"Please select a different date."}</span>
            </Toast>
          )
        );

        return;
      }
    }

    callback();
  };

  return (
    <Box className={"moveDialog-footerContainer"}>
      <Button
        className={"footerContainer-button__back"}
        onClick={() => verifyStage(() => setDialogStage(dialogStage - 1))}
        disabled={dialogStage === DialogStage.Start}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className={"fa fa-arrow-left"} />
          Back
        </div>
      </Button>
      {dialogStage === DialogStage.Last ? (
        <Button className={"footerContainer-button__submit"} onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? (
            <span className="fa fa-fw fa-spin fa-spinner" />
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className={"fa fa-check"} />
              Submit
            </div>
          )}
        </Button>
      ) : (
        <Button
          className={"footerContainer-button__next"}
          onClick={() => verifyStage(() => setDialogStage(dialogStage + 1))}
          disabled={dialogStage === DialogStage.Last}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className={"fa fa-arrow-right"} />
            Next
          </div>
        </Button>
      )}
    </Box>
  );
};

export default FooterControls;
