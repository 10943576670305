const Backbone = require("backbone");
var ApiConfig = require("_lib/data/ApiConfig.js");
var View = require("./../models/View.js");

var ViewCollection = Backbone.Collection.extend({
  model: View,
  comparator: "name",

  initialize: function (models, attributes) {
    this.attributes = {};
    for (var key in attributes) {
      this.attributes[key] = attributes[key];
    }
  },

  url: function () {
    // views will only ever be for the template that the current schedule is in
    return ApiConfig.getPrefix() + "/views?template_list=" + this.attributes.templateList;
  },
});

module.exports = ViewCollection;
