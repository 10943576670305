"use strict";

// scss requires
require("./_styles.scss");
// dependencies
var React = require("react/addons");
var moment = require("moment");

// collections
// mixins
var TouchMixin = require("_lib/ui/mixins/TouchMixin.jsx");
var SwapMixin = require("_lib/ui/mixins/SwapMixin.jsx");

var RequestActionDialog = React.createClass({
  mixins: [TouchMixin, SwapMixin],

  /* lifecycle methods */
  getInitialState: function () {
    return {
      step: 1,
      initStep: 1,
    };
  },
  componentDidMount: function () {
    var that = this;

    // send a tracking event
    if (!this.props.inline) {
      window.LbsAppData.Helpers.Analytics.sendEvent("Open: Dialog", "Request: Pending");
    }
  },

  /* privates */
  _getTotalSteps: function () {
    return 1;
  },
  _getTitleText: function () {
    var action = this.props.getData("pendingAction");
    return action.charAt(0).toUpperCase() + action.substr(1);
  },
  /* publics */

  /* render */
  render: function () {
    /* jshint ignore:start */
    const actionType = this.props.getData("pendingAction");

    var classes = React.addons.classSet({
      Dialog: true,
      isTop: this.props.isTop,
      RequestAction: true,
      grant: actionType == window.LbsAppData.GRANT_ACTION,
      deny: actionType == window.LbsAppData.DENY_ACTION,
      suspend: actionType == window.LbsAppData.SUSPEND_ACTION,
      delete: actionType == window.LbsAppData.DELETE_ACTION,
    });

    var content;
    if (!this.props.inline) {
      content = (
        <div className={classes} data-cy="RAWindowPrompt">
          <div className="position-reset">
            <div
              className="close"
              onClick={this.touchProxy.bind(this, this.props.close, [])}
              onTouchEnd={this.touchProxy.bind(this, this.props.close, [])}
            >
              <i className="fa fa-close" />
            </div>

            <div className="header-container">
              <div className="header">
                <div className="mobile-section-nav">
                  <div className="mobile-section-nav-tab active">{this._getTitleText()}</div>
                </div>
              </div>
            </div>

            <div className="section-container">
              <div className={"section _" + this.state.step}>{this._renderStep1(actionType)}</div>
            </div>

            <div className="bottom-controls">
              <div className="position-reset">
                <div className="left-side">{this._getBottomLeftControl(this._getTotalSteps())}</div>
                <div className="right-side">{this._getBottomRightControl(this._getTotalSteps())}</div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      content = <div className="SwapPending">{this._renderInline()}</div>;
    }

    return content;
  },
  /* rendering functions */
  _renderStep1: function (actionType) {
    var swapSentence = this.props.widgetProxy("sentenceSwap");
    var noteElement = this.props.widgetProxy("addNote");
    var denialElement = this.props.widgetProxy("selectDenialReason");
    var locationElement = this.props.widgetProxy("selectLocations");
    var loaReasonsElement = this.props.widgetProxy("selectLoaReason");

    return (
      <div className="content">
        <div className="details">
          <div className="sentence-container">{swapSentence}</div>
        </div>
        <div className="editables">
          {noteElement}
          {actionType !== window.LbsAppData.DENY_ACTION && loaReasonsElement}
          {actionType === window.LbsAppData.DENY_ACTION && denialElement}
          {actionType !== window.LbsAppData.DELETE_ACTION && locationElement}
        </div>
      </div>
    );
  },
  _renderInline: function () {
    var noteElement = this.props.widgetProxy("addNote");
    var denialElement = this.props.widgetProxy("selectDenialReason");

    return (
      <div className="inline-container">
        <div className="editables">
          <div className="spacer"></div>
          {noteElement}
          {denialElement}
        </div>
      </div>
    );
  },
});

module.exports = RequestActionDialog;
