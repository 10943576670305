"use strict";

var React = require("react/addons");
var $ = require("jquery");
var moment = require("moment");

var DatePickerCalendarDate = require("./DatePickerCalendarDate.jsx");
var DatePickerCalendarHeader = require("./DatePickerCalendarHeader.jsx");

const fitsInBounds = (date, lowerBound, upperBound) => {
  return (
    (date.isAfter(lowerBound) || date.isSame(lowerBound, "day")) &&
    (date.isBefore(upperBound) || date.isSame(upperBound, "day"))
  );
};

var DatePickerCalendar = React.createClass({
  /* privates */

  /* publics */

  /* lifecycle methods */

  render: function () {
    const {
      month,
      selectDate,
      selectedDates,
      rangeStart,
      rangeStop,
      lowerBound,
      upperBound,
      blockOptions = {},
    } = this.props;

    const { dayRange: blockDayRange, startWeekDay: blockStartWeekDay, length: blockLength } = blockOptions;

    const classes = React.addons.classSet({
      DatePickerCalendar: true,
    });

    // container to push date boxes relative to the day of the start of the month
    const offsetContainer = <div className={"datepicker-calendar-offset _" + month.day()} />;

    // calendar date boxes
    const calendarDates = [];
    for (let i = 1; i <= month.daysInMonth(); i++) {
      const dateRep = moment(month).date(i).startOf("day");

      const isShownDate = selectedDates.hasOwnProperty(dateRep.format("YYYYMMDD"));

      const isInBounds = !lowerBound ? true : fitsInBounds(dateRep, lowerBound, upperBound);

      const isInBlockBounds = !blockLength
        ? true
        : (() => {
            // Grab the current day in the loop, and find the date within that week
            // where the block starts
            const currentDayOfWeek = dateRep.day();
            const weekDayDifference = blockDayRange.indexOf(currentDayOfWeek);
            const currentWeekBlockStart = dateRep
              .clone()
              .subtract(blockStartWeekDay === -1 ? 0 : weekDayDifference, "days");

            // Based on the now known block area, ensure all dates within the
            // area fit within the lower and upper bounds
            return _.range(0, blockLength).every((i) => {
              const date = currentWeekBlockStart.clone().add(i, "days");
              return fitsInBounds(date, lowerBound, upperBound);
            });
          })();

      const isInBlockRange = !blockLength || blockStartWeekDay === -1 ? true : blockDayRange.includes(dateRep.day());

      const isSelectable = isInBounds && isInBlockRange && isInBlockBounds;

      const isSameAsStart = rangeStart && dateRep.isSame(rangeStart, "days");

      const isSameAsStop = rangeStop && dateRep.isSame(rangeStop, "days");

      const isInRange = rangeStop && dateRep.isAfter(rangeStart, "days") && dateRep.isBefore(rangeStop, "days");

      const isSelected = isSameAsStart || isSameAsStop || isInRange || isShownDate;

      const item = (
        <DatePickerCalendarDate
          key={i}
          date={moment(dateRep)}
          unselectable={!isSelectable}
          selected={isSelected}
          toggleDateSelection={selectDate}
        />
      );

      calendarDates.push(item);
    }

    return (
      <div className={classes}>
        <DatePickerCalendarHeader />

        <div className="calendar-dates">
          {offsetContainer}
          {calendarDates}
        </div>
      </div>
    );
  },
});

module.exports = DatePickerCalendar;
