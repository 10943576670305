import { VirtualizedRow } from "@/viewer/ui/modules/common/types/virtualization";
import StandardWeekTallyRow from "@/viewer/ui/modules/grid/standard/components/StandardWeekTallyRow";
import * as React from "react";
import { ReportData, ReportResult, TallyResult } from "@/viewer/types/domain/report";
import { formatISODate } from "@/viewer/utils/dateFormatters";
import { FilterMap, SettingsContext } from "@/viewer/ui/modules/common/types/context";
import {
  GROUP_BANNER_ROW_HEIGHT,
  BASE_STANDARD_WEEK_DATA_ROW_HEIGHT,
} from "@/viewer/ui/modules/grid/standard/constants";

interface RenderTallyGroupsOpts {
  settings: SettingsContext;

  dateStart: Date;
  dateEnd: Date;

  tallies?: TallyResult[];

  reportData?: ReportData;
  reportResult?: ReportResult;

  filteredPersonnel: FilterMap;
}

const RenderTallyGroups = (opts: RenderTallyGroupsOpts): VirtualizedRow[] => {
  const { settings, reportData, reportResult, tallies, dateStart, dateEnd, filteredPersonnel } = opts;

  const rows: VirtualizedRow[] = [];
  const weekKey = formatISODate(settings, dateStart);

  // Tally groups
  if (reportData && reportResult && tallies && tallies.length > 0) {
    rows.push({
      height: GROUP_BANNER_ROW_HEIGHT,
      key: `tally-header-${weekKey}`,
      wrapperClassName: "data-groups",
      element: (
        <div className={"data-group tallies"}>
          <div className="group-title group-title-tallies">
            <i className="fa fa-caret-up" />
            Tallies
          </div>
        </div>
      ),
    });

    tallies.forEach(({ tally_id, tally_name }) =>
      rows.push({
        height: BASE_STANDARD_WEEK_DATA_ROW_HEIGHT,
        key: `tally-${tally_id}-${weekKey}`,
        wrapperClassName: "data-groups",
        element: (
          <div className={"data-group tallies"}>
            <div className="data-rows">
              <StandardWeekTallyRow
                key={tally_id}
                settings={settings}
                reportData={reportData}
                reportResult={reportResult}
                tallyId={tally_id}
                tallyName={tally_name}
                dateStart={dateStart}
                dateEnd={dateEnd}
                filteredPersonnel={filteredPersonnel}
              />
            </div>
          </div>
        ),
      })
    );
  }
  return rows;
};

export default RenderTallyGroups;
